import React from 'react';
import '../Challenges.scss';
import Aux from '../../../hoc/Wrapper';
import { Container, Row, Col } from 'react-bootstrap';
import Breadcrumb from '../../../components/Breadcrumb/Breadcrumb';
import { withNamespaces } from 'react-i18next';
import AddChallenge from '../../../components/Challenges/AddChallenges/AddChallenge';
import { Link } from 'react-router-dom';
import { getCurrentUserRole } from '../../../common/Functions';
import TreeView from 'deni-react-treeview';



class AddChallenges extends React.Component {

    constructor(props) {
        super(props);
        this.treeviewref = React.createRef(TreeView);
    }

    state = {
        challenges: [],
        treeViewData: null,
        filteredChallenges: [],
        challengesData: null,
        no_challenge: false,
        sortBy: 'Easy',
        treeFilter: true,
        time: 0,
        isLoading: true,
        userrole: getCurrentUserRole(),
        challengeId: null,
        chapterName: null,
        sectionName: null,
        subsectionName: null,
        subsectionId: null,
        parentId: null

    }
    titlesOfData = []
    componentDidMount() {
        this.setState({ challengeId: this.props.match.params.challengeId })           
    }   
    render() {                 
        return (
            <Aux>
                <Row style={{ backgroundColor: "#f1f1f1", margin:"0" }}>
                    <Col sm="10"> <Breadcrumb
                        currentNode='Add Challenge'
                        prevNode={this.props.location.state ? this.props.location.state : []} /></Col>
                    {
                        this.state.userrole === "ContentAuthor" ? (<Col sm="2" style={{ textAlign: "center", paddingTop: "8px" }}>
                            <Link to="/curriculum-problems" style={{ textDecoration: 'none' }}>Cancel</Link>
                        </Col>) : null
                    }
                </Row>                
                <Container>
                    <Row className='challenges mb-5'>
                       
                        <div className='challange-right'>
                            <Row>
                                <Col>
                                    
                                    <AddChallenge
                                        challengeId={this.state.challengeId}
                                        // subsectionName={state.sub_section_name}
                                        // sectionName={state.section_name}
                                        // chapterName={state.chapter_name}
                                        // subsectionId={state.id}
                                        // parentId={state.parent_id}
                                    ></AddChallenge>
                                   
                                </Col>

                            </Row>
                        </div>
                    </Row>
                </Container>
            </Aux>
        );
    }
}


export default withNamespaces()(AddChallenges);