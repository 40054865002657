import React from 'react'
import './HighlightText.scss'
import DeleteIcon from '@material-ui/icons/Delete';
import CloseIcon from '@material-ui/icons/Close';
import Tooltip from '@material-ui/core/Tooltip';

class HighlightText extends React.Component {

    state = {
        note: '',
        highlighterColor: 'transparent',
        editNote: false
    }

    noteTextChangeHandler = (e) => {
        this.setState({
            note: e.target.value
        })
    }

    highlightText = (e) => {
        this.setState({
            highlighterColor: e.target.id.length ? e.target.id : this.state.note.length > 0 ? 'transparent' : ''
        }, () => {
            this.props.saveHighlight(this.state)
        })

    }

    handleSaveNoteClick = (e) => {
        e.preventDefault()
        if (this.state.note && !this.state.highlighterColor) {
            this.setState({
                highlighterColor: 'transparent'
            }, () => {
                this.props.saveHighlight(this.state, true)
            })
        } else if (!this.state.note && this.state.highlighterColor === 'transparent') {
            this.props.deleteHighlight(this.state)
        } else {
            this.props.saveHighlight(this.state, true)
        }
    }

    handleCancelClick = (e) => {
        e.preventDefault()
        this.props.closeHighlighter()
    }

    componentWillReceiveProps(newProps) {
        if (Object.keys(newProps.editMode).length !== 0) {
            // Clicking on already highlighted text
            this.setState({
                ...newProps.editMode,
                editNote: newProps.editMode.note.length > 0 ? true : false
            })
        } else if (newProps.highlighterPosition !== this.props.highlighterPosition) {
            this.setState({
                note: '',
                highlighterColor: 'transparent',
                editNote: false
            })
        }
    }

    render() {
        let { top, left } = this.props.highlighterPosition

        return (
            <div className={`note-highlighter ${this.props.showHighlighter ? 'show-highlighter' : 'hidden-highlighter'}`}
                style={{ top, left }}>
                <div className="highlighter-header">
                    <div className='highlighter-color-picker'>
                        {this.props.showLegends ?
                            <div className="d-flex flex-column align-items-center justify-content-center mx-1">
                                <div className='yellow-highlighter' id='yellow' onClick={this.highlightText}>
                                    {this.state.highlighterColor === 'yellow' ? <svg viewBox="0 0 512 512" fill="#000" className="selected-highlighter-color">
                                        <path fill="#000" d="M173.898 439.404l-166.4-166.4c-9.997-9.997-9.997-26.206 0-36.204l36.203-36.204c9.997-9.998 26.207-9.998 36.204 0L192 312.69 432.095 72.596c9.997-9.997 26.207-9.997 36.204 0l36.203 36.204c9.997 9.997 9.997 26.206 0 36.204l-294.4 294.401c-9.998 9.997-26.207 9.997-36.204-.001z"></path>
                                    </svg> : null}
                                </div>
                                <span className='legend text-center'>Diagnostics<br />Question</span>
                            </div>
                            : <div className='yellow-highlighter' id='yellow' onClick={this.highlightText}>
                                {this.state.highlighterColor === 'yellow' ? <svg viewBox="0 0 512 512" fill="#000" className="selected-highlighter-color">
                                    <path fill="#000" d="M173.898 439.404l-166.4-166.4c-9.997-9.997-9.997-26.206 0-36.204l36.203-36.204c9.997-9.998 26.207-9.998 36.204 0L192 312.69 432.095 72.596c9.997-9.997 26.207-9.997 36.204 0l36.203 36.204c9.997 9.997 9.997 26.206 0 36.204l-294.4 294.401c-9.998 9.997-26.207 9.997-36.204-.001z"></path>
                                </svg> : null}
                            </div>}

                        {this.props.showLegends ?
                            <div className="d-flex flex-column align-items-center justify-content-center mx-1">
                                <div className='green-highlighter' id='green' onClick={this.highlightText}>
                                    {this.state.highlighterColor === 'green' ? <svg viewBox="0 0 512 512" fill="#000" className="selected-highlighter-color">
                                        <path fill="#000" d="M173.898 439.404l-166.4-166.4c-9.997-9.997-9.997-26.206 0-36.204l36.203-36.204c9.997-9.998 26.207-9.998 36.204 0L192 312.69 432.095 72.596c9.997-9.997 26.207-9.997 36.204 0l36.203 36.204c9.997 9.997 9.997 26.206 0 36.204l-294.4 294.401c-9.998 9.997-26.207 9.997-36.204-.001z"></path>
                                    </svg> : null}
                                </div>
                                <span className='legend text-center'>Remedial<br />Text</span>
                            </div>
                            : <div className='green-highlighter' id='green' onClick={this.highlightText}>
                                {this.state.highlighterColor === 'green' ? <svg viewBox="0 0 512 512" fill="#000" className="selected-highlighter-color">
                                    <path fill="#000" d="M173.898 439.404l-166.4-166.4c-9.997-9.997-9.997-26.206 0-36.204l36.203-36.204c9.997-9.998 26.207-9.998 36.204 0L192 312.69 432.095 72.596c9.997-9.997 26.207-9.997 36.204 0l36.203 36.204c9.997 9.997 9.997 26.206 0 36.204l-294.4 294.401c-9.998 9.997-26.207 9.997-36.204-.001z"></path>
                                </svg> : null}
                            </div>}

                        {this.props.hasGreyHighlight ?
                            this.props.showLegends ?
                                <div className="d-flex flex-column align-items-center justify-content-center mx-1">
                                    <div className='grey-highlighter' id='grey' onClick={this.highlightText}>
                                        {this.state.highlighterColor === 'grey' ? <svg viewBox="0 0 512 512" fill="#000" className="selected-highlighter-color">
                                            <path fill="#000" d="M173.898 439.404l-166.4-166.4c-9.997-9.997-9.997-26.206 0-36.204l36.203-36.204c9.997-9.998 26.207-9.998 36.204 0L192 312.69 432.095 72.596c9.997-9.997 26.207-9.997 36.204 0l36.203 36.204c9.997 9.997 9.997 26.206 0 36.204l-294.4 294.401c-9.998 9.997-26.207 9.997-36.204-.001z"></path>
                                        </svg> : null}
                                    </div>
                                    <span className='legend text-center'>Student<br />Answer</span>
                                </div>
                                : <div className='grey-highlighter' id='grey' onClick={this.highlightText}>
                                    {this.state.highlighterColor === 'grey' ? <svg viewBox="0 0 512 512" fill="#000" className="selected-highlighter-color">
                                        <path fill="#000" d="M173.898 439.404l-166.4-166.4c-9.997-9.997-9.997-26.206 0-36.204l36.203-36.204c9.997-9.998 26.207-9.998 36.204 0L192 312.69 432.095 72.596c9.997-9.997 26.207-9.997 36.204 0l36.203 36.204c9.997 9.997 9.997 26.206 0 36.204l-294.4 294.401c-9.998 9.997-26.207 9.997-36.204-.001z"></path>
                                    </svg> : null}
                                </div>
                            : null}

                        {this.props.showLegends ?
                                <div className="d-flex flex-column align-items-center justify-content-center mx-1">
                                    <div className='blue-highlighter' id='blue' onClick={this.highlightText}>
                                        {this.state.highlighterColor === 'blue' ? <svg viewBox="0 0 512 512" fill="#000" className="selected-highlighter-color">
                                            <path fill="#000" d="M173.898 439.404l-166.4-166.4c-9.997-9.997-9.997-26.206 0-36.204l36.203-36.204c9.997-9.998 26.207-9.998 36.204 0L192 312.69 432.095 72.596c9.997-9.997 26.207-9.997 36.204 0l36.203 36.204c9.997 9.997 9.997 26.206 0 36.204l-294.4 294.401c-9.998 9.997-26.207 9.997-36.204-.001z"></path>
                                        </svg> : null}
                                    </div>
                                    <span className='legend text-center'>Resource</span>
                                </div>
                                : <div className='blue-highlighter' id='blue' onClick={this.highlightText}>
                                    {this.state.highlighterColor === 'blue' ? <svg viewBox="0 0 512 512" fill="#000" className="selected-highlighter-color">
                                        <path fill="#000" d="M173.898 439.404l-166.4-166.4c-9.997-9.997-9.997-26.206 0-36.204l36.203-36.204c9.997-9.998 26.207-9.998 36.204 0L192 312.69 432.095 72.596c9.997-9.997 26.207-9.997 36.204 0l36.203 36.204c9.997 9.997 9.997 26.206 0 36.204l-294.4 294.401c-9.998 9.997-26.207 9.997-36.204-.001z"></path>
                                    </svg> : null}
                                </div>}
                        {this.props.showLegends ?
                            <div className="d-flex flex-column align-items-center justify-content-center mx-1">
                                <div className='lq-highlighter' id='lq' onClick={this.highlightText}>
                                    {this.state.highlighterColor === 'lq' ? <svg viewBox="0 0 512 512" fill="#000" className="selected-highlighter-color">
                                        <path fill="#000" d="M173.898 439.404l-166.4-166.4c-9.997-9.997-9.997-26.206 0-36.204l36.203-36.204c9.997-9.998 26.207-9.998 36.204 0L192 312.69 432.095 72.596c9.997-9.997 26.207-9.997 36.204 0l36.203 36.204c9.997 9.997 9.997 26.206 0 36.204l-294.4 294.401c-9.998 9.997-26.207 9.997-36.204-.001z"></path>
                                    </svg> : null}
                                </div>
                                <span className='legend text-center'>Hints</span>
                            </div>
                            : <div className='lq-highlighter' id='lq' onClick={this.highlightText}>
                                {this.state.highlighterColor === 'lq' ? <svg viewBox="0 0 512 512" fill="#000" className="selected-highlighter-color">
                                    <path fill="#000" d="M173.898 439.404l-166.4-166.4c-9.997-9.997-9.997-26.206 0-36.204l36.203-36.204c9.997-9.998 26.207-9.998 36.204 0L192 312.69 432.095 72.596c9.997-9.997 26.207-9.997 36.204 0l36.203 36.204c9.997 9.997 9.997 26.206 0 36.204l-294.4 294.401c-9.998 9.997-26.207 9.997-36.204-.001z"></path>
                                </svg> : null}
                            </div>}
                            {this.props.showLegends ?
                            <div className="d-flex flex-column align-items-center justify-content-center mx-1">
                                <div className='pink-highlighter' id='pink' onClick={this.highlightText}>
                                    {this.state.highlighterColor === 'pink' ? <svg viewBox="0 0 512 512" fill="#000" className="selected-highlighter-color">
                                        <path fill="#000" d="M173.898 439.404l-166.4-166.4c-9.997-9.997-9.997-26.206 0-36.204l36.203-36.204c9.997-9.998 26.207-9.998 36.204 0L192 312.69 432.095 72.596c9.997-9.997 26.207-9.997 36.204 0l36.203 36.204c9.997 9.997 9.997 26.206 0 36.204l-294.4 294.401c-9.998 9.997-26.207 9.997-36.204-.001z"></path>
                                    </svg> : null}
                                </div>
                                <span className='legend text-center'>Hints</span>
                            </div>
                            : <div className='pink-highlighter' id='pink' onClick={this.highlightText}>
                                {this.state.highlighterColor === 'pink' ? <svg viewBox="0 0 512 512" fill="#000" className="selected-highlighter-color">
                                    <path fill="#000" d="M173.898 439.404l-166.4-166.4c-9.997-9.997-9.997-26.206 0-36.204l36.203-36.204c9.997-9.998 26.207-9.998 36.204 0L192 312.69 432.095 72.596c9.997-9.997 26.207-9.997 36.204 0l36.203 36.204c9.997 9.997 9.997 26.206 0 36.204l-294.4 294.401c-9.998 9.997-26.207 9.997-36.204-.001z"></path>
                                </svg> : null}
                            </div>}    
                        {this.props.showLegends ?
                            <div className="d-flex flex-column align-items-center justify-content-center mx-1">
                                <div className='purple-highlighter' id='purple' onClick={this.highlightText}>
                                    {this.state.highlighterColor === 'purple' ? <svg viewBox="0 0 512 512" fill="#000" className="selected-highlighter-color">
                                        <path fill="#000" d="M173.898 439.404l-166.4-166.4c-9.997-9.997-9.997-26.206 0-36.204l36.203-36.204c9.997-9.998 26.207-9.998 36.204 0L192 312.69 432.095 72.596c9.997-9.997 26.207-9.997 36.204 0l36.203 36.204c9.997 9.997 9.997 26.206 0 36.204l-294.4 294.401c-9.998 9.997-26.207 9.997-36.204-.001z"></path>
                                    </svg> : null}
                                </div>
                                <span className='legend text-center'>Error Root Cause</span>
                            </div>
                            : <div className='purple-highlighter' id='purple' onClick={this.highlightText}>
                                {this.state.highlighterColor === 'purple' ? <svg viewBox="0 0 512 512" fill="#000" className="selected-highlighter-color">
                                    <path fill="#000" d="M173.898 439.404l-166.4-166.4c-9.997-9.997-9.997-26.206 0-36.204l36.203-36.204c9.997-9.998 26.207-9.998 36.204 0L192 312.69 432.095 72.596c9.997-9.997 26.207-9.997 36.204 0l36.203 36.204c9.997 9.997 9.997 26.206 0 36.204l-294.4 294.401c-9.998 9.997-26.207 9.997-36.204-.001z"></path>
                                </svg> : null}
                            </div>}

                    </div>
                    <Tooltip title='Delete'>
                        <DeleteIcon className='delete-tooltip' onClick={() => { this.props.deleteHighlight(this.state) }} />
                    </Tooltip>
                    <Tooltip title='Close'>
                        <CloseIcon className='close-tooltip' onClick={() => { this.props.closeHighlighter() }} />
                    </Tooltip>
                </div>
                <form>
                    <textarea
                        maxlength="1000"
                        value={this.state.note}
                        onChange={this.noteTextChangeHandler}
                        placeholder="Add a note..."
                        className="inline-note" />
                    <div className={`highlighter-buttons ${this.state.editNote ? 'show-highlighter' : (this.state.note.length ? 'show-highlighter' : 'hidden-highlighter')}`}>
                        <button className='save-button'
                            onClick={this.handleSaveNoteClick}>Save</button>
                        <button className='cancel-button'
                            onClick={this.handleCancelClick}>Cancel</button>
                    </div>
                </form>
            </div>
        )
    }
}

export default HighlightText