import React from 'react';
import './Answerboard.scss';
import { Row, Col, Image } from 'react-bootstrap';
import Modal from '../Common/ModalComponent/ModalComponent';
import IconButton from './IconButton/IconButton';
import Aux from '../../hoc/Wrapper';
import Button from '../Common/Button/Button';
import parse from 'html-react-parser';
import CircularProgress from '@mui/material/CircularProgress';
import { Link, withRouter } from 'react-router-dom';
import IconNotification from '../../assets/images/icon_notification.svg';
import Acc2eImg from '../../assets/images/Acc-2e.png';
import LogoSign from '../../assets/images/logo-sign.svg';
import IconHintDescription from '../../assets/images/remember-this.png';
import AskAITutIcon from '../../assets/images/ai-tutor.png';
import {
    checkChallengeAnswer, fetchChallengeHint, fetchChallengeHintnonfrq, getNextStep, generateSVGFromMathML,
    getEntireSolution, fetchDiagnosticQuestions, checkDiagnosticQuestionAnswer, createRoomParticipantMapping,
    fetchTranscript, initiateTranscription, saveSynopsisEquation
    , gettutorsforstudenthelp, checkEquation, imgURL, SaveStudentChallenge
    , checkStudentAnswer, getTutorAnswer
    , askAnyProblemQuestion, studentSubscription, student_challengestatenonfrq,
    CalculatePTFRQGrade, getCalculatePTFRQGrade
} from '../../common/API';
import { substituteText } from '../../common/Constants';
import { ReactSortable } from 'react-sortablejs';
import ProgressSidebar from './ProgressSidebar/ProgressSidebar';
import IdleTimer from 'react-idle-timer';
import ArrowForwardIcon from '@material-ui/icons/ArrowForward';
import {
    getCurrentUserId, getCurrentUserRole, Call_Type, User_Role, obtainSubstitution
    , formatAnyProblemAPIResponse, getCurrentUserEmail, isRiceUser, Problem_source, formatNumber, equationContainerClass

} from '../../common/Functions';
//import { MathpixLoader, MathpixMarkdown } from 'mathpix-markdown-it'
import Spinner from 'react-bootstrap/Spinner'
import { toast } from 'react-toastify';
import SessionSynopsisWithData from './SessionSynopsis/SessionSynopsisWithData';
import { ToastContainer } from 'react-toastify';
import SketchpadBoard from '../../container/SketchPad/SketchpadBoard';
import Flow from '../SolutionGraph/flow';
import TutorProfile from './TutorProfile';
import Breadcrumb from '../Breadcrumb/Breadcrumb';
import ContentOptionsPopup from '../Common/ContentOptionsPopup/ContentOptionsPopup';
import Tutoritem from './Tutoritem'
// import TimeSlotBooking from './TimeSlotBooking'
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { styled } from '@mui/material/styles';
import TablePagination from '@mui/material/TablePagination';
import GraphComponent from '../../container/Homepage/Graph/GraphComponent'
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import CancelIcon from '@material-ui/icons/Cancel';
import CloseIcon from '@mui/icons-material/Close';
import DnsIcon from '@material-ui/icons/Dns';
import _, { isEmpty, isNil } from 'lodash';
import ProblemStatement from '../SolutionBoard/ProblemStatement';
import { ArrowBack } from '@material-ui/icons';
import configData from '../../common/config.json';
import Feedback from '../Feedback/Feedback';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import {
    Accordion,
    AccordionDetails,
    AccordionSummary,
    Typography
} from "@mui/material";
import ReactImageMagnify from 'react-image-magnify';
import MathJaxRenderer from '../Common/MathJaxRenderer/MathJaxRenderer';
import Stack from '@mui/material/Stack';
import TrapFocus from '@mui/material/Unstable_TrapFocus';
import Fade from '@mui/material/Fade';
import MuiButton from '@mui/material/Button';
import FeedbackC from '../Feedback/FeedbackC';
import MuiIconButton from '@mui/material/IconButton';
import MuiCloseIcon from '@mui/icons-material/Close';


const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
        //backgroundColor: theme.palette.common.black,	
        //color: theme.palette.common.white,	
        padding: "10px",
    },
    [`&.${tableCellClasses.body}`]: {
        fontSize: 12,
        padding: "7px"
    },
}));

class Answerboard extends React.Component {
    constructor(props) {
        super(props)
        this.idleTimer = null
        this.handleOnAction = this.handleOnAction.bind(this)
        this.handleOnActive = this.handleOnActive.bind(this)
        this.handleOnIdle1 = this.handleOnIdle1.bind(this)

        this.state = {
            showModal: false,

            hints: [
                {
                    title: "Remember this?",
                    subTitle: "See if you can use your previous work to solve your problem.",
                    hintImage: IconHintDescription
                }
            ],
            currentHintIndex: 0,
            rankingOptionList: [],
            hintRemedialText: null,
            hintRemedialSource: null,
            hintLastMatchedCheckpointId: null,
            totalHints: -1,
            result: null,
            tutorFlag: false,
            tutorAvailableFlag: false,
            openBackdrop: false,
            showProgressSidebar: false,
            showKnowledgeGraphSidebar: false,
            showSessionSynopsis: false,
            progressSidebarContent: '',
            knowledgeGraphSidebarContent: '',
            boardLoaded: false,
            isSessionSynopsisLoading: false,
            disabledSynopsisButton: false,
            diagnosticQuestions: undefined,
            diagnosticQuestionIndex: undefined,
            diagnosticQuestionOption: undefined,
            participantsForTranscript: [],
            transcripts: {},
            synopsisData: {},
            mappingId: "",
            tutorName: '',
            tutors_arr: [],
            no_tutor: false,
            tutor_in_tutors_arr: null,
            selectedEquation: undefined,
            availabletutorlist: false,
            availablecurrenttutordata: [],
            availablenext24tutordata: [],
            showAnswerModal: false,
            showContentOptions: false,
            isContentOptionsLoading: false,
            contentOptionsPosition: {
                top: 0,
                left: 0
            },
            tutorfinallist: [],
            page: 0,
            rowsPerPage: 5,
            tutorid: null,
            nextStepCounter: 0,
            totalStepCounter: 0,
            roomId: null,
            isText: false,
            showSolution: null,
            checkMyAnswer: null,
            homeworkreviewdata: [],
            homeworknonfrqreviewdata: [],
            equationdata: [],
            hint_data: null,
            footerbuttondisplay: true,
            is_review_complete: false,
            showsubmitbutton: true,
            showreviewdModal: false,
            showreviewdModalnonFRQ: false,
            loader: false,
            leftpanelbuttonhide: false,
            nonfrqhintdata: [],
            anyProblemStatement: '',
            actionBtnDisabled: this.props.anyProblem ? true : false,
            anyproblemBackbuttonText: "",
            anyproblemBackbuttonlink: "",
            sketchpadDivcolor: this.props.anyProblem ? "transparent" : "#fff",
            checkMyWorkClickedOnce: false,
            askTutorTextYes: false,
            afterSubscriptionMessage: false,
            askTutorButton: false,
            upperSectionHide: false,
            isScreenFull: false,
            cHeight: "90vh",
            eHeight: "570px",
            show: false,
            problem_source: Problem_source.interactiveproblems,
            issubmitdialog: false,
            studentAnswer: "",
            validationMessage: false,
            dButtonText: "",
            mcqcorrectAnswerReview: null,
            mainDivDisabled: false,
            askAITutorEnabled: false,
            hinTextforAIAskTutorQuestion: "",
            forHintAITutor: false,
            checkedItems: new Map(),
            selectedCheckboxIds: [],
            selectedAnswersByUser: "",
            totalGradeCalculationTrial: 1,
            lastCalculatedGrades: 0.0,
            divTotalGrades: "",
            setShowSolutionBtnVisibility: false,
            bannerOpen: false,
            checkMyWorkCounter: 0,
            openFeedbackModal: false,
            assignment_id: null,
            progressbarTitle: "Evaluation",
            askAIquestion: "",
            askAIBgColor: true,
            equationClass: equationContainerClass.correctContainer,
            equationClick: true
        }
    }

    availabletutorlistdata = null
    ids = []
    pSource = ""
    userAnswerText = ""

    refs = []
    modalBody = null;
    tutormodalbody = null;
    modalTitle = null;
    showButton = false;
    customButton = null;
    loadTranscriptTimer = null;
    draggable = false;

    handleOnAction(event) {
        //console.log('user did something', event)
    }

    handleOnActive(event) {
        console.log('user is active', event)
        console.log('time remaining', this.idleTimer.getRemainingTime())
    }

    handleOnIdle1(event) {
        this.setState({ bannerOpen: true, openFeedbackModal: false })
        console.log('user is idle', event)
        console.log('last active', this.idleTimer.getLastActiveTime())
    }


    componentWillMount() {
        let feedbackdiv = document.getElementById("submitFeedback")
        if (this.props.question.type === "FRQ") {
            feedbackdiv.style.visibility = "hidden"
        }
    }
    componentDidMount() {
        debugger;
        this.setState({ loader: true })

        if (this.props.match.params.assignmentId !== undefined && this.props.match.params.assignmentId !== null && this.props.match.params.assignmentId !== "") {
            this.setState({ assignment_id: this.props.match.params.assignmentId, problem_source: Problem_source.assignment, showsubmitbutton: false })
        }

        if (this.props.anyProblem) {
            if (!isNil(this.props.location.state["prevNode"]) && !isEmpty(this.props.location.state["prevNode"])) {
                if (this.props.location.state["prevNode"][0] === "Curriculum Content") {
                    this.setState({ anyproblemBackbuttonText: "Interactive Problems", anyproblemBackbuttonlink: "/curriculum-problems" })
                }
                else {
                    this.setState({ anyproblemBackbuttonText: "Back", anyproblemBackbuttonlink: "/" })
                }
            }
            else {
                this.setState({ anyproblemBackbuttonText: "Back", anyproblemBackbuttonlink: "/" })
            }

            this.props.location.state = {
                ...this.props.location.state,
                resetHintCount: this.resetHintCount
            }
        }
        else {
            if (!isNil(this.props.location.state["prevNode"]) && !isEmpty(this.props.location.state["prevNode"])) {
                if (this.props.location.state["prevNode"][0] === "Interactive Problems") {
                    this.setState({ showsubmitbutton: true })
                }
                else if (this.props.location.state["prevNode"][0] === "Learning Content") {
                    this.setState({ showsubmitbutton: false })
                }
            }
            else {
                this.setState({ showsubmitbutton: false })
            }
            localStorage.removeItem("studentroomid")
            this.props.location.state = {
                ...this.props.location.state,
                resetHintCount: this.resetHintCount
            }
            window.$(document).scroll(() => {
                this.listenScrollEvent();
            });
            if (this.props.question.type === 'Ranking') {
                let rankingOptionList = this.props.question.options.map((opt, index) => ({ id: String(index + 1), name: opt.trim() }))
                this.setState({ rankingOptionList: rankingOptionList });
            }
            if (this.props.call_type === Call_Type.Challenge && getCurrentUserRole() == User_Role.Student) {
                const reqData = { params: { 'Challenge_ID': this.props.challengeId, 'user_id': getCurrentUserId() } }
                gettutorsforstudenthelp(reqData).then(res => {
                    this.setState({ loader: false })
                    if (res.status === 200) {
                        let finaldata = []

                        if (res.data.currentavailbletutor.length > 0 && res.data.next24availabletutor.length > 0) {
                            finaldata = res.data.currentavailbletutor;
                            Array.prototype.push.apply(finaldata, res.data.next24availabletutor);
                        }
                        else if (res.data.currentavailbletutor.length > 0 && res.data.next24availabletutor.length === 0) {
                            finaldata = res.data.currentavailbletutor;
                        }
                        else if (res.data.currentavailbletutor.length === 0 && res.data.next24availabletutor.length > 0) {
                            finaldata = res.data.next24availabletutor;
                        }
                        this.setState({ tutorfinallist: finaldata })
                    }
                })
            }
            if (getCurrentUserRole() === User_Role.Student && this.pSource == Problem_source.interactiveproblems && this.props.question.type == 'FRQ') {
                SaveStudentChallenge({ user_id: getCurrentUserId(), challenge_id: this.props.challengeId })
                    .then(res => { })
                    .catch(err => {
                        this.setState({ loader: false })
                        console.error(err.message);
                    })
            }
            if (getCurrentUserRole() === User_Role.Student) {
                if (this.props.question.type !== 'FRQ') {
                    this.setState({ loader: true })
                    let reqData = {
                        challenge_id: this.props.challengeId,
                        studentId: getCurrentUserId(),
                    }
                    student_challengestatenonfrq({ params: reqData }).then(res => {
                        this.setState({ loader: false })
                        if (res.data.data !== undefined && res.data.data.length > 0) {
                            let questionanswertext = res.data.data[0].answertext
                            this.setState({ selectedAnswersByUser: questionanswertext, mcqcorrectAnswerReview: questionanswertext, msqCorrectAnswerReview: questionanswertext }, () => {
                                this.setState({
                                    footerbuttondisplay: false
                                    , showsubmitbutton: false
                                    , leftpanelbuttonhide: true
                                })
                            })
                        }
                        else {
                            this.setState({
                                leftpanelbuttonhide: false, mainDivDisabled: false
                            })
                        }
                    })
                }
                SaveStudentChallenge({ user_id: getCurrentUserId(), challenge_id: this.props.challengeId })
                    .then(res => { })
                    .catch(err => {
                        this.setState({ loader: false })
                        console.error(err.message);
                    })
            }

            let params = new URLSearchParams(window.location.search);
            let roomId = params.get('roomId');
            this.setState({ roomId: roomId });
            if (!this.props.question.type === 'FRQ' && getCurrentUserRole() !== 'Student') {
                this.setState({ footerbuttondisplay: true })
            }
            else if (!this.props.question.type === 'FRQ' && getCurrentUserRole() === 'Student') {
                this.setState({ footerbuttondisplay: true })
            }
            else if (this.props.question.type === 'FRQ' && getCurrentUserRole() !== 'Student') {
                this.setState({ footerbuttondisplay: true })
            }
            else if (this.props.question.type === 'FRQ' && getCurrentUserRole() === 'Student') {
                this.setState({ footerbuttondisplay: false })
            }
        }

        if (window.location.pathname.includes("answerboardpt")) {
            debugger;
            let assignmentId = this.props.match.params.assignmentId !== undefined && this.props.match.params.assignmentId !== null && this.props.match.params.assignmentId !== "" ? this.props.match.params.assignmentId : ""
            const reqGetData = {
                params: {
                    user_id: getCurrentUserId(),
                    challenge_id: this.props.challengeId.split('c')[1],
                    assignment_id: assignmentId
                }
            }
            getCalculatePTFRQGrade(reqGetData).then(res => {
                this.setState({ loader: false })
                if (res.data !== undefined) {
                    //    debugger;
                    this.state.totalGradeCalculationTrial = parseInt(res.data.totalGradeCalculationTrial)
                    this.setState({ totalGradeCalculationTrial: parseInt(res.data.totalGradeCalculationTrial), lastCalculatedGrades: res.data.totalGrades })
                    if (parseInt(res.data.totalGradeCalculationTrial) >= this.props.totalTrials) {
                        this.setState({ setShowSolutionBtnVisibility: true })
                    }
                }
            })
        }

        this.setState({ loader: false })
    }
    handleChangePage = (event, page) => {
        this.setState({ page }, () => {
            this.raiseHandClickHandler()
        });
    };
    handleChangeRowsPerPage = event => {
        this.setState({ rowsPerPage: event.target.value, page: 0 }, () => {
            this.raiseHandClickHandler()
        });
    };

    openModal = (event, modalTitle, modalBody, customButton = null, showButton = false, draggable = false) => {
        if (event) {
            event.preventDefault();
        }
        this.modalBody = modalBody;
        this.modalTitle = modalTitle;
        this.showButton = showButton;
        this.customButton = customButton;
        this.draggable = draggable;
        this.setState({ showModal: true, loader: false });
    }

    closeCalculateGradeModalHandler = () => {
        this.setState({ showModal: false, loader: false });

    }

    closeHIntModal = () => {
        this.setState({ bannerOpen: true, openFeedbackModal: false })
        this.setState({ showModal: false, askAITutorEnabled: false });
    }

    forhintAiTutorfalsehandler = () => {
        this.setState({ forHintAITutor: false })
    }
    askAItutorHandlerhint = (e, question) => {

        this.setState({ askAITutorEnabled: true, showModal: false, hinTextforAIAskTutorQuestion: "Hint:" + question, forHintAITutor: true });
        //this.setState({ showModal: false });
    }
    handlehintequationclick = () => {

        this.setState({ hinTextforAIAskTutorQuestion: "" });
        //this.setState({ showModal: false });
    }

    closeCalculateGradeModal = () => {
        this.setState({ showModal: false })
    }

    closeModal = () => {

        this.setState({ showModal: false, askTutorTextYes: false, afterSubscriptionMessage: false, askTutorButton: false });
        if (this.submitModalClose) {
            if (this.state.problem_source === Problem_source.assignment) {
                this.props.history.push('/assignment');
            }

            else {
                this.props.history.push('/curriculum-problems');
            }
        }

        this.setState({ bannerOpen: true, openFeedbackModal: false })
        this.availabletutorlistdata = null
    }

    handleasktutoryes = () => {
        this.setState({ askTutorTextYes: true, askTutorButton: true }, () => {
            this.raiseHandClickHandler()
        })
    }
    handleasktutorsubscription = () => {
        this.setState({ loader: true })
        const reqData = {
            "user_id": getCurrentUserId(),
            "user_email": getCurrentUserEmail(),
            "challenge_content_id": this.props.challengeId,
            "action_type": "ask tutor challenge",
            "institute_name": ""
        }
        studentSubscription(reqData).then(res => {
            if (res.status === 200) {
                this.setState({ loader: false, afterSubscriptionMessage: true }, () => {
                    this.raiseHandClickHandler()
                })
            }
        }).catch(err => {
            this.setState({ loader: false, afterSubscriptionMessage: false })
            console.error(err.message);
        })

    }


    handleclosetutoravail = () => {
        this.setState({ availabletutorlist: false });
    }

    closeAnswerModal = () => {
        this.raiseHandClickHandler()
        this.setState({ showAnswerModal: false });
    }

    closetutorprofilecontent = () => {
        this.setState({ showContentOptions: false });
    }
    openContentOptions = (event, modalBody, position, isContentOptionsLoading) => {
        // event.preventDefault();
        this.tutormodalbody = modalBody;

        this.setState({
            showContentOptions: true,
            isContentOptionsLoading: isContentOptionsLoading,
            contentOptionsPosition: position,
        });
    }

    openContentOptionsModal = (e, data) => {
        let isTouchEvent = false
        if (e.nativeEvent instanceof TouchEvent) {
            isTouchEvent = true
        }
        let topPosition = e.pageY
        let leftPosition = 55
        // if (isTouchEvent) {
        //     var touch = e.changedTouches[0];
        //     topPosition = touch.pageY
        //     leftPosition = touch.pageX - 460
        // }
        let position = {
            top: topPosition,
            left: leftPosition
        }
        this.openContentOptions(e,
            <div className="progress-bar-container"><Spinner animation="border" role="status" /></div>,
            position, true)

        this.openContentOptions(e,
            this.getContentOptionsJSX(data),
            position, false)
    }
    handleopentutoravail = () => {
        let answerModalBody =
            <Table responsive>
                <thead>
                    <tr>
                        <th>Avatar</th>
                        <th>Name</th>
                        <th>Availability</th>
                    </tr>
                </thead>
                <tbody>
                    {
                        this.state.availablecurrenttutordata.length > 0 ? (
                            <>

                                {
                                    this.state.availablecurrenttutordata.map((data, index) => {
                                        return (
                                            <tr>
                                                <td
                                                    //onMouseEnter={(e) => !this.state.isContentOptionsLoading ? this.openContentOptionsModal(e, data) : null}
                                                    // onMouseLeave={(e) => this.setState({isContentOptionsLoading:true,})}
                                                    // onMouseOut={(e) => this.setState({isContentOptionsLoading:true})}
                                                    // onMouseOver={(e) => this.setState({isContentOptionsLoading:true})}
                                                    onClick={(e) => this.openContentOptionsModal(e, data)}
                                                >
                                                    <Image src={imgURL + data.profile_pic} alt="" style={{ height: "40px", width: "40px" }} rounded />
                                                </td>
                                                <td>{data.name}</td>
                                                <td>{data.availabilitytime}</td>
                                            </tr>
                                        )

                                    })
                                }

                            </>) : null

                    }
                    {
                        this.state.availablenext24tutordata.length > 0 ? (
                            <>
                                {
                                    this.state.availablenext24tutordata.map((data, index) => {
                                        return (
                                            <tr>
                                                <td
                                                    onClick={(e) => this.openContentOptionsModal(e, data)}
                                                //onMouseEnter={(e) => !this.state.isContentOptionsLoading ? this.openContentOptionsModal(e, data) : null}
                                                // onMouseLeave={(e) => this.setState({isContentOptionsLoading:true})}
                                                // onMouseOut={(e) => this.setState({isContentOptionsLoading:true})}
                                                // onMouseOver={(e) => this.setState({isContentOptionsLoading:true})}
                                                >
                                                    <Image
                                                        src={imgURL + data.profile_pic} alt="" style={{ height: "40px", width: "40px" }} rounded /></td>
                                                <td>{data.name}</td>
                                                <td>{data.availabilitytime}</td>
                                            </tr>
                                        )

                                    })
                                }
                            </>
                        ) : null
                    }
                </tbody>
            </Table>
        this.availabletutorlistdata = answerModalBody
        this.raiseHandClickHandler()
    }

    raiseHandAITutorClickHandler = (event) => {
        this.setState({ askAITutorEnabled: !this.state.askAITutorEnabled });
        return;
    }

    closeAskAITutor = () => {
        this.setState({ askAITutorEnabled: false });
    }

    raiseHandClickHandler = (event) => {
        //    this.setState({ askAITutorEnabled: !this.state.askAITutorEnabled });
        //  return;
        let listdata = null;
        if (this.state.tutorfinallist.length > 0) {
            listdata = <>
                <TableContainer component={Paper}>
                    <Table aria-label="collapsible table">
                        <TableHead>
                            <TableRow>
                                <StyledTableCell />
                                <StyledTableCell></StyledTableCell>
                                <StyledTableCell>Name</StyledTableCell>
                                <StyledTableCell>Availability</StyledTableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {
                                this.state.tutorfinallist.slice(this.state.page * this.state.rowsPerPage, this.state.page * this.state.rowsPerPage + this.state.rowsPerPage).map((row) => {
                                    return (
                                        <Tutoritem key={row.name} row={row} handleconnect={(e) => this.openVCCloseModal(e, row)}
                                            handlebookappointment={(e) => this.handlebookappointment(e, row)} />
                                    )
                                }
                                )}
                        </TableBody>
                    </Table>
                </TableContainer>
                <TablePagination
                    rowsPerPageOptions={[5, 10]}
                    component="div"
                    count={this.state.tutorfinallist.length}
                    rowsPerPage={this.state.rowsPerPage}
                    page={this.state.page}
                    onPageChange={this.handleChangePage}
                    onRowsPerPageChange={this.handleChangeRowsPerPage}
                />
            </>
        }


        // for 
        var modalBody1 = (
            <>
                <div className='text-center '>
                    <img src={IconNotification} alt='NotificationIcon' />
                    <div className='iconNotification'>Would you like help from a tutor?</div>
                </div>
                <div className='mt-3'>
                    {listdata}

                </div>
            </>
        );
        var customButton1 = <div className='modal-button text-center'>
            {
                this.state.tutorfinallist.length > 0 ? (
                    <>
                        <Button clicked={this.openVCCloseModal} className="mr-2 yellow-btn">Auto Connect</Button>
                        <Button clicked={this.closeModal} className="mr-2 grey-btn">No Thanks</Button>
                    </>
                ) :
                    (<>
                        <Button clicked={this.openVCCloseModal} className="mr-2 yellow-btn">Ok</Button>
                        <Button clicked={this.closeModal} className="mr-2 grey-btn">No Thanks</Button>
                    </>)

            }
        </div>
        var modalBody = (
            <>
                <div className='text-center'>
                    {
                        !this.state.askTutorTextYes ? (<> <img src={IconNotification} alt='NotificationIcon' /><div className='iconNotification'>We recommend you to ask for Hints first. Still want to connect to Tutor?</div></>) :
                            (<>
                                <img src={Acc2eImg} className='askTutorImageContainer' alt='NotificationIcon' />
                                {
                                    isRiceUser() ? <>
                                        <div className='iconNotification'>This service is not available for your institution for now.</div>
                                    </> :
                                        !this.state.afterSubscriptionMessage ? (<>
                                            <div className='iconNotification'>Sorry, no tutor is available at this time for your subscription level.</div>
                                            <div className="upgrateSubscriptionBox" onClick={this.handleasktutorsubscription}>Please
                                                contact us to upgrade your subscription</div>
                                        </>) : (<div>Thanks for your request. We will get back soon.</div>)
                                }
                            </>)
                    }

                </div>
                <div className='mt-3'>
                    {listdata}

                </div>
            </>
        );
        var customButton = <div className='modal-button text-center'>
            {
                this.state.tutorfinallist.length > 0 ? (
                    <>
                        <Button clicked={this.openVCCloseModal} className="mr-2 yellow-btn">Auto Connect</Button>
                        <Button clicked={this.closeModal} className="mr-2 grey-btn">No Thanks</Button>
                    </>
                ) :
                    (<>

                        {
                            (getCurrentUserEmail() === "student@aiplato.ai" || getCurrentUserEmail() === "student1@aiplato.ai") ? (<> <Button clicked={this.openVCCloseModal} className="mr-2 yellow-btn">Ok</Button>
                                <Button clicked={this.closeModal} className="mr-2 grey-btn">No Thanks</Button></>) :
                                (<>
                                    {
                                        !this.state.askTutorButton ? (<> <Button clicked={this.handleasktutoryes} className="mr-2 yellow-btn">Yes</Button>
                                            <Button clicked={this.closeModal} className="mr-2 grey-btn">No</Button></>) : (
                                            <>
                                                {
                                                    this.state.afterSubscriptionMessage ? (<Button clicked={this.closeModal} className="mr-2 grey-btn">Ok</Button>) : null
                                                }
                                            </>

                                        )
                                    }
                                </>)
                        }



                    </>)

            }
        </div>

        {
            (getCurrentUserEmail() === "student@aiplato.ai" || getCurrentUserEmail() === "student1@aiplato.ai") ? (this.openModal(null,
                null,
                modalBody1,
                customButton1,
                false,
                false)) : (this.openModal(null,
                    null,
                    modalBody,
                    customButton,
                    false,
                    false))
        }


    }

    setBoardLoaded = (value) => {
        console.log("val - " + value)
        this.setState({ boardLoaded: value });
    }

    handlebookappointment = (event, row) => {
        var modalTitle = (
            <>
                <div>Book Your appointment with <b>{row.name}</b></div>
            </>
        )
        var modalBody = (
            <>
                <div >
                    {/* <TimeSlotBooking row={row} ></TimeSlotBooking> */}
                </div>
            </>
        );
        this.openModal(event,
            modalTitle,
            modalBody,
            false,
            false,
            false)
    }

    openVCCloseModal = (event, row = null) => {
        this.setState({ showProgressSidebar: false });
        this.setState({ showKnowledgeGraphSidebar: false });
        // if (this.props.location.state.submitFunction) {
        //     this.props.location.state.submitFunction(false);
        // }
        if (row !== null) {
            this.setState({ tutorid: row.tutor_user_id }, () => {
                this.props.toggleVC(this.state.tutorid);
            })
        }
        else {
            this.props.toggleVC();
        }
        this.closeModal();

        this.availabletutorlistdata = null;
    }
    mcqChangeHandler = (ev, selectedtext) => {
        this.setState({ mcqcorrectAnswerReview: ev.target.value, selectedAnswersByUser: ev.target.value }, () => {
            this.checkChallengeAnswerCommon(ev, "MCQ")
        })
    }
    msqChangeHandler = (ev, position, opttext) => {
        debugger;
        ev.persist();
        let targetValue = ev.target.value
        let answerbyuser = this.state.selectedAnswersByUser
        if (ev.target.checked) {

            if (answerbyuser === undefined || answerbyuser === null || answerbyuser == "") {
                answerbyuser = targetValue
            }
            else {
                answerbyuser = answerbyuser + "###" + targetValue
            }

        } else {

            if (answerbyuser.includes(targetValue)) {
                let finaldata = ""
                if (answerbyuser.includes("###")) {

                    let arr = answerbyuser.split("###")
                    arr.forEach((rem) => {
                        if (targetValue !== rem) {
                            if (finaldata !== "" && finaldata !== null && finaldata !== undefined) {
                                finaldata = finaldata + "###" + rem
                            }
                            else {
                                finaldata = rem
                            }
                        }
                    })
                }
                answerbyuser = finaldata
            }
        }
        this.setState({ selectedAnswersByUser: answerbyuser }, () => {
            this.checkChallengeAnswerCommon(ev, "MSQ")
        })
    }
    questionChallengeClickHandler = (s) => {
        if (s === 'yes') {
            this.openModal(undefined,
                'Please wait...',
                <p>Thanks for submitting your solution! Taking you to more challenges...</p>);
            setTimeout(() => {
                this.submitModalClose = true;
                this.closeModal();
            }, 5000)

        } else {
            this.closeModal();
        }
    }

    resetHintCount = () => {
        this.setState({ hintRemedialText: "", currentHintIndex: 0, totalHints: -1 });
    }
    getAnswerText = (anstext) => {
        this.userAnswer = anstext
    }
    closeAnswerDeviders = (deviderName) => {
        if (deviderName === "checkMyAnswer") {
            this.state.checkMyAnswer = null;
            this.setState({ checkMyAnswer: null })
        }

        if (deviderName === "showSolution") {
            this.state.showSolution = null;
            this.setState({ showSolution: null })
        }

        if (this.state.checkMyAnswer === null && this.state.showSolution === null)
            this.state.isText = false
    }
    submitNonFRQHandler = (event) => {

        if (this.userAnswerText || this.props.question.type === 'Ranking') {

            if (this.props.question.type === 'Ranking') {
                this.userAnswerText = this.state.rankingOptionList.map(item => item.name)
            }
        }
        if (this.userAnswerText !== undefined && this.userAnswerText !== null && this.userAnswerText !== "") {
            let userselectedanswer = ""
            //debugger;
            if (this.userAnswerText.includes("###")) {
                let splitString = this.userAnswerText.split("###")
                splitString.forEach(element => {
                    if (userselectedanswer !== "") {
                        userselectedanswer = userselectedanswer + " " + element
                    }
                    else {
                        userselectedanswer = element
                    }

                });

                // userselectedanswer = userselectedanswer.replace("###", " ")
            }
            else {
                userselectedanswer = this.userAnswerText
            }
            //debugger;
            this.setState({ studentAnswer: "Your final answer: " + userselectedanswer, validationMessage: false, dButtonText: "Yes" }, () => {
                this.setState({ issubmitdialog: true })
            })
        }
        else {
            this.setState({ studentAnswer: "Please Select Answer First!" }, () => {
                this.setState({ issubmitdialog: true, validationMessage: true, dButtonText: "Ok" })
            })
        }
    }

    checkChallengeAnswerCommon = (event, qtype) => {
        let question_id = this.props.question.id.slice(1, this.props.question.id.length);
        let selecteduserdata = this.state.selectedAnswersByUser
        if (selecteduserdata || this.props.question.type === 'Ranking') {

            if (this.props.question.type === 'Ranking') {
                selecteduserdata = this.state.rankingOptionList.map(item => item.name)
            }
            let assignmentId = this.props.match.params.assignmentId !== undefined && this.props.match.params.assignmentId !== null && this.props.match.params.assignmentId !== "" ? this.props.match.params.assignmentId : ""
            let reqData = {
                userAnswer: selecteduserdata,
                questionId: question_id,
                user_id: getCurrentUserId(),
                assignment_id: assignmentId,
                hints_used: this.state.nonfrqhintdata,
                problem_source: this.state.problem_source,
                boardId: this.props.location.state.boardId,
                final_submission: 0,
                optionselected: 1,
                qtype: qtype,
                fromTestPaper: 0,
            }
            checkChallengeAnswer(reqData).then(res => {
                this.setState({ loader: false })

            }).catch(err => {
                this.setState({ loader: false })
                console.error(err.message);
                alert('Error occurred while submitting the answer.');
            })
        }

    }
    submitFinalAnswer = (event) => {

        if (this.props.question.type !== 'FRQ') {
            this.setState({ loader: true })

            let question_id = this.props.question.id.slice(1, this.props.question.id.length);

            if (this.props.question.type.toLowerCase() === "text") {
                this.setState({ loader: false })
                let modalContent = null;
                if (!isNil(this.userAnswer) && this.userAnswer !== undefined) {
                    this.state.isText = true
                    modalContent = (
                        <Aux>
                            <div className="progress-bar-container">
                                <Spinner animation="border" role="status" />
                            </div>
                        </Aux>
                    )

                    this.setState({ checkMyAnswer: modalContent });
                    let assignmentId = this.props.match.params.assignmentId !== undefined && this.props.match.params.assignmentId !== null && this.props.match.params.assignmentId !== "" ? this.props.match.params.assignmentId : ""
                    checkStudentAnswer(this.userAnswer, question_id, getCurrentUserId(), assignmentId, this.state.problem_source).then(res => {

                        if (res.status === 200) {
                            this.state.isText = true
                            var correctFacts = [];
                            var missingFacts = [];
                            var extraFacts = [];

                            const correct_step_feedback = ["Good job covering the following concepts. ", "Well done covering the following concepts.", "Well done outlining the key points related to following concepts: "];
                            let ind_correct_step_feedback = Math.floor(Math.random() * 3);
                            const correct_step_feedback_ending = ["! 😊", "! 👍"];
                            let ind_correct_step_feedback_ending = Math.floor(Math.random() * 2);

                            const missing_step_feedback = ["Consider covering the following concepts. It provides important context.", "Don't forget to mention the following concepts as it provides important context."];
                            let ind_missing_step_feedback = Math.floor(Math.random() * 2);
                            const missing_step_feedback_ending = [" ", " ", " "];
                            let ind_missing_step_feedback_ending = Math.floor(Math.random() * 3);

                            const extra_step_feedback = ["The following concepts are not necessary in the answer. ", "While  the following information is interesting, it may not be directly relevant to the question."];
                            let ind_extra_step_feedback = Math.floor(Math.random() * 2);
                            const extra_step_feedback_ending = ["", ""]
                            let ind_extra_step_feedback_ending = Math.floor(Math.random() * 2);

                            if (res.data.Covered.length > 0) {
                                correctFacts.push(
                                    <div className="green">
                                        <CheckCircleIcon className='greenIcon' />
                                        {correct_step_feedback[ind_correct_step_feedback] + correct_step_feedback_ending[ind_correct_step_feedback_ending]}
                                    </div>
                                )
                                res.data.Covered.forEach(correct => {
                                    correctFacts.push(
                                        <p className='feedbackmodal p'>{correct}</p>
                                    )
                                });
                            }

                            if (res.data.Missing.length > 0) {
                                missingFacts.push(
                                    <div className="red">
                                        <CancelIcon className='redIcon' />
                                        {missing_step_feedback[ind_missing_step_feedback] + '' + missing_step_feedback_ending[ind_missing_step_feedback_ending]}
                                    </div>
                                )
                                res.data.Missing.forEach(missing => {

                                    let data = res.data.ConceptResource[missing]
                                    if (data !== undefined && data !== null && data.length > 0 && data !== "" && data !== 'undefined' && data !== 'null') {

                                        let conceptresourcediv = []
                                        data.forEach(item => {

                                            let dataitem
                                            dataitem = <li> <a href={item.ResourceUrl !== null && item.ResourceUrl !== undefined && item.ResourceUrl !== "" ? item.ResourceUrl : "#"}
                                                target='_blank' rel="noopener noreferrer">
                                                <div dangerouslySetInnerHTML={{ __html: item.ResourceText }}></div></a></li>

                                            conceptresourcediv.push(dataitem)
                                        })
                                        missingFacts.push(
                                            <>
                                                <p className='feedbackmodal p' style={{ marginBottom: "2px" }}>{missing}</p>
                                                <ul
                                                //style={{ paddingLeft: "35px" }}
                                                >{conceptresourcediv}</ul>
                                                {/* <p style={{ paddingLeft: "15px" }}>{conceptresourcediv}</p> */}
                                            </>

                                        );

                                    }
                                    else {
                                        missingFacts.push(
                                            <p className='feedbackmodal p'>{missing}</p>

                                        );
                                    }
                                })
                            }

                            if (res.data.Other.length > 0) {
                                extraFacts.push(
                                    <div className='gray'>
                                        <DnsIcon className='grayIcon' />
                                        {extra_step_feedback[ind_extra_step_feedback] + '' + extra_step_feedback_ending[ind_extra_step_feedback_ending]}
                                    </div>
                                )
                                res.data.Other.forEach(extra => {
                                    extraFacts.push(
                                        <p className='feedbackmodal p'>{extra}</p>
                                    )
                                });
                            }

                            modalContent = (
                                <div className='p-1' >
                                    <div className='showSolutionTitle'>
                                        Your Answer
                                        <a className='float-right text-secondary' onClick={(e) => this.closeAnswerDeviders("checkMyAnswer")}>
                                            {/* <i class="fa fa-close" aria-hidden="true"></i> */}
                                            <CloseIcon className='' />
                                        </a>
                                    </div>

                                    <div className='modal-content divFacts'>
                                        {(correctFacts.length > 0 ? (Object.values(correctFacts)) : "")}
                                    </div>
                                    <div className='modal-content divFacts'>
                                        {(missingFacts.length > 0 ? (Object.values(missingFacts)) : "")}
                                    </div>
                                    <div className='modal-content divFacts'>
                                        {(extraFacts.length > 0 ? (Object.values(extraFacts)) : "")}
                                    </div>
                                    <div className='modal-content graphdiv'>
                                        <div className='showSolutionTitle border-0'> Graph View : </div>
                                        {(res.data.Node.length > 0 && res.data.Edge.length > 0 ? <GraphComponent isPT={false} isauthordashboard={false} componentId="checkMyAnswer" chartType="basic" nodes={res.data.Node} edges={res.data.Edge} ></GraphComponent> : "")}
                                    </div>
                                </div>
                            )

                            this.setState({ checkMyAnswer: modalContent });
                            //    this.openModal(event,modalTitle, modalContent,modalCustomButton,false,true)                    
                        }
                    }).catch(err => {
                        // For testing of div clouser and progress bar when api gives error
                        //     this.state.isText = true
                        //     modalContent = (
                        //         <div className='p-1' >
                        //             <div className='showSolutionTitle'>
                        //                 Your Solution
                        //                 <a className='float-right text-secondary' onClick={(e) => this.closeAnswerDeviders("checkMyAnswer")}><i class="fa fa-close" aria-hidden="true"></i></a>
                        //             </div>
                        //         </div>
                        //     )

                        //     this.setState({ checkMyAnswer: modalContent });

                        //  //   
                        this.setState({ loader: false })
                        this.closeAnswerDeviders("checkMyAnswer")
                        console.error(err.message);
                        alert('Error occurred while submitting the answer.');
                    })
                }
                else {
                    //alert('Please enter the answer.');
                    this.setState({ studentAnswer: "Please Select Answer First!" }, () => {
                        this.setState({ issubmitdialog: true, validationMessage: true, dButtonText: "Ok" })
                    })
                }
            }
            else {
                debugger;
                // this.setState({ loader: true })
                if (this.props.question.type === 'MCQ') {
                    this.userAnswer = this.state.mcqcorrectAnswerReview
                }

                if (this.userAnswer || this.props.question.type === 'Ranking') {

                    if (this.props.question.type === 'Ranking') {
                        this.userAnswer = this.state.rankingOptionList.map(item => item.name)
                    }
                    let assignmentId = this.props.match.params.assignmentId !== undefined && this.props.match.params.assignmentId !== null && this.props.match.params.assignmentId !== "" ? this.props.match.params.assignmentId : ""
                    let reqData = {
                        userAnswer: this.userAnswer,
                        questionId: question_id,
                        user_id: getCurrentUserId(),
                        assignment_id: assignmentId,
                        hints_used: this.state.nonfrqhintdata,
                        problem_source: this.state.problem_source,
                        boardId: this.props.location.state.boardId,
                        final_submission: 0,
                        optionselected: 0,
                        qtype: this.props.question.type,
                        fromTestPaper: 0

                    }
                    checkChallengeAnswer(reqData).then(res => {
                        this.setState({ loader: false })
                        let modalContent = null;
                        let finaldata = []
                        let modalTitle = '';
                        let modalCustomButton = null;
                        if (res.data.status === 'correct') {
                            modalContent = (
                                <div className='answer-model-correct d-flex justify-content-center'>
                                    <span className='correct-answer mr-2'></span>
                                    <h5>Correct Answer</h5>
                                </div>
                            )
                        } else {

                            modalTitle = (
                                <div className='d-flex align-items-center'>
                                    <span className='incorrect-answer mr-2'></span>
                                    <h4 className='m-0'>"This isn’t the correct answer."</h4>
                                </div>);


                            res.data.data !== undefined && res.data.data.length > 0 && res.data.data.map(item => {
                                let contentdata = (
                                    <>
                                        {
                                            item.remedial_text !== "" && item.remedial_text !== '' && item.remedial_text !== null ? (
                                                <>
                                                    <h5>Explanation:</h5>
                                                    <span className='d-block'>
                                                        <p dangerouslySetInnerHTML={{ __html: item.remedial_text }}></p>
                                                    </span>
                                                </>) : null
                                        }
                                        {
                                            item.remedial_source_url !== "" && item.remedial_source_url !== '' && item.remedial_source_url !== null ?
                                                (<>
                                                    <h5>Recommended Resources:</h5>
                                                    <p className='mb-4'>
                                                        <a href={item.remedial_source_url} target='_blank' rel="noopener noreferrer">
                                                            <p dangerouslySetInnerHTML={{ __html: item.remedial_source_title ? item.remedial_source_title : item.remedial_source_url }}></p></a></p>
                                                </>) : null
                                        }


                                    </>
                                )
                                finaldata.push(contentdata)
                            })
                            const tutorModalContent = (
                                <div className='text-center'>
                                    <img src={IconNotification} alt='NotificationIcon' />
                                    <div className='iconNotification'>Would you like help from a tutor?</div>
                                </div>
                            );
                            const tutorModalButton = (
                                <div className='modal-button text-center'>
                                    {isRiceUser() ?
                                        (<Button clicked={this.oenNotAvailablePopUp} className="mr-2 yellow-btn">Ok</Button>)
                                        :
                                        (<Button clicked={this.openVCCloseModal} className="mr-2 yellow-btn">Ok</Button>)
                                    }
                                    <Button clicked={this.closeModal} className="mr-2 grey-btn">No Thanks</Button>
                                </div>
                            );
                            modalCustomButton = (
                                <div className='modal-button text-center'>
                                    <Button clicked={(event) => this.openModal(event,
                                        null,
                                        tutorModalContent,
                                        tutorModalButton,
                                        false,
                                        true)} tooltip="Start a live video call with a human tutor. Share your board to collaborate." className="mr-2 yellow-btn">Ask Tutor</Button>
                                    <Button clicked={this.closeModal} className="mr-2 yellow-btn">Try Again</Button>
                                </div>
                            )
                        }

                        if (res.data.status !== 'correct') {
                            modalContent = (
                                <div className='answer-modal-incorrect'>
                                    {finaldata}
                                </div>
                            )
                        }

                        this.openModal(event,
                            modalTitle,
                            modalContent,
                            modalCustomButton,
                            false,
                            true);
                        // this.submitModalClose = true;
                    }).catch(err => {
                        this.setState({ loader: false })
                        console.error(err.message);
                        alert('Error occurred while submitting the answer.');
                    })
                } else {
                    this.setState({ loader: false })
                    //alert('Please answer the question.');
                    this.setState({ studentAnswer: "Please Select Answer First!" }, () => {
                        this.setState({ issubmitdialog: true, validationMessage: true, dButtonText: "Ok" })
                    })
                }
            }
        }
        else {
            debugger;
            this.setState({ loader: true })
            let modalBody = null
            let customButton = null;
            if (this.props.location.state) {
                let searchParams = new URLSearchParams(this.props.location.search);
                let teacherStudentId = searchParams.get('student')
                let assignmentId = this.props.match.params.assignmentId !== undefined && this.props.match.params.assignmentId !== null && this.props.match.params.assignmentId !== "" ? this.props.match.params.assignmentId : ""
                let is_finalsubmission = 1
                if ((assignmentId === undefined || assignmentId === null || assignmentId === "") && this.state.problem_source === Problem_source.interactiveproblems) {
                    is_finalsubmission = 0
                }
                checkEquation({
                    'board': this.props.location.state.boardId,
                    'userId': this.props.teacher ? teacherStudentId : getCurrentUserId(),
                    'challengeMapId': this.props.location.state.challengeMapId,
                    'hintUsed': this.props.location.state.hintUsed ? 1 : 0,
                    'is_finalsubmission': is_finalsubmission,
                    'assignment_id': assignmentId,
                    'problem_source': this.state.problem_source
                }).then(async res => {
                    this.setState({ loader: false })
                    toast("Your problem is submitted successfully!", {
                        type: toast.TYPE.INFO,
                        position: toast.POSITION.TOP_RIGHT
                    })
                    //}


                    // modalBody = (
                    //     <h5>Problem Submitted</h5>
                    // )
                    // this.submitModalClose = true;
                    // this.openModal(event, 'Submit Final Answer', modalBody, customButton, customButton ? null : true, false);
                })
                // } else {
                //     modalBody = (
                //         <p>Challenge is not completed. Are you sure you want to submit?</p>
                //     )
                //     customButton = (
                //         <div className='modal-button text-center'>
                //             <Button clicked={() => this.questionChallengeClickHandler('no')} className="mr-2 yellow-btn">No</Button>
                //             <Button clicked={() => this.questionChallengeClickHandler('yes')} className="mr-2 yellow-btn">Yes</Button>
                //         </div>
                //     )
                // }
            } else {
                modalBody = (
                    <p>Challenge is not completed. Are you sure you want to submit?</p>
                )
                customButton = (
                    <div className='modal-button text-center'>
                        <Button clicked={() => this.questionChallengeClickHandler('no')} className="mr-2 yellow-btn">No</Button>
                        <Button clicked={() => this.questionChallengeClickHandler('yes')} className="mr-2 yellow-btn">Yes</Button>
                    </div>
                )
                this.openModal(event, 'Submit Final Answer', modalBody, customButton, customButton ? null : true, false);
            }

            /// Open Feedback pop up to submit feedback 
            // this.setState({ show: true })
        }
        // }
    }

    oenNotAvailablePopUp = () => {
        let modalContent =
            <>
                <div className='text-center'>
                    <img src={Acc2eImg} className='askTutorImageContainer' alt='NotificationIcon' />
                    <div className='iconNotification'>This service is not available for your institution for now.</div>
                </div>
            </>

        var customButton = <div className='modal-button text-center'></div>

        this.openModal(this.event,
            null,
            modalContent,
            customButton,
            false,
            false);
    }

    frqsubmitAftercompleteAttempt = (board, challengeMapId, hintUsed, assignment_id, problem_source, event, trialcount, modalBody, custombutton) => {
        debugger
        let searchParams = new URLSearchParams(this.props.location.search);
        this.setState({ loader: true })
        let teacherStudentId = searchParams.get('student')
        checkEquation({
            'board': board,
            'userId': this.props.teacher ? teacherStudentId : getCurrentUserId(),
            'challengeMapId': challengeMapId,
            'hintUsed': hintUsed,
            'is_finalsubmission': 1,
            'assignment_id': assignment_id,
            'problem_source': problem_source
        }).then(async res => {
            this.setState({ loader: false })
            this.openModal(event, trialcount, modalBody, custombutton, false, true)
        })
    }

    calculateGradesForPTFRQ = (event) => {
        this.setState({ loader: true })
        // debugger;
        let isEquationOnBoard = this.props.location.state.checkEquationBoard();
        if (isEquationOnBoard) {
            let trialCounter = this.state.totalGradeCalculationTrial
            if (trialCounter <= this.props.totalTrials) {
                let assignmentId = this.props.match.params.assignmentId !== undefined && this.props.match.params.assignmentId !== null && this.props.match.params.assignmentId !== "" ? this.props.match.params.assignmentId : ""
                const reqData = {
                    "board": this.props.location.state.boardId,
                    "userId": getCurrentUserId(),
                    "challengeMapId": this.props.location.state.challengeMapId,
                    "hintUsed": this.props.location.state.hintUsed ? 1 : 0,
                    "is_finalsubmission": 0,
                    "assignment_id": assignmentId,
                    "problem_source": this.state.problem_source
                }
                CalculatePTFRQGrade(reqData).then(res => {
                    if (res.data !== undefined && res.data !== null) {

                        this.state.totalGradeCalculationTrial = trialCounter + 1
                        this.setState({ totalGradeCalculationTrial: trialCounter + 1 })

                        const reqGetData = {
                            params: {
                                "user_id": getCurrentUserId(),
                                "challenge_id": this.props.challengeId.split('c')[1],
                                "assignment_id": assignmentId,
                            }
                        }

                        getCalculatePTFRQGrade(reqGetData).then(res => {
                            if (res.data !== undefined) {
                                this.setState({ lastCalculatedGrades: res.data.totalGrades, loader: false })
                                let modalBody = (
                                    <Aux>
                                        <div>
                                            <ul>
                                                <li style={{ fontWeight: 'bold', fontSize: '10pt' }}>
                                                    Score : {res.data.totalGrades}
                                                </li>
                                            </ul>
                                            {this.state.totalGradeCalculationTrial === (this.props.totalTrials - 1) ?
                                                <div style={{ fontStyle: 'italic', fontWeight: 'bold', fontSize: '16px' }}>
                                                    Next trial will be your last trial to check your score. Post that the answer will be auto-submitted.
                                                </div>
                                                :
                                                <>
                                                    {
                                                        this.state.totalGradeCalculationTrial === this.props.totalTrials ?
                                                            <Aux>
                                                                <div style={{ fontStyle: 'italic', fontWeight: 'bold', fontSize: '16px' }}>
                                                                    <div className='iconNotification'>You have completed all trials for this question. The current answer will be auto-submitted now.</div>
                                                                </div>
                                                            </Aux>
                                                            : null
                                                    }
                                                </>
                                            }
                                        </div>
                                    </Aux>
                                )
                                let modalCustomButton = (
                                    <div className='modal-button text-center'>
                                        <Button clicked={(e) => this.closeCalculateGradeModalHandler(e)}
                                            tooltip="Ok">Ok</Button>

                                    </div>
                                )

                                if (this.state.totalGradeCalculationTrial === this.props.totalTrials && this.props.isGraded) {
                                    this.setState({ setShowSolutionBtnVisibility: true })
                                }

                                if (parseInt(trialCounter) !== (this.props.totalTrials - 1)) {
                                    this.openModal(event, "Attempt " + this.state.totalGradeCalculationTrial + "/" + this.props.totalTrials + "", modalBody, modalCustomButton, false, true)
                                }
                                if (parseInt(trialCounter) === (this.props.totalTrials - 1)) {
                                    let assignmentId = this.props.match.params.assignmentId !== undefined && this.props.match.params.assignmentId !== null && this.props.match.params.assignmentId !== "" ? this.props.match.params.assignmentId : ""
                                    this.frqsubmitAftercompleteAttempt(this.props.location.state.boardId, this.props.location.state.challengeMapId
                                        , this.props.location.state.hintUsed ? 1 : 0, assignmentId, this.state.problem_source
                                        , event, "Attempt " + this.state.totalGradeCalculationTrial + "/" + this.props.totalTrials + "", modalBody, modalCustomButton)
                                }
                            }
                        })
                    }
                }).catch(err => {
                    this.setState({ loader: false })
                    console.error(err.message);
                    toast("Some error occured!", {
                        type: toast.TYPE.ERROR,
                        position: toast.POSITION.BOTTOM_RIGHT
                    })
                })
            }
            else {
                this.setState({ loader: true })
                let modalBody = (
                    <Aux>
                        <div className='text-center modal-content' style={{ display: "block" }}>
                            <div style={{ position: 'absolute' }} className='iconNotification'>You have completed all trials for this problem.</div>
                        </div>
                    </Aux>
                )

                this.openModal(event, "Attempts Exceeded ", modalBody, false, true, true)
                this.setState({ setShowSolutionBtnVisibility: true, loader: false })
            }
        }
        else {
            this.setState({ loader: false })
        }


    }

    handleClose = () => {
        this.setState({ show: false });
    }

    getHint = (event) => {
        if (!this.props.anyProblem) {
            this.setState({ loader: true, bannerOpen: false, openFeedbackModal: false })
            if (this.props.location.state.challengeMapId) {
                if (this.props.question.type === 'FRQ') {
                    let assignmentId = this.props.match.params.assignmentId !== undefined && this.props.match.params.assignmentId !== null && this.props.match.params.assignmentId !== "" ? this.props.match.params.assignmentId : ""
                    let reqData = {
                        boardId: this.props.location.state.boardId,
                        user_id: getCurrentUserId(),
                        challengeMapId: this.props.location.state.challengeMapId,
                        hintCount: this.state.currentHintIndex,
                        prevsiousHintRemedialText: this.state.hintRemedialText,
                        problem_source: this.state.problem_source,
                        assignment_id: assignmentId
                    }
                    fetchChallengeHint({ params: reqData }).then(res => {
                        this.setState({ loader: false })
                        console.log("********************HINT")
                        console.log(this.state.hintLastMatchedCheckpointId)
                        console.log(res.data.last_matched_checkpoint_id)

                        let finalsubstitutionslist = ""
                        if (!isNil(res.data.substitution_map)) {
                            finalsubstitutionslist = obtainSubstitution(res.data.substitution_map)
                        }

                        if (res.data.hint_remedial_text || res.data.hint_remedial_source) {
                            if (!(this.state.hintLastMatchedCheckpointId === res.data.last_matched_checkpoint_id)) {
                                console.log("HINT1")
                                this.setState((state, props) => ({
                                    currentHintIndex: 1,
                                    hintRemedialText: res.data.hint_remedial_text,
                                    hintRemedialSource: res.data.hint_remedial_source,
                                    hintLastMatchedCheckpointId: res.data.last_matched_checkpoint_id,
                                    totalHints: res.data.total_hints_for_the_challenge,
                                }));
                            }
                            else if (this.state.hintLastMatchedCheckpointId ===
                                res.data.last_matched_checkpoint_id)// && !(this.state.hintRemedialSource === res.data.hint_remedial_source && this.state.hintRemedialText === res.data.hint_remedial_text))
                            {
                                console.log("HINT2");
                                this.setState((state, props) => ({
                                    currentHintIndex: res.data.total_hints_for_the_challenge > state.currentHintIndex ? state.currentHintIndex + 1 : state.currentHintIndex,
                                    hintRemedialText: res.data.hint_remedial_text,
                                    hintRemedialSource: res.data.hint_remedial_source,
                                    hintLastMatchedCheckpointId: res.data.last_matched_checkpoint_id,
                                    totalHints: res.data.total_hints_for_the_challenge,
                                }));

                            }

                            this.openHintResponseModel(event, finalsubstitutionslist, res, this.state.totalHints)

                            if (this.props.location.state.hintUsed) {
                                this.props.location.state.hintUsed = true;
                            } else {
                                this.props.location.state = {
                                    ...this.props.location.state,
                                    hintUsed: true
                                }
                            }
                        } else if (res.data.message) {
                            toast(res.data.message, {
                                type: toast.TYPE.INFO,
                                position: toast.POSITION.BOTTOM_RIGHT
                            })
                        }
                    }).catch(err => {
                        this.setState({ loader: false })
                        console.error(err.message);
                        // alert('Error occurred while fetching the hint')
                        toast("No more hints found!", {
                            type: toast.TYPE.INFO,
                            position: toast.POSITION.BOTTOM_RIGHT
                        })
                    })
                }
                else {
                    let assignmentId = this.props.match.params.assignmentId !== undefined && this.props.match.params.assignmentId !== null && this.props.match.params.assignmentId !== "" ? this.props.match.params.assignmentId : ""
                    let reqData = {
                        boardId: this.props.location.state.boardId,
                        user_id: getCurrentUserId(),
                        challengeMapId: this.props.location.state.challengeMapId,
                        problem_source: this.state.problem_source,
                        assignment_id: assignmentId
                    }
                    fetchChallengeHintnonfrq({ params: reqData }).then(res => {
                        this.setState({ loader: false })

                        if (res.data !== undefined && res.data !== null && res.data.length > 0) {
                            let contentdatafinal = []
                            this.setState({ nonfrqhintdata: res.data })
                            if (res.data[0].remedial_text === "" || res.data[0].remedial_text === null || res.data[0].remedial_text === undefined) {
                                toast("No more hints found!", {
                                    type: toast.TYPE.INFO,
                                    position: toast.POSITION.BOTTOM_RIGHT
                                })
                            }
                            else {
                                res.data.forEach((rem) => {
                                    let contentdata = (
                                        <Aux>
                                            <p className='modal-p mt-3' dangerouslySetInnerHTML={{ __html: rem.remedial_text ? rem.remedial_text : '' }}></p>
                                            <a className='mt-2 ' href={rem.remedial_source_url} target='_blank' rel="noopener noreferrer" >{rem.remedial_source_title ? rem.remedial_source_title : rem.remedial_source_url}</a>
                                        </Aux>
                                    )
                                    contentdatafinal.push(contentdata)
                                })
                                let modalBody = (
                                    <>{contentdatafinal}</>
                                );
                                this.openModal(event, 'Hint', modalBody, null, true, true);
                            }


                            if (this.props.location.state.hintUsed) {
                                this.props.location.state.hintUsed = true;
                            } else {
                                this.props.location.state = {
                                    ...this.props.location.state,
                                    hintUsed: true
                                }
                            }
                        } else if (res.data.message) {
                            toast(res.data.message, {
                                type: toast.TYPE.INFO,
                                position: toast.POSITION.BOTTOM_RIGHT
                            })
                        }
                    }).catch(err => {
                        console.error(err.message);
                        this.setState({ loader: false })
                        // alert('Error occurred while fetching the hint')
                        toast("No more hints found!", {
                            type: toast.TYPE.INFO,
                            position: toast.POSITION.BOTTOM_RIGHT
                        })
                    })
                }
            }
        }
        else {
            if (this.state.showProgressSidebar === true) {
                this.setState({ showProgressSidebar: false })
            }
            this.setState({ loader: true })

            let params = new URLSearchParams();
            params.append('InputMessage', 'show me a hint');
            params.append('hintCount', this.state.currentHintIndex);
            params.append('isNew', false);
            params.append('sessionid', this.props.session_id);
            params.append('prevsiousHintRemedialText', this.state.hintRemedialText);
            params.append('userid', getCurrentUserId());

            askAnyProblemQuestion(params).then(res => {
                this.setState({ loader: false })

                if (res.data.response !== undefined && res.data.response !== null && res.data.response !== "") {
                    let finalresponse = formatAnyProblemAPIResponse(res.data.response)

                    let contentdata = (
                        <Aux>
                            <div className='modal-p mt-3'>
                                <MathJaxRenderer math={finalresponse}></MathJaxRenderer>
                            </div>

                        </Aux>
                    )
                    let modalBody = (
                        <>{contentdata}</>
                    );

                    this.openModal(event, 'Hint', modalBody, null, true, true);
                }
                else if (!isNil(res.data.hint_remedial_text)) {

                    let finalsubstitutionslist = ""
                    if (!isNil(res.data.substitution_map)) {
                        finalsubstitutionslist = obtainSubstitution(res.data.substitution_map)
                    }

                    this.openHintResponseModel(event, finalsubstitutionslist, res)

                    if (res.data.hint_remedial_text || res.data.hint_remedial_source) {
                        if (!(this.state.hintLastMatchedCheckpointId === res.data.last_matched_checkpoint_id)) {
                            console.log("HINT1")
                            this.setState((state, props) => ({
                                currentHintIndex: 1,
                                hintRemedialText: res.data.hint_remedial_text,
                                hintRemedialSource: res.data.hint_remedial_source,
                                hintLastMatchedCheckpointId: res.data.last_matched_checkpoint_id,
                                totalHints: res.data.total_hints_for_the_challenge,
                            }));
                        }
                        else if (this.state.hintLastMatchedCheckpointId ===
                            res.data.last_matched_checkpoint_id)// && !(this.state.hintRemedialSource === res.data.hint_remedial_source && this.state.hintRemedialText === res.data.hint_remedial_text))
                        {
                            console.log("HINT2");
                            this.setState((state, props) => ({
                                currentHintIndex: res.data.total_hints_for_the_challenge > state.currentHintIndex ? state.currentHintIndex + 1 : state.currentHintIndex,
                                hintRemedialText: res.data.hint_remedial_text,
                                hintRemedialSource: res.data.hint_remedial_source,
                                hintLastMatchedCheckpointId: res.data.last_matched_checkpoint_id,
                                totalHints: res.data.total_hints_for_the_challenge,
                            }));
                        }

                        this.openHintResponseModel(event, finalsubstitutionslist, res, this.state.totalHints)

                        if (this.props.location.state.hintUsed) {
                            this.props.location.state.hintUsed = true;
                        } else {
                            this.props.location.state = {
                                ...this.props.location.state,
                                hintUsed: true
                            }
                        }
                    } else if (res.data.message) {
                        toast(res.data.message, {
                            type: toast.TYPE.INFO,
                            position: toast.POSITION.BOTTOM_RIGHT
                        })
                    }
                }
            }).catch((err) => {
                return err;
            })
        }
    }

    openHintResponseModel = (event, finalsubstitutionslist, res) => {

        let modalBody = (
            <Aux>
                <p className='modal-p' dangerouslySetInnerHTML={{ __html: res.data.hint_remedial_text ? res.data.hint_remedial_text : '' }}></p>
                <a className='mt-2' href={res.data.hint_remedial_source} target='_blank' rel="noopener noreferrer" >{res.data.hint_remedial_resource_display_name ? res.data.hint_remedial_resource_display_name : res.data.hint_remedial_source}</a>

                {
                    finalsubstitutionslist !== "" && finalsubstitutionslist !== '' && finalsubstitutionslist !== undefined && finalsubstitutionslist !== null && finalsubstitutionslist !== "null" && finalsubstitutionslist !== "null" ? (
                        <>
                            <div style={{ fontSize: "11px", marginTop: "10px", maxWidth: "500px" }}>
                                {substituteText} {finalsubstitutionslist}
                            </div>
                        </>
                    ) : null
                }
            </Aux>
        );

        let customButton = (
            <div className='modal-button text-center d-flex justify-content-center'>
                <div>
                    <button
                        type='button'
                        className='modal-footer-button btn mx-auto'
                        onClick={this.closeHIntModal}>OK</button>
                </div>
                {
                    this.props.question.type === "FRQ" ? (
                        <div style={{ marginLeft: "5px" }}>
                            <button
                                tooltip="Get further explanations from the interactive AI tutor."
                                type='button'
                                className='modal-footer-button btn mx-auto'
                                onClick={(e) => this.askAItutorHandlerhint(e, res.data.hint_remedial_text)}>Ask AI Tutor</button>
                        </div>) : null
                }

            </div>
        )

        this.openModal(event, 'Hint', modalBody, customButton, customButton ? false : true, true);
    }

    componentWillUnmount() {
        if (this.loadTranscriptTimer) {
            clearInterval(this.loadTranscriptTimer)
        }
        try {
            window.$(document).unbind('scroll');
        } catch (e) {
            console.log(e.message);
        }

        let feedbackdiv = document.getElementById("submitFeedback")
        if (this.props.question.type === "FRQ") {
            feedbackdiv.style.visibility = "visible"
        }
    }

    listenScrollEvent = () => {
        // Keep buttons above footer
        var socialFloat = document.querySelector('.whiteboard-bottom-buttons')
        var footer = document.querySelector('.footer');

        function getRectTop(el) {
            var rect = el.getBoundingClientRect();
            return rect.top;
        }

        if (socialFloat && footer) {
            if ((getRectTop(socialFloat) + document.body.scrollTop) + socialFloat.offsetHeight >= (getRectTop(footer) + document.body.scrollTop) - 10)
                socialFloat.style.position = 'absolute';
            if (document.body.scrollTop + window.innerHeight < (getRectTop(footer) + document.body.scrollTop))
                socialFloat.style.position = 'fixed'; // restore when scroll up}
        }
    }

    anyPoblemStatementHandler = (statement) => {
        this.setState({ anyProblemStatement: statement, actionBtnDisabled: false, sketchpadDivcolor: "#fff" })
    }

    setSidebarContent = (content, header = 'Your progress so far:') => {
        content = <>
            <p className='sidebar-heading mt-2 pl-3'>{header}</p>
            {content}
        </>
        this.progressSidebarContent = content
        this.setState({
            showProgressSidebar: true, progressbarTitle: "Evaluation"
        })
    }

    setKnowledgeGraphSidebarContent = (content, header = 'Your progress so far:') => {
        content = <>
            <p className='sidebar-heading mt-2 pl-3'>{header}</p>
            {content}
        </>
        this.setState({ knowledgeGraphSidebarContent: content });
        this.setState({
            showKnowledgeGraphSidebar: true,
        })
    }

    submitEquations = () => {

        // Commented as per Khyati's request to show it when the board has more then 5 equations.so the code is in sketchpadboard now.
        // if (!this.state.checkMyWorkClickedOnce) {
        //     this.setState({ checkMyWorkClickedOnce: true })
        //     toast('Please streamline your steps just the way you would when showing it to tutor. This also helps reduce AI tutors response time as you check your work!', {
        //         type: toast.TYPE.INFO,
        //         title: "Is your solution streamlined ?",
        //         position: toast.POSITION.BOTTOM_RIGHT,
        //         autoClose: false
        //     })
        // }

        this.resetNextStepCounter();
        this.setState({ hintRemedialText: "", currentHintIndex: 0, bannerOpen: false, openFeedbackModal: false, checkMyWorkCounter: parseInt(this.state.checkMyWorkCounter) + 1 });
        if (this.props.location.state.submitFunction) {
            this.props.location.state.submitFunction();
        }
    }

    getKnowledgeGraph = () => {
        if (this.props.location.state.knowledgeGraphFunction) {
            this.props.location.state.knowledgeGraphFunction();
        }
    }
    closeProgressSidebar = () => {

        this.resetNextStepCounter();
        this.setState((prevState) => ({ showProgressSidebar: false }));

        if (this.state.checkMyWorkCounter === 1) {
            this.setState({ bannerOpen: true, openFeedbackModal: false })
        }
        // else {
        //     let counter = this.state.checkMyWorkCounter
        //     if (counter % 3 === 0) {
        //         this.setState({ bannerOpen: true, openFeedbackModal: false })
        //     }
        // }



    }

    closeKnowledgeGraphSidebar = () => {
        this.setState({ knowledgeGraphSidebarContent: '' });
        this.setState((prevState) => ({ showKnowledgeGraphSidebar: false }));
    }
    closeSessionSynopsis = () => {
        this.setState({ showSessionSynopsis: false });
    }

    svg2img = svg => {
        var placeholder = document.createElement('div');
        placeholder.innerHTML = svg
        var elem = placeholder.firstChild;

        var xml = new XMLSerializer(svg).serializeToString(elem);
        var svg64 = ''
        try {
            svg64 = btoa(xml); //for utf8: btoa(unescape(encodeURIComponent(xml)))
        } catch (e) {
            svg64 = btoa(unescape(encodeURIComponent(xml)))
        }
        var b64start = 'data:image/svg+xml;base64,';
        var image64 = b64start + svg64;
        return image64;
    }

    resetNextStepCounter = () => {
        this.setState({ nextStepCounter: 0, totalStepCounter: 0 })
    }

    handleaskAIBySketchpad = () => {
        this.setState({ askAIquestion: "", askAIBgColor: false, equationClass: "", equationClick: false })
    }
    handleaskAItutor = (e, inputLatex, equationstatus, mathml) => {
        this.askaicheckmywork()
        this.forhintAiTutorfalsehandler()
        document.getElementById('root').scrollTo(0, document.body.scrollHeight);

        let eqmathML = "<p>" + mathml + "</p>"
        let containerclass = ""
        if (equationstatus === "correct") {
            containerclass = equationContainerClass.correctContainer
        }
        this.setState({ askAIquestion: eqmathML, askAIBgColor: true, equationClass: containerclass, equationClick: true })


    }

    showNextStepHandler = async (ev) => {
        //ev.preventDefault();
        if (!this.props.anyProblem) {
            this.setState({ loader: true, bannerOpen: false, openFeedbackModal: false })
            let hasNextStep = true;
            const nextStepCounter = this.state.nextStepCounter;
            if (nextStepCounter != 0) {
                if (nextStepCounter >= this.state.totalStepCounter) {
                    hasNextStep = false;
                    toast('No next step available.', {
                        type: toast.TYPE.INFO,
                        position: toast.POSITION.BOTTOM_RIGHT,
                        autoClose: 1500
                    })
                }
            }
            if (!hasNextStep) return;
            const params = new URLSearchParams(window.location.search);
            this.progressSidebarContent = null;
            // Uncomment when use aww board
            // const boardId = params.get('awwBoard');

            const boardId = this.props.location.state.boardId;
            const challengeMapId = this.props.location.state.challengeMapId;


            let content = null;
            let finalsubstitutionslistnext = ""
            if (boardId && challengeMapId && hasNextStep) {
                let reqData = {
                    'userId': getCurrentUserId(),
                    'board': boardId,
                    'challengeMapId': challengeMapId,
                    'currentstepno': nextStepCounter,
                    'problem_source': this.state.problem_source
                }
                let latex = null
                let explanation = null

                if (this.props.location.state.challengeCompleted) {
                    this.setState({ loader: false })
                    content = <p>No step remaining.</p>
                } else {
                    await getNextStep(reqData).then(res => {
                        this.setState({ loader: false })
                        if (res.status === 200) {
                            latex = res.data.nextStep;
                            explanation = parse(res.data.explanation);

                            if (!isNil(res.data.substitute_map)) {
                                finalsubstitutionslistnext = obtainSubstitution(res.data.substitute_map)
                            }

                            this.setState({ nextStepCounter: this.state.nextStepCounter + 1, totalStepCounter: res.data.totalstepsno })
                        } else if (res.status === 204) {
                            content = <p>No next step to display.</p>
                        }

                    }).catch(err => {
                        console.error(err.message)
                    })
                    if (latex) {

                        content = (
                            <>
                                <span>{explanation}</span>
                                <MathJaxRenderer math={'$$' + latex + '$$'}></MathJaxRenderer>

                            </>
                        )


                    }
                }



                if (content) {
                    this.progressSidebarContent = (
                        <div className='d-flex flex-column mx-3 my-3'>
                            <h5><u>Next Step</u>:</h5>
                            {content}
                            {
                                finalsubstitutionslistnext !== "" && finalsubstitutionslistnext !== '' && finalsubstitutionslistnext !== undefined && finalsubstitutionslistnext !== null && finalsubstitutionslistnext !== "null" && finalsubstitutionslistnext !== "null" ? (
                                    <>
                                        <div style={{ fontSize: "11px", marginTop: "10px", maxWidth: "500px" }}>
                                            {substituteText} {finalsubstitutionslistnext}
                                        </div>

                                    </>
                                ) : null
                            }
                        </div>
                    )
                }
                if (this.progressSidebarContent !== null) {
                    this.setState((prevState) => ({ showProgressSidebar: true, progressbarTitle: "Show Next Step" }));
                }
            }
        }
        else {
            this.setState({ loader: true })


            let params = new URLSearchParams();
            params.append('InputMessage', 'Show Next Step');
            params.append('isNew', false);
            params.append('sessionid', this.props.session_id);
            params.append('userid', getCurrentUserId());

            askAnyProblemQuestion(params).then(res => {
                this.setState({ loader: false })
                if (res.data.response !== undefined && res.data.response !== null && res.data.response !== "") {

                    let finalresponse = formatAnyProblemAPIResponse(res.data.response)

                    let contentdata = (
                        <Aux>
                            <div className='modal-p mt-3'>
                                {/* <MathComponent mathml={res.data.response} /> */}
                                <MathJaxRenderer math={finalresponse}></MathJaxRenderer>
                            </div>
                            {/* <p className='modal-p mt-3' dangerouslySetInnerHTML={{ __html: res.data.response }}></p> */}
                        </Aux>
                    )
                    let modalBody = (
                        <>{contentdata}</>
                    );

                    this.openModal(ev, 'Next Step', modalBody, null, true, true);
                }

            }).catch((err) => {
                return err;
            })
        }

    }
    fullScreenHandler = () => {
        this.setState({ upperSectionHide: !this.state.upperSectionHide }, () => {


            if (this.state.upperSectionHide) {
                let heightd = window.innerHeight

                let headerheight = document.getElementById('div_header').clientHeight
                let footerheight = document.getElementById('div_footer').clientHeight
                let finalheight = parseInt(heightd)//- (parseInt(headerheight) + parseInt(footerheight) + 200)
                //let eheight= parseInt(finalheight) +  50

                finalheight = parseInt(finalheight) - 100
                this.setState({ isScreenFull: true, cHeight: `${finalheight}px`, eHeight: `${finalheight}px` })
            }
            else {
                this.setState({ isScreenFull: false, cHeight: "90vh", eHeight: "570px" })
            }
        })
    }

    showEntireSolution = async (ev) => {
        // debugger;
        if (!this.props.anyProblem) {
            this.resetNextStepCounter();
            this.setState({ loader: true, bannerOpen: false, openFeedbackModal: false })
            //ev.preventDefault();
            let content = null;
            let remedial_resources = []
            const challengeMapId = this.props.location.state.challengeMapId;
            this.progressSidebarContent = null;

            if (this.props.question.type.toLowerCase() === "text") {
                let challengeId = this.props.question.id.slice(1, this.props.question.id.length);
                const reqData = { params: { 'ChallengeId': challengeId } }

                this.state.isText = true
                content = (
                    <Aux>
                        <div className="progress-bar-container">
                            <Spinner animation="border" role="status" />
                        </div>
                    </Aux>
                )

                this.setState({ showSolution: content });
                //    content = (
                //     <Aux>
                //       <div className="progress-bar-container">
                //         <Spinner animation="border" role="status" />
                //       </div>
                //     </Aux>
                //   )

                //   this.openModal(ev,"Solution",content,null,false,true)   

                getTutorAnswer(reqData).then(res => {
                    this.setState({ loader: false })
                    if (res.status === 200) {
                        this.state.isText = true
                        if (res.data.Answers.length > 0) {
                            let answerData = res.data.Answers[0];
                            content = (
                                <div className='bg-light p-1' >
                                    <div className='showSolutionTitle'>
                                        Model Answer
                                        <a className='float-right text-secondary' onClick={(e) => this.closeAnswerDeviders("showSolution")}>
                                            {/* <i class="fa fa-close" aria-hidden="true"></i> */}
                                            <CloseIcon className='' />
                                        </a>
                                    </div>
                                    <div className='divFacts'>
                                        {(answerData.Answer !== null ? answerData.Answer.split('\n').map(paragraph =>
                                            <p className='feedbackmodal p'>
                                                {paragraph.split('\n').reduce((total, line) => [total, <br />, line])}
                                            </p>
                                        ) : "")}
                                    </div>
                                    {/* <div style={{ marginBottom: '0.5rem!important', border: '#ccc solid 1px', marginTop: '0.5rem!important' }}>
                                </div> */}
                                    <div style={{ width: '100%', height: '100%' }} className='graphdiv'>
                                        <div className='showSolutionTitle border-0'> Graph View : </div>
                                        {(answerData.Nodes !== null && answerData.Edges !== null ? <GraphComponent isPT={false} isauthordashboard={false} componentId="showSolution" chartType="basic" nodes={JSON.parse(answerData.Nodes)} edges={JSON.parse(answerData.Edges)}></GraphComponent> : "")}
                                    </div>
                                </div>
                            )

                            this.setState({ showSolution: content });
                            //this.openModal(ev,"Solution",content,null,false,true)               
                        }
                    }
                }).catch(err => {
                    this.setState({ loader: false })
                    this.closeAnswerDeviders("showSolution")
                    console.error(err.message);
                    alert('Error occurred while showing solution.');
                })
            }
            else {
                if (challengeMapId) {
                    let allEquations = null;
                    let challengeType = null;
                    let ui_json;
                    // let latexdata;
                    // let explanation;
                    let solution = []

                    await getEntireSolution({
                        params: {
                            'challengeMapId': challengeMapId,
                            'user_id': getCurrentUserId(),
                            'problem_source': this.state.problem_source,
                            'boardId': this.props.location.state.boardId
                        }
                    }).then(res => {

                        ui_json = res.data.ui_json;
                        remedial_resources = res.data.remedial_resources;
                        challengeType = res.data.challengeType;
                        solution = res.data.solution
                        if (res.data.challengeType !== 'frq') {
                            let correctAnswer = null;
                            if (res.data.challengeType === 'mcq') {
                                correctAnswer = res.data.solution[0]
                            } else if (res.data.challengeType === 'msq') {
                                correctAnswer = (
                                    <ul key='answer'>
                                        {res.data.solution.map((ans, index) => <li key={'msq' + index}>{ans}</li>)}
                                    </ul>
                                )
                            } else if (res.data.challengeType === 'ranking') {
                                correctAnswer = (
                                    <ol>
                                        {res.data.solution.map((ans, index) => <li key={'ranking' + index}>{ans}</li>)}
                                    </ol>
                                )
                            }
                            content = correctAnswer;
                        } else {
                            allEquations = res.data.solution;
                        }

                    }).catch(err => {
                        this.setState({ loader: false })
                        console.error(err.message);
                    })
                    if (allEquations && challengeType === "frq") {
                        let imgOfSolution = []
                        for (let equation of allEquations) {
                            const latexReqData = {
                                params: {
                                    format: 'svg',
                                    fontSize: '18px',
                                    latex: equation.latex
                                }
                            }
                            const response = await generateSVGFromMathML(latexReqData)
                            if (response.status === 200) {
                                const svg = response.data;
                                imgOfSolution.push({
                                    image: this.svg2img(svg),
                                    explanation: equation.explanation
                                });
                            } else {
                                console.error('Unable to convert latex to svg');
                            }
                        }
                        content = (
                            <Aux>
                                <div className="solutionGraphCotainer">
                                    {/* <p className="sidebar-heading mt-2">Solution Graph</p> */}
                                    {/* <div className="legendContainer">
                                        <div className="legendItemContainer">
                                            <div className="correct"></div>
                                            <span>Expected Step</span>
                                        </div>

                                        <div className="legendItemContainer">
                                            <div className="optional"></div>
                                            <span>Intermediate Step</span>
                                        </div>
                                    </div>
                                    <Flow readOnly={true} uiJson={ui_json} hasCheckMyWOrk={false} ></Flow> */}
                                    <div>
                                        {
                                            solution.map(sol => {
                                                return (
                                                    <>
                                                        <div className="mt-3 d-flex align-items-center eqfont modalscroll popupSectionTitlemediumlatex, eqpaddingshowsolution green">
                                                            <MathJaxRenderer math={`$$${sol.latex}$$`}></MathJaxRenderer>
                                                        </div>
                                                        <div className="mt-1 d-flex align-items-center eqfont modalscroll popupSectionTitlemediumlatex, eqpaddingshowsolution">
                                                            <MathJaxRenderer math={sol.explanation}></MathJaxRenderer>
                                                        </div>
                                                        <div className="mt-1 d-flex align-items-center eqfont modalscroll popupSectionTitlemediumlatex, eqpaddingshowsolution"> 
                                                            {`For more help,  `}<button className='linkbutton' tooltip="Get further explanations from the interactive AI tutor."
                                                            onClick={(e) => this.handleaskAItutor(e, sol.latex, "correct", sol.mathml)}>
                                                            <img style={{ height: '24px', width: '24px', color: '#4252AF', marginRight: '6px', marginLeft: "5px" }}
                                                                alt="Get further explanations from the interactive AI tutor."
                                                                src={AskAITutIcon} width="20px" height="20px" />Ask AI Tutor</button>
                                                        </div>
                                                        <div className='titleorcss'></div>
                                                    </>
                                                )

                                            })
                                        }
                                    </div>
                                </div>

                            </Aux>
                        )

                    } else if (content === null) {
                        content = (
                            <p>No solutions found</p>
                        )
                        // Show no solutions found
                    }

                    if (challengeType === 'frq') {
                        this.progressSidebarContent = (
                            <div className='d-flex flex-column mx-3 my-3 solutionContainer'>
                                {content}
                            </div>
                        )
                    } else {
                        this.progressSidebarContent = (
                            <div className='d-flex flex-column mx-3 my-3'>
                                <h5 className='mb-2'><u>Correct Answer</u>:</h5>
                                {parse(content)}

                                {remedial_resources !== null && remedial_resources.length > 0 ?
                                    <Aux>
                                        <h5 className='mb-2 mt-3'><u>Resources</u>:</h5>
                                        {remedial_resources.map(resource => {

                                            if (resource.remedial_source_url !== null && resource.remedial_source_url.length > 0) {
                                                return <a href={resource.remedial_source_url} target="_blank" rel="noopener noreferrer"><p dangerouslySetInnerHTML={{ __html: resource.remedial_source_title }}></p></a>
                                            }
                                            return null
                                        })}
                                    </Aux>
                                    : null}
                            </div>
                        )
                    }

                }
                if (this.progressSidebarContent !== null) {
                    this.setState((prevState) => ({ showProgressSidebar: true, loader: false, progressbarTitle: "Entire Solution" }));
                }
            }
        }
        else {
            this.setState({ loader: true })

            let params = new URLSearchParams();
            params.append('InputMessage', 'Show Full Solution');
            params.append('isNew', false);
            params.append('sessionid', this.props.session_id);
            params.append('userid', getCurrentUserId());

            askAnyProblemQuestion(params).then(res => {
                this.setState({ loader: false })
                if (res.data.response !== undefined && res.data.response !== null && res.data.response !== "") {
                    let finalresponse = formatAnyProblemAPIResponse(res.data.response)
                    let contentdata = (
                        <Aux>
                            <div className='modal-p mt-3'>
                                {/* <MathComponent mathml={res.data.response} /> */}
                                <MathJaxRenderer math={finalresponse}></MathJaxRenderer>
                            </div>

                        </Aux>
                    )
                    let modalBody = (
                        <>{contentdata}</>
                    );
                    this.openModal(ev, 'Show Full Solution', modalBody, null, true, true);

                } else if (!isNil(res.data.ui_json)) {

                    let contentdata = (
                        <Aux>
                            <div className='modal-p mt-3'>
                                <div className="solutionGraphCotainer">
                                    <p className="sidebar-heading mt-2">Solution Graph</p>
                                    <div className="legendContainer">
                                        <div className="legendItemContainer">
                                            <div className="correct"></div>
                                            <span>Expected Step</span>
                                        </div>

                                        <div className="legendItemContainer">
                                            <div className="optional"></div>
                                            <span>Intermediate Step</span>
                                        </div>
                                    </div>
                                    <Flow readOnly={true} uiJson={res.data.ui_json} hasCheckMyWOrk={false} ></Flow>
                                </div>
                            </div>

                        </Aux>
                    )
                    let modalBody = (
                        <>{contentdata}</>
                    );

                    this.openModal(ev, 'Show Full Solution', modalBody, null, true, true);

                }

            }).catch((err) => {
                return err;
            })

            //  this.setState({ show: true })
        }
    }

    handlefeedbackIdeal = (inactive = true) => {
        alert("11111111111")
    }

    handleOnIdle = (inactive = true) => {
        alert("11111111111")
        // perform stuff when user is idle
        if (!this.props.vcState && !this.state.showModal) {
            const modalContent = (
                <div className='text-center modal-content' style={{ display: "block" }}>
                    <img src={IconNotification} alt='NotificationIcon' style={{ width: "30px" }} />
                    {inactive ? <div className='iconNotification mt-2'>Hmm... You haven’t interacted for a while.</div> : null}
                    <div className='iconNotification'>Would you like help from a tutor?</div>
                </div>
            );
            const modalButton = (
                <div className='modal-button text-center'>
                    {this.props.question.type === 'FRQ' ?
                        <Button clicked={(event) => this.getHint(event)} className="mr-2 yellow-btn">Use Hint</Button>
                        : null}
                    <Button clicked={this.openVCCloseModal} className="mr-2 yellow-btn">Yes</Button>
                    <Button clicked={this.closeModal} className="mr-2 grey-btn">No Thanks</Button>
                </div>
            );
            this.openModal(null,
                null,
                modalContent,
                modalButton,
                false,
                true)
        }
    }

    showDiagnosticQuestionModal = (event, diagnosticQuestionObj, extraContent = null, extraContentButton = null) => {
        const onDiagnosticQuestionOptionChangeHandler = (e, diagnosticQuestionObj) => {
            this.setState({
                diagnosticQuestionOption: e.target.value
            }, () => {
                this.showDiagnosticQuestionModal(e, diagnosticQuestionObj)
            })
        }

        const submitDiagnosticQuestion = (e, diagnosticQuestionObj) => {

            if (!this.state.diagnosticQuestionOption) {
                toast('Please select any option.', {
                    type: toast.TYPE.ERROR,
                    position: toast.POSITION.BOTTOM_RIGHT
                })
                return
            }

            const reqData = {
                questionId: diagnosticQuestionObj.questionId,
                userResponse: this.state.diagnosticQuestionOption,
            }

            checkDiagnosticQuestionAnswer(reqData)
                .then(res => {

                    if (res.data.status === 'correct') {
                        if (this.state.diagnosticQuestions.length > 1 && this.state.diagnosticQuestionIndex < this.state.diagnosticQuestions.length - 1) {
                            // another question to show...
                            const extraContent = <div className='mt-2 green'>
                                <p>Seems that you know this concept well.</p>
                                <p>Let’s check another concept required to solve this problem.</p>
                            </div>

                            const extraContentButton = <div className='modal-button text-center'>
                                <Button
                                    clicked={(e) => {
                                        this.setState({
                                            diagnosticQuestionIndex: this.state.diagnosticQuestionIndex + 1,
                                            diagnosticQuestionOption: undefined,
                                        }, () => {
                                            this.showDiagnosticQuestionModal(e, this.state.diagnosticQuestions[this.state.diagnosticQuestionIndex])
                                        })
                                    }}
                                    className="mr-2 yellow-btn width-btn">Next Diagnostic Question</Button>
                            </div>
                            this.showDiagnosticQuestionModal(e, diagnosticQuestionObj, extraContent, extraContentButton)
                        } else {
                            const extraContent = <div className='mt-2 green'>
                                <p>Seems that you know this concept well.</p>
                                <p>I’ve checked the concepts I could think of, would you like to talk to a tutor?</p>
                            </div>

                            const extraContentButton = <div className='modal-button text-center'>
                                <Button
                                    clicked={this.closeModal}
                                    className="mr-2 yellow-btn width-btn">Return to Challenge</Button>
                                <Button tooltip="Start a live video call with a human tutor. Share your board to collaborate."
                                    clicked={this.openVCCloseModal}
                                    className="mr-2 yellow-btn">Ask Tutor</Button>
                            </div>
                            this.showDiagnosticQuestionModal(e, diagnosticQuestionObj, extraContent, extraContentButton)
                        }
                    } else {
                        const extraContent = <div className='mt-2'>
                            <div className="red mb-3">
                                <p>This isn’t the correct answer.</p>

                                {res.data.remedial_text ?
                                    <>
                                        <p>{res.data.remedial_text}</p>
                                    </>
                                    : null}

                                {res.data.remedial_resource ?
                                    <>
                                        <a href={res.data.remedial_resource} target='_blank' rel="noopener noreferrer"><p>{res.data.remedial_resource}</p></a>
                                    </>
                                    : null}
                            </div>
                            <span>Would you like to talk to a tutor?</span>
                            <Button tooltip="Start a live video call with a human tutor. Share your board to collaborate."
                                clicked={this.openVCCloseModal}
                                className="ml-2 yellow-btn">Ask Tutor</Button>

                        </div>

                        const extraContentButton = <div className='modal-button text-center'>
                            <Button
                                clicked={this.closeModal}
                                className="mr-2 yellow-btn width-btn">Return to Challenge</Button>
                        </div>

                        this.showDiagnosticQuestionModal(e, diagnosticQuestionObj, extraContent, extraContentButton)
                    }

                }).catch(err => {
                    console.error(err.message);
                    alert('Error occurred while submitting the answer.');
                })
        }

        let modalTitle = (
            <div className='d-flex align-items-center'>
                <h4 className='m-0'>Diagnostic Question</h4>
            </div>);

        let modalContent = (
            <div className='diagnostic-question-content'>
                <p>
                    <MathJaxRenderer math={diagnosticQuestionObj.question}></MathJaxRenderer>
                </p>

                {diagnosticQuestionObj.options.map((opt, index) => (
                    <Aux key={'a' + index}>
                        <input
                            type='radio'
                            name='option'
                            id={'opt' + index}
                            key={'k' + index}
                            value={opt}
                            checked={this.state.diagnosticQuestionOption === opt}
                            className='mr-2 summative-option'
                            onChange={(event) => onDiagnosticQuestionOptionChangeHandler(event, diagnosticQuestionObj)}
                            style={{ cursor: 'pointer' }} />
                        <label htmlFor={'opt' + index} key={'l' + index} style={{ cursor: 'pointer' }}>
                            <p className='mb-0'>
                                <MathJaxRenderer math={opt}></MathJaxRenderer>
                            </p>
                        </label>
                        <br />
                    </Aux>
                ))}
            </div>
        )

        let modalCustomButton = (
            <div className='modal-button text-center'>
                <Button
                    clicked={(e) => submitDiagnosticQuestion(e, diagnosticQuestionObj)}
                    disabled={!this.state.diagnosticQuestionOption}
                    className="mr-2 yellow-btn">Submit</Button>
            </div>
        )

        this.openModal(event,
            modalTitle,
            extraContent ? <>
                {modalContent}
                {extraContent}
            </> : modalContent,
            extraContentButton ? extraContentButton : modalCustomButton,
            false,
            true);
    }

    showLoadingModal = (event, title) => {

        let modalTitle = (
            <div className='d-flex align-items-center'>
                <h4 className='m-0'>{title}</h4>
            </div>);

        let modalContent = (<div className="progress-bar-container">
            <Spinner animation="border" role="status" />
        </div>)

        this.openModal(event,
            modalTitle,
            modalContent,
            null,
            false,
            true);
    }

    showDiagnosticQuestion = (event, diagnosticObj) => {
        const reqParams = {
            params: {
                checkpointId: diagnosticObj.checkpointId,
                challengeId: diagnosticObj.challengeId,
            }
        }

        this.showLoadingModal(event, "Diagnostic Question")

        fetchDiagnosticQuestions(reqParams)
            .then(res => {
                if (res.data.diagnosticQuestions.length > 0) {
                    this.setState({
                        diagnosticQuestions: res.data.diagnosticQuestions,
                        diagnosticQuestionIndex: 0,
                        diagnosticQuestionOption: undefined,
                    })

                    this.showDiagnosticQuestionModal(event, res.data.diagnosticQuestions[0])
                }
            }).catch(err => {
                console.error(err.message);
                alert('Error occurred while submitting the answer.');
            })
    }

    endConferenceHandler = () => {

        const callFetchTranscriptApi = () => {
            const apiHandler = (participantId, transcriptIds) => {
                console.log("1", participantId)
                fetchTranscript({
                    transcriptIds: transcriptIds
                })
                    .then(res => {
                        let transcripts = { ...this.state.transcripts }

                        for (const transcriptId in res.data.response) {
                            if (res.data.response.hasOwnProperty(transcriptId)) {
                                transcripts[participantId] = res.data.response
                            }
                        }
                        console.log("transcripts.......", transcripts)

                        this.setState({ transcripts });
                    }).catch(err => {
                        console.error(err.message);
                    })
            }

            let apiCalled = false

            for (const participantId in this.state.transcripts) {
                console.log("2", participantId)
                if (this.state.transcripts.hasOwnProperty(participantId)) {
                    const participant = this.state.transcripts[participantId];
                    let transcriptIds = []

                    for (const transcriptId in participant) {
                        if (participant.hasOwnProperty(transcriptId)) {
                            const transcriptObj = participant[transcriptId];

                            if (transcriptObj.status === 'InProgress') {
                                transcriptIds.push(transcriptId)
                            }
                        }
                    }

                    if (transcriptIds.length > 0) {
                        console.log("3", participantId)
                        apiHandler(participantId, transcriptIds)
                        apiCalled = true
                    }
                }
            }

            if (!apiCalled) {
                this.setState({ isSessionSynopsisLoading: false });
                clearInterval(this.loadTranscriptTimer)
            }

        }

        const callInitiateTranscriptionApi = (mappingId, participantId1, participantId2 = null) => {
            console.log("participant1:", participantId1)
            console.log("participantId2:", participantId2)
            initiateTranscription({
                mappingId,
                participantId1,
                participantId2
            })
                .then(res => {
                    let transcriptObj = {}

                    res.data.transcriptIds.map(id => {
                        transcriptObj = {
                            ...transcriptObj,
                            [id]: { status: 'InProgress' },
                        }
                    })

                    this.setState({
                        transcripts: {
                            ...this.state.transcripts,
                            [participantId1]: transcriptObj,
                        }
                    }, () => {
                        if (this.loadTranscriptTimer === null) {
                            this.loadTranscriptTimer = setInterval(callFetchTranscriptApi, 5000);
                        }
                    });
                }).catch(err => {
                    console.error(err.message);
                })
        }

        // this.props.toggleVC();
        this.setState({ showSessionSynopsis: true, isSessionSynopsisLoading: true, disabledSynopsisButton: true });
        let params = new URLSearchParams(window.location.search);
        let roomId = params.get('roomId');
        const reqData = {
            roomId: roomId
        }
        setTimeout(() => {
            createRoomParticipantMapping(reqData)
                .then(res => {
                    let participantsForTranscript = []

                    this.setState({
                        synopsisData: {
                            mappingId: res.data.mappingId,
                            roomId
                        }
                    });

                    if (this.loadTranscriptTimer !== null) {
                        clearInterval(this.loadTranscriptTimer)
                    }
                    let participant_one = ""
                    res.data.participants.map(participant => {
                        participantsForTranscript.push(participant)
                        //callInitiateTranscriptionApi(participant.participant_sid, res.data.mappingId)
                    })

                    setTimeout(() => {
                        console.log("Calling INITIATE TRASCRIPTION-----------------", participantsForTranscript)
                        if (participantsForTranscript.length > 1) {
                            callInitiateTranscriptionApi
                                (res.data.mappingId, participantsForTranscript[0].participant_sid, participantsForTranscript[1].participant_sid)
                        }
                        else {
                            if (participantsForTranscript.length > 0) {
                                callInitiateTranscriptionApi
                                    (res.data.mappingId, participantsForTranscript[0].participant_sid)
                            }

                        }

                    }, 500)

                    this.setState({
                        mappingId: res.data.mappingId,
                        participantsForTranscript
                    });

                }).catch(err => {
                    console.error(err.message);
                })

        }, 2000)
    }

    onEquationSelectListener = (imagePath, mathMl) => {
        if (imagePath && mathMl) {
            this.setState({
                selectedEquation: {
                    imagePath,
                    mathMl,
                }
            });
        } else {
            this.setState({ selectedEquation: undefined });
        }
    }

    saveSynopsisEquationToDatabase = () => {
        if (this.state.selectedEquation) {
            let params = new URLSearchParams(window.location.search);
            let roomId = params.get('roomId');
            saveSynopsisEquation({
                roomId,
                source: this.state.selectedEquation.imagePath,
                mathMl: this.state.selectedEquation.mathMl,
            })
                .then(res => {
                    toast('Images and equations are saved.', {
                        type: toast.TYPE.INFO,
                        position: toast.POSITION.BOTTOM_RIGHT,
                    })
                })
                .catch(err => {
                    console.error(err.message);
                })
        }
    }
    getContentOptionsJSX = (data) => {
        return (
            <div className="content-options">
                <TutorProfile
                    title="Recommended Content for Your Cognitive Profile"
                    data={data}
                    handleclose={this.closetutorprofilecontent}
                />

            </div>
        )
    }
    showreviewdModalclose = () => {
        this.setState({ showreviewdModal: false })
    }

    showreviewdnonFRQModalclose = () => {
        this.setState({ showreviewdModalnonFRQ: false })
    }

    closeDialogHandler = () => {
        this.setState({ issubmitdialog: false })
    }
    calculategradinghandler = (e, bText) => {
        if (bText === "Yes") {
            this.setState({ loader: true })
            let question_id = this.props.question.id.slice(1, this.props.question.id.length);
            let assignmentId = this.props.match.params.assignmentId !== undefined && this.props.match.params.assignmentId !== null && this.props.match.params.assignmentId !== "" ? this.props.match.params.assignmentId : ""
            let reqData = {
                userAnswer: this.userAnswer,
                questionId: question_id,
                user_id: getCurrentUserId(),
                assignment_id: assignmentId,
                hints_used: this.state.nonfrqhintdata,
                problem_source: this.state.problem_source,
                boardId: this.props.location.state.boardId,
                final_submission: 1,
                optionselected: 0,
                qtype: this.props.question.type,
                fromTestPaper: 0
            }
            checkChallengeAnswer(reqData).then(res => {
                this.setState({ loader: false })
                if (this.state.problem_source === Problem_source.assignment) {
                    toast("Your assignment is submitted successfully!", {
                        type: toast.TYPE.INFO,
                        position: toast.POSITION.TOP_RIGHT
                    })
                }

                else {
                    toast("Your problem is submitted successfully!", {
                        type: toast.TYPE.INFO,
                        position: toast.POSITION.TOP_RIGHT
                    })
                }



            }).catch(err => {
                this.setState({ loader: false })
                console.error(err.message);
                alert('Error occurred while submitting the answer.');
            })

        }
        this.setState({ issubmitdialog: false })
    }
    askaicheckmywork = () => {
        this.setState({ askAITutorEnabled: true })
    }
    closeBanner = () => {

        this.setState({ bannerOpen: false, openFeedbackModal: false })
    }
    feedbackLinkHandler = () => {
        // this.setState({ bannerOpen: false }, () => {
        //     // let url = configData.WEBSITE_URL + "" + "feedback";
        //     // window.open(url, '_blank');
        // })
        this.setState({ openFeedbackModal: true, bannerOpen: false })

    }
    handlecloseFeedbackModal = () => {
        this.setState({ openFeedbackModal: false })
    }

    render() {

        const totalHints = this.state.totalHints
        const currentHint = this.state.currentHintIndex

        if (this.props.question.custom && !this.props.location.state) {
            this.props.location.state = {}
        }


        if (!this.props.question.custom) {
            const ques = this.props.question.question_image;
            this.props.location.state = {
                ...this.props.location.state,
                image: ques
            };
        }

        let options = [];
        let rankingOptions = null;
        if (!this.props.teacher) {
            if (this.props.question.type === 'MCQ') {
                this.props.question.options.map((opt, index) => {
                    // let checkedvalue = false
                    // if (opt.toLowerCase() === this.state.mcqcorrectAnswerReview.toLowerCase()) {
                    //     checkedvalue = true
                    // }
                    options.push(
                        <div key={'d' + index}>
                            <input
                                type='radio'
                                name='option'
                                id={'opt' + index}
                                key={'o' + index}
                                value={opt.id}
                                className='mr-2'
                                checked={parseInt(this.state.mcqcorrectAnswerReview) === parseInt(opt.id)}
                                onChange={(event) => this.mcqChangeHandler(event, opt.text)} />
                            <label htmlFor={'opt' + index} key={'l' + index}>{parse(opt.text)}</label>
                        </div>
                    );
                })
            } else if (this.props.question.type === 'MSQ') {
                this.props.question.options.map((opt, index) => {
                    let selectionans = this.state.selectedAnswersByUser
                    if (selectionans.includes("###")) {
                        let str = selectionans.split("###")
                        // debugger;
                        str.forEach((rem) => {
                            if (parseInt(opt.id) === parseInt(rem)) {
                                selectionans = rem.trim()
                            }
                        })
                    }

                    options.push(
                        <div key={'d' + index}>
                            <input
                                type='checkbox'
                                name={'choice' + index}
                                id={'opt' + index}
                                key={'o' + index}
                                value={opt.id}
                                className='mr-2'
                                checked={parseInt(selectionans) === parseInt(opt.id)}
                                onChange={(event) => this.msqChangeHandler(event, index, opt.text)} />
                            <label htmlFor={'opt' + index} key={'l' + index}>{parse(opt.text)}</label>
                        </div>
                    );
                })
            } else if (this.props.question.type === 'Ranking') {
                rankingOptions = (
                    <ReactSortable
                        list={this.state.rankingOptionList}
                        setList={newState => this.setState({ rankingOptionList: newState })}
                        animation={150}
                        id='ranking-selector'
                        ghostClass='blue-background-class'
                        className='d-flex flex-column list-group-ranking ml-5 mr-5'
                    >
                        {
                            this.state.rankingOptionList.map(item => (
                                <div key={item.id} className='list-group-item-ranking'>{item.name}</div>
                            ))
                        }
                    </ReactSortable >
                );
            }
        }

        let shouldShowHint = true

        if (this.props.question.type === 'FRQ' || this.props.isAnyProblem) {
            shouldShowHint = this.state.currentHintIndex >= totalHints ? true : false
        }

        const idleEvents = [
            //'mousemove',
            'keydown',
            'wheel',
            'DOMMouseScroll',
            'mouseWheel',
            'mousedown',
            'touchstart',
            'touchmove',
            'MSPointerDown',
            'MSPointerMove',
            'visibilitychange'
        ]
        const timeForIdle = 1000 * 60 * 2 // in Milliseconds
        const isText = this.state.isText
        const checkMyAnswer = this.state.checkMyAnswer;
        const showSolution = this.state.showSolution;
        return (
            <>

                <Dialog
                    className='feedbackDialog'
                    disableEnforceFocus={true}
                    hideBackdrop={false}
                    open={this.state.openFeedbackModal}
                    onClose={this.handlecloseFeedbackModal}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                >
                    <DialogTitle id="alert-dialog-title" sx={{ borderBottom: "1px solid #ccc", borderWidth: "95%" }}>
                        Feedback
                    </DialogTitle>
                    <MuiIconButton
                        aria-label="close"
                        onClick={this.handlecloseFeedbackModal}
                        sx={{
                            position: 'absolute',
                            right: 8,
                            top: 8,
                            color: (theme) => theme.palette.grey[500],
                        }}
                    >
                        <MuiCloseIcon />
                    </MuiIconButton>
                    <DialogContent sx={{ paddingTop: "1px !important", marginRight: "3px" }}>
                        <DialogContentText id="alert-dialog-description">
                            <FeedbackC onClose={this.handlecloseFeedbackModal}></FeedbackC>
                        </DialogContentText>
                    </DialogContent>

                </Dialog>

                <IdleTimer
                    ref={ref => { this.idleTimer = ref }}
                    timeout={1000 * 60 * 5}
                    onActive={this.handleOnActive}
                    onIdle={this.handleOnIdle1}
                    onAction={this.handleOnAction}
                    debounce={250}
                />



                <Dialog
                    hideBackdrop={false}
                    open={this.state.issubmitdialog}
                    onClose={this.closeDialogHandler}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                >

                    <DialogContent>
                        <DialogContentText id="alert-dialog-description">

                            <p dangerouslySetInnerHTML={{ __html: this.state.studentAnswer }}></p>
                            {
                                !this.state.validationMessage ? (
                                    <div style={{ marginTop: "10px", color: "#164b99" }}>
                                        Do you want to submit this?
                                    </div>
                                ) : null
                            }
                        </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                        <button className="dragLabelcancel leftmargin" onClick={(e) => this.calculategradinghandler(e, this.state.dButtonText)}>
                            {this.state.dButtonText}
                        </button>
                        {
                            !this.state.validationMessage ? (
                                <button className="dragLabelcancel leftmargin" onClick={this.closeDialogHandler}>
                                    No
                                </button>
                            ) : null
                        }

                    </DialogActions>
                </Dialog>


                <>
                    {
                        this.props.question.type === 'Text' && getCurrentUserRole() === User_Role.Student ? (<div
                            className='bg-white-with-shadow11'>
                            <div className=""
                                style={{ marginBottom: "30px", fontSize: "16px", marginTop: "20px" }}
                                dangerouslySetInnerHTML={{ __html: `For Subscription Users Only - <a href=${configData.APP_URL}signup>subscribe</a>` }} />
                        </div>) : null
                    }

                </>
                <div style={{ width: '100%', height: '100vh' }}
                    className={`${this.props.question.type === 'Text' && getCurrentUserRole() === User_Role.Student ? ""
                        : this.state.mainDivDisabled ? "" : ""}`}
                >
                    {(this.props.question.type === 'Text') ?
                        <div className='w-100'>
                            <Aux>
                                {(!this.props.teacher && !this.props.question.custom) && this.state.boardLoaded ?
                                    <IdleTimer
                                        events={idleEvents}
                                        timeout={timeForIdle}
                                        onIdle={this.handleOnIdle}
                                    />
                                    : null}

                                <ToastContainer />
                                <ProgressSidebar
                                    show={this.state.showProgressSidebar}
                                    title={this.state.progressbarTitle}
                                    closeSidebar={this.closeProgressSidebar} >
                                    {this.progressSidebarContent}
                                </ProgressSidebar>
                                <ProgressSidebar
                                    fromLeft={true}
                                    show={this.state.showKnowledgeGraphSidebar}
                                    closeSidebar={this.closeKnowledgeGraphSidebar} >
                                    {this.state.knowledgeGraphSidebarContent}
                                </ProgressSidebar>
                                {this.props.teacher ? <SessionSynopsisWithData
                                    challengeId={this.props.challengeId}
                                    show={this.state.showSessionSynopsis}
                                    onClose={this.closeSessionSynopsis}
                                    isLoading={this.state.isSessionSynopsisLoading}
                                    transcripts={this.state.transcripts}
                                    synopsisData={this.state.synopsisData}
                                    isHistoryView={false}
                                    call_type={this.props.call_type}
                                    iseditable={(getCurrentUserRole() === "Tutor" || getCurrentUserRole() === "Professor") ? true : false}
                                /> : null}
                                {
                                    !this.state.upperSectionHide ? (
                                        <Row className='mx-0 mb-2 divflex'>
                                            <Col sm="9">
                                                <div id="curiocityTitle" className='curiosity-title'>
                                                    {this.props.isAnyProblem ? null :
                                                        <Breadcrumb className='d-flex flex-row breadCrumbContainer' currentNode={this.props.question.title}
                                                            prevNode={this.props.location.state ? this.props.location.state : []} />}
                                                    {
                                                        !this.props.isAnyProblem ?
                                                            this.props.question.section_name ?
                                                                <h5 className='breadcrumbH5 pl-0'>
                                                                    {this.props.question.section_name}
                                                                    {this.props.question.title ?
                                                                        <> <ArrowForwardIcon
                                                                            style={{
                                                                                color: "#007bff",
                                                                                width: "0.75em",
                                                                                height: "0.75em",

                                                                            }} /> Challenge: {this.props.question.title}

                                                                        </>
                                                                        : null}</h5>
                                                                : null
                                                            :
                                                            <> <ArrowBack
                                                                style={{
                                                                    color: "#007bff",
                                                                    width: "0.75em",
                                                                    height: "0.75em",
                                                                    marginLeft: "10px"
                                                                }}
                                                            />
                                                                <Link className='backlinkbutton'
                                                                    to={{
                                                                        pathname: this.state.anyproblemBackbuttonlink,
                                                                    }}
                                                                >  {this.state.anyproblemBackbuttonText}</Link>
                                                                {/* <a href={this.state.anyproblemBackbuttonlink} className='link breadcrumbH5'>
                                                            {this.state.anyproblemBackbuttonText}</a> */}
                                                            </>
                                                    }
                                                    {
                                                        this.props.question.ismultipart ? (
                                                            <div className='pmstatementdiv'><strong>Statement</strong><span className='pmstatement' id="divproblemstatement" dangerouslySetInnerHTML={{ __html: this.props.question.problem_statement }}>
                                                            </span></div>
                                                        ) : null
                                                    }
                                                    {this.props.question.type !== 'FRQ' && !this.props.teacher && !this.props.question.custom ?
                                                        this.props.question.type === 'MCQ' ?
                                                            <p class="first" style={{ paddingLeft: "10px" }}>Multiple Choice Question: Please select your answer.</p>
                                                            : this.props.question.type === 'MSQ' ?
                                                                <p class="first" style={{ paddingLeft: "10px" }}>Multiple Select Question. Please select all choices that apply.</p>
                                                                : this.props.question.type === 'Ranking' ?
                                                                    <p class="first" style={{ paddingLeft: "10px" }}>Ranking Problem: Please drag and drop blocks in the right order</p>
                                                                    :
                                                                    null
                                                        : null
                                                    }
                                                    <strong className='questiondescription'>Description:</strong>
                                                    <h1 class="second" style={{ fontSize: "18px !important" }} dangerouslySetInnerHTML={{ __html: this.props.question ? this.props.question.question : '' }}></h1>
                                                </div>
                                                {this.props.question.flag == "ALPHA" ?
                                                    <Row>
                                                        <span className="alpha-data-label text-right flex-grow-1">
                                                            * Alpha-Test Content
                                                        </span>
                                                    </Row> :
                                                    null
                                                }
                                                {options.length > 0 ?
                                                    <div className='d-flex flex-column ml-5'>
                                                        {options.map(opt => opt)}
                                                    </div>
                                                    : null}
                                                {rankingOptions ?
                                                    <Row>
                                                        <Col sm={12} md={this.props.location.state.image ? 12 : 6}>
                                                            {rankingOptions}
                                                        </Col>
                                                    </Row>
                                                    : null}
                                            </Col>
                                            {this.props.location.state.image ?
                                                <Col style={{ top: '10px' }} sm="3">

                                                    <ReactImageMagnify {...{
                                                        smallImage: {
                                                            alt: 'Interactive Problem',
                                                            isFluidWidth: true,
                                                            src: imgURL + this.props.location.state.image,
                                                            lensStyle: {
                                                                background: 'hsla(0, 0%, 100%, .3)',
                                                                border: '1px solid #ccc'
                                                            }
                                                        },
                                                        largeImage: {
                                                            src: imgURL + this.props.location.state.image,
                                                            width: 1000,
                                                            height: 1800,
                                                        },
                                                        enlargedImageContainerStyle: {
                                                            position: 'absolute',
                                                            zIndex: 1
                                                        },
                                                        enlargedImageContainerDimensions: { width: '150%', height: '100%' },
                                                        enlargedImageContainerClassName: 'zoomImg',
                                                        enlargedImagePortalId: "curiocityTitle",
                                                        shouldUsePositiveSpaceLens: true
                                                    }} />

                                                    {/* <img src={imgURL + this.props.location.state.image}
                                                     alt="Question Images"
                                                     className='question-image-answerboard' /> */}

                                                </Col>
                                                // <Col sm="3">
                                                //     <img src={imgURL + this.props.location.state.image} alt="Question" className='question-image-answerboard' />
                                                // </Col>
                                                : null}
                                        </Row>) : null
                                }


                                <div className={`w-100 d-flex bg-white pr-2`}
                                >
                                    <div className={isText ? 'div40' : 'div100'}>
                                        <SketchpadBoard
                                            askAITutorEnabled={this.state.askAITutorEnabled}
                                            askaicheckmywork={this.askaicheckmywork}
                                            challengeId={this.props.challengeId}
                                            roomId={this.state.roomId}
                                            teacher={this.props.teacher}
                                            setBoardId={this.props.setBoardId}
                                            setSidebarContent={this.setSidebarContent}
                                            setKnowledgeGraphContent={this.setKnowledgeGraphSidebarContent}
                                            showDiagnosticQuestion={this.showDiagnosticQuestion}
                                            setBoardLoaded={this.setBoardLoaded}
                                            showHintAndConferenceDialog={this.handleOnIdle}
                                            onEquationSelectListener={this.onEquationSelectListener}
                                            resetNextStepCounter={this.resetNextStepCounter}
                                            hasActiveConference={this.props.hasActiveConference}
                                            getAnswerText={this.getAnswerText}
                                            checkMyWorkHandler={this.submitEquations}
                                            askTutorHandler={this.raiseHandClickHandler}
                                            askAITutorHandler={this.raiseHandAITutorClickHandler}
                                            closeAskAITutor={this.closeAskAITutor}
                                            hintHandler={this.getHint}
                                            hintText={`Hints ${this.props.question.type === 'FRQ' && totalHints >= 0 ? '(' + currentHint + '/' + totalHints + ')' : ''}`}
                                            hintDisabled={shouldShowHint}
                                            submitHandler={this.submitFinalAnswer}
                                            submitNonFRQHandler={this.submitNonFRQHandler}
                                            questiontype={this.props.question.type}
                                            showsubmitbutton={this.state.showsubmitbutton}
                                            showNextStepHandler={this.showNextStepHandler}
                                            showEntireSolutionHandler={this.showEntireSolution}
                                            getKnowledgeGraphHandler={this.getKnowledgeGraph}
                                            saveSynopsisEquationToDatabaseHandler={this.saveSynopsisEquationToDatabase}
                                            endConferenceHandler={this.endConferenceHandler}
                                            leftpanelbuttonhide={this.state.leftpanelbuttonhide}
                                            isAnyProblem={this.props.anyProblem}
                                            fullScreenHandler={this.fullScreenHandler}
                                            isScreenFull={this.state.isScreenFull}
                                            sessionId={this.props.session_id}
                                            actionBtnDisabled={this.state.actionBtnDisabled}
                                            anyProblemStatement={this.state.anyProblemStatement}
                                            sketchpadDivcolor={this.state.sketchpadDivcolor}
                                            cHeight={this.state.cHeight}
                                            eHeight={this.state.eHeight}
                                            problem_source={this.state.problem_source}
                                            showEquationPanel={true}
                                            forMobile={false}
                                            hinTextforAIAskTutorQuestion={this.state.hinTextforAIAskTutorQuestion}
                                            forHintAITutor={this.state.forHintAITutor}
                                            handlehintequationclick={this.handlehintequationclick}
                                            forhintAiTutorfalsehandler={this.forhintAiTutorfalsehandler}
                                            // practiceMode={this.props.practiceMode}
                                            // isReview={this.props.isReview}
                                            // timeLapseString={this.props.timeLapseString}
                                            calculateGradesForPTFRQ={this.calculateGradesForPTFRQ}
                                            totalGradeCalculationTrial={this.state.totalGradeCalculationTrial}
                                            setShowSolutionBtnVisibility={this.state.setShowSolutionBtnVisibility}
                                            assignment_id={this.props.match.params.assignmentId !== undefined && this.props.match.params.assignmentId !== null && this.props.match.params.assignmentId !== "" ? this.props.match.params.assignmentId : ""}
                                            isGuided={this.props.isGuided}
                                            isGraded={this.props.isGraded}
                                            totalTrials={this.props.totalTrials}
                                            isTimed={this.props.isTimed}
                                            assignmentTime={this.props.assignmentTime}
                                            isReview={this.props.isReview}
                                            timeLapseString={this.props.timeLapseString}
                                            askAIquestion={this.state.askAIquestion}
                                            askAIBgColor={this.state.askAIBgColor}
                                            equationClass={this.state.equationClass}
                                            equationClick={this.state.equationClick}
                                            handleaskAIBySketchpad={this.handleaskAIBySketchpad}
                                            {...this.props}
                                        />

                                        {/* )
                                    } */}

                                        {(!this.props.question.custom || this.props.question.custom)
                                            ?
                                            <Aux>
                                                {
                                                    this.props.anyProblem ? null :
                                                        (this.state.footerbuttondisplay ? (

                                                            <>
                                                                <Row className={`whiteboard-bottom-buttons ${this.props.teacher ? 'for-teacher' : ''}`}>

                                                                    <Col className='d-flex align-items-center marginclass' >
                                                                        {(!this.props.teacher && !this.props.question.custom) ?
                                                                            <Aux>
                                                                                {this.props.question.type.toLowerCase() !== "text" ?
                                                                                    <IconButton
                                                                                        className='mr-2'
                                                                                        text={`Hints ${this.props.question.type === 'FRQ' && totalHints >= 0 ? '(' + currentHint + '/' + totalHints + ')' : ''}`}
                                                                                        icon='ic ic-hint'
                                                                                        disabled={shouldShowHint}
                                                                                        clicked={(event) => this.getHint(event)}
                                                                                    /> : null}

                                                                                <IconButton
                                                                                    className='mr-2 lessImportant'
                                                                                    text={this.props.question.type.toLowerCase() === "text" ? 'Show Model Answer' : 'Show Solution'}
                                                                                    clicked={(event) => this.showEntireSolution(event)} />
                                                                            </Aux>
                                                                            : null}

                                                                        <div className='ml-auto d-flex'>
                                                                            {(!this.props.teacher && !this.props.question.custom) ?
                                                                                <Aux>

                                                                                    {getCurrentUserRole() !== 'Tutor' ? <IconButton
                                                                                        className='mr-2'
                                                                                        text='Ask Tutor'
                                                                                        tooltip="Start a live video call with a human tutor. Share your board to collaborate."
                                                                                        disabled={false}
                                                                                        clicked={

                                                                                            (event) => this.raiseHandClickHandler(event)
                                                                                        } /> : null}
                                                                                    {getCurrentUserRole() !== 'Tutor' ? <IconButton
                                                                                        className='mr-2'
                                                                                        text='Ask AI Tutor'
                                                                                        disabled={false}
                                                                                        tooltip="Get further explanations from the interactive AI tutor."
                                                                                        clicked={

                                                                                            (event) => this.raiseHandAITutorClickHandler(event)
                                                                                        } /> : null}
                                                                                    {this.props.question.type.toLowerCase() !== "text" ?
                                                                                        <IconButton
                                                                                            className='mr-2'
                                                                                            text={this.state.nextStepCounter ? `Show Next Step (${this.state.nextStepCounter}/${this.state.totalStepCounter})` : `Show Next Step`}
                                                                                            disabled={this.props.question.type !== 'FRQ' ? true : false}
                                                                                            clicked={(event) => this.showNextStepHandler(event)} /> : null}
                                                                                    <div className='has-answerboard-guide'>
                                                                                        {this.props.question.type.toLowerCase() !== "text" ?
                                                                                            <IconButton
                                                                                                className='mr-2'
                                                                                                text='Check My Work'
                                                                                                icon='ic ic-check'
                                                                                                disabled={this.props.question.type !== 'FRQ' ? true : false}
                                                                                                clicked={this.submitEquations}
                                                                                            /> : null}
                                                                                        {this.props.question.type === 'FRQ' ?
                                                                                            <div className='answerboard-guide check-work-button'>
                                                                                                <div className='guide-text'>Click here after any step to check if you are on the right track.</div>
                                                                                                <div className='arrow-down'></div>
                                                                                            </div>
                                                                                            : null}
                                                                                    </div>
                                                                                    <IconButton
                                                                                        text={this.props.question.type !== 'FRQ' ? (this.props.question.type.toLowerCase() === "text" ? 'Check My Answer' : 'Enter Answer') : 'Submit'}
                                                                                        icon='ic ic-submit'
                                                                                        clicked={(event) => this.submitFinalAnswer(event)} />
                                                                                </Aux>
                                                                                : (!this.props.teacher && this.props.question.custom) ? null
                                                                                    :
                                                                                    <>
                                                                                        <IconButton
                                                                                            className='mr-2'
                                                                                            text="Knowledge Graph"
                                                                                            icon='bi bi-file-bar-graph'
                                                                                            disabled={this.props.question.type !== 'FRQ' ? true : false}
                                                                                            clicked={this.getKnowledgeGraph}
                                                                                        />
                                                                                        <IconButton
                                                                                            className='mr-2'
                                                                                            text="Check Student's Work"
                                                                                            icon='ic ic-check'
                                                                                            disabled={this.props.question.type !== 'FRQ' ? true : false}
                                                                                            clicked={this.submitEquations}
                                                                                        />
                                                                                        <IconButton
                                                                                            className="mr-2"
                                                                                            text="Save to Transcript"
                                                                                            disabled={this.state.disabledSynopsisButton || !this.state.selectedEquation}
                                                                                            clicked={this.saveSynopsisEquationToDatabase}
                                                                                        />

                                                                                        <IconButton
                                                                                            className="mr-2"
                                                                                            // text="End Conference and Initiate Transcribe"
                                                                                            text="Synopsis"
                                                                                            icon="ic ic-submit"
                                                                                            disabled={this.state.disabledSynopsisButton}
                                                                                            clicked={this.endConferenceHandler}
                                                                                        />
                                                                                    </>}
                                                                        </div>
                                                                    </Col>
                                                                </Row>
                                                            </>
                                                        ) : null
                                                        )

                                                }


                                                <Modal
                                                    show={this.state.showModal}
                                                    handleClose={this.closeModal}
                                                    title={this.modalTitle}
                                                    body={this.modalBody}
                                                    showButton={this.showButton}
                                                    customButton={this.customButton}
                                                    draggable={this.draggable} />

                                                <Modal
                                                    show={this.state.showAnswerModal}
                                                    handleClose={this.closeAnswerModal}
                                                    body={this.answerModalBody}
                                                    showButton={this.showAnswerButton}
                                                    customButton={this.customAnswerButton}
                                                //title={this.title} 
                                                />

                                                <ContentOptionsPopup
                                                    showContentOptions={this.state.showContentOptions}
                                                    handleClose={() => this.setState({ showContentOptions: false })}
                                                    body={this.tutormodalbody}
                                                    contentOptionsPosition={this.state.contentOptionsPosition} />

                                            </Aux>
                                            : null}
                                    </div>

                                    {checkMyAnswer !== null && showSolution !== null ?
                                        <div className={"d-flex solutionBox " + (isText ? 'div60 pl-2 pt-2' : 'div0 d-none')}>
                                            <div className='p-1 div50'>
                                                {checkMyAnswer}
                                            </div>
                                            <div className='p-1 div50'>
                                                {showSolution}
                                            </div>
                                        </div>
                                        :
                                        (checkMyAnswer !== null && showSolution === null ?
                                            <div className={"d-flex solutionBox " + (isText ? 'div60 pl-2 pt-2' : 'div0 d-none')}>
                                                <div className='p-1 div100'>
                                                    {checkMyAnswer}
                                                </div>
                                                <div className='p-1 div0'>
                                                    {showSolution}
                                                </div>
                                            </div>
                                            :
                                            <div className={"d-flex solutionBox " + (isText ? 'div60 pl-2 pt-2' : 'div0 d-none')}>
                                                <div className='p-1 div0'>
                                                    {checkMyAnswer}
                                                </div>
                                                <div className='p-1 div100'>
                                                    {showSolution}
                                                </div>
                                            </div>
                                        )
                                    }
                                </div>
                            </Aux>
                        </div>



                        :

                        <Aux>
                            {(!this.props.teacher && !this.props.question.custom) && this.state.boardLoaded ?
                                <IdleTimer
                                    events={idleEvents}
                                    timeout={timeForIdle}
                                    onIdle={this.handleOnIdle}
                                />
                                : null}

                            <ToastContainer />
                            <ProgressSidebar
                                fromLeft={true}
                                title={this.state.progressbarTitle}
                                show={this.state.showProgressSidebar}
                                closeSidebar={this.closeProgressSidebar} >
                                {this.progressSidebarContent}
                            </ProgressSidebar>

                            <ProgressSidebar
                                fromLeft={true}
                                show={this.state.showKnowledgeGraphSidebar}
                                closeSidebar={this.closeKnowledgeGraphSidebar} >
                                {this.state.knowledgeGraphSidebarContent}
                            </ProgressSidebar>

                            {this.props.teacher ? <SessionSynopsisWithData
                                challengeId={this.props.challengeId}
                                show={this.state.showSessionSynopsis}
                                onClose={this.closeSessionSynopsis}
                                isLoading={this.state.isSessionSynopsisLoading}
                                transcripts={this.state.transcripts}
                                synopsisData={this.state.synopsisData}
                                isHistoryView={false}
                                call_type={this.props.call_type}
                                iseditable={(getCurrentUserRole() === "Tutor" || getCurrentUserRole() === "Professor") ? true : false}
                            /> : null}

                            {
                                !this.state.upperSectionHide ? (
                                    <Row className='mx-0 mb-2 divflex'>
                                        <Col id="curiocityTitle" sm="9">
                                            <div className={this.props.anyProblem ? 'curiosity-titleAnyProb curiosity-title' : 'curiosity-title'}>
                                                {this.props.isAnyProblem ? null : <Breadcrumb className='d-flex flex-row breadCrumbContainer' currentNode={this.props.question.title} prevNode={this.props.location.state ? this.props.location.state : []} />}
                                                {!this.props.anyProblem ? this.props.question.sub_section_name ?
                                                    <h5 className='breadcrumbH5 pl-0'>{this.props.question.sub_section_name}
                                                        {this.props.question.title ?
                                                            <> <ArrowForwardIcon
                                                                style={{
                                                                    color: "#007bff",
                                                                    width: "0.75em",
                                                                    height: "0.75em",

                                                                }} /> Challenge: {this.props.question.title}

                                                            </>
                                                            : null}</h5>
                                                    : null
                                                    :
                                                    <> <ArrowBack
                                                        style={{
                                                            color: "#007bff",
                                                            width: "0.75em",
                                                            height: "0.75em",
                                                            marginLeft: "10px"

                                                        }} />

                                                        <Link className='backlinkbutton'

                                                            to={{
                                                                pathname: this.state.anyproblemBackbuttonlink,

                                                            }}
                                                        >  {this.state.anyproblemBackbuttonText}</Link>
                                                        {/* <a href={this.state.anyproblemBackbuttonlink} className='link breadcrumbH5'>
                                                    {this.state.anyproblemBackbuttonText}</a> */}

                                                    </>
                                                }
                                                {
                                                    this.props.question.ismultipart ? (
                                                        <div className='pmstatementdiv'><strong>Statement</strong><span className='pmstatement' id="divproblemstatement"
                                                            dangerouslySetInnerHTML={{ __html: this.props.question.problem_statement }}>
                                                        </span></div>
                                                    ) : null
                                                }
                                                {this.props.question.type !== 'FRQ' && !this.props.teacher && !this.props.question.custom ?
                                                    this.props.question.type === 'MCQ' ?
                                                        <p class="first" style={{ paddingLeft: "10px" }}>Multiple Choice Question: Please select your answer.</p>
                                                        : this.props.question.type === 'MSQ' ?
                                                            <p class="first" style={{ paddingLeft: "10px" }}>Multiple Select Question. Please select all choices that apply.</p>
                                                            : this.props.question.type === 'Ranking' ?
                                                                <p class="first" style={{ paddingLeft: "10px" }}>Ranking Problem: Please drag and drop blocks in the right order</p>
                                                                :
                                                                null
                                                    : null
                                                }
                                                {this.props.anyProblem && isEmpty(this.state.anyProblemStatement) ?
                                                    <ProblemStatement sessionId={this.props.session_id} onSubmitProblemStatement={this.anyPoblemStatementHandler}></ProblemStatement> :
                                                    this.props.anyProblem ?
                                                        <div style={{ width: 'max-content', width: '134%' }}>
                                                            <Accordion style={{ fontSize: '16px', width: '100%' }}>
                                                                <AccordionSummary
                                                                    expandIcon={<ExpandMoreIcon />}
                                                                    aria-controls="panel1a-content"
                                                                    id="panel1a-header"
                                                                >
                                                                    <Typography><span>Problem Statement</span></Typography>
                                                                </AccordionSummary>
                                                                <AccordionDetails>
                                                                    <Typography>
                                                                        <MathJaxRenderer fromCKEditor={true} math={this.state.anyProblemStatement} />
                                                                    </Typography>
                                                                </AccordionDetails>
                                                            </Accordion>
                                                        </div> :
                                                        <>
                                                            <strong className='questiondescription'>Description:</strong>
                                                            <h1 style={{ paddingLeft: "10px" }}
                                                                class="second test1"
                                                                dangerouslySetInnerHTML={{ __html: this.props.question ? this.props.question.question : '' }}>

                                                            </h1></>
                                                }
                                                {/* <div className='curi'>

                                                </div> */}

                                            </div>
                                            {this.props.question.flag == "ALPHA" ?
                                                <Row>
                                                    <span className="alpha-data-label text-right flex-grow-1">
                                                        * Alpha-Test Content
                                                    </span>
                                                </Row> :
                                                null
                                            }
                                            {options.length > 0 ?
                                                <div className='d-flex flex-column ml-5'>
                                                    {options.map(opt => opt)}
                                                </div>
                                                : null}
                                            {rankingOptions ?
                                                <Row>
                                                    <Col sm={12} md={this.props.location.state.image ? 12 : 6}>
                                                        {rankingOptions}
                                                    </Col>
                                                </Row>
                                                : null}
                                        </Col>
                                        {this.props.location.state.image ?

                                            <Col style={{ top: '10px' }} sm="3">

                                                <ReactImageMagnify {...{
                                                    smallImage: {
                                                        alt: 'Interactive Problem',
                                                        isFluidWidth: true,
                                                        src: imgURL + this.props.location.state.image,
                                                        lensStyle: {
                                                            background: 'hsla(0, 0%, 100%, .3)',
                                                            border: '1px solid #ccc'
                                                        }
                                                    },
                                                    largeImage: {
                                                        src: imgURL + this.props.location.state.image,
                                                        width: 1000,
                                                        height: 1800,
                                                    },
                                                    enlargedImageContainerStyle: {
                                                        position: 'absolute',
                                                        zIndex: 1
                                                    },
                                                    isActivatedOnTouch: true,
                                                    enlargedImageContainerDimensions: { width: '150%', height: '100%' },
                                                    enlargedImageContainerClassName: 'zoomImg',
                                                    enlargedImagePortalId: "curiocityTitle",
                                                    shouldUsePositiveSpaceLens: true
                                                }} />

                                                {/* <img src={imgURL + this.props.location.state.image}
                                                        alt="Question Images"
                                                        className='question-image-answerboard' /> */}

                                            </Col>
                                            : null}
                                    </Row>) : null
                            }
                            <div >
                                <div style={{ width: '100%' }}>
                                    <SketchpadBoard
                                        roomId={this.state.roomId}
                                        askAITutorEnabled={this.state.askAITutorEnabled}
                                        askaicheckmywork={this.askaicheckmywork}
                                        teacher={this.props.teacher}
                                        setBoardId={this.props.setBoardId}
                                        setSidebarContent={this.setSidebarContent}
                                        setKnowledgeGraphContent={this.setKnowledgeGraphSidebarContent}
                                        showDiagnosticQuestion={this.showDiagnosticQuestion}
                                        setBoardLoaded={this.setBoardLoaded}
                                        showHintAndConferenceDialog={this.handleOnIdle}
                                        onEquationSelectListener={this.onEquationSelectListener}
                                        resetNextStepCounter={this.resetNextStepCounter}
                                        hasActiveConference={this.props.hasActiveConference}
                                        getAnswerText={this.getAnswerText}
                                        checkMyWorkHandler={this.submitEquations}
                                        askTutorHandler={this.raiseHandClickHandler}
                                        askAITutorHandler={this.raiseHandAITutorClickHandler}
                                        closeAskAITutor={this.closeAskAITutor}
                                        hintHandler={this.getHint}
                                        hintText={`Hints ${this.props.question.type === 'FRQ' && totalHints >= 0 ? '(' + currentHint + '/' + totalHints + ')' : ''}`}
                                        hintDisabled={shouldShowHint}
                                        submitHandler={this.submitFinalAnswer}
                                        submitNonFRQHandler={this.submitNonFRQHandler}
                                        questiontype={this.props.question.type}
                                        showsubmitbutton={this.state.showsubmitbutton}
                                        showNextStepHandler={this.showNextStepHandler}
                                        showEntireSolutionHandler={this.showEntireSolution}
                                        getKnowledgeGraphHandler={this.getKnowledgeGraph}
                                        saveSynopsisEquationToDatabaseHandler={this.saveSynopsisEquationToDatabase}
                                        endConferenceHandler={this.endConferenceHandler}
                                        fullScreenHandler={this.fullScreenHandler}
                                        isScreenFull={this.state.isScreenFull}
                                        leftpanelbuttonhide={this.state.leftpanelbuttonhide}
                                        isAnyProblem={this.props.anyProblem}
                                        sessionId={this.props.session_id}
                                        actionBtnDisabled={this.state.actionBtnDisabled}
                                        anyProblemStatement={this.state.anyProblemStatement}
                                        sketchpadDivcolor={this.state.sketchpadDivcolor}
                                        challengeId={this.props.challengeId}
                                        cHeight={this.state.cHeight}
                                        eHeight={this.state.eHeight}
                                        problem_source={this.state.problem_source}
                                        showEquationPanel={true}
                                        forMobile={false}
                                        hinTextforAIAskTutorQuestion={this.state.hinTextforAIAskTutorQuestion}
                                        forHintAITutor={this.state.forHintAITutor}
                                        handlehintequationclick={this.handlehintequationclick}
                                        forhintAiTutorfalsehandler={this.forhintAiTutorfalsehandler}
                                        // practiceMode={this.props.practiceMode}
                                        // isReview={this.props.isReview}
                                        // timeLapseString={this.props.timeLapseString}
                                        calculateGradesForPTFRQ={this.calculateGradesForPTFRQ}
                                        totalGradeCalculationTrial={this.state.totalGradeCalculationTrial}
                                        setShowSolutionBtnVisibility={this.state.setShowSolutionBtnVisibility}
                                        assignment_id={this.props.match.params.assignmentId !== undefined && this.props.match.params.assignmentId !== null && this.props.match.params.assignmentId !== "" ? this.props.match.params.assignmentId : ""}
                                        isGuided={this.props.isGuided}
                                        isGraded={this.props.isGraded}
                                        totalTrials={this.props.totalTrials}
                                        isTimed={this.props.isTimed}
                                        assignmentTime={this.props.assignmentTime}
                                        isReview={this.props.isReview}
                                        timeLapseString={this.props.timeLapseString}
                                        askAIquestion={this.state.askAIquestion}
                                        askAIBgColor={this.state.askAIBgColor}
                                        equationClass={this.state.equationClass}
                                        equationClick={this.state.equationClick}
                                        handleaskAIBySketchpad={this.handleaskAIBySketchpad}
                                        {...this.props}
                                    />

                                </div>
                                {/* <div className='anyProblemChartBot' style={{ width: this.state.askAITutorEnabled ? `400px` : '0' }}>
                                    <AnyProblem challengeId={this.props.challengeId} boardId={this.props.location.state.boardId}></AnyProblem>
                                </div> */}
                            </div>


                            {(!this.props.question.custom || this.props.question.custom) ?
                                <Aux>
                                    {
                                        this.props.anyProblem ? null :
                                            (this.state.footerbuttondisplay
                                                ? (<Row className={`whiteboard-bottom-buttons ${this.props.teacher ? 'for-teacher' : ''}`}>

                                                    <Col className='d-flex align-items-center'>
                                                        {(!this.props.teacher && !this.props.question.custom) ?
                                                            null         // <Aux><IconButton
                                                            //     className='mr-2'
                                                            //     text={`Hints ${this.props.question.type === 'FRQ' && totalHints >= 0 ? '(' + currentHint + '/' + totalHints + ')' : ''}`}
                                                            //     icon='ic ic-hint'
                                                            //     disabled={shouldShowHint}
                                                            //     clicked={(event) => this.getHint(event)}
                                                            // />

                                                            //     <IconButton
                                                            //         className='mr-2 lessImportant'
                                                            //         text='Show Solution'
                                                            //         clicked={(event) => this.showEntireSolution(event)} />
                                                            // </Aux>
                                                            : null}

                                                        <div className='ml-auto d-flex'>
                                                            {(!this.props.teacher && !this.props.question.custom) ? null
                                                                // <Aux>

                                                                //     {getCurrentUserRole() !== 'Tutor' ? <IconButton
                                                                //         className='mr-2'
                                                                //         text='Ask Tutor'
                                                                //         disabled={false}
                                                                //         clicked={
                                                                //             // (event) => this.openModal(event,
                                                                //             // null,
                                                                //             // modalContent,
                                                                //             // modalButton,
                                                                //             // false,
                                                                //             // true)
                                                                //             (event) => this.raiseHandClickHandler(event)
                                                                //         } /> : null}

                                                                //     {/* <IconButton
                                                                //         className='mr-2'
                                                                //         text={this.state.nextStepCounter ? `Show Next Step (${this.state.nextStepCounter}/${this.state.totalStepCounter})` : `Show Next Step`}
                                                                //         disabled={this.props.question.type !== 'FRQ' ? true : false}
                                                                //         clicked={(event) => this.showNextStepHandler(event)} />
                                                                //     <div className='has-answerboard-guide'>
                                                                //         <IconButton
                                                                //             className='mr-2'
                                                                //             text='Check My Work'
                                                                //             icon='ic ic-check'
                                                                //             disabled={this.props.question.type !== 'FRQ' ? true : false}
                                                                //             clicked={this.submitEquations}
                                                                //         />
                                                                //         {this.props.question.type === 'FRQ' ?
                                                                //             <div className='answerboard-guide check-work-button'>
                                                                //                 <div className='guide-text'>Click here after any step to check if you are on the right track.</div>
                                                                //                 <div className='arrow-down'></div>
                                                                //             </div>
                                                                //             : null}
                                                                //     </div>
                                                                //     <IconButton
                                                                //         text={this.props.question.type !== 'FRQ' ? 'Enter Answer' : 'Submit'}
                                                                //         icon='ic ic-submit'
                                                                //         clicked={(event) => this.submitFinalAnswer(event)} /> */}

                                                                // </Aux>
                                                                : (!this.props.teacher && this.props.question.custom) ? null
                                                                    : null
                                                                /*
                                                                <>
                                                                    <IconButton
                                                                        className='mr-2'
                                                                        text="Knowledge Graph"
                                                                        icon='bi bi-file-bar-graph'
                                                                        disabled={this.props.question.type !== 'FRQ' ? true : false}
                                                                        clicked={this.getKnowledgeGraph}
                                                                    />
                                                                    <IconButton
                                                                        className='mr-2'
                                                                        text="Check Student's Work"
                                                                        icon='ic ic-check'
                                                                        disabled={this.props.question.type !== 'FRQ' ? true : false}
                                                                        clicked={this.submitEquations}
                                                                    />
                                                                    <IconButton
                                                                        className="mr-2"
                                                                        text="Save to Transcript"
                                                                        disabled={this.state.disabledSynopsisButton || !this.state.selectedEquation}
                                                                        clicked={this.saveSynopsisEquationToDatabase}
                                                                    />
     
                                                                    <IconButton
                                                                        className="mr-2"
                                                                        // text="End Conference and Initiate Transcribe"
                                                                        text="Synopsis"
                                                                        icon="ic ic-submit"
                                                                        disabled={this.state.disabledSynopsisButton}
                                                                        clicked={this.endConferenceHandler}
                                                                    />
     
     
                                                                </>
                                                                */
                                                            }
                                                        </div>
                                                    </Col>
                                                </Row>) : null
                                            )
                                    }

                                    <Modal
                                        show={this.state.show}
                                        handleClose={this.handleClose}
                                        title="Submit Feedback"
                                        body={<Feedback />}
                                        draggable={true} />

                                    <Modal
                                        show={this.state.showModal}
                                        handleClose={this.closeModal}
                                        title={this.modalTitle}
                                        body={this.modalBody}
                                        showButton={this.showButton}
                                        customButton={this.customButton}
                                        draggable={this.draggable} />

                                    <Modal
                                        show={this.state.showAnswerModal}
                                        handleClose={this.closeAnswerModal}
                                        body={this.answerModalBody}
                                        showButton={this.showAnswerButton}
                                        customButton={this.customAnswerButton}
                                    //title={this.title} 
                                    />

                                    <ContentOptionsPopup
                                        showContentOptions={this.state.showContentOptions}
                                        handleClose={() => this.setState({ showContentOptions: false })}
                                        body={this.tutormodalbody}
                                        contentOptionsPosition={this.state.contentOptionsPosition} />




                                </Aux>
                                : null}
                        </Aux>
                    }

                    {/* {this.props.question.type === 'FRQ' ? <Tour pagename="answerboardContent"></Tour> : null} */}

                </div>
                {
                    this.state.loader && (
                        <CircularProgress
                            size={24}
                            sx={{
                                color: "#164b99",
                                position: 'absolute',
                                top: '50%',
                                left: '50%',
                                marginTop: '-12px',
                                marginLeft: '-12px',
                            }}
                        />
                    )
                }




                <TrapFocus open disableAutoFocus disableEnforceFocus>
                    <Fade appear={false} in={this.state.bannerOpen}>
                        <Paper
                            elevation={24}
                            role="dialog"
                            aria-modal="false"
                            aria-label="Cookie banner"
                            square
                            variant="outlined"
                            tabIndex={-1}
                            sx={{
                                position: 'fixed',
                                right: 0,
                                m: 0,
                                p: 2,
                                borderWidth: "1px",
                                borderColor: "black",
                                // borderTopWidth: 1,
                                zIndex: 9999,
                                width: "20% !important",
                                right: "30px",
                                bottom: "40px",
                                borderRadius: "10px",
                                '@media (max-width: 600px)': {
                                    width: '100% !important',
                                    right: "0px !important"
                                },
                                '@media only screen and (min-device-width: 768px) and (max-device-width: 1024px) and (-webkit-device-pixel-ratio: 2)': {
                                    width: '34% !important'
                                }
                            }}
                        >
                            <Stack
                                direction={{ xs: 'column', sm: 'row' }}
                                justifyContent="flex-start"
                                gap={2}

                            >
                                <img src={LogoSign} alt="" className="feebackcompanyLogo" />

                                <Typography sx={{
                                    fontSize: "18px !important"
                                }}>Please help us improve aiPlato.</Typography>



                            </Stack>
                            <Stack
                                direction={{ xs: 'column', sm: 'row' }}
                                justifyContent="flex-end"
                                gap={2}
                                sx={{ mt: 1 }}
                            >
                                <MuiButton size="small" onClick={this.closeBanner} variant="outlined"
                                    sx={{

                                        textTransform: "capitalize",
                                        fontSize: "15px"
                                    }}>
                                    No Thanks
                                </MuiButton>
                                <MuiButton size="small"
                                    // target='_blank ' 
                                    // href={`${configData.WEBSITE_URL}feedback`} 
                                    onClick={this.feedbackLinkHandler}
                                    variant="contained"
                                    sx={{

                                        textTransform: "capitalize",
                                        fontSize: "15px",
                                        '&:hover': {
                                            color: '#ffffff',

                                        },


                                    }}>
                                    Feedback
                                </MuiButton>

                            </Stack>


                        </Paper>
                    </Fade>
                </TrapFocus>


            </>
        );
    }
}

export default withRouter(React.memo(Answerboard));