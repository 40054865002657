
import React, { useMemo, useState, useEffect } from 'react';
import { MaterialReactTable } from 'material-react-table';
import { Topiccompletion } from '../../../common/API';
import { getCurrentUserId } from '../../../common/Functions';


const ProgressByTopic = () => {

  const [topicdata, setTopicdata] = useState([])
  const columns = useMemo(
    //column definitions...
    () => [
      {
        accessorKey: 'Name',
        header: 'Name',
        size: 120
      },
      {
        accessorKey: 'ContentCompletion',
        header: 'Content   Completion',
        size: 80
       
      },

      {
        accessorKey: 'ContentProficiency',
        header: 'Content   Proficiency',
        size: 80
      },
      {
        accessorKey: 'ChallengeCompletion',
        header: 'Challenge Completion',
        size: 80
      },
      {
        accessorKey: 'ChallengeProficiency',
        header: 'Challenge Proficiency',
        size: 80
      },
      {
        accessorKey: 'ChallengeAttempt',
        header: 'Challenge     Attempt',
        size: 80
      },

     
    ],
    [],
    //end
  );

    useEffect(() => {

    const reqData = {
      params: {
        user_id: getCurrentUserId()
      }
    }
    Topiccompletion(reqData).then(res => {

      if (res.data !== undefined && res.data.data !== undefined) {
        setTopicdata(res.data.data)
      }
    })

  }, []);

  return (
    <>
    {
      topicdata !== undefined && topicdata.length > 0 ? ( <MaterialReactTable
        columns={columns}
        enableColumnResizing
        data={topicdata}
        enableExpanding
        //initialState={{ expanded: true }}
        enableExpandAll ={true}
        enablePagination={false}
        enableColumnFilters ={false}
        enableHiding={false}
        enableFilters={false}
        enableFullScreenToggle={false}
        enableDensityToggle={false}
        enableSorting={false}
        enableTopToolbar={false}
        enableTopBottombar={false}
        enableColumnActions={false}
      />) : (<MaterialReactTable
        columns={columns}
        data={[]}
        enableExpanding
        //initialState={{ expanded: true }}
        enableExpandAll ={true}
        enablePagination={false}
        enableColumnFilters ={false}
        enableTopBottombar={false}
        enableHiding={false}
        enableFilters={false}
        enableFullScreenToggle={false}
        enableDensityToggle={false}
        enableSorting={false}
        enableTopToolbar={false}
        enableColumnActions={false}
      />)

    }
    
    </>
    
  );
};

export default ProgressByTopic;

// import React, { useState, useEffect } from 'react';
// import {
//   Accordion,
//   AccordionDetails,
//   AccordionSummary,
//   Grid,
//   Typography
// } from "@material-ui/core";
// import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
// import { Topiccompletion } from '../../../common/API';
// import { getCurrentUserId } from '../../../common/Functions';
// import IconButton from "@material-ui/core/IconButton";
// import {
//   Table,
//   TableBody,
//   TableCell,
//   TableHead,
//   TableRow,
//   Paper,
//   Button
// } from "@material-ui/core";
// import { makeStyles } from "@material-ui/core/styles";
// import TableContainer from '@mui/material/TableContainer';
// import SvgMore from "@material-ui/icons/ExpandMore";

// import ExpandLess from "@material-ui/icons/ExpandLess";
// import ExpandMore from "@material-ui/icons/ExpandMore";
// import Collapse from "@material-ui/core/Collapse";


// const useStyles = makeStyles(() => ({
//   root:{
//     fontSize:"12px !important"
//   },
//   container: {
//     overflow: "auto"
//   },
//   header: {
//     fontSize:"10px !important"
//     // display: "flex",
//     // justifyContent: "space-between",
//     // alignItems: "center",
//     // fontSize: "14px",
//     // padding:"10px"


//   },
//   row: {
//     minHeight: 0
//   },
//   expandbutton: {
//     "&:hover": {
//       //you want this to be the same as the backgroundColor above
//       backgroundColor: "transparent !important"
//     }
//   },

//   oddevenbackground: {
//     '&:nth-of-type(odd)': {
//       backgroundColor: "#f8f8f8 !important"
//     },
//     "&:hover": {
//       '&:nth-of-type(odd)': {
//         backgroundColor: "#f8f8f8 !important"
//       },
//       '&:nth-of-type(even)': {
//         backgroundColor: "#fff !important"
//       },
//     }
//   }


// }));

// const headers = [{ key: "Name" }, { key: "ContentCompletion" }, { key: "ChallengeCompletion" }, { key: "ChallengeAttempt" }];
// const ProgressByTopic = (props) => {

//   const classes = useStyles();
//   const [topicdata, setTopicdata] = useState([])
//   const [state, setState] = React.useState({});
  
//   console.log(state);
//   console.log(state["parentTasks"]);


//   if (state["parentTasks"] === undefined) {
//     const newstate = { ...state, parentTasks: true };
//     setState(newstate);
//   }
//   const handleClick = item => {

//     const newstate = { ...state, [item]: !state[item] };
//     setState(newstate);
//   };
//   useEffect(() => {

//     const reqData = {
//       params: {
//         user_id: getCurrentUserId()
//       }
//     }
//     Topiccompletion(reqData).then(res => {

//       if (res.data !== undefined && res.data.data !== undefined) {
//         setTopicdata(res.data.data)
//       }
//     })

//   }, []);

//   function treeTable(taskTree, collapseId) {
//     if (taskTree.children !== undefined) {
//       return taskTree.children.map((task, i) => {

//         return (
//           <React.Fragment>
//             <TableRow hover key={task.id} className={classes.oddevenbackground}>
//               {headers.map(({ key, format = value => value }, index) => (
//                 <TableCell style={{
//                   padding: 10, 
//                   border: 0,
//                   paddingLeft:
//                     task.children !== undefined && task.children.length > 0 ? index === 0 ?
//                       task.parent == 2 ? "5px" : "30px"
//                       : "0" : index === 0
//                       ? "45px" : "0",
//                   //width: index === 0 ? "550px" : "90px",
//                   textAlign: index === 0 ? "left" : "right",
//                   fontSize: "12px"
//                 }}>
//                   <Collapse
//                     key={collapseId}
//                     in={state[collapseId]}
//                     timeout="auto"
//                     unmountOnExit
//                   >
//                     <span style={{ textAlign: "center" }}> {format(task[key])}</span>

//                   </Collapse>

//                 </TableCell>
//               ))}
//               {
//                 task.children !== undefined ? (

//                   <TableCell style={{ padding: 10, border: 0 }}>
//                     <Collapse
//                       key={collapseId}
//                       // component="table"
//                       in={state[collapseId]}
//                       timeout="auto"
//                       unmountOnExit
//                     >
//                       {

//                         task.children.length > 0 ? (
//                           <Button
//                             key={task.id}
//                             className={classes.expandbutton}
//                             onClick={() => handleClick(task.id)}
//                             style={{ backgroundColor: "none" }}

//                           >
//                             {state[task.id] ? <ExpandLess /> : <ExpandMore />}
//                           </Button>
//                         ) : (<TableCell style={{ padding: 10, border: 0 }}></TableCell>)}
//                     </Collapse>
//                   </TableCell>
//                 ) : (<TableCell style={{ padding: 10, border: 0 }}></TableCell>)
//               }

//             </TableRow>
//             {treeTable(task, task.id)}
//           </React.Fragment>
//         )
//       }

//       );
//     }

//   }
//   return (
//     <>
//       {
//         topicdata.children !== undefined && topicdata.children.length > 0 && topicdata !== undefined ? (

//           <Paper classtask={classes.container}>
//             <Table>
//               <TableHead>
//                 <TableRow style={{ backgroundColor: "#babfc7" }}>
//                   {headers.map(({ key }, index) => (
//                     <TableCell key={key}
//                       style={{
//                         //width: index === 0 ? "550px" : "90px",
//                         textAlign: index === 0 ? "left" : "right",
//                         fontSize: "13px",
//                         fontWeight:"bold"
//                       }}>
//                       <div classtask={classes.header}>{key}</div>
//                     </TableCell>
//                   ))}
//                   <TableCell></TableCell>
//                 </TableRow>
//               </TableHead>
//               <TableBody>{treeTable(topicdata, "parentTasks")}</TableBody>
//             </Table>
//           </Paper>
//         ) : null
//       }

//     </>
//   );
// };
// export default ProgressByTopic;