import React from 'react';
import './Student.scss';
import Aux from '../../../../hoc/Wrapper';

export default (props) => {
    return (
        <Aux>
            <div className='friend-thumb'>
                {/* <a href={props.link}> */}
                <a>
                    <img src={props.image} alt={props.name} />
                </a>
            </div>
            <div className='friend-text'>
                <h3>{props.name}</h3>
                <p>{props.interest}</p>
            </div>
        </Aux>
    );
}