import React from 'react';
import './Homework.scss';
import HomeWorkForm from './HomeWorkForm';
import { Problem_source } from '../../common/Functions';

const Homework = () => {
    return (
        <HomeWorkForm problem_source = {Problem_source.homework}></HomeWorkForm>
    )
}

export default Homework