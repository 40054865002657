import React from 'react';
import Aux from '../../hoc/Wrapper';
import { Container, Row, Col } from 'react-bootstrap';
import Breadcrumb from '../../components/Breadcrumb/Breadcrumb';
import { withNamespaces } from 'react-i18next';
import './tutor.scss';
import TutorForm from '../../components/Tutor/TutorForm';

class Tutor extends React.Component {

    render() {        
        return (
            <Aux>
                <Row style={{ backgroundColor: "#f1f1f1", margin: "0" }}>
                    <Breadcrumb
                        currentNode='Profile'
                        prevNode={this.props.location.state ? this.props.location.state : []} />
                </Row>
                <Container>
                    <Row className='tutorprofile'>                        
                        <div className='tutorprofile-right'>
                            <Row>
                                <Col>
                                    <TutorForm></TutorForm>
                                </Col>
                            </Row>
                        </div>
                    </Row>
                </Container>
            </Aux>
        );
    }
}
export default withNamespaces()(Tutor);
