import React, { Component } from 'react';
import Aux from '../../hoc/Wrapper';
import '../Homepage/Homepage.scss';
import SearchInput from '../../components/Homepage/Search/Search';
import Modal from '../../components/Common/ModalComponent/ModalComponent';
import { fetchSubSectionContent } from '../../common/API';
import Spinner from 'react-bootstrap/Spinner'
import img from '../../assets/images/conceptmaprecvised5apr2021v2.png';
import imgWithTryThisIcon from '../../assets/images/tryThisConceptmaprecvised22aug2020v1.png';
import game_1 from '../../assets/images/left-vid-05.png';
import game_2 from '../../assets/images/left-vid-06.png';
import game_3 from '../../assets/images/left-vid-09.png';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import ExpandLessIcon from '@material-ui/icons/ExpandLess';
import SearchIcon from '@material-ui/icons/Search';
import { getTryThisPinsEnabledFlag } from '../../common/Functions';
import ContentOptionsPopup from '../../components/Common/ContentOptionsPopup/ContentOptionsPopup';
import HomeCurriculumContentCard from '../../components/CurriculumContent/CurriculumContentCard/HomeCurriculumContentCard';

class CurriculumMap extends Component {

    constructor(props) {
        super(props);
        this.updateCurriculumMapPosition = this.updateCurriculumMapPosition.bind(this);
        setTimeout(this.updateCurriculumMapPosition, 500)
    }

    state = {
        showModal: false,
        showContentOptions: false,
        isContentOptionsLoading: false,
        zoomLevel: 1.0,
        games: [
            {
                link: 'https://www.walter-fendt.de/html5/phde/newtonlaw2_de.htm',
                image: game_1,
                title: 'Newton\'s 2nd Law Experiment'
            },
            {
                link: 'https://www.walter-fendt.de/html5/phde/inclinedplane_de.htm',
                image: game_2,
                title: 'Forces on the inclined plane'
            },
            {
                link: 'https://phet.colorado.edu/sims/html/forces-and-motion-basics/latest/forces-and-motion-basics_en.html',
                image: game_3,
                title: 'Forces and Motion'
            }
        ],
        videos: null,
        upcomingInteraction: [
            { time: 'Tue, 3 pm', group_tutor: 'Study Group: "Skywalker"', topic: 'Topic: Newton\'s 2nd Law', featured: true },
            { time: 'Tue, 5 pm', group_tutor: 'Tutor: John K.', topic: 'Topic: Kinetic Energy', featured: true },
            { time: 'Wed, 6 pm', group_tutor: 'Study Group: "Skywalker"', topic: 'Topic: Newton\'s 3rd Law' }
        ],
        isSearchBarCollapsed: true,
        contentOptionsPosition: {
            top: 0,
            left: 0
        },
    }


    componentWillMount() {
        this.setState({

            videos: [
                {
                    clicked: this.openModal,
                    image: 'https://i.ytimg.com/vi/4a0FbQdH3dY/hqdefault.jpg?sqp=-oaymwEcCPYBEIoBSFXyq4qpAw4IARUAAIhCGAFwAYABAQ==&rs=AOn4CLBPhtVxTHC0X4oTWiXqMXcvQx2VYw',
                    title: 'For the Love of Physics',
                    videoUrl: 'https://www.youtube.com/embed/4a0FbQdH3dY'
                },
                {
                    clicked: this.openModal,
                    image: 'https://i.ytimg.com/vi/xdIjYBtnvZU/hqdefault.jpg?sqp=-oaymwEcCPYBEIoBSFXyq4qpAw4IARUAAIhCGAFwAYABAQ==&rs=AOn4CLBxq7GcboGxeGMNWJ1eTlHrvNfkjQ',
                    title: 'Feynman\'s Lost Lecture',
                    videoUrl: 'https://www.youtube.com/embed/xdIjYBtnvZU'
                },
                {
                    clicked: this.openModal,
                    image: 'https://i3.ytimg.com/vi/3JH1wCKlR5s/maxresdefault.jpg',
                    title: 'Science Bob Pflugfelder\'s Science',
                    videoUrl: 'https://www.youtube.com/embed/3JH1wCKlR5s'
                },
                {
                    clicked: this.openModal,
                    image: 'https://i.ytimg.com/vi/Zo62S0ulqhA/hqdefault.jpg?sqp=-oaymwEcCPYBEIoBSFXyq4qpAw4IARUAAIhCGAFwAYABAQ==&rs=AOn4CLAMIfRZ2k3UmgiuwPBWULk9lgNzqQ',
                    title: 'What I learned from going blind in space?',
                    videoUrl: 'https://www.youtube.com/embed/Zo62S0ulqhA'
                },
                {
                    clicked: this.openModal,
                    image: 'https://i.ytimg.com/vi/WaGc_Wkb1dk/hqdefault.jpg?sqp=-oaymwEcCPYBEIoBSFXyq4qpAw4IARUAAIhCGAFwAYABAQ==&rs=AOn4CLC2SEjG0bH03PZ_8lbnUqhvcEIhfQ',
                    title: 'For the Love of Physics || Walter Lewin\'s Last',
                    videoUrl: 'https://www.youtube.com/embed/WaGc_Wkb1dk'
                },
            ]
        });


    }

    componentDidMount() {
        window.addEventListener('load', this.updateCurriculumMapPosition);

        let clicking = false;
        let previousX;
        let previousY;

        window.$("#scroll").mousedown(function (e) {
            e.preventDefault();
            previousX = e.clientX;
            previousY = e.clientY;
            clicking = true;
        });

        window.$(document).mouseup(function () {

            clicking = false;
        });

        window.$("#scroll").mousemove(function (e) {

            if (clicking) {
                e.preventDefault();
                var directionX = (previousX - e.clientX) > 0 ? 1 : -1;
                var directionY = (previousY - e.clientY) > 0 ? 1 : -1;
                //$("#scroll").scrollLeft($("#scroll").scrollLeft() + 10 * directionX);
                //$("#scroll").scrollTop($("#scroll").scrollTop() + 10 * directionY);
                window.$("#scroll").scrollLeft(window.$("#scroll").scrollLeft() + (previousX - e.clientX));
                window.$("#scroll").scrollTop(window.$("#scroll").scrollTop() + (previousY - e.clientY));
                previousX = e.clientX;
                previousY = e.clientY;
            }
        });

        window.$("#scroll").mouseleave(function (e) {
            clicking = false;
        });

        // To handle touch on curriculum graph
        window.$("#scroll").on("touchstart", function (e) {
            // e.preventDefault();
            previousX = e.clientX;
            previousY = e.clientY;
            clicking = true;
        });

        window.$(document).on("touchend", function () {
            clicking = false;
        });

        window.$("#scroll").on("touchmove", function (e) {
            if (clicking) {
                e.preventDefault();
                var touch = e.originalEvent.touches[0] || e.originalEvent.changedTouches[0];
                var directionX = (previousX - touch.pageX) > 0 ? 1 : -1;
                var directionY = (previousY - touch.pageY) > 0 ? 1 : -1;
                window.$("#scroll").scrollLeft(window.$("#scroll").scrollLeft() + 10 * directionX);
                window.$("#scroll").scrollTop(window.$("#scroll").scrollTop() + 10 * directionY);
                // window.$("#scroll").scrollLeft(window.$("#scroll").scrollLeft() + (previousX - touch.pageX));
                // window.$("#scroll").scrollTop(window.$("#scroll").scrollTop() + (previousY - touch.pageY));
                previousX = touch.pageX;
                previousY = touch.pageY;
            }
        });

        window.$("#scroll").on("touchend", function (e) {
            clicking = false;
        });
    }

    componentWillUnmount() {
        window.removeEventListener('load', this.updateCurriculumMapPosition)
    }

    updateCurriculumMapPosition() {
        window.$("#scroll").scrollLeft(730);
        window.$("#scroll").scrollTop(570);
    }

    modalBody = null;
    modalTitle = null;
    showButton = false;

    openModal = (event, modalTitle, modalBody, showButton = false) => {
        event.preventDefault();
        this.modalBody = modalBody;
        this.modalTitle = modalTitle;
        this.showButton = showButton;
        this.setState({ showModal: true });
    }

    openContentOptions = (event, modalBody, position, isContentOptionsLoading) => {
        // event.preventDefault();
        this.modalBody = modalBody;
        this.setState({
            showContentOptions: true,
            isContentOptionsLoading: isContentOptionsLoading,
            contentOptionsPosition: position,
        });
    }

    closeModal = () => {
        this.setState({ showModal: false });
    }

    generateProgressBar = (ctx, network) => {
        for (let node of this.state.graph.nodes) {
            let nodeBound = network.getBoundingBox(node.id);
            let coordinates = network.getPosition(node.id);
            let posLeft = nodeBound.left > 0 ? nodeBound.left : nodeBound.left * -1;
            let posRight = nodeBound.right > 0 ? nodeBound.right : nodeBound.right * -1;
            // let radius = posRight - posLeft;
            let x = coordinates.x > 0 ? coordinates.x : coordinates.x * -1;
            let radius1 = x - posLeft;
            let radius2 = x - posRight;
            radius1 = radius1 > 0 ? radius1 : radius1 * -1;
            radius2 = radius2 > 0 ? radius2 : radius2 * -1;

            // outer progress
            let progress1 = (node.completion * 2) / 100;
            ctx.beginPath();
            ctx.lineWidth = 3
            ctx.arc(coordinates.x, coordinates.y, radius1 > radius2 ? radius1 - 5 : radius2 - 5, (0 + 1.5) * Math.PI, (progress1 + 1.5) * Math.PI);
            ctx.strokeStyle = '#5e7df7';
            ctx.stroke();

            let progress2 = (node.mastery * 2) / 100;
            // inner progress
            ctx.beginPath();
            ctx.lineWidth = 3
            ctx.arc(coordinates.x, coordinates.y, radius1 > radius2 ? radius1 - 11 : radius2 - 11, (0 + 1.5) * Math.PI, (progress2 + 1.5) * Math.PI);
            ctx.strokeStyle = '#f00';
            ctx.stroke();
        }
    }

    changeZoom = (zoomType) => {
        let newZoomLevel = zoomType === 'zoomIn' ? this.state.zoomLevel + 0.1 : this.state.zoomLevel - 0.1
        newZoomLevel = Number(newZoomLevel.toFixed(1))
        if (!(newZoomLevel > 2 || newZoomLevel < 1)) {
            this.setState({ zoomLevel: newZoomLevel })
        }
    }

    getContentOptionsJSX = (data) => {
        return (
            <div className="content-options">
                <HomeCurriculumContentCard
                    title="Recommended Content for Your Cognitive Profile"
                    data={[data[0]]}
                    linkStateObj={{ prevPath: ['/'], prevNode: ['Dashboard'] }} />
                {data.length > 1 ?
                    <HomeCurriculumContentCard
                        title="Other Curated Content Options"
                        data={data.splice(1)}
                        linkStateObj={{ prevPath: ['/'], prevNode: ['Dashboard'] }} />
                    : null}
            </div>
        )
    }

    openContentOptionsModal = (e, subSectionId) => {
        let isTouchEvent = false
        if (e.nativeEvent instanceof TouchEvent) {
            isTouchEvent = true
        }

        let topPosition = e.pageY
        let leftPosition = e.pageX - 50
        if (isTouchEvent) {
            var touch = e.changedTouches[0];
            topPosition = touch.pageY
            leftPosition = touch.pageX - 50
        }
        let position = {
            top: topPosition,
            left: leftPosition
        }

        this.openContentOptions(e,
            <div className="progress-bar-container"><Spinner animation="border" role="status" /></div>,
            position, true)

        const reqData = { params: { subSectionId } }
        fetchSubSectionContent(reqData)
            .then(res => {
                res.data.content.length > 0 ?
                    this.openContentOptions(e,
                        this.getContentOptionsJSX(res.data.content),
                        position, false)
                    : this.openContentOptions(e,
                        <p>No Content Found.</p>,
                        position, false)
            })
            .catch(err => {
                console.error(err.message)
                if (err.response.status === 404) {
                    this.openContentOptions(e,
                        <p>No Content Found.</p>,
                        position, false)
                }
            })
    }

    searchBarCollapseToggle = () => {
        this.setState({ isSearchBarCollapsed: !this.state.isSearchBarCollapsed })
    }
    render() {
        return (
            <Aux>
                <div className='mt-3 mb-3' style={{marginLeft:"0.75rem"}}>
                    <h2>Curriculum Map</h2>
                </div>
                <div className='middle-section'>
                    <div className='img-bg' id="scroll" style={{ height: "761px", overflowX: "hidden" }}>
                        <img src={getTryThisPinsEnabledFlag() ? imgWithTryThisIcon : img} className='graph-image' usemap="#image_map" style={{ transform: `scale(${this.state.zoomLevel})` }} />
                        <map name="image_map">
                            <area
                                onMouseEnter={(e) => !this.state.isContentOptionsLoading ? this.openContentOptionsModal(e, 2) : null}
                                onClick={(e) => this.openContentOptionsModal(e, 2)}
                                alt="Newton's 1st Law" title="Newton's 1st Law" coords="928,760,49" shape="circle" />
                            <area
                                onMouseEnter={(e) => !this.state.isContentOptionsLoading ? this.openContentOptionsModal(e, 3) : null}
                                onClick={(e) => this.openContentOptionsModal(e, 3)}
                                alt="Newton's 2nd Law" title="Newton's 2nd Law" coords="1124,869,62" shape="circle" />
                            <area
                                onMouseEnter={(e) => !this.state.isContentOptionsLoading ? this.openContentOptionsModal(e, 8) : null}
                                onClick={(e) => this.openContentOptionsModal(e, 8)}
                                alt="Newton's 3rd Law" title="Newton's 3rd Law" coords="868,955,57" shape="circle" />
                            <area
                                onMouseEnter={(e) => !this.state.isContentOptionsLoading ? this.openContentOptionsModal(e, 10) : null}
                                onClick={(e) => this.openContentOptionsModal(e, 10)}
                                alt="Gravitation Near Earth" title="Gravitation Near Earth" coords="1685,1113,78" shape="circle" />
                            <area
                                onMouseEnter={(e) => !this.state.isContentOptionsLoading ? this.openContentOptionsModal(e, 11) : null}
                                onClick={(e) => this.openContentOptionsModal(e, 11)}
                                alt="Newton's Law of Gravitation" title="Newton's Law of Gravitation" coords="1895,815,57" shape="circle" />
                        </map>
                    </div>
                    <div className={`search-content ${!this.state.isSearchBarCollapsed ? 'show-every-search-content' : ''}`}>
                        <div className="search-result visible-search-component d-flex align-items-center">
                            <span>Engage Your Curiosity: Recommended Questions/Explorations</span>
                            <div className="d-flex justify-content-around flex-grow-1">
                                {this.state.isSearchBarCollapsed ?
                                    <ExpandMoreIcon onClick={this.searchBarCollapseToggle} />
                                    : <ExpandLessIcon onClick={this.searchBarCollapseToggle} />}
                                <SearchIcon onClick={this.searchBarCollapseToggle} />
                            </div>
                        </div>
                        <SearchInput mxAuto />
                    </div>
                    <div className='zoom-buttons d-flex flex-column'>
                        <button className='zoom-in' onClick={() => this.changeZoom('zoomIn')}></button>
                        <button className='zoom-out' onClick={() => this.changeZoom('zoomOut')}></button>
                    </div>
                    <div className='graph-legend'>
                        <div className='legend-completed'><span>% Completion</span></div>
                        <div className='legend-mastery'> <span>% Proficiency</span></div>
                    </div>
                </div>
                <Modal
                    show={this.state.showModal}
                    handleClose={this.closeModal}
                    title={this.modalTitle}
                    body={this.modalBody}
                    showButton={this.showButton} />
                <ContentOptionsPopup
                    showContentOptions={this.state.showContentOptions}
                    handleClose={() => this.setState({ showContentOptions: false })}
                    body={this.modalBody}
                    contentOptionsPosition={this.state.contentOptionsPosition} />
            </Aux>
        );
    }
}

export default CurriculumMap;