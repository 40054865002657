import React from 'react';
import { Link } from 'react-router-dom';
import { Row, Col } from 'react-bootstrap';
import {
    Card,
    CardContent,
    CardMedia,
} from "@material-ui/core";
import Spinner from 'react-bootstrap/Spinner'
import { User_Role, getCurrentUserRole, getCurrentUserId, getTryThisPinsEnabledFlag } from '../../common/Functions';
import { makeStyles } from "@material-ui/core/styles";
import ImageList from "@material-ui/core/ImageList";
import Typography from '@mui/material/Typography';
import betaimage from '../../assets/images/beta-11.png'
import {
    imgURL
} from '../../common/API'
import TryThisPin from '../../components/Common/TryThisPin/TryThisPin';
import parse from 'html-react-parser';
import './ChallengeCardcss.scss'

const useStyles = makeStyles((theme) => ({
    root: {
        "& > *": {
            margin: theme.spacing(3),
            width: "25ch"
        }
    },
    gridList: {
        display: "inline-flex",
        height: "auto",
        position: "relative",
        overflowY: "unset",
        marginBottom: "10px !important",
        columnGap: "50px",
        //borderBottom: "1px solid rgb(209, 209, 209)"
        width: "50%",
    },
    card: {
        // minWidth: "300px",
        // height: "370px",
        height: "200px !important",
        width: "90% !important",
        display: "flex",
        flexDirection: "column",
        // justifyContent: "flex-end",
        margin: "15px",
        borderRadius: "10px",
        position: "relative",
        overflowY: "unset",
        overflowX: "unset",
        backgroundColor: "#fff"
    },
    cardStudent: {
        // minWidth: "300px",
        height: "220px",
        margin: "15px",
        borderRadius: "10px",
        //position: "relative",
        overflowY: "unset",
        overflowX: "unset",
        backgroundColor: "#fff",
        width:"100%"
    },
    contentImage: {
        padding: "5px",
        borderRadius: "12px",
        maxWidth: "100%",
        height: "100px",
        // width:"300px",
        // maxWidth:"305px",

        margin: "0 auto",
        objectFit: "contain",
    },
    font16: {
        fontSize: "16px !important",
    },
   
    contentClass: {
        padding: "10px !important"
    }

}));
const ChallengeCard = (props) => {
    const classes = useStyles()
    const getColor = (complexity) => {

        if (complexity == "Medium")
            return 'yellow'
        else if (complexity == "Easy")
            return "green"
        else
            return 'orange';
    }
    return (
        <div className='home-curriculum-content-card'>
            
            {
                props.challengeLoader ? (<div className="progress-bar-container"><Spinner animation="border" role="status" /></div>) : (
                    <div className="dashboarcards border-bottom-last">
                        <div className='questions' >
                            {
                                props.data !== undefined && props.data !== null && props.data.length > 0 ? (
                                    <>
                                        {props.data.map((challenge) => {
                                            // debugger;
                                            let pathname = `/answerboard/c${challenge.id}`;

                                            let str = challenge.question.replace(/(<([^>]+)>)/ig, '');
                                            let data1 = parse(str)

                                            return (
                                                <ImageList className={classes.gridList} id={challenge.id}>
                                                    <>
                                                        <Card className={classes.cardStudent} style={{ position: 'relative' }}>
                                                            <Link
                                                                to={{
                                                                    pathname: pathname,
                                                                    state: { prevPath: ['/dashboard'], prevNode: ['Dashboard'] }
                                                                }}
                                                            >

                                                                {challenge.has_try_this_pin && getTryThisPinsEnabledFlag() ?
                                                                    (
                                                                        <>
                                                                            <TryThisPin
                                                                                showPin
                                                                                style={{
                                                                                    position: 'absolute',
                                                                                    //left: '0px', top: '0px'
                                                                                }}
                                                                            />
                                                                            <CardMedia
                                                                                component="img"
                                                                                image={imgURL + challenge.thumbnail}
                                                                                className={classes.contentImage}
                                                                            />
                                                                        </>
                                                                    )
                                                                    :
                                                                    (
                                                                        <>
                                                                            <CardMedia
                                                                                component="img"
                                                                                image={imgURL + challenge.thumbnail}
                                                                                className={classes.contentImage}
                                                                            />
                                                                        </>
                                                                    )
                                                                }
                                                                {
                                                                    challenge.has_QA_Test_Flag ?
                                                                        // <div className='betaImage' title='Beta testing'></div> : null
                                                                        <div title='Beta testing'>
                                                                            <img src={betaimage} style={{
                                                                                position: 'absolute',
                                                                                width: "80px",
                                                                                //height: "80px",
                                                                                top: "-4px",
                                                                                left: "-18px"

                                                                            }}></img>
                                                                        </div> : null
                                                                }

                                                                <CardContent className={classes.contentClass}>
                                                                    <Typography className='ch-cardstitle'>
                                                                        <div style={{ fontSize: "12px", boxShadow: "none", textDecoration: 'none', fontWeight: "600" }}>
                                                                            <span className="ch-title"> {challenge.title}</span>
                                                                        </div>

                                                                    </Typography>
                                                                    <Typography>
                                                                        <div class="questionfontsmulti" dangerouslySetInnerHTML={{ __html: data1 }}></div>
                                                                    </Typography>
                                                                    <div style={{ display: "flex", justifyContent: "space-between" }}>
                                                                        <Typography className='ch-cardstext'>
                                                                            <div className='challenge-status'>

                                                                                
                                                                                <Row className='status-title-source'>
                                                                                    <Col sm="12">
                                                                                        <div className='statusContainer'>

                                                                                            <div>Source: {challenge.bookname}</div>
                                                                                        </div>
                                                                                    </Col>
                                                                                </Row>

                                                                            </div></Typography>

                                                                        <Typography className='ch-cardstext'>
                                                                            <div className='challenge-status'>
                                                                                <Row className='status-title'>
                                                                                    <Col sm="12">
                                                                                        <div className='statusContainer'>
                                                                                            <span >{challenge.type}
                                                                                            </span>
                                                                                            <div title={challenge.complexity} style={{ marginLeft: '4px', width: '15px', height: '15px', background: getColor(challenge.complexity) }}></div>
                                                                                        </div>
                                                                                    </Col>
                                                                                </Row>

                                                                            </div></Typography>
                                                                    </div>
                                                                </CardContent>
                                                            </Link>
                                                        </Card>
                                                    </>
                                                </ImageList>
                                            )
                                        })
                                        }
                                    </>

                                ) : (
                                    <Card className={classes.cardStudent} 
                                    style={{ position: 'relative', marginTop:"20px", padding:"50px"
                                 }}>No interactive problems available for this currently</Card>
                                )
                            }

                        </div>
                    </div>
                )
            }

        </div >
    );
}
export default ChallengeCard
