import React from 'react';
import './MainContent.scss';
import Ratings from '../../Common/Ratings/Ratings';
import Aux from '../../../hoc/Wrapper';
import { Row, Col } from 'react-bootstrap';
import VideoContent from './VideoContent/VideoContent';
import HtmlContent from './HtmlContent/HtmlContentLearningContent';
import Modal from '../../Common/ModalComponent/ModalComponent';
import Radiobutton from '../../Common/Radiobutton/Radiobutton';
import Button from '../../Common/Button/Button';
import { saveReview, fetchReview } from '../../../common/API'
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { withRouter } from 'react-router-dom';
import { getCurrentUserId } from '../../../common/Functions';

class MainContent extends React.Component {

    state = {
        video_content_id: this.props.topic.type === 'Video' ? this.props.topic.video_content_id : null,
        showModal: false,
        contentRating: this.props.topic.rating,
        username: 'Jennifer Smith',
        overallRating: 0,
        explanationRating: 0,
        presentationRating: 0,
        pace: 50,
        abstractRating: '',
        reviewText: '',

    }

    // UNSAFE_componentWillReceiveProps = (props) => {
    //     this.setState({
    //         video_content_id: props.topic.type === 'Video' ? props.topic.video_content_id : null
    //     })
    // }

    openModal = (event) => {
        event.preventDefault();
        if (this.props.location.state) {
            if (this.props.location.state.videoPlayer) {
                this.props.location.state.videoPlayer.pauseVideo();
            }
        }
        const reqData = {
            params: {
                contentId: this.props.contentId,
                userId: getCurrentUserId(),
            }
        }
        fetchReview(reqData)
            .then(res => {
                if (res.data.success) {
                    this.setState({
                        showModal: true,
                        ...res.data.data
                    })
                } else {
                    this.setState({ showModal: true })
                }
            })
            .catch(err => {
                console.error(err.message)
                alert('Some error occurred!')
                this.props.history.goBack()
            })
    }

    closeModal = () => {
        if (this.props.location.state) {
            if (this.props.location.state.videoPlayer) {
                this.props.location.state.videoPlayer.playVideo();
            }
        }
        this.setState({ showModal: false });
    }

    handleExplanationRatingChange = (newValue) => {
        this.setState({
            explanationRating: newValue,
        })
    }

    handleOverallRatingChange = (newValue) => {
        this.setState({
            overallRating: newValue
        })
    }

    handlePresentationRatingChange = (newValue) => {
        this.setState({
            presentationRating: newValue,
        })
    }

    handlePaceChange = (e) => {
        this.setState({
            pace: Number(e.target.value)
        })
    }

    handleReviewTextChange = (e) => {
        this.setState({
            reviewText: e.target.value
        })
    }

    handleAbstractReviewChange = (e) => {
        this.setState({
            abstractRating: e.target.value
        })
    }

    saveReview = () => {
        const reqData = {
            userId: getCurrentUserId(),
            overallRating: this.state.overallRating,
            explanationRating: this.state.explanationRating,
            presentationRating: this.state.presentationRating,
            pace: this.state.pace,
            abstractRating: this.state.abstractRating,
            reviewText: this.state.reviewText,
            contentId: this.props.contentId,
        }
        if (reqData.overallRating === 0) {
            toast("Please fill all the fields correctly.", {
                type: toast.TYPE.WARNING,
                position: toast.POSITION.BOTTOM_RIGHT
            })
        } else {
            saveReview(reqData)
                .then(res => {
                    toast(res.data.message, {
                        type: toast.TYPE.INFO,
                        position: toast.POSITION.BOTTOM_RIGHT
                    })
                    this.setState({
                        contentRating: res.data.data.contentRating
                    })
                    this.closeModal()
                })
                .catch(err => {
                    console.error(err.message);
                    alert('Some error occurred!')
                    this.props.history.goBack()
                })
        }
    }


    render() {

        console.log(this.props.topic)

        const modalBody = (
            <div className='review-modal'>
                <div className='write-review'>
                    <h2>Overall Rating</h2>
                    <div className='rating'>
                        <Ratings stars={this.state.overallRating} onChange={this.handleOverallRatingChange} className='ratings' />
                    </div>
                </div>
                <div className='write-review'>
                    <h2>Clear Explanation</h2>
                    <div className='rating'>
                        <Ratings stars={this.state.explanationRating} onChange={this.handleExplanationRatingChange} className='ratings' />
                    </div>
                </div>
                <div className='write-review'>
                    <h2>Engaging Presentation</h2>
                    <div className='rating'>
                        <Ratings stars={this.state.presentationRating} onChange={this.handlePresentationRatingChange} className='ratings' />
                    </div>
                </div>
                <div className='write-review'>
                    <h2>Well-paced, Fast or Slow</h2>
                    <div className='slide-container'>
                        <input type="range" min="1" max="100" value={this.state.pace} onChange={this.handlePaceChange} id="myRange" />
                        <label className='slow'>Slow</label>
                        <label className='fast'>Fast</label>
                    </div>
                </div>
                <div className='write-review well-paced-checkbox'>
                    <div className='checkbox'>
                        <fieldset onChange={this.handleAbstractReviewChange.bind(this)}>
                            <div className="abstractReview">
                                <Radiobutton name="abstractreview" value='Needs Improvement' checked={this.state.abstractRating === 'Needs Improvement'} text='Needs Improvement' />
                                <Radiobutton name="abstractreview" value='Good' checked={this.state.abstractRating === 'Good'} text='Good' />
                                <Radiobutton name="abstractreview" value='Very Good' checked={this.state.abstractRating === 'Very Good'} text='Very Good' />
                                <Radiobutton name="abstractreview" value='Exceptional' checked={this.state.abstractRating === 'Exceptional'} text='Exceptional' />
                                <Radiobutton name="abstractreview" value='Irresistible' checked={this.state.abstractRating === 'Irresistible'} text='Irrestible' />
                            </div>
                        </fieldset>
                    </div>
                </div>
                <textarea rows="3" class="review-text" placeholder='Write a review' value={this.state.reviewText} onChange={this.handleReviewTextChange}></textarea>
            </div>
        );
        const modalTitle = 'Write a Review';
        const customButton = (
            <div className='mx-auto'>
                <Button className='mr-2 yellow-btn' clicked={this.saveReview}>Submit</Button>
                <Button className="grey-btn" clicked={this.closeModal}>Cancel</Button>
            </div>
        );


        return (
            <Aux>
                <Row className='top-title'>
                    <Col className='top-title-col'>
                        <div className="d-flex align-items-end flex-wrap">
                            <h1 className='title'>{this.props.topic.title}</h1>
                            {this.props.topic.subPartData && this.props.topic.subPartData.length > 0 && this.props.topic.subPartData !== "ALPHA" ?
                                <span className="sub-part">{this.props.topic.subPartData}</span> : null}
                        </div>
                        <div className='rating-button-box'>
                            <Ratings stars={this.state.contentRating} className='ratings' readOnly />
                            <Button
                                clicked={(event) => this.openModal(event)}
                                className='rating-button yellow-btn '>Write a Review</Button>


                        </div>
                    </Col>
                </Row>
                {this.props.topic.subPartData === "ALPHA" ?
                    <Row>
                        <span className="alpha-data-label text-right flex-grow-1">
                            * Alpha-Test Content
                        </span>
                    </Row> :
                    null
                }
                <Row>
                    <Col className='top-source-col'>
                        <p className='source-text mb-0'>Source: {this.props.topic.source}</p>
                    </Col>
                </Row>

                <Row>
                    <Col onMouseUp={this.props.topic.type !== 'Video' ? this.props.onMouseUp : null}
                        onTouchEnd={this.props.topic.type !== 'Video' ? this.props.onMouseUp : null}>
                        {this.props.topic.type === 'Video' ?
                            <VideoContent
                                videoId={this.props.topic.videoId}
                                SubSection_id={this.props.topic.SubSection_id}
                                videoContentId={this.props.topic.video_content_id}
                                subPartStartTime={this.props.topic.subPartStartTime}
                                subPartEndTime={this.props.topic.subPartEndTime}
                                toggleVC={this.props.toggleVC}
                                raisebuttondisabled={this.props.raisebuttondisabled}
                                isTeacher={this.props.isTeacher}
                                call_type={this.props.call_type}
                                Content_type="Video"
                                isLimitExceed={this.props.isLimitExceed}
                            />
                            : <HtmlContent content={this.props.topic.content}
                                Content_type="Text"
                                contentId={this.props.contentId}
                                textbook_id={this.props.topic.textbook_id}
                                iseditablecontent={this.props.iseditablecontent}
                                hintObjectList={this.props.hintObjectList}
                                highlightedObjectList={this.props.highlightedObjectList}
                                showHighlight={this.props.showHighlight}
                                showHint={this.props.showHint}
                                toggleVC={this.props.toggleVC}
                                isHintVisible={this.props.isHintVisible}
                                isTeacher={this.props.isTeacher}
                                call_type={this.props.call_type}
                                hideHint={this.props.hideHint} 
                                SubSection_id={this.props.topic.SubSection_id}/>}
                    </Col>
                </Row>
                <Modal
                    show={this.state.showModal}
                    handleClose={this.closeModal}
                    title={modalTitle}
                    body={modalBody}
                    customButton={customButton} />
            </Aux>
        );
    }
}

export default withRouter(MainContent);