import React from 'react';
import './HomeCurriculumContentCard.scss';
import { Link } from 'react-router-dom';
import Ratings from '../../Common/Ratings/Ratings';
import Tooltip from '@material-ui/core/Tooltip';
import RatingsHover from '../../Common/Ratings/RatingsHover';
import arrowImage from '../../../assets/images/icon_arrow.svg';
import { Row, Col } from 'react-bootstrap';
import brainIcon from '../../../assets/icons/brain_icon.ico';
import { isNil } from 'lodash';
import Spinner from 'react-bootstrap/Spinner'

export default (props) => {
   
    return (
        <div className='home-curriculum-content-card'>
            <h4 className='title'>{props.title}</h4>
            {
                props.contentloader ? (<div className="progress-bar-container"><Spinner animation="border" role="status" /></div>) : (
                    <div className='content-card'>
                        {
                            props.data.length > 0 ? (
                                <>
                                    {
                                        props.data.map(content => (
                                            !isNil(content.id) ?
                                                <Link to={{
                                                    pathname: `/curriculum-content/${content.id}`,
                                                    flag: content.flag
                                                }} >
                                                    <div className='video-thumb'>
                                                        {content.thumbnail.length > 0 ?
                                                            <img src={content.thumbnail} alt={content.title} />
                                                            :
                                                            <img src='/images/ic-challenge-placeholder.svg' alt={content.title} />
                                                        }
                                                    </div>
                                                    <div className='content-card-text'>
                                                        {
                                                            content.title !== null ?? (<Tooltip title={content.title}>
                                                                <h3>{content.title}</h3>
                                                            </Tooltip>)
                                                        }

                                                        <p>{content.type} {content.source ? <> | Source: {content.source} </> : ''}</p>
                                                        <Row>
                                                            <Col>
                                                                <div className="clearfix rating-hover">
                                                                    <Ratings stars={content.rating} className='ratings main-video-rating mr-2' readOnly />
                                                                    <img src={arrowImage} alt="" />
                                                                    <RatingsHover
                                                                        explanationStars={content.average_explanation_rating || 0}
                                                                        presentationStars={content.average_presentation_rating || 0}
                                                                        paceRange={content.average_pace_review} />

                                                                </div>
                                                            </Col>
                                                            {content.is_interactive ?
                                                                <Col xs={3}>
                                                                    <div className="interactive-video-icon">
                                                                        <img title="Interactive" alt="" src={brainIcon}></img>
                                                                    </div>
                                                                </Col>
                                                                : null
                                                            }
                                                        </Row>
                                                    </div>

                                                    {content.flag === "ALPHA" ?
                                                        <Row>
                                                            <span className="alpha-data-label text-right flex-grow-1">
                                                                * Alpha-Test Content
                                                            </span>
                                                        </Row> :
                                                        null
                                                    }
                                                </Link> : <a target='_blank' href={content.url}>{content.name}</a>
                                        ))}
                                </>

                            ) : (
                                <div>No AI-Interactive Content available for this currently</div>
                            )
                        }

                    </div>
                )
            }

        </div >
    );
}
