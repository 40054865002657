import React from 'react';
import './ProgressUpdate.scss';
import Aux from '../../../hoc/Wrapper';

export default (props) => {
    return (
        <Aux>
            <h1 className={props.danger ? 'text-danger' : 'text-primary'}>{props.percentage}</h1>
            <div className='w3Border'>
                <div className={props.danger ? 'w3-red' : 'w3-blue'} style={{width: props.percentage}}></div>
            </div>
            <h4 className='text-muted mt-2'>{props.text}</h4>
        </Aux>
    );
}