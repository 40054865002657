import React from 'react';
import Aux from '../../hoc/Wrapper';
import { Container, Row, Col } from 'react-bootstrap';
import { withNamespaces } from 'react-i18next';
import Flow from '../../components/SolutionGraph/flow';
import "../../styles/App.scss";

class SolutionGraph extends React.Component {
    render() {
        return (
            <Aux>
                <Container className='mainDiv'>
                    <Row className='challenges'>
                        <div className='challange-right'>
                            <Row>
                                <Col>
                                    <Flow readOnly={false} hasCheckMyWOrk={false}
                                    ></Flow>
                                </Col>

                            </Row>
                        </div>
                    </Row>
                </Container>
            </Aux>
        );
    }
}
export default withNamespaces()(SolutionGraph);