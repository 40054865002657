import React, { Component } from 'react';
import './Breadcrumb.scss';
import Container from '../../hoc/Container';
import { Link } from 'react-router-dom'
import ArrowForwardIcon from '@material-ui/icons/ArrowForward';

class Breadcrumb extends Component {

    routes = {
        'Curiosity Exploration': '/curiosity-exploration',
        'Learning Content': '/curriculum-content',
        'Learning Content (Interactive Video)': '/curriculum-content/2',
        'Learning Content (Interactive Textbook)': '/curriculum-content/1',
        'Answer Board': '/answerboard',
        'Challenge': '/challenges',
        'Notes/Bookmarks': '/notes-bookmarks',
        'Interactive Content': '/curriculum-content',
        'Interactive Problems': '/curriculum-problems',
    }

    getNewState = (dictObj, start, end) => {
        let newState = {}
        newState.prevNode = dictObj.prevNode.slice(start, end)
        newState.prevPath = dictObj.prevPath.slice(start, end)
        return newState
    }

    getBreadcrumbLink = (path, name, state) => {
        let topath = ""
        if (path.includes('#Video'))
            topath = '/curriculum-content#Video'
        else if (path.includes('#Textbook'))
            topath = '/curriculum-content#Textbook'

        return (
            <div>
                {topath !== "" ?
                    <Link className='link' to={topath}>{name}</Link>
                    :
                    <Link className="link" to={{ pathname: path, state: state }}>{name}</Link>
                }

                <ArrowForwardIcon
                    style={{
                        color: "#007bff",
                        width: "0.75em",
                        height: "0.75em",
                        
                    }} />
            </div>
        )
    }

    generateBreadcrumbs = () => {
        let returnJSX = null
        if (this.props.prevNode && this.props.prevNode.prevPath) {
            if (this.props.prevNode.prevPath[this.props.prevNode.prevPath.length - 1] !== window.location.pathname) {
                returnJSX = this.props.prevNode.prevPath.map((item, index) => (
                    this.getBreadcrumbLink(item, this.props.prevNode.prevNode[index], this.getNewState(this.props.prevNode, 0, index))
                ))
            } else {
                returnJSX = this.props.prevNode.prevPath.map((item, index) => {
                    if (this.props.prevNode.prevNode.length === index + 1) {
                        // last one
                        return null
                    } else {
                        return this.getBreadcrumbLink(item, this.props.prevNode.prevNode[index], this.getNewState(this.props.prevNode, 0, index))
                    }
                })
            }
        }
        return returnJSX
    }

    render() {
        return (
            <div className='breadcrumb'>
                <Container className={this.props.className} >
                    {this.props.currentNode ?
                        <div className='extended-breadcrumb d-flex'>
                            {this.generateBreadcrumbs()}
                            {/* <span>{this.props.currentNode}</span> */}
                        </div>
                        : null}
                </Container>
            </div>
        );
    }
}

export default Breadcrumb;