import React, { useState, useEffect } from 'react';
import './AssignmentList.scss'
import AssignmentCards from '../../components/Assignment/AssignmentCards';
import { getAssignmentList, updateassignment, getassignmenttitle } from '../../common/API'
import CircularProgress from '@mui/material/CircularProgress';
import { getCurrentUserId, isDemoUserFlagged, detectDevice, TABLET, DESKTOP, getMenuSelectedItem, getCurrentUserRole, User_Role, showFullApp } from '../../common/Functions';
import { testInstructionText } from '../../common/Constants';
import { makeStyles } from "@material-ui/core/styles";
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import infoIcon from '../../assets/images/info_icon.png'
import Modal from '../../components/Common/ModalComponent/ModalComponent';
import whiteBoardVideo from "../../assets/videos/Quiz_HelpCenter.mp4";
import { Button } from 'react-bootstrap';
import dayjs from 'dayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import TextField from '@mui/material/TextField';
import Select from 'react-select';
import Stack from '@mui/material/Stack';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';

const useStyles = makeStyles((theme) => ({
    root: {
    },
    maignGrid: {
        marginLeft: "0px"
    },
}));

const AssignmentList = () => {
    const classes = useStyles()
    const [assignmentData, setassignmentData] = useState([])
    const [loader, setLoader] = useState(false)
    const [whiteBoardVideoDiv, setwhiteBoardVideoDiv] = useState("")
    const [updateList, setUpdateList] = useState(false)
    const [showModalVideoUsageGuide, setshowModalVideoUsageGuide] = useState(false)
    const [displayMessage, setdisplayMessage] = useState("Fetching Data...")
    const [homewrokTitleData, sethomewrokTitleData] = useState([])



    const [generalInstructions, setgeneralInstructions] = useState("")
    const [valuestart, setvaluestart] = useState(new Date())
    const [valueend, setvalueend] = useState(new Date())
    const [error, seterror] = useState("")
    const [enderror, setenderror] = useState("")
    const [title, settitle] = useState("")
    const [graded, setgraded] = useState(false)
    const [description, setdescription] = useState("")
    const [assprofessor, setassprofessor] = useState(null)
    const [afteraddhwfinalids, setafteraddhwfinalids] = useState([])
    const [disabledChallengeMessage, setdisabledChallengeMessage] = useState(false)
    const [selectedTitle, setselectedTitle] = useState({ key: "", value: "" })
    const [titleDisabled, settitleDisabled] = useState(false)
    const [assignmentId, setassignmentId] = useState(0)
    const [challengeSelected, setchallengeSelected] = useState(false)
    const [timed, settimed] = useState(false)
    const [guided, setguided] = useState(true)
    const [totalMinutes, settotalMinutes] = useState(1)
    const [nooftrials, setnooftrials] = useState(1)
    const [assignmentType, setassignmentType] = useState("")
    const [generalInstructionsContent, setgeneralInstructionsContent] = useState("")
    const [generalInstructionEditor, setgeneralInstructionEditor] = useState("")
    const [errorTextGraded, seterrorTextGraded] = useState("")
    const [errorTextTimed, seterrorTextTimed] = useState("")
    const [selectedIdsForAssignments, setselectedIdsForAssignments] = useState("")
    const [isChallengeSelected, setisChallengeSelected] = useState(false)
    const [opencreatehomeworkmodal, setopencreatehomeworkmodal] = useState(false)
    const [OpenDeleteConfirmation, setOpenDeleteConfirmation] = useState(false)

    useEffect(() => {
        if (getCurrentUserRole() !== User_Role.Student) {
            const reqData = { params: { "user_id": getCurrentUserId() } };
            getassignmenttitle(reqData)
                .then(res => {
                    if (res.data.data !== undefined && res.data.data !== null) {
                        sethomewrokTitleData(res.data.data)
                    }
                })
                .catch(err => {
                    console.error(err.message)
                    setLoader(false)
                })
        }
        getAssignmentDetails()

    }, []);

    const getAssignmentDetails = () => {
        setLoader(true)
        const reqData = { params: { 'user_id': getCurrentUserId(), "course_id": getMenuSelectedItem() } }
        getAssignmentList(reqData).then(res => {
            debugger;
            if (res.data !== undefined && res.data !== null && res.data.length > 0) {
                // If TryItOut user then show only 2 PT => 1 Full PT and 1 Half PT //
                if (isDemoUserFlagged()) {
                    let finalData = []
                    let counter = 0
                    let resData = res.data
                    resData.map(item => {
                        if (item.totalchallengeperPtTest < 30 && counter === 0) {
                            finalData.push(item)
                            counter = 1
                        }
                    })

                    counter = 0
                    resData.map(item => {
                        if (item.totalchallengeperPtTest > 30 && counter === 0) {
                            finalData.push(item)
                            counter = 1
                        }
                    })

                    if (finalData.length > 0) {
                        setassignmentData(finalData)
                    }
                }
                else {
                    setassignmentData(res.data)
                }
                setLoader(false)

            }
            else {
                setLoader(false)
                setassignmentData([])
                setdisplayMessage("No Data Found.")
            }
        }).catch(err => {
            setLoader(false)
            console.error(err.message);
        })
    }

    useEffect(() => {
        if (updateList) {
            getAssignmentDetails()
        }
    }, [updateList]);

    const itemchangedhandler = (event) => {
        setUpdateList(true)
    }

    const usageguidehandler = () => {
        setshowModalVideoUsageGuide(true)
        setwhiteBoardVideoDiv(
            <div className='videoModalDiv'>
                <div className='videoDiv'>
                    <video playsinline muted loop={true} autoPlay={true} width="590" height="270" controls>
                        <source src={whiteBoardVideo} type="video/mp4" />
                    </video>
                </div>
                <div className='videoDescription mt-4'>
                    <p>Welcome to the Quiz Guide! In this video, we will walk you through everything you need to know to successfully complete your quiz.</p>
                </div>
                <div className='modal-button text-center'>
                    <Button className="videoClosebtn" onClick={closeusagaeguidehandler}> CLOSE </Button>
                </div>
            </div>
        )
    }

    const closeusagaeguidehandler = () => {
        setshowModalVideoUsageGuide(false)
    }

    const handleupdateAssignment = (e, flag) => {
        debugger;
        const reqData = {
            start_date: dayjs(new Date(valuestart)),
            end_date: dayjs(new Date(valueend)),
            description: description,
            // user_id: getCurrentUserId(),
            title: title.trim(),
            total_trials: nooftrials,
            assignment_type: assignmentType,
            isgraded: graded,
            isguided: guided,
            istimed: timed,
            assignment_time: totalMinutes,
            general_instructions: generalInstructions,
            course_id: getMenuSelectedItem(),
            "id": parseInt(assignmentId),
            "flag": flag,
        }
        updateassignment(reqData).then(res => {
            if (res.data !== undefined && res.data !== null) {
                setOpenDeleteConfirmation(false)
                getAssignmentDetails()
                setopencreatehomeworkmodal(false)
            }
        })
    }
    const handleSelect = (selected) => {
        if (selected !== null) {
            settitleDisabled(true)
            setdescription(selected.description)
            settitle(selected.key)
            setgraded(Boolean(selected.isgraded))
            setvalueend(dayjs(new Date(selected.end_date)))
            setvaluestart(dayjs(new Date(selected.start_date)))
            //setassignmentId(selected.value)
            setguided(Boolean(selected.isguided))
            settimed(Boolean(selected.istimed))
            setnooftrials(selected.total_trials)
            settotalMinutes(selected.assignment_time)
            setassignmentType(selected.assignment_type)
            setgeneralInstructions(selected.general_instructions === undefined || selected.general_instructions === null ? testInstructionText : selected.general_instructions)
        }
        else {
            settitleDisabled(false)
            setdescription("")
            settitle("")
            setgraded(false)
            setvalueend(new Date())
            setvaluestart(new Date())
            setassignmentId(0)
            setguided(false)
            settimed(false)
            setnooftrials(1)
            settotalMinutes(1)
            setgeneralInstructions("")
        }
        setselectedTitle(selected)
    };
    const handleChangestart = (newValue) => {
        setvaluestart(new Date(newValue))

    };
    const handleChangeend = (newValue) => {
        setvalueend(new Date(newValue))
        const today = dayjs(new Date(newValue));
        const pastDate = dayjs(new Date(valuestart));
        if (pastDate.isAfter(today)) {
            return setenderror("End date must be greater then start date!")
        }
        else {
            setenderror("")
        }
    };

    const handleTitleChange = (e) => {
        settitle(e.target.value)

    }

    const handleTotalMinutesChange = (e) => {
        settotalMinutes(e.target.value)
        if (e.target.value <= 0 && timed) {
            seterrorTextTimed("Duration is required.")
        }
        else {
            seterrorTextTimed("")
        }
    }

    const handleNoOfTrialsChange = (e) => {
        setnooftrials(e.target.value)
        if (e.target.value <= 0 && graded) {
            seterrorTextGraded("No. of trials is required.")
        }
        else {
            seterrorTextGraded("")
        }
    }

    const handleGradedChangecheckbox = (e) => {
        setgraded(e.target.checked)
    }

    const handleGuidedChangeCheckbox = (e) => {
        setguided(e.target.checked)
    }

    const handleTimedChangeCheckbox = (e) => {
        settimed(e.target.checked)
    }

    const handleAssignmentTypeSelect = (e) => {
        setassignmentType(e.target.value)
    }

    const handledescriptionchange = (e) => {
        setdescription(e.target.value)
    }
    const handleGeneralInstruction = (e) => {
        setgeneralInstructions(e.target.value)
    }
    const onKeyDown = (e) => {
        e.preventDefault();
    };
    const clostassignmentmodal = () => {
        setopencreatehomeworkmodal(false)
    }

    const handleeditmodedata = (e, start_date, end_date
        , description, general_instructions, title, total_trials
        , assignment_type, isgraded, isguided, istimed, assignment_time, id) => {
        setopencreatehomeworkmodal(true)
        setdescription(description)
        settitle(title)
        setgraded(Boolean(isgraded))
        setvalueend(dayjs(new Date(start_date)))
        setvaluestart(dayjs(new Date(end_date)))
        setassignmentId(id)
        setguided(Boolean(isguided))
        settimed(Boolean(istimed))
        setnooftrials(total_trials)
        settotalMinutes(assignment_time)
        setassignmentType(assignment_type)
        setgeneralInstructions(general_instructions)
    }

    const handledeleteconfirmation = (e, assignmentId) => {
        setassignmentId(assignmentId)
        setOpenDeleteConfirmation(true)

    }

    const handledeleteconfirmationclose = () => {
        setOpenDeleteConfirmation(false)

    }
    return (
        <>

            <Dialog
                hideBackdrop={false}
                open={OpenDeleteConfirmation}
                onClose={handledeleteconfirmationclose}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description">
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        <div style={{ marginTop: "10px", color: "#164b99" }}>
                            Are you sure you want to delete this assignment?
                        </div>
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <button className="dragLabelcancel leftmargin" onClick={(e) => handleupdateAssignment(e, "delete")}>
                        Yes
                    </button>
                    <button className="dragLabelcancel leftmargin" onClick={handledeleteconfirmationclose}>
                        No
                    </button>
                </DialogActions>
            </Dialog>

            <Modal
                show={opencreatehomeworkmodal}
                onHide={clostassignmentmodal}
                draggable={false}
                popUpSize="small"
                body=
                {
                    <>
                        <label style={{ fontSize: "14px" }}> {`Select From Existing Assignment`}
                        </label>
                        <Select
                            getOptionLabel={option => option.key}
                            getOptionValue={option => option.value}
                            onChange={handleSelect}
                            options={homewrokTitleData}
                            value={selectedTitle}
                            placeholder="Select Title"
                            isSearchable={true}
                            isClearable={true}
                            disabled={true}
                        />
                        <div className='titleorcss'><span>OR</span></div>
                        <label style={{ fontSize: "14px" }}>Title</label>
                        <input class="form-control bg-yellow" value={title}
                            onChange={handleTitleChange}
                        ></input>
                        {/* <div className='titleorcss'></div> */}
                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                            <Stack sx={{ marginTop: "20px" }} direction="row" spacing={2} >
                                <label style={{ fontSize: "14px", paddingTop: "15px" }}>Start</label>
                                <DatePicker
                                    sx={{ marginTop: "0px !important", marginLeft: "5px" }}
                                    value={valuestart}
                                    disabled={true}
                                    onChange={handleChangestart}
                                    renderInput={(params) => <TextField {...params} />} />
                                <label style={{ fontSize: "14px", paddingTop: "15px" }}>End</label>
                                <DatePicker
                                    sx={{ marginTop: "0px !important", marginLeft: "5px" }}
                                    disabled={titleDisabled}
                                    value={valueend}
                                    onChange={handleChangeend}
                                    renderInput={(params) => <TextField onKeyDown={onKeyDown} {...params} error={!!enderror}
                                        helperText={enderror} />}
                                />
                            </Stack>
                        </LocalizationProvider>
                        {/* <div className='titleorcss'></div> */}
                        <label style={{ marginTop: "10px", fontSize: "14px" }}>Description</label>
                        <textarea
                            class="form-control bg-yellow"
                            rows="3"
                            value={description}
                            placeholder='Enter Description...'
                            style={{ width: `${showFullApp() ? "400px" : "300px"}` }}
                            disabled={titleDisabled}
                            onChange={handledescriptionchange}
                        />
                        <div className='titleorcss'></div>
                        <label style={{ marginTop: "10px", fontSize: "14px" }}>Guided </label>
                        <input style={{ marginLeft: '10px', display: 'inline' }}
                            checked={guided} type="checkbox"
                            onChange={(e) => handleGuidedChangeCheckbox(e)} disabled={true} />
                        <div className='titleorcss'></div>
                        <Stack direction="row" spacing={2} >
                            <label style={{ marginTop: "10px", fontSize: "14px" }}>Graded </label>
                            <input style={{ marginLeft: '10px', display: 'inline' }}
                                checked={graded} type="checkbox"
                                onChange={(e) => handleGradedChangecheckbox(e)} disabled={true} />
                            {graded ?
                                <>
                                    <label style={{ marginTop: "10px", fontSize: "14px", marginRight: "5px" }}>No. of Trials </label>
                                    <input style={{ width: '70px', display: 'inline' }}
                                        value={nooftrials} type="number" onChange={(e) => handleNoOfTrialsChange(e)}
                                        disabled={true} />
                                    <label style={{ marginLeft: '5px', color: 'red', marginTop: "10px", fontSize: "14px" }}>
                                        {errorTextGraded} </label>
                                </>

                                : null}
                        </Stack>
                        <div className='titleorcss'></div>
                        <Stack direction="row" spacing={2}>
                            <label style={{ marginTop: "10px", fontSize: "14px" }}>Timed </label>
                            <input style={{ marginLeft: '10px', display: 'inline' }}
                                checked={timed} type="checkbox"
                                onChange={(e) => handleTimedChangeCheckbox(e)} disabled={true} />
                            {timed ?
                                <>
                                    <label style={{ marginTop: "10px", fontSize: "14px", marginRight: "10px" }}>Duration </label>
                                    <input type='number' value={totalMinutes}
                                        style={{ width: '70px', display: 'inline' }}
                                        onChange={handleTotalMinutesChange}
                                        disabled={true}></input>
                                    <label style={{ marginLeft: '5px', marginTop: "10px", fontSize: "14px" }}> Minutes</label>
                                    <label style={{ marginLeft: '5px', color: 'red', marginTop: "10px", fontSize: "14px" }}>{errorTextTimed} </label>
                                </>
                                : null}
                        </Stack>
                        <div className='titleorcss'></div>
                        <label style={{ marginTop: "10px", fontSize: "14px" }}>Assignement Type</label>
                        <select
                            class="form-control bg-yellow"
                            onChange={handleAssignmentTypeSelect}
                            value={assignmentType}
                            disabled={titleDisabled}
                            name="assignmentType1">
                            <option value="homework">Homework</option>
                            <option value="quiz">Quiz</option>
                        </select>
                        <div className='titleorcss'></div>
                        <label style={{ fontSize: "14px" }}>Instructions for Student</label>
                        <textarea
                            class="form-control bg-yellow"
                            rows="3"
                            value={generalInstructions}
                            placeholder='Enter Instructions for student...'
                            style={{ width: `${showFullApp() ? "590px" : "300px"}` }}
                            disabled={titleDisabled}
                            onChange={handleGeneralInstruction}
                        />
                    </>
                }
                handleClose={clostassignmentmodal}
                title={"Create Assignment"}
                showButton={false}
                customButton={
                    <div className='modal-button text-center'>
                        <Button type='submit' className="yellow-btn" onClick={(e) => handleupdateAssignment(e, "edit")}> Save </Button>
                        <Button className="ml-2 grey-btn" onClick={clostassignmentmodal}> Cancel </Button>
                    </div>
                }
            >


            </Modal>
            {(detectDevice() === TABLET || detectDevice() === DESKTOP) ?
                <div className="APTestPaper">
                    <div className="container">
                        <div className='borderBottom'>
                            <div className="label d-flex justify-content-between">
                                <div className="text-wrapper">Assignments</div>
                                <div>
                                    <button
                                        onClick={usageguidehandler} className="btnLightBlue" style={{ marginLeft: "5px" }}>Usage Guide</button>
                                </div>
                            </div>
                        </div>

                        {assignmentData !== undefined && assignmentData !== null && assignmentData.length > 0 ?
                            (
                                <Box sx={{ flexGrow: 1, marginBottom: "50px", padding: "20px", maxWidth: "100%", width: '100%' }} >
                                    <Grid className={classes.maignGrid} sx={{ marginBottom: "50px" }} container spacing={{ xs: 1, md: 3 }} columns={{ xs: 1, sm: 8, md: 12 }}>
                                        {
                                            assignmentData.map((data, index) => {
                                                return (
                                                    <Grid item xs={2} sm={4} md={4} key={index}>
                                                        <AssignmentCards
                                                            openeditmodeModal={(e) =>
                                                                handleeditmodedata(e, data.start_date, data.end_date
                                                                    , data.description, data.general_instructions, data.title, data.total_trials
                                                                    , data.assignment_type, data.isgraded, data.isguided, data.istimed, data.assignment_time, data.id)}
                                                            homewrokTitleData={homewrokTitleData}
                                                            handledeleteconfirmation={(e) => handledeleteconfirmation(e, data.id)}
                                                            item={data} itemchanged={(e) => itemchangedhandler(e)}></AssignmentCards></Grid>
                                                )
                                            })
                                        }
                                    </Grid>
                                </Box>
                            )
                            :
                            <Box sx={{ flexGrow: 1, marginBottom: "50px", padding: "20px", maxWidth: "100%", width: '100%' }}>{displayMessage} </Box>
                        }
                    </div>
                </div>
                :
                <div style={{ marginTop: '100px', textAlign: 'center', background: '#fff' }}>
                    <h5>Optimized Experience Advisory</h5>
                    <img src={infoIcon}></img>
                    <p>We are actively working on making aiPlato available on all mobile and tablet devices. Currently we only support large screens.</p>
                </div>
            }

            {
                loader && (
                    <CircularProgress
                        size={24}
                        sx={{
                            color: "#164b99",
                            position: 'absolute',
                            top: '50%',
                            left: '50%',
                            marginTop: '-12px',
                            marginLeft: '-12px',
                        }}
                    />
                )
            }

            <Modal className='modalVideo'
                show={showModalVideoUsageGuide}
                onHide={closeusagaeguidehandler}
                draggable={false}
                body={whiteBoardVideoDiv}
                handleClose={closeusagaeguidehandler}
                hideHeader={true}
                showButton={false}
            >
            </Modal>

        </>
    )
}

export default AssignmentList