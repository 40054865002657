import React from 'react';
import './SearchResult.scss';
import Aux from '../../../../hoc/Wrapper';
import { Link } from 'react-router-dom';

export default (props) => {

    let searchResult = {
        'Engage Your Curiosity: Recommended Questions/Explorations': [
            { text: 'How does a plane fly?', link: '/curiosity-exploration' },
            { text: 'What makes a baseball curve?', link: '#' },
            { text: 'How do bicycles stay balanced?', link: '#' }
        ]
    };

    let searchJSX = Object.keys(searchResult).map(sKey => (
        <Aux>
            {searchResult[sKey].length > 0 ?
                <ul>
                    {searchResult[sKey].map((result, index) => (
                        <li key={'k_' + index}><Link to={result['link']} className={result.link === '#' ? 'disabled-link' : ''}>{result['text']}</Link></li>
                    ))}
                </ul>
                : null}
        </Aux>
    ));

    return (
        <div className='search-result'>
            {searchJSX}
        </div>
    );
}