import React from 'react';
import './FeatureDetailBubble.scss';
import { Row, Col } from 'react-bootstrap';

const FeatureDetailBubble = (props) => {

    let renJAX = null;

    const openVideoLink = (event, video) => {
        video.clicked(event, video['title'], (
            <iframe id="vid_frame" width="560" height="315" title={video['title']} src={video['videoUrl']} frameborder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowfullscreen=""></iframe>
        ))
    }

    if (props.games) {
        renJAX = props.data.map(game => (
            <Col sm={12}>
                <a className='detail-card' href={game['link']} target='_blank'>
                    <div className='friends-thumb'>
                        <img src={game['image']} alt='Game' width={50} />
                    </div>
                    <div className='text'>
                        <h3>{game['title']}</h3>
                    </div>
                </a>
            </Col>
        ));
    } else if (props.videos) {
        renJAX = props.data.map(video => (
            <Col sm={12} className='video-card' onClick={(event) => openVideoLink(event, video)}>
                <div className='friends-thumb'>
                    <img src={video['image']} alt='Video' width={50} />
                </div>
                <div className='text'>
                    <h3>{video['title']}</h3>
                </div>
            </Col>
        ))
    }

    return (
        <Row className={props.videos ? 'video-scroll' : ''}>
            {renJAX}
        </Row>
    );
}


export default FeatureDetailBubble;

export const FeatureDetailImageBubble = (props) => {
    return (
        <Row>
            <Col sm={12}>
                <div className='img-container'>
                    <a href={props.link} target='_blank' rel='noopener noreferrer'>
                        <img src={props.image} alt={props.title} />
                    </a>
                </div>
                <div className='text width100 mt-3'>
                    <h3>{props.title}</h3>
                </div>
            </Col>
        </Row>
    );
}