const initState = {
    questions: [],
    summativeQuestions: [],
    allItems: [],
    filteredItems: [],
}

const VideoInteractionsReducer = (state = initState, action) => {
    if (action.type === "UPDATE_INTERACTIONS") {
        return {
            ...state,
            questions: action.questions,
            summativeQuestions: action.summativeQuestions,
            allItems: action.allItems,
            filteredItems: action.filteredItems,
        };
    }
    return state;
};

export const mapStateToProps = (state) => {
    return {
        ...state,
    };
};

export const mapDispatchToProps = (dispatch) => {
    return {
        updateInteractions: (newState) => {
            dispatch({
                type: "UPDATE_INTERACTIONS",
                ...newState
            });
        },
    };
};

export default VideoInteractionsReducer