import React, { useEffect, useState } from 'react';
import {
    CardMedia
} from "@material-ui/core";
import {
    imgURL
} from '../../common/API';
import { makeStyles } from "@material-ui/core/styles";
import parse from 'html-react-parser';
import Tooltip from '@mui/material/Tooltip';
import { IconButton } from '@mui/material'
import ShowFeedbackIcon from '../../assets/images/icon-show-next-step.svg';
import ShowFullSolutionIcon from '../../assets/images/icon-show-full-solution.svg';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import CircularProgress from '@mui/material/CircularProgress';
import { Link, withRouter } from 'react-router-dom';
import { Problem_source, QuestionType, assignment_Type } from '../../common/Functions';


const newStyles = makeStyles((theme) => ({
    root: {},
    cardDetails: {
        justifyContent: "flex-start",
        flexDirection: "column",
        alignItems: "flex-start",
        gap: "16px",
        display: "flex"
    },
    librarPylaceholder: {
        height: "240px",
        alignItems: 'stretch',
        position: 'relative',
        width: '100%'
    },
    cardMedia: {
        width: "390px",
        borderRadius: "8px",
        overflow: "hidden",
        justifyContent: "flex-start",
        flexDirection: "column",
        alignItems: "flex-start",
        display: "flex",
        marginBottom: "0px",
        marginTop: "24px",
        marginLeft: "24px",
        marginRight: "24px"
    },
    photoOutlined: {
        width: "100%",
        height: "210px",
        padding: "5px",
        objectFit: "contain",
        borderRadius: "12px",
    },
    radioGrp: {
        justifyContent: "flex-start",
        alignItems: "flex-start",
        marginLeft: "25px"
    }
}));

let frqQuestionpoints = 0.00
let frqtchallengepoints = 0.00

const FRQAssignmentDetails = (props) => {

    const classes = newStyles();
    const [loading, setloading] = useState(false)
    const [openSubmittedPTMessgageModalMSQ, setopenSubmittedPTMessgageModalMSQ] = useState(false);
    const [isOpenAlertDialog, setisOpenAlertDialog] = useState(false)

    const [assignmentId, setassignmentId] = useState("");
    const [sptboardId, setboardId] = useState("");
    const [sptquestionId, setquestionId] = useState("");
    const [spttype, setspttype] = useState("");
    const [sptindex, setindex] = useState("");
    const [sptisHintUsed, setisHintUsed] = useState("");
    const [sptansForCHKWork, setansForCHKWork] = useState("");
    const [sptisCheckMyWork, setisCheckMyWork] = useState("");
    const [userGivenAnswer, setuserGivenAnswer] = useState("");
    const [sptusermsqEvent, setusermsqEvent] = useState();
    const [sptmsqSelectionAnswer, setsptmsqSelectionAnswer] = useState("");
    const [sptchallengeHref, setsptchallengeHref] = useState("");
    const [sptchallenge_id, setsptchallenge_id] = useState("");
    const [sptqueIndex, setsptqueIndex] = useState("");


    return (
        <>
            <div key={"keyQ_" + props.frqQuestion.assignment_id + "_" + props.frqQuestion.challenge_id} id={props.frqQuestion.assignment_id + "_" + props.frqQuestion.challenge_id} className="questionDiv">
                <div className="lineBreak_question"></div>
                <div className="questionDetails">
                    {props.isReview ?
                        <div className="questionHeaderChapterText">({props.frqQuestion.chapter})</div>
                        :
                        null
                    }
                    <div className={classes.cardDetails}>
                        <div className={classes.cardMedia}>
                            <div className={classes.librarPylaceholder}>
                                <CardMedia
                                    component="img"
                                    image={imgURL + props.frqQuestion.thumbnail}
                                    alt="Problem Image"
                                    className={classes.photoOutlined}
                                />
                            </div>
                        </div>
                        {
                            parseInt(props.frqQuestion.is_multipart) === 1 ? (
                                <span className="questionText">
                                    Statement: {parse(props.frqQuestion.problemstement)}
                                </span>)
                                : null
                        }
                        <span className="questionText">
                            {parse(props.frqQuestion.question)}
                        </span>
                    </div>
                    <div className='frqLinkDiv'>
                        {
                            parseInt(props.isPtSubmitted) === 1 ? (<div className={`frqLink ${props.isTutorReviewDone ? ' disabled' : ''}`}
                                onClick={(e) => props.handleRedirectFRQ(e, props.challengeHref, props.testId, props.frqQuestion.challenge_id, props.queIndex)}>
                                <Link
                                    to="#"
                                    id={props.frqQuestion.challenge_id}
                                >
                                    click to open workspace and submit answer
                                </Link>
                            </div>) : (<div className={`frqLink ${props.isTutorReviewDone ? ' disabled' : ''}`}>
                                <Link
                                    onClick={(e) => props.handleRedirectFRQ1(e, props.frqQuestion.challenge_id)}
                                    to={{
                                        pathname: props.challengeHref,
                                        state: { prevPath: ['/assignmentdetails/' + props.testId], prevNode: ['Assignment'], problemSource: [`${Problem_source.assignment}`] }
                                    }}
                                    id={props.frqQuestion.challenge_id}
                                >
                                    <a alt="click to open workspace and submit answer"
                                        href={props.challengeHref}
                                    >
                                        click to open workspace and submit answer
                                    </a>
                                </Link>
                            </div>)
                        }
                        {
                            parseInt(props.isPtSubmitted) === 1 && props.frqQuestion.is_attempted === 1 ?
                                (
                                    <>
                                        <div className="divGuideBtns" style={{ display: 'flex', flexDirection: 'row', flexGrow: 1 }}>
                                            <Tooltip key={"feedback_" + props.frqQuestion.challenge_id} style={{ flexDirection: 'column' }} title="Show Feedback"
                                                placement='right' arrow>
                                                <IconButton onClick={() =>
                                                    props.showfeedback(this, props.queIndex, props.frqQuestion.hintdata, props.frqQuestion.isgraded, props.frqQuestion.boardId, QuestionType.frq, props.frqQuestion.challenge_id, props.frqQuestion.assignment_id, props.frqQuestion.frqGradeCaclulationTrial)}>
                                                    <div className='boardBtns'>
                                                        <img alt='Show Feedback' src={ShowFeedbackIcon} />
                                                    </div>
                                                    <p className='toolLabel'>Show Feedback</p>
                                                </IconButton>
                                            </Tooltip>
                                            <Tooltip key={"sol_" + props.frqQuestion.challenge_id} style={{ flexDirection: 'column' }} title="Show Solution"
                                                placement='right' arrow>
                                                <IconButton onClick={() =>
                                                    props.showSolutionFRQ(this, props.frqQuestion.user_challenge_map_id, props.frqQuestion.boardId)}>
                                                    <div className='boardBtns'>
                                                        <img alt='Show Feedback' src={ShowFullSolutionIcon} />
                                                    </div>
                                                    <p className='toolLabel'>Show Solution</p>
                                                </IconButton>
                                            </Tooltip>
                                        </div>

                                    </>
                                )
                                :
                                props.frqQuestion.is_submitted === 1 ?
                                    (
                                        <div className="divGuideBtns" style={{ display: 'flex', flexDirection: 'row', flexGrow: 1 }}>
                                            <Tooltip key={"sum_" + props.frqQuestion.challenge_id} style={{ flexDirection: 'column' }} title="Show Feedback"
                                                placement='right' arrow>
                                                <IconButton onClick={() => props.showfeedback(this, props.queIndex, props.frqQuestion.hintdata, props.frqQuestion.isgraded, props.frqQuestion.boardId, QuestionType.frq, props.frqQuestion.challenge_id, props.frqQuestion.assignment_id, props.frqQuestion.frqGradeCaclulationTrial)}>
                                                    <div className='boardBtns'>
                                                        <img alt='Show Feedback' src={ShowFeedbackIcon} />
                                                    </div>
                                                    <p className='toolLabel'>Show Feedback</p>
                                                </IconButton>
                                            </Tooltip>
                                            <Tooltip key={"sol_" + props.frqQuestion.challenge_id} style={{ flexDirection: 'column' }} title="Show Solution"
                                                placement='right' arrow>
                                                <IconButton onClick={() =>
                                                    props.showSolutionFRQ(this, props.frqQuestion.user_challenge_map_id, props.frqQuestion.boardId)}>
                                                    <div className='boardBtns'>
                                                        <img alt='Show Feedback' src={ShowFullSolutionIcon} />
                                                    </div>
                                                    <p className='toolLabel'>Show Solution</p>
                                                </IconButton>
                                            </Tooltip>
                                        </div>
                                    )
                                    : null
                        }
                    </div>
                </div>
                <div className="questionHeader">
                    <div className="questionHeaderText">
                        {
                            props.assignmentType === assignment_Type.homework ? (<>{props.frqQuestion.challenge_name}</>) : <>{props.frqQuestion.challenge_display}</>
                        }
                        {props.frqQuestion.is_attempted === 0 ?
                            <span style={{ fontWeight: 'bold', fontSize: '13px', color: '#7896B2' }}> Not Attempted </span> :
                            <span style={{ fontWeight: 'bold', fontSize: '13px', color: 'green' }}> Challenge Attempted </span>}</div>
                    <div className='questionRightDiv'>
                        {props.frqQuestion.frqGradeCaclulationTrial > 0 ?
                            <div className="gradeCalTrial">
                                # of Attempts : {props.frqQuestion.frqGradeCaclulationTrial}/5
                            </div>
                            : null}
                        {props.isGraded ?
                            <div className="points5">
                                {
                                    props.frqQuestion.is_attempted === 1 ?
                                        (
                                            props.isReview ? "Points: " +
                                                (props.frqQuestion.challenge_points !== undefined && props.frqQuestion.challenge_points !== null ? parseFloat(props.frqQuestion.challenge_points).toFixed(2) : 0)
                                                + "/" +
                                                (props.frqQuestion.frqchallengepoints !== undefined && props.frqQuestion.frqchallengepoints !== null ? parseFloat(props.frqQuestion.frqchallengepoints).toFixed(2) : 0)
                                                :
                                                props.frqQuestion.is_submitted === 1 ?
                                                    (
                                                        "Points: " +
                                                        (props.frqQuestion.challenge_points !== undefined && props.frqQuestion.challenge_points !== null ? parseFloat(props.frqQuestion.challenge_points).toFixed(2) : 0)
                                                        + "/" + (props.frqQuestion.frqchallengepoints !== undefined && props.frqQuestion.frqchallengepoints !== null ? parseFloat(props.frqQuestion.frqchallengepoints).toFixed(2) : 0)
                                                    ) :
                                                    (
                                                        "Points: -/" + (props.frqQuestion.frqchallengepoints !== undefined && props.frqQuestion.frqchallengepoints !== null ? parseFloat(props.frqQuestion.frqchallengepoints).toFixed(2) : 0)
                                                    )
                                        )
                                        : "Points: -/" + (props.frqQuestion.frqchallengepoints !== undefined && props.frqQuestion.frqchallengepoints !== null ? parseFloat(props.frqQuestion.frqchallengepoints).toFixed(2) : 0)}
                            </div>
                            :
                            null
                        }
                        <div className="questionAvatar">
                            <div className="op">{props.frqQuestion.type}</div>
                            <div className="minWidth" />
                        </div>
                    </div>
                </div>
            </div>

            {
                loading && (
                    <CircularProgress
                        size={24}
                        sx={{
                            color: "#164b99",
                            position: 'absolute',
                            top: '50%',
                            left: '50%',
                            marginTop: '-12px',
                            marginLeft: '-12px',
                        }}
                    />
                )
            }

        </>
    )
}

export default FRQAssignmentDetails
