import React from 'react';
import Ratings from './Ratings';

export default (props) => {

    return (
        <div className="ratingBox">
            <h4 className="rating-modal-title">Reviews</h4>
            <div className="write-review clearfix">
                <h2>Clear Explanation</h2>
                <Ratings className='rating' stars={props.explanationStars} readOnly={true} />
            </div>
            <div className="write-review clearfix">
                <h2>Engaging<br/>Presentation</h2>
                <Ratings className='rating' stars={props.presentationStars} readOnly={true} />
            </div>
            <div className="write-review-slider clearfix">
                <h2>Well-paced, Fast<br/>or Slow</h2>
                <div className="slidecontainer">
                    <input type="range" min="1" max="100" value={props.paceRange} className="slider" disabled id="myRange" />
                    <label className="slow">Slow</label>
                    <label className="fast">Fast</label>
                </div>
            </div>
        </div>
    );
}