import React from 'react';
import Breadcrumb from '../../components/Breadcrumb/Breadcrumb';
import Aux from '../../hoc/Wrapper';
import Container from '../../hoc/Container';
import { Row, Col } from 'react-bootstrap';
import List from './List/List';
import NotesColumns from './NotesColumns/NotesColumns';
import LeftPanel from './LeftPanel/LeftPanel';
import './NotesBookmarks.scss';
import { fetchNotesHighlight } from '../../common/API';
import Icon_List from '../../assets/images/icon_list.svg';
import Icon_Columns from '../../assets/images/icon_columns.svg';
import { deleteBookmark, deleteNote, deleteHighlight } from '../../common/API'
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { withNamespaces } from 'react-i18next';
import { getCurrentUserId, showFullApp, isDemoUser } from '../../common/Functions';
import notesBookmarsImg from '../../assets/images/notes-bookmarks-sceen.png';
import notebooksImg from "../../assets/images/notebooks.png";
import configData from '../../common/config.json';
// import { demoUserId } from '../../common/Constants';

class NotesBookmarks extends React.Component {

    constructor(props) {
        super(props);
        this.changeTreeViewStyle = this.changeTreeViewStyle.bind(this);
    }

    state = {
        isListType: false,
        listData: null,
        allListData: null,
        sortBy: 'Curriculum Sequence',
        filterBy: null,
        treeFilter: true,
        treeViewData: [],
        time: 0,
    }
    titlesOfData = []

    componentDidMount() {
        window.addEventListener('load', this.changeTreeViewStyle);
        if (isDemoUser() !== "true") {
            const reqData = {
                params: {
                    userId: getCurrentUserId(),
                }
            }

            fetchNotesHighlight(reqData).then(res => {

                let notes = res.data.notes.map(note => {
                    note.review = note.review === "true" ? true : false;
                    note.clarification = note.clarification === "true" ? true : false;
                    return note;
                })

                let new_list = { ...this.state.allListData };
                new_list.notes = notes;
                new_list.highlights = res.data.highlights;
                new_list.videoBookmarks = res.data.video_bookmarks;
                this.titlesOfData = res.data.titleOfContent
                this.setState({ listData: new_list, allListData: new_list, treeViewData: res.data.treeViewData });
            }).catch(err => {
                console.error(err.message);
                alert("Some error occurred while fetching the data.");
                this.props.history.goBack();
            });
        }

    }


    componentWillUnmount() {
        window.removeEventListener('load', this.changeTreeViewStyle)
    }

    changeTreeViewStyle() {
        window.$('.deni-react-treeview-container .text-inner').each((index, element) => {
            if (this.titlesOfData.includes(element.innerHTML)) {
                element.style.color = 'black'
            }
        });
    }

    changeViewHandler = (event, type) => {
        event.preventDefault();

        if (type === 'list') {
            this.setState({ isListType: true });

        } else {
            this.setState({ isListType: false });
        }
    }

    sortDataSequentially = (list) => {
        return list.sort(
            function (a, b) {
                if (a.chapterNo === b.chapterNo) {
                    // sectionNo is only important when chapterNo are the same

                    if (a.sectionNo === b.sectionNo) {
                        // subSectionNo is only important when sectionNo are the same

                        if (a.subSectionNo === b.subSectionNo) {
                            // subSectionNo is only important when sectionNo are the same
                            return b.subSectionNo - a.subSectionNo
                        }

                        return a.subSectionNo > b.subSectionNo ? 1 : -1
                    }
                    return a.sectionNo > b.sectionNo ? 1 : -1
                }
                return a.chapterNo > b.chapterNo ? 1 : -1
            })
    }

    getListData = () => {
        let dataSource = this.state.listData
        let newNotes = [...dataSource.notes];
        newNotes = newNotes.map(item => ({
            ...item,
            type: 'note'
        }));

        let newHighlights = [...dataSource.highlights];
        newHighlights = newHighlights.map(item => ({
            ...item,
            type: 'highlight'
        }));

        let newVideos = [...dataSource.videoBookmarks];
        newVideos = newVideos.map(item => ({
            ...item,
            type: 'video'
        }));

        let maxLen = Math.max(newNotes.length, newHighlights.length, newVideos.length);

        let combineListData = [];

        for (let i = 0; i < maxLen; i++) {
            let shiftNote = newNotes.shift();
            if (shiftNote !== undefined) {
                combineListData.push(shiftNote);
            }
            let shiftHighlight = newHighlights.shift();
            if (shiftHighlight !== undefined) {
                combineListData.push(shiftHighlight);
            }
            let shiftVideo = newVideos.shift();
            if (shiftVideo !== undefined) {
                combineListData.push(shiftVideo);
            }
        }

        if (this.state.sortBy === 'Chronological') {
            return combineListData.sort((a, b) => { return new Date(a.datetime) - new Date(b.datetime) })
        } else if (this.state.sortBy === 'Reverse Chronological') {
            return combineListData.sort((a, b) => { return new Date(a.datetime) - new Date(b.datetime) }).reverse()
        } else if (this.state.sortBy === 'Curriculum Sequence') {
            return this.sortDataSequentially(combineListData)
        } else {
            return combineListData
        }
    }

    deleteBookmark = (bookmarkId, contentId) => {
        const reqData = {
            params: {
                bookmarkId
            }
        }

        deleteBookmark(reqData)
            .then(res => {
                toast(res.data.message, {
                    type: toast.TYPE.INFO,
                    position: toast.POSITION.BOTTOM_RIGHT
                })

                let allVideoData = this.state.allListData.videoBookmarks
                allVideoData.forEach((element, index, object) => {
                    if (element.link === contentId) {
                        element.bookmarkedItems = element.bookmarkedItems.filter(vid => vid.bookmarkId != bookmarkId)

                        if (element.bookmarkedItems.length === 0) {
                            object.splice(index, 1);
                        }
                    }
                });

                let videoData = this.state.listData.videoBookmarks
                videoData.forEach((element, index, object) => {
                    if (element.link === contentId) {
                        element.bookmarkedItems = element.bookmarkedItems.filter(vid => vid.bookmarkId != bookmarkId)

                        if (element.bookmarkedItems.length === 0) {
                            object.splice(index, 1);
                        }
                    }
                });

                this.setState({
                    allListData: {
                        ...this.state.allListData,
                        videoBookmarks: allVideoData
                    },
                    listData: {
                        ...this.state.listData,
                        videoBookmarks: videoData
                    }
                })
            })
            .catch(err => {
                console.error(err.message)
                toast('Oops, something went wrong!', {
                    type: toast.TYPE.ERROR,
                    position: toast.POSITION.BOTTOM_RIGHT
                })
            })
    }

    deleteNote = (noteId) => {
        const reqData = {
            params: {
                noteId,
            }
        }

        deleteNote(reqData)
            .then(res => {
                toast(res.data.message, {
                    type: toast.TYPE.INFO,
                    position: toast.POSITION.BOTTOM_RIGHT
                })

                this.setState({
                    allListData: {
                        ...this.state.allListData,
                        notes: this.state.allListData.notes.filter(note => note.noteId != noteId)
                    },
                    listData: {
                        ...this.state.listData,
                        notes: this.state.listData.notes.filter(note => note.noteId != noteId)
                    }
                })
            })
            .catch(err => {
                console.error(err.message)
                toast('Oops, something went wrong!', {
                    type: toast.TYPE.ERROR,
                    position: toast.POSITION.BOTTOM_RIGHT
                })
            })
    }

    deleteHighlight = (highlightId, contentId) => {
        const reqData = {
            params: {
                highlightId,
                userId: getCurrentUserId(),
            }
        }

        deleteHighlight(reqData)
            .then(res => {
                toast(res.data.message, {
                    type: toast.TYPE.INFO,
                    position: toast.POSITION.BOTTOM_RIGHT
                })

                let allBookmarkData = this.state.allListData.highlights
                allBookmarkData.forEach((element, index, object) => {
                    if (element.link === contentId) {
                        element.description = element.description.filter(obj => obj.highlightId != highlightId)

                        if (element.description.length === 0) {
                            object.splice(index, 1);
                        }
                    }
                });

                let bookmarkData = this.state.listData.highlights
                bookmarkData.forEach((element, index, object) => {
                    if (element.link === contentId) {
                        element.description = element.description.filter(obj => obj.highlightId != highlightId)

                        if (element.description.length === 0) {
                            object.splice(index, 1);
                        }
                    }
                });

                this.setState({
                    allListData: {
                        ...this.state.allListData,
                        highlights: allBookmarkData
                    },
                    listData: {
                        ...this.state.listData,
                        highlights: bookmarkData
                    }
                })
            })
            .catch(err => {
                console.error(err.message)
                toast('Oops, something went wrong!', {
                    type: toast.TYPE.ERROR,
                    position: toast.POSITION.BOTTOM_RIGHT
                })
            })
    }

    sortData = (sortBy) => {
        this.setState({ sortBy: sortBy })
        let dataSource = this.state.allListData

        switch (sortBy) {
            case 'Chronological':
                this.setState({
                    allListData: {
                        notes: dataSource.notes.sort(function (a, b) { return new Date(a.datetime) - new Date(b.datetime) }),
                        highlights: dataSource.highlights.sort(function (a, b) { return new Date(a.datetime) - new Date(b.datetime) }),
                        videoBookmarks: dataSource.videoBookmarks.sort(function (a, b) { return new Date(a.datetime) - new Date(b.datetime) }),
                    }
                })
                break;

            case 'Reverse Chronological':
                this.setState({
                    allListData: {
                        notes: dataSource.notes.sort(function (a, b) { return new Date(a.datetime) - new Date(b.datetime) }).reverse(),
                        highlights: dataSource.highlights.sort(function (a, b) { return new Date(a.datetime) - new Date(b.datetime) }).reverse(),
                        videoBookmarks: dataSource.videoBookmarks.sort(function (a, b) { return new Date(a.datetime) - new Date(b.datetime) }).reverse(),
                    }
                })
                break;

            case 'Curriculum Sequence':
                this.setState({
                    allListData: {
                        notes: this.sortDataSequentially(dataSource.notes),
                        highlights: this.sortDataSequentially(dataSource.highlights),
                        videoBookmarks: this.sortDataSequentially(dataSource.videoBookmarks),
                    }
                })
                break;

            default:
                break;
        }
    }

    filterData = (filterBy) => {
        let dataSource = this.state.treeFilter ? this.state.listData : this.state.allListData
        this.setState({ filterBy: filterBy })

        switch (filterBy) {
            case 'Show all':
                this.setState({ listData: this.state.allListData, filterBy: null })
                break;

            case 'Review':
                this.setState({ listData: { notes: dataSource.notes.filter(o => o.review), highlights: [], videoBookmarks: [] } })
                break;

            case 'Clarification':
                this.setState({ listData: { notes: dataSource.notes.filter(o => o.clarification), highlights: [], videoBookmarks: [] } })
                break;

            case 'Notes':
                this.setState({ listData: { notes: dataSource.notes, highlights: [], videoBookmarks: [] } })
                break;

            case 'Highlights':
                this.setState({ listData: { notes: [], highlights: dataSource.highlights, videoBookmarks: [] } })
                break;

            case 'Bookmarks':
                this.setState({ listData: { notes: [], highlights: [], videoBookmarks: dataSource.videoBookmarks } })
                break;

            default:
                break;
        }
    }

    getLeafItem = (item) => {
        if (item.isLeaf) {
            this.listTreeChallenges.push(item);
            return item;
        }
        item.children.map(newItem => {
            this.getLeafItem(newItem);
        })
    }

    setFilteredData = (itemList) => {
        let newNoteData = !this.state.treeFilter ? [] : [...this.state.listData.notes]
        let newHighlightData = !this.state.treeFilter ? [] : [...this.state.listData.highlights]
        let newBookmarkData = !this.state.treeFilter ? [] : [...this.state.listData.videoBookmarks]
        this.setState({ treeFilter: true })

        itemList.map(item => {
            this.state.allListData.notes.map(t => {
                if (t.subSection === item.text) {
                    if (item.state === 1) {
                        if (newNoteData.findIndex(el => el.subSection === item.text) === -1) {
                            newNoteData.push(t);
                        }
                    } else {
                        newNoteData.splice(newNoteData.findIndex(el => el.subSection === item.text), 1);
                    }
                }
            })

            this.state.allListData.highlights.map(t => {
                if (t.subSection === item.text) {
                    if (item.state === 1) {
                        if (newHighlightData.findIndex(el => el.subSection === item.text) === -1) {
                            newHighlightData.push(t);
                        }
                    } else {
                        newHighlightData.splice(newHighlightData.findIndex(el => el.subSection === item.text), 1);
                    }
                }
            })

            this.state.allListData.videoBookmarks.map(t => {
                if (t.subSection === item.text) {
                    if (item.state === 1) {
                        if (newBookmarkData.findIndex(el => el.subSection === item.text) === -1) {
                            newBookmarkData.push(t);
                        }
                    } else {
                        newBookmarkData.splice(newBookmarkData.findIndex(el => el.subSection === item.text), 1);
                    }
                }
            })
        })

        // if (newNoteData.length > 0 || newHighlightData.length > 0 || newBookmarkData.length > 0) {

        if (this.state.filterBy === 'Notes') {
            newHighlightData = []
            newBookmarkData = []
        } else if (this.state.filterBy === 'Highlights') {
            newNoteData = []
            newBookmarkData = []
        } else if (this.state.filterBy === 'Bookmarks') {
            newNoteData = []
            newHighlightData = []
        } else if (this.state.filterBy === 'Review') {
            newNoteData = newNoteData.filter(obj => obj.review)
            newHighlightData = []
            newBookmarkData = []
        } else if (this.state.filterBy === 'Clarification') {
            newNoteData = newNoteData.filter(obj => obj.clarification)
            newHighlightData = []
            newBookmarkData = []
        }

        this.setState({
            listData: {
                notes: newNoteData,
                highlights: newHighlightData,
                videoBookmarks: newBookmarkData,
            }
        })
        // } else {
        //     if (this.state.filterBy === 'Notes') {
        //         this.setState({ listData: { notes: this.state.allListData.notes, highlights: [], videoBookmarks: [] }, treeFilter: false })
        //     } else if (this.state.filterBy === 'Highlights') {
        //         this.setState({ listData: { notes: [], highlights: this.state.allListData.highlights, videoBookmarks: [] }, treeFilter: false })
        //     } else if (this.state.filterBy === 'Bookmarks') {
        //         this.setState({ listData: { notes: [], highlights: [], videoBookmarks: this.state.allListData.videoBookmarks }, treeFilter: false })
        //     } else if (this.state.filterBy === 'Review') {
        //         this.setState({ listData: { notes: this.state.allListData.notes.filter(obj => obj.review), highlights: [], videoBookmarks: [] }, treeFilter: false })
        //     } else if (this.state.filterBy === 'Clarification') {
        //         this.setState({ listData: { notes: this.state.allListData.notes.filter(obj => obj.clarification), highlights: [], videoBookmarks: [] }, treeFilter: false })
        //     } else {
        //         this.setState({ listData: this.state.allListData, treeFilter: false })
        //     }
        // }
    }

    handleOnCheckTreeViewItem = (item) => {
        if (item.children) {
            this.listTreeChallenges = [item,]
            this.getLeafItem(item);
            this.setFilteredData(this.listTreeChallenges);
        }
        if (item.isLeaf) {
            this.setFilteredData([item,]);
        }

    }

    clearFilter = () => {
        let treeViewData = this.state.treeViewData
        let newTreeViewData = []
        treeViewData.map(element => {
            let sectionList = []
            if (element.children) {
                element.children.map(section => {
                    let subSectionList = []
                    if (section.children) {
                        section.children.map(subSection => {
                            subSectionList.push({
                                text: subSection.text,
                                isLeaf: subSection.isLeaf
                            })
                        })
                    }
                    let item = {
                        text: section.text,
                    }
                    if (subSectionList.length > 0) {
                        item.children = subSectionList
                    } else {
                        item.isLeaf = true
                    }
                    sectionList.push(item)
                })
            }
            newTreeViewData.push({
                text: element.text,
                children: sectionList,
            })
        })

        this.setState({
            listData: this.state.allListData,
            treeViewData: newTreeViewData,
            time: this.state.time + 1,
            treeFilter: false,
        }, () => {
            if (this.state.time < 2) {
                // First time tree view doesn't update!!!
                this.clearFilter()
            } else {
                this.setState({ time: 0 })
            }
        })
    }

    render() {
        setTimeout(this.changeTreeViewStyle, 500)

        return (
            isDemoUser() === "true" ?
                <>
                    <div>
                        <Container>
                            <Row>
                                <Col sx={12} >
                                    <p className='text-center pt-4'>
                                        For Subscription Users Only. Please contact us to <a href={configData.APP_URL + 'signup'}>Subscribe</a>
                                    </p>
                                    <img style={{ marginTop: '-30px' }} alt='' src={notesBookmarsImg} className='w-100' />
                                </Col>
                            </Row>
                        </Container>
                    </div>

                </>
                :
                showFullApp() ?
                    <Aux>
                        <ToastContainer />
                        <Breadcrumb
                            className="bookmark-container"
                            currentNode='Notes/Bookmarks'
                            prevNode={this.props.location.state ? this.props.location.state : []} />
                        <Container className="bookmark-container">
                            <Row>
                                <div className='mynotes-left'>
                                    <LeftPanel
                                        clearFilter={this.clearFilter}
                                        sortData={this.sortData}
                                        filterData={this.filterData}
                                        changeTreeViewStyle={this.changeTreeViewStyle}
                                        handleOnCheckTreeViewItem={this.handleOnCheckTreeViewItem}
                                        treeViewData={this.state.treeViewData} />
                                </div>
                                <div className='mynotes-right'>
                                    <Row className='border-bottom ml-2 mb-2'>
                                        <ul className='column-tabs'>
                                            <li>
                                                <a href='#' onClick={(event) => this.changeViewHandler(event, 'columns')} className={this.state.isListType ? '' : 'active'}>
                                                    <img src={Icon_Columns} alt='Columns' />
                                                    Columns by Type of Annotation
                                                </a>
                                            </li>
                                            <li>
                                                <a href='#' onClick={(event) => this.changeViewHandler(event, 'list')} className={this.state.isListType ? 'active' : ''}>
                                                    <img src={Icon_List} alt='List' />
                                                    Sequential List
                                                </a>
                                            </li>
                                        </ul>
                                    </Row>
                                    {this.state.listData ?
                                        this.state.isListType ?
                                            <List data={this.getListData()}
                                                sortBy={this.state.sortBy}
                                                deleteBookmark={this.deleteBookmark}
                                                deleteHighlight={this.deleteHighlight}
                                                deleteNote={this.deleteNote} /> :
                                            <NotesColumns data={this.state.listData}
                                                sortBy={this.state.sortBy}
                                                deleteBookmark={this.deleteBookmark}
                                                deleteHighlight={this.deleteHighlight}
                                                deleteNote={this.deleteNote} />

                                        : null}
                                </div>
                            </Row>
                        </Container>
                    </Aux> :
                    <>
                        {

                            (<div>
                                <Container>
                                    <Row>
                                        <Col sx={12} >
                                            {
                                               
                                                (<p className='text-center pt-4'>Please view this content using your laptop, desktop, or tablet.</p>)
                                            }

                                            <img src={notesBookmarsImg} className='w-100' />
                                        </Col>
                                    </Row>
                                </Container>
                            </div>)}
                    </>

        );
    }
}

export default withNamespaces()(NotesBookmarks)