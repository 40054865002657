import React, { useCallback, useEffect, useState, useRef } from 'react';
import { useHistory } from 'react-router-dom';
import CardMedia from '@mui/material/CardMedia';
import { Row, Col } from 'react-bootstrap';
import './QuestionRenderer.scss'
import {
    imgURL, getHomeworkChallengesByPracticeId, fetchChallengeHintnonfrq, checkChallengeAnswer
    , getEntireSolution, ptTestSubmit
    , studenthomework_review
    , studentnonfrqhomework_detail
    , savepttimer,
    fetchSubSectionContent,
    dashboardnodechallenges,
    is_pt_submitted
    , CalculatePTMCQGrade
    , PTTimerSubmitUpdate
} from '../../../common/API';
import { makeStyles } from '@material-ui/core/styles';
import { Button } from '@mui/material';
import {
    fullPTTimeLapseCounterInMiliSeconds, halfPTTimeLapseCounterInMiliSeconds, halfTestMarksLimit, timeLapseCounter,
    Institute, fullPTTimeInString, halfPTTimeInString, testInstructionText
} from '../../../common/Constants';
import {
    getCurrentUserId, Problem_source, showFullApp, getIsPTTeacher, getInstituteId, convertDurationToSeonds,
    getTimeDifference, convertSecondsToHMS, formatDate
} from '../../../common/Functions';
import parse from 'html-react-parser';
import { Link, withRouter } from 'react-router-dom';
import CircularProgress from '@mui/material/CircularProgress';
import { IconButton } from '@mui/material'
import Box from "@mui/material/Box";
import Card from "@mui/material/Card";
import Tooltip from '@mui/material/Tooltip';
import HintIcon from '../../../assets/images/icon-hint.svg';
import ShowFullSolutionIcon from '../../../assets/images/icon-show-full-solution.svg';
import checkWorkIcon from '../../../assets/images/icon-check.svg';
import ShowFeedbackIcon from '../../../assets/images/icon-show-next-step.svg';
import { toast } from 'react-toastify';
import { ToastContainer } from 'react-toastify';
import Aux from "../../../hoc/Wrapper";
import Modal from '../../Common/ModalComponent/ModalComponent';
import ProgressSidebar from '../../Answerboard/ProgressSidebar/ProgressSidebar';
import GraphComponent from '../../../container/Homepage/Graph/GraphComponent';
import MathJaxRenderer from '../../Common/MathJaxRenderer/MathJaxRenderer';
import { ArrowBack } from '@material-ui/icons';
import HomeworkReviewDetailNonFRQ from '../../Answerboard/HomeworkReviewDetailNonFRQ';
import HomeworkReviewDetail from '../../Answerboard/HomeworkReviewDetail';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import Cookies from 'universal-cookie';
import ExpandIcon from '../../../assets/images/icon-expand.svg';
import HomeCurriculumContentCard from '../../CurriculumContent/CurriculumContentCard/HomeCurriculumContentCard';
import ChallengeCard from '../../../container/Homepage/ChallengeCard';
import { forEach, isEmpty, isNil } from 'lodash';
import Drawer from '@mui/material/Drawer';
import AnyProblem from '../../CurriculumContent/AnyProblem/AnyProblem';
import scrollUp from "../../../assets/images/scroll-vertical-1.svg";
import legendCircle from "../../../assets/images/legend-circle.svg";
import Stack from '@mui/material/Stack';
import TrapFocus from '@mui/material/Unstable_TrapFocus';
import Fade from '@mui/material/Fade';
import MuiButton from '@mui/material/Button';
import FeedbackC from '../../Feedback/FeedbackC';
import MuiIconButton from '@mui/material/IconButton';
import MuiCloseIcon from '@mui/icons-material/Close';
import DialogTitle from '@mui/material/DialogTitle';
import { Typography } from "@mui/material";
import LogoSign from "../../../assets/images/logo-sign.svg";
import Paper from '@mui/material/Paper';
import whiteBoardVideo from "../../../assets/videos/Quiz_HelpCenter.mp4";
import VideocamIcon from '@mui/icons-material/Videocam';
import SchoolIcon from '@mui/icons-material/School';
import AutoAwesomeIcon from '@mui/icons-material/AutoAwesome';



const newStyles = makeStyles((theme) => ({
    root: {},
    cardDetails: {
        justifyContent: "flex-start",
        flexDirection: "column",
        alignItems: "flex-start",
        gap: "16px",
        display: "flex"
    },
    librarPylaceholder: {
        height: "240px",
        alignItems: 'stretch',
        position: 'relative',
        width: '100%'
    },
    cardMedia: {
        width: "390px",
        borderRadius: "8px",
        overflow: "hidden",
        justifyContent: "flex-start",
        flexDirection: "column",
        alignItems: "flex-start",
        display: "flex",
        marginBottom: "0px",
        marginTop: "24px",
        marginLeft: "24px",
        marginRight: "24px"
    },
    photoOutlined: {
        width: "100%",
        height: "210px",
        padding: "5px",
        objectFit: "contain",
        borderRadius: "12px",
    },
    radioGroup: {
        justifyContent: "flex-start",
        flexDirection: "column",
        alignItems: "flex-start",
        display: "flex"
    },
    formControlClass: {
        justifyContent: "flex-start",
        alignItems: "center",
        display: "inline-flex"
    },
    radioGrp: {
        justifyContent: "flex-start",
        alignItems: "flex-start",
        marginLeft: "25px"
    },
    formControlLabels: {
        color: "#1B262F",
        fontFamily: "Average Sans",
        fontWeight: "40",
        lineHeight: "27px",
        letterSpacing: "0.15px",
        wordWrap: "break-word",
        bottom: "24px",
        position: "relative"
    },
    btnSubmit: {
        height: '100%',
        paddingLeft: "22px",
        paddingRight: "22px",
        paddingTop: "8px",
        paddingBottom: "8px",
        background: '#4353B0',
        boxShadow: '0px 3px 1px -2px rgba(0, 0, 0, 0.20)',
        borderRadius: "100px",
        overflow: 'hidden',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        display: 'inline-flex',
        color: "#fff",
        '&:hover': {
            background: '#4353B0 !important',
        },
    },
    modal_title: {
        "& .modal-title": {
            width: "100% !important"
        }
    }
}));


let modalBody = null;
let modalTitle = null;
let showButton = false;
let customButton = null;
let draggable = false;
let contentModalBody = null;
let contentModalTitle = null;
let showContentModalButton = false;
let attemptedQueList = [];
let isTimeLapse = false
//const startTime = Date.now()
//let timeCounter = null
let epochValue = 0;
let isExamSubmitted = false;
let initialTime = null;
const QuestionRenderer = (props) => {
    const history = useHistory();

    const [loading, setloading] = useState(false)
    const [testTitle, settestTitle] = useState("")
    const [testTotalPoints, settestTotalPoints] = useState(0)
    const [studentTotalPoints, setstudentTotalPoints] = useState(0)
    const [mcqSectionPoints, setmcqSectionPoints] = useState(0)
    const [msqSectionPoints, setmsqSectionPoints] = useState(0)
    const [frqSectionPoints, setfrqSectionPoints] = useState(0)
    const [userGetMCQPoints, setuserGetMCQPoints] = useState(0)
    const [userGetMSQPoints, setuserGetMSQPoints] = useState(0)
    const [userGetFRQPoints, setuserGetFRQPoints] = useState(0)
    const [testId, settestId] = useState(0)
    const [mcqQuestionList, setmcqQuestionList] = useState([])
    const [msqQuestionList, setmsqQuestionList] = useState([])
    const [frqQuestionList, setfrqQuestionList] = useState([])
    const [showModal, setshowModal] = useState(false)
    const [showContentModal, setShowContentModal] = useState(false)
    const [showProgressSidebar, setshowProgressSidebar] = useState(false)
    const [progressSidebarContent, setprogressSidebarContent] = useState(null)
    const [practiceTestMode, setpracticeTestMode] = useState("")
    const [isReview, setisReview] = useState(false)
    const [attemptedQueCount, setattemptedQueCount] = useState(0)
    const [frqreviewModalOpen, setfrqreviewModalOpen] = useState(false)
    const [nonfrqreviewModalOpen, setnonfrqreviewModalOpen] = useState(false)
    const [homeworkreviewdata, sethomeworkreviewdata] = useState([])
    const [is_review_complete, setis_review_complete] = useState(false)
    const [equationdata, setequationdata] = useState([])
    const [homeworknonfrqreviewdata, sethomeworknonfrqreviewdata] = useState([])
    const [isOpenAlertDialog, setisOpenAlertDialog] = useState(false)
    const [ptTimeLapseCounter, setptTimeLapseCounter] = useState(0)
    // const [timeLapse, settimeLapse] = useState("")
    const [totalQuestionCount, settotalQuestionCount] = useState(0);
    const [sub_sectionId, setSectionId] = useState(null);
    const [modalContentData, setModalContentData] = useState([]);
    const [contentloader, setContentLoader] = useState(false);
    const [othercontentdata, setOtherContentData] = useState([]);
    const [challengeData, setChallengeData] = useState([]);
    const [challengeLoader, setChallengeLoader] = useState(false);
    const [isContentLoaded, setIsContentLoaded] = useState(false);
    const [isChallengesLoaded, setIsChallengesLoaded] = useState(false);
    const [userProficiency, setuserProficiency] = useState(0)
    const [studentName, setStudentName] = useState("")
    const [isTutorReview, setIsTutorReview] = useState(false);
    const [student_Id, setStudent_Id] = useState("");
    const [isPtSubmitted, setisPtSubmitted] = useState(false);
    const [isFromFRQQ, setisFromFRQQ] = useState(false);
    const [openSubmittedPTMessgageModal, setopenSubmittedPTMessgageModal] = useState(false);
    const [dialogFRQPtSubmitModalopen, setdialogFRQPtSubmitModalopen] = useState(false);
    const [openSubmittedPTMessgageModalMSQ, setopenSubmittedPTMessgageModalMSQ] = useState(false);
    const [spthwId, setspthwId] = useState("");
    const [sptboardId, setboardId] = useState("");
    const [sptquestionId, setquestionId] = useState("");
    const [spttype, setspttype] = useState("");
    const [sptindex, setindex] = useState("");
    const [sptisHintUsed, setisHintUsed] = useState("");
    const [sptansForCHKWork, setansForCHKWork] = useState("");
    const [sptisCheckMyWork, setisCheckMyWork] = useState("");
    const [userGivenAnswer, setuserGivenAnswer] = useState("");
    const [sptusermsqEvent, setusermsqEvent] = useState();
    const [sptmsqSelectionAnswer, setsptmsqSelectionAnswer] = useState("");
    const [sptchallengeHref, setsptchallengeHref] = useState("");
    const [sptchallenge_id, setsptchallenge_id] = useState("");
    const [sptqueIndex, setsptqueIndex] = useState("");
    const [askAIsideBarOpen, setAskAIsideBarOpen] = useState(false);
    const [askAIquestionHint, setaskAIquestionHint] = useState("");
    const [hintBoardId, sethintBoardId] = useState("");
    const [hintChallengeId, setHintchallengeId] = useState("");
    const [equationClick, setequationClick] = useState(false)
    const [askAIBgColor, setaskAIBgColor] = useState(false)
    const [iscompleteTimer, setiscompleteTimer] = useState(false)
    const [frqGradeCaclulationTrial, setfrqGradeCaclulationTrial] = useState(0)
    const [deductedpoints, setdeductedpoints] = useState(0)

    const [PtStartDate, setPtStartDate] = useState("")
    const [PtEndDate, setPtEndDate] = useState("")
    const [withoutUserGetMCQPoints, setWithoutUserGetMCQPoints] = useState("-")
    const [withoutUserGetMSQPoints, setWithoutUserGetMSQPoints] = useState("-")
    const [withoutUserGetFRQPoints, setWithoutUserGetFRQPoints] = useState("-")
    const [isanychallengeSubmitted, setisanychallengeSubmitted] = useState(0);
    const [dialogViewInstructionsModalopen, setdialogViewInstructionsModalopen] = useState(false)
    const [dialogSubmitExamConfirmationModalopen, setdialogSubmitExamConfirmationModalopen] = useState(false)
    const [bannerOpen, setbannerOpen] = useState(false)
    const [openFeedbackModal, setopenFeedbackModal] = useState(false)
    const [showModalVideo, setshowModalVideo] = useState(false)
    const [whiteBoardVideoDiv, setwhiteBoardVideoDiv] = useState("")

    const [attemptedIncorrect, setattemptedIncorrect] = useState(0)
    const [incorrectAttemptCount, setIncorrectAttemptCount] = useState(0)
    const [problemSource, setProblemSource] = useState(Problem_source.apptesthomework)
    const [isViewByProfessor, setisViewByProfessor] = useState(false)

    const classes = newStyles()
    const cookies = new Cookies();



    useEffect(() => {
        let prId = 0

        if (props.location.state !== undefined && props.location.state !== null && getIsPTTeacher() === "true" && props.match.params.review === "review") {
            if (props.location.state["prevNode"].includes('Dashboard')) {
                setStudentName(props.location.state["studentname"])
                setIsTutorReview(true)
                if (props.match.params.studentId !== undefined) {
                    setStudent_Id(parseInt(props.match.params.studentId))
                    setisViewByProfessor(true)
                }
            }
        }
        else if (props.location.state !== undefined && props.location.state !== null && props.match.params.review === "review") {
            if (props.location.state["prevNode"].includes('Student List')) {
                setStudentName(props.location.state["studentname"])
                setIsTutorReview(true)
                
                setProblemSource(props.location.state["problemsource"])
                if (props.match.params.studentId !== undefined) {
                    setisViewByProfessor(true)
                    setStudent_Id(parseInt(props.match.params.studentId))
                }
            }
        }

        cookies.set("practiceMode", "", { path: "/" })
        cookies.set("unique_id", "", { path: "/" })
        cookies.set("timelapse", "", { path: "/" })
        cookies.set("review", false, { path: "/" })
        setloading(true)

        if (props.match.params.review === "review") {
            cookies.set("review", true, { path: "/" })
            setisReview(true)
        }
        else {
            cookies.set("review", false, { path: "/" })
            setisReview(false)
        }

        if (props.match.params.practiceMode === "exam") {
            setpracticeTestMode("exam")
        }
        else {
            setpracticeTestMode("guided")
        }

        if (props.match.params.practiceId !== undefined || props.match.params.practiceId !== 0) {
            prId = props.match.params.practiceId
            settestId(prId)

            let stId = getCurrentUserId()
            if (props.match.params.studentId !== undefined && props.match.params.studentId !== null) {
                stId = parseInt(props.match.params.studentId)
            }
            const reqData = {
                params: {
                    user_id: stId,
                    unique_id: prId,
                    problem_source: Problem_source.apptesthomework
                }
            }

            getHomeworkChallengesByPracticeId(reqData).then(res => {
                if (res.data !== undefined && res.data !== null && res.data.length > 0) {

                    const mcqList = res.data[0].childrenNonFRQ.filter((e) => e.type === "MCQ")
                    const msqList = res.data[0].childrenNonFRQ.filter((e) => e.type === "MSQ")
                    const frqList = res.data[0].childrenFRQ

                    epochValue = res.data[0].epoch
                    cookies.set("epoch" + prId, epochValue, { path: "/" })

                    settestTitle(res.data[0].title)
                    setPtStartDate(formatDate(res.data[0].start_date))
                    setPtEndDate(formatDate(res.data[0].end_date))
                    setstudentTotalPoints(res.data[0].totalPoints)
                    settestTotalPoints(res.data[0].totalptexammarks)
                    setmcqSectionPoints(res.data[0].mcqsectionpoints)
                    setmsqSectionPoints(res.data[0].msqsectionpoints)
                    setfrqSectionPoints(res.data[0].frqSectionPoints)
                    setuserGetMCQPoints(res.data[0].usergetmcqpoints)
                    setuserGetMSQPoints(res.data[0].usergetmsqpoints)
                    setuserGetFRQPoints(res.data[0].usergetfrqpoints)
                    setuserProficiency(res.data[0].totalproficiency)
                    setisPtSubmitted(res.data[0].isPtSubmitted)
                    setisanychallengeSubmitted(res.data[0].isanychallengeSubmitted)
                    
                    if (res.data[0].timelapse !== null && res.data[0].timelapse !== undefined && res.data[0].timelapse !== "") {
                        isTimeLapse = true
                        if (cookies.get("fromFRQ", { pathname: "/" }) === true) {
                            cookies.set("fromFRQ", false, { path: "/" })
                        }
                    }


                   
                    setmcqQuestionList(mcqList)
                    setmsqQuestionList(msqList)
                    setfrqQuestionList(frqList)
                    if (parseInt(res.data[0].isanychallengeSubmitted) === 1) {
                        var totalfrqpoints = 0;
                        frqList.forEach(item => {
                            totalfrqpoints += item.challenge_points;
                        });
                        setWithoutUserGetFRQPoints(totalfrqpoints)


                        var totalmcqpoints = 0;
                        mcqList.forEach(item => {
                            totalmcqpoints += item.challenge_points;
                        });
                        setWithoutUserGetMCQPoints(totalmcqpoints)

                        var totalmsqpoints = 0;
                        msqList.forEach(item => {
                            totalmsqpoints += item.challenge_points;
                        });
                        setWithoutUserGetMSQPoints(totalmsqpoints)
                    }

                    var totalfrqpoints1 = 0;
                    var totalfrqmcqpoints1 = 0
                    frqList.forEach(item => {
                        totalfrqpoints1 += item.frqchallengepoints;
                    });
                    frqList.forEach(item => {
                        totalfrqmcqpoints1 += item.totalnonfrqpoints;
                    });
                    setfrqSectionPoints(parseInt(totalfrqpoints1) + parseInt(totalfrqmcqpoints1))


                    var totalmcqpoints1 = 0;
                    mcqList.forEach(item => {
                        totalmcqpoints1 += item.totalnonfrqpoints;
                    });
                    setmcqSectionPoints(totalmcqpoints1)


                    var totalmsqpoints1 = 0;
                    msqList.forEach(item => {
                        totalmsqpoints1 += item.totalnonfrqpoints;
                    });
                    setmsqSectionPoints(totalmsqpoints1)
                   
                    if (epochValue > 0 && props.match.params.review !== "review") {
                        /////////// Calculate current Timer values based on the time difference of Start time and Current time  ///////////
                        if (getInstituteId() === Institute.Rice) {
                            let totalChallenges = res.data[0].totalchallengeperPtTest
                            if (isTimeLapse) {
                                initialTime = res.data[0].timelapse
                                isTimeLapse = false
                            }
                            else {
                                if (totalChallenges > halfTestMarksLimit) {
                                    initialTime = fullPTTimeInString
                                }
                                else {
                                    initialTime = halfPTTimeInString
                                }
                            }

                            let initialTimeInSec = convertDurationToSeonds(initialTime)

                            let now = new Date();
                            let currentEpoch = Math.floor(now.getTime() / 1000);
                            let getTimeDiffArray = getTimeDifference(epochValue, currentEpoch)
                            let timeDiffString = getTimeDiffArray.hours + ":" + getTimeDiffArray.minutes + ":" + getTimeDiffArray.seconds
                            let timeDiffInSec = convertDurationToSeonds(timeDiffString)

                            let timeLapseInSec = initialTimeInSec - timeDiffInSec
                            let timeLapseString = convertSecondsToHMS(timeLapseInSec)

                            if (timeLapseString.includes("-1:-1")) {
                                timeLapseString = "00:00:00"
                            }

                            setptTimeLapseCounter(timeLapseString)
                        }
                        /////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
                    }
                    let frqcount = 0
                    if (frqList !== undefined && frqList !== null && frqList.length > 0) {
                        const groupbylist = groupByFRQ(frqList, frq => frq.multipartgroupid)

                        groupbylist.map(item => {
                            if (item.value !== undefined && item.value.length > 0) {
                                let toalgeouplength = item.value.length
                                let attemptedcountlength = item.value.filter((e) => e.is_attempted === 1).length
                                if (toalgeouplength === attemptedcountlength) {
                                    frqcount = frqcount + 1
                                }
                            }
                        });
                    }

                    let list1 = res.data[0].childrenNonFRQ.filter((e) => e.is_attempted === 1)
                    let nonFRQAttemptedCount = list1.length
                    let totalCount = nonFRQAttemptedCount + frqcount
                    setattemptedQueCount(totalCount)
                    settotalQuestionCount(res.data[0].totalchallengeperPtTest)
                }
                setloading(false)
            }).catch(err => {
                console.error(err.message);
                setloading(false)
            })
        }

        setTimeout(function () {
            const sPosition = cookies.get("PTTestFRQChallengeScroll")
            if (sPosition !== undefined && sPosition !== null && sPosition !== "") {
                const element = document.getElementById(sPosition);
                if (element) {
                    element.scrollIntoView({ behavior: 'smooth' });
                }
            }
            else {
                document.getElementById('root').scrollTo({
                    top: 0, behavior:
                        'smooth'
                })
            }
            // }
            cookies.remove("PTTestFRQChallengeScroll")
        }, 1000);
    }, [])


    useEffect(() => {
        let data = [...mcqQuestionList]
       

        let initialTimeInSec = 0
        let timeLapseInSec = 0
        let timeLapseString = null
        if (props.match.params.practiceMode === "exam" && epochValue > 0) {
            if (props.match.params.review !== "review") {
                const interval = setInterval(() => {
                    if (getInstituteId() === Institute.Rice) {
                        let initialTimeInSec = convertDurationToSeonds(initialTime)

                        let now = new Date();
                        let currentEpoch = Math.floor(now.getTime() / 1000);
                        let getTimeDiffArray = getTimeDifference(epochValue, currentEpoch)
                        let timeDiffString = getTimeDiffArray.hours + ":" + getTimeDiffArray.minutes + ":" + getTimeDiffArray.seconds
                        let timeDiffInSec = convertDurationToSeonds(timeDiffString)

                        timeLapseInSec = initialTimeInSec - timeDiffInSec
                        timeLapseString = convertSecondsToHMS(timeLapseInSec)

                        if (timeLapseString.includes("-1:-1")) {
                            timeLapseString = "00:00:00"
                        }

                        cookies.set("timelapse", timeLapseString, { path: "/" })

                        setptTimeLapseCounter(timeLapseString)
                        checkTimeForAlertMessages(timeLapseString)


                        if (timeDiffInSec > initialTimeInSec) {
                            startExamTimeCountdownComplete()
                            clearInterval(interval)

                        }
                    }
                    else {
                        let initialTime = timeLapseString === null ? ptTimeLapseCounter : timeLapseString
                        initialTimeInSec = convertDurationToSeonds(initialTime)

                        let timeLapseInSec = initialTimeInSec - (timeLapseCounter / 1000)
                        timeLapseString = convertSecondsToHMS(timeLapseInSec)
                        if (timeLapseString.includes("-1:-1")) {
                            timeLapseString = "00:00:00"
                        }
                        cookies.set("timelapse", timeLapseString, { path: "/" })
                        setptTimeLapseCounter(timeLapseString)
                        checkTimeForAlertMessages(timeLapseString)

                        let data = [...mcqQuestionList]
                       
                        if (timeLapseInSec <= 0) {
                            startExamTimeCountdownComplete()
                            clearInterval(interval)

                        }
                    }

                    //  console.log('Time lapse after 1 second is - ' + timeLapseString);
                }, timeLapseCounter)

                if (getInstituteId() !== Institute.Rice) {
                    return () => {
                        const reqData = {
                            "user_id": getCurrentUserId(),
                            "unique_id": parseInt(props.match.params.practiceId),
                            "timelapse": timeLapseString,
                            "epoch": epochValue
                        }

                        savepttimer(reqData).then(res => {
                            if (res.data !== undefined && res.data !== null) {

                            }
                        })

                        clearInterval(interval);
                    }
                }
            }
        }
        else {
            cookies.set("practiceMode", "guided", { path: "/" })
        }
    }, [totalQuestionCount])


    const openModal = (event, modTitle, modBody, customBtn = null, showBtn = false, isDraggable = false, isContentPopup = false) => {
        if (event) {
            event.preventDefault();
        }
        if (!isContentPopup) {
            modalBody = modBody;
            modalTitle = modTitle;
            showButton = showBtn;
            customButton = customBtn;
            draggable = isDraggable;
            setshowModal(true)
        } else {
            contentModalBody = modBody;
            contentModalTitle = modTitle;
            showContentModalButton = showBtn;
            customButton = customBtn;
            draggable = false;
            setShowContentModal(true)
        }
    }

    const closeModal = () => {
        setshowModal(false)
    }

    const askAItutorHandler = (e) => {
        setAskAIsideBarOpen(true)
        setshowModal(false)
        setequationClick(true)
    }

    const closeaskAItutorHandler = (e) => {
        setAskAIsideBarOpen(false)
    }

    const handleremovequestions = () => {
        setaskAIquestionHint("")
    }

    const handleequationclick = () => {
        setequationClick(false)
    }

    const handleremoveAskAIBgcolor = () => {
        setaskAIBgColor(false)
    }
    const groupByFRQ = (list, keyGetter) => {
        const map = new Map();
        list.forEach((item) => {
            const key = keyGetter(item);
            const collection = map.get(key);
            if (!collection) {
                map.set(key, [item]);
            } else {
                collection.push(item);
            }
        });
        let array = Array.from(map, ([key, value]) => ({ key, value }));
        return array
    }

    const checkTimeForAlertMessages = (timeCounter) => {
        let hours = timeCounter.split(":")[0]
        let minutes = timeCounter.split(":")[1]
        let seconds = timeCounter.split(":")[2]

        if (props.match.params.practiceMode === "exam") {
            if (parseInt(hours) === 0 && parseInt(minutes) === 10 && parseInt(seconds) === 0) {
                // alert("Last 10 min. to finish the exam..!! Hurry Up.")
                toast("Last 10 min. to finish the exam..!! Hurry Up.", {
                    type: toast.TYPE.WARNING,
                    position: toast.POSITION.TOP_CENTER
                })
            }
            else if (parseInt(hours) === 0 && parseInt(minutes) === 5 && parseInt(seconds) === 0) {
                // alert("Last 5 min. to finish the exam..!! Hurry Up.")
                toast("Last 5 min. to finish the exam..!! Hurry Up.", {
                    type: toast.TYPE.WARNING,
                    position: toast.POSITION.TOP_CENTER
                })
            }
            else if (parseInt(hours) === 0 && parseInt(minutes) === 1 && parseInt(seconds) === 0) {
                // alert("Last 1 min...If you do not submit this exam, It will get auto submit afterf a min !! Hurry Up.")
                toast("Last 1 min...If you do not submit this exam, It will get auto submit afterf a min !! Hurry Up.", {
                    type: toast.TYPE.WARNING,
                    position: toast.POSITION.TOP_CENTER
                })
            }

            cookies.set("practiceMode", "exam", { path: "/" })
            cookies.set("unique_id", props.match.params.practiceId, { path: "/" })
        }
        return timeCounter
    }

    const startExamTimeCountdownComplete = () => {
        if (!isExamSubmitted) {
            let timelapse = cookies.get("timelapse", { pathname: "/" })
            if (!timelapse.includes("-1:-1") || !timelapse.includes("00:00:00")) {
                submitExamClick();
            }

        }
    }

    const feedbackLinkHandler = () => {
        setbannerOpen(false)
        setopenFeedbackModal(true)
    }

    const closeBanner = () => {
        setbannerOpen(false)
        setopenFeedbackModal(false)
    }

    const handlecloseFeedbackModal = () => {
        setopenFeedbackModal(false)
    }



    const mcqmsqProblemSubmit = (userEnteredAns, questionId, hwId, isHintUsed, boardId, type, index, isFromFRQ) => {
        // debugger
        setloading(true)
        let reqData = {
            userAnswer: userEnteredAns,
            questionId: questionId,
            user_id: getCurrentUserId(),
            homeworkproblems_id: hwId,
            hints_used: isHintUsed,
            problem_source: Problem_source.apptesthomework,
            boardId: boardId,
            final_submission: 1,
            optionselected: 0,
            qtype: type,
            fromTestPaper: 1
        }
        checkChallengeAnswer(reqData).then(res => {
            if (res.data !== undefined && res.data !== null) {
                setloading(false)
                if (type === "MCQ") {
                    if (!isFromFRQ) {
                        let updateToList = [...mcqQuestionList]
                        if (updateToList[index] !== undefined && updateToList[index] !== null) {
                            let tempArr = updateToList[index]
                            tempArr.selectedAnswer = userEnteredAns;
                            tempArr.is_submitted = 1
                            tempArr.challenge_points = res.data.returnpoint
                            updateToList[index] = tempArr
                            
                            setmcqQuestionList(updateToList)

                            var total = 0;
                            updateToList.forEach(item => {
                                total += item.challenge_points;
                            });
                            setWithoutUserGetMCQPoints(total)

                        }
                    }
                    else {
                        let updateToList = [...frqQuestionList]
                        if (updateToList[index] !== undefined && updateToList[index] !== null) {
                            let tempArr = updateToList[index]
                            tempArr.selectedAnswer = userEnteredAns;
                            tempArr.is_submitted = 1
                            tempArr.challenge_points = res.data.returnpoint
                            updateToList[index] = tempArr
                            setfrqQuestionList(updateToList)

                            var total = 0;
                            updateToList.forEach(item => {
                                total += item.challenge_points;
                            });
                            setWithoutUserGetFRQPoints(total)

                        }
                    }
                }
            }
        }).catch(err => {
            setloading(false)
            console.error(err.message);
            alert('Error occurred while submitting the answer.');
        })

    }

    const getMyScore = (event, hwId, userEnteredAns, boardId, questionId, type, index, isHintUsed, isCheckMyWork, mcqGradeCalclulationTrial, isFromFRQ) => {
        if (!isNil(userEnteredAns) && !isEmpty(userEnteredAns)) {
            // alert(mcqGradeCalclulationTrial)
            if (parseInt(mcqGradeCalclulationTrial) < 5) {
                let reqData = {
                    userAnswer: userEnteredAns,
                    questionId: questionId,
                    user_id: getCurrentUserId(),
                    homeworkproblems_id: hwId,
                    hints_used: isHintUsed,
                    problem_source: Problem_source.apptesthomework,
                    boardId: boardId,
                    final_submission: 0,
                    optionselected: 1,
                    fromTestPaper: 1,
                    qtype: type
                }
                CalculatePTMCQGrade(reqData).then(res => {
                    if (res.data !== undefined && res.data !== null) {

                        let isCorrect = false
                        let remainingTiral = 0
                        if (res.data.answerStatus === "correct") {
                            isCorrect = true
                        }
                        else {
                            isCorrect = false
                            setIncorrectAttemptCount(incorrectAttemptCount + 1)
                        }

                        remainingTiral = (5 - parseInt(res.data.totalGradeCalculationTrial))

                        let modalBody = (
                            <Aux>
                                <div>
                                    <ul>
                                        <li style={{ fontWeight: 'bold', fontSize: '10pt' }}>
                                            Score : {res.data.totalonlyCheckGrades}
                                        </li>
                                    </ul>
                                    {parseInt(res.data.totalGradeCalculationTrial) === 4 && practiceTestMode === "exam" ?
                                        <div style={{ fontStyle: 'italic', fontWeight: 'bold', fontSize: '16px' }}>
                                            Next trial will be your last trial to check your score. Post that the answer will be auto-submitted.
                                        </div>
                                        :
                                        <>
                                            {
                                                parseInt(res.data.totalGradeCalculationTrial) === 4 && practiceTestMode === "exam" ?
                                                    <Aux>
                                                        <div style={{ fontStyle: 'italic', fontWeight: 'bold', fontSize: '16px' }}>
                                                            <div className='iconNotification'>You have completed all trials for this question. The current answer will be auto-submitted now.</div>
                                                        </div>
                                                    </Aux>
                                                    : null
                                            }
                                        </>
                                    }
                                </div>
                            </Aux>
                        )

                        if (isFromFRQ) {
                            let updateToList = [...frqQuestionList]
                            if (updateToList[index] !== undefined && updateToList[index] !== null) {
                                let tempArr = updateToList[index];

                                // let scorevalue = tempArr.challenge_points
                                // if (res.data.totalonlyCheckGrades.includes("/")) {
                                //     let splitvalue = res.data.totalonlyCheckGrades.split("/")
                                //     scorevalue = parseFloat(splitvalue[0])
                                // }

                                tempArr.totalGradeCalculationTrial = res.data.totalGradeCalculationTrial;
                                // tempArr.challenge_points = scorevalue
                                tempArr.remainingTrial = remainingTiral
                                tempArr.isCorrect = isCorrect

                                updateToList[index] = tempArr
                                setfrqQuestionList(updateToList)

                                setloading(false)

                                var total = 0;
                                updateToList.forEach(item => {
                                    total += item.challenge_points;
                                });
                                setWithoutUserGetFRQPoints(total)
                            }
                        }
                        else {
                            let updateToList = [...mcqQuestionList]
                            if (updateToList[index] !== undefined && updateToList[index] !== null) {

                                let tempArr = updateToList[index];

                                // let scorevalue = tempArr.challenge_points
                                // if (res.data.totalonlyCheckGrades.includes("/")) {
                                //     let splitvalue = res.data.totalonlyCheckGrades.split("/")
                                //     scorevalue = parseFloat(splitvalue[0])
                                // }

                                tempArr.totalGradeCalculationTrial = res.data.totalGradeCalculationTrial;
                                // tempArr.challenge_points = scorevalue
                                tempArr.remainingTrial = remainingTiral
                                tempArr.isCorrect = isCorrect

                                updateToList[index] = tempArr
                               
                                setmcqQuestionList(updateToList)
                                setloading(false)

                                var total = 0;
                                updateToList.forEach(item => {
                                    total += item.challenge_points;
                                });
                                setWithoutUserGetMCQPoints(total)
                            }
                        }
                        openModal(event, "Attempt " + res.data.totalGradeCalculationTrial + "/5", modalBody, false, true, true)

                        if (parseInt(mcqGradeCalclulationTrial) === 4) {
                            mcqmsqProblemSubmit(userEnteredAns, questionId, hwId, isHintUsed, boardId, type, index, isFromFRQ)
                        }
                    }
                }).catch(err => {
                    setloading(false)
                    console.error(err.message);
                    toast("Some error occured!", {
                        type: toast.TYPE.ERROR,
                        position: toast.POSITION.BOTTOM_RIGHT
                    })
                })
            }
            else {
                let modalBody = (
                    <Aux>
                        <div className='text-center modal-content' style={{ display: "block" }}>
                            <div style={{ position: 'absolute' }} className='iconNotification'>You have completed all trials for this problem.</div>
                        </div>
                    </Aux>
                )

                openModal(event, "Attempts Exceeded ", modalBody, false, true, true)

            }
        }
        else {

            let modalBody = (
                <Aux>
                    <div style={{ fontStyle: 'italic', fontWeight: 'bold', fontSize: '12px' }}>
                        <div className='iconNotification'>Please select an answer first ...!</div>
                    </div>
                </Aux>
            )

            openModal(event, "Alert", modalBody, false, true, true)
        }
    }

    const getHint = (event, challengeMapId, boardId, index, type, challenge_Id) => {
        let stId = getCurrentUserId()
        sethintBoardId(boardId)
        setHintchallengeId(challenge_Id)
        if (props.match.params.studentId !== undefined && props.match.params.studentId !== null) {
            stId = parseInt(props.match.params.studentId)
        }

        let reqData = {
            boardId: boardId,
            user_id: stId,
            challengeMapId: challengeMapId,
            problem_source: Problem_source.apptesthomework
        }

        fetchChallengeHintnonfrq({ params: reqData }).then(res => {
            setloading(false)
            if (res.data !== undefined && res.data !== null && res.data.length > 0) {
                let contentdatafinal = []
                let hintfinalforAiTutor = []
                let finalhintforAitutor = ""
                if (res.data[0].remedial_text === "" || res.data[0].remedial_text === null || res.data[0].remedial_text === undefined) {
                    toast("No more hints found!", {
                        type: toast.TYPE.INFO,
                        position: toast.POSITION.BOTTOM_RIGHT
                    })
                }
                else {
                    res.data.forEach((rem) => {
                        let contentdata = (
                            <Aux>
                                <p className='modal-p mt-3' dangerouslySetInnerHTML={{ __html: rem.remedial_text ? rem.remedial_text : '' }}></p>
                                <a className='mt-2 ' href={rem.remedial_source_url} target='_blank' rel="noopener noreferrer" >{rem.remedial_source_title ? rem.remedial_source_title : rem.remedial_source_url}</a>
                            </Aux>
                        )
                        contentdatafinal.push(contentdata)
                        hintfinalforAiTutor.push(rem.remedial_text)
                    })
                    if (hintfinalforAiTutor.length > 0) {
                        finalhintforAitutor = "Hint: " + hintfinalforAiTutor.join(", ")
                        setaskAIquestionHint(finalhintforAitutor)
                    }
                    let modalBody = (
                        <>{contentdatafinal}</>
                    );
                    customButton = (
                        <div className='modal-button text-center d-flex justify-content-center'>
                            <div>
                                <button
                                    type='button'
                                    className='modal-footer-button btn mx-auto'
                                    onClick={closeModal}>OK</button>
                            </div>
                            {
                                type === "FRQ" ? (<div style={{ marginLeft: "5px" }}>
                                    <button Tooltip="Get further explanations from the interactive AI tutor."
                                        type='button'
                                        className='modal-footer-button btn mx-auto'
                                        onClick={askAItutorHandler}>Ask AI Tutor</button>
                                </div>) : null
                            }

                        </div>
                    )
                    openModal(event, 'Hint', modalBody, customButton, customButton ? false : true, true);
                }
                let updateToList = type === "MCQ" ? [...mcqQuestionList] : [...msqQuestionList]
                if (updateToList[index] !== undefined && updateToList[index] !== null) {
                    let tempArr = updateToList[index];
                    tempArr.hint_used = true;

                    updateToList[index] = tempArr
                    if (type === "MCQ") {
                      
                        setmcqQuestionList(updateToList)
                    }
                    else {
                        setmsqQuestionList(updateToList)
                    }
                    setloading(false)
                }
            }
            else if (res.data.message) {
                toast(res.data.message, {
                    type: toast.TYPE.INFO,
                    position: toast.POSITION.BOTTOM_RIGHT
                })
            }
        }).catch(err => {
            console.error(err.message);
            setloading(false)

            toast("No more hints found!", {
                type: toast.TYPE.INFO,
                position: toast.POSITION.BOTTOM_RIGHT
            })
        })
    }

    const showfeedback = (event, boardId, qtype, challenge_id, hid, frqGradeCaclulationTrial1 = 0, totalpoints = 0) => {
        setdeductedpoints(0)
        setattemptedIncorrect(0)
        let stId = getCurrentUserId()
        setloading(true)
        if (props.match.params.studentId !== undefined && props.match.params.studentId !== null) {
            stId = parseInt(props.match.params.studentId)
        }
        if (qtype === 'FRQ') {
            setloading(true)
            let reqData = {
                challenge_id: challenge_id,
                studentId: stId,
                homeworkproblemsId: hid,
                problem_source: Problem_source.apptesthomework

            }
            studenthomework_review({ params: reqData }).then(res => {
                if (res.data.data !== undefined && res.data.data.length > 0) {
                    sethomeworkreviewdata(res.data.data)
                    setis_review_complete(res.data.data[0].is_review_complete)
                    let incorrectattemptcount = res.data.data[0].incorrectattemptcount
                    setattemptedIncorrect(incorrectattemptcount)
                    if (frqGradeCaclulationTrial1 > 0) {
                        setfrqGradeCaclulationTrial(frqGradeCaclulationTrial1)
                        let totalattempt = parseInt(incorrectattemptcount)
                        let deductedper = ((totalattempt) * 20)
                        setdeductedpoints(deductedper)
                    }


                    setfrqreviewModalOpen(true)
                    setnonfrqreviewModalOpen(false)


                    if (res.data.data[0].ta_feedback !== undefined && res.data.data[0].ta_feedback !== null) {

                        if (res.data.data[0].ta_feedback["result"] !== undefined &&
                            res.data.data[0].ta_feedback["result"] !== null && res.data.data[0].ta_feedback["result"].length > 0) {
                            setequationdata(res.data.data[0].ta_feedback["result"])
                        } else {
                            setequationdata(res.data.data[0].ta_feedback)
                        }
                        setequationdata(res.data.data[0].ta_feedback)
                    }
                    // setloading(false)
                }
                setloading(false)
            }).catch(err => {
                setloading(false)
                console.error(err.message);
            })
        }
        else {
            setloading(true)

            let reqData = {
                challenge_id: challenge_id,
                studentId: stId,
                homeworkproblems_id: hid,
                problem_source: Problem_source.apptesthomework
            }

            studentnonfrqhomework_detail({ params: reqData }).then(res => {
                // setloading(false)
                if (res.data.data !== undefined && res.data.data.length > 0) {
                    sethomeworknonfrqreviewdata(res.data.data)
                    setfrqreviewModalOpen(false)
                    setnonfrqreviewModalOpen(true)


                    if (frqGradeCaclulationTrial1 > 0) {
                        let getpoints = res.data.data[0].totalgrade
                        let pointdifference = totalpoints - getpoints
                        if (pointdifference > 0 && totalpoints > 0) {
                            let getpercentage = (100 * pointdifference) / totalpoints
                            if (getpercentage > 0) {
                                let attemptcount = (5 * getpercentage) / 100
                                setdeductedpoints(getpercentage)
                                setattemptedIncorrect(attemptcount)
                            }
                        }

                        // setfrqGradeCaclulationTrial(frqGradeCaclulationTrial1)
                        // let totalattempt = parseInt(frqGradeCaclulationTrial1)
                        // let deductedper = ((totalattempt) * 20)
                        // setdeductedpoints(deductedper)
                    }
                }
                setloading(false)
            }).catch(err => {
                setloading(false)
                console.error(err.message);
            })
        }
    }

    const showSolution = async (event, challengeMapId, boardId) => {
        let PSContent = null
        let content = null;
        let remedial_resources = []


        let stId = getCurrentUserId()
        if (props.match.params.studentId !== undefined && props.match.params.studentId !== null) {
            stId = parseInt(props.match.params.studentId)
        }

        setprogressSidebarContent(null)
        await getEntireSolution({
            params: {
                'challengeMapId': challengeMapId,
                'user_id': stId,
                'problem_source': Problem_source.apptesthomework,
                'boardId': boardId
            }
        }).then(res => {
            setloading(false)

            if (res.data.challengeType !== 'frq') {
                let correctAnswer = null;
                if (res.data.challengeType === 'mcq') {
                    correctAnswer = res.data.solution[0]
                } else if (res.data.challengeType === 'msq') {
                    correctAnswer = `<ul key='answer'>`
                    {
                        res.data.solution.map((ans, index) => {
                            correctAnswer += index === 0 ? `<li key={'msq' + index}>${ans}</li>` : `<br/><li key={'msq' + index}>${ans}</li>`
                        })
                    }
                    correctAnswer += `</ul>`


                } else if (res.data.challengeType === 'ranking') {
                    correctAnswer = (
                        <ol>
                            {res.data.solution.map((ans, index) => <li key={'ranking' + index}>{ans}</li>)}
                        </ol>
                    )
                }
                content = correctAnswer;
            }
        }).catch(err => {
            setloading(false)
            console.error(err.message);
        })
        if (content === null) {
            content = (
                <p>No solutions found</p>
            )
        }
        PSContent = (
            <div className='d-flex flex-column mx-3 my-3'>
                <h5 className='mb-2'><u>Correct Answer</u>:</h5>
                {<div dangerouslySetInnerHTML={{ __html: content }} />}
                {remedial_resources !== null && remedial_resources.length > 0 ?
                    <Aux>
                        <h5 className='mb-2 mt-3'><u>Resources</u>:</h5>
                        {remedial_resources.map(resource => {
                            if (resource.remedial_source_url !== null && resource.remedial_source_url.length > 0) {
                                return <a href={resource.remedial_source_url} target="_blank" rel="noopener noreferrer"><p dangerouslySetInnerHTML={{ __html: resource.remedial_source_title }}></p></a>
                            }
                            return null
                        })}
                    </Aux>
                    : null}
            </div>
        )
        setshowProgressSidebar(true)
        setprogressSidebarContent(PSContent)
    }

    const closeProgressSidebar = () => {
        setshowProgressSidebar(false)
    }

    const showcheckmyworkmcq = (ev, hwId, boardId, questionId, type, index, isHintUsed, ansForCHKWork, isCheckMyWork, isFromFRQ) => {
        let userAnswer

        if (isCheckMyWork && ansForCHKWork === "") {
            setisOpenAlertDialog(true)
            // alert("please select an answer first")
        }
        else {
            if (ansForCHKWork === "") {
                userAnswer = ev.target.value.trim();
            }
            else {
                userAnswer = ansForCHKWork
            }

            checkChallengeAnswerCommonForMCQ(ev, hwId, userAnswer, boardId, questionId, type, index, isHintUsed, isCheckMyWork, isFromFRQ)
        }
    }

    const mcqcommoncode = (ev, hwId, boardId, questionId, type, index, isHintUsed, ansForCHKWork, isCheckMyWork, usergivenanser, isFromFRQ) => {
        let userAnswer

        if (isCheckMyWork && ansForCHKWork === "") {
            setisOpenAlertDialog(true)
            // alert("please select an answer first")
        }
        else {
            if (ansForCHKWork === "") {
                userAnswer = usergivenanser;
            }
            else {
                userAnswer = ansForCHKWork
            }
            checkChallengeAnswerCommonForMCQ(ev, hwId, userAnswer, boardId, questionId, type, index, isHintUsed, isCheckMyWork, isFromFRQ)
        }
    }

    const closepointmessagehansler = (ev) => {
        setopenSubmittedPTMessgageModal(false)
        mcqcommoncode(ev, spthwId, sptboardId, sptquestionId, spttype, sptindex, sptisHintUsed, sptansForCHKWork, sptisCheckMyWork, userGivenAnswer, isFromFRQQ)
    }

    const mcqChangeHandler = (ev, hwId, boardId, questionId, type, index, isHintUsed, ansForCHKWork, isCheckMyWork, isFromFRQ) => {
        setspthwId(hwId)
        setboardId(boardId)
        setquestionId(questionId)
        setspttype(type)
        setindex(index)
        setisHintUsed(isHintUsed)
        setansForCHKWork(ansForCHKWork)
        setisCheckMyWork(isCheckMyWork)
        setuserGivenAnswer(ev.target.value.trim())
        setisFromFRQQ(isFromFRQ)
        if (parseInt(isPtSubmitted) === 1) {
            setopenSubmittedPTMessgageModal(true)
            setopenSubmittedPTMessgageModalMSQ(false)
        }
        else {
            mcqcommoncode(ev, hwId, boardId, questionId, type, index, isHintUsed, ansForCHKWork, isCheckMyWork, ev.target.value.trim(), isFromFRQ)
        }
    }

    const checkChallengeAnswerCommonForMCQ = (event, hwId, userEnteredAns, boardId, questionId, type, index, isHintUsed, isCheckMyWork, isFromFRQ) => {
        if (userEnteredAns) {
            let reqData = {
                userAnswer: userEnteredAns,
                questionId: questionId,
                user_id: getCurrentUserId(),
                homeworkproblems_id: hwId,
                hints_used: isHintUsed,
                problem_source: Problem_source.apptesthomework,
                boardId: boardId,
                final_submission: 0,
                optionselected: 1,
                fromTestPaper: 1,
                qtype: type
            }

            checkChallengeAnswer(reqData).then(res => {
                let isExist = false

                if (attemptedQueList.length > 0) {
                    attemptedQueList.map(cdata => {
                        return (
                            cdata.challengeId === questionId ? isExist = true : null
                        )
                    })
                }

                if (!isExist) {
                    attemptedQueList.push({
                        "challengeId": questionId,
                        "index": index
                    })
                }

                if (isFromFRQ) {
                    let updateToList = [...frqQuestionList]
                    if (updateToList[index] !== undefined && updateToList[index] !== null) {
                        let tempArr = updateToList[index]
                        // if (tempArr.is_attempted === 0) {
                        //     setattemptedQueCount(attemptedQueCount + 1)
                        // }

                        tempArr.selectedAnswer = userEnteredAns;
                        tempArr.is_attempted = 1

                        updateToList[index] = tempArr
                        setfrqQuestionList(updateToList)
                        setloading(false)                      

                        let multigrouplist = updateToList.filter((e) => e.multipartgroupid === parseInt(tempArr.multipartgroupid))
                        let isattemptlist = multigrouplist.filter((e) => e.is_attempted === 1)
                        if (multigrouplist.length === isattemptlist.length) {
                            if (attemptedQueCount < totalQuestionCount)
                                setattemptedQueCount(attemptedQueCount + 1)
                        }

                    }
                }
                else {
                    let updateToList = [...mcqQuestionList]
                    if (updateToList[index] !== undefined && updateToList[index] !== null) {
                        let tempArr = updateToList[index]
                        if (tempArr.is_attempted === 0) {
                            setattemptedQueCount(attemptedQueCount + 1)
                        }

                        tempArr.selectedAnswer = userEnteredAns;
                        tempArr.is_attempted = 1

                        updateToList[index] = tempArr
                        debugger;
                        setmcqQuestionList(updateToList)
                        setloading(false)
                    }
                }

                if (isCheckMyWork) {
                    setloading(true)
                    let modalContent = null;
                    let chkWorkModalTitle = null
                    if (res.data.status === 'correct') {
                        modalContent = (
                            <div className='answer-model-correct d-flex justify-content-center'>
                                <span className='correct-answer mr-2'></span>
                                <h5>Correct Answer</h5>
                            </div>
                        )
                    } else {
                        chkWorkModalTitle = (
                            <div className='d-flex align-items-center'>
                                <span className='incorrect-answer mr-2'></span>
                                <h4 className='m-0'>"This isn’t the correct answer."</h4>
                            </div>)

                        modalContent = (
                            <div className='answer-modal-incorrect'>
                                {
                                    res.data.data !== undefined && res.data.data.length > 0 ? (
                                        res.data.data.map(item => {
                                            return (
                                                <>
                                                    {
                                                        item.remedial_text !== "" && item.remedial_text !== '' && item.remedial_text !== null ? (
                                                            <>
                                                                <h5>Explanation:</h5>
                                                                <span className='d-block'>
                                                                    <p dangerouslySetInnerHTML={{ __html: item.remedial_text }}></p>
                                                                </span>
                                                            </>) : null
                                                    }
                                                    {
                                                        item.remedial_source_url !== "" && item.remedial_source_url !== '' && item.remedial_source_url !== null ?
                                                            (<>
                                                                <h5>Recommended Resources:</h5>
                                                                <p className='mb-4'>
                                                                    <a href={item.remedial_source_url} target='_blank' rel="noopener noreferrer">
                                                                        <p dangerouslySetInnerHTML={{ __html: item.remedial_source_title ? item.remedial_source_title : item.remedial_source_url }}></p></a></p>
                                                            </>) : null
                                                    }


                                                </>
                                            )
                                        })

                                    ) : null
                                }

                            </div>
                        )
                    }

                    setloading(false)
                    openModal(event, chkWorkModalTitle, modalContent, null, true, true);
                }
            }).catch(err => {
                setloading(false)
                console.error(err.message);
                alert('Error occurred while submitting the answer.');
            })
        }
    }

    const showcheckmywork = (ev, hwId, boardId, questionId, type, index, isHintUsed, selectionans, isCheckMyWork) => {
        let userAnswer

        if (isCheckMyWork && selectionans === "") {
            setisOpenAlertDialog(true)
            // alert("Please select an answer first")
        }
        else {
            if (!isCheckMyWork) {
                userAnswer = ev.target.value;
            }
            else {
                userAnswer = selectionans
            }

            checkChallengeAnswerCommonForMSQcheckmywork(ev, hwId, userAnswer, boardId, questionId, type, index, isHintUsed, selectionans, isCheckMyWork)
        }
    }

    const closepointmessagehanslerMSQ = () => {
        setopenSubmittedPTMessgageModalMSQ(false)
        msqcommoncode(sptusermsqEvent, spthwId, sptboardId, sptquestionId, spttype, sptindex, sptisHintUsed, sptmsqSelectionAnswer, sptisCheckMyWork, userGivenAnswer)
    }

    const msqcommoncode = (isChecked, hwId, boardId, questionId, type, index, isHintUsed, selectionans, isCheckMyWork, usergivenanser) => {
        let userAnswer
        if (isCheckMyWork && selectionans === "") {
            setisOpenAlertDialog(true)
            // alert("Please select an answer first")
        }
        else {
            if (!isCheckMyWork) {
                userAnswer = usergivenanser;
            }
            else {
                userAnswer = selectionans
            }

            checkChallengeAnswerCommonForMSQ(isChecked, hwId, userAnswer, boardId, questionId, type, index, isHintUsed, selectionans, isCheckMyWork)
        }
    }

    const msqChangeHandler = (ev, hwId, boardId, questionId, type, index, isHintUsed, selectionans, isCheckMyWork) => {
        setspthwId(hwId)
        setboardId(boardId)
        setquestionId(questionId)
        setspttype(type)
        setindex(index)
        setisHintUsed(isHintUsed)
        setsptmsqSelectionAnswer(selectionans)
        setisCheckMyWork(isCheckMyWork)
        setuserGivenAnswer(ev.target.value.trim())
        setusermsqEvent(ev.target.checked)

        if (parseInt(isPtSubmitted) === 1) {
            setopenSubmittedPTMessgageModal(false)
            setopenSubmittedPTMessgageModalMSQ(true)
        }
        else {
            msqcommoncode(ev.target.checked, hwId, boardId, questionId, type, index, isHintUsed, selectionans, isCheckMyWork, ev.target.value.trim())
        }
    }

    const checkChallengeAnswerCommonForMSQ = (isChecked, hwId, userEnteredAns, boardId, questionId, type, index, isHintUsed, selectionans, isCheckMyWork) => {
        if (userEnteredAns) {

            let userAnswer = ""

            if (isChecked) {
                if (selectionans !== "" && selectionans !== null && selectionans !== undefined) {
                    userAnswer = selectionans + "###" + userEnteredAns
                }
                else {
                    userAnswer = userEnteredAns
                }
            }
            else {
                if (selectionans.includes(userEnteredAns)) {

                    let finaldata = ""
                    if (selectionans.includes("###")) {

                        let arr = selectionans.split("###")
                        arr.forEach((rem) => {
                            if (userEnteredAns !== rem) {
                                // finalarray.push(rem)
                                if (finaldata !== "" && finaldata !== null && finaldata !== undefined) {
                                    finaldata = finaldata + "###" + rem
                                }
                                else {
                                    finaldata = rem
                                }
                            }
                        })
                    }
                    userAnswer = finaldata
                }
            }

            let reqData = {
                userAnswer: userAnswer,
                questionId: questionId,
                user_id: getCurrentUserId(),
                homeworkproblems_id: hwId,
                hints_used: isHintUsed,
                problem_source: Problem_source.apptesthomework,
                boardId: boardId,
                final_submission: 0,
                optionselected: 1,
                fromTestPaper: 1,
                qtype: type
            }
            checkChallengeAnswer(reqData).then(res => {
                let isExist = false
                if (attemptedQueList.length > 0) {
                    attemptedQueList.map(cdata => {
                        return (
                            cdata.challengeId === questionId ? isExist = true : null
                        )
                    })
                }

                if (!isExist) {
                    attemptedQueList.push({
                        "challengeId": questionId,
                        "index": index
                    })
                }

                let updateToList = [...msqQuestionList]
                if (updateToList[index] !== undefined && updateToList[index] !== null) {
                    let tempArr = updateToList[index]
                    if (tempArr.is_attempted === 0) {
                        setattemptedQueCount(attemptedQueCount + 1)
                    }

                    tempArr.selectedAnswer = userAnswer
                    if (userAnswer === "") {
                        tempArr.is_attempted = 0
                    }
                    else {
                        tempArr.is_attempted = 1
                    }

                    updateToList[index] = tempArr

                    if (updateToList[index].selectedAnswer === "" || tempArr.is_attempted === 0) {
                        setattemptedQueCount(attemptedQueCount - 1)
                    }

                    setmsqQuestionList(updateToList)
                    setloading(false)
                }

                if (isCheckMyWork) {
                    setloading(true)
                    let modalContent = null;
                    let chkWorkModalTitle = null
                    if (res.data.status === 'correct') {
                        modalContent = (
                            <div className='answer-model-correct d-flex justify-content-center'>
                                <span className='correct-answer mr-2'></span>
                                <h5>Correct Answer</h5>
                            </div>
                        )
                    } else {
                        chkWorkModalTitle = (
                            <div className='d-flex align-items-center'>
                                <span className='incorrect-answer mr-2'></span>
                                <h4 className='m-0'>"This isn’t the correct answer."</h4>
                            </div>)

                        modalContent = (
                            <div className='answer-modal-incorrect'>
                                {
                                    res.data.data !== undefined && res.data.data.length > 0 ? (
                                        res.data.data.map(item => {
                                            return (
                                                <>
                                                    {
                                                        item.remedial_text !== "" && item.remedial_text !== '' && item.remedial_text !== null ? (
                                                            <>
                                                                <h5>Explanation:</h5>
                                                                <span className='d-block'>
                                                                    <p dangerouslySetInnerHTML={{ __html: item.remedial_text }}></p>
                                                                </span>
                                                            </>) : null
                                                    }
                                                    {
                                                        item.remedial_source_url !== "" && item.remedial_source_url !== '' && item.remedial_source_url !== null ?
                                                            (<>
                                                                <h5>Recommended Resources:</h5>
                                                                <p className='mb-4'>
                                                                    <a href={item.remedial_source_url} target='_blank' rel="noopener noreferrer">
                                                                        <p dangerouslySetInnerHTML={{ __html: item.remedial_source_title ? item.remedial_source_title : item.remedial_source_url }}></p></a></p>
                                                            </>) : null
                                                    }


                                                </>
                                            )
                                        })

                                    ) : null
                                }

                            </div>
                        )
                    }

                    setloading(false)
                }
            }).catch(err => {
                setloading(false)
                console.error(err.message);
                alert('Error occurred while submitting the answer.');
            })
        }
    }

    const checkChallengeAnswerCommonForMSQcheckmywork = (event, hwId, userEnteredAns, boardId, questionId, type, index, isHintUsed, selectionans, isCheckMyWork) => {
        if (userEnteredAns) {

            let userAnswer = ""
            if (event !== undefined) {
                if (event.target.checked) {
                    if (selectionans !== "" && selectionans !== null && selectionans !== undefined) {
                        userAnswer = selectionans + "###" + userEnteredAns
                    }
                    else {
                        userAnswer = userEnteredAns
                    }
                }
                else {
                    if (selectionans.includes(userEnteredAns)) {
                        // let finalarray = []
                        let finaldata = ""
                        if (selectionans.includes("###")) {

                            let arr = selectionans.split("###")
                            arr.forEach((rem) => {
                                if (userEnteredAns !== rem) {
                                    // finalarray.push(rem)
                                    if (finaldata !== "" && finaldata !== null && finaldata !== undefined) {
                                        finaldata = finaldata + "###" + rem
                                    }
                                    else {
                                        finaldata = rem
                                    }
                                }
                            })
                        }
                        userAnswer = finaldata
                    }
                }
            }
            else {
                if (selectionans.includes(userEnteredAns)) {
                    // let finalarray = []
                    let finaldata = ""
                    if (selectionans.includes("###")) {
                        let arr = selectionans.split("###")
                        arr.forEach((rem) => {
                            if (userEnteredAns !== rem) {
                                // finalarray.push(rem)
                                if (finaldata !== "" && finaldata !== null && finaldata !== undefined) {
                                    finaldata = finaldata + "###" + rem
                                }
                                else {
                                    finaldata = rem
                                }
                            }
                        })

                        userAnswer = finaldata
                    }
                    else {
                        userAnswer = selectionans.toString()
                    }
                }
            }

            let reqData = {
                userAnswer: userAnswer,
                questionId: questionId,
                user_id: getCurrentUserId(),
                homeworkproblems_id: hwId,
                hints_used: isHintUsed,
                problem_source: Problem_source.apptesthomework,
                boardId: boardId,
                final_submission: 0,
                optionselected: 1,
                fromTestPaper: 1,
                qtype: type
            }
            checkChallengeAnswer(reqData).then(res => {
                let isExist = false
                if (attemptedQueList.length > 0) {
                    attemptedQueList.map(cdata => {
                        return (
                            cdata.challengeId === questionId ? isExist = true : null
                        )
                    })
                }

                if (!isExist) {
                    attemptedQueList.push({
                        "challengeId": questionId,
                        "index": index
                    })
                }

                let updateToList = [...msqQuestionList]
                if (updateToList[index] !== undefined && updateToList[index] !== null) {
                    let tempArr = updateToList[index]
                    if (tempArr.is_attempted === 0) {
                        setattemptedQueCount(attemptedQueCount + 1)
                    }

                    tempArr.selectedAnswer = userAnswer
                    if (userAnswer === "") {
                        tempArr.is_attempted = 0
                    }
                    else {
                        tempArr.is_attempted = 1
                    }

                    updateToList[index] = tempArr

                    if (updateToList[index].selectedAnswer === "" || tempArr.is_attempted === 0) {
                        setattemptedQueCount(attemptedQueCount - 1)
                    }

                    setmsqQuestionList(updateToList)
                    setloading(false)
                }

                if (isCheckMyWork) {
                    setloading(true)
                    let modalContent = null;
                    let finaldata = []
                    let chkWorkModalTitle = null
                    if (res.data.status === 'correct') {
                        modalContent = (
                            <div className='answer-model-correct d-flex justify-content-center'>
                                <span className='correct-answer mr-2'></span>
                                <h5>Correct Answer</h5>
                            </div>

                        )
                    } else {
                        chkWorkModalTitle = (
                            <div className='d-flex align-items-center'>
                                <span className='incorrect-answer mr-2'></span>
                                <h4 className='m-0'>"This isn’t the correct answer."</h4>
                            </div>)

                        {

                            res.data.data !== undefined && res.data.data.length > 0 && res.data.data.map(item => {
                                let contentdata = (
                                    <Aux>
                                        {
                                            item.remedial_text !== "" && item.remedial_text !== '' && item.remedial_text !== null ? (
                                                <>
                                                    <h5>Explanation:</h5>
                                                    <span className='d-block'>
                                                        <p dangerouslySetInnerHTML={{ __html: item.remedial_text }}></p>
                                                    </span>
                                                </>) : null
                                        }
                                        {
                                            item.remedial_source_url !== "" && item.remedial_source_url !== '' && item.remedial_source_url !== null ?
                                                (<>
                                                    <h5>Recommended Resources:</h5>
                                                    <p className='mb-4'>
                                                        <a href={item.remedial_source_url} target='_blank' rel="noopener noreferrer">
                                                            <p dangerouslySetInnerHTML={{ __html: item.remedial_source_title ? item.remedial_source_title : item.remedial_source_url }}></p></a></p>
                                                </>) : null
                                        }
                                    </Aux>
                                )
                                finaldata.push(contentdata)
                            })
                        }
                    }

                    if (res.data.status !== 'correct') {
                        modalContent = (
                            <div className='answer-modal-incorrect'>
                                {finaldata}
                            </div>
                        )
                    }
                    setloading(false)
                    openModal(event, chkWorkModalTitle, modalContent, null, true, true);
                }
            }).catch(err => {
                setloading(false)
                console.error(err.message);
                alert('Error occurred while submitting the answer.');
            })
        }
    }

    const handletabchange = () => {
    }

    const submitExamClick = async () => {
        setdialogSubmitExamConfirmationModalopen(false)
        if (isExamSubmitted) {
            toast("Exam submitted Successfully !", {
                type: toast.TYPE.SUCCESS,
                position: toast.POSITION.BOTTOM_RIGHT
            })

            return;
        }
        isExamSubmitted = true;
        setloading(true)

        const reqGetData = {
            params: {
                user_id: getCurrentUserId(),
                unique_id: props.match.params.practiceId
            }
        }

        let isPTSubmittedAlready = await is_pt_submitted(reqGetData).then(res => {
            if (res.data !== undefined) {
                if (parseInt(res.data.isPtSubmitted) === 1) {
                    setloading(false);
                    isExamSubmitted = true;
                    toast("This practice test is already submitted.", {
                        type: toast.TYPE.WARNING,
                        position: toast.POSITION.TOP_CENTER
                    })
                    return true
                }
                else {
                    return false
                }
            }
        })

        if (!isPTSubmittedAlready) {
            //   console.log("time - " + cookies.get("timelapse", { pathname: "/" }))
            /// Update Remaining TimeLapse in DB
            // debugger;
            let timelapse = cookies.get("timelapse", { pathname: "/" })
            if (timelapse.includes("-1:-1")) {
                timelapse = "00:00:00"
            }
            // debugger;
            const reqData1 = {
                "user_id": getCurrentUserId(),
                "unique_id": parseInt(props.match.params.practiceId),
                "timelapse": timelapse, //cookies.get("timelapse", { pathname: "/" }),
                "epoch": epochValue
            }

            savepttimer(reqData1).then(res => {
                if (res.data !== undefined && res.data !== null) {
                    /// PT TimeLapse updated successfully..!
                }
            })


            let challengeList = []
            mcqQuestionList.map(ques => {
                return (
                    ques.selectedAnswer ?
                        challengeList.push(
                            {
                                "board": ques.boardId,
                                "user_id": getCurrentUserId(),
                                "is_finalsubmission": 1,
                                "hint_used": ques.hint_used,
                                "homeworkproblems_id": ques.id,
                                "problem_source": Problem_source.apptesthomework,
                                "challenge_id": ques.challenge_id,
                                "user_answer": ques.selectedAnswer,
                                "optionselected": 0,
                                "challengeMapId": ques.user_challenge_map_id
                            }
                        )
                        : null
                )
            })

            msqQuestionList.map(ques => {
                return (
                    ques.selectedAnswer ?
                        challengeList.push(
                            {
                                "board": ques.boardId,
                                "user_id": getCurrentUserId(),
                                "is_finalsubmission": 1,
                                "hint_used": ques.hint_used,
                                "homeworkproblems_id": ques.id,
                                "problem_source": Problem_source.apptesthomework,
                                "challenge_id": ques.challenge_id,
                                "user_answer": ques.selectedAnswer,
                                "optionselected": 0,
                                "challengeMapId": ques.user_challenge_map_id
                            }
                        )
                        : null
                )
            })

            frqQuestionList.map(ques => {
                //debugger;
                return (
                    ques.is_attempted === 1 ?
                        challengeList.push(
                            {
                                "board": ques.boardId,
                                "user_id": getCurrentUserId(),
                                "is_finalsubmission": 1,
                                "hint_used": ques.hint_used,
                                "homeworkproblems_id": ques.id,
                                "problem_source": Problem_source.apptesthomework,
                                "challenge_id": ques.challenge_id,
                                "user_answer": ques.type === "MCQ" ? ques.selectedAnswer : "",
                                "optionselected": 0,
                                "challengeMapId": ques.user_challenge_map_id
                            }
                        )
                        : null
                )
            })
            debugger;
            const reqData = {
                "challengelist": JSON.stringify(challengeList)
            }

            ptTestSubmit(reqData).then(res => {
                if (res.data !== undefined && res.data !== null) {
                    debugger;
                    const reqData = {
                        "user_id": getCurrentUserId(),
                        "unique_id": parseInt(props.match.params.practiceId),
                    }
                    PTTimerSubmitUpdate(reqData).then(res => {
                        if (res.data !== undefined && res.data !== null) {
                            setloading(false)
                            toast("Exam submitted Successfully !", {
                                type: toast.TYPE.SUCCESS,
                                position: toast.POSITION.BOTTOM_RIGHT
                            })
                            setTimeout(() => {
                                window.location = "/quesionRenderer/" + props.match.params.practiceId + "/guided/review"
                            }, 1500);
                        }
                    })
                        .catch((err) => {
                            setloading(false);
                            isExamSubmitted = false;
                            toast("Some error occurred while submiting this exam...!", {
                                type: toast.TYPE.ERROR,
                                position: toast.POSITION.BOTTOM_RIGHT
                            })
                            console.error(err.message);
                        });
                }

            })
        }
    }

    const showreviewdnonFRQModalclose = () => {
        setnonfrqreviewModalOpen(false)
    }

    const showreviewdModalclose = () => {
        setfrqreviewModalOpen(false)
    }

    const closeAlertDialogHandler = () => {
        setisOpenAlertDialog(false)
    }

    const openGraphInFullMode = (e) => {
        let tempArr = [];
        let contentdata = (
            <Aux>
                <div style={{ width: '1300px', height: '500px' }}>
                    <GraphComponent
                        componentId="ptSummaryPopup"
                        isauthordashboard={isViewByProfessor && props.match.params.review === "review" ? true : false}
                        selectedstudentId={student_Id}
                        openContentOptionsModal={(e, subSectionId, type, hasPosition) => openContentOptionsModal(e, subSectionId, type, hasPosition)}
                        handletabchange={() => handletabchange}
                        isPT={true}
                        

                    ></GraphComponent></div>
            </Aux>
        )
        tempArr.push(contentdata)
        let modalBody = (
            <>{tempArr}</>
        );
        openModal(e, 'Your Proficiency Graph', modalBody, null, true, false, false);
    }

    const openContentOptionsModal = (e, subSectionId, type = null, hasPosition = false) => {
        if (!isNil(type)) {
            const reqData = { params: { subSectionId: subSectionId, nodetype: type } };
            setloading(true)
            setContentLoader(true);
            setOtherContentData([]);
            setChallengeData([])
            fetchSubSectionContent(reqData)
                .then((res) => {
                    setIsContentLoaded(true)
                    if (res.data.content.length > 0) {

                        setSectionId(subSectionId);
                        setModalContentData(res.data.content);
                        setContentLoader(false);
                        if (res.data.content.length > 1) {
                            setOtherContentData(res.data.content.splice(1))
                        } else {
                            setOtherContentData([])
                        }
                    }

                    getChallenges(subSectionId, type)
                })
                .catch((err) => {
                    setContentLoader(false);
                    setIsContentLoaded(true)
                    getChallenges(subSectionId, type)
                    console.error(err.message);
                });
        }
    }

    const getChallenges = (subSectionId, chapterType) => {
        setChallengeLoader(true)
        let stId = getCurrentUserId()
        if (props.match.params.studentId !== undefined && props.match.params.studentId !== null) {
            stId = parseInt(props.match.params.studentId)
        }
        const reqData1 = { params: { id: subSectionId, user_id: stId, ch_type: chapterType } };
        dashboardnodechallenges(reqData1)
            .then((res) => {
                setSectionId(subSectionId);
                setChallengeLoader(false);
                setIsChallengesLoaded(true)
                setloading(false)
                if (res.data !== undefined && res.data !== null && res.data.length > 0) {
                    setChallengeData(res.data);
                }
                else {
                    setChallengeData([])
                }
            })
            .catch((err) => {
                setChallengeLoader([]);
                setIsChallengesLoaded(true)
                setloading(false)
                console.error(err.message);
            });
    }

    useEffect(() => {
        if (isChallengesLoaded && isContentLoaded) {
            openContentModalPopup();
            setIsContentLoaded(false)
            setIsChallengesLoaded(false)
        }

    }, [challengeData, othercontentdata])
    const openContentModalPopup = () => {

        let content = (
            <>
                <Aux>
                    <Box sx={{ width: 450 }}>
                        <div className="sidebar-right-fix pb-0 position-relative">
                            <div className="content-options">

                                {modalContentData.length > 0 ? (
                                    <HomeCurriculumContentCard
                                        title={null}
                                        data={[modalContentData[0]]}
                                        contentloader={contentloader}
                                        linkStateObj={{
                                            prevPath: ["/"],
                                            prevNode: ["Dashboard"],
                                        }}
                                    />
                                ) : (
                                    <HomeCurriculumContentCard
                                        title={null}
                                        data={modalContentData}
                                        contentloader={contentloader}
                                        linkStateObj={{
                                            prevPath: ["/"],
                                            prevNode: ["Dashboard"],
                                        }}
                                    />
                                )}
                                {othercontentdata.length > 0 ? (
                                    <div style={{ marginTop: "25px" }}>
                                        <HomeCurriculumContentCard
                                            title="Other Curated Content Options"
                                            data={othercontentdata}
                                            contentloader={false}
                                            linkStateObj={{
                                                prevPath: ["/"],
                                                prevNode: ["Dashboard"],
                                            }}
                                        />
                                    </div>
                                ) : null}


                                <h4 className='title' style={{ marginTop: "30px" }}>Interactive Problems</h4>
                                {
                                    challengeData !== undefined && challengeData !== null && challengeData.length > 0 ?
                                        (
                                            <>
                                                <ChallengeCard
                                                    title="Interactive Problems"
                                                    data={challengeData}
                                                    challengeLoader={challengeLoader}>
                                                </ChallengeCard>
                                            </>
                                        ) : (
                                            <Card
                                                style={{
                                                    position: 'relative', marginTop: "20px", padding: "50px"
                                                }}>Interactive problems will be available soon</Card>
                                        )

                                }

                            </div>
                        </div>
                    </Box>
                </Aux>
            </>
        )
        let tempArr = []
        tempArr.push(content)
        let modalBody = (
            <>{tempArr}</>
        );
        openModal(null, 'Recommended Content for Your Cognitive Profile', modalBody, null, true, false, true);
    }

    const getStudentMessage = () => {
        let msg = ''
        if (testTotalPoints !== 0) {
            let percntage = (studentTotalPoints / testTotalPoints) * 100;

            if (percntage >= 97) {
                msg = "Excellence is not an act, but a habit. You're living proof!"
            } else if (percntage >= 93) {
                msg = "Your hard work shines as brightly as your results. Keep aiming high!"
            } else if (percntage >= 90) {
                msg = "Great job! Your efforts are bringing you closer to perfection."
            } else if (percntage >= 87) {
                msg = "Almost there! Your potential is peeking through."
            } else if (percntage >= 83) {
                msg = "Solid work! Your dedication is your stepping stone to success."
            } else if (percntage >= 80) {
                msg = "You've got a strong foundation. Now, build on it!"
            } else if (percntage >= 77) {
                msg = "Your progress is commendable. Keep pushing your limits!"
            } else if (percntage >= 73) {
                msg = "Persistence conquers all. Stay determined!"
            } else if (percntage >= 70) {
                msg = "Every effort counts. You're on the right path!"
            } else if (percntage >= 50) {
                msg = "Solid, room for growth, refine understanding."
            } else {
                msg = "Identify weak areas, persevere, improve."
            }
        }


        return msg

    }
    const handleRedirectFRQ1 = (e, challenge_id) => {
        cookies.set('PTTestFRQChallengeScroll', challenge_id);
    }
    const handleRedirectFRQ = (e, challengeHref, testId, challenge_id, queIndex) => {
        cookies.set('PTTestFRQChallengeScroll', challenge_id);

        // const [sptchallengeHref, setsptchallengeHref] = useState("");
        // const [sptchallenge_id, setsptchallenge_id] = useState("");
        // const [sptqueIndex, setsptqueIndex] = useState("");
        setsptchallengeHref(challengeHref);
        setsptchallenge_id(challenge_id);
        setsptqueIndex(queIndex);
        // setdialogFRQPtSubmitModalopen(true)
        if (parseInt(isPtSubmitted) === 1) {
            setdialogFRQPtSubmitModalopen(true)
        }
        else {
            props.history.push({
                pathname: sptchallengeHref,
                state: isReview ?
                    { prevPath: ['/quesionRenderer/' + testId + "/" + practiceTestMode + "/review"], prevNode: ['Test Assessment Result'], problemSource: [`${Problem_source.apptesthomework}`] }
                    :
                    { prevPath: ['/quesionRenderer/' + testId + "/" + practiceTestMode + "/" + sptchallenge_id + "/" + sptqueIndex], prevNode: ['Practice Test Paper'], problemSource: [`${Problem_source.apptesthomework}`] }

            })

        }

    }

    const closedialogFRQPtSubmitModalopen = (e) => {

        setdialogFRQPtSubmitModalopen(false)

        props.history.push({
            pathname: sptchallengeHref,
            state: isReview ?
                { prevPath: ['/quesionRenderer/' + testId + "/" + practiceTestMode + "/review"], prevNode: ['Test Assessment Result'] }
                :
                { prevPath: ['/quesionRenderer/' + testId + "/" + practiceTestMode + "/" + sptchallenge_id + "/" + sptqueIndex], prevNode: ['Practice Test Paper'] }

        })

    }

    const closedialogViewInstructionsModalopen = (e) => {
        setdialogViewInstructionsModalopen(false)
    }

    const closedialogSubmitExamConfirmationModalopen = (e) => {
        setdialogSubmitExamConfirmationModalopen(false)
    }

    const FRQClickScroll = () => {
        const element = document.getElementById('frq-section');
        if (element) {
            element.scrollIntoView({ behavior: 'smooth' });
        }
    };
    const MSQClickScroll = () => {
        const element = document.getElementById('msq-section');
        if (element) {
            element.scrollIntoView({ behavior: 'smooth' });
        }
    };

    const MCQClickScroll = () => {
        const element = document.getElementById('mcq-section');
        if (element) {
            element.scrollIntoView({ behavior: 'smooth' });
        }
    }
    const handletopscroll = () => {
        const element = document.getElementById('top-section');
        if (element) {
            element.scrollIntoView({ behavior: 'smooth' });
        }
    }



    const usageguidehandler = () => {
        setshowModalVideo(true)
        setwhiteBoardVideoDiv(
            <div className='videoModalDiv'>
                <div className='videoDiv'>
                    <video playsinline muted loop={true} autoPlay={true} width="590" height="270" controls>
                        <source src={whiteBoardVideo} type="video/mp4" />
                    </video>
                </div>
                {/* <div className='videoHeader'>
                    <span>Welcome to Interactive Problems <SchoolIcon className='schoolIcon' /> <AutoAwesomeIcon className='awesomeIcon' /></span>
                </div> */}
                <div className='videoDescription mt-4'>
                    <p>Welcome to the Quiz Guide! In this video, we will walk you through everything you need to know to successfully complete your quiz.</p>
                </div>
                <div className='modal-button text-center'>
                    <Button className="videoClosebtn" onClick={closeusagaeguidehandler}> CLOSE </Button>
                </div>
            </div>
        )
    }
    const closeusagaeguidehandler = () => {
        setshowModalVideo(false)
    }


    const renderMCQQuestionList = (mcqQuestion, queIndex, isFromFRQ) => {
        let mcqchallengepoints = 0.00
        if (mcqQuestion.challenge_points !== undefined && mcqQuestion.challenge_points !== null) {
            mcqchallengepoints = parseFloat(mcqQuestion.challenge_points).toFixed(2)
        }
        let totalnonfrqpointss = 0.00
        if (mcqQuestion.totalnonfrqpoints !== undefined && mcqQuestion.totalnonfrqpoints !== null) {
            totalnonfrqpointss = parseFloat(mcqQuestion.totalnonfrqpoints).toFixed(2)
        }
        let mcqHTML =
            <>
                <div key={"keyQ_" + mcqQuestion.id + "_" + mcqQuestion.challenge_id} id={mcqQuestion.id + "_" + mcqQuestion.challenge_id}
                    className="questionDiv">
                    <div className="lineBreak_question"></div>
                    <div className="questionDetails">
                        {isReview ? <div className="questionHeaderChapterText">({mcqQuestion.chapter})</div> : null}
                        <div className={classes.cardDetails}>
                            <div className={classes.cardMedia}>
                                <div className={classes.librarPylaceholder}>
                                    <CardMedia
                                        component="img"
                                        // height="194px"
                                        image={imgURL + mcqQuestion.thumbnail}
                                        alt="dummy Image"
                                        className={classes.photoOutlined}
                                    // color='white'
                                    />
                                </div>
                            </div>

                            {
                                parseInt(mcqQuestion.is_multipart) === 1 ? (
                                    <span className="questionText">
                                        Statement: {parse(mcqQuestion.problemstement)}
                                    </span>)
                                    : null
                            }
                            <span className="questionText">
                                {parse(mcqQuestion.question)}
                            </span>
                        </div>
                        {
                            mcqQuestion.options.map((opt, index) => {
                                return (
                                    <div className={`no-gutters ${classes.radioGrp}`}
                                        key={mcqQuestion.challenge_id + "_" + index}
                                        style={{ display: "flex" }}>
                                        <div>
                                            <input
                                                type='radio'
                                                disabled={practiceTestMode === "exam" && parseInt(mcqQuestion.totalGradeCalculationTrial) === 5 ? true : isTutorReview}
                                                name={"rOption_" + mcqQuestion.challenge_id + "_" + index}
                                                id={"rdo_" + mcqQuestion.challenge_id + "_" + index}
                                                key={'rdoKey_' + mcqQuestion.challenge_id + "_" + index}
                                                value={opt.id}
                                                className='mr-2'
                                                checked={parseInt(mcqQuestion.selectedAnswer) === parseInt(opt.id)}
                                                onChange={(event) => mcqChangeHandler(event, mcqQuestion.id, mcqQuestion.boardId, mcqQuestion.challenge_id, mcqQuestion.type, queIndex, mcqQuestion.hint_used, "", false, isFromFRQ)}
                                            />
                                        </div>
                                        <div>
                                            <label htmlFor={"rdo_" + mcqQuestion.challenge_id + "_" + index} key={'l_' + mcqQuestion.challenge_id + "_" + index}
                                                style={{ cursor: 'pointer' }}>
                                                <p className='mb-0'>
                                                    {/* <MathJaxRenderer math={opt}></MathJaxRenderer> */}
                                                    <div dangerouslySetInnerHTML={{ __html: opt.text }} />
                                                </p>
                                            </label>
                                        </div>
                                    </div>
                                )
                            })
                        }

                        {
                            practiceTestMode === "guided" ?
                                <div className="divGuideBtns">
                                    <Tooltip key={"hint_" + mcqQuestion.challenge_id} style={{ flexDirection: 'column' }} title="Hint" placement='right' arrow >
                                        <IconButton disabled={mcqQuestion.totalGradeCalculationTrial <= 5 ? false : false} onClick={() => getHint(this, mcqQuestion.user_challenge_map_id, mcqQuestion.boardId, queIndex, mcqQuestion.type, mcqQuestion.challenge_id)}>
                                            <div className='boardBtns' >
                                                <img alt='Hint Icon' src={HintIcon} />
                                            </div>
                                            <p className='toolLabel'>Hint</p>
                                        </IconButton>
                                    </Tooltip>
                                    <Tooltip key={"sol_" + mcqQuestion.challenge_id} style={{ flexDirection: 'column' }} title="Show Full Solution" placement='right' arrow>
                                        <IconButton onClick={() => showSolution(this, mcqQuestion.user_challenge_map_id, mcqQuestion.boardId)}>
                                            <div className='boardBtns'>
                                                <img alt='Show Full Solution' src={ShowFullSolutionIcon} />
                                            </div>
                                            <p className='toolLabel'>Show Full Solution</p>
                                        </IconButton>
                                    </Tooltip>
                                    <Tooltip key={"ChkMCQW_" + mcqQuestion.challenge_id} style={{ flexDirection: 'column' }} title="Check My Work" placement='right' arrow>
                                        <IconButton disabled={mcqQuestion.totalGradeCalculationTrial <= 5 ? false : false} onClick={() => showcheckmyworkmcq(this, mcqQuestion.id, mcqQuestion.boardId, mcqQuestion.challenge_id, mcqQuestion.type, queIndex, mcqQuestion.hint_used, mcqQuestion.selectedAnswer, true, isFromFRQ)}>
                                            <div className='boardBtns'>
                                                <img alt='Check My Work' src={checkWorkIcon} />
                                            </div>
                                            <p className='toolLabel'>Check My Work</p>
                                        </IconButton>
                                    </Tooltip>
                                    {
                                        mcqQuestion.is_attempted === 1 ? (<Tooltip key={"sum_" + mcqQuestion.challenge_id} style={{ flexDirection: 'column' }} title="Show Feedback"
                                            placement='right' arrow>
                                            <IconButton onClick={() => showfeedback(this, mcqQuestion.boardId, "MCQ", mcqQuestion.challenge_id, mcqQuestion.id, mcqQuestion.totalGradeCalculationTrial, mcqQuestion.totalnonfrqpoints)}>
                                                <div className='boardBtns'>
                                                    <img alt='Show Feedback' src={ShowFeedbackIcon} />
                                                </div>
                                                <p className='toolLabel'>Show Feedback</p>
                                            </IconButton>
                                        </Tooltip>) : null
                                    }

                                </div>
                                :
                                // getInstituteId() === Institute.Rice ?
                                <div className="divGuideBtns">
                                    <p className={mcqQuestion.isCorrect ? "divMsgCorrectBtns" : "divMsgInCorrectBtns"}>
                                        {parseInt(mcqQuestion.totalGradeCalculationTrial) === 0 ?
                                            null
                                            :
                                            parseInt(mcqQuestion.totalGradeCalculationTrial) > 0 && parseInt(mcqQuestion.totalGradeCalculationTrial) < 5 ?
                                                mcqQuestion.isCorrect && parseInt(mcqQuestion.totalGradeCalculationTrial) <= 5 ?
                                                    "Correct Answer. Remaining attempt(s): " + mcqQuestion.remainingTrial : "In-Correct Answer. Try again! Remaining attempt(s): " + mcqQuestion.remainingTrial
                                                :
                                                parseInt(mcqQuestion.totalGradeCalculationTrial) === 5 && mcqQuestion.isCorrect ? "Correct Answer. All trials(5/5) are completed for this question." : "In-Correct Answer. All trials(5/5) are completed for this question."
                                        }
                                    </p>
                                    {
                                        parseInt(mcqQuestion.totalGradeCalculationTrial) >= 5 ?
                                            <Tooltip key={"sol_" + mcqQuestion.challenge_id} style={{ flexDirection: 'column' }} title="Show Full Solution" placement='right' arrow>
                                                <IconButton onClick={() => showSolution(this, mcqQuestion.user_challenge_map_id, mcqQuestion.boardId)}>
                                                    <div className='boardBtns'>
                                                        <img alt='Show Full Solution' src={ShowFullSolutionIcon} />
                                                    </div>
                                                    <p className='toolLabel'>Show Full Solution</p>
                                                </IconButton>
                                            </Tooltip>
                                            :
                                            null
                                    }
                                    {
                                        !isReview ? (
                                            <Tooltip key={"ChkMCQScore_" + mcqQuestion.challenge_id} style={{ flexDirection: 'column' }} title="Each incorrect answer(score check) will result in a 20% deduction for this question." placement='right' arrow >
                                                <IconButton disabled={parseInt(mcqQuestion.totalGradeCalculationTrial) === 5 ? true : false}
                                                    onClick={() => getMyScore(this, mcqQuestion.id, mcqQuestion.selectedAnswer, mcqQuestion.boardId, mcqQuestion.challenge_id, mcqQuestion.type, queIndex, mcqQuestion.hint_used, false, mcqQuestion.totalGradeCalculationTrial, isFromFRQ)}>
                                                    <div className='boardBtns' >
                                                        <img alt='Score Icon' src={ShowFeedbackIcon} />
                                                    </div>
                                                    <p className={parseInt(mcqQuestion.totalGradeCalculationTrial) === 5 ? "toolLabelGrey" : 'toolLabel'}>
                                                        {parseInt(mcqQuestion.totalGradeCalculationTrial) > 0 && parseInt(mcqQuestion.totalGradeCalculationTrial) <= 5 ?
                                                            parseInt(mcqQuestion.totalGradeCalculationTrial) === 5 ?
                                                                "All trials(5/5) are completed for this question" : "Check Score (" + parseInt(mcqQuestion.totalGradeCalculationTrial) + "/5)"
                                                            :
                                                            "Check Score"
                                                        }
                                                    </p>
                                                </IconButton>
                                            </Tooltip>
                                        ) : null
                                    }

                                    {
                                        mcqQuestion.is_submitted === 1 ? (<Tooltip key={"sum_" + mcqQuestion.challenge_id} style={{ flexDirection: 'column' }} title="Show Feedback"
                                            placement='right' arrow>
                                            <IconButton onClick={() => showfeedback(this, mcqQuestion.boardId, "MCQ", mcqQuestion.challenge_id, mcqQuestion.id, mcqQuestion.totalGradeCalculationTrial, mcqQuestion.totalnonfrqpoints)}>
                                                <div className='boardBtns'>
                                                    <img alt='Show Feedback' src={ShowFeedbackIcon} />
                                                </div>
                                                <p className='toolLabel'>Show Feedback</p>
                                            </IconButton>
                                        </Tooltip>) : null
                                    }
                                </div>
                            // : null

                        }
                    </div>
                    <div className="questionHeader">
                        <div className="questionHeaderText">{mcqQuestion.challenge_display}</div>
                        <div className='questionRightDiv'>

                            {mcqQuestion.totalGradeCalculationTrial > 0 ?
                                <div className="gradeCalTrial">
                                    # of Attempts : {mcqQuestion.totalGradeCalculationTrial}/5
                                </div>
                                : null}
                            <div className="points5">
                                {mcqQuestion.is_attempted === 1 ?
                                    (
                                        isReview ? "Points: " + mcqchallengepoints + "/" + totalnonfrqpointss
                                            :
                                            mcqQuestion.is_submitted === 1 ?
                                                (
                                                    "Points: " + mcqchallengepoints + "/" + totalnonfrqpointss
                                                )
                                                :
                                                (

                                                    "Points: -/" + totalnonfrqpointss

                                                )
                                    )
                                    :

                                    (

                                        "Points: -/" + totalnonfrqpointss


                                    )
                                }
                            </div>
                            <div className="questionAvatar">
                                <div className="op">{mcqQuestion.type}</div>
                                <div className="minWidth" />
                            </div>
                        </div>
                    </div>
                </div>
            </>

        return mcqHTML;
    }

    const renderMSQQuestionList = (msqQuestion, queIndex) => {
        let msqQuestionpoints = 0.00
        if (msqQuestion.challenge_points !== undefined && msqQuestion.challenge_points !== null) {
            msqQuestionpoints = parseFloat(msqQuestion.challenge_points).toFixed(2)
        }

        let msqHTML =
            <>
                <div key={"keyQ_" + msqQuestion.id + "_" + msqQuestion.challenge_id} id={msqQuestion.id + "_" + msqQuestion.challenge_id} className="questionDiv">
                    <div className="lineBreak_question"></div>
                    <div className="questionDetails">
                        {isReview ? <div className="questionHeaderChapterText">({msqQuestion.chapter})</div> : null}
                        <div className={classes.cardDetails}>
                            <div className={classes.cardMedia}>
                                <div className={classes.librarPylaceholder}>
                                    <CardMedia
                                        component="img"
                                        // height="194px"
                                        image={imgURL + msqQuestion.thumbnail}
                                        alt="dummy Image"
                                        className={classes.photoOutlined}
                                    // color='white'
                                    />
                                </div>
                            </div>
                            {
                                parseInt(msqQuestion.is_multipart) === 1 ? (
                                    <span className="questionText">
                                        Statement: {parse(msqQuestion.problemstement)}
                                    </span>)
                                    : null
                            }
                            <span className="questionText">
                                {parse(msqQuestion.question)}
                            </span>
                        </div>
                        {
                            msqQuestion.options.map((opt, index) => {
                                let selectionans = msqQuestion.selectedAnswer
                                if (msqQuestion.selectedAnswer.includes("###")) {
                                    let str = selectionans.split("###")
                                    str.forEach((rem) => {
                                        if (parseInt(opt.id) === parseInt(rem)) {
                                            selectionans = rem.trim()
                                        }
                                    })
                                }
                                return (
                                    <div className={`no-gutters ${classes.radioGrp}`} key={msqQuestion.challenge_id + "_" + index} style={{ display: "flex" }}>
                                        <div><input
                                            type='checkbox' disabled={isTutorReview}
                                            name={"cOption_" + msqQuestion.challenge_id + "_" + index}
                                            id={"chk_" + msqQuestion.challenge_id + "_" + index}
                                            key={'chkKey_' + msqQuestion.challenge_id + "_" + index}
                                            value={opt.id}
                                            className='mr-2'
                                            checked={parseInt(selectionans) === parseInt(opt.id)}
                                            onChange={(event) => msqChangeHandler(event, msqQuestion.id, msqQuestion.boardId, msqQuestion.challenge_id, msqQuestion.type, queIndex, msqQuestion.hint_used, msqQuestion.selectedAnswer, false)}
                                        /></div>

                                        <div>
                                            <label htmlFor={"chk_" + msqQuestion.challenge_id + "_" + index} key={'l_' + msqQuestion.challenge_id + "_" + index}
                                                style={{ cursor: 'pointer' }}>
                                                <div dangerouslySetInnerHTML={{ __html: opt.text }} /> </label>
                                        </div>
                                    </div>
                                )
                            })
                        }
                        {practiceTestMode === "guided" ?
                            <div className="divGuideBtns">
                                <Tooltip key={"hint_" + msqQuestion.challenge_id} style={{ flexDirection: 'column' }} title="Hint" placement='right' arrow >
                                    <IconButton onClick={() => getHint(this, msqQuestion.user_challenge_map_id, msqQuestion.boardId, queIndex, msqQuestion.type, msqQuestion.challenge_id)}>
                                        <div className='boardBtns' >
                                            <img alt='Hint Icon' src={HintIcon} />
                                        </div>
                                        <p className='toolLabel'>Hint</p>
                                    </IconButton>
                                </Tooltip>
                                <Tooltip key={"sol_" + msqQuestion.challenge_id} style={{ flexDirection: 'column' }} title="Show Full Solution" placement='right' arrow>
                                    <IconButton onClick={() => showSolution(this, msqQuestion.user_challenge_map_id, msqQuestion.boardId)}>
                                        <div className='boardBtns'>
                                            <img alt='Show Full Solution' src={ShowFullSolutionIcon} />
                                        </div>
                                        <p className='toolLabel'>Show Full Solution</p>
                                    </IconButton>
                                </Tooltip>
                                <Tooltip key={"ChkMSQW_" + msqQuestion.challenge_id} style={{ flexDirection: 'column' }} title="Check My Work" placement='right' arrow>
                                    <IconButton onClick={() => showcheckmywork(this, msqQuestion.id, msqQuestion.boardId, msqQuestion.challenge_id, msqQuestion.type, queIndex, msqQuestion.hint_used, msqQuestion.selectedAnswer, true)}>
                                        <div className='boardBtns'>
                                            <img alt='Check My Work' src={checkWorkIcon} />
                                        </div>
                                        <p className='toolLabel'>Check My Work</p>
                                    </IconButton>
                                </Tooltip>
                                {
                                    msqQuestion.is_attempted === 1 && isReview ? (<Tooltip key={"sum_" + msqQuestion.challenge_id} style={{ flexDirection: 'column' }} title="Show Feedback"
                                        placement='right' arrow>
                                        <IconButton onClick={() => showfeedback(this, msqQuestion.boardId, "MSQ", msqQuestion.challenge_id, msqQuestion.id, msqQuestion.totalGradeCalculationTrial)}>
                                            <div className='boardBtns'>
                                                <img alt='Show Feedback' src={ShowFeedbackIcon} />
                                            </div>
                                            <p className='toolLabel'>Show Feedback</p>
                                        </IconButton>
                                    </Tooltip>) : null
                                }

                            </div>
                            :
                            null
                        }
                    </div>
                    <div className="questionHeader">
                        <div className="questionHeaderText">{msqQuestion.challenge_display}</div>
                        <div className='questionRightDiv'>
                            <div className="points5">
                                {msqQuestion.is_attempted === 1 ?
                                    (isReview ? "Points: " + msqQuestionpoints + "/" + parseFloat(msqQuestion.totalnonfrqpoints).toFixed(2)
                                        :
                                        msqQuestion.is_submitted === 1 ? ("Points: " + msqQuestionpoints + "/" + parseFloat(msqQuestion.totalnonfrqpoints).toFixed(2)) : "Points: -/" + parseFloat(msqQuestion.totalnonfrqpoints).toFixed(2))
                                    : "Points: -/" + parseFloat(msqQuestion.totalnonfrqpoints).toFixed(2)
                                }
                            </div>
                            <div className="questionAvatar">
                                <div className="op">{msqQuestion.type}</div>
                                <div className="minWidth" />
                            </div>
                        </div>
                    </div>
                </div>
            </>

        return msqHTML;
    }

    const renderFRQQuestionList = (frqQuestion, queIndex, challengeHref) => {
        //console.log(frqQuestion.frqGradeCaclulationTrial)

        // let scorevalue = frqQuestion.challenge_points
        // if (frqQuestion.FRQCheckScore.includes("/")) {
        //     let splitvalue = frqQuestion.FRQCheckScore.split("/")
        //     scorevalue = parseFloat(splitvalue[0])
        // }


        let frqQuestionpoints = 0.00
        if (frqQuestion.challenge_points !== undefined && frqQuestion.challenge_points !== null) {
            frqQuestionpoints = parseFloat(frqQuestion.challenge_points).toFixed(2)
        }

        let frqtchallengepoints = 0.00
        if (frqQuestion.frqchallengepoints !== undefined && frqQuestion.frqchallengepoints !== null) {
            frqtchallengepoints = parseFloat(frqQuestion.frqchallengepoints).toFixed(2)
        }


        let frqHTML =
            <>
                <div key={"keyQ_" + frqQuestion.id + "_" + frqQuestion.challenge_id} id={frqQuestion.id + "_" + frqQuestion.challenge_id} className="questionDiv">
                    <div className="lineBreak_question"></div>
                    <div className="questionDetails">
                        {isReview ?
                            <div className="questionHeaderChapterText">({frqQuestion.chapter})</div>
                            :
                            null
                        }
                        <div className={classes.cardDetails}>
                            <div className={classes.cardMedia}>
                                <div className={classes.librarPylaceholder}>
                                    <CardMedia
                                        component="img"
                                        image={imgURL + frqQuestion.thumbnail}
                                        alt="Problem Image"
                                        className={classes.photoOutlined}
                                    />
                                </div>
                            </div>
                            {
                                parseInt(frqQuestion.is_multipart) === 1 ? (
                                    <span className="questionText">
                                        Statement: {parse(frqQuestion.problemstement)}
                                    </span>)
                                    : null
                            }
                            <span className="questionText">
                                {parse(frqQuestion.question)}
                            </span>
                        </div>
                        <div className='frqLinkDiv'>
                            {
                                parseInt(isPtSubmitted) === 1 ? (<div className={`frqLink ${isTutorReview ? ' disabled' : ''}`}
                                    onClick={(e) => handleRedirectFRQ(e, challengeHref, testId, frqQuestion.challenge_id, queIndex)}>
                                    <Link
                                        to="#"
                                        id={frqQuestion.challenge_id}
                                    >
                                        click to open workspace and submit answer
                                    </Link>
                                </div>) : (<div className={`frqLink ${isTutorReview ? ' disabled' : ''}`}>
                                    <Link
                                        onClick={(e) => handleRedirectFRQ1(e, frqQuestion.challenge_id)}
                                        to={{
                                            pathname: challengeHref,
                                            state: isReview ?
                                                { prevPath: ['/quesionRenderer/' + testId + "/" + practiceTestMode + "/review"], prevNode: ['Test Assessment Result'], problemSource: [`${Problem_source.apptesthomework}`] }
                                                :
                                                { prevPath: ['/quesionRenderer/' + testId + "/" + practiceTestMode + "/" + frqQuestion.challenge_id + "/" + queIndex], prevNode: ['Practice Test Paper'], problemSource: [`${Problem_source.apptesthomework}`] }
                                        }}
                                        id={frqQuestion.challenge_id}
                                    >
                                        <a alt="click to open workspace and submit answer"
                                            href={challengeHref}
                                        >
                                            click to open workspace and submit answer
                                        </a>
                                    </Link>
                                </div>)
                            }

                            {
                                frqQuestion.is_attempted === 1 && isReview ? (<div className="divGuideBtns">
                                    <Tooltip key={"sum_" + frqQuestion.challenge_id} style={{ flexDirection: 'column' }} title="Show Feedback"
                                        placement='right' arrow>
                                        <IconButton onClick={() =>
                                            showfeedback(this, frqQuestion.boardId, "FRQ", frqQuestion.challenge_id, frqQuestion.id, frqQuestion.frqGradeCaclulationTrial)}>
                                            <div className='boardBtns'>
                                                <img alt='Show Feedback' src={ShowFeedbackIcon} />
                                            </div>
                                            <p className='toolLabel'>Show Feedback</p>
                                        </IconButton>
                                    </Tooltip>
                                </div>)
                                    :
                                    frqQuestion.is_submitted === 1 ?
                                        (
                                            <div className="divGuideBtns">
                                                <Tooltip key={"sum_" + frqQuestion.challenge_id} style={{ flexDirection: 'column' }} title="Show Feedback"
                                                    placement='right' arrow>
                                                    <IconButton onClick={() => showfeedback(this, frqQuestion.boardId, "FRQ", frqQuestion.challenge_id, frqQuestion.id, frqQuestion.frqGradeCaclulationTrial)}>
                                                        <div className='boardBtns'>
                                                            <img alt='Show Feedback' src={ShowFeedbackIcon} />
                                                        </div>
                                                        <p className='toolLabel'>Show Feedback</p>
                                                    </IconButton>
                                                </Tooltip>
                                            </div>
                                        )
                                        : null
                            }
                        </div>
                    </div>
                    <div className="questionHeader">
                        <div className="questionHeaderText">{frqQuestion.challenge_display} {frqQuestion.is_attempted === 0 ?
                            <span style={{ fontWeight: 'bold', fontSize: '13px', color: '#7896B2' }}> Not Attempted </span> :
                            <span style={{ fontWeight: 'bold', fontSize: '13px', color: 'green' }}> Challenge Attempted </span>}</div>
                        <div className='questionRightDiv'>
                            {frqQuestion.frqGradeCaclulationTrial > 0 ?
                                <div className="gradeCalTrial">
                                    # of Attempts : {frqQuestion.frqGradeCaclulationTrial}/5
                                </div>
                                : null}
                            <div className="points5">
                                {
                                    frqQuestion.is_attempted === 1 ?
                                        (
                                            isReview ? "Points: " + frqQuestionpoints + "/" + frqtchallengepoints
                                                :
                                                frqQuestion.is_submitted === 1 ?
                                                    (
                                                        "Points: " + frqQuestionpoints + "/" + frqtchallengepoints
                                                    ) :
                                                    (

                                                        "Points: -/" + frqtchallengepoints



                                                    )
                                        )
                                        : "Points: -/" + frqtchallengepoints}
                            </div>
                            <div className="questionAvatar">
                                <div className="op">{frqQuestion.type}</div>
                                <div className="minWidth" />
                            </div>
                        </div>
                    </div>
                </div>
            </>

        return frqHTML;
    }

    const openTestInstructionPopUp = () => {
        setdialogViewInstructionsModalopen(true)
    }

    return (
        <>

            <Dialog
                className='feedbackDialog'
                disableEnforceFocus={true}
                hideBackdrop={false}
                open={openFeedbackModal}
                onClose={handlecloseFeedbackModal}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title" sx={{ borderBottom: "1px solid #ccc", borderWidth: "95%" }}>
                    Feedback
                </DialogTitle>
                <MuiIconButton
                    aria-label="close"
                    onClick={handlecloseFeedbackModal}
                    sx={{
                        position: 'absolute',
                        right: 8,
                        top: 8,
                        color: (theme) => theme.palette.grey[500],
                    }}
                >
                    <MuiCloseIcon />
                </MuiIconButton>
                <DialogContent sx={{ paddingTop: "1px !important", marginRight: "3px" }}>
                    <DialogContentText id="alert-dialog-description">
                        <FeedbackC onClose={handlecloseFeedbackModal}></FeedbackC>
                    </DialogContentText>
                </DialogContent>

            </Dialog>

            <Dialog
                hideBackdrop={false}
                open={dialogSubmitExamConfirmationModalopen}
                onClose={(e) => closedialogSubmitExamConfirmationModalopen(e)}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        <div style={{ marginTop: "10px", color: "#000", fontSize: '16px' }}>
                            <div className="examInstMainDiv">
                                {attemptedQueCount === totalQuestionCount ?
                                    "Are you sure you want to submit the entire test. You will not be able to make any changes after the submission."
                                    :
                                    "All the questions are not completed yet. Do you still want to submit the entire Quiz?"
                                }
                            </div>
                        </div>
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <div className='submitConfirmationButtonContainer'>
                        <button className={classes.btnSubmit} onClick={(e) => closedialogSubmitExamConfirmationModalopen(e)}>
                            Continue Working On Quiz
                        </button>
                        <button className={classes.btnSubmit} onClick={(e) => submitExamClick(e)}>
                            Submit Entire Quiz
                        </button>
                    </div>

                </DialogActions>
            </Dialog>

            <Dialog
                hideBackdrop={false}
                open={dialogViewInstructionsModalopen}
                onClose={(e) => closedialogSubmitExamConfirmationModalopen(e)}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        <div style={{ marginTop: "10px", color: "#164b99" }}>

                            <div className="examInstMainDiv">
                                {parse(testInstructionText)}
                            </div>
                        </div>
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <button style={{ width: "150px" }} className={classes.btnSubmit} onClick={(e) => closedialogViewInstructionsModalopen(e)}>
                        OK
                    </button>
                </DialogActions>
            </Dialog>

            <Dialog
                hideBackdrop={false}
                open={dialogFRQPtSubmitModalopen}
                onClose={(e) => closedialogFRQPtSubmitModalopen(e)}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        <div style={{ marginTop: "10px", color: "#164b99" }}>
                            Important: Your grades reflect your submitted solutions only. Any modifications afterward won't alter your grades.
                        </div>
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <button className="dragLabelcancel leftmargin" onClick={(e) => closedialogFRQPtSubmitModalopen(e)}>
                        OK
                    </button>
                </DialogActions>
            </Dialog>
            <Dialog
                hideBackdrop={false}
                open={openSubmittedPTMessgageModalMSQ}
                onClose={(e) => closepointmessagehanslerMSQ(e)}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        <div style={{ marginTop: "10px", color: "#164b99" }}>
                            Important: Your grades reflect your submitted solutions only. Any modifications afterward won't alter your grades.
                        </div>
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <button className="dragLabelcancel leftmargin" onClick={(e) => closepointmessagehanslerMSQ(e)}>
                        OK
                    </button>
                </DialogActions>
            </Dialog>

            <Dialog
                hideBackdrop={false}
                open={openSubmittedPTMessgageModal}
                onClose={(e) => closepointmessagehansler(e)}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        <div style={{ marginTop: "10px", color: "#164b99" }}>
                            Important: Your grades reflect your submitted solutions only. Any modifications afterward won't alter your grades.
                        </div>
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <button className="dragLabelcancel leftmargin" onClick={(e) => closepointmessagehansler(e)}>
                        OK
                    </button>
                </DialogActions>
            </Dialog>
            <Dialog
                hideBackdrop={false}
                open={isOpenAlertDialog}
                onClose={closeAlertDialogHandler}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        <div style={{ marginTop: "10px", color: "#164b99" }}>
                            Please select an answer first.
                        </div>
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <button className="dragLabelcancel leftmargin" onClick={closeAlertDialogHandler}>
                        OK
                    </button>
                </DialogActions>
            </Dialog>

            <Modal
                show={frqreviewModalOpen}
                onHide={showreviewdModalclose}
                draggable={false}
                popUpSize="medium"
                body=
                {
                    homeworkreviewdata !== undefined && homeworkreviewdata.length > 0 ?
                        (
                            <>
                                <div className='w-100 d-flex bg-white pr-2' >
                                    <div className={'div100'}>
                                        {homeworkreviewdata[0].isgraded ?
                                            <div style={{ paddingLeft: "20px", background: "#fff", maxWidth: "700px" }} className='popupSectionTitle'>
                                                Total Points: {homeworkreviewdata[0].totalgrade}/{homeworkreviewdata[0].totalpointscore}
                                                {
                                                    parseInt(isPtSubmitted) === 1 ? (<span style={{ paddingLeft: "5px", fontSize: "13px" }}>(Important: Your grades reflect your submitted solutions only. Any modifications afterward won't alter your grades.)</span>) : null
                                                }
                                            </div>
                                            : null}

                                        {
                                            homeworkreviewdata[0].ta_feedback["result"] !== undefined && homeworkreviewdata[0].ta_feedback["result"] !== null && homeworkreviewdata[0].ta_feedback["result"].length > 0 ?
                                                (
                                                    homeworkreviewdata[0].ta_feedback["result"].map(item => {
                                                        if (Number.isInteger(item.line_match)) {
                                                            return <HomeworkReviewDetail
                                                                graded={homeworkreviewdata[0].isgraded}
                                                                is_review_complete={is_review_complete}
                                                                item={item}
                                                                frqGradeCaclulationTrial={frqGradeCaclulationTrial}
                                                            ></HomeworkReviewDetail>
                                                        }

                                                    })
                                                ) :
                                                <>
                                                    {
                                                        homeworkreviewdata[0].ta_feedback !== undefined && homeworkreviewdata[0].ta_feedback !== null && homeworkreviewdata[0].ta_feedback.length > 0 ?
                                                            (
                                                                homeworkreviewdata[0].ta_feedback.map(item => {
                                                                    if (Number.isInteger(item.line_match)) {
                                                                        return <HomeworkReviewDetail
                                                                            graded={homeworkreviewdata[0].isgraded}
                                                                            is_review_complete={is_review_complete}
                                                                            item={item}
                                                                        ></HomeworkReviewDetail>
                                                                    }

                                                                })
                                                            ) : null
                                                    }
                                                </>
                                        }
                                    </div>
                                </div>
                            </>
                        ) : null
                }
                handleClose={showreviewdModalclose}
                title={
                    deductedpoints > 0 ? (
                        <>
                            <div className='d-flex align-items-center justify-content-between'>
                                <div>
                                    Review Detail
                                </div>
                                <div style={{ fontSize: "15px" }}>
                                    #of incorrect attempts = {attemptedIncorrect === undefined || attemptedIncorrect === null ? 0 : Math.round(attemptedIncorrect)} so, total grades are reduced by {deductedpoints === undefined || deductedpoints === null ? 0 : Math.round(deductedpoints)}%
                                </div>

                            </div>
                        </>
                    ) : "Review Detail"
                }
                showButton={false}
                customButton={
                    <div className='modal-button text-center'>
                        <Button className={classes.btnSubmit} onClick={showreviewdModalclose}> Ok </Button>
                    </div>
                }
            >
            </Modal>
            <Modal
                show={nonfrqreviewModalOpen}
                onHide={showreviewdnonFRQModalclose}
                draggable={false}
                popUpSize="medium"
                body=
                {
                    homeworknonfrqreviewdata !== undefined && homeworknonfrqreviewdata !== null && homeworknonfrqreviewdata.length > 0 ?
                        (
                            <>
                                <div className='w-100 d-flex bg-white pr-2' >
                                    <div className={'div100'}>
                                        {homeworknonfrqreviewdata[0].isgraded ?
                                            <div style={{ paddingLeft: "20px", background: "#fff", maxWidth: "700px" }} className='popupSectionTitle'>
                                                Total Points: {homeworknonfrqreviewdata[0].totalgrade}/{parseFloat(homeworknonfrqreviewdata[0].totalnonfrqpoints).toFixed(2)}

                                                {
                                                    parseInt(isPtSubmitted) === 1 ? (<span style={{ paddingLeft: "5px", fontSize: "13px" }}>(Important: Your grades reflect your submitted solutions only. Any modifications afterward won't alter your grades.)</span>) : null
                                                }
                                            </div>
                                            : null}

                                        {
                                            homeworknonfrqreviewdata[0].children !== undefined && homeworknonfrqreviewdata[0].children !== null && homeworknonfrqreviewdata[0].children.length > 0 ?
                                                (
                                                    homeworknonfrqreviewdata[0].children.map(item => {
                                                        return <HomeworkReviewDetailNonFRQ item={item} ></HomeworkReviewDetailNonFRQ>

                                                    })
                                                ) : null

                                        }
                                    </div>
                                </div>

                            </>
                        ) : null
                }
                handleClose={showreviewdnonFRQModalclose}
                title={
                    deductedpoints > 0 ? (
                        <>
                            <div className='d-flex align-items-center justify-content-between'>
                                <div>
                                    Review Detail
                                </div>
                                <div style={{ fontSize: "15px" }}>
                                    #of incorrect attempts = {attemptedIncorrect === undefined || attemptedIncorrect === null ? 0 : Math.round(attemptedIncorrect)} so, total grades are reduced by {deductedpoints === undefined || deductedpoints === null ? 0 : Math.round(deductedpoints)}%
                                </div>

                            </div>
                        </>
                    ) : "Review Detail"
                }
                showButton={false}
                customButton={
                    <div className='modal-button text-center'>
                        <Button className={classes.btnSubmit} onClick={showreviewdnonFRQModalclose}> Ok </Button>
                    </div>
                }
            >
            </Modal>

            <div className='questionRenderer' id="top-section">
                <div className='divBackLink container'>
                    <ArrowBack
                        style={{
                            color: "#4252AF",
                            width: "0.75em",
                            height: "0.98em",
                            marginLeft: "10px"
                        }}
                    />


                    <Link className='backlinkbutton'
                        to={{
                            pathname: isReview ? (props.location.state === undefined ?
                                // "/questionRenderer/" + props.match.params.practiceId + "/" + props.match.params.practiceMode
                                "/practicetest"
                                :
                                (
                                    props.location.state["prevNode"].includes('Practice Test') || props.location.state["prevNode"].includes('Quiz') ? props.location.state["prevPath"]
                                        : props.location.state["prevNode"].includes('Dashboard') || props.location.state["prevNode"].includes('Student List') ? props.location.state["prevPath"]
                                            // : "/questionRenderer/" + props.match.params.practiceId + "/" + props.match.params.practiceMode
                                            : "/practicetest"
                                )
                            )
                                : "/practicetest"
                            , state: { prevPath: ['/practicetest'], prevNode: ['Assessment_Summary'], practiceId: [`${props.match.params.practiceId}`] ,problemsource: [`${problemSource}`] }
                        }}
                    >
                        <div style={{ textDecoration: "underline" }}>
                            {/* <span style={{ marginRight: "2px" }}>Back to </span> */}
                            {
                                isReview ? (props.location.state === undefined ? getInstituteId() === Institute.Rice ? "Back to Rice University Quiz" : "Back to Practice Test" :
                                    (
                                        props.location.state["prevNode"].includes('Practice Test') || props.location.state["prevNode"].includes('Quiz')
                                            ? "Back to" + " " + props.location.state["prevNode"]
                                            : props.location.state["prevNode"].includes('Dashboard') || props.location.state["prevNode"].includes('Student List')
                                                ? "Back to" + " " + props.location.state["prevNode"]
                                                : getInstituteId() === Institute.Rice ? "Quiz" : "Practice Test"
                                    )
                                )
                                    :
                                    getInstituteId() === Institute.Rice ? "Back to Quiz" : "Back to Practice Test"
                            }
                        </div>
                    </Link>
                </div>

                <div className='container-fluid'>
                    <div className='container bg-white-with-shadow my-3 my-md-5'>
                        <div className="testTitleDiv">
                            {
                                isTutorReview ? (<p className="testTitleTextStudent">
                                    {studentName}
                                </p>) : null
                            }
                            <div className='d-flex align-items-center justify-content-between' style={{ width: "100%" }}>
                                <p className="testTitleText">
                                    {isReview ? "Assessment For " : null} {testTitle}
                                </p>
                                <p className='testTitledate'>{PtStartDate}  - {PtEndDate}</p>
                            </div>

                            <div className='d-flex align-items-center justify-content-between' style={{ width: "100%" }}>
                                <div style={{ paddingLeft: '3px' }} className='d-flex justify-content-start' >
                                    <button onClick={FRQClickScroll} className='btn btn-link'>FRQ Section</button>
                                    {getInstituteId() !== Institute.Rice ? <button onClick={MSQClickScroll} className='btn btn-link'>MSQ Section</button> : null}
                                    <button onClick={MCQClickScroll} className='btn btn-link'>MCQ Section</button>
                                </div>
                                <div className='d-flex justify-content-end'>
                                    {!isReview ? <button style={{ position: 'relative', left: '10px', textDecorationLine: 'underline' }} onClick={openTestInstructionPopUp} className='btn btn-link'>
                                        View Essential Quiz Instructions</button> : null}

                                    {!isReview ? <button style={{ position: 'relative', textDecorationLine: 'underline' }} onClick={usageguidehandler} className='btn btn-link'>
                                        Usage Guide</button> : null}
                                </div>
                            </div>

                            <div className="lineBreak"></div>
                        </div>
                        {
                            !isReview ? null :
                                <>
                                    <div style={{ height: '100%', width: '100%', float: 'left', marginBottom: '50px' }}>
                                        <div className="divOverallResult">
                                            <div className="divHeaderass">Overall Test Results</div>
                                            <div className="divProgress">
                                                <div className="divPointsCircle">
                                                    <div className="Sharts" style={{ width: 149, height: 149, justifyContent: 'center', alignItems: 'center', display: 'flex' }}>
                                                        <div className="ShartsCharts1" style={{ width: 149, height: 149, position: 'relative', flexDirection: 'column', justifyContent: 'flex-start', alignItems: 'flex-start', display: 'flex' }}>
                                                            <div className="ElementsOval" style={{ width: 149, height: 149, position: 'relative' }}>
                                                                <div className="Color" style={{ width: 149, height: 149, left: 0, top: 0, position: 'absolute', background: '#E8F5D9' }}></div>
                                                            </div>
                                                            <div className="divPoints">
                                                                <div className="divScore">{studentTotalPoints}/{testTotalPoints}</div>
                                                                <div className="divPointText">points</div>
                                                            </div>
                                                            <div className="Oval" style={{ width: 127.86, height: 149, background: '#76B72E' }}></div>
                                                        </div>
                                                    </div>
                                                    <div className="divPointDesc">{getStudentMessage()}</div>
                                                </div>
                                                {
                                                    parseInt(isPtSubmitted) === 1 ? (
                                                        <div className="divPointDesc" style={{ width: "100%" }}>
                                                            Important: Your grades reflect your submitted solutions only. Any modifications afterward won't alter your grades.

                                                        </div>
                                                    ) : null
                                                }

                                                {/* <div className="divProficiencyProgress">
                                                    <div className="divTitle">Your Proficiency</div>
                                                    <div className="divColorBarBar">
                                                        <div className="divLightGreenColorBar"></div>
                                                    </div>
                                                    <div className="divDarkGreenBar">
                                                        <div className="divYourProgresBars"></div>
                                                    </div>
                                                    <div className="divYourDigits">{userProficiency}%</div>
                                                </div>
                                                <div className="divProficiencyProgress">
                                                    <div className="divTitle">Peer Proficiency</div>
                                                    <div className="divColorBarBar">
                                                        <div className="divLightBlueColorBar"></div>
                                                    </div>
                                                    <div className="divDarkBlueBar">
                                                        <div className="divPeerProgressBar"></div>
                                                    </div>
                                                    <div className="divPeerDigits">98%</div>
                                                </div> */}
                                            </div>
                                        </div>

                                        <div className="divProfiencyResult">
                                            <div className="divHeaderass">Your Proficiency Graph</div>
                                            <div className="graph-legend" style={{ background: "#fff", bottom: 'unset', top: '12px', color: 'black' }}>
                                                <img src={legendCircle} />
                                                <div>
                                                    <div className="legend-completed">
                                                        <span>% Completion</span>
                                                    </div>
                                                    <div className="legend-mastery">
                                                        {" "}
                                                        <span>% Proficiency</span>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="divProgressGraph">
                                                {showFullApp() ?
                                                    <div className='boardBtns expandViewBtn' title='Expand' onClick={() => openGraphInFullMode()}>
                                                        <img src={ExpandIcon} />
                                                    </div> : null
                                                }

                                                <GraphComponent
                                                    componentId="ptSummary"
                                                    isauthordashboard={isViewByProfessor && props.match.params.review === "review" ? true : false}
                                                    selectedstudentId={student_Id}
                                                    openContentOptionsModal={(e, subSectionId, type, hasPosition) => openContentOptionsModal(e, subSectionId, type, hasPosition)}
                                                    handletabchange={() => handletabchange}
                                                    isPT={true}
                                                ></GraphComponent>
                                            </div>
                                        </div>
                                    </div>

                                    <div style={{ height: '100%', width: '100%', float: 'left' }}>
                                        <div className="divResult">
                                            <div className="divHeaderass marginbottom50">Problem Level Result</div>
                                        </div>
                                    </div>
                                </>
                        }

                        {mcqQuestionList !== undefined && mcqQuestionList !== null && mcqQuestionList.length > 0 ?
                            <div className="questionTypeMainDiv" id="mcq-section">
                                <div className="questionTypeDiv">
                                    <div className="questionTypeTitleDiv">
                                        <div className="questionTypeTitleText">Multiple Choice Questions</div>
                                        <div className="avatar">
                                            <div className="op">MCQ</div>
                                            <div className="minWidth" />
                                        </div>
                                    </div>
                                    <div className="totalPoints25">
                                        {isReview ? "Total Points: " + userGetMCQPoints + "/" + mcqSectionPoints


                                            : "Total Points: " + withoutUserGetMCQPoints + "/" + mcqSectionPoints}
                                    </div>
                                </div>
                                <div className="questionDivMain">
                                    {mcqQuestionList.map((question, Index) => {
                                        return (
                                            question.status === "Released" ?
                                                renderMCQQuestionList(question, Index, false)
                                                :
                                                null
                                        )

                                    })
                                    }
                                </div>
                            </div>
                            : null
                        }

                        {msqQuestionList !== undefined && msqQuestionList !== null && msqQuestionList.length > 0 ?
                            <div className="questionTypeMainDiv" id="msq-section">
                                <div className="questionTypeDiv">
                                    <div className="questionTypeTitleDiv">
                                        <div className="questionTypeTitleText">Multiple Select Questions</div>
                                        <div className="avatar">
                                            <div className="op">MSQ</div>
                                            <div className="minWidth" />
                                        </div>
                                    </div>
                                    <div className="totalPoints25">
                                        {isReview ? "Total Points: " + userGetMSQPoints + "/" + msqSectionPoints
                                            : "Total Points: " + withoutUserGetMSQPoints + "/" + msqSectionPoints}
                                    </div>

                                    <Tooltip key="msqToolTip" style={{ flexDirection: 'column' }} title="Scroll To Top"
                                        placement='top' arrow>
                                        <div onClick={handletopscroll} className='pointerclassblink'><img src={scrollUp} height="30px" width="30px" /></div>
                                    </Tooltip>

                                </div>

                                <div className="questionDivMain">
                                    {msqQuestionList.map((question, Index) => {
                                        return (
                                            question.status === "Released" ?
                                                renderMSQQuestionList(question, Index)
                                                : null
                                        )
                                    })
                                    }
                                </div>
                            </div>
                            :
                            null
                        }

                        {frqQuestionList !== undefined && frqQuestionList !== null && frqQuestionList.length > 0 ?
                            <div className="questionTypeMainDiv" id="frq-section">
                                <div className="questionTypeDiv">
                                    <div className="questionTypeTitleDiv">
                                        <div className="questionTypeTitleText">Full Response Questions</div>

                                        <div className="avatar">
                                            <div className="op">FRQ</div>
                                            <div className="minWidth" />
                                        </div>

                                    </div>
                                    <div className="totalPoints25">
                                        {isReview ? "Total Points: " + userGetFRQPoints + "/" + frqSectionPoints

                                            : "Total Points: " + withoutUserGetFRQPoints + "/" + frqSectionPoints}
                                    </div>
                                    <Tooltip key="frqToolTip" style={{ flexDirection: 'column' }} title="Scroll To Top"
                                        placement='top' arrow>
                                        <div onClick={handletopscroll} className='pointerclassblink'><img src={scrollUp} height="30px" width="30px" /></div>
                                    </Tooltip>
                                </div>
                                <span className="frqInfo">In this practice test, the format of some FRQs is enhanced to pinpoint knowledge gaps more precisely than typical text-based answers allow.</span>
                                <div className="questionDivMain">
                                    {frqQuestionList.map((question, Index) => {
                                        let challengeLink = "/answerboardpt/c" + question.challenge_id + "/" + question.id
                                        return (
                                            question.status === "Released" ?
                                                <>
                                                    {question.type === "FRQ" ?
                                                        renderFRQQuestionList(question, Index, challengeLink)
                                                        :
                                                        renderMCQQuestionList(question, Index, true)
                                                    }
                                                </>
                                                : null
                                        )
                                    })
                                    }
                                </div>
                            </div>
                            :
                            null
                        }
                    </div>

                    {isReview ? null :
                        <div className='examPaperFooterQuestion'>
                            <div className='progressFooter'>
                                {practiceTestMode === "guided" || props.match.params.review === "review" ? null :
                                    <div className="countDownDiv">
                                        <div className="divTimeCounter">
                                            <div className="timeLeft">Time left</div>
                                            <div className="timeNumber">
                                                {ptTimeLapseCounter}
                                            </div>
                                        </div>
                                    </div>
                                }
                                <div className="submitExamDiv">
                                    <div className="questionsAttempted">Attempted Questions: {attemptedQueCount}/{totalQuestionCount}</div>
                                    <div className='btnSubmitDiv'>
                                        {getInstituteId() === Institute.Rice ?
                                            <Button className={classes.btnSubmit} disabled={isExamSubmitted} onClick={() => setdialogSubmitExamConfirmationModalopen(true)}>    SUBMIT ENTIRE QUIZ</Button>
                                            :
                                            <Button className={classes.btnSubmit} disabled={isExamSubmitted} onClick={() => setdialogSubmitExamConfirmationModalopen(true)}>   SUBMIT ENTIRE QUIZ</Button>
                                        }
                                    </div>
                                </div>
                            </div>
                        </div>
                    }

                    <ToastContainer />

                </div>
            </div>

            <Modal
                show={showModal}
                handleClose={closeModal}
                title={modalTitle}
                body={modalBody}
                showButton={showButton}
                customButton={customButton}
                draggable={draggable} />

            <Modal
                show={showContentModal}
                handleClose={() => setShowContentModal(false)}
                title={contentModalTitle}
                body={contentModalBody}
                showButton={showContentModalButton}
                customButton={customButton}
                draggable={false} />

            <ProgressSidebar
                show={showProgressSidebar}
                closeSidebar={closeProgressSidebar}>
                {progressSidebarContent}
            </ProgressSidebar>

            <Modal className='modalVideo'
                show={showModalVideo}
                onHide={closeusagaeguidehandler}
                draggable={false}
                body={whiteBoardVideoDiv}
                handleClose={closeusagaeguidehandler}
                hideHeader={true}
                showButton={false}
            >
            </Modal>

            {
                loading && (
                    <CircularProgress
                        size={24}
                        sx={{
                            color: "#164b99",
                            position: 'absolute',
                            top: '50%',
                            left: '50%',
                            marginTop: '-12px',
                            marginLeft: '-12px',
                        }}
                    />
                )
            }

            <Drawer
                anchor="right"
                open={askAIsideBarOpen}
                onClose={closeaskAItutorHandler}
            >
                <div className='anyProblemChartBotpt' style={{ width: window.innerWidth * 0.25 }}>
                    <AnyProblem
                        handleremovequestions={handleremovequestions}
                        isInline={false}
                        question={askAIquestionHint}
                        onClose={() => closeaskAItutorHandler()}
                        challengeId={hintChallengeId}
                        boardId={hintBoardId}
                        equationClick={true}
                        askAIBgColor={askAIBgColor}
                        handleequationclick={handleequationclick}
                        handleremoveAskAIBgcolor={handleremoveAskAIBgcolor}
                        isWOP={false}
                        fromPTTest={true}
                    ></AnyProblem>
                </div>
            </Drawer>

            <TrapFocus open disableAutoFocus disableEnforceFocus>
                <Fade appear={false} in={bannerOpen}>
                    <Paper
                        elevation={24}
                        role="dialog"
                        aria-modal="false"
                        aria-label="Cookie banner"
                        square
                        variant="outlined"
                        tabIndex={-1}
                        sx={{
                            position: 'fixed',
                            right: 0,
                            m: 0,
                            p: 2,
                            borderWidth: "1px",
                            borderColor: "black",
                            // borderTopWidth: 1,
                            zIndex: 9999,
                            width: "20% !important",
                            right: "30px",
                            bottom: "40px",
                            borderRadius: "10px",
                            '@media (max-width: 600px)': {
                                width: '100% !important',
                                right: "0px !important"
                            },
                            '@media only screen and (min-device-width: 768px) and (max-device-width: 1024px) and (-webkit-device-pixel-ratio: 2)': {
                                width: '34% !important'
                            }
                        }}
                    >
                        <Stack
                            direction={{ xs: 'column', sm: 'row' }}
                            justifyContent="flex-start"
                            gap={2}

                        >
                            <img src={LogoSign} alt="" className="feebackcompanyLogo" />

                            <Typography sx={{
                                fontSize: "18px !important"
                            }}>Please help us improve aiPlato.</Typography>



                        </Stack>
                        <Stack
                            direction={{ xs: 'column', sm: 'row' }}
                            justifyContent="flex-end"
                            gap={2}
                            sx={{ mt: 1 }}
                        >
                            <MuiButton size="small" onClick={closeBanner} variant="outlined"
                                sx={{

                                    textTransform: "capitalize",
                                    fontSize: "15px"
                                }}>
                                No Thanks
                            </MuiButton>
                            <MuiButton size="small"
                                // target='_blank ' 
                                // href={`${configData.WEBSITE_URL}feedback`} 
                                onClick={feedbackLinkHandler}
                                variant="contained"
                                sx={{

                                    textTransform: "capitalize",
                                    fontSize: "15px",
                                    '&:hover': {
                                        color: '#ffffff',

                                    },


                                }}>
                                Feedback
                            </MuiButton>

                        </Stack>


                    </Paper>
                </Fade>
            </TrapFocus>

        </>
    )
}


export default withRouter(QuestionRenderer)