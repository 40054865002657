import React, { useEffect, useState, useRef } from "react";
import useWebSocket from 'react-use-websocket';
import { User_Role } from '../../common/Functions';
import { savesessionsynopsisresource } from '../../common/API';
import { toast } from 'react-toastify';
import {
    AppBar,
    Backdrop,
    CircularProgress,
    Container,
    CssBaseline,
    Grid,
    IconButton,
    List,
    TextField,
    Toolbar,
    Typography,
} from "@material-ui/core";
import { Send } from "@material-ui/icons";
import configData from '../../common/config.json';

// const WS_URL = 'ws://3.18.172.159:3002';
const WS_URL = configData.WSS_URL;
// const WS_URL = 'ws://localhost:8001';
// Function to create WebSocket instance with disabled SSL certificate validation
const createWebSocket = (url) => {
    const ws = new WebSocket(url, [], {
        rejectUnauthorized: false, // Disable SSL certificate validation
    });
    return ws;
};
function Chatbot(props) {
    const { sendJsonMessage, lastMessage, readyState } = useWebSocket(WS_URL);
    const [messages, setmessages] = useState([])
    const [text, settext] = useState("")
    const scrollDiv = useRef(null);
    const [messagecount, setmesssagecount] = useState(0)

    useEffect(() => {
        if (lastMessage !== null) {
            debugger
            let msg = JSON.parse(lastMessage.data)
            if (msg.content !== undefined) {
                if (msg.content.textmessage !== undefined) {
                    let boardId = msg.content.boardId
                    if (msg.type === 'contentchat' && boardId === props.boardId) {
                        let messagedata = msg.content.textmessage
                        let previousmessags = [...messages]
                        previousmessags.push(messagedata)
                        setmessages(previousmessags)
                    }
                }
            }
        }
    }, [lastMessage]);

    const handlesendMessage = () => {
       
        if (messagecount < 5) {
            const reqdata = {
                roomId: props.roomId,
                resource_text: text,
                challenge_id: props.challengeId
            }
            savesessionsynopsisresource(reqdata).then(res => {
                if (res.status === 200) {
                    debugger;
                    sendJsonMessage({
                        action: "broadcastMessage",
                        message: {
                            type: 'contentchat',
                            content: { textmessage: text, userRole: props.userRole, boardId: props.boardId, type: 'chat' }
                        }
                    });
                    setmesssagecount(messagecount + 1)
                    settext("")
                }
            }).catch(err => {
                toast.error("Error!", {
                    position: toast.POSITION.BOTTOM_RIGHT
                });
                console.error(err.message)
            })
        }
        else {
            toast.error("You can enter upto 5 resource URL!", {
                position: toast.POSITION.BOTTOM_RIGHT
            });

        }

    }
    return (
        <>
            <Container component="main" maxWidth="md">
                <Grid container direction="column" style={styles.mainGrid}>
                    <Grid item style={styles.gridItemChatList} ref={scrollDiv} >
                        <List dense={true}>
                            {messages &&
                                messages.map((message) => (
                                    <a href={message}
                                        target='_blank' rel="noopener noreferrer">
                                        <div dangerouslySetInnerHTML={{ __html: message}}></div></a>
                                ))}
                        </List>
                    </Grid>
                    {
                        props.userRole === User_Role.Tutor || props.userRole === User_Role.Professor ? (<Grid item style={styles.gridItemMessage}>
                            <Grid
                                container
                                direction="row"
                                justify="center"
                                alignItems="center"
                            >
                                <Grid item style={styles.textFieldContainer}>
                                    <TextField
                                        required
                                        style={styles.textField}
                                        placeholder="Enter Resource URL"
                                        variant="outlined"
                                        multiline
                                        value={text}
                                        onChange={(event) =>
                                            settext(event.target.value)
                                        }
                                    />
                                </Grid>
                                <Grid item>
                                    <IconButton
                                        style={styles.sendButton}
                                        onClick={handlesendMessage}
                                    //disabled={!channel || !text}
                                    >
                                        <Send style={styles.sendIcon} />
                                    </IconButton>
                                </Grid>
                            </Grid>
                        </Grid>) : null
                    }
                </Grid>
            </Container>
        </>
    );
}
const styles = {
    textField: { width: "100%", borderWidth: 0, borderColor: "transparent" },
    textFieldContainer: { flex: 1, marginRight: 12 },
    gridItem: { paddingTop: 12, paddingBottom: 12 },
    gridItemChatList: { overflow: "auto", height: "20vh" },
    gridItemMessage: { marginTop: 12, marginBottom: 12 },
    sendButton: { backgroundColor: "#3f51b5" },
    sendIcon: { color: "white" },
    mainGrid: { borderWidth: 1 },
};
export default Chatbot;