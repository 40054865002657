import React, { useEffect, useState } from 'react';
import Modal from '../Common/ModalComponent/ModalComponent';
// import Table from '@mui/material/Table';
// import TableBody from '@mui/material/TableBody';
import { tableCellClasses } from '@mui/material/TableCell';
// import TableContainer from '@mui/material/TableContainer';
// import TableHead from '@mui/material/TableHead';
// import TableRow from '@mui/material/TableRow';
// import { useParams } from 'react-router-dom';
import './StudentList.scss';
import {
    Avatar,
    makeStyles

} from '@material-ui/core';
import { useParams } from 'react-router-dom';
import {
    getstudentlist_nonfrqReview, update_nonfrqReview, studentnonfrqhomework_detail, imgURL
} from '../../common/API'
import ArrowForwardIcon from '@material-ui/icons/ArrowForward';
import Aux from '../../hoc/Wrapper';
import Button from '../Common/Button/Button';
import { toast } from 'react-toastify';
import { Row, Col } from 'react-bootstrap';
import { styled } from '@mui/material/styles';
import CircularProgress from '@mui/material/CircularProgress';
//import Paper from '@mui/material/Paper';
import { getCurrentUserId,formatNumber } from '../../common/Functions';
//import { IconButton } from '@mui/material'
//import Tooltip from '@mui/material/Tooltip';
import HintIcon from '../../assets/images/icon-hint-show.svg';
import checkWorkIcon from '../../assets/images/icon-check.svg';
import ShowNextstepIcon from '../../assets/images/icon-show-next-step.svg';
import ShowFullSolutionIcon from '../../assets/images/icon-show-full-solution.svg';
import CheckMyworkResultCNonFRQ from '../Answerboard/CheckMyworkResultCNonFRQ';
import Pagination from '@mui/material/Pagination';
import Stack from '@mui/material/Stack';
import PropTypes from 'prop-types';
import { alpha } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import TableSortLabel from '@mui/material/TableSortLabel';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import Paper from '@mui/material/Paper';
import Checkbox from '@mui/material/Checkbox';
import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';
import FormControlLabel from '@mui/material/FormControlLabel';
import Switch from '@mui/material/Switch';
import DeleteIcon from '@mui/icons-material/Delete';
import FilterListIcon from '@mui/icons-material/FilterList';
import { visuallyHidden } from '@mui/utils';



function descendingComparator(a, b, orderBy) {
    if (b[orderBy] < a[orderBy]) {
        return -1;
    }
    if (b[orderBy] > a[orderBy]) {
        return 1;
    }
    return 0;
}

function getComparator(order, orderBy) {
    return order === 'desc'
        ? (a, b) => descendingComparator(a, b, orderBy)
        : (a, b) => -descendingComparator(a, b, orderBy);
}


function stableSort(array, comparator) {
    const stabilizedThis = array.map((el, index) => [el, index]);
    stabilizedThis.sort((a, b) => {
        const order = comparator(a[0], b[0]);
        if (order !== 0) {
            return order;
        }
        return a[1] - b[1];
    });
    return stabilizedThis.map((el) => el[0]);
}



function EnhancedTableHead(props) {

    const { onSelectAllClick, order, orderBy, numSelected, rowCount, onRequestSort } =
        props;
    const createSortHandler = (property) => (event) => {
        onRequestSort(event, property);
    };

    return (
        <TableHead>
            <TableRow>
                <TableCell
                    key="student_name"
                    align={'left'}
                    padding={'normal'}
                    sortDirection={orderBy === "student_name" ? order : false}
                    sx={{ cursor: 'pointer' }}
                >
                    <TableSortLabel
                        active={orderBy === "student_name"}
                        direction={orderBy === "student_name" ? order : 'asc'}
                        onClick={createSortHandler("student_name")}
                    >
                        Student Name
                        {orderBy === "student_name" ? (
                            <Box component="span" sx={visuallyHidden}>
                                {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                            </Box>
                        ) : null}
                    </TableSortLabel>
                </TableCell>
                <TableCell
                    key="totalgrade"
                    align={'left'}
                    padding={'normal'}
                    sortDirection={orderBy === "totalgrade" ? order : false}
                    sx={{ cursor: 'pointer' }}
                >
                    <TableSortLabel
                        active={orderBy === "totalgrade"}
                        direction={orderBy === "totalgrade" ? order : 'asc'}
                        onClick={createSortHandler("totalgrade")}
                    >
                        Total Grades
                        {orderBy === "totalgrade" ? (
                            <Box component="span" sx={visuallyHidden}>
                                {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                            </Box>
                        ) : null}
                    </TableSortLabel>
                </TableCell>
                {/* <TableCell
                    key="common_feedback"
                    align={'left'}
                    padding={'normal'}
                    sortDirection={orderBy === "common_feedback" ? order : false}
                    sx={{ cursor: 'pointer' }}
                >
                    <TableSortLabel
                        active={orderBy === "common_feedback"}
                        direction={orderBy === "common_feedback" ? order : 'asc'}
                        onClick={createSortHandler("common_feedback")}
                    >
                        Feedback
                        {orderBy === "common_feedback" ? (
                            <Box component="span" sx={visuallyHidden}>
                                {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                            </Box>
                        ) : null}
                    </TableSortLabel>
                </TableCell> */}
            </TableRow>
        </TableHead>
    );
}

EnhancedTableHead.propTypes = {
    numSelected: PropTypes.number.isRequired,
    onRequestSort: PropTypes.func.isRequired,
    // onSelectAllClick: PropTypes.func.isRequired,
    order: PropTypes.oneOf(['asc', 'desc']).isRequired,
    orderBy: PropTypes.string.isRequired,
    // rowCount: PropTypes.number.isRequired,
};

const useStyles = makeStyles((theme) => ({
    root: {},
    avatar: {
        height: 42,
        width: 42,
        marginRight: theme.spacing(1)
    },
    avatarlarge: {
        height: 150,
        width: 150,
    },
    linkbutton: {
        fontSize: "10px"
    },
}));
const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
        backgroundColor: "#a4a4a4",
        color: theme.palette.common.white,
        padding: "10px",
        fontSize: 16,
        fontWeight: "bold"
    },
    [`&.${tableCellClasses.body}`]: {
        fontSize: 14,
        padding: "7px"
    },
}));
const StyledTableRow = styled(TableRow)(({ theme }) => ({
    '&:nth-of-type(even)': {
        backgroundColor: theme.palette.action.hover,
    },
    // hide last border
    '&:last-child td, &:last-child th': {
        border: 0,
    },
}));


const StudentListNonFRQ = () => {

    const params = useParams();
    const classes = useStyles();
    const [challengeTitle, setchallengeTitle] = useState("")
    const [studentData, setstudentData] = useState([])
    const [modalData1, setmodalData1] = useState([])
    const [flowUIJson, setflowUIJson] = useState([])
    const [selectedStudentName, setselectedStudentName] = useState("")
    const [selectedStudent_id, setselectedStudent_id] = useState(0)
    const [boardId, setboardId] = useState(null)
    const [showModal, setshowmodel] = useState(false)
    const [modalTitle, setModalTitle] = useState(null)
    const [currentindex, setcurrentindex] = useState(null)
    const [open, setOpen] = useState(false);
    const [hintdata, sethintdata] = useState([])
    const [loading, setloading] = useState(false)
    const [hintDissplay, sethintDisplay] = useState([])
    const [updatedtagrade, setupdatedtagrade] = useState(0)
    const [currentIndex1, setcurrentindex1] = useState(null)
    const [is_review_complete, setis_review_complete] = useState(false)
    const [currentIndexText1, setcurrentindexText1] = useState(null)
    const [modalData1Text, setmodalData1Text] = useState([])
    const [showModalText, setshowmodelText] = useState(false)
    const [isgraded, setIsGraded] = useState(false)
    const [challengeType, setchallengeType] = useState("")
    const [hintDivVisible, setHintDivVisible] = useState(false)
    const [checkMyWorkDivVisible, setCheckMyWorkDivVisible] = useState(false)
    const [showNextStepDivVisible, setShowNextStepDivVisible] = useState(false)
    const [showSolutionDivVisible, setShowSolutionDivVisible] = useState(false)
    const [reSubmitMessageShow, setReSubmitMessageShow] = useState(false)
    const [totalPages, settotalPages] = useState(1)
    const [page, setpage] = useState(1)
    const [startPage, setstartPage] = useState(0)
    const [rowsPerPage, setrowsPerPage] = useState(10)
    const [order, setOrder] = React.useState('asc');
    const [orderBy, setOrderBy] = React.useState('student_name');
    const [selected, setSelected] = React.useState([]);

    let challengeId = params.challengeId
    let homeworkproblems_id = params.homeworkId
    useEffect(() => {
        setloading(true)
        const reqData = { params: { 'challenge_id': challengeId, 'homeworkproblems_id': homeworkproblems_id, 'problem_source': params.problemsource } }
        getstudentlist_nonfrqReview(reqData).then(res => {
            if (res.data !== undefined && res.data !== null) {
                setchallengeTitle(res.data.challengeName)
                if (res.data.data !== undefined && res.data.data !== null && res.data.data.length > 0) {
                    if (res.data.data[0] !== undefined) {
                        setis_review_complete(res.data.data[0].is_review_complete)
                        setchallengeType(res.data.data[0].challenge_type)
                        setstudentData(res.data.data)
                        settotalPages(Math.ceil(res.data.data.length / rowsPerPage))
                        setloading(false)
                    }
                }
                else {
                    setloading(false)
                }
            }
            else {
                setloading(false)
            }
        }).catch(err => {
            toast.error("Error!", {
                position: toast.POSITION.BOTTOM_RIGHT
            });
            setloading(false)
            console.error(err.message)
        })

    }, [])

    const resetdta = () => {
        setmodalData1(null)
        setshowmodel(true)
        setloading(false)
    }


    const handleChangePage = (event, page) => {
        // this.setState({ page }, () => {
        //     // this.raiseHandClickHandler()
        // });
        setpage(page)
        setstartPage(page - 1)
    }


    const handleRequestSort = (event, property) => {
        debugger;
        const isAsc = orderBy === property && order === 'asc';
        setOrder(isAsc ? 'desc' : 'asc');
        setOrderBy(property);
    };
    const isSelected = (id) => selected.indexOf(id) !== -1;

    // Avoid a layout jump when reaching the last page with empty rows.
    const emptyRows =
        page > 0 ? Math.max(0, (1 + page) * rowsPerPage - studentData.length) : 0;

    const visibleRows = React.useMemo(
        () =>
            stableSort(studentData, getComparator(order, orderBy)).slice(
                startPage * rowsPerPage,
                startPage * rowsPerPage + rowsPerPage,
            ),
        [studentData, order, orderBy, page, rowsPerPage],
    );


    const openStudentLinkPopUp = (boardid, studentId, studentName, hintdata, graded, index, challenge_type) => {
        setloading(true)
        setHintDivVisible(false)
        setCheckMyWorkDivVisible(false)
        setShowNextStepDivVisible(false)
        setselectedStudentName(studentName)
        setchallengeType(challenge_type)
        setselectedStudent_id(studentId)
        setModalTitle('Homework Details For : ' + studentName)
        const reqData = { params: { 'challenge_id': challengeId, 'homeworkproblems_id': homeworkproblems_id, "studentId": studentId, 'problem_source': params.problemsource, "boardId": boardid } }
        studentnonfrqhomework_detail(reqData).then(res => {
            //
            if (res.data.data[0] !== undefined) {
                setboardId(boardid)
                sethintdata(hintdata)
                setcurrentindex1(index)
                setIsGraded(graded)

                if (res.data.data[0].submit_id !== undefined && res.data.data[0].submit_id !== null) {
                    if (parseInt(res.data.data[0].submit_id) > 1)
                        setReSubmitMessageShow(true)
                    else {
                        setReSubmitMessageShow(false)
                    }
                }
                else {
                    setReSubmitMessageShow(false)
                }

                if (res.data.data[0].children !== undefined && res.data.data[0].children !== null && res.data.data[0].children.length > 0) {

                    if (challenge_type !== "Text") {
                        let childdata = res.data.data[0].children
                        // debugger;

                        // let finaldata = []
                        // res = {
                        //     answer_text: "Plot D",
                        //     boardId: "06883ad3-34cc-46aa-a6e0-7a554d988cc6",
                        //     challenge_type: "mcq",
                        //     checkmyworkuseddata: "",
                        //     hints_points: "0.00",
                        //     //hints_used: [{ remedial_source_title: "", remedial_source_url: "", remedial_text: "" }],
                        //     id: 69,
                        //     is_answer_correct: true,
                        //     is_review_complete: false,
                        //     is_review_complete_common: false,
                        //     isgraded: false,
                        //     points: "0.00",
                        //     student_answer: { 'studentAnswer': 164, 'correctAnswer': 164, 'result': { 'data': [{ 'status': 'correct' }], 'req_know': ['346'], 'status': 'correct' } },
                        //     student_id: 34,
                        //     ta_feedback: null,
                        //     ta_hint_points: "0.00",
                        //     ta_user_id: null,
                        //     totalpointscore: 0
                        // }
                        // finaldata.push(res)
                        setmodalData1(childdata)
                        setshowmodelText(false)
                        setmodalData1Text(null)
                        setshowmodel(true)
                    }
                    else {
                        setshowmodelText(true)
                        setshowmodel(false)
                        setmodalData1(null)
                        setmodalData1Text(res.data.data[0].children)
                    }
                    setloading(false)
                }
                else {
                    resetdta()
                }
            }
            else {
                resetdta()
            }
        })


    }
    const handleclosemodal = () => {
        setmodalData1(null)
        setshowmodel(false)
    }

    const handleclosemodalText = () => {
        setshowmodelText(null)
        setshowmodelText(false)
    }

    const handleFeedbackChangeEventCorrectText = (e, index) => {
        e.preventDefault();
        e.stopPropagation();
        let changeData = []
        if (modalData1Text !== undefined && modalData1Text.length > 0) {
            changeData = [...modalData1Text]
            // setcurrentindex(index)
            if (changeData[index] !== undefined) {
                let tempArr = changeData[index]
                tempArr.ta_feedback = e.target.value
                changeData[index] = tempArr
                setmodalData1Text(changeData)
            }
        }
    }

    const handleFeedbackChangeEventCorrect = (e, index) => {
        e.preventDefault();
        e.stopPropagation();
        let changeData = []
        if (modalData1 !== undefined && modalData1.length > 0) {
            changeData = [...modalData1]
            setcurrentindex(index)
            if (changeData[index] !== undefined) {
                let tempArr = changeData[index]
                tempArr.ta_feedback = e.target.value
                changeData[index] = tempArr
                setmodalData1(changeData)
            }
        }
    }

    const handleFeedbackChangeEventIncorrect = (e, index) => {
        e.preventDefault();
        e.stopPropagation();
        let changeData = []
        if (modalData1 !== undefined && modalData1.length > 0) {
            changeData = [...modalData1]
            setcurrentindex(index)
            if (changeData[index] !== undefined) {
                let tempArr = changeData[index]
                tempArr.ta_feedback = e.target.value
                changeData[index] = tempArr
                setmodalData1(changeData)
            }
        }
    }

    const handleHintPointsChangeEvent = (e, index) => {
        e.preventDefault();
        e.stopPropagation();
        let changeData = []
        if (modalData1 !== undefined && modalData1.length > 0) {
            changeData = [...modalData1]
            setcurrentindex(index)
            if (changeData[index] !== undefined) {
                let tempArr = changeData[index]
                tempArr.ta_hint_points = e.target.value
                changeData[index] = tempArr
                setmodalData1(changeData)
            }
        }
    }
    const handlePointsTextChangeEvent = (e, index) => {
        e.preventDefault();
        e.stopPropagation();
        let changeData = []
        if (modalData1Text !== undefined && modalData1Text.length > 0) {
            changeData = [...modalData1Text]
            setcurrentindexText1(index)
            if (changeData[index] !== undefined) {
                let tempArr = changeData[index]
                tempArr.points = e.target.value
                changeData[index] = tempArr
                setmodalData1Text(changeData)
            }
        }
    }
    const handlePointsChangeEvent = (e, index) => {
        e.preventDefault();
        e.stopPropagation();
        let changeData = []
        if (modalData1 !== undefined && modalData1.length > 0) {
            changeData = [...modalData1]
            setcurrentindex(index)
            if (changeData[index] !== undefined) {
                let tempArr = changeData[index]
                tempArr.points = e.target.value
                changeData[index] = tempArr
                setmodalData1(changeData)
            }
        }
    }
    const handleUpdateText = () => {
        let ta_hint_points = 0
        let tafeedbackData = []
        let ta_totalgrades = 0
        modalData1Text.map((item) => {
            tafeedbackData.push({
                "id": item.id,
                "points": item.points,
                "ta_feedback": item.ta_feedback,
                "ta_hint_points": item.ta_hint_points
            })
            if (parseFloat(item.points, 2) < 0) {
                ta_totalgrades = parseFloat(ta_totalgrades, 2) + parseFloat(item.points, 2)
            }
            else {
                ta_totalgrades = parseFloat(ta_totalgrades, 2) - parseFloat(item.points, 2)
            }
        })

        let form_data = new FormData();
        form_data.append("ta_user_data", JSON.stringify(tafeedbackData))
        form_data.append("problem_source", params.problemsource)
        form_data.append("challengeId", challengeId)
        form_data.append("ta_user_id", getCurrentUserId())
        form_data.append("user_id", selectedStudent_id)
        form_data.append("homeworkproblems_id", homeworkproblems_id)
        form_data.append("boardId", boardId)
        form_data.append("ta_totalgrades", ta_totalgrades)
        form_data.append("ta_hint_points", ta_hint_points)

        update_nonfrqReview(form_data).then(res => {
            if (res.status === 200) {
                toast.success("Updated successfully!", {
                    position: toast.POSITION.BOTTOM_RIGHT
                });
                // setupdatedtagrade(res.data)
                let changeData = [...studentData]
                if (studentData[currentIndexText1] !== undefined) {
                    let tempArr = changeData[currentIndexText1]
                    if (ta_totalgrades === "NaN") {
                        ta_totalgrades = 0
                    }
                    tempArr.totalgrade = parseFloat(ta_totalgrades, 2)
                    changeData[currentIndexText1] = tempArr
                    setstudentData(changeData)
                }
                setshowmodelText(false)
            }
        }).catch(err => {

            toast.error("Error!", {
                position: toast.POSITION.BOTTOM_RIGHT
            });
            setshowmodelText(false)
            console.error(err.message)
        })

    }
    const handleUpdate = () => {
        let tafeedbackData = []
        let ta_totalgrades = 0
        let ta_hint_points = 0
        modalData1.map((item) => {
            // debugger;
            //ta_hint_points = parseFloat(ta_hint_points, 2) - (parseFloat(item.ta_hint_points, 2))
            if (Boolean(item.is_answer_correct) === true) {
                tafeedbackData.push({
                    "is_answer_correct": item.is_answer_correct,
                    "id": item.id,
                    "points": item.points,
                    "ta_feedback": item.ta_feedback,
                    "ta_hint_points": item.ta_hint_points
                },
                )

                ta_totalgrades = parseFloat(ta_totalgrades, 2) + parseFloat(item.points, 2)
                // ta_totalgrades = parseFloat(ta_totalgrades, 2) + parseFloat(item.hint_points, 2)

                if (parseFloat(item.ta_hint_points, 2) < 0) {
                    ta_totalgrades = parseFloat(ta_totalgrades, 2) + parseFloat(item.ta_hint_points, 2)
                }
                else {
                    ta_totalgrades = parseFloat(ta_totalgrades, 2) - parseFloat(item.ta_hint_points, 2)
                }

            }
            else {
                tafeedbackData.push({
                    "is_answer_correct": item.is_answer_correct,
                    "id": item.id,
                    "points": item.points,
                    "ta_feedback": item.ta_feedback,
                    "ta_hint_points": item.ta_hint_points
                },
                )
                if (parseFloat(item.points, 2) < 0) {
                    ta_totalgrades = parseFloat(ta_totalgrades, 2) + parseFloat(item.points, 2)
                }
                else {
                    ta_totalgrades = parseFloat(ta_totalgrades, 2) - parseFloat(item.points, 2)
                }
            }
            return tafeedbackData

        })
        // alert(ta_hint_points)
        console.log("ta feedback json - " + JSON.stringify(tafeedbackData))
        let form_data = new FormData();
        form_data.append("ta_user_data", JSON.stringify(tafeedbackData))
        form_data.append("problem_source", params.problemsource)
        form_data.append("challengeId", challengeId)
        form_data.append("ta_user_id", getCurrentUserId())
        form_data.append("user_id", selectedStudent_id)
        form_data.append("homeworkproblems_id", homeworkproblems_id)
        form_data.append("boardId", boardId)
        form_data.append("ta_totalgrades", ta_totalgrades)
        form_data.append("ta_hint_points", ta_hint_points)

        update_nonfrqReview(form_data).then(res => {
            if (res.status === 200) {
                toast.success("Updated successfully!", {
                    position: toast.POSITION.BOTTOM_RIGHT
                });
                // setupdatedtagrade(res.data)
                let changeData = [...studentData]
                if (studentData[currentIndex1] !== undefined) {
                    let tempArr = changeData[currentIndex1]
                    if (ta_totalgrades === "NaN") {
                        ta_totalgrades = 0
                    }
                    tempArr.totalgrade = parseFloat(ta_totalgrades, 3)
                    changeData[currentIndex1] = tempArr
                    setstudentData(changeData)
                }
                setshowmodel(false)
            }
        }).catch(err => {
            toast.error("Error!", {
                position: toast.POSITION.BOTTOM_RIGHT
            });
            setshowmodel(false)
            console.error(err.message)
        })
    }
    const hinticonhandler = () => {
        setHintDivVisible(!hintDivVisible)
    }
    const checkmyworkiconhandler = () => {
        setCheckMyWorkDivVisible(!checkMyWorkDivVisible)
    }

    const showNextStephandler = () => {
        setShowNextStepDivVisible(!showNextStepDivVisible)
    }

    const showSholutionhandler = () => {
        setShowSolutionDivVisible(!showSolutionDivVisible)
    }

    return (
        <>
            <Modal
                show={showModalText}
                onHide={handleclosemodalText}
                draggable={false}
                popUpSize="small"
                body=
                {
                    <>
                        <div className='model-answers'>
                            {
                                modalData1Text !== null && modalData1Text !== undefined && modalData1Text.length > 0 ?

                                    modalData1Text.map((item, index) => {

                                        let converedclassname = "mt-1 align-items-center eqfontText popupSectionTitlemediumlatex green"
                                        let missingconceptclassname = "mt-1 align-items-center eqfontText popupSectionTitlemediumlatex red"
                                        let converedtopics = []
                                        let missingconcepts = [];
                                        if (item.student_answer.covered_concepts !== undefined && item.student_answer.covered_concepts !== null && item.student_answer.covered_concepts.length > 0) {
                                            item.student_answer.covered_concepts.forEach((rem) => {
                                                let data = ""
                                                data = rem
                                                let contentdata1 = (
                                                    <Aux>
                                                        {
                                                            (data && data !== null && data !== 'null' && data !== "null" && data != "undefined" && data !== "" && data !== '' && data !== ',' && data !== ",") ?
                                                                <div className="mb-1 mt-1" dangerouslySetInnerHTML={{ __html: data }}></div>
                                                                : null
                                                        }
                                                    </Aux>
                                                );
                                                converedtopics.push(contentdata1)
                                            })
                                        }
                                        if (item.student_answer.missing_concepts !== undefined && item.student_answer.missing_concepts !== null && item.student_answer.missing_concepts.length > 0) {
                                            item.student_answer.missing_concepts.forEach((rem) => {
                                                let data = ""
                                                data = rem
                                                let contentdata1 = (
                                                    <Aux>
                                                        {
                                                            (data && data !== null && data !== 'null' && data !== "null" && data != "undefined" && data !== "" && data !== '' && data !== ',' && data !== ",") ?
                                                                <div className="mb-1 mt-1" dangerouslySetInnerHTML={{ __html: data }}></div>
                                                                : null
                                                        }
                                                    </Aux>
                                                );
                                                missingconcepts.push(contentdata1)
                                            })
                                        }
                                        return (
                                            <>
                                                <div className='correctAnswerContainer'>
                                                    <span className='answer-correct-sign'></span>
                                                    <div className='answer-container'>
                                                        <div className='d-flex align-items-center justify-content-between pb-3'>
                                                            <div>
                                                                <p className='yrResTxt'>Your Response</p>
                                                            </div>
                                                            <div className='pointsContainer'>
                                                                <span>Points: </span>
                                                                <input name="points" class="form-control bg-yellow" type='text'
                                                                    onChange={(e) => handlePointsTextChangeEvent(e, index)}
                                                                    value={item.points}></input>
                                                            </div>
                                                        </div>
                                                        <div className="feedbackBox popupSectionTitlemediumwithoutborder" style={{ width: "600px", fontSize: "14px" }}>
                                                            {item.answer_text}
                                                        </div>
                                                        <div>
                                                            <div className='popupSectionTitlemediumborder popupSectionTitlemedium' style={{ marginTop: "20px" }}>Covered Topics: </div>
                                                            <div className={converedclassname} style={{ width: "600px" }}>
                                                                {converedtopics}
                                                            </div>
                                                        </div>

                                                        {
                                                            missingconcepts !== undefined && missingconcepts !== null && missingconcepts.length > 0 ?
                                                                (
                                                                    <>
                                                                        <div className='popupSectionTitlemediumborder popupSectionTitlemedium' style={{ marginTop: "20px" }}>Missing Concepts: </div>
                                                                        <div className={missingconceptclassname} style={{ width: "600px" }}>
                                                                            {missingconcepts}
                                                                        </div></>) : null
                                                        }
                                                        <div className='feedbackBox'>
                                                            <span >Additional feedback by professor</span>
                                                            <textarea rows={3} type='text'
                                                                value={item.ta_feedback}
                                                                onChange={(e) => handleFeedbackChangeEventCorrectText(e, index)}
                                                            >
                                                            </textarea>
                                                        </div>
                                                    </div>
                                                </div>
                                            </>
                                        )
                                    })

                                    : "No homework details found for this challenge !"
                            }
                        </div>
                    </>
                }
                handleClose={handleclosemodalText}
                title={modalTitle}
                showButton={false}
                customButton={
                    <div className='modal-button text-center'>
                        <Button className="yellow-btn"
                            clicked={() => handleUpdateText()}> Update </Button>
                        <span style={{ marginLeft: "10px" }}>
                            <Button className="grey-btn"
                                clicked={handleclosemodalText}> Cancel </Button></span>
                    </div>
                }>
            </Modal>
            <Modal
                show={showModal}
                onHide={handleclosemodal}
                draggable={false}
                popUpSize="small"
                body=
                {
                    <>
                        <div className='model-answers'>
                            {
                                reSubmitMessageShow ? (<div>
                                    <span style={{ fontWeight: "bold", fontSize: "18px" }}>Notification: Student Resubmission</span>
                                    <span style={{ fontWeight: "bold" }}> A student has resubmitted their homework. Please review and provide feedback on the updated submission.</span>
                                </div>) : null
                            }
                            {
                                modalData1 !== null && modalData1 !== undefined && modalData1.length > 0 ?
                                    modalData1.map((item, index) => {
                                       
                                        let finalanswerdata = []
                                        let answertext = item.selected_answer_text
                                        if (answertext.includes("###")) {
                                            let arr = answertext.split("###")
                                            arr.forEach((rem) => {
                                                let contentdata1 = (
                                                    <Aux>

                                                        <div dangerouslySetInnerHTML={{ __html: rem.toString().replace(/^,/, '').replace(/,\s*$/, "") }}>
                                                        </div>


                                                    </Aux >
                                                );
                                                finalanswerdata.push(contentdata1)
                                            })
                                        }
                                        else
                                        {
                                            
                                            let contentdata1 = (
                                                <Aux>

                                                    <div dangerouslySetInnerHTML={{ __html: answertext.toString().replace(/^,/, '').replace(/,\s*$/, "") }}>
                                                    </div>


                                                </Aux >
                                            );
                                            finalanswerdata.push(contentdata1)
                                        }
                                        return (
                                            item.is_answer_correct === true ? (
                                                <>
                                                    <div className='correctAnswerContainer'>
                                                        <span className='answer-correct-sign'></span>
                                                        <div className='answer-container'>
                                                            <div className='d-flex align-items-center justify-content-between pb-3'>
                                                                <div>
                                                                    <p className='yrResTxt'>Your Response</p>
                                                                    {/* <p>Correct Equation</p> */}
                                                                </div>
                                                                {
                                                                    item.isgraded ? (
                                                                        <div className='pointsContainer'>
                                                                            <span>Points: </span>
                                                                            <input name="points" class="form-control bg-yellow" type='text'
                                                                                onChange={(e) => handlePointsChangeEvent(e, index)}
                                                                                value={item.points}></input>
                                                                        </div>
                                                                    ) : null
                                                                }


                                                            </div>
                                                            <Row className='no-gutters'>
                                                                <Col sm="9"><div className='equationTxtnonfrq green'>
                                                                    {finalanswerdata}
                                                                </div></Col>

                                                                <Col sm="3" style={{ textAlign: "end", paddingLeft: "10px" }}>
                                                                    {
                                                                        item.hints_used !== undefined && item.hints_used !== "" && item.hints_used.length > 0 ?
                                                                            (<Tooltip title="Hints Used" placement='top' arrow >
                                                                                <IconButton onClick={hinticonhandler}>
                                                                                    <div className={`boardBtnsreview `}                                                >
                                                                                        <img src={HintIcon} />
                                                                                    </div>

                                                                                </IconButton>
                                                                            </Tooltip>) : null
                                                                    }
                                                                    {
                                                                        item.checkmyworkuseddata !== undefined && item.checkmyworkuseddata !== null && item.checkmyworkuseddata !== "" ?
                                                                            (<Tooltip title="Feedback Used" placement='top' arrow>
                                                                                <IconButton onClick={checkmyworkiconhandler}>
                                                                                    <div className={`boardBtnsreview`}>
                                                                                        <img alt='Feedback Used' src={checkWorkIcon} />
                                                                                    </div>
                                                                                </IconButton>
                                                                            </Tooltip>) : null
                                                                    }
                                                                    {
                                                                        item.nextstep_used !== undefined && item.nextstep_used !== null && item.nextstep_used !== "" ?
                                                                            (<Tooltip title="Hint: Next Step Used" placement='top' arrow>
                                                                                <IconButton onClick={showNextStephandler}>
                                                                                    <div className={`boardBtnsreview`}>
                                                                                        <img alt='Hint: Next Step Used' src={ShowNextstepIcon} />
                                                                                    </div>
                                                                                </IconButton>
                                                                            </Tooltip>) : null
                                                                    }
                                                                    {
                                                                        item.show_solution_used !== undefined && item.show_solution_used !== null && item.show_solution_used !== "" ?
                                                                            (<Tooltip title="Show Solution Used" placement='top' arrow>
                                                                                <IconButton onClick={showSholutionhandler}>
                                                                                    <div className={`boardBtnsreview`}>
                                                                                        <img alt='Show Solution Used' src={ShowFullSolutionIcon} />
                                                                                    </div>
                                                                                </IconButton>
                                                                            </Tooltip>) : null
                                                                    }

                                                                </Col>
                                                            </Row>
                                                            <div className='feedbackBox'>
                                                                <span >Additional feedback by professor</span>
                                                                <textarea rows={3} type='text'
                                                                    value={item.ta_feedback}
                                                                    onChange={(e) => handleFeedbackChangeEventCorrect(e, index)}
                                                                >
                                                                </textarea>
                                                            </div>
                                                            {
                                                                hintDivVisible ? (
                                                                    <>
                                                                        {
                                                                            item.hints_used !== undefined && item.hints_used !== "" && item.hints_used.length > 0 ?
                                                                                <>
                                                                                    <div className='whiteBox'><div className='popupSectionTitlemediumborder' style={{ display: "flex", justifyContent: "space-between" }}>
                                                                                        <div className='popupSectionTitlemedium'> Hints Used</div>
                                                                                        {
                                                                                            item.isgraded ? (
                                                                                                <div className='pointsContainer'>
                                                                                                    <span>Hint Points: </span>
                                                                                                    <input name="points" class="form-control bg-yellow" type='text'
                                                                                                        onChange={(e) => handleHintPointsChangeEvent(e, index)}
                                                                                                        value={item.ta_hint_points}></input>
                                                                                                </div>
                                                                                            ) : null}
                                                                                    </div>
                                                                                        <div className='tuorporfile-content-card'>
                                                                                            {
                                                                                                item.hints_used.map((hint) => {

                                                                                                    let displaystring = ""
                                                                                                    if (hint.remedial_source_title !== "None" && hint.remedial_source_title !== null && hint.remedial_source_title !== undefined && hint.remedial_source_title !== "null" && hint.remedial_source_title !== "undefined") {
                                                                                                        displaystring = hint.remedial_source_title
                                                                                                    }
                                                                                                    else if (hint.remedial_source_url !== "None" && hint.remedial_source_url !== null && hint.remedial_source_url !== undefined && hint.remedial_source_url !== "null" && hint.remedial_source_url !== "undefined") {
                                                                                                        displaystring = hint.remedial_source_url
                                                                                                    }
                                                                                                    return (
                                                                                                        <div className='profile-card-text equationTxtnonfrqsmall'>
                                                                                                            <Row>
                                                                                                                <Col>
                                                                                                                    <div className='modal-content' style={{ marginBottom: '10px', borderBottom: '1px solid lightGray' }}>
                                                                                                                        <p className='mb-2' dangerouslySetInnerHTML={{ __html: hint.remedial_text }} />

                                                                                                                        <a href={hint.remedial_source_url} target='_blank' rel="noopener noreferrer">
                                                                                                                            <p className='mb-2' dangerouslySetInnerHTML={{ __html: displaystring }} />
                                                                                                                        </a>

                                                                                                                    </div>
                                                                                                                </Col>
                                                                                                            </Row>
                                                                                                        </div>
                                                                                                    )
                                                                                                })
                                                                                            }


                                                                                        </div>
                                                                                    </div>
                                                                                </>
                                                                                : <div className=''></div>
                                                                        }
                                                                    </>
                                                                ) : null
                                                            }

                                                            {
                                                                checkMyWorkDivVisible ? (
                                                                    <>
                                                                        {
                                                                            item.checkmyworkuseddata !== undefined && item.checkmyworkuseddata !== "" && item.checkmyworkuseddata !== "[]" && item.checkmyworkuseddata.length > 0 ?

                                                                                (
                                                                                    <>
                                                                                        <div style={{ maxWidth: "500px" }}>
                                                                                            <div className='popupSectionTitlemedium' style={{ borderBottom: "1px solid #ccc", paddingBottom: "10px" }}> Feedback Used</div>
                                                                                            <CheckMyworkResultCNonFRQ checkmyworkuseddata={item.checkmyworkuseddata}></CheckMyworkResultCNonFRQ>
                                                                                        </div>
                                                                                    </>

                                                                                ) : null

                                                                        }
                                                                    </>
                                                                ) : null
                                                            }
                                                            {/* {
                                                                showNextStepDivVisible ? (
                                                                    <>
                                                                        {
                                                                            item.nextstep_used !== undefined && item.nextstep_used !== null && item.nextstep_used !== "" ?
                                                                                (
                                                                                    <>
                                                                                        <div className='whiteBox' style={{marginTop:"10px"}}>
                                                                                            <Row>
                                                                                                <Col>
                                                                                                    <div className='popupSectionTitlemediumborder' style={{ marginTop: "10px" }}>
                                                                                                        <p className='popupSectionTitlemedium'>Next Step</p>

                                                                                                    </div>

                                                                                                    <MathJax.Context
                                                                                                        input='tex'
                                                                                                        onError={(MathJax, error) => {
                                                                                                            console.warn(error);
                                                                                                            console.log("Encountered a MathJax error, re-attempting a typeset!");
                                                                                                            MathJax.Hub.Queue(
                                                                                                                MathJax.Hub.Typeset()
                                                                                                            );
                                                                                                        }}

                                                                                                        script="https://cdnjs.cloudflare.com/ajax/libs/mathjax/2.7.1/MathJax.js?config=TeX-MML-AM_CHTML">
                                                                                                        <MathJax.Node >{item.nextstep_used}</MathJax.Node>
                                                                                                    </MathJax.Context>



                                                                                                </Col>
                                                                                            </Row>
                                                                                        </div>
                                                                                    </>
                                                                                ) : null

                                                                        }
                                                                    </>
                                                                ) : null
                                                            } */}

                                                        </div>
                                                    </div>
                                                </>
                                            ) :
                                                <>
                                                    <div className='incorrectAnswerContainer ' >
                                                        <span className='answer-incorrect-sign'></span>
                                                        <div className='answer-container'>
                                                            <div className='d-flex align-items-center justify-content-between pb-3'>
                                                                <div >
                                                                    <p className='yrResTxt'>Your Response</p>
                                                                    {/* <p>In-Correct Equation</p> */}
                                                                </div>
                                                                {
                                                                    item.isgraded ? (
                                                                        <div className='pointsContainer'>
                                                                            <span>Points: </span>
                                                                            <input name="points" class="form-control bg-yellow" type='text'
                                                                                onChange={(e) => handlePointsChangeEvent(e, index)}
                                                                                value={item.points}></input>
                                                                        </div>) : null}

                                                            </div>
                                                            <div className='equationTxtnonfrq red'>{finalanswerdata}</div>

                                                            <div className='feedbackBox'>
                                                                <span>Additional feedback by professor</span>
                                                                <textarea rows={3} type='text'
                                                                    value={item.ta_feedback}
                                                                    onChange={(e) => handleFeedbackChangeEventIncorrect(e, index)}
                                                                >
                                                                </textarea>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </>
                                        )
                                    })

                                    : "No homework details found for this challenge !"
                            }
                        </div>
                    </>}
                handleClose={handleclosemodal}
                title={modalTitle}
                showButton={false}
                customButton={
                    modalData1 !== null && modalData1 !== undefined ?
                        <div className='modal-button text-center'>
                            <Button className="yellow-btn"
                                clicked={() => handleUpdate()}
                            > Update </Button>
                            <span style={{ marginLeft: "10px" }}>
                                <Button className="grey-btn"
                                    clicked={handleclosemodal}
                                > Cancel </Button></span>
                        </div>
                        : <Button className="grey-btn" clicked={handleclosemodal}> Cancel </Button>
                }
            >
            </Modal>
            <div class="container-fluid">
                <div className='container bg-white-with-shadow my-3 my-md-5'>
                    <div className=''>
                        <h5 className='breadcrumbH5'>
                            {<a href="/homeworkproblems">Homework</a>}
                            <> <ArrowForwardIcon /> Challenge: {challengeTitle}</>
                        </h5>
                    </div>
                    <div className=''>

                        <Box sx={{ width: '100%', mt: 3 }}>
                            <Paper sx={{ width: '100%', mb: 2 }}>

                                <TableContainer>
                                    <Table
                                        sx={{ minWidth: 750 }}
                                        aria-labelledby="tableTitle"
                                        size={'medium'}
                                    >
                                        <EnhancedTableHead
                                            numSelected={selected.length}
                                            order={order}
                                            orderBy={orderBy}
                                            onRequestSort={handleRequestSort}
                                        // rowCount={rows.length}
                                        />
                                        <TableBody>
                                            {
                                                visibleRows !== undefined && visibleRows !== null && visibleRows.length > 0 ?

                                                    (visibleRows.map((row, index) => {
                                                        const isItemSelected = isSelected(row.id);
                                                        const labelId = `enhanced-table-checkbox-${index}`;



                                                        return (
                                                            <TableRow
                                                                hover
                                                                role="checkbox"
                                                                aria-checked={isItemSelected}
                                                                tabIndex={-1}
                                                                key={row.id}
                                                                sx={{ cursor: 'pointer' }}
                                                            >
                                                                <TableCell
                                                                    component="th"
                                                                    id={labelId}
                                                                    scope="row"
                                                                    padding="none"
                                                                >
                                                                    <div style={{ display: "flex", justifyContent: "flex-start", alignItems: "center" }}>
                                                                        <Avatar
                                                                            className={classes.avatar}
                                                                            src={imgURL + row.profile_pic}>
                                                                        </Avatar>
                                                                        <div className='mt-2'>
                                                                            <button className='buttonStudent'
                                                                                onClick={() => openStudentLinkPopUp(row.boardId, row.student_id, row.student_name, row.hintdata, row.isgraded, index, row.challenge_type)}
                                                                            >{row.student_name}</button>
                                                                        </div>
                                                                    </div>
                                                                </TableCell>
                                                                <TableCell align="left">
                                                                    {row.isgraded ? row.totalgrade + "/" + 
                                                                    row.totalnonfrqpoints !== null && row.totalnonfrqpoints !== undefined ? formatNumber(row.totalnonfrqpoints) : 0.00
                                                                    
                                                                    : "Non Graded"
                                                                    }</TableCell>
                                                                <TableCell align="left">{row.common_feedback}</TableCell>


                                                            </TableRow>
                                                        );
                                                    })) : (<div style={{ padding: "20px" }}>No Student Found!</div>)

                                            }

                                        </TableBody>
                                    </Table>
                                </TableContainer>

                                <Stack spacing={2}>



                                    <Pagination
                                        sx={{
                                            "& .MuiTablePagination-selectLabel": {
                                                marginBottom: "0px !important"
                                            },
                                            "& .MuiTablePagination-displayedRows": {
                                                marginBottom: "0px !important"
                                            }
                                        }}
                                        variant="outlined"
                                        shape="rounded"
                                        color="primary"
                                        count={totalPages}
                                        page={page}
                                        onChange={handleChangePage}
                                    />
                                </Stack>

                            </Paper>

                        </Box>
                        {/* <TableContainer component={Paper}>
                            <Table aria-label="collapsible table">
                                <TableHead>
                                    <TableRow>
                                        <StyledTableCell />
                                        <StyledTableCell>Student Name</StyledTableCell>
                                        <StyledTableCell>Total Grades</StyledTableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {
                                        studentData !== undefined && studentData !== null && studentData.length > 0 ?
                                            (
                                                studentData.map((row, index) => (
                                                    <>
                                                        <StyledTableRow sx={{ '& > *': { borderBottom: 'unset' } }}>
                                                            <StyledTableCell>
                                                            </StyledTableCell>
                                                            <StyledTableCell>
                                                                <button className='buttonStudent'
                                                                    onClick={() => openStudentLinkPopUp(row.boardId, row.student_id, row.student_name, row.hintdata, row.isgraded, index, row.challenge_type)}
                                                                >{row.student_name}</button>
                                                            </StyledTableCell>
                                                            <StyledTableCell component="th" scope="row">
                                                                {row.isgraded ? row.totalgrade : "N/A"}/1.00
                                                            </StyledTableCell>
                                                        </StyledTableRow >

                                                    </>
                                                ))
                                            ) :
                                            <div style={{ padding: "20px" }}>No Student Found!</div>
                                    }
                                </TableBody>
                            </Table>
                        </TableContainer> */}
                    </div>
                </div>
            </div>
            {
                loading && (
                    <CircularProgress
                        size={24}
                        sx={{
                            color: "#164b99",
                            position: 'absolute',
                            top: '50%',
                            left: '50%',
                            marginTop: '-12px',
                            marginLeft: '-12px',
                        }}
                    />
                )
            }
        </>
    )
}

export default StudentListNonFRQ 