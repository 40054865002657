import React, { Component } from 'react';
import './VideoConferenceSideBar.scss'
import problemImage from '../../assets/images/remember-this.png';
import Tooltip from '@material-ui/core/Tooltip';
import VideoConference from '../VideoConference/VideoConference';
import { initToolbar } from './js/chatToolbar';
import { getCurrentUserName, getCurrentUserRole, getCurrentUserId } from '../../common/Functions';
import { getrecommendedlinks_problems } from '../../common/API';
import queryString from 'query-string';
import { withRouter } from 'react-router-dom';
import Chatbot from './Chatbot'
import Draggable from 'react-draggable';
import MinimizeIcon from '@mui/icons-material/Minimize';
import CropSquareIcon from '@mui/icons-material/CropSquare';
import CloseIcon from '@mui/icons-material/Close';

class VideoConferenceSideBar extends Component {
    state = {
        tutorName: "",
        tutorInterest: "",
        tutorDegree: "",
        recommendedlinks: [],
        minimizeWindow: false
    }

    componentDidMount() {
        this.setState({
            tutorName: this.props.isTeacher ? "Please wait while we connect you to a waiting student." : "Waiting for the tutor...",
        })

        if (this.props.call_type !== null && this.props.call_type !== undefined) {
            const reqData = {
                params: {
                    Challenge_ID: this.props.challengeId,
                    call_type: this.props.call_type,
                    content_id: this.props.topicId

                }
            }
            getrecommendedlinks_problems(reqData).then(res => {
                if (res.data.resdata !== undefined) {
                    this.setState({ recommendedlinks: res.data.resdata })
                }
            }).catch(err => {
                console.error(err.message)

            })


        }



    }

    getRoomName = () => {
        let roomName = undefined
        if (this.props.isTeacher) {
            let params = queryString.parse(this.props.location.search)
            roomName = params.roomName
        }

        return roomName
    }
    getRoomId = () => {
        let roomId = undefined
        if (this.props.isTeacher) {
            let params = queryString.parse(this.props.location.search)
            roomId = params.roomId
        }

        return roomId
    }

    setInitialValues = () => {
        this.setState({
            tutorName: this.props.isTeacher ? "Student ends the conference." : "Waiting for the tutor...",
            tutorInterest: "",
            tutorDegree: ""
        })
    }

    setPartnerName = (name) => {
        this.setState({
            tutorName: name,
            tutorInterest: "Loves Physics & Robots",
            tutorDegree: "MS Physics, Harvard"
        })
    }

    toggleWindowMinimization = () => {
        this.setState({ minimizeWindow: !this.state.minimizeWindow })
    }

    render() {
        let classnamev = 'modal--visible'
        let classnameinv = 'modal--invisible'
        let videoclass = ""
        if (this.props.from === "cur") {
            classnamev = 'modal--visiblecur'
            classnameinv = 'modal--invisiblecur'
            videoclass = this.props.videoclass
        }

        return this.props.showModal ? (
            <Draggable>
                <div className={`${videoclass} vc-container ml-auto ${this.props.showModal ? classnamev : classnameinv}`}>

                    {!this.state.minimizeWindow ?
                        <div className="curriculum-right-fix pb-0 position-relative">
                            <div style={{ float: 'right' }}>
                                <Tooltip title='Minimize'>
                                    <button type="button" className="vidWindowBtn" onClick={this.toggleWindowMinimization}> <MinimizeIcon /> </button>
                                </Tooltip>
                                <Tooltip title='Close'>
                                    <button type="button" className="vidWindowBtn" onClick={this.props.toggleVideoConfModal}> <CloseIcon /> </button>
                                </Tooltip>
                            </div>
                            <div className="tutor-profile">
                                <h2 className="mb-2" style={{ cursor: 'move' }}>
                                    {this.state.tutorName}
                                </h2>
                                <div className="loves">{this.state.tutorInterest}</div>
                                <div className="degree">{this.state.tutorDegree}</div>
                                <div className="clearfix ">
                                    <VideoConference
                                        user={getCurrentUserName()}
                                        userRole={getCurrentUserRole()}
                                        userId={getCurrentUserId()}
                                        roomName={this.getRoomName()}
                                        boardId={this.props.boardId}
                                        challengeMapId={this.props.challengeMapId}
                                        challengeId={this.props.challengeId}
                                        questionId={this.props.questionId}
                                        topicId={this.props.topicId}
                                        partnerDisconnected={this.setInitialValues}
                                        setPartnerName={this.setPartnerName}
                                        tutorid={this.props.tutorid}
                                        call_type={this.props.call_type}
                                        problem_source={this.props.problem_source} />
                                </div>
                            </div>
                            <div className="panel-box">
                                <Chatbot
                                    user={getCurrentUserName()}
                                    userRole={getCurrentUserRole()}
                                    userId={getCurrentUserId()}
                                    roomId={this.getRoomId()}
                                    boardId={this.props.boardId}
                                    challengeId={this.props.challengeId}
                                    isTeacher={this.props.isTeacher}
                                ></Chatbot>
                            </div>
                            <div className="panel-box mb-20">
                                <label>Previously Recommended Links for this Problem</label>
                                {
                                    this.state.recommendedlinks !== undefined && this.state.recommendedlinks.length > 0 ? (
                                        this.state.recommendedlinks.map((item) => (
                                            <div>
                                                <a href={item.Resource_url}
                                                    style={{ padding: "10px", marginBottom: "10px" }}
                                                    target='_blank' rel="noopener noreferrer">
                                                    {item.Resource_url}</a></div>
                                        ))
                                    ) : null
                                }
                                <img className="problem-img responsiv-img" src={problemImage} alt="" />
                            </div>
                        </div>
                        :
                        <div style={{ height: 'auto' }} className="curriculum-right-fix">
                            <div style={{ float: 'right' }}>
                                <Tooltip title='Maximize'>
                                    <button type="button" className="vidWindowBtn" onClick={this.toggleWindowMinimization}> <CropSquareIcon /> </button>
                                </Tooltip>
                                <Tooltip title='Close'>
                                    <button type="button" className="vidWindowBtn" onClick={this.props.toggleVideoConfModal}><CloseIcon /></button>
                                </Tooltip>
                            </div>
                            <div className="tutor-profile">
                                <h2 className="mb-2" style={{ cursor: 'move' }}>
                                    {this.state.tutorName}
                                </h2>
                                <div className="loves">{this.state.tutorInterest}</div>
                                <div className="degree">{this.state.tutorDegree}</div>
                                <div className="clearfix ">
                                    <VideoConference
                                        user={getCurrentUserName()}
                                        userRole={getCurrentUserRole()}
                                        userId={getCurrentUserId()}
                                        roomName={this.getRoomName()}
                                        boardId={this.props.boardId}
                                        challengeMapId={this.props.challengeMapId}
                                        challengeId={this.props.challengeId}
                                        questionId={this.props.questionId}
                                        topicId={this.props.topicId}
                                        partnerDisconnected={this.setInitialValues}
                                        setPartnerName={this.setPartnerName}
                                        tutorid={this.props.tutorid}
                                        call_type={this.props.call_type}
                                        winMinimize={this.state.minimizeWindow}
                                        problem_source={this.props.problem_source}
                                    />
                                </div>
                            </div>
                        </div>
                    }
                </div>
            </Draggable>
        ) : null
    }
}

export default withRouter(VideoConferenceSideBar);