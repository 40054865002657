import React from 'react';
import './Accordion.scss';

export const Accordion = (props) => {
    return(
        <div className='accordion' style={props.style}>
            {props.children}
        </div>
    );
}

export const AccordionItem = (props) => {
    return (
        <section className='accordion-item'>
            <h1>{props.title}</h1>
            <div className='accordion-item-content'>
                {props.children}
            </div>
        </section>
    );
}