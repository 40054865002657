import React, { useEffect, useState } from "react";
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { isNil } from "lodash";
import { CSVLink } from "react-csv";
import { getInClassQASummary } from "../../common/API";
import { getCurrentUserId, formatDateUs,getMenuSelectedItem } from "../../common/Functions";
import DownloadIcon from '../../assets/images/download-icon.svg';
import './InClassQAReport.scss'


export default function InClassQAReport() {
   
    const [isLoading, setIsLoading] = useState(true);
    const [data, setData] = useState([]);
    const [dataLoaded, setDataLoaded] = useState(false);
    const [csvData, setCSVData] = useState([])

    useEffect(() => {
        if (!dataLoaded) {
            setDataLoaded(true);            
            const reqData = {
                params: {
                    Prof_id: getCurrentUserId(),
                    "course_id": getMenuSelectedItem()
                }
            }
            getInClassQASummary(reqData).then((res) => {
                if (!isNil(res.data) && !isNil(res.data.data)) {
                    setData(res.data.data)
                }
                setIsLoading(false);

            }).catch(err => {
                setIsLoading(false)
            })
        }
    }, [dataLoaded])

    useEffect(() => {
        prepareCSVData();
    }, [data])
    const prepareCSVData = () => {
        let tempArr = [];
        tempArr.push(['Student Name', 'Session Name', '# Question'])
        data.forEach(element => {
            element.session_list.forEach(item => {
                let arr = [element.name, item.name, item.usercount]
                tempArr.push(arr);
            })
        })

        setCSVData(tempArr)
    }
    return (
        <div className="qaReportContainer">
            <div className="exportCSV">
                <CSVLink data={csvData} className='exportCSVbtn exportRepresentative'
                    filename={"In_Class_QA_Summary_ " + formatDateUs(Date.now()) + ".csv"}> Export To CSV
                    <img src={DownloadIcon}></img></CSVLink>
            </div>
            <TableContainer component={Paper}>
                <Table stickyHeader sx={{ minWidth: 650 }} aria-label="simple table">

                    <TableHead>
                        <TableRow>
                            <TableCell >Student Name</TableCell>
                            <TableCell align="left">Session Name: # of Questions</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {isLoading ?
                            <TableRow>
                                <TableCell colSpan={5} style={{ padding: "20px", textAlign: "center", fontSize: "17px" }}>{'Fetching Data...'}</TableCell>
                            </TableRow> :
                            data.map((row) => (
                                <TableRow
                                    key={row.name}
                                    sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                >
                                    <TableCell component="th" scope="row">
                                        {row.name}
                                    </TableCell>
                                    <TableCell align="left">{row.session_list.map(element => (
                                        <div>
                                            <span>{element.name}:</span>
                                            <span>{element.usercount}</span>
                                        </div>
                                    ))}</TableCell>

                                </TableRow>
                            ))
                        }
                    </TableBody>
                </Table>
            </TableContainer>
        </div>

    )
}