import React, { useState, useEffect, useRef } from 'react';
import Aux from '../../hoc/Wrapper';
import { Container, Row, Col, Button } from 'react-bootstrap';
import { withNamespaces } from 'react-i18next';
import Form from 'react-bootstrap/Form';
import { getCurrentUserId } from '../../common/Functions';
import { changepassword, checkuseroldpassword } from '../../common/API'
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

function ChangePassword() {


    const [oldpassword, setoldPassword] = useState('');
    const [oldpasswordmessage, setoldPasswordmessage] = useState('');
    const [password, setPassword] = useState('');
    const [cPassword, setCPassword] = useState('');
    const [showErrorMessage, setShowErrorMessage] = useState(false);
    const [cPasswordClass, setCPasswordClass] = useState('form-control');
    const [isCPasswordDirty, setIsCPasswordDirty] = useState(false);
    const [validated, setvalidated] = useState(false)
    const [message, setmessage] = useState('')
    const [OldPasswordClass, setOldPasswordClass] = useState('form-control');
    const [openForgotPassworddiv, setopenForgotPassworddiv] = useState(false)
    const formRef = useRef(null);

    useEffect(() => {
        if (isCPasswordDirty) {
            if (cPassword !== '' && cPassword !== null && cPassword !== "") {
                if (password === cPassword) {
                    setmessage('')
                    setShowErrorMessage(false);
                    setCPasswordClass('form-control is-valid')
                } else {
                    setShowErrorMessage(true)
                    setmessage("Password and Confirm Password does not match ")
                    setCPasswordClass('form-control is-invalid')
                }
            }
            else {
                setShowErrorMessage(false)
                setmessage('')
            }

        }
    }, [cPassword])

    useEffect(() => {
        if (oldpassword !== "" && oldpassword != '' && oldpassword !== null && oldpassword !== undefined) {
            const reqData = { params: { 'user_id': getCurrentUserId(), 'oldpassword': oldpassword } }
            checkuseroldpassword(reqData).then(res => {

                if (res.data.data !== undefined && res.data.data !== null) {
                    if (parseInt(res.data.data) === 0) {
                        setShowErrorMessage(true)
                        setOldPasswordClass('form-control is-invalid')
                        setoldPasswordmessage("Please enter valid password")
                    }
                    else {
                        setoldPasswordmessage('')
                        setShowErrorMessage(false);
                        setOldPasswordClass('form-control is-valid')
                    }
                }
            })
        }

    }, [oldpassword])


    useEffect(() => {
        if (password !== '' && password !== null && password !== "") {
            if (isCPasswordDirty) {
                if (password === cPassword) {
                    setmessage('')
                    setShowErrorMessage(false);
                    setCPasswordClass('form-control is-valid')
                } else {
                    setShowErrorMessage(true)
                    setmessage("Password and Confirm Password does not match ")
                    setCPasswordClass('form-control is-invalid')
                }
            }
        }
    }, [password])

    const handleCPassword = (e) => {
        if (e.target.value === "" && e.target.value === undefined && e.target.value === null) {
            setmessage('');
            setIsCPasswordDirty(false);
        }
        else {
            setCPassword(e.target.value);
            setIsCPasswordDirty(true);
        }
    }
    const handlePassword = (e) => {
        setPassword(e.target.value);
        setIsCPasswordDirty(true);
    }
    const handleOldPassword = (e) => {
        setoldPassword(e.target.value);
    }
    const handleSubmit = async (event) => {
        event.preventDefault();
        const form = event.currentTarget;
        if (form.checkValidity() === false) {
            event.stopPropagation();
            setvalidated(true);
        }
        else {
            if (oldpasswordmessage === '' && message === '') {
                let form_data = new FormData();
                form_data.append('user_id', getCurrentUserId());
                form_data.append('password', password);
                await changepassword(form_data).then(res => {
                    if (res.status === 200) {
                        setvalidated(false)
                        setoldPassword('')
                        setPassword('')
                        setCPassword('')
                        toast.success("Password Updated!", {
                            position: toast.POSITION.BOTTOM_RIGHT
                        });
                    }
                }).catch(err => {
                    toast.error("Error!", {
                        position: toast.POSITION.BOTTOM_RIGHT
                    });
                    console.error(err.message)
                })
            }
        }
    }
    const handleforgotpassword = (e) => {
        e.preventDefault()
        setopenForgotPassworddiv(true)

    }
    const handlecloseforgotpassword = () => {
        setopenForgotPassworddiv(false)

    }
    return (
        <Aux>
            <Container>
                <Row className='tutorprofile'>
                    <div class="container-fluid">
                        <div className='container bg-white-with-shadow my-3 my-md-5'>
                            <Row>
                                <Col>
                                    <div style={{ paddingTop: "20px" }}>
                                        <Row style={{ alignItems: "center" }}>
                                            <Col sm="12"><h2> Change Password </h2></Col>
                                        </Row>
                                    </div>
                                    <Row style={{ marginTop: "30px" }}>
                                        <Col sm="12">
                                            <Form noValidate
                                                ref={formRef}
                                                id='myForm'
                                                validated={validated}
                                                onSubmit={handleSubmit}
                                            >
                                                <Form.Group as={Row} className="mb-3" controlId="oldPassword" style={{ alignItems: "center" }}>
                                                    <Form.Label column="sm" lg={2}>
                                                        Old Password
                                                    </Form.Label>
                                                    <Col sm="10">
                                                        <Form.Control placeholder="Old Password" size="sm" type="password"
                                                            style={{ width: "410px" }}
                                                            name="oldpassword"
                                                            value={oldpassword}
                                                            required
                                                            className={OldPasswordClass}
                                                            onChange={handleOldPassword}
                                                        />
                                                        <Form.Control.Feedback type="invalid">
                                                            {oldpasswordmessage !== '' && oldpasswordmessage !== "" && oldpasswordmessage !== "" ? <div> {oldpasswordmessage} </div> : 'Enter old Password'}
                                                        </Form.Control.Feedback>
                                                    </Col>
                                                </Form.Group>
                                                <Form.Group as={Row} className="mb-3" controlId="password">
                                                    <Form.Label column="sm" lg={2}>
                                                        New Password
                                                    </Form.Label>
                                                    <Col sm="10">
                                                        <Form.Control placeholder="New Password" size="sm" type="password"
                                                            style={{ width: "410px" }}
                                                            name="password"
                                                            value={password}
                                                            required
                                                            onChange={handlePassword}
                                                        />
                                                        <Form.Control.Feedback type="invalid">
                                                            Enter Password
                                                        </Form.Control.Feedback>
                                                    </Col>
                                                </Form.Group>
                                                <Form.Group as={Row} className="mb-3" controlId="cpassword">
                                                    <Form.Label column="sm" lg={2}>
                                                        Confirm Password
                                                    </Form.Label>
                                                    <Col sm="10">
                                                        <Form.Control placeholder="Confirm Password" size="sm" type="password"
                                                            style={{ width: "410px" }}
                                                            required
                                                            name="confirmPassword"
                                                            className={cPasswordClass}
                                                            value={cPassword}
                                                            onChange={handleCPassword}
                                                        />
                                                        <Form.Control.Feedback type="invalid">
                                                            {showErrorMessage && message !== '' ? <div> {message} </div> : 'Enter Confirm Password'}
                                                        </Form.Control.Feedback>
                                                    </Col>

                                                </Form.Group>

                                                <Form.Group as={Row} className="mb-3 mt-3" controlId="formthumbnailimage">
                                                    <Form.Label column="sm" lg={2} ></Form.Label>
                                                    <Col sm="10">
                                                        <Button type="submit" variant="primary" style={{ marginBottom: "20px", marginTop: "30px" }}>Save</Button>

                                                        <button onClick={(e) => handleforgotpassword(e)} 
                                                        className='btn btn-link' style={{ marginLeft: "30px", paddingTop: "10px" }}>Forgot Password</button>
                                                        {
                                                            openForgotPassworddiv ? (
                                                                <div>
                                                                    <span>Email us at <a href="mailto:support@aiplato.ai?subject=Password Reset Request">support@aiplato.ai.</a> We'll help you reset your password!</span>
                                                                </div>
                                                            ) : null

                                                        }
                                                    </Col>
                                                </Form.Group>
                                            </Form>
                                        </Col>
                                    </Row>

                                </Col>
                            </Row>
                        </div>
                    </div>
                </Row>
            </Container>
            <ToastContainer />
        </Aux>
    );
}

export default withNamespaces()(ChangePassword);
