import React, { useEffect } from 'react';

const MathJaxRenderer = ({ math }) => {
  useEffect(() => {
    if (window.MathJax) {
      try {
        window.MathJax.typeset();
      } catch (e) {
        console.log(e)
      }

    }
    // else {
    //   const script = document.createElement('script');
    //   script.type = 'text/javascript';
    //   script.src = 'https://cdnjs.cloudflare.com/ajax/libs/mathjax/3.2.0/es5/tex-mml-chtml.js';
    //   script.async = true;
    //   script.onload = () => {
    //     window.MathJax.typeset();
    //   };
    //   document.head.appendChild(script);
    // }
  }, [math]);

  return <div dangerouslySetInnerHTML={{ __html: math }} />;
};

export default MathJaxRenderer;
