import React from "react";
import "./UserProgress.scss";
import Aux from "../../../hoc/Wrapper";
import Progress from "../../Common/Progress/Progress";

import progressImg from "../../../assets/images/icon_progress-update-white.svg";

import { withNamespaces } from "react-i18next";
import i18next from "i18next";

const userProgress = (props) => {
  return (
    <Aux>
      <div className="user-progress">
        <a className="user-progress-anchor" href={props.link} title="Progress Update">
          <label className="user-progress-label">
            {i18next.t("header", { returnObjects: "true" }).progress}
          </label>
          <img src={progressImg} alt="Progress" />
        </a>
        <div className="progress-box">
          <Progress />
          {/* <div className={classes.section_icon_text}>

                        <div className={classes.peersName}>
                            <div>Yours</div>
                            <div>Peers</div>
                        </div>
                        <div className={classes.progress_update}>
                            <label data-toggle="modal" data-target="#progressUpdate">Completion</label>
                            <img src={progressUpdate1} alt="" />
                        </div>
                        <div className={classes.peersName}>
                            <div>Yours</div>
                            <div>Peers</div>
                        </div>
                        <div className={classes.progress_update}>
                            <label data-toggle="modal" data-target="#progressUpdate">Mastery</label>
                            <img src={progressUpdate2} alt="" />
                        </div>
                        <div className={classes.clearfix}>
                            <div className={classes.last_progress}>
                                <img src={lastProgress} alt="" />
                                <label class="progres2 text-muted">Last Week</label>
                            </div>
                            <div className={classes.total_progress}>
                                <img src={totalProgress} alt="" />
                                <label class="progres1 text-muted">Total</label>
                            </div>

                        </div>
                        <div className={classes.bottomProgress}>
                            <div><label>View By Topics</label></div>
                            <div><label>View By Weeks</label></div>
                        </div>
                    </div> */}
        </div>
      </div>
    </Aux>
  );
};

export default withNamespaces()(userProgress);
