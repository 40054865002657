import React, { Component } from 'react'
import './SessionHistory.scss'
import { Link } from 'react-router-dom';
import { Container, Row } from 'react-bootstrap';
import synopsisImage from "../../../assets/images/synopsis_image.svg";
import eq1 from "../../../assets/images/eq1.svg";
import eq2 from "../../../assets/images/eq2.svg";
import eq3 from "../../../assets/images/eq3.svg";
import eq4 from "../../../assets/images/eq4.svg";
import SessionSynopsis from '../../../components/Answerboard/SessionSynopsis/SessionSynopsis';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import EditIcon from '@material-ui/icons/Edit';
import DeleteIcon from '@material-ui/icons/Delete';
import { getSynopsis, sessionsynopsisdelete } from '../../../common/API';
import { Call_Type, User_Role, getCurrentUserId, getCurrentUserRole } from '../../../common/Functions';
import SessionSynopsisWithData from '../../../components/Answerboard/SessionSynopsis/SessionSynopsisWithData';
import { MathComponent } from 'mathjax-react';
import CircularProgress from '@mui/material/CircularProgress';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import { toast } from 'react-toastify';


class SessionHistory extends Component {
    state = {

        showSynopsis: false,
        showDummySynopsis: false,
        synopsis: [],
        isLoading: false,
        synopsisData: {},
        updatedcontent: [],
        newitemloading: false,
        synopsisId: -1,
        deleteConfirmationDialog: false,
        selectedSynopsisId: null
    }

    closeSessionSynopsis = () => {
        this.setState({ showSynopsis: false, showDummySynopsis: false, synopsisId: -1 });
        setTimeout(this.loadSynopsisData, 500);
    }

    componentDidMount() {
        this.setState({ isLoading: true });
        this.loadSynopsisData()
    }

    loadSynopsisData = () => {

        getSynopsis({})
            .then(res => {
                //debugger;
                this.setState({ synopsis: res.data.synopsis, isLoading: false });
            })
            .catch((err) => {
                console.error(err.message);
                this.setState({ isLoading: false });
            });
    }
    handleedit = (e, item) => {

        this.setState({ showSynopsis: false, newitemloading: false }, () => {
            setTimeout(() => {
                this.setState({
                    newitemloading: false,
                    showSynopsis: false,
                    showDummySynopsis: false,
                    synopsisData: [],
                    synopsisId: item.session_synopsis_id,
                    studentError: item.studentError,
                    updatedcontent: [],
                    tutor_id: item.tutor_id
                })
            }, 500)
        })

    }
    deleteconfirmationhandler = (e, selectedSynopsisId) => {
        this.setState({ deleteConfirmationDialog: true, selectedSynopsisId: selectedSynopsisId })
    }

    closedeleteconfirmodel = () => {
        this.setState({ deleteConfirmationDialog: false })
    }
    deletesynopsishandler = () => {
        let reqparams = {
            "session_synopsis_id": this.state.selectedSynopsisId
        }
        sessionsynopsisdelete(reqparams).then(res => {

            getSynopsis({})
                .then(res => {
                    //debugger;
                    this.setState({ synopsis: res.data.synopsis, isLoading: false, deleteConfirmationDialog: false,showSynopsis: false });
                    toast('Selected Synopsis deleted successfully!', {
                        type: toast.TYPE.INFO,
                        position: toast.POSITION.BOTTOM_RIGHT,
                        autoClose: true
                    })
                })
                .catch((err) => {
                    console.error(err.message);
                    this.setState({ isLoading: false });
                });

        })

    }
    render() {
        return (
            <>
               
                <Dialog
                    hideBackdrop={false}
                    open={this.state.deleteConfirmationDialog}
                    onClose={this.closedeleteconfirmodel}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                >

                    <DialogContent>
                        <DialogContentText id="alert-dialog-description">
                            <div style={{ marginTop: "10px", color: "#164b99" }}>
                                Are you sure you want to delete this synopsis?
                            </div>
                        </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                        <button className="dragLabelcancel leftmargin" onClick={this.deletesynopsishandler}>
                            Yes
                        </button>

                        <button className="dragLabelcancel leftmargin" onClick={this.closedeleteconfirmodel}>
                            No
                        </button>


                    </DialogActions>
                </Dialog>

                {
                    this.state.isLoading && (
                        <CircularProgress
                            size={24}
                            sx={{
                                color: "#164b99",
                                position: 'absolute',
                                top: '50%',
                                left: '50%',
                                marginTop: '-12px',
                                marginLeft: '-12px',
                            }}
                        />
                    )
                }
                <div className="session-history-container">


                    <Row className='mx-0'>
                        <div className='left-side'>
                            <SessionSynopsisWithData
                                show={this.state.showSynopsis}
                                onClose={this.closeSessionSynopsis}
                                isLoading={false}
                                transcripts={[]}
                                fromHistory
                                updatedcontent={this.state.updatedcontent}
                                isHistoryView={true}
                                synopsisId={this.state.synopsisId}
                                synopsisData={this.state.synopsisData}
                                newitemloading={this.state.newitemloading}
                                iseditable={this.state.tutor_id === getCurrentUserId() ? true : false}
                            />

                            <SessionSynopsis
                                show={this.state.showDummySynopsis}
                                onClose={this.closeSessionSynopsis}
                            />

                            <p className='instructions'>Once you select any session, synopsis will be shown here.</p>
                        </div>
                        <div className='right-side'>
                            <h3 className='text-center'>Session History</h3>
                            <div className="history-list">
                                {this.state.isLoading ?
                                    <p className='text-center'>Please wait while we load the history.</p>
                                    :
                                    this.state.synopsis.length > 0 ? this.state.synopsis.map(item => (
                                        <div className="history"
                                            onClick={() => {
                                                this.setState({ showSynopsis: false, newitemloading: true }, () => {
                                                    setTimeout(() => {
                                                        this.setState({
                                                            newitemloading: false,
                                                            showSynopsis: true,
                                                            showDummySynopsis: false,
                                                            synopsisData: item,
                                                            synopsisId: item.session_synopsis_id,
                                                            studentError: item.studentError,
                                                            updatedcontent: [],
                                                            tutor_id: item.tutor_id

                                                        })
                                                    }, 500)
                                                })

                                            }}
                                        >
                                            {
                                                item.tutor_id === getCurrentUserId() ? (
                                                    <div style={{ display: "flex", justifyContent: "space-between" }}>
                                                        <div className='svgedit'> <EditIcon /></div>
                                                        <div className='svgdelete'><DeleteIcon onClick={(e) => this.deleteconfirmationhandler(e, item.session_synopsis_id)} /></div>
                                                    </div>
                                                ) : null
                                            }

                                            <p><b>Time:</b> {item.time}</p>
                                            <p><b>Student:</b> {item.student}</p>
                                            {
                                                item.call_type === Call_Type.Challenge ? (
                                                    <>
                                                        <p><b>Challenge:</b>
                                                            {getCurrentUserRole() === User_Role.Professor ?
                                                                <Link
                                                                    to={{
                                                                        pathname: `/answerboard/` + item.challengeId,
                                                                        state: { prevPath: ['/'], prevNode: ['Dashboard'], HomeworkId: [`${item.challengeId}`] }
                                                                    }} > {item.challenge}
                                                                </Link>
                                                                :
                                                                <Link
                                                                    to={{
                                                                        pathname: `/answerboard/` + item.challengeId,
                                                                        state: { prevPath: ['/session-history'], prevNode: ['Session History'], HomeworkId: [`${item.challengeId}`] }
                                                                    }} > {item.challenge}
                                                                </Link>
                                                            }
                                                            {/* <a href= target='_blank'>{item.challenge}</a> */}
                                                        </p>
                                                        <p className='position-relative'><span className='pr-1'><b>Student Error:</b></span> <MathComponent tex={item.studentError} /> </p>

                                                    </>
                                                ) :
                                                    (
                                                        <>
                                                            <p><b>Content:</b> <a href={"/curriculum-content/" + item.learningcontent_id} target='_blank'>{item.learningcontent}</a>
                                                            </p>
                                                            <p className="sessionquestion"><b>Question:</b> {item.customquestion}
                                                            </p>
                                                        </>
                                                    )
                                            }

                                            <p><b>Status:</b> <span className="green">{item.status}</span></p>
                                        </div>
                                    ))
                                        : null//<p className='text-center'>No session found.</p>
                                }

                                <div className="history" onClick={() => {
                                    this.setState({ showDummySynopsis: true, showSynopsis: false })
                                }}>
                                    <EditIcon />
                                    <p><b>Time:</b> 24th Dec 2020</p>
                                    <p><b>Student:</b> Jack Ryan</p>
                                    <p><b>Challenge:</b> <a href="/answerboard/c1" target='_blank'>Force inside a planet</a></p>
                                    <p><b>Student Error:</b></p>
                                    <img src={synopsisImage} alt="Error" />
                                    <p><b>Status:</b> <span className="green">Finalized</span></p>
                                </div>
                                <div className="history disabled" onClick={() => {
                                    this.setState({ showDummySynopsis: true, showSynopsis: false })
                                }}>
                                    <EditIcon />
                                    <p><b>Time:</b> 29th Dec 2020</p>
                                    <p><b>Student:</b> Breonna Arbery</p>
                                    <p><b>Challenge:</b> <a href="/answerboard/c1" target='_blank'>Force inside a planet</a></p>
                                    <p><b>Student Error:</b></p>
                                    <img src={eq1} alt="Error" />
                                    <p><b>Status:</b> <span className="green">Finalized</span></p>
                                </div>
                                <div className="history disabled" onClick={() => {
                                    this.setState({ showDummySynopsis: true, showSynopsis: false })
                                }}>
                                    <EditIcon />
                                    <p><b>Time:</b> 22nd Dec 2020</p>
                                    <p><b>Student:</b> Topher Beifong</p>
                                    <p><b>Challenge:</b> <a href="/answerboard/c1" target='_blank'>Force inside a planet</a></p>
                                    <p><b>Student Error:</b></p>
                                    <img src={eq2} alt="Error" />
                                    <p><b>Status:</b> <span className="green">Finalized</span></p>
                                </div>
                                <div className="history disabled" onClick={() => {
                                    this.setState({ showDummySynopsis: true, showSynopsis: false })
                                }}>
                                    <EditIcon />
                                    <p><b>Time:</b> 20th Dec 2020</p>
                                    <p><b>Student:</b> Bo-Katan Kryze</p>
                                    <p><b>Challenge:</b> <a href="/answerboard/c15" target='_blank'>Skiing down an incline</a></p>
                                    <p><b>Student Error:</b></p>
                                    <img src={eq3} alt="Error" />
                                    <p><b>Status:</b> <span className="green">Finalized</span></p>
                                </div>
                                <div className="history disabled" onClick={() => {
                                    this.setState({ showDummySynopsis: true, showSynopsis: false })
                                }}>
                                    <EditIcon />
                                    <p><b>Time:</b> 15th Dec 2020</p>
                                    <p><b>Student:</b> Marco Inaros</p>
                                    <p><b>Challenge:</b> <a href="/answerboard/c7" target='_blank'>Skiing down an incline</a></p>
                                    <p><b>Student Error:</b></p>
                                    <img src={eq4} alt="Error" />
                                    <p><b>Status:</b> <span className="green">Finalized</span></p>
                                </div>
                            </div>
                        </div>
                    </Row>
                    <ToastContainer />
                </div ></>);
    }
}

export default SessionHistory;