import React from 'react';
import './FooterLinks.scss';
import { Link } from 'react-router-dom';
import Aux from '../../../hoc/Wrapper';

export const FooterLinks = (props) => {
    return (
        <div className='footer-menu'>
            <ul>
                {props.children}
            </ul>
        </div>
    );
}

export const FooterLink = (props) => {
    return (
        <Aux>
            <li>
                <span></span>
            </li>
            <li>
                <Link to={props.link}>{props.text}</Link>
            </li>
        </Aux>
    );
}