import React from 'react';
import './NotesCard.scss';
import Aux from '../../../../hoc/Wrapper';
import DeleteIcon from '@material-ui/icons/Delete';
import Tooltip from '@material-ui/core/Tooltip';
import { Link } from 'react-router-dom';
import { withRouter } from 'react-router-dom';

export default withRouter((props) => {
    let renderContent = null;

    let descriptions = [];
    if (props.highlight) {
        props.description.map(desc => {
            descriptions.push(
                <div className="highlight-content mb-2">
                    <Link to={{
                        pathname: `/curriculum-content/${props.link}`,
                        state: { prevPath: ['/notes-bookmarks'], prevNode: ['Notes/Bookmarks'] }
                    }}>
                        <p className='mb-1'>{desc.text}</p><p className='font-italic'>{desc.inlineNote ? 'Note:' + desc.inlineNote : ''}</p>
                    </Link>
                    <Tooltip title='Delete'>
                        <DeleteIcon color="action" className='delete-highlight' onClick={() => { props.deleteHighlight(desc.highlightId, props.link) }} />
                    </Tooltip>
                </div>)
        });
    } else {
        descriptions.push(<p>{props.description}</p>)
    }

    const openVideo = () => {
        props.history.push({
            pathname: '/curriculum-content/' + props.link,
            search: "?" + new URLSearchParams({ t: props.time }).toString(),
            state: { prevPath: ['/notes-bookmarks'], prevNode: ['Notes/Bookmarks'] }
        })
    }

    if (props.video) {
        renderContent = (
            <div className='my-video d-flex'>
                <div className='my-thumb-video' onClick={openVideo}>
                    <img src={props.image} alt='Video' />
                </div>
                <div className='text d-flex flex-column'>
                    <h4 onClick={openVideo}>{props.title}</h4>
                    {/* <p dangerouslySetInnerHTML={{ __html: props.description }}></p> */}
                    {descriptions.map(desc => desc)}
                    <div className='video-content'>
                        <p className='date'>{props.displayTime}</p>
                        <Tooltip title='Delete'>
                            <DeleteIcon color="action" className='delete-bookmark' onClick={() => { props.deleteBookmark(props.bookmarkId, props.link) }} />
                        </Tooltip>
                    </div>
                </div>
            </div>
        )
    } else if (props.highlight) {
        renderContent = (
            <div className='notescard'>
                <Link to={{
                    pathname: `/curriculum-content/${props.link}`,
                    state: { prevPath: ['/notes-bookmarks'], prevNode: ['Notes/Bookmarks'] }
                }}>
                    <div className='text'>
                        <h3 className='ml-0 chp-title'>{props.title}</h3>
                    </div>
                </Link>
                {descriptions.length > 1 ?
                    <ul>
                        {descriptions.map(desc => (<li>{desc}</li>))}
                    </ul>
                    :
                    descriptions.map(desc => desc)
                }
            </div>
        )
    } else {
        renderContent = (
            <div className='notescard'>
                <Link to={{
                    pathname: `/curriculum-content/${props.link}`,
                    state: { prevPath: ['/notes-bookmarks'], prevNode: ['Notes/Bookmarks'] }
                }}>
                    <div className='text'>
                        <h3 className='ml-0 chp-title'>{props.title}</h3>
                        {/* <p  className='ml-0' dangerouslySetInnerHTML={{ __html: props.description }}></p> */}
                        {descriptions.length > 1 ?
                            <ul>
                                {descriptions.map(desc => (<li>{desc}</li>))}
                            </ul>
                            :
                            descriptions.map(desc => desc)
                        }
                    </div>
                </Link>
                {(props.review || props.clarification) ?
                    <div className='note-content'>
                        <p className='review'>{props.review ? 'For Review' : 'For Clarification'}</p>
                        <Tooltip title='Delete'>
                            <DeleteIcon color="action" className='delete-note' onClick={() => { props.deleteNote(props.noteId) }} />
                        </Tooltip>
                    </div>
                    : null}
            </div>
        )
    }

    return (
        <Aux>
            {renderContent}
        </Aux>
    );
})