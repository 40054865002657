import { filter, isEmpty, isNil } from "lodash";
import React from "react";
import './GraphNode.css';

class GraphNode extends React.Component {
  //val1 = this.props.prof//Math.floor(Math.random() * (10 - 5 + 1) + 5);
  //val2 = this.props.avg;//Math.floor(Math.random() * (5 - 2 + 1) + 2);
  option = {
    title: [{ text: '' }],
    polar: { radius: [25, '50%'] },
    angleAxis: {
      max: 100,
      startAngle: 90,
      show: false,
      splitArea: {
        show: false
      },
      splitLine: {
        show: false
      },
      axisLine: {
        show: false
      },
      axisTick: {
        show: false
      }
    },
    radiusAxis: {
      type: 'category',
      data: ['a'],
      show: false
    },

    tooltip: {},
    series: [{
      type: 'bar',
      color: ['#ff0000', '#000000'],
      data: [],
      coordinateSystem: 'polar',
      label: {
        show: false,
        position: 'middle',
        formatter: '{b}: {c}'
      },

    },
    {
      type: 'bar',
      color: ['#000eff'],
      data: [],
      coordinateSystem: 'polar',
      label: {
        show: false,
        position: 'middle',
        formatter: '{b}: {c}'
      }
    }
    ]
  }
  constructor(props) {
    super(props);
  }

  componentDidMount() {
    const id = this.props.id;
    const callBackFunction = this.props.onNodeCreation;
    const label = this.props.label;
    const type = this.props.type;
    const chapter = this.props.chapter;
    const comp = document.getElementById(this.props.id);
    if(this.props.prof > 0 || this.props.avg > 0){
      var proficiencyChart = window.echarts.init(document.getElementById(this.props.id));
      this.option.series[0].data = [this.props.prof]
      this.option.series[1].data = [this.props.avg]
      proficiencyChart.setOption(this.option);
      proficiencyChart.on('finished', () => {
        callBackFunction({ id: id.toString(),size:type === 'chapter' ? 50 : 30, label: label.replace(" ","\n"), type: type, shape: "circularImage",chapter:chapter.toString(), color: this.getColor(chapter.toString()), font: { face: "Open Sans", align: "left", color: 'black' }, image: comp.toDataURL() , x:this.props.x,y:this.props.y });
      })
    }else {
       callBackFunction({ id: id.toString(), size:type === 'chapter' ? 50 : 30,label: label.replace(" ","\n"), type: type, shape: "circularImage",chapter:chapter.toString(), color: this.getColor(chapter.toString()), font: { face: "Open Sans", align: "left", color: 'black' }, image: comp.toDataURL(), x:this.props.x,y:this.props.y });
    }
   
  }
  getColor(chapterId) {
    var item = filter(this.props.chapterColors, ['chapterId', chapterId]);
    if (!isNil(item) && !isEmpty(item)) {
      return item[0]['color'];
    }
    return '#661100';
  }
  render() {
    return <canvas id={this.props.id} width="50" height="50"></canvas>
  }
}

export default GraphNode;