import React, { Component } from "react";
import notebooksImg from "../../assets/images/notebooks.png";
import homeworkProblemImg from "../../assets/images/homeworkproblems.png";
import RealtimeQAImg from "../../assets/images/realtimeQAStudent.png";
import configData from '../../common/config.json';


class AccessLimitationContent extends Component {

    constructor(props) {
        super(props);
        this.state = {
            menuAccessLimitationText: "",
            menuAccessImage: null,
        }
    }

    componentDidMount() {
        console.log("page - " + this.props.pageName)
        if (this.props.pageName === "HomeworkProblem") {
            this.setState({
                menuAccessLimitationText: `For subscription users only - <a href=${configData.APP_URL}signup>subscribe</a>`,
                menuAccessImage: homeworkProblemImg
            })
        } else if (this.props.pageName === "NotesBookmarks") {
            this.setState({
                menuAccessLimitationText: `Please <a href=${configData.APP_URL}signup>sign up</a> for a free account to access this`,
                menuAccessImage: notebooksImg
            })
        } else if (this.props.pageName === "RealtimeQA") {
            this.setState({
                menuAccessLimitationText: `For subscription users only - <a href=${configData.APP_URL}signup>subscribe</a>`,
                menuAccessImage: RealtimeQAImg
            })
        }
    }

    render() {
        return <>
            
            <div className="d-flex">
                <div className="tourcontent">
                    <div className="tourcontentDetails" 
                    style={{marginBottom:"30px", fontSize:"16px"}} 
                    dangerouslySetInnerHTML={{ __html: this.state.menuAccessLimitationText }} />
                    {this.state.menuAccessImage !== null ?
                        <img src={this.state.menuAccessImage} alt="" className="tourcontentImage img-fluid text-center" />
                        : null}
                </div>
            </div>
        </>
    }
}

export default (AccessLimitationContent)