import React from 'react';
import './ChallengeStudents.scss';
import { Row, Col } from 'react-bootstrap';
import Student from './Student/Student';

export default (props) => {
    return (
        <Row style={{ paddingBottom: '15px' }} className='friends-list disabled-section'>
            <Col sm={9}>
                <h2>{props.title}</h2>
                <p>{props.subtitle}</p>
            </Col>
            <Col sm={3} className='d-flex'>
                <span className="sample-data-label text-right flex-grow-1 mx-0 justify-content-end">* Sample Data</span>
            </Col>
            {props.students.map((student, index) => (
                <Col md={4} sm={6} xs={12} key={index}>
                    <Student {...student} />
                </Col>
            ))}
        </Row>
    );
}