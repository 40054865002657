import React, { useState, useEffect, useRef } from "react";
import { Container, Row, Col, Button } from 'react-bootstrap';
import { withNamespaces } from 'react-i18next';
import {
    makeStyles,
} from '@material-ui/core';
import Form from 'react-bootstrap/Form';
import { get_challenge_symbols, insertSymbolSubs, deleteSymbolSubs } from '../../common/API'
import { getCurrentUserId } from '../../common/Functions'
import { styled } from '@mui/material/styles';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import CircularProgress from '@mui/material/CircularProgress';
import Pagination from '@mui/material/Pagination';
import { useParams, useLocation } from 'react-router-dom';
import { isNil } from 'lodash';
import DeleteIcon from '@material-ui/icons/Delete';
import EditIcon from '@material-ui/icons/Edit';
import './index.scss'

const useStyles = makeStyles((theme) => ({
    root: {},
    avatar: {
        height: 42,
        width: 42,
        marginRight: theme.spacing(1)
    },
    avatarlarge: {
        height: 150,
        width: 150,
    },
    linkbutton: {
        fontSize: "10px"
    },
    datepickerc: {
        backgroundColor: "#fff"
    }
}));

const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
        backgroundColor: "#a4a4a4",
        color: theme.palette.common.white,
        padding: "10px",
        fontSize: "12px !important",
        fontWeight: "bold",
        textTransform: "none !important",
    },
    [`&.${tableCellClasses.body}`]: {
        fontSize: "12px !important",
        padding: "7px",
        textTransform: "none !important",
    },
}));


const StyledTableCellbody = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
        //backgroundColor: theme.palette.common.black,
        //color: theme.palette.common.white,
        padding: "10px",
        textTransform: "none !important",

    },
    [`&.${tableCellClasses.body}`]: {
        fontSize: "12px !important",
        padding: "7px"
    },
}));
const StyledTableRow = styled(TableRow)(({ theme }) => ({
    // '&:nth-of-type(even)': {
    //     backgroundColor: theme.palette.action.hover,
    // },
    // // hide last border
    // '&:last-child td, &:last-child th': {
    //     border: 0,
    // },
    '&:nth-of-type(odd)': {
        backgroundColor: "#fff !important"
    },
    '&:nth-of-type(even)': {

        backgroundColor: "#f8f8f8 !important"
    },
}));
const ChallengeSymbol = () => {
    const classes = useStyles();
    const params = useParams();
    const { state } = useLocation()
    const [loading, setloading] = useState(false)
    const [challengeSymbolData, setchallengeSymbolData] = useState([])
    const [page, setpage] = useState(1)
    const [startPage, setstartPage] = useState(0)
    const [rowsPerPage, setrowsPerPage] = useState(5)
    const [totalPages, settotalPages] = useState(1)
    const [chSymbol, setchSymbol] = useState("")
    const [subSymbols, setsubSymbols] = useState("")
    const [validated, setvalidated] = useState(false)
    const [mode, setmode] = useState("Add")
    const [challengeName, setchallengeName] = useState("")
    const [ids, setids] = useState("")
    const formRef = useRef(null);

    const getchallengeSymbols = () => {
        setloading(true)
        const reqData = {
            params: {
                "challenge_id": params.challengeId,
                "user_id":getCurrentUserId()
            }
        }
        get_challenge_symbols(reqData).then(res => {
            setloading(false)
            if (res.status === 200) {
                if (!isNil(res.data) && res.data.length > 0) {
                    setchallengeName(res.data[0].challengename)
                    if (res.data[0].srno !== null && res.data[0].srno !== undefined) {
                        setchallengeSymbolData(res.data)
                        settotalPages(Math.ceil(res.data.length / rowsPerPage))
                    }
                    else {
                        setchallengeSymbolData([])
                    }


                }
                else {
                    setchallengeSymbolData([])
                }
            }
        }).catch(err => {
            setloading(false)
            console.error(err.message);
        })
    }
    useEffect(() => {
        getchallengeSymbols();

    }, []);
    const handleChangePage = (event, page) => {
        setpage(page)
        setstartPage(page - 1)
    }

    const handlechallengesymbol = (e) => {
        setchSymbol(e.target.value)
    }
    const handlesubstitutionsymbols = (e) => {
        setsubSymbols(e.target.value)
    }

    const handleSubmit = async (event) => {
        event.preventDefault();
        const form = event.currentTarget;
        if (form.checkValidity() === false) {
            event.preventDefault();
            event.stopPropagation();
        }
        else {
            var symbollist = []
            var data = {
                "chsymbol": chSymbol,
                "subsymbols": subSymbols,
                "ids": ids
            }
            symbollist.push(data)
            // console.log(symbollist)           
            let form_data = new FormData();
            form_data.append("challenge_id", params.challengeId);
            form_data.append("user_id", getCurrentUserId());
            form_data.append("symbolslist", JSON.stringify(symbollist));
            insertSymbolSubs(form_data).then(res => {
                getchallengeSymbols();
                handleReset();

            }).catch(err => {
                setloading(false)
                handleReset();
                console.error(err.message);
            })

        }
        setvalidated(true)
    }
    const handleReset = () => {
        setchSymbol("")
        setsubSymbols("")
        setids("")
        setvalidated(false);
    };

    const handleeditclick = (e, ids, chSymbol, subsymbol) => {
        setchSymbol(chSymbol)
        setsubSymbols(subsymbol)
        setids(ids)
    }
    const handledelete = (e, chSymbol) => {
        let form_data = new FormData();
        form_data.append("challenge_id", params.challengeId);
        form_data.append("user_id", getCurrentUserId());
        form_data.append("chsymbol", chSymbol);
        deleteSymbolSubs(form_data).then(res => {
            getchallengeSymbols();
            handleReset();

        }).catch(err => {
            setloading(false)
            handleReset();
            console.error(err.message);
        })
    }
    const handlecancel = () => {
        handleReset();
    }

    return (
        <>
            <Container className="chsymbols_container">
                <Row className='teacher-ui'>
                    <h3 className='title my-3'>{challengeName} - Substitution Symbols</h3>

                </Row>
                <>
                    <Row style={{ marginTop: "10px" }}>
                        <TableContainer component={Paper} sx={{ userSelect: "text" }}>
                            <Table aria-label="collapsible table">
                                <TableHead>
                                    <TableRow>
                                        <StyledTableCell width="25%">sr#</StyledTableCell>
                                        <StyledTableCell width="25%">Challenge Symbols</StyledTableCell>
                                        <StyledTableCell width="25%">Substitution Symbols</StyledTableCell>
                                        <StyledTableCell width="25%">Actions</StyledTableCell>

                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {
                                        challengeSymbolData !== undefined && challengeSymbolData !== null && challengeSymbolData.length > 0 ? (
                                            challengeSymbolData.slice(startPage * rowsPerPage, startPage * rowsPerPage + rowsPerPage).map((row) => {
                                                return (
                                                    <StyledTableRow sx={{ '& > *': { borderBottom: 'unset' } }} width="10%">
                                                        <StyledTableCellbody width="25%">
                                                            {row.srno}
                                                        </StyledTableCellbody>
                                                        <StyledTableCellbody width="25%">
                                                            {row.ch_symbols}
                                                        </StyledTableCellbody>
                                                        <StyledTableCellbody width="25%">
                                                            {row.sub_symbols}
                                                        </StyledTableCellbody>
                                                        <StyledTableCellbody width="25%">
                                                            <div style={{ display: "flex" }}>
                                                                <span onClick={(e) => handleeditclick(e, row.id, row.ch_symbols, row.sub_symbols)}><EditIcon /></span>
                                                                <span onClick={(e) => handledelete(e, row.ch_symbols)} style={{ marginLeft: "10px" }}><DeleteIcon></DeleteIcon></span>
                                                            </div>
                                                        </StyledTableCellbody>
                                                    </StyledTableRow>
                                                )
                                            }
                                            )
                                        ) : (
                                            <StyledTableRow>
                                                <StyledTableCellbody width="25%"></StyledTableCellbody>
                                                <StyledTableCellbody width="25%"></StyledTableCellbody>
                                                <StyledTableCellbody width="25%">No Data Found!</StyledTableCellbody>
                                                <StyledTableCellbody width="25%"></StyledTableCellbody>
                                            </StyledTableRow>
                                        )
                                    }
                                </TableBody>
                            </Table>
                        </TableContainer>
                        <Pagination
                            sx={{
                                "& .MuiTablePagination-selectLabel": {
                                    marginBottom: "0px !important"
                                },
                                "& .MuiTablePagination-displayedRows": {
                                    marginBottom: "0px !important"
                                }
                            }}
                            variant="outlined"
                            shape="rounded"
                            color="primary"
                            count={totalPages}
                            page={page}
                            onChange={handleChangePage}
                        />
                    </Row>
                </>

                <div className='container bg-white-with-shadow1'>

                    <Row className='teacher-ui'>
                        <h3 className='title my-3'>{mode} Substitution Symbols</h3>

                    </Row>


                    <Form ref={formRef} noValidate validated={validated} onSubmit={handleSubmit}>


                        <Form.Group as={Row} className="mb-2" controlId="formchallengesymbol">
                            <Form.Label column="sm" lg={2}>
                                Challenge Symbol
                            </Form.Label>
                            <Col sm="10">
                                <Form.Control placeholder="Challenge Symbol" size="sm" type="text"
                                    style={{ width: "410px" }}
                                    required
                                    value={chSymbol}
                                    onChange={handlechallengesymbol}
                                />
                                <Form.Control.Feedback type="invalid">
                                    Please enter Challenge Symbol.
                                </Form.Control.Feedback>

                            </Col>

                        </Form.Group>
                        <Form.Group as={Row} className="mb-2" controlId="formsubstitutionsymbol">
                            <Form.Label column="sm" lg={2}>
                                Substitution Symbol
                            </Form.Label>
                            <Col sm="10">

                                <Form.Control placeholder="Substitution Symbols" size="sm" as="textarea" rows={6}
                                    style={{ width: "410px" }}
                                    required
                                    value={subSymbols}
                                    onChange={handlesubstitutionsymbols} />
                                <Form.Control.Feedback type="invalid">
                                    Please Substitution Symbols.
                                </Form.Control.Feedback>

                            </Col>

                        </Form.Group>
                        <Form.Group as={Row} className="mb-2 mt-4" controlId="formthumbnailimage">
                            <Form.Label column="sm" lg={2} ></Form.Label>
                            <Col sm="10">
                                <Button type="submit" variant="primary" style={{  marginBottom: "20px" }}>Save</Button>

                                <a onClick={handlecancel} className="btn btn-outline-dark" 
                                style={{ textDecoration: 'none', marginBottom: "20px",  marginLeft: "10px" }}>Cancel</a>
                            </Col>
                        </Form.Group>
                    </Form>

                </div>


            </Container>
            {
                loading && (
                    <CircularProgress
                        size={24}
                        sx={{
                            color: "#164b99",
                            position: 'absolute',
                            top: '50%',
                            left: '50%',
                            marginTop: '-12px',
                            marginLeft: '-12px',
                        }}
                    />
                )
            }
        </>
    );
};
export default withNamespaces()(ChallengeSymbol);