import React from 'react';
import { withRouter } from 'react-router-dom';
import "./ChatBot.scss";
import { askChatGPT } from '../../../common/API';
import { Switch } from '@mui/material';
import { isNil } from 'lodash';
import { detectDevice, TABLET } from '../../../common/Functions';


class ChatBotComponent extends React.Component {

    question = '';
    loaderClass = '';
    hasChatGptAns = true;
    idSuffix = '';
    aiPlatoTrained = false;
    videoTimeStamp = 0;
    firstQuestion = true;
    option = {
        tooltip: {
            trigger: 'item'
        },
        series: [
            {
                name: 'Access From',
                type: 'pie',
                radius: ['40%', '70%'],
                avoidLabelOverlap: false,
                itemStyle: {
                    borderRadius: 10,
                    borderColor: '#fff',
                    borderWidth: 2
                },
                label: {
                    show: false,
                    position: 'center'
                },
                emphasis: {
                    label: {
                        show: true,
                        fontSize: 40,
                        fontWeight: 'bold'
                    }
                },
                labelLine: {
                    show: false
                },
                data: [
                    { value: 1048, name: 'Search Engine' },
                    { value: 735, name: 'Direct' },
                    { value: 580, name: 'Email' },
                    { value: 484, name: 'Union Ads' },
                    { value: 300, name: 'Video Ads' }
                ]
            }
        ]
    };
    constructor(props) {
        super(props);
        this.idSuffix = this.props.isInline ? '_inline' : '';
        this.question = this.props.isInline ? this.props.question : '';
        if (this.props.isInline) {
            this.aiPlatoTrained = this.props.isInline ? true : false;
        }

        this.videoTimeStamp = this.props.videoTimeStamp ? parseInt(this.props.videoTimeStamp) : 0;
        this.state = {
            showComponent: true, questions: [], questionCount: 0, isChatWindowOpen: false
            , askChatGPT: this.props.isInline ? false : true
        };

    }

    componentDidMount() {
        if (this.props.isInline) {
            this.submitQuestion();
            this.setState({ isChatWindowOpen: true })
        }
        this.manageShowHideChatbot();
    }

    componentDidUpdate(prevProps) {
        if (prevProps !== this.props) {
            this.manageShowHideChatbot();
        }

    }

    manageShowHideChatbot = () => {
        if (this.props.location.pathname.search('curriculum-content') !== -1 || this.props.isInline) {
            this.setState({ showComponent: true })
        } else {
            this.setState({ showComponent: false })
        }
    }
    submitQuestion(q) {

        if (this.question !== '' && this.state.showComponent) {
            let newQuestion = { que: this.question, ans: '', type: 'text' }
            // if(!this.props.isInline)
            // {
            this.setState({ questions: [...this.state.questions, newQuestion] })
            // }
            // else{
            //     this.setState({ questions: [newQuestion ]})  
            // }            
            this.askChatGPT(this.question);
            if (!this.props.isInline) {
                this.question = '';
            }
            document.getElementById('questionText' + this.idSuffix).value = '';
        }

    }

    askChatGPT = (quesValue) => {

        this.loaderClass = 'loaderContainer';
        let ans = '';
        this.hasChatGptAns = true;
        this.hasChart = false;
        //if (!this.props.isInline) {

        this.aiPlatoTrained = this.state.askChatGPT ? false : true;
        //}
        if (quesValue.search('bored') === -1 && quesValue.search('boring') === -1) {
            let Content_type = this.props.Content_type
            let SubSection_id = this.props.SubSection_id
            askChatGPT(quesValue, this.aiPlatoTrained, this.videoTimeStamp, this.firstQuestion, Content_type, SubSection_id).then(res => {
                this.firstQuestion = false;
                this.loaderClass = '';
                const ansObj = JSON.parse(res.data.chatgpt);
                ans = ansObj.answer;
                this.setAnswer(ans)
            })
        } else {
            setTimeout(() => {
                this.loaderClass = '';
                ans = '<div><div class="links"><a href="/curriculum-content/17">I read you! Let\'s try real-world examples to ignite your interest? </a><img src="/images/content/4-3_IC_b.jpg" alt="Slide" class="responsiv-img"></div><div class="links"><a href="/curriculum-content/18">Let\'s adjust the pace, making learning physics a breeze? </a><img src="/images/content/4-3_IC_c.jpg" alt="Slide" class="responsiv-img"></div><div class="links"><a href="/curriculum-content/15">Keep going, fascinating discoveries lie ahead in the lecture! </a><img src="/images/content/4-2_IC_a.jpg" alt="Slide" class="responsiv-img"><div></div>';
                this.hasChatGptAns = false;
                this.setAnswer(ans);
            }, 200)
        }


    }

    setAnswer = (ans, type = 'text') => {
        let o = this.state.questions[this.state.questions.length - 1];
        o['ans'] = ans;
        if (type !== 'text') {
            o['type'] = type;
        }
        let tempArray = (this.state.questions);
        tempArray.pop();
        tempArray.push(o);
        this.setState({ questionCount: tempArray.length - 1 });
        this.setState({ questions: tempArray });
        setTimeout(this.handleLastAnswer, 300);

    }
    onInputKeyUp = (evt) => {
        if (evt.keyCode === 13) {
            this.submitQuestion();
        }
    }

    handleLastAnswer = () => {
        if (this.state.questions[this.state.questions.length - 1]['type'] === 'text') {
            if (this.hasChatGptAns) {
                console.log("lines - " + this.state.questions[this.state.questions.length - 1]['ans'])
                let words = this.state.questions[this.state.questions.length - 1]['ans'].split(' ');
                let count = 0;
                let ansText = '';
                let lastAns = document.getElementById('lastAnswer' + this.idSuffix)
                if (lastAns !== null) {
                    lastAns.innerHTML = '';
                    const interval = setInterval(() => {
                        lastAns.innerHTML = '';
                        ansText += ' ' + (words[count].includes('\n') ? words[count].replace('\n', '<br /><br />') : words[count]);
                        lastAns.innerHTML = ansText;
                        if (count === words.length - 1) {
                            clearInterval(interval);
                        }
                        count++;
                        var element = document.getElementById('chatContainer' + this.idSuffix);
                        if (!isNil(element)) {
                            element.scroll({ top: element.scrollHeight, behavior: 'smooth' });
                        }
                    }, 100);
                }
            } else {
                let lastAns = document.getElementById('lastAnswer' + this.idSuffix);
                lastAns.innerHTML = '';
                lastAns.innerHTML = this.state.questions[this.state.questions.length - 1]['ans'];
            }
        }
        // else {
        //     let comp = document.getElementById('eChartContainer')
        //     var proficiencyChart = window.echarts.init(document.getElementById('eChartContainer'));
        //     proficiencyChart.setOption(this.option);
        // }


    }

    openCloseChatBot = () => {
        this.setState({ isChatWindowOpen: !this.state.isChatWindowOpen })
    }

    navigateToUserInterestedPage = () => {
        this.props.navigationFunction();
    }

    getChatBotStyle = () => {
        if (this.state.isChatWindowOpen && !this.props.isInline) {
            return '530px'
        } else if (!this.state.isChatWindowOpen && this.props.isInline) {
            return '460px'
        } else {
            return '0'
        }
    }

    getMainContainerStle = () => {
        if (this.state.isChatWindowOpen && !this.props.isInline) {
            return '530px'
        } else if (!this.state.isChatWindowOpen && this.props.isInline) {
            return '450px'
        } else {
            return '0'
        }
    }

    togglehandler = () => {
        this.setState({ askChatGPT: !this.state.askChatGPT }, () => {
            //this.aiPlatoTrained = this.state.askChatGPT ? true : false;
            //this.aiPlatoTrained = this.state.askChatGPT ? false : true
            this.submitQuestion();
            if (this.props.isInline) {
                let newQuestion = { que: this.question, ans: '', type: 'text' }
                this.setState({ questions: [newQuestion] })
            }
        })


    }
    render() {
        console.log("check inline", this.props.isInline)

        return <div>
            {this.state.showComponent ? <div className='chatBot' style={this.state.isChatWindowOpen || this.props.isInline ? { height: this.getMainContainerStle(), width: '467px' } : { height: "50px", width: '150px' }}>

                <div id='chatBotContainer' className='chatBotContainer' style={{ height: this.getChatBotStyle() }}>
                    {/* {!this.props.isInline ? */}
                    <div className='header'
                        style={this.state.isChatWindowOpen ? { display: 'flex' } : { display: 'none' }}
                    //style={{ display: 'flex' }}
                    >
                        <span>aiPlato</span>
                        <div className='switch'>
                            <span>{this.state.askChatGPT ? 'Ask Generative AI' : 'Ask ChatGPT'}</span>
                            {detectDevice() === TABLET ?
                                <Switch defaultChecked size="small" color="default" checked={this.state.askChatGPT}
                                    onClick={this.togglehandler}
                                />
                                :
                                <Switch defaultChecked size="small" color="default"
                                    onChange={this.togglehandler}
                                />
                            }
                        </div>
                        {

                            this.props.isInline ? (<button class="closeButton" onClick={this.props.handleclose}><i class="ico-times"></i></button>)
                                : (<button class="closeButton" onClick={this.openCloseChatBot}><i class="ico-times"></i></button>)


                        }
                    </div>
                    {/* : null} */}
                    <div id={`chatContainer${this.idSuffix}`} className='chatContainer'>
                        <span className='cautionText mainText' style={{ display: this.props.isInline ? "relative" : "none" }}><span>{!this.state.askChatGPT ? 'aiPlato-Trained Generative AI' : 'ChatGPT'}</span></span><br />
                        <span className='cautionText mainText' style={{ display: this.props.isInline ? "none" : "relative" }}>If your question is about the lecture, please click on ‘Raise Hand’</span><br />
                        <span className='cautionText'>Caution: We recommend that you confirm the accuracy of the Generative AI content with a human tutor.  Generative AI is reported to frequently produce “confident, but wrong” content, especially in subjects that require complex reasoning. </span>
                        {this.state.questions.map((item, index) => {
                            return (<div>
                                <div className='chatTextContainer questionContainer'>
                                    <span >{item.que}</span>
                                </div>

                                <div className='chatTextContainer answerContainer'>
                                    {index !== this.state.questionCount ? <span dangerouslySetInnerHTML={{ __html: item.ans }}></span> : <span id={`lastAnswer${this.idSuffix}`} style={{ wordBreak: 'break-word' }}></span>}

                                </div>

                            </div>);
                        })}
                        <div className={this.loaderClass}></div>
                    </div>
                    <div className='inputContainer'>
                        <input
                            id={`questionText${this.idSuffix}`}
                            style={{ width: "100%", border: "1px solid #ccc" }}
                            onChange={(evt) => {
                                this.question = evt.target.value;
                            }}
                            onKeyUp={(evt) => { this.onInputKeyUp(evt) }}
                            placeholder="Ask question..."
                            className="labels"
                        />
                        <div className='sendButton' onClick={(evt) => { this.submitQuestion(evt) }}>
                            <img
                                alt='' src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAADAAAAAwCAYAAABXAvmHAAAACXBIWXMAAAsTAAALEwEAmpwYAAAC7klEQVR4nO2YXUgUURiGT1QQRRDVjaVRBJH9F14UEaR540WQUUQ3URQRFIQRJoSoBKHozjmrNy5kQlGUhTdGF4bYheme42RhpLTaD2r5Q1BS7pxvpb6YQtcV93dmdlaaB77b5TznvPOemSXEwcHBIWWob8UlVEiVeuGUR8XFZL7h9k6kMwGoDxUwTDmUVqm4mswXKIddUwLTIlxKyuUdJmAbSXUUoeXOFggZLtsUIQ8j4gKSilABJyIKBOPlY0JernyNy0gqoQjtYiwCM07kOxPgZqp/HUkFmJAlcQkERX5RAU1KZ2C/rQJUQHVCAiHxsrGGGZf3jQoETwW+6DVcw3FV0gQoh2bTBIInouk1rHDYmgQB2WW2wIz75Dfj8MzSGmYcBqwSmDXv9Br2qLjUXAEh/UkSCKnhKuHPMEVgy43eyd03fZhd/RGP1A3h6QejWND0DUtbf6LCpWUilMsAE9BAeWCfIYGsCt9IWmE3zjXpRW8ws6wX97r6Ma92AE/eHcYLjV/x6tN/giY+9KpewyWtuChugWz2/nk4gWiTEUawqHkcK9u1hGvY1Y4rYxbIqx0sS1Qg2my4/hbDxZPyiPH6Qbn00A6ZGVUgv25wp1UCkWZthNOreOEPeV1hXDsUVuDovc+b7RBIK+zGjcU9uKe8D3NqPmH+7SE883AUC54EC+TvhSjkLZcK28NHyDNQbOsui7keahhhAsprXk6siRqhg+4PLXbknM2d/S63CJzXv9NJrGSV+4aT2jQizGu50HJJImwq7Z2cvcBzj8amd9Gyi0zIcf1Gph3aemIEl1eTVi0yTL59rFO7VNGGy4kZUC7HkrJ4LtsYh+MNDbiQmAnj0GfZbk/9PdMBO4hVUCFVC2ISew0ahQloMXHH469Bo1ABjbbWoFGYkPW21qBRKAcWX0ygn3J5jb7CFSQVYBzKbK1Bo1Aur9hag0ZhXjhraw0aRemUx2ytQaMoXi2HcniseAMHDP+Yg4ODw3/BH1mSw16YNJHVAAAAAElFTkSuQmCC"></img>
                        </div>

                    </div>
                </div>
                <div className='buttonContainer' style={this.state.isChatWindowOpen || this.props.isInline ? { visibility: 'hidden' } : { visibility: 'unset' }} onClick={this.openCloseChatBot}>
                    <span className='minimized-view-icon'>
                        <svg width="16" height="16" viewBox="0 0 16 16" version="1.1" xmlns="http://www.w3.org/2000/svg"><g id="Page-1" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd"><path d="M16,2 L16,10.4444444 C16,11.5490139 15.1045695,12.4444444 14,12.4444444 L11.9109137,12.4444444 L11.9109137,16 L7.74039,12.4444444 L2,12.4444444 C0.8954305,12.4444444 0,11.5490139 0,10.4444444 L0,2 C0,0.8954305 0.8954305,0 2,0 L14,0 C15.1045695,0 16,0.8954305 16,2 Z" id="chat" fill="#ebf1ff"></path></g></svg>
                    </span>
                    <span className='minimized-view-text' >aiPlato Help</span>
                </div>
            </div>
                : null}
        </div>

    }
}

export default withRouter(ChatBotComponent);