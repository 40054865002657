export const ckConfig = [{
    name: 'document',
    groups: ['mode', 'document', 'doctools']
},
{
    name: 'clipboard',
    groups: ['PasteFromWord','clipboard', 'undo']
},
{
    name: 'editing',
    groups: ['find', 'selection', 'spellchecker', 'editing']
},
{
    name: 'forms',
    groups: ['forms']
},
{
    name: 'paragraph',
    groups: ['list', 'indent', 'blocks', 'align', 'bidi', 'paragraph']
},
{
    name: 'links',
    groups: ['links']
},
{
    name: 'insert',
    groups: ['insert']
},
{
    name: 'styles',
    groups: ['styles']
},
{
    name: 'basicstyles',
    groups: ['basicstyles', 'cleanup']
},
{
    name: 'colors',
    groups: ['colors']
},
{
    name: 'tools',
    groups: ['tools']
},
{
    name: 'others',
    groups: ['others']
}]

export const graphCkConfig = [
  
  {
    name: 'paragraph',
    items: ['NumberedList', 'BulletedList', '-', 'Outdent', 'Indent', '-', 'Blockquote']
  },
  {
    name: 'insert',
    items: ['Image', 'Table']
  },
  {
    name: 'align',
    items: ['JustifyLeft', 'JustifyCenter', 'JustifyRight', 'JustifyBlock']
  },
  {
    name: 'links',
    items: ['Link', 'Unlink']
  },
  {
    name: 'editing',
    items: ['Scayt']
  },
  {
    name: 'styles',
    items: ['Format', 'Font', 'FontSize']
  },
  {
    name: 'basicstyles',
    items: ['Bold', 'Italic', 'Underline', 'Strike', 'Subscript', 'Superscript']
  },
  {
    name: 'colors',
    items: ['CopyFormatting', 'RemoveFormat', '-', 'TextColor', 'BGColor']
  }
]
export const basicCkConfig = [     
  {
    name: 'insert',
    items: ['Image']
  }, 
  
  {

    name: 'colors',    
    items: ['BGColor']
  }
]