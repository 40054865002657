import React from 'react';
import { MathComponent } from "mathjax-react";

import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { Col, Row } from 'react-bootstrap';
import { showFullApp, formatAnyProblemAPIResponse } from '../../common/Functions'
import { tr } from 'date-fns/locale';
import parse from 'html-react-parser';
import MathJaxRenderer from '../../components/Common/MathJaxRenderer/MathJaxRenderer';

class EquationDisplay extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            latex: '',
            mathml: '',
            isExpand: null,
            equations: [],
            expand: false,
        };
    }

    componentWillMount() {
        if (this.props.isanyProb && this.props.mathml !== '') {
            let mathmls = this.props.mathml.split('<mspace linebreak="newline"/>')
            let i = 0;
            for (const mathmlitemitr of mathmls) {
                let mathmlitem = "";
                if (i === 0) {
                    mathmlitem = mathmlitemitr;
                    mathmlitem += mathmls.length > 1 ? '</math>' : '';
                }
                else if (i > 0 && i < mathmls.length - 1) {
                    mathmlitem = '<math xmlns="http://www.w3.org/1998/Math/MathML">' + mathmlitemitr + '</math>';
                }
                else if (mathmlitemitr !== '</math>') {
                    mathmlitem = '<math xmlns="http://www.w3.org/1998/Math/MathML">' + mathmlitemitr;
                }

                if (mathmlitem !== '') {
                    this.state.equations.push(mathmlitem)
                }

                i++;
            }
        }
    }

    componentDidUpdate(prevProps) {
        if (this.state.mathml !== this.props.mathml) {
            this.setState({ mathml: this.props.mathml });
        }
    }

    handleAccordionChange = id => (_, isExpanded) => {
        if (this.props.isanyProb) {
            this.setState({ expand: true, isExpand: (isExpanded ? id : null) })
        }
        else {
            this.setState({ isExpand: (isExpanded ? id : null) })
        }
    }


    render() {
        return (
            !this.props.isanyProb ?
                (
                    <MathJaxRenderer math={this.state.mathml} />
                )
                :
                (
                    <div style={{ overflowY: 'auto' }} className={`psAccs ${!showFullApp() ? ' noErrorLine' : ''}  `} id={this.props.setid}>
                        <Accordion
                            sx={{
                                backgroundColor: "transparent",
                                borderBottom: "none !important"
                            }}
                            expanded={this.state.isExpand === (this.props.totalEquation - 1 === this.props.setid && !this.state.expand ? null : this.props.setid)}
                            onChange={this.handleAccordionChange(this.props.setid)}>
                            <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel1a-content" id="panel1a-header">
                                <Typography>
                                    <Row>
                                        <Col className='pl-1 col-10 col-md-10'>
                                            {this.props.setid !== 0 ?
                                                <span style={{ textWrap: 'nowrap' }}>Line {this.props.setid} </span>
                                                :
                                                null
                                            }
                                            {this.state.equations.map((item) => {
                                                return (
                                                    !showFullApp() ?
                                                        <MathJaxRenderer math={item}></MathJaxRenderer>
                                                        :
                                                        <span>{parse(this.props.mathml)}</span>
                                                )
                                            })}
                                        </Col>
                                    </Row>
                                </Typography>
                            </AccordionSummary>
                            <AccordionDetails>
                                <Typography>
                                    <div className="challengeTopic border-bottom-last">

                                        {this.props.setid !== 0 ?
                                            Array.isArray(this.props.responseText.length) ?
                                                this.props.responseText.map((item) => {
                                                    let finalcontent = []
                                                    item.forEach(element => {
                                                        let data = formatAnyProblemAPIResponse(element)
                                                        finalcontent.push(data)
                                                    });
                                                    return (
                                                        item ?
                                                            finalcontent.map(content => {
                                                                return (
                                                                    <>
                                                                        <MathJaxRenderer math={content}></MathJaxRenderer>
                                                                        <br />
                                                                    </>
                                                                )
                                                            })
                                                            :
                                                            null
                                                    )
                                                })
                                                :
                                                <MathJaxRenderer math={this.props.responseText}></MathJaxRenderer>
                                            :
                                            <div>{this.props.responseText}</div>
                                        }
                                    </div>
                                </Typography>
                            </AccordionDetails>
                        </Accordion>
                    </div>
                )
        )
    }
}

export default EquationDisplay