import React from 'react';
import './HtmlContent.scss';
import './TextbookEquationsStyles.css'
import parse from 'html-react-parser';
import { isNil } from "lodash";
import { CKEditor } from 'ckeditor4-react';
import { basicCkConfig } from "../../../../utils/CkEditorConfig";
import { Switch } from '@mui/material';
import CircularProgress from '@mui/material/CircularProgress';
import { height, maxHeight } from '@mui/system';


export default class HtmlContentSynposis extends React.Component {
    state = {
        contentdata: this.props.content,
        editor: null,
        istutortostudent: this.props.istutortostudent,
        localloading: false,
        highlightedObjectList: this.props.highlightedObjectList,
        displayid: this.props.displayid

    };
    static getDerivedStateFromProps(props, state) {
        console.log("checkautohighlighted....", props.highlightedObjectList)
        if (props.content !== state.contentdata
        ) {
            // debugger;
            if (props.displayid !== undefined && props.displayid !== null) {
                if (state.displayid !== props.displayid) {
                    if (!isNil(state.editor)) {
                        state.editor.setData("")
                    }
                }
            }

            let { highlightedObjectList, hintObjectList } = props
            //console.log("contentchange..........", props.content)
            let innerHTML = props.content//.replace(/<\/?div[^>]*>/g, "").replace(/<\/?span[^>]*>/g, "")
            // let spanindex = innerHTML.indexOf("span")
            // if (spanindex === -1) {
            //     innerHTML = innerHTML.replace(/<\/?div[^>]*>/g, "").replace(/<\/?span[^>]*>/g, "")
            //     console.log("richeditor switch dat&&&&&&&&&&&&&&&&&&&&&&&", innerHTML)
            //     // Add hints to the HTML content
            //     if (hintObjectList) {
            //         hintObjectList.forEach(hintObj => {
            //             let start = hintObj.selectionStart
            //             let end = hintObj.selectionEnd
            //             let focusNodeText = hintObj.focusNodeText
            //             let index = innerHTML.indexOf(hintObj.elementClass);
            //             if (index >= 0) {
            //                 let subHTML = innerHTML.substring(index, innerHTML.length)
            //                 let mainContentStartIndex = subHTML.indexOf(focusNodeText)
            //                 let mainContentEndIndex = mainContentStartIndex + focusNodeText.length
            //                 let mainContent = subHTML.substring(mainContentStartIndex, mainContentEndIndex)
            //                 let highlightedText = mainContent.substring(start, end)
            //                 if (highlightedText === hintObj.hintText) {
            //                     let iconClass = hintObj.hintText === '' ? 'has-note' : ''
            //                     let modifiedMainContent = mainContent.substring(0, start) + `<span class='hint ${iconClass}' name='${start}##${end}'>` + highlightedText + "</span>" + mainContent.substring(end, subHTML.length)
            //                     let modifiedSubHTML = subHTML.substring(0, mainContentStartIndex) + modifiedMainContent + subHTML.substring(mainContentEndIndex, subHTML.length)
            //                     innerHTML = innerHTML.substring(0, index) + modifiedSubHTML
            //                 }
            //             }
            //         });
            //     }
            //     // Add highlights to the HTML content
            //     if (highlightedObjectList.length) {




            //         highlightedObjectList.map(highlightObj => {

            //             let start = highlightObj.selectionStart
            //             let end = highlightObj.selectionEnd
            //             let { focusNodeText, highlighterColor } = highlightObj
            //             let index = innerHTML.indexOf(highlightObj.elementClass);
            //             if (index >= 0) {

            //                 let subHTML = innerHTML.substring(index, innerHTML.length)
            //                 let mainContentStartIndex = subHTML.indexOf(focusNodeText)
            //                 let mainContentEndIndex = mainContentStartIndex + focusNodeText.length
            //                 let mainContent = subHTML.substring(mainContentStartIndex, mainContentEndIndex)
            //                 //let highlightedText = mainContent
            //                 // debugger;
            //                 let highlightedText = mainContent.substring(start, end)
            //                 let noteClass = highlightObj.note ? 'has-note' : ''
            //                 if (highlightedText === highlightObj.highlightedText) {
            //                     let backgroundcolor = ""
            //                     switch (highlighterColor) {
            //                         case "orange":
            //                             backgroundcolor = "#ffa500";
            //                             break;
            //                         case "yellow":
            //                             backgroundcolor = "#ffff8a";
            //                             break;
            //                         case "green": // blue
            //                             backgroundcolor = "#bfd4ff";
            //                             break;
            //                         case "grey":
            //                             backgroundcolor = "#c0c0c0";
            //                             break;
            //                         case "blue":
            //                             backgroundcolor = "#5897fb";
            //                             break;
            //                         case "pink":
            //                             backgroundcolor = "#bdd8b2";
            //                             break;
            //                         case "purple":
            //                             backgroundcolor = "#ffc1c1";
            //                         case "darkgreen":
            //                             backgroundcolor = "#74ba56";
            //                             break;
            //                         default:
            //                             break;
            //                     }
            //                     let modifiedMainContent = mainContent.substring(0, start) + `<span style="background-color:${backgroundcolor}" name='${start}##${end}'>` + highlightedText + "</span>" + mainContent.substring(end, subHTML.length)
            //                     //let modifiedMainContent = mainContent.substring(0, start) + `<span class='highlight highlight-${highlighterColor} ${noteClass}' name='${start}##${end}'>` + highlightedText + "</span>" + mainContent.substring(end, subHTML.length)
            //                     let modifiedSubHTML = subHTML.substring(0, mainContentStartIndex) + modifiedMainContent + subHTML.substring(mainContentEndIndex, subHTML.length)
            //                     innerHTML = innerHTML.substring(0, index) + modifiedSubHTML
            //                 }
            //             }
            //         });
            //     }

            //     // if (props.istutortostudent) {                    
            //     //     let data1 = innerHTML.replaceAll("Tutor:", "Tutor_Old:");
            //     //     let data2 = data1.replaceAll("Student:", "Tutor:");
            //     //     innerHTML = data2.replaceAll("Tutor_Old:", "Student:");
            //     // }


            // }
            // else {
            //     innerHTML = innerHTML.replace(/<\/?div[^>]*>/g, "")
            // }
            // //debugger;
            debugger;
            if (!props.newitemloading) {
                setTimeout(() => {
                    console.log("innerhtml data...........", innerHTML)
                    if (!isNil(state.editor)) {
                        state.editor.setData(innerHTML)

                    }
                }, 300);
            }

            return {
                contentdata: innerHTML,
                hintObjectList: hintObjectList,
                //istutortostudent: props.istutortostudent,
                highlightedObjectList: highlightedObjectList,
                displayid: props.displayid
            };


        }
        return null; // No change to state
    }

    getHTMLContent = () => {
        let innerHTML = this.props.content;
        return innerHTML
    }

    componentDidUpdate() {
        // Set listeners on hint section
        try {
            let hint = window.$('.hint');
            hint.on('mouseover', this.mouseOver);

            let hintDetail = window.$('.textbook-hint')
            hintDetail.on('mouseleave', () => {
                if (this.props.isHintVisible && this.shouldHideHint) {
                    this.props.hideHint()
                    this.shouldHideHint = false
                }
            });

        } catch (e) {
            console.log(e.message);
        }
    }

    mouseOver = (e) => {
        if (!this.props.isHintVisible) {
            const hintText = e.target.innerHTML
            const range = e.target.getAttribute('name')
            this.props.showHint(e, range.split('##'), hintText)
            setTimeout(() => { this.shouldHideHint = true }, 100)
        }
    }

    handleeditorchange = (e) => {
        //let finalobj = []
        let fetchingtext = ""
        if (this.props.highlightedObjectList.length > 0) {
            this.props.highlightedObjectList.splice(0, this.props.highlightedObjectList.length)
        }

        if (this.props.updatedcontent.length > 0)
            this.props.updatedcontent.shift()
        //alert(JSON.stringify(e.editor.getData()))
        parse(e.editor.getData(), {
            trim: true,
            replace: domNode => {

                if (domNode.name === "span") {

                    let objdata = {}
                    let backgroundcolor = ""
                    let finalcolorname = ""
                    let str_text = domNode.children[0].data
                    let str_style = domNode.attribs.style
                    if (str_text !== "Fetching Transcript" && str_text !== undefined) {
                        if (str_style !== undefined || str_style !== null) {
                            str_style = str_style.split(":")
                            backgroundcolor = str_style[1]
                            debugger;
                            switch (backgroundcolor) {
                                case "#ffa500":
                                    finalcolorname = "orange";
                                    break;
                                case "#ffff8a":
                                    finalcolorname = "yellow";
                                    break;
                                case "#bfd4ff": // blue
                                    finalcolorname = "green";
                                    break;
                                case "#c0c0c0":
                                    finalcolorname = "grey";
                                    break;
                                case "#5897fb":
                                    finalcolorname = "blue";
                                    break;
                                case "#bdd8b2": //light green -- Hint
                                    finalcolorname = "pink";
                                    break;
                                case "#ffc1c1": // Pink -- Error Root cause
                                    finalcolorname = "purple";
                                    break;
                                case "#ffffff":
                                    finalcolorname = "white";
                                    break;
                                case "#74ba56":
                                    finalcolorname = "darkgreen";
                                    break;
                                default:
                                    break;
                            }
                        }
                        // debugger;
                        // let subHTML = e.editor.getData().replace(/<\/?span[^>]*>/g, "").substring(0, e.editor.getData().length)
                        // let mainContentStartIndex = subHTML.indexOf(str_text)
                        // let mainContentEndIndex = mainContentStartIndex + str_text.length

                        // console.log("startindex", mainContentStartIndex)
                        // console.log("end index", mainContentEndIndex)
                        if (str_text !== undefined) {
                            objdata = {
                                "elementClass": "",
                                "focusNodeText": str_text,
                                "highlightedText": str_text,
                                "highlighterColor": finalcolorname,
                                "ignore": false,
                                "note": "",
                                "selectionStart": 0,
                                "selectionEnd": str_text.length,

                            }
                            //debugger;
                            // const isElementPresent = this.props.highlightedObjectList.some((o) => o.highlightedText.trim() === str_text.trim() && o.highlighterColor.trim() === finalcolorname.trim());
                            // //const isElementPresentcolor = this.props.highlightedObjectList.some((o) => o.highlighterColor.trim() === finalcolorname.trim());
                            // if (!isElementPresent) {
                            this.props.highlightedObjectList.push(objdata)
                            // }
                            // else {
                            //     let getdata = this.props.highlightedObjectList.filter(o => o.highlightedText === str_text);
                            //     if (getdata.length > 0) {
                            //         if (getdata[0].highlighterColor !== finalcolorname) {
                            //             let getindex = this.props.highlightedObjectList.findIndex(o => o.highlightedText === str_text);
                            //             this.props.highlightedObjectList[getindex].highlighterColor = finalcolorname
                            //         }
                            //     }

                            // }
                        }
                    }
                    else {
                        fetchingtext = str_text
                    }
                }

            }
        });
        if (this.props.updatedcontent.length > 0)
            this.props.updatedcontent.shift()
        //console.log("test#######################", e.editor.getData())
        if (fetchingtext !== "Fetching Transcript") {
            this.props.updatedcontent.push(e.editor.getData());
        }

        console.log("get updated contentdata", this.props.updatedcontent)
        //}
        //console.log("final highlightedobjects..............", this.props.highlightedObjectList)
    }
    shouldHideHint = 0


    handleswitchtutortostudent = () => {
        this.setState({ istutortostudent: !this.state.istutortostudent }, () => {
            if (this.state.istutortostudent) {
                let data = this.state.editor.getData()
                let data1 = data.replaceAll("Tutor:", "Tutor_Old:");
                let data2 = data1.replaceAll("Student:", "Tutor:");
                let data3 = data2.replaceAll("Tutor_Old:", "Student:");

                this.setState({ contentdata: data3 }, () => {
                    setTimeout(() => {
                        console.log("data......data...........", data3)
                        if (!isNil(this.state.editor)) {
                            this.state.editor.setData(this.state.contentdata)
                        }

                    }, 500);

                })

            }

        })
    }

    render() {
        //let data = this.state.contentdata
        //console.log("contentdata......................", data)
        return (

            <>

                {
                    this.props.isloading ? (<div
                        dangerouslySetInnerHTML={{ __html: this.getHTMLContent() }}
                    ></div>) : (

                        <>

                            {
                                !this.props.isLoading ? (<p className="desc" style={{ fontSize: "13px" }}>
                                    Switch speaker labels?
                                    <Switch size="small"
                                        checked={this.state.istutortostudent}
                                        onChange={(e, c) => this.handleswitchtutortostudent()} />
                                </p>) : null
                            }
                            <div
                                className='html-content'
                            >
                                <div className={`note-highlighter1 show-highlighter`}
                                    style={{ left: "50px" }}
                                >


                                    <div className="highlighter-header">
                                        <div className='highlighter-color-picker'>
                                            <div className='div1 bordercss'>
                                                <div className='orange-highlighter' id='orange' >

                                                </div>
                                                <span className='legend text-center'>New Interaction (click on speaker name)</span>
                                            </div>

                                            <div style={{ display: "flex", paddingTop: "2px", paddingBottom: "2px" }}>
                                                <div style={{ display: "flex", paddingTop: "2px", paddingBottom: "2px", paddingRight: "2px" }}>
                                                    <div >
                                                    </div>
                                                    <div className='legend text-center bordercss'>Diagnosis:</div>
                                                </div>
                                                <div className="div1">
                                                    <div className='yellow-highlighter' id='yellow' >
                                                    </div>
                                                    <div className='legend text-center'>Diagnostics Question</div>
                                                </div>

                                                <div className='div1'>
                                                    <div className='grey-highlighter' id='grey' >

                                                    </div>
                                                    <div className='legend text-center'>Diagnostics Answer</div>
                                                </div>

                                                <div className='div1'>
                                                    <div className='purple-highlighter' id='purple' >

                                                    </div>
                                                    <span className='legend text-center'>Error Root Cause</span>
                                                </div>
                                            </div>
                                            <div style={{ display: "flex", paddingTop: "2px", paddingBottom: "2px" }}>
                                                <div style={{ display: "flex", paddingTop: "2px", paddingBottom: "2px", paddingRight: "2px" }}>
                                                    <div >
                                                    </div>
                                                    <div className='legend text-center bordercss'>Intervention:</div>
                                                </div>
                                                <div className='div1'>
                                                    <div className='pink-highlighter' id='pink' >

                                                    </div>
                                                    <span className='legend text-center'>Hints</span>
                                                </div>
                                                <div className='div1'>
                                                    <div className='lq-highlighter' id='lq' >
                                                    </div>
                                                    <span className='legend text-center'>Leading Question</span>
                                                </div>
                                                <div className="div1">
                                                    <div className='green-highlighter' id='green'>

                                                    </div>
                                                    <div className='legend text-center'>Remedial Instruction</div>
                                                </div>
                                                <div className='div1'>
                                                    <div className='blue-highlighter' id='blue' >

                                                    </div>
                                                    <span className='legend text-center'>Resource</span>
                                                </div>

                                            </div>

                                        </div>

                                    </div>

                                </div>
                                <div style={{ height: '100%' }} className='ckeditorsynopsis'>
                                    <CKEditor

                                        initData={this.state.contentdata}
                                        onInstanceReady={(e) => {
                                            this.setState({ editor: e.editor });
                                            this.state.editor.setData(this.state.contentdata.trim())
                                            var this_instance = document.getElementById(e.editor.id + '_toolbox');
                                            var this_button = this_instance.querySelector('.cke_button__bgcolor');
                                            this_button.title = "Text Highlight Color"
                                        }}
                                        config={{
                                            allowedContent: false,
                                            toolbar: basicCkConfig,
                                            colorButton_colors: 'New Interaction/ffa500, Diagnostics Question/ffff8a,Diagnostics Answer/c0c0c0,Error Root Cause/ffc1c1,Remedial Text/bfd4ff,Leading Question/74ba56, Hints/bdd8b2,Resource/5897fb,No Color/ffffff',
                                            // colorButton_colors: 'New Interaction/ffa500, Diagnostics Question/ffff8a,Remedial Text/bfd4ff,Student Answer/c0c0c0,Resource/5897fb, Hints/bdd8b2,Error Root Cause/ffc1c1,No Color/ffffff',
                                            colorButton_enableAutomatic: false,
                                            colorButton_enableMore: false,
                                            ContextMenu: [],
                                            colorButton_colorsPerRow: 8,
                                            removePlugins: 'liststyle,tableselection,tabletools,tableresize,contextmenu'
                                        }}
                                        onChange={(e) => { this.handleeditorchange(e) }}
                                    />


                                </div></div>
                        </>)
                }

                {this.props.isloeadingforhighlight && (
                    <CircularProgress
                        size={24}
                        sx={{
                            color: "#fdbd17",
                            position: 'absolute',
                            top: '50%',
                            left: '65%',
                            marginTop: '-12px',
                            marginLeft: '-12px',
                        }}
                    />
                )
                }


            </>
        )
    }
}