import React from 'react';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { Col, Row } from 'react-bootstrap';
import { formatAnyProblemAPIResponse, formatInteractionResponse } from '../../common/Functions';
import { getInteractionEvaluation, getTranscriptIdsInteractionEvaluate, getChallengesInteractionEvaluate } from '../../common/API'
import './InteractionEvaluation.scss'
import { withRouter } from 'react-router-dom';
import MathJaxRenderer from '../../components/Common/MathJaxRenderer/MathJaxRenderer';
import { TableHead } from '@mui/material';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import Box from '@mui/material/Box';
import { isNil, isEmpty } from 'lodash';
import Aux from "../../hoc/Wrapper";
import InteractionEvaluationItem from './InteractionEvaluationItem';
import CircularProgress from '@mui/material/CircularProgress';

class InteractionEvaluation extends React.Component {

    mathJaxTimeout = null;
    constructor(props) {
        super(props);
        this.state = {
            latex: '',
            mathml: '',
            isExpand: null,
            equations: [],
            expand: false,
            interactionData: [],
            challengeList: [],
            transcriptList: [],
            selectedChallenge: 0,
            selectedTranscript: 0,
            showNoDataMsg: false,
            aiTutorRes: [],
            modalBodyaiTutorfinal: [],
            eqDataFinal: [],
            loader: false
        };
    }

    componentDidMount() {
        getChallengesInteractionEvaluate().then(res => {
            if (res.data.data !== undefined && res.data.data !== null) {
                this.setState({ challengeList: res.data.data })
            }
        })
    }

    componentDidUpdate() {
        clearTimeout(this.mathJaxTimeout)
        this.mathJaxTimeout = setTimeout(() => {
            if (window.MathJax) {
                try {
                    window.MathJax.typeset();
                } catch (e) {
                    console.log(e)
                }

            }
        }, 200)
    }

    handleAccordionChange = (id, airesult, item) => (_, isExpanded) => {

        this.setState({ isExpand: (isExpanded ? id : null), loader: true }, () => {


            // this.setState({ eqDataFinal: eqData })

            if (!isNil(airesult) && !isEmpty(airesult)) {
                let assumed_content_list = [];
                let stepNo = 0;
                let line_match = 0;
                let isLastStepCorrect = false;
                let correct_version_exists = false;
                let correct_version_line_no = -1;
                let headerContent = null, footerContent = null;
                let correctEq = [];
                let incorrectHints = [];
                let assumed_content = null
                let propagation = 0
                let modalBodyaiTutor = []
                let isChallengeCompleted = false;
                let is_partially_complete = false;
                let subHeaderContent = null;
                let noMatch = false;
                let knowledgeGraphUiJson = null;
                let ui_json
                let substitution_mapkey = null
                let substitution_mapvalue = null
                let finalsubstitutionslist = ""
                for (let result of airesult) {
                    let modalBody = []
                    line_match = result.line_match;

                    stepNo += 1
                    isLastStepCorrect = result.status === 'correct' ? true : false
                    if (isLastStepCorrect === false)
                        correct_version_exists = result.correct_version_exists ? result.correct_version_exists : false
                    if (correct_version_exists) {
                        correct_version_line_no = result.correct_version_line_no;
                    }

                    if (result.status === 'correct') {
                        const assumed_step_feedback = ["Great job advancing to this step."]
                        let ind_assumed_step_feedback = Math.floor(Math.random() * 1);
                        const correct_step_feedback =
                            ["Well done!", "You've nailed it.", "Spot on.", "Fantastic."];
                        let ind_correct_step_feedback = Math.floor(Math.random() * 4);
                        const correct_step_feedback_ending =
                            ["", ""];
                        let ind_correct_step_feedback_ending = Math.floor(Math.random() * 2);


                        correctEq.push(result.eq_correct);



                        let equationImg = undefined


                        if (result.propagation) {
                            propagation = 1;
                        }
                        if (!Number.isInteger(line_match)) {
                            assumed_content = (
                                <div>
                                    <div className='mb-3 d-flex align-items-center'>
                                        {equationImg ?
                                            <img src={equationImg} alt={"Step " + stepNo + " Equation"} />
                                            :
                                            // <MathJaxRenderer math={'$$' + result.inputLatex + '$$'}></MathJaxRenderer>
                                            <div dangerouslySetInnerHTML={{ __html: '$$' + result.inputLatex + '$$' }} />
                                        }
                                    </div>
                                </div>
                            )
                            assumed_content_list.push(assumed_content)
                        }

                        let content = (
                            <Aux>
                                {/* <p>{parse(result.explanation)}</p> */}
                                {<hr></hr>}

                                <p className="mb-0">
                                    {
                                        <div className="green">
                                            {/* <CheckCircleIcon style={{ fill: "green" }} /> */}
                                            {<span><span>{correct_step_feedback[ind_correct_step_feedback]} </span>  <span>{correct_step_feedback_ending[ind_correct_step_feedback_ending]}</span></span>}
                                        </div>
                                    }
                                    <div className='mb-3 d-flex align-items-center'>
                                        {/* <div className='mr-2'>Step : </div> */}
                                        <div>
                                            {equationImg ?
                                                <img src={equationImg} alt={"Step " + stepNo + " Equation"} />
                                                :
                                                // <MathJaxRenderer math={'$$' + result.inputLatex + '$$'}></MathJaxRenderer>
                                                <div dangerouslySetInnerHTML={{ __html: '$$' + result.inputLatex + '$$' }} />
                                            }
                                        </div>
                                    </div>

                                </p>
                                {Number.isInteger(line_match) && assumed_content_list.length > 1 ?
                                    <div>
                                        <div className="grey">
                                            {assumed_step_feedback[ind_assumed_step_feedback]}
                                            {assumed_content_list}
                                        </div>
                                    </div>
                                    : null
                                }
                            </Aux>
                        );
                        content = !Number.isInteger(line_match) ? null : content;
                        assumed_content_list = Number.isInteger(line_match) ? [] : assumed_content_list
                        propagation = Number.isInteger(line_match) ? 0 : propagation
                        headerContent = content;

                        modalBody.push(content);

                    }
                    else if (result.status === 'unrecognized') {
                        const incorrect_step_feedback =
                            ["There is a small mistake in ", "There's a little error in "];
                        let ind_incorrect_step_feedback = Math.floor(Math.random() * 2);
                        const incorrect_step_feedback_ending =
                            [".", ".", "."];
                        let ind_incorrect_step_feedback_ending = Math.floor(Math.random() * 3);


                        const correct_step_feedback =
                            ["Well done!", "You've nailed it.", "Spot on.", "Fantastic."]
                        let ind_correct_step_feedback = Math.floor(Math.random() * 4);
                        const correct_step_feedback_ending =
                            ["", ""];
                        let ind_correct_step_feedback_ending = Math.floor(Math.random() * 2);
                        // result.inputLatex = result.inputLatex.replace('\\stackrel{^}', '\\stackrel{\\text{^}}')
                        result.inputLatex = result.inputLatex.replace(/\\stackrel{\^}/g, '\\stackrel{\\text{^}}');
                        headerContent = result.inputLatex ?
                            <> <hr></hr>
                                <div className='mb-2 d-flex align-items-center'>
                                    <p className={result.eq_status === 'correct' ? 'correctRecognized' : 'incorrectRecognized'}>
                                        <span>

                                            {
                                                result.eq_status === 'correct' ?
                                                    correct_step_feedback[ind_correct_step_feedback] :
                                                    incorrect_step_feedback[ind_incorrect_step_feedback]
                                            }
                                        </span>
                                        <label className="lineNumber"> Equation #{result.line_match}</label>
                                        <span>
                                            {
                                                result.eq_status === 'correct' ?
                                                    correct_step_feedback_ending[ind_correct_step_feedback_ending] :
                                                    incorrect_step_feedback_ending[ind_incorrect_step_feedback_ending]
                                            }
                                        </span>

                                        <br /><span className="aiAnswerNote">Note: Feedback under review to ensure its quality.</span></p>
                                </div>

                                {/* <MathJaxRenderer math={'$$' + result.inputLatex + '$$'}></MathJaxRenderer> */}
                                <div dangerouslySetInnerHTML={{ __html: '$$' + result.inputLatex + '$$'}} />

                            </>

                            : null
                        footerContent = <p className={result.eq_status === 'correct' ? 'correctRecognized' : 'incorrectRecognized'}><div dangerouslySetInnerHTML={{ __html: formatAnyProblemAPIResponse(result.explanation) }} /></p>
                        modalBody.push((
                            <Aux>
                                {headerContent}
                                {footerContent}

                            </Aux>
                        ));
                    }
                    else if (result.status === 'incorrect') {
                        const assumed_step_feedback_incorrect = ["It's evident you've got a good handle on the initial steps. Let's confirm your thought process which led you to the above step.", "Looks like you are doing great with interim steps as you are trying to attempt the above advanced step. Let's confirm your thought process which led you to the above step.", "Your ability to attempt this advanced step shows mastery of the interim steps. Let's review your approach once. Keep up the enthusiasm."]
                        let ind_assumed_step_feedback_incorrect = Math.floor(Math.random() * 3);
                        const assumed_step_propagation_feedback = ["It seems that the following error, in an interim step that you did in your mind, is impacting your result."]
                        // ["Nice effort in attempting this advanced step.  However, it seems that the following error, in an interim step that you did in your mind, is impacting your result.  Let's pinpoint that error to help our understanding."]
                        const incorrect_step_feedback =
                            ["There is a small mistake in", "There's a little error in"];
                        let ind_incorrect_step_feedback = Math.floor(Math.random() * 2);
                        const incorrect_step_feedback_ending =
                            [".", ".", "."];
                        let ind_incorrect_step_feedback_ending = Math.floor(Math.random() * 3);
                        let content = null
                        let contentdatafinal = []

                        var feedbackthumbsup = ""

                        if (result.remedial_text) {
                            for (let i = 0; i <= result.remedial_text.length; i++) {
                                let data = result.remedial_text[i]
                                let data1 = result.remedial_text2[i]
                                let data3 = result.remedial_source[i]
                                let data4 = result.remedial_source_display_name[i]
                                if (data && data !== null && data !== 'null' && data !== "null" && data != "undefined" && data !== "" && data !== '') {
                                    data = data.toString().replace(/^,/, '').replace(/,\s*$/, "");
                                    data = data.replace(".,", ".")
                                }
                                if (data !== undefined) {
                                    if (feedbackthumbsup !== "") {
                                        feedbackthumbsup = feedbackthumbsup + "##" + data.replace(/(<([^>]+)>)/ig, '')
                                    }
                                    else {
                                        feedbackthumbsup = data.replace(/(<([^>]+)>)/ig, '')
                                    }
                                }

                                if (data1 && data1 !== null && data1 !== 'null' && data1 !== "null" && data1 != "undefined" && data1 !== "" && data1 !== '') {
                                    data1 = data1.toString().replace(/^,/, '').replace(/,\s*$/, "");
                                    data1 = data1.replace(".,", ".")
                                }
                                if (data3 && data3 !== null && data3 !== 'null' && data3 !== "null" && data3 != "undefined" && data3 !== "" && data3 !== '') {
                                    data3 = data3.toString().replace(/^,/, '').replace(/,\s*$/, "");
                                    data3 = data3.replace(".,", ".")
                                }
                                if (data4 && data4 !== null && data4 !== 'null' && data4 !== "null" && data4 != "undefined" && data4 !== "" && data4 !== '') {
                                    data4 = data4.toString().replace(/^,/, '').replace(/,\s*$/, "");
                                    data4 = data4.replace(".,", ".")
                                }

                                incorrectHints.push({
                                    remedial: true,
                                    remedial_text: result.remedial_text[i],
                                    remedial_text2: result.remedial_text2[i],
                                    remedial_source: result.remedial_source[i]
                                })
                                let contentdata = (
                                    <Aux>
                                        {/* <hr className="horizontalRuler" /> */}
                                        {
                                            (data && data !== null && data !== 'null' && data !== "null" && data != "undefined" && data !== "" && data !== '' && data !== ',' && data !== ",") ?
                                                <div className="mb-1 mt-3 rdTextcss" dangerouslySetInnerHTML={{ __html: data.toString().replace(/^,/, '').replace(/,\s*$/, "") }}></div>
                                                : null
                                        }
                                        {
                                            (data1 && data1 !== null && data1 !== 'null' && data1 !== "null" && data1 != "undefined" && data1 !== "" && data1 !== '' && data1 !== ",") ?
                                                <div className="mb-1 rdTextcss" dangerouslySetInnerHTML={{ __html: data1.toString().replace(/^,/, '').replace(/,\s*$/, "") }}></div>
                                                : null
                                        }
                                        {
                                            (data3 && data3 !== null && data3 !== 'null' && data3 !== "null" && data3 != "undefined" && data3 !== "" && data3 !== '') ?
                                                <a href={data3}
                                                    target='_blank' rel="noopener noreferrer">
                                                    <div dangerouslySetInnerHTML={{ __html: data4 ? data4.toString().replace(/^,/, '').replace(/,\s*$/, "") : data3.toString().replace(/^,/, '').replace(/,\s*$/, "") }}></div></a>
                                                : null
                                        }

                                    </Aux>
                                );
                                contentdatafinal.push(contentdata)
                            }

                        } else if (result.hint_remedial_text) {
                            let data3 = result.hint_remedial_text
                            if (data3 && data3 !== null && data3 !== 'null' && data3 !== "null" && data3 != "undefined" && data3 !== "" && data3 !== '') {
                                data3 = data3.toString().replace(/^,/, '').replace(/,\s*$/, "");
                            }
                            feedbackthumbsup = data3
                            incorrectHints.push({
                                hint: true,
                                hint_remedial_text: data3
                            })
                            content = (
                                <Aux>
                                    {data3 ? <div>{data3}</div> : null}
                                </Aux>
                            );
                        }
                        else {
                            if (result.symbolsDiff) {
                                feedbackthumbsup = "Equations you entered don’t match expected steps."
                                if (result.symbolsDiff.length > 0) {
                                    content = <>
                                        {content}
                                        <p className="mb-1 mt-3">Equations you entered don’t match expected steps.</p>
                                        <p className="mb-1 mt-3">Unexpected Symbols or Subscripts: <em>{result.symbolsDiff}</em></p>
                                        <p className="mb-2">Expected Symbols or Subscripts: <em>{result.expectedSymbols}</em></p>
                                        <a href='/docs/Ref_Symbols_and_Equations_AP_1999_C_Fr.pdf' target='_blank' rel="noopener noreferrer"><p>Physics Symbols and Equations Reference Sheet</p></a>
                                    </>
                                }
                            }


                        }


                        let equationImg = undefined

                        subHeaderContent = !result.no_match ? <>
                            {correctEq.length === 0 ?
                                <p className='sidebar-heading mb-2'>You made this mistake:</p>
                                :
                                <p className='sidebar-heading mb-2'><span className='text-dark'>Seems like you understood this.</span><br /> Then you made this mistake:</p>}
                        </> : null


                        headerContent = <>
                            {<hr></hr>}
                            {result.no_match ?
                                <>
                                    {!isNil(result.valid) && !result.valid ?
                                        <p>Your line {result.line_match} is not anything I was expecting. Please correct if you mean to enter an equation.</p>
                                        : <p>Line #{result.line_match}:
                                            <br></br><br></br>
                                            {result.explanation}
                                        </p>
                                    }
                                    <div>
                                        <br />
                                        {''}
                                        {equationImg ?
                                            <img src={equationImg} alt={"Step " + stepNo + " Equation"} />
                                            :
                                            // <MathJaxRenderer math={'$$' + result.inputLatex + '$$'}></MathJaxRenderer>
                                            <div dangerouslySetInnerHTML={{ __html: '$$' + result.inputLatex + '$$' }} />

                                        }
                                    </div>
                                    {!isNil(result.hint) && result.hint.length > 0 ?
                                        <>
                                            <p>Hint: <span dangerouslySetInnerHTML={{ __html: result.hint ? result.hint : '' }}></span></p>
                                            <a className='mt-2' href={item.response_aiTutor.result.hint_remedial_source} target='_blank' rel="noopener noreferrer" >
                                                {item.response_aiTutor.result.hint_remedial_resource_display_name ? item.response_aiTutor.result.hint_remedial_resource_display_name : item.response_aiTutor.result.hint_remedial_source}</a>
                                        </>
                                        : null}
                                </>
                                :
                                <>

                                    <div className="red">

                                        <div>
                                            <br />
                                            {'Incorrect Step: '}
                                            {equationImg ?
                                                <img src={equationImg} alt={"Step " + stepNo + " Equation"} />
                                                :
                                                // <MathJaxRenderer math={'$$' + result.inputLatex + '$$'}></MathJaxRenderer>
                                                <div dangerouslySetInnerHTML={{ __html: '$$' + result.inputLatex + '$$' }} />
                                            }
                                        </div>
                                    </div>
                                </>
                            }
                        </>
                        noMatch = result.no_match ? true : false;
                        footerContent = <>
                            <div className="red">
                                {contentdatafinal}

                                {content} <br></br>
                                {Number.isInteger(line_match) && (assumed_content_list.length > 1 || propagation == 1) && !correct_version_exists ?
                                    <div>
                                        <div className="grey">
                                            {propagation == 1 ? assumed_step_propagation_feedback[0] : assumed_step_feedback_incorrect[ind_assumed_step_feedback_incorrect]}
                                            {assumed_content_list}
                                        </div>
                                    </div>
                                    : null
                                }
                            </div>


                        </>
                        assumed_content_list = Number.isInteger(line_match) ? [] : assumed_content_list
                        propagation = Number.isInteger(line_match) ? 0 : propagation

                        modalBody.push((
                            <Aux>
                                {headerContent}
                                {footerContent}
                                {/* {content} */}
                            </Aux>
                        ));
                        // break
                    } else if (result.status === 'error') {

                        headerContent = result.inputLatex ?
                            <div className='mb-2 d-flex align-items-center'>
                                <p>You've made an error at line {result.line_match}</p>
                                <div className='mr-2'>Erroneous Step: </div>

                            </div>
                            : null
                        footerContent = <p>{result.message}</p>
                        modalBody.push((
                            <Aux>
                                {headerContent}
                                {footerContent}
                                {'Try again and let me know if you need help. You got this!'}
                            </Aux>
                        ));
                        break
                    }

                    if (result.is_partially_complete === true) {
                        is_partially_complete = true
                        if (stepNo === item.response_aiTutor.result.length) {
                            modalBody.push((
                                <h5><hr></hr><div className='green'>You've achieved one of the right answers! Keep honing your skills to discover
                                    the second solution. Excellent progress!
                                </div></h5>
                            ));
                        }
                    }
                    if (result.final === true) {
                        isChallengeCompleted = true
                        if (stepNo === item.response_aiTutor.result.length) {
                            modalBody.push((

                                <h5><hr></hr><div className='green'>Problem Completed! It's clear that you've been putting in the effort to understand the concepts.</div></h5>
                            ));
                        }


                    }


                    modalBodyaiTutor.push(modalBody)
                }
                this.setState({ modalBodyaiTutorfinal: modalBodyaiTutor, loader: false })
            }
        })

    }

    handleSelectChallenge = (selected) => {
        if (selected.target.value > 0) {
            this.setState({ selectedChallenge: selected.target.value })

            const reqData = {
                params: {
                    "challenge_id": selected.target.value
                }
            }
            getTranscriptIdsInteractionEvaluate(reqData).then(res => {
                if (!isNil(res.data.data) && !isEmpty(res.data.data)) {
                    this.setState({ transcriptList: res.data.data })
                }
            })
        }
    }

    handleSelectTranscript = (value) => {
        this.setState({ selectedTranscript: value.target.value })
    }

    handleGetInteractionEvaluation = (event) => {

        if (this.state.selectedChallenge > 0 && this.state.selectedTranscript > 0) {
            this.setState({ interactionData: [] }, () => {
                const reqData = {
                    params: {
                        "challenge_id": this.state.selectedChallenge,
                        "transcript_id": this.state.selectedTranscript
                    }
                }

                getInteractionEvaluation(reqData).then(res => {
                    debugger;
                    if (res.data.data !== undefined && res.data.data !== null && res.data.data.length > 0) {
                        this.setState({ interactionData: res.data.data, showNoDataMsg: false })
                    }
                    else {
                        this.setState({ interactionData: [], showNoDataMsg: true })
                    }
                })
            })
        }
        else {
            this.setState({ interactionData: [], showNoDataMsg: true })
        }


    }

    render() {
        return (
            <>
                {
                    this.state.loader && (
                        <CircularProgress
                            size={24}
                            sx={{
                                color: "#164b99",
                                position: 'absolute',
                                top: '50%',
                                left: '50%',
                                marginTop: '-12px',
                                marginLeft: '-12px',
                            }}
                        />
                    )
                }
                <div className='container-fluid'>
                    <div className='container bg-white-with-shadow my-3 my-md-5'>
                        <div className='interactionPageHeader'>
                            <div className='pageHeader'>
                                Evaluate - aiPlato vs Other Platforms
                            </div>
                            <div className='challengeddl'>
                                <label style={{ fontWeight: 'bold', marginRight: '10px' }}>Challenge : </label>
                                <select
                                    style={{ height: "45px", marginRight: '20px' }}
                                    width="600px"
                                    name='SelectChallenge'
                                    id='challenge'
                                    value={this.state.selectedChallenge}
                                    onChange={this.handleSelectChallenge} >
                                    <option key={-1} value="0"> Select Challenge</option>
                                    {this.state.challengeList !== undefined ?
                                        this.state.challengeList.map((item) =>
                                            <option value={item.challenge_id}>{item.challenge_name}</option>
                                        )
                                        : null}
                                </select>
                                <label style={{ fontWeight: 'bold', marginRight: '10px' }}>Transcript Number : </label>
                                <select
                                    width="600px"
                                    style={{ height: "45px", marginRight: '50px' }}
                                    name='SelectTranscript'
                                    id='transcript'
                                    value={this.state.selectedTranscript}
                                    onChange={this.handleSelectTranscript} >
                                    <option key={-1} value="0"> Select Transcript</option>
                                    {this.state.transcriptList.map((item, index) =>
                                        <option key={index} value={item}>{item}</option>
                                    )}
                                </select>
                                <button style={{ width: '100px', height: "45px", marginRight: '20px' }} onClick={(e) => this.handleGetInteractionEvaluation(e)}>Filter</button>
                            </div>
                        </div>
                        <div className="testTitleDiv">
                            <div style={{ margin: '30px', width: '90%' }} className='psAcc'>
                                {
                                    this.state.interactionData !== undefined && this.state.interactionData !== null && this.state.interactionData.length > 0 ?
                                        this.state.interactionData.map(item => {
                                            let inputType = formatAnyProblemAPIResponse(item.student_input)
                                            let gpt4Res = item.response_gpt4o !== undefined && item.response_gpt4o !== null ? formatInteractionResponse(item.response_gpt4o) : null
                                            let geminiRes = item.response_gemini !== undefined && item.response_gemini !== null ? formatInteractionResponse(item.response_gemini) : null
                                            let claudeRes = item.response_claude !== undefined && item.response_claude !== null ? formatInteractionResponse(item.response_claude) : null

                                            let eqData = []
                                            if (item.equations !== undefined && item.equations !== null) {
                                                debugger;
                                                for (let index = 0; index < item.equations.length; index++) {
                                                    eqData.push(item.equations[index])
                                                }
                                            }
                                            let data1 = []
                                            if (eqData.length > 0) {
                                                eqData.forEach(eq => {
                                                    debugger;
                                                    let displayEq = formatAnyProblemAPIResponse(eq)
                                                    data1.push(<div dangerouslySetInnerHTML={{ __html: displayEq }}></div>)
                                                });
                                            }

                                            return (
                                                <>
                                                    <InteractionEvaluationItem
                                                        item={item}
                                                        handleAccordionChange={this.handleAccordionChange(item.interaction_id, item.response_aiTutor.result, item)}
                                                        modalBodyaiTutorfinal={this.state.modalBodyaiTutorfinal}
                                                        gpt4Res={gpt4Res}
                                                        geminiRes={geminiRes}
                                                        claudeRes={claudeRes}
                                                        inputType={inputType}
                                                        isExpand={this.state.isExpand}
                                                        eqData={eqData}
                                                        eqfinaldata={data1}
                                                    ></InteractionEvaluationItem>
                                                </>
                                            )
                                        })
                                        :
                                        this.state.showNoDataMsg ? <div style={{ margin: '30px', fontWeight: 'bold' }}>No Interaction Evaluation Data Found for this Challenge </div> : null
                                }
                            </div>
                        </div>

                    </div>
                </div>
            </>

        )
    }
}

export default withRouter(React.memo(InteractionEvaluation))