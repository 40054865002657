import React from 'react';
import './CurriculumContentCard.scss';
import { Link } from 'react-router-dom';
import Ratings from '../../Common/Ratings/Ratings';
import RatingsHover from '../../Common/Ratings/RatingsHover';
import arrowImage from '../../../assets/images/icon_arrow.svg';
import brainIcon from '../../../assets/icons/brain_icon.ico';
//import Card from 'react-bootstrap/Card';
// import CardGroup from 'react-bootstrap/CardGroup';


import GridList from "@material-ui/core/GridList";
import {
    Card,
    CardContent,
    CardMedia,
    Typography,

} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { useHistory } from 'react-router-dom';


const useStyles = makeStyles((theme) => ({
    root: {

        "& > *": {
            margin: theme.spacing(3),
            width: "25ch"
        }
    },
    gridList: {
        width: "100%",
        height: "auto",
        position: "relative",
        overflowY: "unset",
        marginBottom: "10px !important",
        columnGap: "50px"
        //flexWrap:"nowrap",
        //borderBottom: "1px solid rgb(209, 209, 209)"
    },
    card: {
        minWidth: "300px",
        height: "300px",
        margin: "10px",
        borderRadius: "10px",
        position: "relative",
        overflowY: "unset",
        overflowX: "unset",
    },
    contentimage: {
        padding: "5px",
        borderRadius: "12px",
        width: "auto",
        height: "200px",
        maxWidth: "100%",
        margin: "0px auto",
        objectFit: "contain",
    },
    contentText:{
        position:"absolute",
        bottom: "0px",
        width: "100%",
    },
    font14: {
        fontSize: "14px !important",
        height:'44px',
    }


}));

export default (props) => {

    const history = useHistory();
    const classes = useStyles();

    const handlecardevent = (id, flag) => {
        history.push({
            pathname: `/curriculum-content/${id}`,
            state: props.linkStateObj,
            flag: flag
        })
    }


    return (
        <div className='curriculum-content-card border-bottom-last'>
            <h4 className='titledata'>{props.title}</h4>
            <div className='content-card'>
                <GridList cellHeight={"auto"} className={classes.gridList}>
                    {props.data.map((content) => (
                        <Link to={{
                            pathname: `/curriculum-content/${content.id}`,
                            state: props.linkStateObj,
                            flag: content.flag
                        }}>
                            <Card className={classes.card}
                            //onClick={() => handlecardevent(content.id, content.flag)}
                            >
                                <CardMedia
                                    component="img"
                                    image={content.thumbnail}
                                    className={classes.contentimage}
                                />
                                <CardContent className={classes.contentText}>
                                    <Typography className={classes.font14}>
                                        {content.type} {content.source ? <> | Source: {content.source} </> : ''}
                                    </Typography>
                                    <div className="parent rating-hover">
                                        <div>
                                            <Ratings stars={content.rating} className='ratings main-video-rating mr-2' readOnly />
                                            <img src={arrowImage} alt="" />
                                            <RatingsHover
                                                explanationStars={content.average_explanation_rating || 0}
                                                presentationStars={content.average_presentation_rating || 0}
                                                paceRange={content.average_pace_review} />
                                        </div>
                                        <div className="interactive-video-icon">
                                            {content.is_interactive ?
                                                <img alt="" title="Interactive" src={brainIcon} style={{ width: "30px", height: "26px" }}></img>
                                                : null
                                            }
                                        </div>
                                    </div>
                                </CardContent>
                            </Card>
                        </Link>
                    ))}
                </GridList>
            </div>
        </div >
    );
}