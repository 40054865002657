import React, { Component } from 'react';
import './TryThisPin.scss'
import pinIcon from '../../../assets/icons/pin-icon.svg'

class TryThisPin extends Component {
    state = {}
    render() {
        return (
            <>
                {this.props.children}
                {this.props.showPin ?
                    <div className="try-this-pin-container"
                        style={this.props.style}>
                        <img  src={pinIcon} alt="pin" width="24" height="24" />
                        <span className='try-this-text'>Try this</span>
                    </div> : null}
            </>
        )
    }
}

export default TryThisPin;