import React from 'react'
import './ProgressImage.scss'
import { withRouter } from 'react-router-dom';
import imageMapResize from 'image-map-resizer'
import { getRoutes } from '../../../common/Functions';

class ProgressImage extends React.Component {

    componentDidMount() {
        imageMapResize()
    }

    render() {
        return (
            <div className="progress-image">
                <label>{this.props.title}</label>
                <img className="responsiv-img" alt="Progress" src={this.props.image} usemap="#links" />
                <map name="links">
                    <area alt="Newton's 1st Law"
                        onClick={(e) => this.props.openContentOptionsModal(e, 2, { prevPath: getRoutes(this.props.prevPath, window.location.pathname), prevNode: getRoutes(this.props.prevNode, this.props.currentNodeTitle) })}
                        coords="256,72,42" shape="circle" />
                    <area alt="Newton's 2nd Law"
                        onClick={(e) => this.props.openContentOptionsModal(e, 3, { prevPath: getRoutes(this.props.prevPath, window.location.pathname), prevNode: getRoutes(this.props.prevNode, this.props.currentNodeTitle) })}
                        coords="434,158,68" shape="circle" />
                    <area alt="Newton's 3rd Law"
                        onClick={(e) => this.props.openContentOptionsModal(e, 8, { prevPath: getRoutes(this.props.prevPath, window.location.pathname), prevNode: getRoutes(this.props.prevNode, this.props.currentNodeTitle) })}
                        coords="394,350,61" shape="circle" />
                </map>
                <div className='sampleDataContainer'>
                    <span>* SAMPLE DATA</span>
                </div>
            </div>
        )
    }
}

export default withRouter(ProgressImage)