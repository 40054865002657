import React, { useEffect, useState } from 'react';
import './Homework.scss';
import { Col, Row, Modal, Container } from 'react-bootstrap';
import {
    imgURL, getHomeworkChallenges, DeleteHomeworkchallenge, edithomeworkproblems, studentSubscription
} from '../../common/API'
import { getCurrentUserId, getCurrentUserRole, showFullApp, User_Role, Problem_source, getCurrentUserEmail, isDemoUser, Previous_Node_Name,getMenuSelectedItem } from '../../common/Functions';
import { ToastContainer, toast } from 'react-toastify';
import Box from '@mui/material/Box';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import dayjs from 'dayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import Stack from '@mui/material/Stack';
import TextField from '@mui/material/TextField';
import Select from 'react-select';
import {
    Card,
    CardContent,
    CardMedia,
} from "@material-ui/core";
import Dropdown from "react-bootstrap/Dropdown";
import { Link } from 'react-router-dom';
import { makeStyles } from "@material-ui/core/styles";
import ImageList from "@material-ui/core/ImageList";
import CircularProgress from '@mui/material/CircularProgress';
import homeworkContentImg from '../../assets/images/home-img-solution.png';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Cookies from 'universal-cookie';

const useStyles = makeStyles((theme) => ({
    root: {
        "& > *": {
            margin: theme.spacing(3),
            width: "25ch"
        }
    },
    gridList: {
        display: "inline-flex",
        height: "auto",
        position: "relative",
        overflowY: "unset",
        marginBottom: "10px !important",
        columnGap: "50px",
        //borderBottom: "1px solid rgb(209, 209, 209)"
        width: "25.33%",
        // '@media (max-width: 1280px)': {
        //     width: '33.33%'
        //   },
        '@media (max-width: 992px)': {
            width: '40%'
        },
        '@media (max-width: 767px)': {
            width: '100%'
        },
    },
    card: {
        // minWidth: "300px",
        // height: "370px",
        height: "275px !important",
        width: "100% !important",
        display: "flex",
        flexDirection: "column",
        // justifyContent: "flex-end",
        margin: "15px",
        borderRadius: "10px",
        position: "relative",
        overflowY: "unset",
        overflowX: "unset",
        backgroundColor: "#fff"
    },
    cardstudent: {
        // minWidth: "300px",
        // height: "370px",
        margin: "15px",
        borderRadius: "10px",
        //position: "relative",
        overflowY: "unset",
        overflowX: "unset",
        backgroundColor: "#fff"
    },
    carddisabled: {
        // minWidth: "300px",
        // height: "340px",
        margin: "15px",
        borderRadius: "10px",
        //position: "relative",
        overflowY: "unset",
        overflowX: "unset",
        backgroundColor: "#f1f1f1"
    },


    contentimage: {
        padding: "5px",
        borderRadius: "12px",
        maxWidth: "100%",
        height: "100px",
        // width:"300px",
        // maxWidth:"305px",

        margin: "0 auto",
        objectFit: "contain",
    },
    font16: {
        fontSize: "16px !important",
    },
    datepickercls: {
        marginTop: "0px !important"
    },
    contentclass: {
        padding: "10px !important"
    }

}));


const HomeWorkForm = (props) => {
    const cookies = new Cookies()
    const [userId, setUserId] = useState(0)
    const [userrole, setuserrole] = useState("")
    const classes = useStyles()
    const [loading, setloading] = useState(false)
    const [homeworkList, sethomeworklist] = useState([])
    //   const [pasthomeworkList, setpasthomeworklist] = useState([])
    const [homeworkmodalopen, sethomeworkmodalopen] = React.useState(false);
    const [expanded, setExpanded] = useState(null)
    const [selectedchallengetitleforhomework, setselectedchallengetitleforhomework] = React.useState("");
    const [selectedchallengeidforhomework, setselectedchallengeidforhomework] = React.useState(null);
    const [valuestart, setValuestart] = React.useState(new Date());
    const [valueend, setValueend] = React.useState(new Date());
    const [enderror, setEndError] = useState("");
    const [title, setTitle] = useState("");
    const [graded, setGraded] = useState(true);
    const [description, setdescription] = useState("");
    const [assprofessor, setassprofessor] = useState(null);
    const [instituteName, setInstituteName] = useState("");
    const [demoUserEmail, setDemoUserEmail] = useState("");
    const [unique_id, setunique_id] = useState(null)
    const [disabledChallengeMessage, setDisabledChallengeMessage] = React.useState(false)
    // const [frqChallenge, setFrqChallenge] = useState([])
    // const [nonFrqChallenge, setNonFrqChallenge] = useState([])


    useEffect(() => {
        setloading(true)
        setUserId(getCurrentUserId())
        setuserrole(getCurrentUserRole())
        
        const reqData = {
            params: {
                user_id: getCurrentUserId(),
                problem_source: props.problem_source,
                course_id: getMenuSelectedItem()
            }
        }
        getHomeworkChallenges(reqData).then(res => {
            if (res.data !== undefined && res.data !== null && res.data.length > 0) {
                sethomeworklist(res.data)
            }
            setloading(false)
        }).catch(err => {
            console.error(err.message);
            setloading(false)
        })

        if (isDemoUser() !== "true") {
            setDemoUserEmail(getCurrentUserEmail())
        }
    }, [])

    const handleAccordionChange = id => (_, isExpanded) => {
        setExpanded(isExpanded ? id : null);
    }

    const formatDate = (date) => {
        const formattedDate = new Date(date).toLocaleDateString('en-US', {
            day: '2-digit',
            month: 'long',
            year: 'numeric',
        });

        return formattedDate
    }

    const handleclosehomeworkmodal = () => {
        sethomeworkmodalopen(false)
    }

    const handleopenhomeworkmodal = (e, id, title, startdate, enddate, desc, grade, asProfId, unique_id) => {
        sethomeworkmodalopen(true)
        setselectedchallengetitleforhomework(title)
        setselectedchallengeidforhomework(id)
        setValuestart(new Date(startdate))
        setValueend(new Date(enddate))
        setTitle(title)
        setGraded(grade)
        setdescription(desc)
        setassprofessor(asProfId)
        setunique_id(unique_id)
    }

    const handleChangestart = (newValue) => {
        setValuestart(new Date(newValue));
    }

    const handleChangeend = (newValue) => {
        setValueend(new Date(newValue));
        const today = dayjs(new Date(newValue));
        const pastDate = dayjs(new Date(valuestart));
        if (pastDate.isAfter(today)) {
            return setEndError("End date must be greater then start date!");
        }
        else {
            setEndError("");
        }
    };
    const onKeyDown = (e) => {
        e.preventDefault();
    };

    const handleTitleChange = (e) => {
        setTitle(e.target.value)
    }

    const handleGradedChangecheckbox = (e) => {
        setGraded(e.target.checked)
    }

    const handledescriptionchange = (e) => {
        setdescription(e.target.value)
    }

    const handleasspro = (e) => {
        setassprofessor(e.target.value)
    }

    const handlesavehomeworkdata = (e) => {
        const reqData = {
            user_id: getCurrentUserId(),
            challengeId: selectedchallengeidforhomework,
            start_date: dayjs(new Date(valuestart)),
            end_date: dayjs(new Date(valueend)),
            title: title,
            isgraded: graded,
            description: description,
            ass_user_id: assprofessor,
            unique_id: unique_id
        }

        edithomeworkproblems(reqData, props.problem_source).then(res => {
            if (res.status === 200) {
                sethomeworkmodalopen(false)
                
                const reqData = {
                    params: {
                        user_id: getCurrentUserId(),
                        problem_source: props.problem_source,
                        course_id: getMenuSelectedItem()
                    }
                }
                getHomeworkChallenges(reqData).then(res => {
                    sethomeworklist(res.data)
                })
            }
        })
    }

    const handleremovefromhomework = (index, challengeId, id) => {

        let form_data = new FormData();
        form_data.append("challenge_id", challengeId);
        form_data.append("id", id);

        DeleteHomeworkchallenge(form_data, props.problem_source).then(res => {
            if (res.status === 200) {
                
                const reqData = {
                    params: {
                        user_id: getCurrentUserId(),
                        problem_source: props.problem_source,
                        course_id: getMenuSelectedItem()
                    }
                }
                getHomeworkChallenges(reqData).then(res => {
                    sethomeworklist(res.data)
                })
            }
        })
    }

    const CustomToggle = React.forwardRef(({ children, onClick }, ref) => (
        <a style={{ boxShadow: "none", textDecoration: 'none' }}
            href="#" box
            ref={ref}
            onClick={e => {
                e.preventDefault();
                onClick(e);
            }}
        >
            {children}
            <span className="threedots" />
        </a>
    ))

    const getColor = (complexity) => {
        // debugger;
        if (complexity == "Medium")
            return 'yellow'
        else if (complexity == "Easy")
            return "green"
        else
            return 'orange';
    }

    const handleInstituteNameChange = (e) => {
        setInstituteName(e.target.value)
    }

    const handleDemoUserEmailChange = (e) => {
        setDemoUserEmail(e.target.value)
    }

    const handleSaveSubscriptionDetails = () => {
        const reqData = {
            user_id: getCurrentUserId(),
            action_type: 'homeworkproblems',
            institute_name: instituteName,
            user_email: demoUserEmail,
            challenge_content_id: "c0"
        }

        try {
            studentSubscription(reqData).then(res => {
                if (res.status === 200) {
                    setInstituteName("")
                    setDemoUserEmail("")
                    toast.success("Details captured successfully.", {
                        position: toast.POSITION.BOTTOM_RIGHT
                    });
                }
            })
        }
        catch (e) {
            console.log(e.message)
            toast.error("Some error occurred.", {
                position: toast.POSITION.BOTTOM_RIGHT
            });
        }
    }

    const cardclickHandler = (e, path) => {
        if (path === "") {
            setDisabledChallengeMessage(true)
        }
        else {
            setDisabledChallengeMessage(false)
        }
    }
    const closeDialogHandler = () => {
        setDisabledChallengeMessage(false)
    }
    return (



        (isDemoUser() === "true" && props.problem_source === Problem_source.homework) ?
            <>
                <div>
                    <Container>
                        <Row>
                            <Col sx={12} >
                                <p className='text-center pt-4' dangerouslySetInnerHTML={{ __html: `For institutional students only - Please contact us for more information.` }}></p>
                                <div style={{ width: '50%', left: '35%', position: 'absolute' }}>
                                    <label style={{ verticalAlign: 'center', float: 'left', marginRight: '10px', fontSize: "14px" }}>Institute Name</label>
                                    <input style={{ float: 'left', width: '50%' }} class="form-control bg-yellow" value={instituteName} onChange={handleInstituteNameChange}></input>
                                </div>
                                <div style={{ marginTop: '45px', float: 'left', width: '50%', left: '35%', position: 'absolute' }}>
                                    <label style={{ verticalAlign: 'center', float: 'left', marginRight: '68px', fontSize: "14px" }}>Email</label>
                                    <input type='email' style={{ float: 'left', width: '50%' }} class="form-control bg-yellow" value={demoUserEmail} onChange={handleDemoUserEmailChange}></input>
                                </div>
                                <div style={{ marginTop: '100px', marginBottom: '20px', width: '50%', left: '49%', position: 'absolute' }}>
                                    <button onClick={handleSaveSubscriptionDetails}> Submit </button>
                                </div>
                                <img style={{ marginTop: '180px' }} alt='' src={homeworkContentImg} className='w-100' />
                            </Col>
                        </Row>
                    </Container>
                    <ToastContainer />
                </div>
            </>
            :
            (!showFullApp() && props.problem_source === Problem_source.homework) ?
                <>
                    {

                        (<div>
                            <Container>
                                <Row>
                                    <Col sx={12} >
                                        <p className='text-center pt-4'>For institutional students only - Please contact us for more information.</p>
                                        <div style={{ width: '50%', left: '35%', position: 'absolute' }}>
                                            <label style={{ verticalAlign: 'center', float: 'left', marginRight: '10px', fontSize: "14px" }}>Institute Name</label>
                                            <input style={{ float: 'left', width: '50%' }} class="form-control bg-yellow" value={instituteName} onChange={handleInstituteNameChange}></input>
                                        </div>
                                        <div style={{ marginTop: '100px', marginBottom: '20px', width: '50%', left: '49%', position: 'absolute' }}>
                                            <button onClick={handleSaveSubscriptionDetails}> Submit </button>
                                        </div>

                                        <img style={{ marginTop: '180px' }} alt='' src={homeworkContentImg} className='w-100' />
                                    </Col>
                                </Row>
                            </Container>

                            <ToastContainer />
                        </div>)}
                </>
                :
                <>
                    <Dialog
                        hideBackdrop={false}
                        open={disabledChallengeMessage}
                        onClose={closeDialogHandler}
                        aria-labelledby="alert-dialog-title"
                        aria-describedby="alert-dialog-description"
                    >
                        <DialogTitle id="alert-dialog-title">
                            Alert
                        </DialogTitle>
                        <DialogContent>
                            <DialogContentText id="alert-dialog-description">
                                Looks like you're jumping ahead. Complete the earlier parts to access this!
                            </DialogContentText>
                        </DialogContent>
                        <DialogActions>

                            <button className="dragLabelcancel leftmargin" onClick={() => closeDialogHandler()}>
                                Ok
                            </button>
                        </DialogActions>
                    </Dialog>
                    <Modal
                        show={homeworkmodalopen}
                        onHide={handleclosehomeworkmodal}
                        aria-labelledby="contained-modal-title-vcenter"
                        centered
                    >
                        <Modal.Header closeButton>
                            <Modal.Title id="contained-modal-title-vcenter">
                                {selectedchallengetitleforhomework}
                            </Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                            <LocalizationProvider dateAdapter={AdapterDayjs}>
                                <Stack spacing={2} style={{ width: "200px" }}>
                                    <label style={{ fontSize: "16px" }}>Start Date</label>
                                    <DatePicker
                                        value={valuestart}
                                        // ampm={false}
                                        onChange={handleChangestart}
                                        renderInput={(params) => <TextField {...params} />} />
                                    <label c style={{ fontSize: "16px" }}>End Date</label>
                                    <DatePicker

                                        // ampm={false}
                                        value={valueend}
                                        onChange={handleChangeend}
                                        renderInput={(params) => <TextField onKeyDown={onKeyDown} {...params} error={!!enderror}
                                            helperText={enderror} />}
                                    />
                                </Stack>
                            </LocalizationProvider>
                            <label style={{ marginTop: "25px", fontSize: "16px" }}>Title</label>
                            <input class="form-control bg-yellow" value={title} onChange={handleTitleChange}></input>
                            <label style={{ marginTop: "25px", fontSize: "16px" }}>Description</label>
                            <textarea
                                class="form-control bg-yellow"
                                rows="4"
                                value={description}
                                placeholder='Enter Description...'
                                style={{ width: "400px" }}
                                onChange={handledescriptionchange}
                            />
                            <label class={classes.Checkbox} style={{ marginTop: "25px", fontSize: "16px" }}>Graded </label>
                            <input style={{ marginLeft: '10px', display: 'inline' }} checked={graded} type="checkbox" onChange={(e) => handleGradedChangecheckbox(e)} />
                            <br />


                        </Modal.Body>
                        <Modal.Footer>
                            <button onClick={handlesavehomeworkdata}> Save </button>
                        </Modal.Footer>
                    </Modal>
                    <div className='realtimeQa'>
                        <div className='container-fluid'>
                            <div
                                className="container bg-white-with-shadow my-3 my-md-5">
                                <Box sx='box' className="qTabs">
                                    <div>
                                        {homeworkList !== undefined && homeworkList !== null && homeworkList.length > 0 ?
                                            homeworkList.map((homework, index) => {
                                                let duedate = formatDate(homework.end_date)
                                                let is_graded = homework.isgraded ? "Graded" : "Non Graded";
                                                let title = homework.title
                                                let homeworkwiseattemptedusercount = homework.homeworkwiseattemptedusercount
                                                let totalPoints = 0
                                                let nonFRQSectionPoints = 0
                                                let frqSectionPoints = 0

                                                if (duedate !== "") {
                                                    title = title // + " - " + duedate
                                                }
                                                totalPoints = homework.totalPoints
                                                nonFRQSectionPoints = homework.nonFRQSectionPoints
                                                frqSectionPoints = homework.frqSectionPoints

                                                return (
                                                    <div className='psAcc' id={homework.title}>
                                                        <Accordion expanded={expanded === index} onChange={handleAccordionChange(index)}>
                                                            <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel1a-content" id="panel1a-header">
                                                                <Typography>
                                                                    <Row>
                                                                        <Col className={`${getCurrentUserRole() === User_Role.Professor ? 'col-6 col-md-6' : 'col-10 col-md-10'}`}>
                                                                            <p style={{ float: 'left' }} className='sessionNameTextH pt-2'>
                                                                                {title}
                                                                            </p>
                                                                        </Col>
                                                                        {
                                                                            getCurrentUserRole() === User_Role.Professor ? (
                                                                                <Col className='col-4 col-md-4'>
                                                                                    <p style={{ float: 'left' }} className='sessionNameTextmedium pt-2'>
                                                                                        Attempted User Count: {homeworkwiseattemptedusercount}</p>
                                                                                </Col>
                                                                            ) : null
                                                                        }
                                                                        <Col className={`${getCurrentUserRole() === User_Role.Professor ? 'col-2 col-md-2' : 'col-2 col-md-2'}`}>
                                                                            <div className='sessionNameTextmedium'>
                                                                                {is_graded}
                                                                            </div>
                                                                            {
                                                                                getCurrentUserRole() === User_Role.Student && homework.isgraded ? (<div className='sessionNameTextsmall'>
                                                                                    Points: {`${parseInt(homework.isoneofchallenge_attepmted) === 1 ? totalPoints : "-"}`}
                                                                                </div>) : null
                                                                            }

                                                                        </Col>
                                                                    </Row>
                                                                </Typography>
                                                            </AccordionSummary>
                                                            <AccordionDetails>
                                                                <Typography>
                                                                    <div className="challengeTopich border-bottom-last">
                                                                        <div className='questions'>
                                                                            <div style={{ marginLeft: "5px", marginBottom: '10px' }} class="questionfontsmultih" dangerouslySetInnerHTML={{ __html: homework.description }}></div>

                                                                            <>
                                                                                {
                                                                                    homework.childrenNonFRQ !== undefined && homework.childrenNonFRQ !== null && homework.childrenNonFRQ.length > 0 ?
                                                                                        (
                                                                                            <div>
                                                                                                {
                                                                                                    props.problem_source !== Problem_source.homework ? (
                                                                                                        <Row className='subheaderQuestions'>
                                                                                                            <Col className='col-10 col-md-10 subheadertextQtype' >
                                                                                                                {
                                                                                                                    props.problem_source !== Problem_source.homework ?
                                                                                                                        ("Multi Select Questions (MCQs) / (MSQs)") : null
                                                                                                                }

                                                                                                            </Col>
                                                                                                            <Col className='col-2 col-md-2' style={{ textAlign: "end" }}>
                                                                                                                {
                                                                                                                    getCurrentUserRole() === User_Role.Student && homework.isgraded ?
                                                                                                                        (
                                                                                                                            <>
                                                                                                                                Points: {`${parseInt(homework.isoneofchallenge_attepmted) === 1 ? nonFRQSectionPoints : "-"}`}

                                                                                                                            </>
                                                                                                                        ) : null
                                                                                                                }

                                                                                                            </Col>
                                                                                                        </Row>) : <Row className='subheaderQuestions'></Row>}
                                                                                                {
                                                                                                    homework.childrenNonFRQ.map((challenge, counter) => {

                                                                                                        let pathname = `/answerboardhow/c${challenge.challenge_id}/${challenge.id}`;
                                                                                                        let previouspath = `/homeworkproblems`
                                                                                                        let previousnode = "Homework Problems"

                                                                                                        if (props.problem_source === Problem_source.homework) {
                                                                                                            if (userrole !== User_Role.Student && challenge.type !== "FRQ") {
                                                                                                                pathname = `/studentlistnonfrq/${challenge.challenge_id}/${challenge.id}/${props.problem_source}`
                                                                                                            }
                                                                                                            else if (userrole === User_Role.Student) {
                                                                                                                pathname = `/answerboardhow/c${challenge.challenge_id}/${challenge.id}`;
                                                                                                            }
                                                                                                            else if (userrole !== User_Role.Student && challenge.type === "FRQ") {
                                                                                                                pathname = `/studentlist/${challenge.challenge_id}/${challenge.id}/${props.problem_source}`
                                                                                                            }

                                                                                                        }
                                                                                                        else {
                                                                                                            if (userrole === User_Role.Student) {
                                                                                                                pathname = `/answerboardpt/c${challenge.challenge_id}/${challenge.id}`;
                                                                                                            }
                                                                                                            else {
                                                                                                                pathname = `/answerboardpt/c${challenge.challenge_id}/${challenge.id}`;
                                                                                                            }
                                                                                                            previouspath = "/practicetest"
                                                                                                            previousnode = "Practice Test"
                                                                                                        }
                                                                                                        // if (challenge.challenge_disabled === true) {
                                                                                                        //     if (userrole === User_Role.Student) {
                                                                                                        //         pathname = ""
                                                                                                        //         //cardclassname = classes.cardstudent 
                                                                                                        //     }
                                                                                                        // }
                                                                                                        return (
                                                                                                            <ImageList className={classes.gridList} id={challenge.challenge_id}>
                                                                                                                <Card className={classes.card} style={{ position: 'relative' }}>
                                                                                                                    <Link
                                                                                                                        onClick={(e) => cardclickHandler(e, pathname)}
                                                                                                                        to={{
                                                                                                                            pathname: pathname,
                                                                                                                            state: { prevPath: [previouspath], prevNode: [previousnode], HomeworkId: [`${challenge.id}`], problemSource: [`${props.problem_source}`] }
                                                                                                                        }}
                                                                                                                    >
                                                                                                                        <CardContent className={classes.contentclass}>
                                                                                                                            <Typography className='ch-cardstitle'>

                                                                                                                                <div style={{ fontSize: "12px", boxShadow: "none", textDecoration: 'none', fontWeight: "600" }}>
                                                                                                                                    <span className="ch-title"> {challenge.challenge_display}</span>
                                                                                                                                </div>

                                                                                                                                <div>
                                                                                                                                    {
                                                                                                                                        (userrole !== User_Role.Student && userId !== User_Role.Tutor) ?
                                                                                                                                            (<Dropdown>
                                                                                                                                                <Dropdown.Toggle as={CustomToggle} />
                                                                                                                                                <Dropdown.Menu size="sm" title="" >
                                                                                                                                                    <>
                                                                                                                                                        {
                                                                                                                                                            props.problem_source === Problem_source.homework ? (<Dropdown.Item>
                                                                                                                                                                <Link
                                                                                                                                                                    to={{
                                                                                                                                                                        pathname: `/answerboardhow/c${challenge.challenge_id}/${challenge.id}`,
                                                                                                                                                                        state: { prevPath: ['/homeworkproblems'], prevNode: ['Homework Problems'], HomeworkId: [`${challenge.id}`] }
                                                                                                                                                                    }}
                                                                                                                                                                    style={{ fontSize: "12px", boxShadow: "none", textDecoration: 'none' }}>
                                                                                                                                                                    <i class="fa fa-folder-open-o" style={{ fontSize: "12px", boxShadow: "none", textDecoration: 'none', }}></i> Access Problem
                                                                                                                                                                </Link>
                                                                                                                                                            </Dropdown.Item>) : null
                                                                                                                                                        }
                                                                                                                                                        <Dropdown.Item>
                                                                                                                                                            <Link to='#' onClick={(e) => handleopenhomeworkmodal(e, challenge.challenge_id, challenge.title, challenge.start_date, challenge.end_date, challenge.description, challenge.isgraded, challenge.assistant_user_id, challenge.unique_id)}
                                                                                                                                                                style={{ fontSize: "12px", boxShadow: "none", textDecoration: 'none' }}>
                                                                                                                                                                <i class="fa fa-edit" style={{ fontSize: "12px", boxShadow: "none", textDecoration: 'none', }}></i>
                                                                                                                                                                {
                                                                                                                                                                    props.problem_source === Problem_source.apptesthomework ? (" Edit Practice Test") : (" Edit Homework")
                                                                                                                                                                }
                                                                                                                                                            </Link>
                                                                                                                                                        </Dropdown.Item>
                                                                                                                                                        <Dropdown.Item>
                                                                                                                                                            <Link to='#' onClick={(e) => handleremovefromhomework(index, challenge.challenge_id, challenge.id)}
                                                                                                                                                                style={{ fontSize: "12px", boxShadow: "none", textDecoration: 'none' }}>
                                                                                                                                                                <i class="fa fa-folder-open-o" style={{ fontSize: "12px", boxShadow: "none", textDecoration: 'none', }}></i>
                                                                                                                                                                {
                                                                                                                                                                    props.problem_source === Problem_source.apptesthomework ? (" Remove Practice Test") : (" Remove From Homework")
                                                                                                                                                                }
                                                                                                                                                            </Link>
                                                                                                                                                        </Dropdown.Item>
                                                                                                                                                    </>
                                                                                                                                                </Dropdown.Menu>
                                                                                                                                            </Dropdown>
                                                                                                                                            ) : null
                                                                                                                                    }
                                                                                                                                </div>
                                                                                                                            </Typography>
                                                                                                                            <Typography>
                                                                                                                                <CardMedia
                                                                                                                                    component="img"
                                                                                                                                    image={imgURL + challenge.thumbnail}
                                                                                                                                    className={classes.contentimage}
                                                                                                                                />
                                                                                                                            </Typography>
                                                                                                                            <Typography>
                                                                                                                                <div>
                                                                                                                                    <Row className='questionfontsmultih'>
                                                                                                                                        <Col sm="12">
                                                                                                                                            <span>Start Date - </span> {formatDate(challenge.start_date)}
                                                                                                                                        </Col>
                                                                                                                                        <Col sm="12">
                                                                                                                                            <span>Due Date - </span> {formatDate(challenge.end_date)}
                                                                                                                                        </Col>
                                                                                                                                    </Row>
                                                                                                                                </div>
                                                                                                                            </Typography>
                                                                                                                            {/* <Typography>
                                                                                                                                <div class="questionfontsmultih" dangerouslySetInnerHTML={{ __html: challenge.description }}></div>
                                                                                                                            </Typography> */}
                                                                                                                            <div style={{ display: "flex", justifyContent: "space-between" }}>

                                                                                                                                <Typography className='ch-cardstext'>
                                                                                                                                    <div className='challenge-statush'>
                                                                                                                                        <Row className='status-title'>
                                                                                                                                            <Col sm="12">
                                                                                                                                                <span>{challenge.reviewstatus}</span>
                                                                                                                                            </Col>
                                                                                                                                        </Row>
                                                                                                                                        {
                                                                                                                                            getCurrentUserRole() === User_Role.Professor ? (<Row className='status-title-source'>
                                                                                                                                                <Col sm="12">
                                                                                                                                                    <div className='statusContainerh'>
                                                                                                                                                        <span >
                                                                                                                                                        </span>
                                                                                                                                                        <div>Attempted User: {challenge.totaluserattemptedcurrentchallenge}</div>
                                                                                                                                                    </div>
                                                                                                                                                </Col>
                                                                                                                                            </Row>) : null
                                                                                                                                        }


                                                                                                                                        <Row className='status-title-source'>
                                                                                                                                            <Col sm="12">
                                                                                                                                                <div className='statusContainerh'>
                                                                                                                                                    <span >
                                                                                                                                                    </span>
                                                                                                                                                    <div>Source: {challenge.bookname}</div>
                                                                                                                                                </div>
                                                                                                                                            </Col>
                                                                                                                                        </Row>
                                                                                                                                    </div>
                                                                                                                                </Typography>
                                                                                                                                <Typography className='ch-cardstext'>
                                                                                                                                    <div className='challenge-statush'>
                                                                                                                                        <Row className='status-title'>
                                                                                                                                            <Col sm="5"></Col>
                                                                                                                                            <Col sm="7">
                                                                                                                                                <div className='statusContainerh'>
                                                                                                                                                    <span >{challenge.type}</span>
                                                                                                                                                    <div title={challenge.complexity} style={{ marginLeft: '4px', width: '15px', height: '15px', background: getColor(challenge.complexity) }}></div>
                                                                                                                                                </div>

                                                                                                                                            </Col>
                                                                                                                                        </Row>

                                                                                                                                        {
                                                                                                                                            getCurrentUserRole() === User_Role.Student && homework.isgraded ? (<Row>
                                                                                                                                                <Col sm="3"></Col>
                                                                                                                                                <Col sm="9">
                                                                                                                                                    <div className='statusContainerh'>
                                                                                                                                                        <span >
                                                                                                                                                        </span>
                                                                                                                                                        <div>Points: {`${parseInt(challenge.is_attempted) === 1 ? challenge.challenge_points : "-"}`}</div>
                                                                                                                                                    </div>
                                                                                                                                                </Col>
                                                                                                                                            </Row>) : null
                                                                                                                                        }

                                                                                                                                    </div>
                                                                                                                                </Typography>
                                                                                                                            </div>
                                                                                                                        </CardContent>
                                                                                                                    </Link>
                                                                                                                </Card>
                                                                                                            </ImageList>
                                                                                                        )
                                                                                                    })
                                                                                                }
                                                                                            </div>
                                                                                        ) :
                                                                                        null
                                                                                }
                                                                            </>
                                                                            <>
                                                                                {
                                                                                    homework.childrenFRQ !== undefined && homework.childrenFRQ !== null && homework.childrenFRQ.length > 0 ?
                                                                                        (
                                                                                            <div>
                                                                                                {
                                                                                                    props.problem_source !== Problem_source.homework ? (<Row className='subheaderQuestions'>
                                                                                                        <Col className='col-10 col-md-10 subheadertextQtype' >

                                                                                                            {
                                                                                                                props.problem_source !== Problem_source.homework ?
                                                                                                                    ("Free-Response Questions") : null
                                                                                                            }

                                                                                                        </Col>
                                                                                                        <Col className='col-2 col-md-2' style={{ textAlign: "end" }}>
                                                                                                            {
                                                                                                                getCurrentUserRole() === User_Role.Student && homework.isgraded ?
                                                                                                                    (
                                                                                                                        <>
                                                                                                                            Points: {`${parseInt(homework.isoneofchallenge_attepmted) === 1 ? frqSectionPoints : "-"}`}

                                                                                                                        </>
                                                                                                                    ) : null
                                                                                                            }

                                                                                                        </Col>
                                                                                                    </Row>) : <Row className='subheaderQuestions'></Row>
                                                                                                }
                                                                                                {
                                                                                                    homework.childrenFRQ.map((challenge, counter) => {

                                                                                                        let pathname = `/answerboardhow/c${challenge.challenge_id}/${challenge.id}`;
                                                                                                        let previouspath = `/homeworkproblems`
                                                                                                        let previousnode = Previous_Node_Name.homeworkproblems

                                                                                                        if (props.problem_source === Problem_source.homework) {
                                                                                                            if (userrole !== User_Role.Student && challenge.type !== "FRQ") {
                                                                                                                pathname = `/studentlistnonfrq/${challenge.challenge_id}/${challenge.id}/${props.problem_source}`
                                                                                                            }
                                                                                                            else if (userrole === User_Role.Student) {
                                                                                                                pathname = `/answerboardhow/c${challenge.challenge_id}/${challenge.id}`;
                                                                                                            }
                                                                                                            else if (userrole !== User_Role.Student && challenge.type === "FRQ") {
                                                                                                                pathname = `/studentlist/${challenge.challenge_id}/${challenge.id}/${props.problem_source}`
                                                                                                            }

                                                                                                        }
                                                                                                        else {
                                                                                                            if (userrole === User_Role.Student) {
                                                                                                                pathname = `/answerboardpt/c${challenge.challenge_id}/${challenge.id}`;
                                                                                                            }
                                                                                                            else {
                                                                                                                pathname = `/answerboardpt/c${challenge.challenge_id}/${challenge.id}`;
                                                                                                            }
                                                                                                            previouspath = "/practicetest"
                                                                                                            previousnode = Previous_Node_Name.practicetest
                                                                                                        }

                                                                                                        if (challenge.challenge_disabled === true) {
                                                                                                            if (userrole === User_Role.Student) {
                                                                                                                pathname = ""
                                                                                                                //cardclassname = classes.cardstudent 
                                                                                                            }
                                                                                                        }
                                                                                                        return (
                                                                                                            <ImageList className={classes.gridList} id={challenge.challenge_id}>
                                                                                                                <Card className={classes.card} style={{ position: 'relative' }}>
                                                                                                                    <Link
                                                                                                                        onClick={(e) => cardclickHandler(e, pathname)}
                                                                                                                        to={{
                                                                                                                            pathname: pathname,
                                                                                                                            state: { prevPath: [previouspath], prevNode: [previousnode], HomeworkId: [`${challenge.id}`], problemSource: [`${props.problem_source}`] }
                                                                                                                        }}
                                                                                                                    >
                                                                                                                        <CardContent className={classes.contentclass}>
                                                                                                                            <Typography className='ch-cardstitle'>

                                                                                                                                <div style={{ fontSize: "12px", boxShadow: "none", textDecoration: 'none', fontWeight: "600" }}>
                                                                                                                                    <span className="ch-title"> {challenge.challenge_display}</span>
                                                                                                                                </div>

                                                                                                                                <div>
                                                                                                                                    {
                                                                                                                                        (userrole !== User_Role.Student && userId !== User_Role.Tutor) ?
                                                                                                                                            (<Dropdown>
                                                                                                                                                <Dropdown.Toggle as={CustomToggle} />
                                                                                                                                                <Dropdown.Menu size="sm" title="" >
                                                                                                                                                    <>
                                                                                                                                                        {
                                                                                                                                                            props.problem_source === Problem_source.homework ? (<Dropdown.Item>
                                                                                                                                                                <Link
                                                                                                                                                                    to={{
                                                                                                                                                                        pathname: `/answerboardhow/c${challenge.challenge_id}/${challenge.id}`,
                                                                                                                                                                        state: { prevPath: ['/homeworkproblems'], prevNode: ['Homework Problems'], HomeworkId: [`${challenge.id}`] }
                                                                                                                                                                    }}
                                                                                                                                                                    style={{ fontSize: "12px", boxShadow: "none", textDecoration: 'none' }}>
                                                                                                                                                                    <i class="fa fa-folder-open-o" style={{ fontSize: "12px", boxShadow: "none", textDecoration: 'none', }}></i> Access Problem
                                                                                                                                                                </Link>
                                                                                                                                                            </Dropdown.Item>) : null
                                                                                                                                                        }
                                                                                                                                                        <Dropdown.Item>
                                                                                                                                                            <Link to='#' onClick={(e) => handleopenhomeworkmodal(e, challenge.challenge_id, challenge.title, challenge.start_date, challenge.end_date, challenge.description, challenge.isgraded, challenge.assistant_user_id, challenge.unique_id)}
                                                                                                                                                                style={{ fontSize: "12px", boxShadow: "none", textDecoration: 'none' }}>
                                                                                                                                                                <i class="fa fa-edit" style={{ fontSize: "12px", boxShadow: "none", textDecoration: 'none', }}></i>
                                                                                                                                                                {
                                                                                                                                                                    props.problem_source === Problem_source.apptesthomework ? (" Edit Practice Test") : (" Edit Homework")
                                                                                                                                                                }
                                                                                                                                                            </Link>
                                                                                                                                                        </Dropdown.Item>
                                                                                                                                                        <Dropdown.Item>
                                                                                                                                                            <Link to='#' onClick={(e) => handleremovefromhomework(index, challenge.challenge_id, challenge.id)}
                                                                                                                                                                style={{ fontSize: "12px", boxShadow: "none", textDecoration: 'none' }}>
                                                                                                                                                                <i class="fa fa-folder-open-o" style={{ fontSize: "12px", boxShadow: "none", textDecoration: 'none', }}></i>
                                                                                                                                                                {
                                                                                                                                                                    props.problem_source === Problem_source.apptesthomework ? (" Remove Practice Test") : (" Remove From Homework")
                                                                                                                                                                }
                                                                                                                                                            </Link>
                                                                                                                                                        </Dropdown.Item>
                                                                                                                                                    </>
                                                                                                                                                </Dropdown.Menu>
                                                                                                                                            </Dropdown>
                                                                                                                                            ) : null
                                                                                                                                    }
                                                                                                                                </div>
                                                                                                                            </Typography>
                                                                                                                            <Typography>
                                                                                                                                <CardMedia
                                                                                                                                    component="img"
                                                                                                                                    image={imgURL + challenge.thumbnail}
                                                                                                                                    className={classes.contentimage}
                                                                                                                                />
                                                                                                                            </Typography>
                                                                                                                            <Typography>
                                                                                                                                <div>
                                                                                                                                    <Row className='questionfontsmultih'>
                                                                                                                                        <Col sm="12">
                                                                                                                                            <span>Start Date - </span> {formatDate(challenge.start_date)}
                                                                                                                                        </Col>
                                                                                                                                        <Col sm="12">
                                                                                                                                            <span>Due Date - </span> {formatDate(challenge.end_date)}
                                                                                                                                        </Col>
                                                                                                                                    </Row>
                                                                                                                                </div>
                                                                                                                            </Typography>
                                                                                                                            <div style={{ display: "flex", justifyContent: "space-between" }}>

                                                                                                                                <Typography className='ch-cardstext'>
                                                                                                                                    <div className='challenge-statush'>
                                                                                                                                        <Row className='status-title'>
                                                                                                                                            <Col sm="12">
                                                                                                                                                <span>{challenge.reviewstatus}</span>
                                                                                                                                            </Col>
                                                                                                                                        </Row>
                                                                                                                                        {
                                                                                                                                            getCurrentUserRole() === User_Role.Professor ? (<Row className='status-title-source'>
                                                                                                                                                <Col sm="12">
                                                                                                                                                    <div className='statusContainerh'>
                                                                                                                                                        <span >
                                                                                                                                                        </span>
                                                                                                                                                        <div>Attempted User: {challenge.totaluserattemptedcurrentchallenge}</div>
                                                                                                                                                    </div>
                                                                                                                                                </Col>
                                                                                                                                            </Row>) : null
                                                                                                                                        }

                                                                                                                                        <Row className='status-title-source'>
                                                                                                                                            <Col sm="12">
                                                                                                                                                <div className='statusContainerh'>
                                                                                                                                                    <span >
                                                                                                                                                    </span>
                                                                                                                                                    <div>Source: {challenge.bookname}</div>
                                                                                                                                                </div>
                                                                                                                                            </Col>
                                                                                                                                        </Row>
                                                                                                                                    </div>
                                                                                                                                </Typography>
                                                                                                                                <Typography className='ch-cardstext'>
                                                                                                                                    <div className='challenge-statush'>
                                                                                                                                        <Row className='status-title'>
                                                                                                                                            <Col sm="5"></Col>
                                                                                                                                            <Col sm="7">
                                                                                                                                                <div className='statusContainerh'>
                                                                                                                                                    <span >{challenge.type}</span>
                                                                                                                                                    <div title={challenge.complexity} style={{ marginLeft: '4px', width: '15px', height: '15px', background: getColor(challenge.complexity) }}></div>
                                                                                                                                                </div>

                                                                                                                                            </Col>
                                                                                                                                        </Row>

                                                                                                                                        {
                                                                                                                                            getCurrentUserRole() === User_Role.Student && homework.isgraded ? (<Row>
                                                                                                                                                <Col sm="3"></Col>
                                                                                                                                                <Col sm="9">
                                                                                                                                                    <div className='statusContainerh'>
                                                                                                                                                        <span >
                                                                                                                                                        </span>
                                                                                                                                                        <div>Points: {`${parseInt(challenge.is_attempted) === 1 ? challenge.challenge_points : "-"}`}</div>
                                                                                                                                                    </div>
                                                                                                                                                </Col>
                                                                                                                                            </Row>) : null
                                                                                                                                        }

                                                                                                                                    </div>
                                                                                                                                </Typography>
                                                                                                                            </div>
                                                                                                                        </CardContent>
                                                                                                                    </Link>
                                                                                                                </Card>
                                                                                                            </ImageList>
                                                                                                        )
                                                                                                    })
                                                                                                }
                                                                                            </div>
                                                                                        ) :
                                                                                        null
                                                                                }
                                                                            </>

                                                                        </div>
                                                                    </div>
                                                                </Typography>
                                                            </AccordionDetails>
                                                        </Accordion>
                                                    </div>
                                                )
                                            })
                                            : <div style={{ paddingTop: "30px", paddingLeft: "20px" }}>
                                                {
                                                    props.problem_source === Problem_source.homework ? ("No Homework Problems Found!") : ("No Practice Test Found!")
                                                }
                                            </div>}
                                    </div>
                                </Box>
                            </div>
                        </div>
                    </div>
                    {
                        loading && (
                            <CircularProgress
                                size={24}
                                sx={{
                                    color: "#164b99",
                                    position: 'absolute',
                                    top: '50%',
                                    left: '50%',
                                    marginTop: '-12px',
                                    marginLeft: '-12px',
                                }}
                            />
                        )
                    }
                </>

    )
}

export default HomeWorkForm