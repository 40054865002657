import React from 'react';
import { NavLink } from 'react-router-dom';
import './Navlink.scss';
import Aux from '../../../../hoc/Wrapper';

const navlink = (props) => {
    let classname = ''

    if (props.title === "Interactive Problems") {
        classname = "interactiveproblem_tour"
    }
    const onNavLinkClickHandler = () => {
        props.onMenuClick();

    }
    return (
        <Aux>
            <li className='navlink'>
                <span></span>
            </li>
            <li className='navlink'>
                <NavLink onClick={() => onNavLinkClickHandler()} to={props.link} exact activeClassName='active'
                className={classname}>{props.title}</NavLink>
            </li>
        </Aux>
    );
}

export default navlink;