import React from "react";
import ModalComponent from '../../../Common/ModalComponent/ModalComponent';
import Button from '../../../Common/Button/Button'
import Ratings from '../../../Common/Ratings/Ratings';
import './WriteReview.scss';
import { toast } from 'react-toastify';
import Radiobutton from "../../../Common/Radiobutton/Radiobutton";
import { saveReview, fetchReview } from '../../../../common/API'
import { getCurrentUserId } from "../../../../common/Functions";

export default class WriteReview extends React.Component {

    state = {
        overallRating: 0,
        explanationRating: 0,
        presentationRating: 0,
        pace: 50,
        abstractRating: '',
        reviewText: ''
    }

    handleExplanationRatingChange = (newValue) => {
        this.setState({
            explanationRating: newValue,
        })
    }

    handleOverallRatingChange = (newValue) => {
        this.setState({
            overallRating: newValue
        })
    }

    handlePresentationRatingChange = (newValue) => {
        this.setState({
            presentationRating: newValue,
        })
    }

    handlePaceChange = (e) => {
        this.setState({
            pace: Number(e.target.value)
        })
    }

    handleReviewTextChange = (e) => {
        this.setState({
            reviewText: e.target.value
        })
    }

    handleAbstractReviewChange = (e) => {
        this.setState({
            abstractRating: e.target.value
        })
    }

    closeModal = () => {
        this.props.toggleReviewModal()
    }

    componentWillReceiveProps(newProps) {
        if (newProps.showModal) {
            const reqData = {
                params: {
                    explorationId: newProps.explorationId,
                    userId: getCurrentUserId(),
                }
            }
            fetchReview(reqData)
                .then(res => {
                    if (res.data.success) {
                        this.setState({
                            ...res.data.data
                        })
                    }
                })
                .catch(err => {
                    console.error(err.message)
                    toast('Oops, something went wrong!', {
                        type: toast.TYPE.ERROR,
                        position: toast.POSITION.BOTTOM_RIGHT
                    })
                })
        }
    }

    saveReview = () => {
        const reqData = {
            userId: getCurrentUserId(),
            overallRating: this.state.overallRating,
            explanationRating: this.state.explanationRating,
            presentationRating: this.state.presentationRating,
            pace: this.state.pace,
            abstractRating: this.state.abstractRating,
            reviewText: this.state.reviewText,
            explorationId: this.props.explorationId,
        }
        if (reqData.overallRating === 0) {
            toast("Please fill all the fields correctly.", {
                type: toast.TYPE.WARNING,
                position: toast.POSITION.BOTTOM_RIGHT
            })
        } else {
            saveReview(reqData)
                .then(res => {
                    toast(res.data.message, {
                        type: toast.TYPE.INFO,
                        position: toast.POSITION.BOTTOM_RIGHT
                    })
                    this.props.setVideoDetailsRatings(res.data.data.contentRating)
                    this.closeModal()
                })
                .catch(err => {
                    console.error(err.message);
                    toast('Oops, something went wrong!', {
                        type: toast.TYPE.ERROR,
                        position: toast.POSITION.BOTTOM_RIGHT
                    })
                })
        }
    }

    render() {

        const modalBody = (
            <div className='review-modal'>
                <div className='write-review'>
                    <h2>Overall Rating</h2>
                    <div className='rating'>
                        <Ratings stars={this.state.overallRating} onChange={this.handleOverallRatingChange} className='ratings' />
                    </div>
                </div>
                <div className='write-review'>
                    <h2>Clear Explanation</h2>
                    <div className='rating'>
                        <Ratings stars={this.state.explanationRating} onChange={this.handleExplanationRatingChange} className='ratings' />
                    </div>
                </div>
                <div className='write-review'>
                    <h2>Engaging Presentation</h2>
                    <div className='rating'>
                        <Ratings stars={this.state.presentationRating} onChange={this.handlePresentationRatingChange} className='ratings' />
                    </div>
                </div>
                <div className='write-review'>
                    <h2>Well-paced, Fast or Slow</h2>
                    <div className='slide-container'>
                        <input type="range" min="1" max="100" value={this.state.pace} onChange={this.handlePaceChange} id="myRange" />
                        <label className='slow'>Slow</label>
                        <label className='fast'>Fast</label>
                    </div>
                </div>
                <div className='write-review well-paced-checkbox'>
                    <div className='checkbox'>
                        <fieldset onChange={this.handleAbstractReviewChange.bind(this)}>
                            <div className="abstractReview">
                                <Radiobutton name="abstractreview" value='Needs Improvement' checked={this.state.abstractRating === 'Needs Improvement'} text='Needs Improvement' />
                                <Radiobutton name="abstractreview" value='Good' checked={this.state.abstractRating === 'Good'} text='Good' />
                                <Radiobutton name="abstractreview" value='Very Good' checked={this.state.abstractRating === 'Very Good'} text='Very Good' />
                                <Radiobutton name="abstractreview" value='Exceptional' checked={this.state.abstractRating === 'Exceptional'} text='Exceptional' />
                                <Radiobutton name="abstractreview" value='Irresistible' checked={this.state.abstractRating === 'Irresistible'} text='Irrestible' />
                            </div>
                        </fieldset>
                    </div>
                </div>
                <textarea rows="3" class="review-text" placeholder='Write a review' value={this.state.reviewText} onChange={this.handleReviewTextChange}></textarea>
            </div>
        );
        const modalTitle = 'Write a Review';
        const customButton = (
            <div className='mx-auto'>
                <Button className='mr-2 yellow-btn' clicked={this.saveReview}>Submit</Button>
                <Button className="grey-btn" clicked={this.closeModal}>Cancel</Button>
            </div>
        );

        return (
            <ModalComponent
                show={this.props.showModal}
                handleClose={this.closeModal}
                title={modalTitle}
                body={modalBody}
                showButton={false}
                customButton={customButton}
                draggable={false}
            />
        )
    }
}