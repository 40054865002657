import React, { useState, useEffect } from 'react';
import './PracticePaper.scss'
import ChallengeCards from '../../../components/ChallengeCards/ChallengeCards';
import { getpracticepaperlist } from '../../../common/API'
import CircularProgress from '@mui/material/CircularProgress';
import { getCurrentUserId, Problem_source, showFullApp, isDemoUserFlagged, getInstituteId, detectDevice, TABLET, DESKTOP, getMenuSelectedItem } from '../../../common/Functions';
import { makeStyles } from "@material-ui/core/styles";
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import infoIcon from '../../../assets/images/info_icon.png'
import practiceTestDemoVideo from "../../../assets/videos/aiPlato_Practice_Tests_edited.mp4";
import Modal from '../../../components/Common/ModalComponent/ModalComponent';
import { Container, Row, Col, Button } from 'react-bootstrap';
import { Institute, testInstructionText } from '../../../common/Constants';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import parse from 'html-react-parser';
import whiteBoardVideo from "../../../assets/videos/Quiz_HelpCenter.mp4";

const useStyles = makeStyles((theme) => ({
    root: {
    },
    maignGrid: {
        marginLeft: "0px"

    },

}));



const PracticePaper = () => {    
    const classes = useStyles()
    const [practicePaperData, setPracticePaperData] = useState([])
    const [loader, setLoader] = useState(false)
    const [showModalVideo, setshowModalVideo] = useState(false)
    const [whiteBoardVideoDiv, setwhiteBoardVideoDiv] = useState("")
    const [updateList, setUpdateList] = useState(false)

    const [showModalVideoUsageGuide, setshowModalVideoUsageGuide] = useState(false)



    useEffect(() => {
        getlist()
    }, []);


    const getlist = () => {
        setLoader(true)
        const reqData = { params: { 'user_id': getCurrentUserId(), 'problem_source': Problem_source.apptesthomework, "course_id":getMenuSelectedItem() } }
        getpracticepaperlist(reqData).then(res => {

            if (res.data !== undefined && res.data !== null) {
                // If TryItOut user then show only 2 PT => 1 Full PT and 1 Half PT //
                if (isDemoUserFlagged()) {
                    let finalData = []
                    let counter = 0
                    let resData = res.data
                    resData.map(item => {
                        if (item.totalchallengeperPtTest < 30 && counter === 0) {
                            finalData.push(item)
                            counter = 1
                        }
                    })

                    counter = 0
                    resData.map(item => {
                        if (item.totalchallengeperPtTest > 30 && counter === 0) {
                            finalData.push(item)
                            counter = 1
                        }
                    })

                    if (finalData.length > 0) {
                        setPracticePaperData(finalData)
                    }
                }
                else {
                    setPracticePaperData(res.data)
                }
                setLoader(false)
            }
        }).catch(err => {
            setLoader(false)
            console.error(err.message);
        })
    }

    useEffect(() => {
        if (updateList) {
            getlist()
        }
    }, [updateList]);


    const itemchangedhandler = (event) => {
        setUpdateList(true)
    }

    const closeVideoModal = () => {
        setshowModalVideo(false)
    }
    const handleHelpCenterClickEvent = () => {

        setshowModalVideo(true)
        setwhiteBoardVideoDiv(
            <div style={{ padding: '10px', margin: "10px", color: "#164b99" }}>
                <div className="examInstMainDiv">
                    <div className="divInstruction">
                        <div className="instructionTitle">Essential Quiz Instructions - Please Read Carefully!</div>

                        <div className="divLis">
                            <div className="divLi instructiondiv">
                                <span className='spanLiTitle'>Before You Begin:<br /></span>
                                <span className='spanLi '>
                                    <ul>
                                        <li> <b>Timing</b> : You will have 150 minutes to complete this quiz. The timer starts as soon as you access or start the assignment.</li>
                                        <li> <b>Auto-Submission</b> : The quiz will be automatically submitted after 150 minutes if you haven't submitted it yourself.</li>
                                    </ul>
                                </span>
                            </div>
                            <div className="divLi instructiondiv">
                                <span className='spanLiTitle'>During the Exam:<br /></span>
                                <span className='spanLi'>
                                    <ul>
                                        <li> <b>Individual Submissions</b> : You have a maximum of 5 independent submissions for each part of each problem, including multiple-choice questions. Individual problem submissions are made using the "Check Score" button.</li>
                                        <li> <b>Point Deductions</b> : Each incorrect submission will result in a 20% deduction of the points for that part. For example, if you get question 4 part b correct on the third attempt, you will receive 60% of the points for that part.</li>
                                        <li> <b>Answer Reveals</b> : The answer for a specific part will be revealed after 5 incorrect submissions for that problem or part of a problem</li>
                                        <li> <b>Check Score</b> : Use the "Check Score" button to submit your answers and see your points for each part as you go along.</li>
                                    </ul>
                                </span>
                            </div>
                            <div className="divLi instructiondiv">
                                <span className='spanLiTitle'>After you Finish:<br /></span>
                                <span className='spanLi'>
                                    <ul>
                                        <li> <b>Submission</b> : Once you have completed the quiz, please submit it using the "Submit Quiz" button.</li>
                                        <li> <b>Assessment</b> : Your assessment will be available immediately after you submit the quiz.</li>
                                    </ul>
                                </span>
                                <span className='spanLiTitle'>Good luck, and remember this is a practice tool—use it to prepare and improve!</span>
                            </div>
                        </div>
                    </div>

                    <button style={{ width: "150px" }} className="dragLabelcancel leftmargin" onClick={(e) => setshowModalVideo(false)}>
                        OK
                    </button>
                </div>
            </div>
        )
        // <div className='videoModalDiv'>
        //     <div className='videoDiv'>
        //         <video playsinline muted loop={true} autoPlay={true} width="590" height="270" controls>
        //             <source src={practiceTestDemoVideo} type="video/mp4" />
        //         </video>
        //     </div>

        //     <div className='modal-button text-center'>
        //         <Button className="videoClosebtn" onClick={closeVideoModal}> CLOSE </Button>
        //     </div>
        // </div>)

    }


    const usageguidehandler = () => {
        setshowModalVideoUsageGuide(true)
        setwhiteBoardVideoDiv(
            <div className='videoModalDiv'>
                <div className='videoDiv'>
                    <video playsinline muted loop={true} autoPlay={true} width="590" height="270" controls>
                        <source src={whiteBoardVideo} type="video/mp4" />
                    </video>
                </div>
                {/* <div className='videoHeader'>
                    <span>Welcome to Interactive Problems <SchoolIcon className='schoolIcon' /> <AutoAwesomeIcon className='awesomeIcon' /></span>
                </div> */}
                <div className='videoDescription mt-4'>
                    <p>Welcome to the Quiz Guide! In this video, we will walk you through everything you need to know to successfully complete your quiz.</p>
                </div>
                <div className='modal-button text-center'>
                    <Button className="videoClosebtn" onClick={closeusagaeguidehandler}> CLOSE </Button>
                </div>
            </div>
        )
    }
    const closeusagaeguidehandler = () => {
        setshowModalVideoUsageGuide(false)
    }

    return (
        <>
            {(detectDevice() === TABLET || detectDevice() === DESKTOP) ? <div className="APTestPaper">
                <div className="container">
                    <div className='borderBottom'>
                        <div className="label d-flex justify-content-between">
                            {getInstituteId() === Institute.Rice ?
                                <div className="text-wrapper">Rice Quiz</div> :
                                <div className="text-wrapper">AP Practice Tests</div>
                            }

                            <div>
                                <button
                                    onClick={handleHelpCenterClickEvent} className="btnLightBlue" >View Instructions</button>
                                <button
                                    onClick={usageguidehandler} className="btnLightBlue"  style={{marginLeft:"5px"}}>Usage Guide</button>
                            </div>
                        </div>
                    </div>

                    {/* <div className="sublabel">
                        <div className="text-wrapper"></div></div> */}
                    {getInstituteId() === Institute.Rice ?
                        null :
                        <div className="description">
                            <p className="choose">
                                <span className="text-wrapper">Choose your challenge with tests that adapt to your needs—select "</span>
                                <span className="span">Guided Mode</span>
                                <span className="text-wrapper">" for step-by-step assistance with hints and explanations from aiPlato or "</span>
                                <span className="span">Exam Mode</span>
                                <span className="text-wrapper">" if you feel more ready for exam conditions.</span>
                            </p>
                        </div>
                    }


                    {
                        practicePaperData !== undefined && practicePaperData !== null && practicePaperData.length > 0 ? (
                            <Box sx={{ flexGrow: 1, marginBottom: "50px", padding: "20px", maxWidth: "100%", width: '100%' }} >
                                <Grid className={classes.maignGrid} sx={{ marginBottom: "50px" }} container spacing={{ xs: 1, md: 3 }} columns={{ xs: 1, sm: 8, md: 12 }}>
                                    {
                                        practicePaperData.map((data, index) => {
                                            return (
                                                <Grid item xs={2} sm={4} md={4} key={index}>
                                                    <ChallengeCards item={data} itemchanged={(e) => itemchangedhandler(e)}></ChallengeCards></Grid>
                                            )
                                        })
                                    }

                                </Grid>
                            </Box>
                        ) :
                            null

                    }

                </div>
            </div > :
                <div style={{ marginTop: '100px', textAlign: 'center', background: '#fff' }}>
                    <h5>Optimized Experience Advisory</h5>
                    <img src={infoIcon}></img>
                    <p>We are actively working on making aiPlato available on all mobile and tablet devices. Currently we only support large screens.</p>
                </div>
            }

            {
                loader && (
                    <CircularProgress
                        size={24}
                        sx={{
                            color: "#164b99",
                            position: 'absolute',
                            top: '50%',
                            left: '50%',
                            marginTop: '-12px',
                            marginLeft: '-12px',
                        }}
                    />
                )
            }

            <Dialog
                hideBackdrop={false}
                open={showModalVideo}
                onClose={(e) => closeVideoModal(e)}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        <div style={{ marginTop: "10px", color: "#164b99" }}>
                            <div className="examInstMainDiv">
                                {parse(testInstructionText)}
                            </div>
                        </div>
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <button style={{
                        width: "150px",
                        height: '100%',
                        paddingLeft: "22px",
                        paddingRight: "22px",
                        paddingTop: "8px",
                        paddingBottom: "8px",
                        background: '#4353B0',
                        boxShadow: '0px 3px 1px -2px rgba(0, 0, 0, 0.20)',
                        borderRadius: "100px",
                        overflow: 'hidden',
                        flexDirection: 'column',
                        justifyContent: 'center',
                        alignItems: 'center',
                        display: 'inline-flex',
                        color: "#fff",
                        '&:hover': {
                            background: '#4353B0 !important',
                        },
                    }} className="dragLabelcancel leftmargin" onClick={(e) => closeVideoModal(e)}>
                        OK
                    </button>
                </DialogActions>
            </Dialog>

            {/* <Modal className='InstructionModal'
                show={showModalVideo}
                onHide={closeVideoModal}
                draggable={false}
                body={whiteBoardVideoDiv}
                handleClose={closeVideoModal}
                hideHeader={true}
                showButton={false}
            >
            </Modal> */}

            <Modal className='modalVideo'
                show={showModalVideoUsageGuide}
                onHide={closeusagaeguidehandler}
                draggable={false}
                body={whiteBoardVideoDiv}
                handleClose={closeusagaeguidehandler}
                hideHeader={true}
                showButton={false}
            >
            </Modal>
        </>

    )
}

export default PracticePaper