import React from "react";
import { Row, Button, Col, Image } from 'react-bootstrap';
import Form from 'react-bootstrap/Form';
import { withRouter } from 'react-router-dom';
import { getCurrentUserId, getlocalsystemtimezone } from '../../common/Functions';
import { updatetutor, imgURL, fetchtutorprofile, gettutorchapterpreferences } from '../../common/API'
import { toast } from 'react-toastify';
import Select from 'react-select';
import { TimezoneSelect, clientTz } from 'timezone-select-js';
import Ratings from '../Common/Ratings/Ratings';
import StudentRating from './StudentRating';

const dummy_imageurl = imgURL + "/media/dummy.png"
const getUrlExtension = (url) => {
    return url
        .split(/[#?]/)[0]
        .split(".")
        .pop()
        .trim();
}
class TutorForm extends React.Component {

    state = {
        initial_name: "",
        first_name: "",
        last_name: "",
        current_profession: "",
        education: "",
        chapter_preferences: [],
        chapter_preferencesdata: [],
        tutor_type: "",
        user_id: getCurrentUserId(),
        profile_pic: dummy_imageurl,
        validated: false,
        pk_id: null,
        mode: "Edit",
        user_timezone: "",
        showedittimezone: false,
        profilephoto: dummy_imageurl,
        selectedTimezone: clientTz(),
        subject: "",
        overallrating: 0

    }
    componentDidMount() {
        // let ary = Intl.supportedValuesOf('timeZone');
        // debugger;
        const reqData = { params: { 'user_id': this.state.user_id } }

        gettutorchapterpreferences().then(res => {
            if (res.data !== undefined && res.data.nodes.length > 0) {
                this.setState({
                    chapter_preferencesdata: res.data.nodes,
                }, () => {
                    fetchtutorprofile(reqData).then(res => {
                        if (res.status === 200) {
                            this.setState({
                                mode: "Edit",
                                initial_name: res.data.resdata.initial_name,
                                first_name: res.data.resdata.first_name,
                                last_name: res.data.resdata.last_name,
                                current_profession: res.data.resdata.current_profession,
                                education: res.data.resdata.education,
                                profile_pic: imgURL + res.data.resdata.profile_pic,
                                profilephoto: imgURL + res.data.resdata.profile_pic,
                                tutor_type: res.data.resdata.tutor_type,
                                chapter_preferences: res.data.resdata.chapter_preferences,
                                user_timezone: res.data.resdata.user_timezone,
                                subject: res.data.resdata.subject,
                                overallrating: res.data.resdata.overall_rating,
                            }, () => {
                                if (res.data.resdata.user_timezone !== getlocalsystemtimezone()) {
                                    this.setState({ showedittimezone: true })
                                }
                            });
                        }
                    })
                });
            }
        })

    }
    handleChangechapterpreference = (e) => {

        this.setState({
            chapter_preferences: Array.isArray(e) ? e.map(x => x.value) : []
        });
    }

    handleinitialname(e) {
        this.setState({
            initial_name: e.target.value
        });
    }
    handlefirstname(e) {
        this.setState({
            first_name: e.target.value
        });
    }
    handletimezone(e) {
        this.setState({
            user_timezone: e.target.value
        });
    }
    handlelastname(e) {
        this.setState({
            last_name: e.target.value
        });
    }
    handleprofession(e) {
        this.setState({
            current_profession: e.target.value
        });
    }
    handleeducation(e) {
        this.setState({
            education: e.target.value
        });
    }
    handletutortype(e) {
        this.setState({
            tutor_type: e.target.value
        });
    }
    handleProfileImageChange = (e) => {
        this.setState({
            profile_pic: e.target.files[0]
        })
    };
    handlesubject(e) {
        this.setState({
            subject: e.target.value
        });
    }

    handletimezonechange = (selectedTimezone) => {
        // e.preventDefault();
        // this.setState({
        //     user_timezone: getlocalsystemtimezone(), showedittimezone: false
        // })        
        this.setState({ user_timezone: selectedTimezone.value })

    };
    handleSubmit = async (event) => {
        event.preventDefault();
        const form = event.currentTarget;
        if (form.checkValidity() === false) {
            event.preventDefault();
            event.stopPropagation();
        }
        else {
            let form_data = new FormData();
            form_data.append('initial_name', this.state.initial_name);
            form_data.append('first_name', this.state.first_name);
            form_data.append('last_name', this.state.last_name);
            form_data.append('current_profession', this.state.current_profession);
            form_data.append('education', this.state.education);
            form_data.append('tutor_type', this.state.tutor_type);
            form_data.append('user_id', this.state.user_id);
            form_data.append('user_timezone', this.state.user_timezone);
            form_data.append('chapter_preferences', this.state.chapter_preferences);
            form_data.append('subject', this.state.subject);
            if (this.state.profile_pic.name !== undefined) {
                form_data.append('profile_pic', this.state.profile_pic);
            }
            else {

                var imgExt1 = getUrlExtension(dummy_imageurl);
                const response = await fetch(dummy_imageurl);
                const blob = await response.blob();
                const file = new File([blob], "dummy." + imgExt1, {
                    type: "image/png",
                });
                form_data.append('thumbnail', file);
            }
            await updatetutor(form_data).then(res => {
                if (res.status === 200) {
                    toast.success("Profile Updated!", {
                        position: toast.POSITION.BOTTOM_RIGHT
                    });
                    const reqData = { params: { 'user_id': this.state.user_id } }
                    fetchtutorprofile(reqData).then(res => {
                        if (res.status === 200) {
                            this.setState({
                                mode: "Edit",
                                initial_name: res.data.resdata.initial_name,
                                first_name: res.data.resdata.first_name,
                                last_name: res.data.resdata.last_name,
                                current_profession: res.data.resdata.current_profession,
                                education: res.data.resdata.education,
                                profile_pic: imgURL + res.data.resdata.profile_pic,
                                tutor_type: res.data.resdata.tutor_type,
                                chapter_preferences: res.data.resdata.chapter_preferences,
                                user_timezone: res.data.resdata.user_timezone,
                                profilephoto: imgURL + res.data.resdata.profile_pic,
                            }, () => {
                                if (res.data.resdata.user_timezone !== getlocalsystemtimezone()) {
                                    this.setState({ showedittimezone: true })
                                }
                            });
                        }
                    })
                    //window.location.reload(false);
                }
            }).catch(err => {
                toast.error("Error!", {
                    position: toast.POSITION.BOTTOM_RIGHT
                });
                console.error(err.message)
            })
        }
        this.setState({
            validated: true
        });
    }
    render() {

        return (
            <>
                <div style={{ paddingTop: "20px" }}>
                    <Row style={{ alignItems: "center" }}>
                        <Col sm="12"><h2> My Profile </h2></Col>
                    </Row>
                </div>
                <Row style={{ marginTop: "30px" }}>
                    <Col sm="4">
                        <Row>
                            <Col>
                                <Image src={this.state.profilephoto}
                                    alt="" style={{ borderRadius: "1.25rem", maxHeight: "130", width: "200px" }} />
                            </Col>
                        </Row>
                    </Col>
                    <Col sm="8">
                        <Form noValidate validated={this.state.validated} onSubmit={this.handleSubmit}>
                            <Form.Group as={Row} className="mb-3" controlId="formsubsection" style={{ alignItems: "center" }}>
                                <Form.Label column="sm" lg={3}>
                                    Initial Name
                                </Form.Label>
                                <Col sm="9">
                                    <Form.Control size="sm" style={{ width: "140px" }}
                                        as="select" required
                                        value={this.state.initial_name}
                                        onChange={this.handleinitialname.bind(this)}
                                    >
                                        <option value="">Select</option>
                                        <option value="Mr">Mr</option>
                                        <option value="Mrs">Mrs</option>
                                        <option value="Miss">Miss</option>
                                    </Form.Control>
                                    <Form.Control.Feedback type="invalid">
                                        Please select initial name.
                                    </Form.Control.Feedback>
                                </Col>
                            </Form.Group>
                            <Form.Group as={Row} className="mb-3" controlId="formchallengename" style={{ alignItems: "center" }}>
                                <Form.Label column="sm" lg={3}>
                                    First Name
                                </Form.Label>
                                <Col sm="9">
                                    <Form.Control placeholder="First Name" size="sm" type="text"
                                        style={{ width: "410px" }}
                                        required
                                        value={this.state.first_name}
                                        onChange={this.handlefirstname.bind(this)}
                                    />
                                    <Form.Control.Feedback type="invalid">
                                        Please enter first name.
                                    </Form.Control.Feedback>
                                </Col>

                            </Form.Group>
                            <Form.Group as={Row} className="mb-3" controlId="formdisplayname">
                                <Form.Label column="sm" lg={3}>
                                    Last Name
                                </Form.Label>
                                <Col sm="9">
                                    <Form.Control placeholder="Last Name" size="sm" type="text"
                                        style={{ width: "410px" }} required
                                        value={this.state.last_name}
                                        onChange={this.handlelastname.bind(this)}
                                    />
                                    <Form.Control.Feedback type="invalid">
                                        Please enter last name.
                                    </Form.Control.Feedback>
                                </Col>

                            </Form.Group>
                            <Form.Group as={Row} className="mb-3" controlId="formdisplayname">
                                <Form.Label column="sm" lg={3}>
                                    Profession
                                </Form.Label>
                                <Col sm="9">
                                    <Form.Control placeholder="Current Profession" size="sm" type="text"
                                        style={{ width: "410px" }} required
                                        value={this.state.current_profession}
                                        onChange={this.handleprofession.bind(this)}
                                    />
                                    <Form.Control.Feedback type="invalid">
                                        Please enter profession.
                                    </Form.Control.Feedback>
                                </Col>

                            </Form.Group>
                            <Form.Group as={Row} className="mb-3" controlId="formdisplayname">
                                <Form.Label column="sm" lg={3}>
                                    Education
                                </Form.Label>
                                <Col sm="9">
                                    <Form.Control placeholder="Education" size="sm" type="text"
                                        style={{ width: "410px" }} required
                                        value={this.state.education}
                                        onChange={this.handleeducation.bind(this)}
                                    />
                                    <Form.Control.Feedback type="invalid">
                                        Please enter education.
                                    </Form.Control.Feedback>
                                </Col>

                            </Form.Group>
                            <Form.Group as={Row} className="mb-3" controlId="formsubsection" style={{ alignItems: "center" }}>
                                <Form.Label column="sm" lg={3}>
                                    Type
                                </Form.Label>
                                <Col sm="9">
                                    <Form.Control size="sm" style={{ width: "140px" }}
                                        as="select" required
                                        value={this.state.tutor_type}
                                        onChange={this.handletutortype.bind(this)}
                                    >
                                        <option value="">Select</option>
                                        <option value="Retained">Retained</option>
                                        <option value="PreScheduledFlexTime">Pre Scheduled Flex Time</option>
                                        <option value="AtwillFlexTime">At will Flex Time</option>
                                    </Form.Control>
                                    <Form.Control.Feedback type="invalid">
                                        Please select type name.
                                    </Form.Control.Feedback>
                                </Col>
                            </Form.Group>

                            <Form.Group as={Row} className="mb-3" controlId="formquestionimage">
                                <Form.Label column="sm" lg={3}>Profile Photo</Form.Label>
                                <Col sm="9">
                                    <Form.Control type="file" size="sm" accept="image/png, image/jpeg"
                                        onChange={this.handleProfileImageChange.bind(this)} />
                                </Col>
                            </Form.Group>

                            <Form.Group as={Row} className="mb-3" controlId="formchallengename" style={{ alignItems: "center" }}>
                                <Form.Label column="sm" lg={3}>
                                    Time Zone
                                </Form.Label>
                                <Col sm="9">
                                    {/* <Form.Control placeholder="Time Zone" size="sm" type="text"
                                        style={{ width: "420px" }}
                                        disabled
                                        value={this.state.user_timezone}
                                        onChange={this.handletimezone.bind(this)}
                                    /> */}
                                    <TimezoneSelect
                                        value={this.state.user_timezone}
                                        onChange={this.handletimezonechange.bind(this)}
                                    />
                                </Col>
                                {/* {
                                     this.state.showedittimezone ? (
                                    <Col sm="2">
                                        <Button
                                            variant="link" onClick={this.handletimezonechange.bind(this)}>
                                            <i class="fa fa-edit" style={{ fontSize: "18px", boxShadow: "none", textDecoration: 'none', }}></i>
                                        </Button>
                                        

                                    </Col>
                                     ) : null
                                } */}

                            </Form.Group>
                            <Form.Group as={Row} className="mb-2" controlId="formanswertype">
                                <Form.Label column="sm" lg={3} >Subject</Form.Label>
                                <Col sm="9"><Form.Control size="sm" style={{ width: "140px" }}
                                    as="select" required custom
                                    value={this.state.subject}
                                    onChange={this.handlesubject.bind(this)}
                                >
                                    <option value="">Select</option>
                                    <option value="AP Physics 2">AP Physics 2</option>
                                    <option value="AP Physics C Mechanics">AP Physics C Mechanics</option>
                                    <option value="AP Physics C E&M">AP Physics C E&M</option>
                                    <option value="Honors Physics">Honors Physics</option>
                                    <option value="High School Physics">High School Physics</option>
                                </Form.Control>
                                    <Form.Control.Feedback type="invalid">
                                        Please select answer type.
                                    </Form.Control.Feedback>
                                </Col>
                            </Form.Group>
                            <Form.Group as={Row} className="mb-3" controlId="formsubsection" style={{ alignItems: "center" }}>
                                <Form.Label column="sm" lg={3}>
                                    Chapter
                                </Form.Label>
                                <Col sm="9">
                                    <div style={{ width: '600px' }}>
                                        <Select
                                            isMulti
                                            name="chapterpreferences"
                                            options={this.state.chapter_preferencesdata}
                                            className="basic-multi-select"
                                            value={this.state.chapter_preferencesdata.filter(obj => this.state.chapter_preferences.includes(obj.value))}
                                            classNamePrefix="select"
                                            style={{ width: "410px" }}
                                            onChange={this.handleChangechapterpreference.bind(this)}
                                        />
                                    </div>

                                </Col>
                            </Form.Group>
                            <Form.Group as={Row} className="mb-3" controlId="formsubsection" style={{ alignItems: "center" }}>
                                <Form.Label column="sm" lg={3}>
                                    Feedback
                                </Form.Label>
                                <Col sm="9">
                                    {/* <Ratings className='main-video-rating' stars={this.state.overallrating} readOnly={true} /> */}

                                    <div className='rating clearfix rating-hover' style={{ margin: 0, width: 'fit-content' }}>
                                        <Ratings className='main-video-rating' stars={this.state.overallrating} readOnly={true} />
                                        <StudentRating />
                                    </div>

                                </Col>
                            </Form.Group>

                            <Form.Group as={Row} className="mb-3 mt-3" controlId="formthumbnailimage">
                                <Form.Label column="sm" lg={3} ></Form.Label>
                                <Col sm="9">
                                    <Button type="submit" variant="primary" style={{ marginBottom: "20px", marginTop: "30px" }}>Save</Button>
                                </Col>
                            </Form.Group>
                        </Form>
                    </Col>
                </Row>

            </>
        )
    }
}
export default withRouter(TutorForm);