import React from 'react';
import Aux from '../../../../../hoc/Wrapper';
import './CheckpointContent.scss';
//import { MathpixLoader, MathpixMarkdown } from 'mathpix-markdown-it'
// import MathJax from 'react-mathjax2';
import MathJaxRenderer from '../../../../Common/MathJaxRenderer/MathJaxRenderer';

export default class CheckpointContent extends React.Component {


    state = {
        content: []
    }

    inputRefs = [];
    currentTimestamp = null;
    ynRedirect = null;
    buttonType = null;
    continueBtnText = null;
    questionType = null;
    eosTopicId = null;
    correctAnswerImageRef = React.createRef();
    correctAnswerImagePath = null;
    correctAnswerImageDescription = null;

    setButtonTypeNText = (ques) => {
        if (ques.resource && ques.responseForm === 'YN') {
            this.ynRedirect = ques.resource;
        }
        if (ques.displayNameResponseResource && (ques.responseForm === 'Continue' || ques.responseForm === 'Continue See Answer')) {
            this.continueBtnText = ques.displayNameResponseResource;
        }
        this.buttonType = ques.responseForm;
        this.currentTimestamp = ques.time2skip
    }

    setAnswerImage = (ques) => {
        if (ques.correct_answer_image) {
            this.correctAnswerImagePath = ques.correct_answer_image
        }
        if (ques.correct_answer_description) {
            this.correctAnswerImageDescription = ques.correct_answer_description
        }
    }

    componentDidMount() {
        let temCon = [];
        this.props.question.questions.map((ques, index) => {

            let el = null;
            
            if (ques.type === 'Comprehension'
                || ques.type === 'Alternative Explanation'
                || ques.type === 'Progress Checkpoint') {
                // let curRef = React.createRef();
                el = (
                    <Aux key={'A1' + index}>
                        {/* <MathJax.Context
                            input='ascii'
                            onError={(MathJax, error) => {
                                console.warn(error);
                                console.log("Encountered a MathJax error, re-attempting a typeset!");
                                MathJax.Hub.Queue(
                                    MathJax.Hub.Typeset()
                                );
                            }}
                            script="https://cdnjs.cloudflare.com/ajax/libs/mathjax/2.7.4/MathJax.js?config=default"
                            options={{
                                asciimath2jax: {
                                    useMathMLspacing: true,
                                    delimiters: [["$$", "$$"]],
                                    preview: "none",
                                }
                            }}
                        >
                            <MathJax.Text text={ques.content} />
                        </MathJax.Context> */}
                        <MathJaxRenderer math={ques.content}></MathJaxRenderer>
                        {/* <MathpixLoader>
                            <MathpixMarkdown text={ques.content} />
                        </MathpixLoader>  */}
                        {ques.question_image ?
                            <img className="img-fluid question-img" src={ques.question_image} alt="question image" />
                            : null}

                        {ques.resource ?
                            <a key={'ac' + index} href={ques.resource} target='_blank' rel="noopener noreferrer">{ques.displayNameResponseResource}</a>
                            : null}

                        {/* <textarea
                            ref={curRef}
                            key={'t' + index}
                            placeholder='Answer'
                            rows={4}
                            className="question-textarea"
                            id={ques.id} /> */}
                        <br key={'b1' + index} />
                        {/* {ques.hint ?
                            <a href='#' key={'h' + index} onClick={(event) => this.showHint(event, ques.hint)}>Need hint?</a>
                            : null} */}
                        <br key={'b2' + index} />
                    </Aux>
                );
                this.setButtonTypeNText(ques);
                this.setAnswerImage(ques);
                // this.inputRefs.push(curRef);
            } else if (ques.type === 'Attention'
                || ques.type === 'Physics in Action') {
                el = (
                    <Aux key={'A2' + index}>
                        <h4 key={'h' + index} dangerouslySetInnerHTML={{ __html: ques.content }}></h4>
                        <br key={'b3' + index} />
                        {ques.resource ?
                            <a key={'a' + index} href={ques.resource} target='_blank' rel="noopener noreferrer">{ques.displayNameResponseResource}</a>
                            : null}
                        <br key={'b4' + index} />
                    </Aux>
                );
                this.questionType = ques.type;
                this.setButtonTypeNText(ques);
            } else if (ques.type === 'EndOfSection') {
                el = (
                    <Aux key={'A2' + index}>
                        <h4 key={'h' + index} dangerouslySetInnerHTML={{ __html: ques.content }}></h4>
                        <br key={'b3' + index} />
                    </Aux>
                );
                this.eosTopicId = ques.end_of_section_topic;
                this.setButtonTypeNText(ques);
            }
            this.questionType = ques.type;
            temCon.push(el);
        });

        if (temCon.length > 0) {
            this.setState({ content: temCon });
        }

    }

    showHint = (ev, hint) => {
        ev.preventDefault();
        alert(hint);
    }

    continueClickHandler = () => {
        this.props.resumeVideo(this.props.currentIndex);

        // check the responses
        // let invalid = false;
        // if (this.inputRefs.length > 0) {
        //     this.inputRefs.map(ref => {
        //         const curRef = ref.current;
        //         if (curRef.value.length <= 0) {
        //             invalid = true;
        //         }
        //     });
        //     if (!invalid) {

        //         let answ = [];

        //         this.inputRefs.map(ref => {
        //             const curRef = ref.current;
        //             answ.push({
        //                 id: curRef.id,
        //                 res: curRef.value
        //             });
        //         });
        //         var params = new URLSearchParams();
        //         params.append('res', JSON.stringify(answ));
        //         saveInteractiveVideoResponse(params)
        //             .then(_ => {
        //                 this.props.resumeVideo(this.props.currentIndex);
        //             }).catch(err => {
        //                 console.error(err);
        //                 alert("Error occurred while submitting the response");
        //                 this.props.resumeVideo(this.props.currentIndex);
        //             })

        //     } else {
        //         this.props.resumeVideo(this.props.currentIndex);
        //     }
        // } else {

        //     this.props.resumeVideo(this.props.currentIndex);
        // }
    }

    seeAnswerClickHandler = () => {
        console.log('do something');
        this.correctAnswerImageRef.current.style.display = 'block'
    }

    yesClickHandler = () => {

        if (this.questionType === 'EndOfSection') {
            //TODO:  Redirect user to particular section
            if (this.eosTopicId) {
                window.location.href = '/curriculum-content/' + this.eosTopicId
            }
        } else {
            window.open(this.ynRedirect, '_blank');
            this.props.resumeVideo(this.props.currentIndex, false, true);
        }

    }

    noClickHandler = () => {

        if (this.questionType !== 'EndOfSection') {
            this.props.resumeVideo(this.props.currentIndex, false, false);
        } else {
            if (this.questionType === 'EndOfSection') {
                this.props.resumeVideo(this.props.currentIndex, false, true, this.currentTimestamp);
            } else {
                this.props.resumeVideo(this.props.currentIndex, false, true);
            }
        }
    }

    render() {

        return (
            <div>
                {this.state.content.map(item => item)}

                {this.buttonType === 'YN' ?
                    <div className='d-flex mt-3'>
                        <button onClick={this.yesClickHandler} className='mr-3 px-4 font-weight-bold'>Yes</button>
                        <button onClick={this.noClickHandler} className='px-4 font-weight-bold'>No</button>
                    </div>
                    : null}
                {this.buttonType === 'Continue' ?
                    <button onClick={this.continueClickHandler} className='mt-2 font-weight-bold'>{this.continueBtnText ? this.continueBtnText : 'Continue'}</button>
                    : null}
                {this.buttonType === 'Continue See Answer' ?
                    <>
                        <button onClick={this.continueClickHandler} className='mr-3 px-4 font-weight-bold'>{this.continueBtnText ? this.continueBtnText : 'Continue'}</button>
                        {this.correctAnswerImagePath != null || this.correctAnswerImageDescription != null ?
                            <>
                                <button onClick={this.seeAnswerClickHandler} className='mr-3 px-4 font-weight-bold'>See answer</button>
                                {this.correctAnswerImageDescription != null ?
                                    <p ref={this.correctAnswerImageRef} className="mt-2 answer-desc">{this.correctAnswerImageDescription}</p>
                                    : null}
                                {this.correctAnswerImagePath != null ?
                                    <img ref={this.correctAnswerImageRef} className="img-fluid mt-2 answer-img" src={this.correctAnswerImagePath} alt="answer image" />
                                    : null}
                            </>
                            : null}
                    </>
                    : null}

            </div>
        );
    }
}