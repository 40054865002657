import React, { Component } from "react";
import "./SessionSynopsis.scss";
import synopsisGraph from "../../../assets/images/synopsis_graph.jpg";
//import questionImage from "../../../assets/images/eqQuestion_1.png";
import Draggable from "react-draggable";
import Button from "../../Common/Button/Button";
import ReactDOMServer from "react-dom/server";
import { toast } from "react-toastify";
import HtmlContentSynposis from "../../CurriculumContent/MainContent/HtmlContent/HtmlContentSynposis";
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Spinner from "react-bootstrap/Spinner";
import Flow from '../../../components/SolutionGraph/flow';
import {
  initiateSynopsis, generateSVGFromMathML, storeSynopsis
  , getSynopsisEquations, imgURL, getMLForInteraction, fetchKnowledgeGraph, getSynopsisData,
  getchallengesubsection
} from "../../../common/API";
import { Call_Type } from '../../../common/Functions'
import { clone, isEmpty, isNil, orderBy, filter } from "lodash";
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import parse from 'html-react-parser';
import Select from 'react-select';
import { withStyles } from '@material-ui/core/styles';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import SelectMui from '@mui/material/Select';
import CircularProgress from '@mui/material/CircularProgress';
import MathJaxRenderer from "../../Common/MathJaxRenderer/MathJaxRenderer";

const SHOW_MORE = "Show more";
const SHOW_LESS = "Show less";



const AccordionSummaryD = withStyles({
  expanded: { backgroundColor: "#e6e6e6", borderRadius: "2px", marginBottom: "15px", paddingTop: "0px", paddingBottom: "0px", width: "100%" },
})(AccordionSummary);
class SessionSynopsisWithData extends Component {

  constructor(props) {
    super(props);
    this.myRef = React.createRef();
  }
  state = {
    questionImage: "",
    videoContent: "",
    isChallenge: true,
    isFinalStage: false,
    SuggestionsDict: {},
    finalSynopsis: {
      question: "",
      answer: "",
      remedial: "",
      resource: "",
      hint: "",
      rca: "",
      leadingquestion: "",
      requisiteknow: [],
      resourcetopic: [],
      shownodedropdown: false,
      iscorrectnode: null,
      correctnodedata: [],
      selectednodeid: 0,
      selectednodedata: null,
      showconfirmationalert: false,
      buttonpaneldisbaled: false,
      isloeadingforhighlight: false

    },
    // Highlighter
    showHighlighter: false,
    highlightedText: "",
    editHighlight: {},
    highlightedObject: null,
    highlightedObjectList: [],
    highlighterPosition: {
      top: 0,
      left: 0,
    },
    isLoading: this.props.isLoading,
    transcripts: this.props.transcripts,
    errorStep: null,
    synopsisEquations: [],
    isKnowledgeGraphData: null,
    sessionSynopsisData: null,
    isSessionSynopsisLoading: false,
    istutortostudent: false,
    transcriptstudentname: null,
    updatedcontent: [],
    updatecontentswitchlabels: [],
    customquestion: "",
    //checkloader:false
    dataSizeLabel: SHOW_MORE,
    errorRootCauseValue: null,
    errorRootCauseOption: null,
    allerrorroutcausedata: null,
    resourcetopicvalue: null
    //checkloader:false
  };

  synopsisInitialized = false;
  htmlContent = undefined

  handleconfirmationalert = () => {
    this.setState({ showconfirmationalert: true })
  }
  handleconfirmationalertcolse = () => {
    this.setState({ showconfirmationalert: false })
  }

  setStudentError = (latex) => {
    //alert("2222")
    const svg2img = svg => {
      var placeholder = document.createElement('div');
      placeholder.innerHTML = svg
      var elem = placeholder.firstChild;

      var xml = new XMLSerializer(svg).serializeToString(elem);
      var svg64 = ''
      try {
        svg64 = btoa(xml); //for utf8: btoa(unescape(encodeURIComponent(xml)))
      } catch (e) {
        svg64 = btoa(unescape(encodeURIComponent(xml)))
      }
      var b64start = 'data:image/svg+xml;base64,';
      var image64 = b64start + svg64;
      return image64;
    }

    const latexReqData = {
      params: {
        format: "svg",
        fontSize: "18px",
        latex: latex,
      },
    };
    generateSVGFromMathML(latexReqData)
      .then((res) => {
        const svg = res.data;
        const base64 = svg2img(svg);
        this.setState({ errorStep: base64 });
      })
      .catch((err) => {
        console.error("Unable to convert latex to svg");
      });
  }

  initiateSynopsis = () => {
    //alert("11111")
    //console.log("here .................................................")
    const fetchSynopsisEquations = () => {
      getSynopsisEquations({
        params: {
          roomId: this.props.synopsisData.roomId,
        }
      })
        .then((res) => {
          this.setState({ synopsisEquations: res.data.equations });
        })
        .catch((err) => {
          console.error(err.message);
        });
    }

    if (
      this.props.synopsisData.roomId &&
      this.props.synopsisData.mappingId &&
      !this.synopsisInitialized
    ) {
      this.synopsisInitialized = true;
      fetchSynopsisEquations()
      initiateSynopsis({
        roomId: this.props.synopsisData.roomId,
        mappingId: this.props.synopsisData.mappingId,
        callType: this.props.call_type
      })
        .then((res) => {
          this.setStudentError(res.data.latex);
          if (res.data.questionImage != undefined) {
            this.state.questionImage = res.data.questionImage;
            //console.log("Image....." + res.data.questionImage);
          }
          else {
            this.state.isChallenge = false
            this.state.videoContent = res.data.videocontent;
            this.state.videoContent = res.data.videocontent;
            this.state.customquestion = res.data.latex
          }
        })
        .catch((err) => {
          console.error(err.message);
        });
      //console.log("Image changed............"+this.synopsis.questionImage)
    }
  };

  getMLStudentTutorInteraction = (contenthtml, tutorname) => {
    this.setState({ isloeadingforhighlight: true })
    let reqData = {
      inputhtmldata: contenthtml,
      tutorname: tutorname,
    }
    getMLForInteraction(reqData).then((res) => {
      this.setState({ isloeadingforhighlight: false })
      //debugger;

      this.setMLHighlights(
        res.data.taggedoutput.DiagnosticQ != undefined ? res.data.taggedoutput.DiagnosticQ : [],
        res.data.taggedoutput.DiagnosticA != undefined ? res.data.taggedoutput.DiagnosticA : [],
        res.data.taggedoutput.RemedialInstruction != undefined ? res.data.taggedoutput.RemedialInstruction : [],
        res.data.taggedoutput.Resource != undefined ? res.data.taggedoutput.Resource : [],
        res.data.taggedoutput.Hint != undefined ? res.data.taggedoutput.Hint : [],
        res.data.taggedoutput.ErrorRootCauseIdentification != undefined ? res.data.taggedoutput.ErrorRootCauseIdentification : [],
        res.data.taggedoutput.Leading_Question != undefined ? res.data.taggedoutput.Leading_Question : [],
      );
      this.setState({ SuggestionsDict: res.data.taggedoutput.SuggestionsDict != undefined ? res.data.taggedoutput.SuggestionsDict : {} })
      console.log("here1111--------------------------------------------------------", res.data.taggedoutput.SuggestionsDict)
      console.log(this.state.SuggestionsDict)
    }).catch((err) => { console.error(err.message) });

  };

  decodeHTMLEntities = (str) => {
    // const parser = new DOMParser();
    // const doc = parser.parseFromString(str, 'text/html');
    // return doc.documentElement.textContent;
    var textArea = document.createElement('textarea');
    textArea.innerHTML = str;
    return textArea.value;
  }

  updateSynopsisData = () => {

    let data = this.htmlContent
    if (this.state.updatedcontent.length > 0 && this.state.updatedcontent[0] !== "") {
      let stringreplace = this.state.updatedcontent[0]
      //.replace(/<\/?span[^>]*>/g, "");
      data = parse(stringreplace)
    }
    storeSynopsis({
      roomId: this.props.synopsisData.roomId,
      content: ReactDOMServer.renderToStaticMarkup(data),
      highlights: this.state.highlightedObjectList,
    })
      .then((res) => {
        if (this.props.synopsisData.call_type === Call_Type.Challenge) {
          if (res.data.correctnodes !== undefined && res.data.correctnodes !== null && res.data.correctnodes.length > 0) {
            this.setState({ shownodedropdown: true, correctnodedata: res.data.correctnodes, iscorrectnode: res.data.iscorrectnode })
          }
          else {
            this.setState({ shownodedropdown: false, correctnodedata: [], iscorrectnode: null })
          }
        }
        else {
          this.setState({ shownodedropdown: false, correctnodedata: null, iscorrectnode: null })
        }
      })
      .catch((err) => {
        console.error(err.message);
      });

  };

  updateSynopsis = (index, type, value) => {
    let tempCollection = clone(this.state.finalSynopsis);
    tempCollection[index][type] = value;
    this.setState({ finalSynopsis: tempCollection })

  }
  handleisresourcereusable = (index, type, value) => {

    let tempCollection = clone(this.state.finalSynopsis);
    tempCollection[index][type] = value === true ? 1 : 0;
    this.setState({ finalSynopsis: tempCollection })

  }
  confirmSynopsis = () => {
    console.log("before.....................", this.state.finalSynopsis)
    let tempCollection = clone(this.state.finalSynopsis);

    for (var i = 0; i < tempCollection.length; i++) {
      delete tempCollection[i].resourcetopic;
      delete tempCollection[i].requisiteknow;
    }
    let data1 = tempCollection
    storeSynopsis({
      roomId: this.props.synopsisData.roomId,
      status: 'finalized',
      node_id: this.state.selectednodeid === 0 ? "" : this.state.selectednodeid,
      // question: this.state.finalSynopsis.question,
      // answer: this.state.finalSynopsis.answer,
      // remedial: this.state.finalSynopsis.remedial,
      // resource: this.state.finalSynopsis.resource,
      // hint: this.state.finalSynopsis.hint,
      // rca: this.state.finalSynopsis.rca,
      synopsis: JSON.stringify(data1)
    })
      .then((res) => {

      })
      .catch((err) => {
        console.error(err.message);
      });

  };

  preLoadedContent = (
    <>

      <p className="d-flex align-items-center justify-content-center">
        <div className="progress-bar-container">
          <Spinner animation="border" role="status" />
        </div>
        <span className="d-flex align-items-center justify-content-center mt-2">
          Fetching Transcript
        </span>
      </p>
    </>
  );

  postLoadingContent = null;

  getContent = () => {

    let userTranscripts = {};
    let users = [];
    let tutorUserName = undefined
    let transcript = "";
    let user_transcripts = ""



    if (this.state.updatedcontent.length > 0 && this.state.updatedcontent[0] !== "") {

      // setTimeout(() => {
      this.postLoadingContent = <div
        dangerouslySetInnerHTML={{
          __html: this.state.updatedcontent[0],
        }}
      ></div>

      this.htmlContent = this.postLoadingContent
      //}, 500);


    }
    else {
      //console.log("getting content.....", this.state.transcripts)
      for (const userId in this.state.transcripts) {
        if (this.state.transcripts.hasOwnProperty(userId)) {
          const transcriptObj = this.state.transcripts[userId];
          let userName = "";
          for (const transcriptId in transcriptObj) {
            if (transcriptObj.hasOwnProperty(transcriptId)) {
              const obj = transcriptObj[transcriptId];
              userName = obj.userName;
              tutorUserName = userName;
              //console.log("TUTORNAME from transcript:", tutorUserName)
              if (obj.isTutor) {
                tutorUserName = userName
              }
              if (obj.status === "Completed") {
                tutorUserName = obj.userName;
                transcript += obj.transcript + " ";
              }
            }
          }
          user_transcripts = transcript

          // userTranscripts[userName] = transcript;
          // if (!users.includes(userName)) users.push(userName);
        }
      }


      if (user_transcripts) {
        const printableText = user_transcripts.replace(/(?:\r\n|\r|\n)/g, "<br />");
        this.postLoadingContent = (
          <>
            {
              this.state.questionImage !== undefined && this.state.questionImage !== null && this.state.questionImage !== "" ? (<img src={imgURL + this.state.questionImage} alt="Question Image" style={{ width: "300px", height: "200px" }} />)
                : (
                  <p
                    className="first-class"
                    dangerouslySetInnerHTML={{
                      __html: this.state.customquestion,
                    }}
                  ></p>
                )
            }
            <p
              ref={this.myRef}
              className="first-class"
              dangerouslySetInnerHTML={{
                // __html: users[0] + ": " + userTranscripts[users[0]],
                __html: printableText,

              }}
            ></p>

            {/* {users[0] === tutorUserName ? this.state.synopsisEquations.map(item => {
            return <img className='synopsis-equation' src={item.source} alt="Equation" />
          }) : null} */}

            {/* {this.state.synopsisEquations.map(item => {
            return <img className='synopsis-equation' src={item.source} alt="Equation" />
          })} */}
            {/* <img src={eq1} alt="Equation" /> */}

            {/* {users.map((user, index) => {
            if (index > 0) {
              return (
                <>
                  <p
                    className={index}
                    dangerouslySetInnerHTML={{
                      __html: user + ": " + userTranscripts[user],
                    }}
                  ></p>
                  {user === tutorUserName ? this.state.synopsisEquations.map(item => {
                    return <img className='synopsis-equation' src={item.source} alt="Equation" />
                  }) : null}
                </>
              );
            }
          })} */}


          </>
        );
        this.htmlContent = this.postLoadingContent
        this.updateSynopsisData()
        //console.log("updated 1", this.props.synopsisData.content, this.htmlContent)
        if (this.htmlContent != undefined) {
          //console.log(this.state.highlightedObjectList, this.props.synopsisData.highlights)
          if (this.props.synopsisData.highlights === undefined || this.props.synopsisData.highlights.length <= 0) {

            this.getMLStudentTutorInteraction(ReactDOMServer.renderToStaticMarkup(this.htmlContent), tutorUserName);
          }


        }
      }
    }

    return this.postLoadingContent;
  };

  onMouseUpHandler = (e) => {
    let isTouchEvent = false;
    if (e.nativeEvent instanceof TouchEvent) {
      isTouchEvent = true;
    }

    e.preventDefault();
    const selectionObj = window.getSelection && window.getSelection();
    const selection = selectionObj.toString();

    let getSelectionHtml = () => {
      var html = "";
      if (typeof window.getSelection != "undefined") {
        var sel = window.getSelection();
        if (sel.rangeCount) {
          var container = document.createElement("div");
          for (var i = 0, len = sel.rangeCount; i < len; ++i) {
            container.appendChild(sel.getRangeAt(i).cloneContents());
          }
          html = container.innerHTML;
        }
      } else if (typeof document.selection != "undefined") {
        if (document.selection.type == "Text") {
          html = document.selection.createRange().htmlText;
        }
      }
      return html;
    };

    if (selection !== "") {
      const htmlSelection = getSelectionHtml();
      //console.log("htmlSelection: ", htmlSelection);
      //console.log("selection: ", selection);

      if (selection !== htmlSelection) {
        toast(
          "Oops, the system doesn't support mixed content highlights as of now.",
          {
            type: toast.TYPE.WARNING,
            position: toast.POSITION.BOTTOM_RIGHT,
          }
        );
        return;
      }

      const anchorNode = selectionObj.anchorNode;
      const focusNode = selectionObj.focusNode;
      const anchorOffset = selectionObj.anchorOffset;
      const focusOffset = selectionObj.focusOffset;
      const position = anchorNode.compareDocumentPosition(focusNode);
      let forward = false;

      if (position === anchorNode.DOCUMENT_POSITION_FOLLOWING) {
        forward = true;
      } else if (position === 0) {
        forward = focusOffset - anchorOffset > 0;
      }

      let selectionStart = forward ? anchorOffset : focusOffset;

      if (forward) {
        if (
          anchorNode.parentNode.getAttribute("data-order") &&
          anchorNode.parentNode.getAttribute("data-order") === "middle"
        ) {
          selectionStart += this.state.selectionStart;
        }
        if (
          anchorNode.parentNode.getAttribute("data-order") &&
          anchorNode.parentNode.getAttribute("data-order") === "last"
        ) {
          selectionStart += this.state.selectionEnd;
        }
      } else {
        if (
          focusNode.parentNode.getAttribute("data-order") &&
          focusNode.parentNode.getAttribute("data-order") === "middle"
        ) {
          selectionStart += this.state.selectionStart;
        }
        if (
          focusNode.parentNode.getAttribute("data-order") &&
          focusNode.parentNode.getAttribute("data-order") === "last"
        ) {
          selectionStart += this.state.selectionEnd;
        }
      }

      const selectionEnd = selectionStart + selection.length;

      if (e.target.hasAttribute("class")) {
        var cList = e.target.classList;
        cList = cList[0];
      } else {
        var cList = e.target.id;
      }

      let rect = e.target.getBoundingClientRect();

      if (cList == null || cList === "hint" || cList === "highlight") {
        // Ignore selection if it is along with the hint.
        return;
      }

      let oRange = window.getSelection().getRangeAt(0); //get the text range
      let oRect = oRange.getBoundingClientRect();

      let topPosition = rect.top + 15; //e.pageY + 15
      let leftPosition = oRect.left - rect.left + 200;
      if (isTouchEvent) {
        var touch = e.changedTouches[0];
        topPosition = touch.pageY + 15;
        leftPosition = touch.pageX;
      }

      this.setState({
        showHighlighter: true,
        highlightedText: selection,
        highlighterPosition: {
          top: topPosition,
          left: leftPosition,
        },
        highlightedObject: {
          selectionStart,
          selectionEnd,
          focusNodeText: anchorNode.nodeValue,
          highlightedText: selection,
          elementClass: cList,
        },
      });
    } else if (this.state.showHighlighter && !isTouchEvent) {
      this.setState({
        showHighlighter: false,
        highlightedText: "",
        highlightedObject: null,
        editHighlight: {},
      });
    }
  };

  saveHighlight = (highlightObj, saveNote = false) => {

    if (highlightObj.highlighterColor) {
      if (
        this.state.highlightedObjectList.filter(
          (o) => o.focusNodeText === this.state.highlightedObject.focusNodeText
        ).length > 0
      ) {
        // When changing color of the same highlighting
        this.setState({
          highlightedObjectList: [
            ...this.changeListObject(
              this.state.highlightedObjectList,
              this.state.highlightedText,
              this.state.highlightedObject.selectionStart,
              {
                ...this.state.highlightedObject,
                highlighterColor: highlightObj.highlighterColor,
                note: highlightObj.note,
              }
            ),
          ],
          editHighlight:
            Object.keys(this.state.editHighlight).length !== 0
              ? {
                note: highlightObj.note,
                highlighterColor: highlightObj.highlighterColor,
              }
              : this.state.editHighlight,
        });
      } else {
        // When highlighting first 

        this.setState({
          highlightedObjectList: [
            ...this.state.highlightedObjectList,
            {
              ...this.state.highlightedObject,
              highlighterColor: highlightObj.highlighterColor,
              note: highlightObj.note,
            },
          ],
        });
      }
    } else {
      // When highlighting removed

      this.setState({
        highlightedObjectList: [
          ...this.changeListObject(
            this.state.highlightedObjectList,
            this.state.highlightedText,
            this.state.highlightedObject.selectionStart,
            null,
            true
          ),
        ],
        editHighlight:
          Object.keys(this.state.editHighlight).length !== 0
            ? {
              note: highlightObj.note,
              highlighterColor: highlightObj.highlighterColor,
            }
            : this.state.editHighlight,
      });
    }

    if (saveNote) {
      // Close highlighter when only inline note added.
      this.closeHighlighter();
    }
  };

  closeHighlighter = () => {
    this.setState({
      showHighlighter: false,
      highlightedText: "",
      highlightedObject: null,
      highlighterPosition: {
        top: 0,
        left: 0,
      },
      editHighlight: {},
    });
  };

  showHighlight = (event, range) => {
    let rect = event.target.getBoundingClientRect();
    let topPosition = rect.top + 15;
    let leftPosition = rect.left / 2 + 100;
    if (event.nativeEvent instanceof TouchEvent) {
      var touch = event.changedTouches[0];
      topPosition = touch.pageY + 15;
      leftPosition = touch.pageX;
    }

    this.state.highlightedObjectList.forEach((highlighObj) => {

      if (
        highlighObj.selectionStart === Number(range[0]) &&
        highlighObj.selectionEnd === Number(range[1])
      ) {
        this.setState({
          showHighlighter: true,
          highlightedText: highlighObj.highlightedText,
          highlighterPosition: {
            top: topPosition,
            left: leftPosition,
          },
          highlightedObject: highlighObj,
          editHighlight: {
            note: highlighObj.note,
            highlighterColor: highlighObj.highlighterColor,
          },
        });
      }
    });
  };

  deleteHighlight = (noteObj) => {

    this.setState({
      highlightedObjectList: [
        ...this.changeListObject(
          this.state.highlightedObjectList,
          this.state.highlightedText,
          this.state.highlightedObject.selectionStart,
          null,
          true
        ),
      ],
      editHighlight: {},
    });
    this.closeHighlighter();
  };

  changeListObject = (
    arr,
    highlightedText,
    startPosition,
    newVal,
    removeValue = false
  ) => {
    var i = arr.length;
    while (i--) {
      if (
        arr[i] &&
        arr[i].hasOwnProperty("highlightedText") &&
        arr[i].hasOwnProperty("selectionStart") &&
        arr[i]["highlightedText"] === highlightedText &&
        arr[i]["selectionStart"] === startPosition
      ) {
        if (removeValue) {
          arr.splice(i, 1);
        } else {
          arr[i] = newVal;
        }
      }
    }
    return arr;
  };
  getActualIndex = () => {
    let rawContent = ReactDOMServer.renderToStaticMarkup(this.htmlContent);
    let tempCollection = [];
    this.state.highlightedObjectList.map((item) => {

      item['actualIndex'] = rawContent.indexOf(item.highlightedText)
      tempCollection.push(item)
    })

    this.setState({ highlightedObjectList: orderBy(tempCollection, ['actualIndex'], ['asc']) });
  }
  onSynopsisFinalize = (e) => {

    this.setState({ shownodedropdown: false, correctnodedata: null })
    // let question = "";
    // let answer = "";
    // let remedial = "";
    // let resource = "";
    // let hint = "";
    // let rca = "";
    // let requisiteknow = [];
    // let resourcetopic = [];
    let synopsisCollection = [];
    this.getActualIndex();

    //console.log(this.state.highlightedObjectList)
    if (this.props.fromHistory &&
      this.props.synopsisData.highlights === this.state.highlightedObjectList &&
      this.props.synopsisData.question !== null && this.props.synopsisData.question !== ""
      && this.props.synopsisData.question !== undefined
      && this.props.synopsisData.question !== "null"
      && this.props.synopsisData.question !== 'undefined') {
      this.updateSynopsisData();
      let question = JSON.parse(this.props.synopsisData.question);
      // if (this.props.synopsisData.call_type === Call_Type.Challenge) {
      //   if (this.props.synopsisData.correctnodes !== null && this.props.synopsisData.correctnodes !== undefined && this.props.synopsisData.correctnodes.length > 0) {
      //     this.setState({ shownodedropdown: true, correctnodedata: this.props.synopsisData.correctnodes })
      //   }
      //   else {
      //     this.setState({ shownodedropdown: false, correctnodedata: null })
      //   }

      // }
      // else {
      //   this.setState({ shownodedropdown: false, correctnodedata: null })
      // }

      if (question !== undefined && question !== null && question.length > 0) {
        question.map((obj) => {
          //debugger;
          let finalSynopsis = {}
          finalSynopsis['question'] = obj.question
          finalSynopsis['answer'] = obj.answer
          finalSynopsis['remedial'] = obj.remedial
          finalSynopsis['resource'] = obj.resource
          finalSynopsis['hint'] = obj.hint
          finalSynopsis['rca'] = obj.rca
          finalSynopsis['leadingquestion'] = obj.leadingquestion

          if (obj.isreusable !== undefined) {
            finalSynopsis['isreusable'] = obj.isreusable
          }
          else {
            finalSynopsis['isreusable'] = 0
          }
          if (obj.errorrootcauseids !== undefined)
            finalSynopsis['errorrootcauseids'] = obj.errorrootcauseids

          if (!isNil(obj.errorrootcauseids)) {
            let defaultRequisiteKnowledge = obj.errorrootcauseids.split(',');
            let tempArr = []
            if (!isNil(defaultRequisiteKnowledge) && !isEmpty(defaultRequisiteKnowledge)) {
              defaultRequisiteKnowledge.forEach((item) => {
                const requisite = filter(this.state.allerrorroutcausedata['nodes'], ['subSectionId', parseInt(item)]);
                if (!isNil(requisite) && !isEmpty(requisite)) {
                  tempArr.push(requisite[0]);
                }
              })
            }
            finalSynopsis['requisiteknow'] = tempArr;
          } else {
            finalSynopsis['requisiteknow'] = [];
          }
          if (obj.resourcetopicid !== undefined)
            finalSynopsis['resourcetopicid'] = obj.resourcetopicid

          if (!isNil(obj.resourcetopicid)) {
            const requisite = filter(this.state.allerrorroutcausedata['nodes'], ['subSectionId', parseInt(obj.resourcetopicid)]);
            if (!isNil(requisite) && !isEmpty(requisite)) {
              finalSynopsis['resourcetopic'] = requisite;
            }
          } else {
            finalSynopsis['resourcetopic'] = [];
          }
          synopsisCollection.push(finalSynopsis)
        })
        //debugger;
      }

    } else {
      this.updateSynopsisData();


      let finalSynopsis = {}
      let SuggestionsDict = this.state.SuggestionsDict
      finalSynopsis = {
        question: '',
        answer: '',
        remedial: '',
        resource: '',
        hint: '',
        rca: '',
        leadingquestion: ''
      };
      const keys = Object.keys(SuggestionsDict);
      this.state.highlightedObjectList.map((obj) => {
        if (!obj.ignore) {
          const wantedKey = keys.find(key => key.includes(obj.highlightedText));
          let suggested = wantedKey ? SuggestionsDict[wantedKey] : obj.highlightedText;
          //debugger;
          switch (obj.highlighterColor) {
            case "orange":
              finalSynopsis = {
                question: '',
                answer: '',
                remedial: '',
                resource: '',
                hint: '',
                rca: '',
                leadingquestion: ''
              };
              synopsisCollection.push(finalSynopsis);
              //finalSynopsis['question'] = obj.highlightedText;
              break;
            case "yellow":
              // finalSynopsis = {
              //   question: '',
              //   answer: '',
              //   remedial: '',
              //   resource: '',
              //   hint: '',
              //   rca: ''
              // };
              // synopsisCollection.push(finalSynopsis);

              // finalSynopsis['question'] = finalSynopsis['question'] + " " + obj.highlightedText;   
              finalSynopsis['question'] = finalSynopsis['question'] + " " + suggested;

              console.log("suggestion1--------------------------------", obj.highlightedText)
              console.log("suggestion--------------------------------", finalSynopsis['question'])
              break;
            case "green": // blue
              finalSynopsis['remedial'] = finalSynopsis['remedial'] + " " + suggested;
              console.log("suggestion1--------------------------------", obj.highlightedText)
              console.log("suggestion--------------------------------", finalSynopsis['remedial'])
              break;
            case "grey":
              finalSynopsis['answer'] = finalSynopsis['answer'] + " " + suggested;

              console.log("suggestion1--------------------------------", obj.highlightedText)
              console.log("suggestion--------------------------------", finalSynopsis['answer'])
              break;
            case "blue":
              finalSynopsis['resource'] = finalSynopsis['resource'] + " " + suggested;
              console.log("suggestion1--------------------------------", obj.highlightedText)
              console.log("suggestion--------------------------------", finalSynopsis['resource'])
              break;
            case "pink":
              finalSynopsis['hint'] = finalSynopsis['hint'] + " " + suggested;
              console.log("suggestion1--------------------------------", obj.highlightedText)
              console.log("suggestion--------------------------------", finalSynopsis['hint'])
              break;
            case "purple":
              finalSynopsis['rca'] = finalSynopsis['rca'] + " " + suggested;
              console.log("suggestion1--------------------------------", obj.highlightedText)
              console.log("suggestion--------------------------------", finalSynopsis['rca'])
              break;
            case "darkgreen":
              finalSynopsis['leadingquestion'] = finalSynopsis['leadingquestion'] + " " + suggested;
              console.log("suggestion1--------------------------------", obj.highlightedText)
              console.log("suggestion--------------------------------", finalSynopsis['leadingquestion'])
              break;
            default:
              break;
          }
        }
      });
      //console.log(synopsisCollection)
    }
    let questiondata = []
    if (this.props.synopsisData.question !== null && this.props.synopsisData.question !== ""
      && this.props.synopsisData.question !== undefined
      && this.props.synopsisData.question !== "null"
      && this.props.synopsisData.question !== 'undefined') {
      questiondata = JSON.parse(this.props.synopsisData.question);
    }
    if (questiondata !== undefined && questiondata !== null && questiondata !== "" && questiondata.length > 0 && synopsisCollection !== undefined && synopsisCollection !== null && synopsisCollection.length > 0) {
      synopsisCollection.map((obj) => {
        let filtereddata = questiondata.find(item => item.question === obj.question)
        if (filtereddata !== undefined && filtereddata !== null) {
          if (obj.isreusable === undefined) {
            obj['isreusable'] = filtereddata.isreusable === undefined ? 0 : filtereddata.isreusable
          }
          if (obj.errorrootcauseids === undefined) {
            obj['errorrootcauseids'] = filtereddata.errorrootcauseids === undefined ? "" : filtereddata.errorrootcauseids
            if (filtereddata.errorrootcauseids !== undefined) {
              let defaultRequisiteKnowledge = filtereddata.errorrootcauseids.split(',');
              let tempArr = []
              if (!isNil(defaultRequisiteKnowledge) && !isEmpty(defaultRequisiteKnowledge)) {
                defaultRequisiteKnowledge.forEach((item) => {
                  const requisite = filter(this.state.allerrorroutcausedata['nodes'], ['subSectionId', parseInt(item)]);
                  if (!isNil(requisite) && !isEmpty(requisite)) {
                    tempArr.push(requisite[0]);
                  }
                })
              }
              obj['requisiteknow'] = tempArr;
            }
            else {
              obj['requisiteknow'] = [];
            }
          }

          if (obj.resourcetopicid === undefined) {
            obj['resourcetopic'] = filtereddata.resourcetopicid === undefined ? "" : filtereddata.resourcetopicid
            if (filtereddata.resourcetopicid !== undefined) {
              const requisite = filter(this.state.allerrorroutcausedata['nodes'], ['subSectionId', parseInt(filtereddata.resourcetopicid)]);
              if (!isNil(requisite) && !isEmpty(requisite)) {
                obj['resourcetopic'] = requisite;
              }
              else {
                obj['resourcetopic'] = [];
              }
            }
          }
        }

      })
    }



    if (this.props.synopsisData.answer !== "" && this.props.synopsisData.answer !== undefined && this.props.synopsisData.answer !== null) {
      this.setState({ selectednodeid: this.props.synopsisData.answer })
    }
    else {
      this.setState({ selectednodeid: 0 })
    }


    if (this.props.synopsisData.correctnodes !== null && this.props.synopsisData.correctnodes !== undefined && this.props.synopsisData.correctnodes.length > 0) {
      if (!isNil(this.props.synopsisData.answer)) {
        const requisite = filter(this.props.synopsisData.correctnodes, ['node_id', parseInt(this.props.synopsisData.answer)]);
        if (!isNil(requisite) && !isEmpty(requisite) && requisite.length > 0) {
          this.setState({ selectednodedata: requisite });
        }
        else {
          this.setState({ selectednodedata: [] });
        }
      } else {
        this.setState({ selectednodedata: [] });
      }
    }
    if (this.props.synopsisData.status === "Finalized") {
      this.setState({ buttonpaneldisbaled: true })
    }
    else {
      this.setState({ buttonpaneldisbaled: false })
    }
    console.log("check synopsisfill..............................", synopsisCollection)
    this.setState({
      finalSynopsis: synopsisCollection,
      showHighlighter: false,
      isFinalStage: true,
    });
  };
  componentDidUpdate(prevProps) {
    if (this.props !== prevProps) {
      this.setState({
        isFinalStage: false,
        isLoading: this.props.isLoading,
        transcripts: this.props.transcripts,
        showHighlighter: false,
        highlightedText: "",
        editHighlight: {},
        highlightedObject: null,
        highlightedObjectList: [],
        istutortostudent: false,
        highlighterPosition: {
          top: 0,
          left: 0,
        },
      });

      // if (this.props.synopsisData.challengeId !== prevProps.synopsisData.challengeId) {
      //   this.getKnowledgeGraph();
      //   this.getrequisiteKnowledge()
      // }
      if (!this.props.isHistoryView && (this.props.synopsisData.roomId !== prevProps.synopsisData.roomId)) {
        this.setState({ sessionSynopsisData: null })
        this.getSynopsisData();
        this.getKnowledgeGraph();
        this.getrequisiteKnowledge();

      } else if (this.props.isHistoryView && (this.props.synopsisId !== prevProps.synopsisId) && this.props.synopsisId !== -1) {
        this.setState({ sessionSynopsisData: null })
        this.setState({ isKnowledgeGraphData: null })
        this.getSynopsisData();
        this.getKnowledgeGraph();
        this.getrequisiteKnowledge()
      }
      //console.log(this.props.synopsisData.content, this.props.synopsisData.highlights)
      if (!this.synopsisInitialized && !this.props.fromHistory) this.initiateSynopsis();
      if (this.props.synopsisData.content != undefined) {
        if (this.props.synopsisData.highlights.length <= 0) {

          this.getMLStudentTutorInteraction(this.props.synopsisData.content, this.props.synopsisData.tutor);
        }

      }
      if (this.props.fromHistory) {

        this.setState({ highlightedObjectList: this.props.synopsisData.highlights || [] });
        //console.log("From historyyyyyyyy" + this.props.synopsisData.content);
        this.postLoadingContent = <div dangerouslySetInnerHTML={{ __html: this.props.synopsisData.content }}></div>
        this.setStudentError(this.props.synopsisData.studentError)

      }
    }
  }

  createHighlight = (startIndex, endIndex, focusNodeText, highlightedText, highlightcolor) => {
    let genhighlight = ""
    genhighlight = {
      "selectionStart": startIndex,
      "selectionEnd": endIndex,
      "focusNodeText": focusNodeText,
      "highlightedText": highlightedText,
      "elementClass": "",
      "highlighterColor": highlightcolor,
      "note": "",
      "ignore": false
    };
    return genhighlight;
  }
  setMLHighlights = (arrtagDQ, arrtagDA, arrtagremedy, arrtagresource, arrtaghint, arrtagRCA, arrtagleadingquestion) => {

    let highlightedobj = []
    if (this.props.synopsisData.highlights == undefined)
      this.props.synopsisData.highlights = []
    let dqhighlight = ""
    let dahighlight = ""
    let resourcehighlight = ""
    let remedyhighlight = ""
    let hinthighlight = ""
    let rcahighlight = ""
    let leadinghighlight = ""
    let startIndex = 0
    let endIndex = 0
    for (let i = 0; i < arrtagDQ.length; i++) {
      //debugger;
      let tagDQ = arrtagDQ[i].substring(arrtagDQ[i].indexOf(":") + 1, arrtagDQ[i].length);
      //console.log(tagDQ)
      startIndex = 0;//content.indexOf(tagDQ);
      endIndex = tagDQ.length;//startIndex + tagDQ.length
      dqhighlight = this.createHighlight(startIndex, endIndex, tagDQ, tagDQ, "yellow")
      //console.log(startIndex, endIndex, tagDQ, tagDQ)
      this.props.synopsisData.highlights.push(dqhighlight)
      //highlightedobj.push(dqhighlight)
      //console.log(dqhighlight.selectionStart, dqhighlight.selectionEnd, dqhighlight.highlightedText)
    }
    for (let i = 0; i < arrtagDA.length; i++) {
      let tagDA = arrtagDA[i].substring(arrtagDA[i].indexOf(":") + 1, arrtagDA[i].length)
      //console.log(tagDA)
      startIndex = 0; //content.indexOf(tagDA);
      endIndex = tagDA.length; //startIndex + tagDA.length
      dahighlight = this.createHighlight(startIndex, endIndex, tagDA, tagDA, "grey")
      this.props.synopsisData.highlights.push(dahighlight)
      //highlightedobj.push(dqhighlight)
    }
    for (let i = 0; i < arrtagremedy.length; i++) {
      let tagremedy = arrtagremedy[i].substring(arrtagremedy[i].indexOf(":") + 1, arrtagremedy[i].length)
      //console.log(tagremedy)
      startIndex = 0; // content.indexOf(tagremedy);
      endIndex = tagremedy.length; //artIndex + tagremedy.length
      remedyhighlight = this.createHighlight(startIndex, endIndex, tagremedy, tagremedy, "green")
      this.props.synopsisData.highlights.push(remedyhighlight)
      // highlightedobj.push(dqhighlight)
    }
    for (let i = 0; i < arrtagresource.length; i++) {
      let tagresource = arrtagresource[i].substring(arrtagresource[i].indexOf(":") + 1, arrtagresource[i].length)
      //console.log(tagresource)
      startIndex = 0; //content.indexOf(tagresource);
      endIndex = tagresource.length; //startIndex + tagresource.length
      resourcehighlight = this.createHighlight(startIndex, endIndex, tagresource, tagresource, "blue")
      //console.log("pushing..")
      this.props.synopsisData.highlights.push(resourcehighlight)
      //highlightedobj.push(resourcehighlight)
      // console.log("pushed..")
      // console.log(this.props.synopsisData.highlights[0].highlightedText)
      // console.log(this.props.synopsisData.highlights.length)
      // console.log(resourcehighlight.selectionStart, resourcehighlight.selectionEnd, resourcehighlight.highlightedText)
    }
    for (let i = 0; i < arrtaghint.length; i++) {
      let taghint = arrtaghint[i].substring(arrtaghint[i].indexOf(":") + 1, arrtaghint[i].length)
      startIndex = 0; //content.indexOf(tagresource);
      endIndex = taghint.length; //startIndex + tagresource.length
      hinthighlight = this.createHighlight(startIndex, endIndex, taghint, taghint, "pink")
      this.props.synopsisData.highlights.push(hinthighlight)
      //highlightedobj.push(hinthighlight)
    }
    for (let i = 0; i < arrtagRCA.length; i++) {
      let tagrca = arrtagRCA[i].substring(arrtagRCA[i].indexOf(":") + 1, arrtagRCA[i].length)
      startIndex = 0; //content.indexOf(tagresource);
      endIndex = tagrca.length; //startIndex + tagresource.length
      rcahighlight = this.createHighlight(startIndex, endIndex, tagrca, tagrca, "purple")
      this.props.synopsisData.highlights.push(rcahighlight)
      //highlightedobj.push(rcahighlight)
    }

    for (let i = 0; i < arrtagleadingquestion.length; i++) {
      let tagrca1 = arrtagleadingquestion[i].substring(arrtagleadingquestion[i].indexOf(":") + 1, arrtagleadingquestion[i].length)
      startIndex = 0; //content.indexOf(tagresource);
      endIndex = tagrca1.length; //startIndex + tagresource.length
      leadinghighlight = this.createHighlight(startIndex, endIndex, tagrca1, tagrca1, "darkgreen")
      this.props.synopsisData.highlights.push(leadinghighlight)
      //highlightedobj.push(rcahighlight)
    }



    if (this.props.synopsisData.highlights != undefined)
      this.setState({ highlightedObjectList: this.props.synopsisData.highlights });

    // if (highlightedobj != undefined)
    //   this.setState({ highlightedObjectList: highlightedobj});

  }

  getKnowledgeGraph = () => {
    if (!isNil(this.props.synopsisData.challengeId) && !isEmpty(this.props.synopsisData.challengeId) && this.props.isHistoryView) {
      fetchKnowledgeGraph({
        params: {
          'challengeId': this.props.synopsisData.challengeId
        }
      }).then(async res => {
        const ui_json = res.data.ui_json;
        this.setState({ isKnowledgeGraphData: ui_json })
      })
    }
  }

  getrequisiteKnowledge = () => {
    if (this.props.call_type === Call_Type.Challenge) {
      getchallengesubsection(this.props.challengeId).then(res => {
        if (res.status === 200) {

          this.setState({ errorRootCauseOption: res.data['Current'], allerrorroutcausedata: res.data })
          //setErrorRootCauseOption(res.data['Current']);
        } else {
          console.log("No data found");
        }
      })
    }

  }

  getSynopsisData = () => {
    if (!isNil(this.props.synopsisData.roomId) && !isEmpty(this.props.synopsisData.roomId) && this.props.isHistoryView) {
      this.setState({ isSessionSynopsisLoading: true })
      getSynopsisData(this.props.synopsisData.roomId).then(async res => {
        this.setState({ sessionSynopsisData: res.data.ui_json })
        this.setState({ isSessionSynopsisLoading: false })
      })
    }
  }
  handleswitchtutortostudent = () => {

    //setTimeout(() => {

    if (this.state.updatedcontent.length > 0) {
      this.setState({ updatecontentswitchlabels: this.state.updatedcontent[0] }, () => {
        this.setState({ istutortostudent: !this.state.istutortostudent, updatedcontent: [] })
        // this.postLoadingContent = null
        // this.postLoadingContent = this.state.updatedcontent[0]
      })
    }
    else {
      this.setState({ istutortostudent: !this.state.istutortostudent })
    }
    //}, 100);

  }
  getswappingdata(content) {

    let data1 = ""

    if (content !== null) {

      if (content.props !== undefined && content.props.children !== undefined) {
        if (this.state.transcripts !== undefined || this.state.transcripts !== null) {
          let tutorUserName = undefined
          let transcript = "";
          let user_transcripts = ""
          for (const userId in this.state.transcripts) {
            if (this.state.transcripts.hasOwnProperty(userId)) {
              const transcriptObj = this.state.transcripts[userId];
              let userName = "";

              for (const transcriptId in transcriptObj) {
                if (transcriptObj.hasOwnProperty(transcriptId)) {
                  const obj = transcriptObj[transcriptId];
                  userName = obj.userName;
                  tutorUserName = userName;

                  if (obj.isTutor) {
                    tutorUserName = userName
                  }
                  if (obj.status === "Completed") {
                    tutorUserName = obj.userName;
                    transcript += obj.transcript + " ";
                  }
                }
              }
              user_transcripts = transcript
            }
          }
          let datareturn = ""
          if (user_transcripts) {
            let printableText = user_transcripts.replace(/(?:\r\n|\r|\n)/g, "<br />");
            printableText = printableText.replaceAll("Tutor:", "Tutor_Old:");
            printableText = printableText.replaceAll("Student:", "Tutor:");
            printableText = printableText.replaceAll("Tutor_Old:", "Student:");
            datareturn = (
              <>
                <img src={imgURL + this.state.questionImage} alt="Question Image" style={{ width: "300px", height: "200px" }} />
                <p
                  className="first-class"
                  dangerouslySetInnerHTML={{

                    __html: printableText,

                  }}
                ></p>
              </>
            );
          }
          return datareturn;
        }
      }
      else {

        let data1 = content.props.dangerouslySetInnerHTML.__html;
        if (this.state.updatedcontent.length > 0 && this.state.updatedcontent[0] !== "") {
          data1 = this.state.updatedcontent[0]
        }
        let data2 = data1.replaceAll("Tutor:", "Tutor_Old:");
        let data3 = data2.replaceAll("Student:", "Tutor:");
        let data4 = data3.replaceAll("Tutor_Old:", "Student:");
        return <div
          dangerouslySetInnerHTML={{
            __html: data4,
          }}
        ></div>;
      }

    }

  }

  handleShowMore = (event, type, index) => {
    // if (this.state.dataSizeLabel === SHOW_MORE) {
    //   this.setState({ dataSizeLabel: SHOW_LESS, errorRootCauseOption: this.state.allerrorroutcausedata['nodes'] })
    // } else {
    //   this.setState({ dataSizeLabel: SHOW_MORE, errorRootCauseOption: this.state.allerrorroutcausedata['Current'] })
    // }
    if (this.state.allerrorroutcausedata !== null && this.state.allerrorroutcausedata !== undefined && this.state.allerrorroutcausedata.length > 0) {
      let tempCollection = clone(this.state.finalSynopsis);
      if (tempCollection[index][type] === SHOW_MORE) {
        tempCollection[index][type] = SHOW_LESS
        tempCollection[index]["requisiteknowdata"] = this.state.allerrorroutcausedata['nodes']
      }
      else {
        tempCollection[index][type] = SHOW_MORE
        tempCollection[index]["requisiteknowdata"] = this.state.allerrorroutcausedata['Current']
      }
      this.setState({ finalSynopsis: tempCollection })
    }


  }

  handleChangeRequisiteKnowledge = (selected, type, index) => {
    let str = "";
    this.setState({ errorRootCauseValue: selected })
    if (!isNil(selected) && !isEmpty(selected)) {
      let tempCollection = clone(this.state.finalSynopsis);
      tempCollection[index][type] = selected;


      selected.forEach((item, index) => {
        str += index === 0 ? item.subSectionId : ',' + item.subSectionId
      })
      tempCollection[index]["errorrootcauseids"] = str;

      this.setState({ finalSynopsis: tempCollection })

    }
    else {
      this.setState({ resourcetopicvalue: null })
      let tempCollection = clone(this.state.finalSynopsis);
      tempCollection[index][type] = null;
      tempCollection[index]["errorrootcauseids"] = null;
      this.setState({ finalSynopsis: tempCollection })
    }

  }
  handleresourcetopic = (selected, type, index) => {
    this.setState({ resourcetopicvalue: selected })
    if (!isNil(selected)) {
      let tempCollection = clone(this.state.finalSynopsis);
      tempCollection[index][type] = selected;
      tempCollection[index]["resourcetopicid"] = selected.subSectionId;
      this.setState({ finalSynopsis: tempCollection })

    }
    else {
      let tempCollection = clone(this.state.finalSynopsis);
      tempCollection[index][type] = null;
      tempCollection[index]["resourcetopicid"] = null
      this.setState({ finalSynopsis: tempCollection })
    }
  }

  handlenodeid = (selected) => {

    this.setState({ selectednodedata: selected })
    if (!isNil(selected)) {
      this.setState({ selectednodeid: selected.node_id })
    }
    else {
      this.setState({ selectednodeid: null })
    }
  }

  handleChangenodedata = (event) => {
    this.setState({ selectednodeid: event.target.value })
    this.props.synopsisData.answer = event.target.value
  }
  handleClearClick = () => {
    this.setState({ selectednodeid: "" })
    this.props.synopsisData.answer = ""
  };

  gethtmlhighlightcontent(content) {

    let innerHTML = ReactDOMServer.renderToStaticMarkup(content)
    //innerHTML = parse(innerHTML.replace(/(<([^>]+)>)/ig, ''))

    innerHTML = this.decodeHTMLEntities(innerHTML)

    //debugger;




    let hl = this.state.highlightedObjectList


    this.state.highlightedObjectList.map(highlightObj => {

      let start = highlightObj.selectionStart
      let end = highlightObj.selectionEnd
      let { focusNodeText, highlighterColor } = highlightObj
      let index = innerHTML.indexOf(highlightObj.elementClass);
      if (index >= 0) {

        let subHTML = innerHTML.substring(index, innerHTML.length)

        focusNodeText = focusNodeText.trim()
        //  focusNodeText="So here you can see that the bucket containing water is in a circular path moving. So uh at the bottom uh position like uh if the bucket is in the bottom position of the circular path, what are the forces uh experienced by the bucket which we have already mentioned almost all uh let's discuss about its direction"
        let mainContentStartIndex = subHTML.indexOf(focusNodeText)

        debugger;
        let mainContentEndIndex = mainContentStartIndex + focusNodeText.length
        let mainContent = subHTML.substring(mainContentStartIndex, mainContentEndIndex)
        //let highlightedText = mainContent
        // debugger;
        // focusNodeText =" "
        // subHTML = subHTML.replace(focusNodeText, "<span style='background-color:red'>@</span>")
        // console.log("focusNodeText.......", focusNodeText)



        // console.log("htmlcontent.......", subHTML)
        let highlightedText = mainContent.substring(start, end)
        let noteClass = highlightObj.note ? 'has-note' : ''
        if (highlightedText.trim() === highlightObj.highlightedText.trim()) {

          let backgroundcolor = ""
          switch (highlighterColor) {
            case "orange":
              backgroundcolor = "#ffa500";
              break;
            case "yellow":
              backgroundcolor = "#ffff8a";
              break;
            case "green": // blue
              backgroundcolor = "#bfd4ff";
              break;
            case "grey":
              backgroundcolor = "#c0c0c0";
              break;
            case "blue":
              backgroundcolor = "#5897fb";
              break;
            case "pink":
              backgroundcolor = "#bdd8b2";
              break;
            case "purple":
              backgroundcolor = "#ffc1c1";
              break;
            case "darkgreen":
              backgroundcolor = "#74ba56";
              break;
            case "white":
              backgroundcolor = "#ffffff";
              break;
            default:
              break;
          }
          let modifiedMainContent = mainContent.substring(0, start) + `<span style="background-color:${backgroundcolor}" name='${start}##${end}'>` + highlightedText + "</span>" + mainContent.substring(end, subHTML.length)
          //let modifiedMainContent = mainContent.substring(0, start) + `<span class='highlight highlight-${highlighterColor} ${noteClass}' name='${start}##${end}'>` + highlightedText + "</span>" + mainContent.substring(end, subHTML.length)
          let modifiedSubHTML = subHTML.substring(0, mainContentStartIndex) + modifiedMainContent + subHTML.substring(mainContentEndIndex, subHTML.length)
          innerHTML = innerHTML.substring(0, index) + modifiedSubHTML
        }
      }
    });
    return <div
      dangerouslySetInnerHTML={{
        __html: innerHTML,
      }}
    ></div>
  }




  render() {

    console.log("synopsis data..................................", this.state.sessionSynopsisData)
    let classes = null;
    if (this.props.show) {
      classes = ["session-synopsis-container editable", "show"];
    } else {
      classes = ["session-synopsis-container editable", "hide"];
    }
    let content = this.state.isLoading
      ? this.preLoadedContent
      : this.postLoadingContent === null
        ? this.getContent()
        : this.postLoadingContent;
    //console.log("I m Here")
    //console.log(content)
    if (this.state.istutortostudent) {
      content = this.getswappingdata(content)
    }

    if (this.state.highlightedObjectList !== undefined && this.state.highlightedObjectList.length > 0) {
      content = this.gethtmlhighlightcontent(content)
    }

    this.htmlContent = content
    return (
      <>
        <Dialog
          hideBackdrop={false}
          open={this.state.showconfirmationalert}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="alert-dialog-title">
            Alert
          </DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              Are you ready to finalize your Synopsis? Once submitted, it cannot be edited.
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <button className="dragLabel"
              onClick={(event) => {
                this.confirmSynopsis()
                toast("Synopsis confirmed successfully.", {
                  type: toast.TYPE.INFO,
                  position: toast.POSITION.BOTTOM_RIGHT,
                });
                this.props.onClose();
                this.setState({ showconfirmationalert: false })
              }}
            >
              Yes
            </button>
            <button className="dragLabelcancel leftmargin"
              onClick={this.handleconfirmationalertcolse}
            >
              No
            </button>
          </DialogActions>
        </Dialog>
        <Draggable cancel=".right-part">
          <div className={classes.join(" ")}>
            <div className="headerContainer">
              <h3 className="mb-3">
                {this.state.isFinalStage ? "Finalize Resource" : "Session Synopsis"}
              </h3>
              <button class="closeButton" onClick={(e) => this.props.onClose()}><i class="ico-times"></i></button>
            </div>

            {/* <HighlightText
            hasGreyHighlight={true}
            showLegends
            highlighterPosition={this.state.highlighterPosition}
            showHighlighter={this.state.showHighlighter}
            saveHighlight={this.saveHighlight}
            closeHighlighter={this.closeHighlighter}
            deleteHighlight={this.deleteHighlight}
            editMode={this.state.editHighlight}
          /> */}

            <div className="d-flex data">
              {this.props.isHistoryView ? <div className="left-part">
                {this.state.isChallenge ? (<h3>Student Error</h3>) : (<h3>Student Question</h3>)}
                {this.state.errorStep && !this.state.isSessionSynopsisLoading ? (
                  this.state.sessionSynopsisData !== null && this.state.sessionSynopsisData !== '[]' && this.state.sessionSynopsisData.length > 0 ?
                    <div style={{ height: '300px', width: '100%' }}>
                      <Flow readOnly={true} uiJson={this.state.sessionSynopsisData} hasCheckMyWOrk={true} isKnowledgeGraph={false}></Flow>
                    </div> : <img src={this.state.errorStep} alt="Error" />
                ) : (
                  <div className="progress-bar-container">
                    <Spinner animation="border" role="status" />
                  </div>
                )}

                <h3 className="mt-2">Knowledge Graph</h3>
                <p className="desc">Error Root Cause for Remedy or Diagnostics</p>
                {/* <img src={synopsisGraph} alt="Graph" /> */}
                {
                  this.props.synopsisData.challengeId !== 'c1' ? (this.state.isKnowledgeGraphData !== null ?
                    <div style={{ height: '500px', width: '350px' }}>
                      <Flow readOnly={true} uiJson={this.state.isKnowledgeGraphData} hasCheckMyWOrk={false} isKnowledgeGraph={true}></Flow>
                    </div> : null) : <img src={synopsisGraph} alt="Graph" />
                }
              </div> : null}

              <div className="right-part">
                {!this.state.isFinalStage ? (
                  <>
                    <p className="desc">
                      Please confirm or reject each suggested interpretation:
                    </p>
                    {/* {
                      !this.state.isLoading ? (<p className="desc" style={{ fontSize: "13px" }}>
                        Switch speaker labels?
                        <Switch size="small"
                          checked={this.state.istutortostudent}
                          onChange={(e, c) => this.handleswitchtutortostudent()} />
                      </p>) : null
                    } */}
                    <div
                      className="content"
                    //onMouseUp={this.onMouseUpHandler}
                    //onTouchEnd={this.onMouseUpHandler}
                    >
                      {/* {
                        this.state.highlightedObjectList !== undefined && this.state.highlightedObjectList.length > 0 ? ( */}
                      <HtmlContentSynposis
                        //newitemloading={this.props.newitemloading}
                        content={ReactDOMServer.renderToStaticMarkup(content)}
                        hintObjectList={[]}
                        istutortostudent={this.state.istutortostudent}
                        highlightedObjectList={this.state.highlightedObjectList}
                        showHighlight={this.showHighlight}
                        showHint={() => { }}
                        isHintVisible={false}
                        hideHint={() => { }}
                        displayid={this.props.synopsisData.session_synopsis_id}
                        iseditable={this.props.iseditable}
                        isloading={this.state.isLoading}
                        isloeadingforhighlight={this.state.isloeadingforhighlight}
                        updatedcontent={this.state.updatedcontent}

                      />
                      {/* ) : (
                          <CircularProgress
                            size={24}
                            sx={{
                              color: "#fdbd17",
                              position: 'absolute',
                              top: '50%',
                              left: '65%',
                              marginTop: '-12px',
                              marginLeft: '-12px',
                            }}
                          />
                        )

                      } */}
                    </div>
                    {
                      this.props.iseditable ? (<Button
                        clicked={this.onSynopsisFinalize}
                        className="mr-2 yellow-btn mt-2"
                      //disabled={this.state.highlightedObjectList != undefined ? this.state.highlightedObjectList.length <= 0 : -1}
                      >
                        Review
                      </Button>) : (<Button
                        disabled
                        className="mr-2 yellow-btn mt-2"
                      //disabled={this.state.highlightedObjectList != undefined ? this.state.highlightedObjectList.length <= 0 : -1}
                      >
                        Review
                      </Button>)
                    }

                  </>
                ) : (
                  <>

                    {
                      this.state.shownodedropdown ? (
                        this.state.iscorrectnode === false || this.state.iscorrectnode === 'false' || this.state.iscorrectnode === "False" ?
                          (
                            <div style={{
                              display: "flex"
                              //, marginBottom: "20px"
                            }}>
                              <div className="desc" style={{ fontSize: "12px" }}>
                                <p>Please edit and confirm attributes for this New Incorrect step:</p>
                              </div>
                              <div style={{ paddingLeft: "5px", fontSize: "12px" }}>
                                <MathJaxRenderer tex={`$$${this.props.synopsisData.studentError}$$`} />
                              </div>
                            </div>

                          ) : (
                            <div className="desc" style={{ fontSize: "12px" }}>
                              <p>Please edit and confirm Additional attributes for the below correct step:</p>
                            </div>
                          )
                      )
                        : (
                          this.props.synopsisData.call_type === Call_Type.Challenge ? (
                            <div style={{ display: "flex", marginBottom: "20px", }}>
                              <div className="desc" style={{ fontSize: "12px" }}>
                                <p> Please edit and confirm Additional attributes for this Existing Incorrect Step:</p>
                                {/* </div> */}
                                {/* <div style={{ paddingLeft: "5px", fontSize: "12px" }}> */}
                                <MathJaxRenderer math={`$$${this.props.synopsisData.studentError}$$`} />
                              </div>
                            </div>
                          ) : (<span style={{ marginBottom: "10px" }}>Please edit and confirm</span>)
                        )
                    }
                    {
                      this.state.shownodedropdown ? (
                        <div className="name-node" style={{
                          width: '100%', marginBottom: "10px",
                          // marginTop: "20px"
                          //display: "flex" 
                        }}
                        >
                          <div style={{ width: "100%" }}>
                            <label className="labeltextnode"
                              style={{ marginTop: "7px" }}
                            >

                              {
                                this.state.iscorrectnode === false || this.state.iscorrectnode === 'false' || this.state.iscorrectnode === "False" ?
                                  (
                                    <span>Select the step that should be associated with the given Incorrect Step:</span>
                                  ) : null
                              }

                            </label>
                          </div>
                          <div style={{ width: "70%" }}>
                            <FormControl
                              //variant="standard"
                              sx={{ width: "100%" }}
                            >
                              {/* <InputLabel id="demo-multiple-name-label"
                                style={{ fontSize: "13px" }}
                              >Select Correct Node</InputLabel> */}

                              <SelectMui
                                labelId="demo-simple-select-label"
                                id="demo-simple-select"
                                style={{ padding: "5px" }}
                                value={this.state.selectednodeid}
                                label=""
                                placeholder="Select Correct Node"
                                onChange={this.handleChangenodedata}
                                displayEmpty

                                sx={{
                                  height: 55,
                                  padding: 10,
                                  fontSize: 12,
                                  //boxShadow: 'none',
                                  // '.MuiOutlinedInput-notchedOutline': { border: "1px solid #ccc" },
                                  // "&.MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline":
                                  // {
                                  //   border: 0,
                                  // },
                                  // "&.MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline":
                                  // {
                                  //   border: 0,
                                  // },
                                }}
                              //   renderValue={(value) => value ? value : <em>Nothing Selected</em>
                              // }
                              //endAdornment={<IconButton sx={{ visibility: this.state.selectednodeid ? "visible" : "hidden" }} 
                              // onClick={this.handleClearClick}><ClearIcon /></IconButton>}
                              >
                                <MenuItem value={0}>
                                  Select
                                </MenuItem>
                                {
                                  this.state.correctnodedata !== undefined && this.state.correctnodedata !== null && this.state.correctnodedata.length > 0 ? (

                                    this.state.correctnodedata.map((item, index) => {
                                      return (
                                        <MenuItem value={item.node_id} style={{ fontSize: "12px" }}>
                                          {/* <MathJaxRenderer math={`$$${item.eq_latex}$$`} /> */}
                                          <div> <MathJaxRenderer
                                            math={'$$' + item.eq_latex + '$$'}
                                          // math={`$$${item.eq_latex}$$`} 
                                          />
                                          </div>
                                        </MenuItem>
                                      )
                                    })
                                  ) : null

                                }

                              </SelectMui>
                            </FormControl>

                          </div>
                        </div>
                      ) : null}
                    {this.state.finalSynopsis.map((item, index) => {

                      let isreusable = false
                      if (item.isreusable === 1) {
                        isreusable = true
                      }

                      let showmoreless = SHOW_MORE;
                      let reqdata = item.requisiteknowdata
                      if (item.showmoreless !== null && item.showmoreless !== undefined) {
                        showmoreless = item.showmoreless
                      }

                      if (reqdata === undefined || reqdata === null) {
                        if (this.state.allerrorroutcausedata !== null && this.state.allerrorroutcausedata !== undefined && this.state.allerrorroutcausedata.length > 0)
                          reqdata = this.state.allerrorroutcausedata['Current']
                      }
                      return (
                        <Accordion style={{ width: '100%' }} sx={{ width: "100%" }}>
                          <AccordionSummary
                            sx={{ backgroundColor: "#e6e6e6", borderRadius: "2px", marginBottom: "5px", paddingTop: "0px", paddingBottom: "0px" }}
                            expandIcon={<ExpandMoreIcon />} aria-controls="panel1a-content" id="panel1a-header">
                            <Typography><span style={{ fontSize: "16px", padding: "5px" }}>Interaction {index + 1}</span></Typography>
                          </AccordionSummary>
                          <AccordionDetails>
                            <Typography>
                              <div className="content">
                                <div className="resourcepanel"
                                //style={{backgroundColor:"#fcfcfc", marginTop:"10px", borderRadius:"10px", padding:"10px", width:"100%"}}
                                >
                                  <div style={{ borderBottom: "1px solid #ccc", marginBottom: "10px", width: "100%" }}>


                                    <label style={{ color: "#000", marginTop: "5px", fontSize: "15px", marginBottom: "1px" }}>Diagnosis:</label></div>

                                  <div class="form-group">
                                    <label
                                      for="exampleFormControlTextarea1"
                                      className="labeltext"
                                    >
                                      Diagnostic Question
                                    </label>
                                    <textarea
                                      class="form-control bg-yellow"
                                      id="exampleFormControlTextarea1"
                                      rows="3"
                                      value={item.question}
                                      onChange={(e) => { this.updateSynopsis(index, 'question', e.target.value) }}
                                    />
                                  </div>

                                  {
                                    this.state.questionImage !== undefined && this.state.questionImage !== null && this.state.questionImage !== "" ?
                                      (<img src={imgURL + this.state.questionImage} alt={imgURL + this.state.questionImage} className="mt-2"
                                      />) : null
                                  }
                                  <div class="form-group">
                                    <label
                                      for="exampleFormControlTextarea1"
                                      className="labeltext"
                                    >
                                      Diagnostic Answer
                                    </label>
                                    <textarea
                                      class="form-control bg-grey"
                                      id="exampleFormControlTextarea1"
                                      rows="2"
                                      value={item.answer}
                                      onChange={(e) => { this.updateSynopsis(index, 'answer', e.target.value) }}

                                    />
                                  </div>


                                  <div class="form-group">
                                    <label
                                      for="exampleFormControlTextarea1"
                                      className="labeltext"
                                    >
                                      Error Root Cause
                                    </label>


                                    <textarea
                                      class="form-control bg-purple"
                                      id="exampleFormControlTextarea1"
                                      rows="3"
                                      value={item.rca}
                                      onChange={(e) => { this.updateSynopsis(index, 'rca', e.target.value) }}

                                    />
                                  </div>

                                  <div className="form-group" style={{ width: '450px', marginBottom: "10px" }} >
                                    <label className="labeltext" >{"Error root cause (Concept)"}:</label>
                                    <button className="linkButton"
                                      title={"Add a new Error Root Cause for this Solution, and for this Step."}
                                      onClick={(e) => { this.handleShowMore(e, 'showmoreless', index) }}>{showmoreless}</button> <br />
                                    <Select className="dropDownSelect"
                                      title={"Select Error Root Cause identified previously for this Solution"}
                                      placeholder={"Select Error Root Cause identified previously for this Solution"}
                                      isClearable="true"
                                      isMulti
                                      getOptionLabel={option => option.name}
                                      getOptionValue={option => option.subSectionId}
                                      onChange={(e) => this.handleChangeRequisiteKnowledge(e, "requisiteknow", index)}
                                      value={item.requisiteknow}
                                      options={reqdata}
                                    />
                                  </div>
                                </div>

                                <div className="resourcepanel" style={{ marginTop: "10px" }}
                                //style={{backgroundColor:"#fcfcfc", marginTop:"10px", borderRadius:"10px", padding:"10px", width:"100%"}}
                                >
                                  <div style={{ borderBottom: "1px solid #ccc", marginBottom: "10px" }}>
                                    <label style={{ color: "#000", marginTop: "5px", fontSize: "15px", marginBottom: "1px" }}>Intervention:</label></div>

                                  <div class="form-group">
                                    <label
                                      for="exampleFormControlTextarea1"
                                      className="labeltext"
                                    >
                                      Hint
                                    </label>
                                    <textarea
                                      class="form-control bg-green"
                                      id="exampleFormControlTextarea1"
                                      rows="2"
                                      value={item.hint}
                                      onChange={(e) => { this.updateSynopsis(index, 'hint', e.target.value) }}

                                    />
                                  </div>
                                  <div class="form-group">
                                    <label
                                      for="exampleFormControlTextarea1"
                                      className="labeltext"
                                    >
                                      Leading Question
                                    </label>
                                    <textarea
                                      class="form-control bg-darkgreen"
                                      id="exampleFormControlTextarea1"
                                      rows="2"
                                      value={item.leadingquestion}
                                      onChange={(e) => { this.updateSynopsis(index, 'leadingquestion', e.target.value) }}

                                    />
                                  </div>
                                  <div class="form-group">
                                    <label
                                      for="exampleFormControlTextarea1"
                                      className="labeltext"
                                    >
                                      Remedial Instruction
                                    </label>
                                    <textarea
                                      class="form-control bg-ligtBlue"
                                      id="exampleFormControlTextarea1"
                                      rows="3"
                                      value={item.remedial}
                                      onChange={(e) => { this.updateSynopsis(index, 'remedial', e.target.value) }}
                                    />
                                  </div>
                                  <div className="form-group" style={{ width: '400px' }} >
                                    <label className="labeltext">Select Resource Topic:</label> <br />
                                    <Select
                                      className="dropDown"
                                      placeholder="Resource Topic"
                                      isSearchable={false}
                                      isClearable="true"
                                      getOptionLabel={option => option.name}
                                      getOptionValue={option => option.subSectionId}
                                      onChange={(e) => this.handleresourcetopic(e, "resourcetopic", index)}
                                      //options={this.state.errorRootCauseValue}
                                      options={item.requisiteknow}
                                      value={item.resourcetopic}
                                      menuPortalTarget={document.body}
                                      styles={{ menuPortal: base => ({ ...base, zIndex: 9999 }) }}
                                    />
                                  </div>

                                  {/* <div class="form-group">
                                <label
                                  for="exampleFormControlTextarea1"
                                  className="bg-green"
                                >
                                  Remedial Equation 1
                                </label>
                                <br />
                                <img src={this.state.errorStep} alt="Equation" className="mb-2" />
                              </div> */}

                                  <div class="form-group">
                                    <label
                                      for="exampleFormControlTextarea1"
                                      className="labeltext"
                                    >
                                      Resource
                                    </label>
                                    <textarea
                                      class="form-control bg-blue"
                                      id="exampleFormControlTextarea1"
                                      rows="3"
                                      value={item.resource}
                                      onChange={(e) => { this.updateSynopsis(index, 'resource', e.target.value) }}

                                    />
                                  </div>
                                  <div class="form-group">
                                    <div style={{ display: "flex", justifyContent: "space-between" }}>

                                      <label className="labels padding0">
                                        <input
                                          type="checkbox"
                                          required
                                          checked={isreusable}
                                          onChange={(e) => { this.handleisresourcereusable(index, 'isreusable', e.target.checked) }}
                                        />
                                        {" Is Resource Reusable ?"}
                                      </label>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </Typography>
                          </AccordionDetails>
                        </Accordion>)
                    })}
                    <div class="content">


                      <div className="d-flex btn-group mt-5 justify-content-between">
                        <Button
                          clicked={(event) => {
                            this.setState({
                              isFinalStage: false,
                              istutortostudent: false
                            });
                            this.postLoadingContent = null
                          }}
                          className="yellow-btn buttoncss"
                        >
                          Back to Synopsis
                        </Button>

                        <Button
                          // clicked={(event) => {
                          //   this.confirmSynopsis()
                          //   toast("Synopsis confirmed successfully.", {
                          //     type: toast.TYPE.INFO,
                          //     position: toast.POSITION.BOTTOM_RIGHT,
                          //   });
                          //   this.props.onClose();
                          // }}
                          className="yellow-btn buttoncss"
                          disabled={true}
                        >
                          Send to Author
                        </Button>

                        {
                          this.state.buttonpaneldisbaled ? (<Button
                            //clicked={this.handleconfirmationalert}
                            className="yellow-btn buttoncss"
                            disabled={true}
                          >
                            Commit to KnowledgeGraph
                          </Button>) : (<Button
                            clicked={this.handleconfirmationalert}
                            className="yellow-btn buttoncss"
                          >
                            Commit to KnowledgeGraph
                          </Button>)
                        }


                      </div>
                    </div>

                  </>
                )}
              </div>
            </div>
          </div>
        </Draggable>
        {this.props.newitemloading && (
          <CircularProgress
            size={24}
            sx={{
              color: "#fdbd17",
              position: 'absolute',
              top: '50%',
              left: '35%',
              marginTop: '-12px',
              marginLeft: '-12px',
            }}
          />
        )
        }
      </>
    );
  }
}

export default React.memo(SessionSynopsisWithData);