import React from 'react';
import { AgGridReact } from  'ag-grid-react';
import 'ag-grid-community/dist/styles/ag-grid.css';
import 'ag-grid-community/dist/styles/ag-theme-alpine.css';

class GridComponent extends React.Component {

    constructor(props) {
        super(props);
        this.state = {}
      }
    
   render() {

   const [gridColumn] = ([this.props.columns]);
   const [gridRows] = ([this.props.rows]);
   const gridOptions = this.props.gridOptions;
   const [gridReady] = ([this.props.gridReady]);
 //  const pageSize = this.props.pageSize;

  return  <AgGridReact  
  columnDefs={gridColumn} 
  rowData={gridRows}  
  gridOptions={gridOptions}
  onGridReady={gridReady}
//     pagination={true}
//      paginationPageSize={pageSize}
>
</AgGridReact>
    }
  }
  
  export default GridComponent