import React, { useState } from 'react';
import ProblemStatement from '../../components/SolutionBoard/ProblemStatement';
import AnyProblem from '../../components/CurriculumContent/AnyProblem/AnyProblem';
import './WOPComponent.scss'
import MathJaxRenderer from '../../components/Common/MathJaxRenderer/MathJaxRenderer';
import { isEmpty, isNil, random } from 'lodash';
import CachedIcon from '@mui/icons-material/Cached';
import { Problem_source } from '../../common/Functions';

class WOPComponent extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            anyproblemQuestion: "",
            sessionId: Date.now()
        }
    }

    AnyProbleemStatementReceived = (statement) => {
        this.setState({ anyproblemQuestion: statement })
    }

    newProblemStatement = () => {
        window.location.reload();
    }

    setProblemStatement = (statement) => {
        this.setState({ anyproblemQuestion: statement })
    }

    render() {
        return (
            <div className='wopContainer'>

                <div className='problemStatementBox bg-white-with-shadow'>
                    <div className='px-3 divPBStatementWOP'>
                        <div className="divPBStatement">Problem Statement</div>
                        <div className='divBtn'>
                            <button onClick={() => this.newProblemStatement()} className="btnNewStatement">Start New Problem</button>
                            <CachedIcon onClick={() => this.newProblemStatement()} />
                        </div>
                        {/* <div className='px-3'>Important: Our system currently supports equations exclusively; diagrams are not processed.</div> */}
                    </div>
                    <div className="vectorLine"></div>


                    {isEmpty(this.state.anyproblemQuestion) ?
                        null
                        :
                        <div className='statementContainer'>
                            <MathJaxRenderer fromCKEditor={true} math={this.state.anyproblemQuestion} />
                        </div>

                    }
                </div>
                <div className='anyProblemBox'>
                    <AnyProblem
                        isInline={false}
                        question={this.state.anyproblemQuestion}
                        challengeId={0}
                        equationClick={true}
                        isWOP={true}
                        sessionId={this.state.sessionId}
                        setProblemStatement={this.setProblemStatement}
                        problem_source={Problem_source.interactiveproblems}
                    >
                    </AnyProblem>
                </div>
            </div>
        )
    }
}

export default WOPComponent