import React, { useEffect, useState } from 'react';
import { useCanvas } from './CanvasContext'
import { IconButton } from '@mui/material'
import Tooltip from '@mui/material/Tooltip';
import QRCode from 'qrcode.react';
import './canvas.css';


import ExpandIcon from '../../assets/images/icon-expand.svg';
import CollapseIcon from '../../assets/images/icon-collapse.svg';
import HandIcon from '../../assets/images/icon_info.svg';
import FullScreenIcon from '../../assets/images/fullscreen_icon.svg';
import MinScreenIcon from '../../assets/images/minimize-screen.svg';
import PencilIcon from '../../assets/images/icon-pencil.svg';
import CalcIcon from '../../assets/images/icon-calculation.svg';
import UndoIcon from '../../assets/images/icon-undo.svg';
import RedoIcon from '../../assets/images/icon-redo.svg';
import DeleteIcon from '../../assets/images/icon-trash.svg';
import MoreIcon from '../../assets/images/icon-more.svg';
import HintIcon from '../../assets/images/icon-hint.svg';
import HintShowIcon from '../../assets/images/icon-hint-show.svg';
import AskTutIcon from '../../assets/images/icon-ask-tutor.svg';
import AskAITutIcon from '../../assets/images/ai-tutor.png';
import AddNewPageIcon from '../../assets/images/icon-add-new-page.svg';
import checkWorkIcon from '../../assets/images/icon-check.svg';
import CopyLatexIcon from '../../assets/images/icon-copy-latex.svg';
import ShowFullSolutionIcon from '../../assets/images/icon-show-full-solution.svg';
import UploadImageIcon from '../../assets/images/icon-upload-image.svg';
import BrowseImageIcon from '../../assets/images/icon-browse-image.svg';
import ShowNextStepIcon from '../../assets/images/icon-show-next-step.svg';
import ShowQuestionIcon from '../../assets/images/icon-show-question.svg';
import ShowEquationLinesIcon from '../../assets/images/icon-show-equation-lines.svg';
import KnowledgeGraphIcon from '../../assets/images/icon-knowledge-graph.svg';
import SaveToTranscriptIcon from '../../assets/images/icon-save-to-transcript.svg';
import SynopsisIcon from '../../assets/images/icon-synopsis.svg';
import SubmitWorkIcon from '../../assets/images/icon-submit-work.svg';
import { MenuItems } from '../../common/Constants';
import { APP_URL, showFullApp } from '../../common/Functions';
import { User_Role } from "../../common/Functions";
import InfoIcon from '@mui/icons-material/Info';
import GradingOutlinedIcon from '@mui/icons-material/GradingOutlined';
import ClearAllOutlinedIcon from '@mui/icons-material/ClearAllOutlined';

// export const LatexRenderer = () => {
//   const { latex } = useCanvas();


//   useEffect(() => {
//     if (latex.code !== '') {
//       const contentWithoutAligned = latexOutput.replace(/\\begin{aligned}|\\end{aligned}/g, '');

//       // Split equations using \\\\
//       const equationsStep1 = contentWithoutAligned.split('\\\\\\n');

//       // Split equations using \\quad within each step1 equation
//       const equations = equationsStep1.reduce((acc, equation) => {
//         const subEquations = equation.split('\\quad');
//         acc.push(...subEquations);
//         return acc;
//       }, []);

//       const filteredEquations = equations
//         .map(equation => equation.trim().replace(/\n/g, ''))
//         .filter(equation => equation !== '');

//       filteredEquations.forEach((equation, idx) => {
//         console.log(`Equation ${idx + 1}: ${equation.replace(/\n/g, '')}`);
//       });

//     }
//   }, [latex.code]);

//   return (
//     <div className='latex-renderer-container'>
//       <MathpixLoader>
//         <MathpixMarkdown text={latex.code} />
//       </MathpixLoader>
//     </div>
//   )
// } 

export const UndoButton = (props) => {
  const { undoHistory, undo, setMenuItem } = useCanvas();
  const handleClick = () => {
    undo();
    props.setSelectedMenuItemHandler(MenuItems.Undo)
    // setMenuItem(MenuItems.Pen)
  }

  return (
    <Tooltip title="Undo" placement='top' arrow>
      <IconButton onClick={handleClick} disabled={undoHistory.length === 0} >
        <div className='boardBtnsupper'>
          {/* <UndoOutlined fontSize="inherit" /> */}
          <img src={UndoIcon} />
        </div>
        <p className='toolLabel'>Undo</p>
      </IconButton>
    </Tooltip>
  )
}

export const RedoButton = () => {
  const { redoHistory, redo, setMenuItem } = useCanvas();
  const handleClick = () => {
    redo();
    // setMenuItem(MenuItems.Pen)
  }

  return (
    <Tooltip title="Redo" placement='top' arrow>
      <IconButton onClick={handleClick} disabled={redoHistory.length === 0} >
        <div className='boardBtnsupper'>
          {/* <RedoOutlined fontSize="inherit" /> */}
          <img src={RedoIcon} />
        </div>
        <p className='toolLabel'>Redo</p>
      </IconButton>
    </Tooltip>
  )
}
export const ClearCanvasButton = (props) => {
  const { clearCanvas, strokes, hasImageOnBoard, clearCurrentDrawing } = useCanvas()
  const handleClick = () => {
    clearCurrentDrawing();
  }
  return (
    <Tooltip title="Erase all content from the sketchpad and reset the equation board." placement='top' arrow className='eraseBtn_tour'>
      <IconButton
        //onClick={handleClick}
        onClick={() => props.setSelectedMenuItemHandler(MenuItems.clearHandwriting)}
        disabled={strokes.length === 0 && !hasImageOnBoard} >
        <div className='boardBtnsupper'>
          <img alt='Erase all content from the sketchpad and reset the equation board.' src={DeleteIcon} height="20px" width="20px" />
        </div>
        <p className='toolLabel'>Clear Handwriting</p>
      </IconButton>
    </Tooltip>
  )
}

export const AddNewCanvasButton = (props) => {
  const { clearCanvas } = useCanvas()
  const handleAddNewClick = () => {
    props.setSelectedMenuItemHandler(MenuItems.New)
    setTimeout(() => {
      clearCanvas(false, true)
    }, 1000);
  }
  return (
    <Tooltip title="Add New Page" placement='right' arrow>
      <IconButton >
        <div className={`boardBtns ${props.selectedMenuItem === MenuItems.New ? 'selected' : ''}`} onClick={handleAddNewClick}>
          <img src={AddNewPageIcon} />
        </div>
        <p onClick={handleAddNewClick} className='toolLabel'>Add New Page</p>
      </IconButton>
    </Tooltip>
  )
}

export const ClearButton = (props) => {

  return (
    <Tooltip title="Delete" placement='right' arrow>
      <IconButton>
        <div className={`boardBtns ${props.selectedMenuItem === MenuItems.Delete ? 'selected' : ''}`} onClick={() => props.setSelectedMenuItemHandler(MenuItems.Delete)}>
          <img src={DeleteIcon} />
        </div>
        <p onClick={() => props.setSelectedMenuItemHandler(MenuItems.Delete)} className='toolLabel'>Delete</p>
      </IconButton>
    </Tooltip>
  )
}

export const CopyToClipboardButton = () => {
  const { latex } = useCanvas();
  const handleClick = () => {
    navigator.clipboard.writeText(latex.code);
  }

  return (
    <Tooltip title="Copy LaTeX to Clipboard" placement='right' arrow>
      <IconButton onClick={handleClick} disabled={latex.isPlaceholder} >
        <div className='boardBtns'>
          {/* <ContentCopyOutlined fontSize="inherit" /> */}
          <img src={CopyLatexIcon} />
        </div>
        <p className='toolLabel'>Copy</p>
      </IconButton>
    </Tooltip>
  )
}

export const UploadImage = (props) => {
  const [isVisible, setIsVisible] = useState(false)
  const { readFromImage } = useCanvas();
  const previewFile = () => {
    var file = document.querySelector('input[type=file]').files[0];
    var reader = new FileReader();
    reader.onloadend = function () {
      readFromImage(reader.result)
    }
    if (file) {
      reader.readAsDataURL(file)
    }

    setIsVisible(false)
  }

  const getParams = () => {
    return window.btoa(`userId=${props.userId}&challengeId=${props.challengeId}&boardId=${props.boardId}`)
  }

  const uploadToggleVisibility = () => {
    setIsVisible(!isVisible)
  }

  const handleclose = () => {
    setIsVisible(false)
  }

  return (
    <div className='position-relative'>
      <Tooltip title={!isVisible ? "Upload an image of your handwritten solution, or scan it with a phone." : "Close"} placement={`${showFullApp() ? 'right' : "top"}`} arrow>
        <IconButton onClick={() => uploadToggleVisibility()} disabled={props.disabled}>
          <div className={`${!showFullApp() ? "boardBtnsuppermob" : "boardBtns"} ${props.selectedMenuItem === MenuItems.Upload ? 'selected' : ''}`} onClick={() => props.setSelectedMenuItemHandler(MenuItems.Upload)}>
            {
              showFullApp() ? (<img alt='Upload an image of your handwritten solution, or scan it with a phone.' src={UploadImageIcon} />) :
                (<img alt='Upload an image of your handwritten solution, or scan it with a phone.' src={UploadImageIcon} height="35px" width="35px" />)
            }
          </div>
          <p onClick={() => props.setSelectedMenuItemHandler(MenuItems.Upload)} className='toolLabel'>
            Upload Handwritten Solution</p>
        </IconButton>
      </Tooltip>
      {
        isVisible ? (
          <div className={`uploadOptionsContainer show`}>
            <div placement='right' className='closeIcon' onClick={handleclose}> <i class="fa fa-close" style={{ fontSize: "18px", boxShadow: "none", textDecoration: 'none', }} onClick={handleclose}></i></div>

            <Tooltip title='' placement='right' arrow>
              <div className='' onClick={() => props.setSelectedMenuItemHandler(MenuItems.Upload)}>
                <label for="file-upload" class="browseImageBtn">
                  <img src={BrowseImageIcon} /> Browse Image
                </label>
                <input id="file-upload" className='imageUploadInput' type="file" onChange={previewFile} />
              </div>
            </Tooltip>
            <p className='orTxt'>-- OR --</p>
            <p className='scanTxt'>Scan from you device</p>
            <div onClick={() => uploadToggleVisibility()} className='qrCodeImg'>
              <QRCode value={`${APP_URL}upload?${getParams()}`} />
            </div>
          </div>) : null
      }

    </div>
  )
}

export const MoreButton = (props) => {
  const [isOpened, setOpened] = useState(false)
  const moreToggleVisibility = () => {
    setOpened(!isOpened);
  };
  return (
    <div className='position-relative'>
      <div className='position-relative'>

        <Tooltip title={!isOpened ? "See More Option" : "Close"} placement='right' arrow className='eraseBtn_tour'>
          <IconButton onClick={() => moreToggleVisibility()}>
            <div className={`boardBtns ${props.selectedMenuItem === MenuItems.MoreButtons ? 'selected' : ''}`} onClick={() => props.setSelectedMenuItemHandler(MenuItems.MoreButtons)}>
              <img alt='More Options Icon' src={MoreIcon} />
            </div>
            <p onClick={() => props.setSelectedMenuItemHandler(MenuItems.MoreButtons)} className='toolLabel'>More Options</p>
          </IconButton>
        </Tooltip>
      </div>
      <div hidden={!isOpened} onClick={() => moreToggleVisibility()} className={`moreOptionsContainer show ${props.selectedMenuItem === MenuItems.MoreButtons ? 'show' : ''}`}>

        <UndoButton setSelectedMenuItemHandler={props.setSelectedMenuItemHandler} />
        <RedoButton setSelectedMenuItemHandler={props.setSelectedMenuItemHandler} />
        <ClearCanvasButton setSelectedMenuItemHandler={props.setSelectedMenuItemHandler} />
        {props.questiontype === "FRQ" ?
          <AddNewCanvasButton questionType={props.questiontype} setSelectedMenuItemHandler={props.setSelectedMenuItemHandler} />
          : null}
      </div>
    </div>
  )
}

export const HintMainButton = (props) => {
  const [isOpened, setOpened] = useState(false)
  const moreToggleVisibility = () => {
    setOpened(!isOpened);
  };
  return (
    <div className='position-relative'>
      <Tooltip title={!isOpened ? "Show Hints, or Next Steps, or Entire Solution." : "Close"} placement='right' arrow >
        <IconButton onClick={() => moreToggleVisibility()}>
          <div className={`boardBtns ${props.selectedMenuItem === MenuItems.HintMainButtons ? 'selected' : ''}`}
            onClick={() => props.setSelectedMenuItemHandler(MenuItems.HintMainButtons)}>
            <img src={HintIcon} />
          </div>
          <p onClick={() => props.setSelectedMenuItemHandler(MenuItems.HintMainButtons)} className='toolLabel'>Hint Options</p>
        </IconButton>
      </Tooltip>
      {
        props.user_Role === User_Role.Student && props.questiontype === "FRQ" ?
          <div hidden={!isOpened} onClick={() => moreToggleVisibility()} className={`moreOptionsContainer ${props.selectedMenuItem === MenuItems.HintMainButtons ? 'show' : ''}`}>
            <HintButton hintText={props.hintText} hintDisabled={props.hintDisabled} setSelectedMenuItemHandler={props.setSelectedMenuItemHandler} />
            <ShowNextStepButton setSelectedMenuItemHandler={props.setSelectedMenuItemHandler} />
            <ShowSolutionButton setSelectedMenuItemHandler={props.setSelectedMenuItemHandler} />
          </div>
          : null
      }

      {
        props.user_Role === User_Role.Student && (props.questiontype === "MCQ" || props.questiontype === "MSQ" || props.questiontype === "Ranking") ?
          <div hidden={!isOpened} onClick={() => moreToggleVisibility()} className={`moreOptionsContainer ${props.selectedMenuItem === MenuItems.HintMainButtons ? 'show' : ''}`}>
            <HintButton hintText={props.hintText} hintDisabled={props.hintDisabled} setSelectedMenuItemHandler={props.setSelectedMenuItemHandler} />
            {/* <ShowNextStepButton setSelectedMenuItemHandler={props.setSelectedMenuItemHandler} /> */}
            <ShowSolutionButton setSelectedMenuItemHandler={props.setSelectedMenuItemHandler} />
          </div>
          : null
      }

      {
        props.user_Role === User_Role.Tutor && (props.questiontype === "MCQ" || props.questiontype === "MSQ" || props.questiontype === "Ranking") ?
          (
            <>
              <div hidden={!isOpened} onClick={() => moreToggleVisibility()} className={`moreOptionsContainer ${props.selectedMenuItem === MenuItems.HintMainButtons ? 'show' : ''}`}>
                <HintButton hintText={props.hintText} hintDisabled={props.hintDisabled} setSelectedMenuItemHandler={props.setSelectedMenuItemHandler} />
                {/* <ShowNextStepButton setSelectedMenuItemHandler={props.setSelectedMenuItemHandler} /> */}
                <ShowSolutionButton setSelectedMenuItemHandler={props.setSelectedMenuItemHandler} />
              </div>
            </>
          ) : null
      }

      {
        (props.user_Role === User_Role.ContentAuthor || props.user_Role === User_Role.ContentExpert || props.user_Role === User_Role.Professor) && props.questiontype === "FRQ" ?
          (
            <>
              <div hidden={!isOpened} onClick={() => moreToggleVisibility()} className={`moreOptionsContainer ${props.selectedMenuItem === MenuItems.HintMainButtons ? 'show' : ''}`}>
                <HintButton hintText={props.hintText} hintDisabled={props.hintDisabled} setSelectedMenuItemHandler={props.setSelectedMenuItemHandler} />
                <ShowNextStepButton setSelectedMenuItemHandler={props.setSelectedMenuItemHandler} />
                <ShowSolutionButton setSelectedMenuItemHandler={props.setSelectedMenuItemHandler} />
              </div>
            </>
          ) : null
      }

      {
        (props.user_Role === User_Role.ContentAuthor || props.user_Role === User_Role.Professor) && (props.questiontype === "MCQ" || props.questiontype === "MSQ" || props.questiontype === "Ranking") ?
          (
            <>
              <div hidden={!isOpened} onClick={() => moreToggleVisibility()} className={`moreOptionsContainer ${props.selectedMenuItem === MenuItems.HintMainButtons ? 'show' : ''}`}>
                <HintButton hintText={props.hintText} hintDisabled={props.hintDisabled} setSelectedMenuItemHandler={props.setSelectedMenuItemHandler} />
                {/* <ShowNextStepButton setSelectedMenuItemHandler={props.setSelectedMenuItemHandler} /> */}
                <ShowSolutionButton setSelectedMenuItemHandler={props.setSelectedMenuItemHandler} />
              </div>
            </>
          ) : null
      }
    </div>
  )
}


export const FullscreenToggleButton = (props) => {
  const [isExpanded, setExpanded] = useState(false)
  return (
    <div >
      <Tooltip title={!isExpanded ? "Expand" : "Collapse"} placement='right' arrow>
        <IconButton >
          <div className='boardBtns' onClick={() => setExpanded(!isExpanded)}>
            <img src={!isExpanded ? ExpandIcon : CollapseIcon} />
          </div>
          <p onClick={() => setExpanded(!isExpanded)} className='toolLabel'>{!isExpanded ? "Expand" : "Collapse"}</p>
        </IconButton>
      </Tooltip>
    </div>

  )
}
export const HandButton = (props) => {

  return (
    <Tooltip title="Usage Guide" placement='top' arrow>
      <IconButton >
        <div className={`boardBtnsupperUsage ${props.selectedMenuItem === MenuItems.Hand ? 'selected' : ''}`}
          onClick={() => props.setSelectedMenuItemHandler(MenuItems.Hand)} >
          <img height="40px" width="40px" src={HandIcon} alt="Usage Guide" />
        </div>
        <span onClick={() => props.setSelectedMenuItemHandler(MenuItems.Hand)}
          className='toolLabel'>Usage Guide</span>
      </IconButton>
    </Tooltip>
  )
}

export const EraseButton = (props) => {
  const handleClick = () => {
    props.setSelectedMenuItemHandler(MenuItems.Erase)
  }
  return (
    <Tooltip title="Earse" placement='top' arrow>
      <IconButton >
        <div className={`boardBtnsupperErase ${props.selectedMenuItem === MenuItems.Erase ? 'selected' : ''}`}
          onClick={() => handleClick()} >
          <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-eraser" viewBox="0 0 16 16">
            <path d="M8.086 2.207a2 2 0 0 1 2.828 0l3.879 3.879a2 2 0 0 1 0 2.828l-5.5 5.5A2 2 0 0 1 7.879 15H5.12a2 2 0 0 1-1.414-.586l-2.5-2.5a2 2 0 0 1 0-2.828zm2.121.707a1 1 0 0 0-1.414 0L4.16 7.547l5.293 5.293 4.633-4.633a1 1 0 0 0 0-1.414zM8.746 13.547 3.453 8.254 1.914 9.793a1 1 0 0 0 0 1.414l2.5 2.5a1 1 0 0 0 .707.293H7.88a1 1 0 0 0 .707-.293z" />
          </svg>
        </div>
        <span onClick={() => props.setSelectedMenuItemHandler(MenuItems.Erase)}
          style={{ marginTop: '3px' }} className='toolLabel'>Erase</span>
      </IconButton>
    </Tooltip>
  )
}

export const FullScreenButton = (props) => {
  let titlename = ""
  if (props.isScreenFull) {
    titlename = "Collapse"
  }
  else {
    titlename = "Expand"
  }

  return (

    <Tooltip title={props.isScreenFull ? "Collapse" : "Full Screen"} placement='top' arrow>
      <IconButton>
        <div className={`boardBtnExpand ${props.selectedMenuItem === MenuItems.FullScreen ? 'selected' : ''}`}
          onClick={() => props.setSelectedMenuItemHandler(MenuItems.FullScreen)}>
          {
            props.isScreenFull ? (<img height="20px" alt='FullScreen Icon' src={props.isScreenFull ? MinScreenIcon : FullScreenIcon}
            />) : (<img alt='FullScreen Icon' src={props.isScreenFull ? MinScreenIcon : FullScreenIcon}
            />)
          }
        </div>
        <span onClick={() => props.setSelectedMenuItemHandler(MenuItems.FullScreen)}
          className='toolLabel toolLabelExpand'>{titlename}</span>
      </IconButton>
    </Tooltip>

  )
}
export const PenButton = (props) => {
  // console.log("check pen menu..............", props)

  return (
    <div style={{ paddingTop: '5px' }}>
      <Tooltip title="Write your solution steps on the sketchpad." placement={`${showFullApp() ? 'right' : "top"}`} arrow className='canvas_tour'>
        <IconButton sx={{ marginTop: `${showFullApp() ? "0px" : "1px !important"}` }} disabled={props.disabled}>
          <div className={`${!showFullApp() ? "boardBtnsuppermob" : "boardBtns"} ${props.selectedMenuItem === MenuItems.Pen ? 'selected' : ''}`} onClick={() => props.setSelectedMenuItemHandler(MenuItems.Pen)}>

            {
              showFullApp() ? (<img alt='Write your solution steps on the sketchpad.' src={PencilIcon} />) : (<img alt='Write your solution steps on the sketchpad.' src={PencilIcon} height="20px" width="20px" />)
            }
          </div>
          <span onClick={() => props.setSelectedMenuItemHandler(MenuItems.Pen)} className='toolLabel'>Pen</span>
        </IconButton>
      </Tooltip>
    </div>
  )
}

export const HintButton = (props) => {

  return (
    <Tooltip title="Hint" placement='right' arrow >
      <IconButton disabled={props.disabled}>
        <div disabled={props.hintDisabled} className={`boardBtns ${props.selectedMenuItem === MenuItems.Hint ? 'selected' : ''}`} onClick={() => props.setSelectedMenuItemHandler(MenuItems.Hint)}>
          <img alt='Hint Icon' src={HintShowIcon} />
        </div>
        <p onClick={() => props.setSelectedMenuItemHandler(MenuItems.Hint)} className='toolLabel'>{props.hintText}</p>
      </IconButton>
    </Tooltip>
  )
}

export const AskAITutorButton = (props) => {

  return (
    <Tooltip title="Get further explanations from the interactive AI tutor." placement='right' arrow >
      <IconButton style={{ marginBottom: '10px' }}>
        <div className={`boardBtns ${props.selectedMenuItem === MenuItems.AskAITutor ? 'selected' : ''}`} onClick={() => props.setSelectedMenuItemHandler(MenuItems.AskAITutor)}>
          <img color='#4252AF' width="34px" height="34px" alt='Get further explanations from the interactive AI tutor.' src={AskAITutIcon} />
        </div>
        <p onClick={() => props.setSelectedMenuItemHandler(MenuItems.AskAITutor)} className='toolLabel'>Ask AI Tutor</p>
      </IconButton>
    </Tooltip>
  )
}

export const AskTutorButton = (props) => {

  return (
    <Tooltip title="Start a live video call with a human tutor. Share your board to collaborate." placement='right' arrow >
      <IconButton>
        <div className={`boardBtns ${props.selectedMenuItem === MenuItems.AskTutor ? 'selected' : ''}`} onClick={() => props.setSelectedMenuItemHandler(MenuItems.AskTutor)}>
          <img src={AskTutIcon} />
        </div>
        <p onClick={() => props.setSelectedMenuItemHandler(MenuItems.AskTutor)} className='toolLabel'>Ask Human Tutor</p>
      </IconButton>
    </Tooltip>
  )
}
export const CheckMyWorkButton = (props) => {

  return (
    <div className={props.isBlinkCheckMyWork ? 'position-relative blink' : 'position-relative'}>

      <Tooltip title="Check My Work" placement='right' arrow className='checkMyWork_tour'>
        <IconButton disabled={props.disabled}>
          <div className={`boardBtns ${props.selectedMenuItem === MenuItems.CheckWork ? 'selected' : ''}`} onClick={() => props.setSelectedMenuItemHandler(MenuItems.CheckWork)}>
            <img alt='Check My Work Icon' src={checkWorkIcon} />
          </div>
          <p onClick={() => props.setSelectedMenuItemHandler(MenuItems.CheckWork)} className='toolLabel'>Check My Work</p>
        </IconButton>
      </Tooltip>
    </div>
  )
}
export const ShowNextStepButton = (props) => {

  return (
    <Tooltip title="Show Next Step" placement='right' arrow>
      <IconButton disabled={props.disabled}>
        <div className={`boardBtns ${props.selectedMenuItem === MenuItems.NextStep ? 'selected' : ''}`} onClick={() => props.setSelectedMenuItemHandler(MenuItems.NextStep)}>
          <img src={ShowNextStepIcon} />
        </div>
        <p onClick={() => props.setSelectedMenuItemHandler(MenuItems.NextStep)} className='toolLabel'>Show Next Step</p>
      </IconButton>
    </Tooltip>
  )
}
export const ShowSolutionButton = (props) => {

  return (
    <Tooltip title="Show Full Solution" placement='right' arrow>
      <IconButton disabled={props.disabled}>
        <div className={`boardBtns ${props.selectedMenuItem === MenuItems.ShowSolution ? 'selected' : ''}`} onClick={() => props.setSelectedMenuItemHandler(MenuItems.ShowSolution)}>
          <img src={ShowFullSolutionIcon} />
        </div>
        <p onClick={() => props.setSelectedMenuItemHandler(MenuItems.ShowSolution)} className='toolLabel'>Show Full Solution</p>
      </IconButton>
    </Tooltip>
  )
}

export const CalculationButton = (props) => {
  const { calc } = useCanvas();
  return (
    <div style={{ paddingBottom: '5px' }}>
      <Tooltip title="Write your solution steps on the sketchpad." placement={`${showFullApp() ? 'right' : "top"}`} arrow
        onClick={() => props.setSelectedMenuItemHandler(MenuItems.Wiris)}>
        <IconButton disabled={props.disabled}>
          <div
            className={`${!showFullApp() ? "boardBtnsuppermobeq" : "boardBtns"} ${props.selectedMenuItem === MenuItems.Wiris ? 'selected' : ''}`} onClick={() => props.setSelectedMenuItemHandler(MenuItems.Wiris)}>
            {
              showFullApp() ? (<img alt='Write your solution steps using keyboard.' src={CalcIcon} />) :
                (<img alt='Write your solution steps on the sketchpad.' src={CalcIcon} height="25px" width="25px" />)
            }
          </div>
          <p onClick={() => props.setSelectedMenuItemHandler(MenuItems.Wiris)} className='toolLabel'>Equation Keyboard</p>
        </IconButton>
      </Tooltip>
    </div>
  )
}
export const SubmitButtonMobile = (props) => {
  return (<Tooltip title="Submit">
    <IconButton disabled={props.disabled} sx={{ opacity: `${props.disabled && !showFullApp() ? "0.4" : 1}` }}>
      <div className={`boardBtns ${props.selectedMenuItem === MenuItems.Submit ? 'selected' : ''}`}
        onClick={() => props.setSelectedMenuItemHandler(MenuItems.Submit)}>
        <img src={SubmitWorkIcon} disabled={props.disabled} />
      </div>
      <p disabled={props.disabled} onClick={() => props.setSelectedMenuItemHandler(MenuItems.Submit)} className='toolLabel'>Submit</p>
    </IconButton>
  </Tooltip>
  )
}
export const SubmitWorkButton = (props) => {
  const { submitWork } = useCanvas();
  return (
    <Tooltip title="">
      <IconButton style={{ justifyContent: 'flex-start' }} className='submitWorkBtnContainer1' disabled={props.disabled}
        onClick={() => props.setSelectedMenuItemHandler(MenuItems.Submit)}>
        <div style={!props.visible ? { display: 'none' } : { display: 'flex' }} className={`submitWorkBtn ${props.disabled ? ' disabledDiv' : ''}  `} >
          <img src={SubmitWorkIcon} disabled={props.disabled} />
          <span class="submitText">Submit</span>
        </div>
      </IconButton>
    </Tooltip>
  )
}

export const CalculateGradeButton = (props) => {
  // const { submitWork } = useCanvas();
  // console.log("total trials - " + props.totalGradeCalculationTrial)
  return (
    <Tooltip title="Each incorrect score check will result in a 20% deduction of the points for this question.">
      <IconButton style={{ width: '120px' }} className='submitWorkBtnContainer3'
        onClick={() => props.setSelectedMenuItemHandler(MenuItems.CalculateGrades)}
        disabled={props.disabled}>
        <div style={!props.visible ? { display: 'none' } : { display: 'flex' }} className={`calculateGradeBtn ${props.disabled ? ' disabledDiv' : ''}  `} >
          {/* <img alt='' src={CalcIcon} /> */}
          <GradingOutlinedIcon style={{ height: '25px', paddingTop: '5px', marginLeft: '5px', marginRight: '5px', color: 'white', alignItems: 'center' }} />
          <span class="submitTextGrade" visible={props.totalGradeCalculationTrial === 5 ? false : true} >Check</span>
          <span class="submitTextGrade">{props.totalGradeCalculationTrial >= 1 && props.totalGradeCalculationTrial <= props.totalTrials ?
            props.totalGradeCalculationTrial === props.totalTrials ? 'Score (' + props.totalGradeCalculationTrial + '/' + props.totalTrials + ')' :
              'Score (' + props.totalGradeCalculationTrial + '/' + props.totalTrials + ')'
            : 'Score'}</span>
        </div>
      </IconButton>
    </Tooltip>
  )
}

export const KnowledgeGraphButton = (props) => {

  return (
    <Tooltip title="Knowledge Graph" placement='right' arrow>
      <IconButton>
        <div className={`boardBtns ${props.selectedMenuItem === MenuItems.KnowledgeGraph ? 'selected' : ''}`} onClick={() => props.setSelectedMenuItemHandler(MenuItems.KnowledgeGraph)}>
          <img src={KnowledgeGraphIcon} />
        </div>
        <p onClick={() => props.setSelectedMenuItemHandler(MenuItems.KnowledgeGraph)} className='toolLabel'>Knowledge Graph</p>
      </IconButton>
    </Tooltip>
  )
}


export const CheckStudentWorkButton = (props) => {

  return (
    <div className='position-relative'>

      <Tooltip title={`${showFullApp() ? "Check Student's Work" : "Evaluate"}`} placement='right' arrow className='checkMyWork_tour'>
        <IconButton>
          <div className={`boardBtns ${props.selectedMenuItem === MenuItems.CheckStudentWork ? 'selected' : ''}`} onClick={() => props.setSelectedMenuItemHandler(MenuItems.CheckStudentWork)}>
            <img alt='Evaluate' src={checkWorkIcon} />
          </div>
          <p onClick={() => props.setSelectedMenuItemHandler(MenuItems.CheckStudentWork)} className='toolLabel'>
            {
              showFullApp() ? ("Check Student Work") : ("Evaluate")
            }

          </p>
        </IconButton>
      </Tooltip>
    </div>
  )
}

export const ShowEquationButton = (props) => {

  return (
    <div className='position-relative'>

      <Tooltip title="Show Equation" placement='right' arrow>
        <IconButton>
          <div
            className={`boardBtns ${props.selectedMenuItem === MenuItems.ShowEquation ? 'selected' : ''}  `}
            // className={`boardBtns ${props.selectedMenuItem === MenuItems.ShowEquation ? 'selected' : ''}`}
            onClick={() => props.setSelectedMenuItemHandler(MenuItems.ShowEquation)}>
            <img alt='Show Equation' src={ShowQuestionIcon} />
          </div>
          <p onClick={() => props.setSelectedMenuItemHandler(MenuItems.ShowEquation)}
            className='toolLabel'>Show Equation</p>
        </IconButton>
      </Tooltip>
    </div>
  )
}

export const SaveToTranscriptButton = (props) => {

  return (
    <Tooltip title="Save To Transcript" placement='right' arrow>
      <IconButton>
        <div className={`boardBtns ${props.selectedMenuItem === MenuItems.SaveToTranscript ? 'selected' : ''}`} onClick={() => props.setSelectedMenuItemHandler(MenuItems.SaveToTranscript)}>
          <img src={SaveToTranscriptIcon} />
        </div>
        <p onClick={() => props.setSelectedMenuItemHandler(MenuItems.SaveToTranscript)} className='toolLabel'>Save To Transcript</p>
      </IconButton>
    </Tooltip>
  )
}

export const SynopsisButton = (props) => {

  return (
    <Tooltip title="Synopsis" placement='right' arrow>
      <IconButton>
        <div className={`boardBtns ${props.selectedMenuItem === MenuItems.Synopsis ? 'selected' : ''}`} onClick={() => props.setSelectedMenuItemHandler(MenuItems.Synopsis)}>
          <img src={SynopsisIcon} />
        </div>
        <p onClick={() => props.setSelectedMenuItemHandler(MenuItems.Synopsis)} className='toolLabel'>Synopsis</p>
      </IconButton>
    </Tooltip>
  )
}

export const EnterAnswerButton = (props) => {

  return (
    <Tooltip title="Check My Work" placement='right' arrow>
      <IconButton>
        <div className={`boardBtns ${props.selectedMenuItem === MenuItems.EnterAnswer ? 'selected' : ''}`} onClick={() => props.setSelectedMenuItemHandler(MenuItems.EnterAnswer)}>
          <img src={checkWorkIcon} />
        </div>
        <p onClick={() => props.setSelectedMenuItemHandler(MenuItems.EnterAnswer)} className='toolLabel'>Check My Work</p>
      </IconButton>
    </Tooltip>
  )
}

export const SaveMyWorkButton = (props) => {
  return (
    <Tooltip title="Save My Work" placement='top' arrow>
      <IconButton >
        <div className={`boardBtnsupperSaveMyWork ${props.selectedMenuItem === MenuItems.SaveMyWork ? 'selected' : ''}`}
          onClick={() => props.setSelectedMenuItemHandler(MenuItems.SaveMyWork)} >
          <img height="22px" width="22px" src={SaveToTranscriptIcon} alt="Save My Work" />
        </div>
        <span onClick={() => props.setSelectedMenuItemHandler(MenuItems.SaveMyWork)}
          className='toolLabel'>Save My Work</span>
      </IconButton>
    </Tooltip>
  )
}