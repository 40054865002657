import React from 'react';
// import Logo from '../../../assets/images/aiPlato.svg'
import Logo from '../../../assets/images/logo_aiPlato-white-h.png'
import { Link } from 'react-router-dom';

const logo = (props) => {
    return (
        <Link to='/'><img src={Logo} alt="Logo" /></Link>
    );
}

export default logo;