import React from "react";
import "./SolutionBoard.scss";
import ProblemStatement from './ProblemStatement';
import HintIcon from "../../assets/images/icon-hint1.svg";
import ShowNextStepIcon from "../../assets/images/icon-show-next-step.svg";
import CalcIcon from "../../assets/images/icon-calculation.svg";
import ShowFullSolutionIcon from "../../assets/images/icon-show-full-solution.svg";
import checkWorkIcon from "../../assets/images/icon-check.svg";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  IconButton,
  Typography
} from "@mui/material";
import Tooltip from '@mui/material/Tooltip';
import { isNil } from 'lodash';
import { generateLatexFromMathML, askAnyProblemQuestion } from '../../common/API';
import { getCurrentUserId, formatAnyProblemAPIResponse, obtainSubstitution } from '../../common/Functions';
// import Modal from '../Common/ModalComponent/ModalComponent';
import CircularProgress from '@mui/material/CircularProgress';
import EquationDisplay from "../../container/SketchPad/EquationDisplay";
import Aux from '../../hoc/Wrapper';
import { Button, Modal } from 'react-bootstrap';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import Feedback from "../Feedback/Feedback";
import { substituteText } from '../../common/Constants';
import { toast } from 'react-toastify';
import { ToastContainer } from 'react-toastify';
import parse from 'html-react-parser';
import CkEditorComponent from "../CkEditorComponent/CkEditorComponent";
import Flow from '../SolutionGraph/flow';
import MathJaxRenderer from "../Common/MathJaxRenderer/MathJaxRenderer";

const CONTENT_TYPE = {
  EQUATION: 'eq',
  TEXT: 'text'
}
class SolutionBoard extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      anyProblemStatement: '',
      actionBtnDisabled: true,
      equations: [],
      isSessionStarted: false,
      loader: false,
      showModal: false,
      showModalHint: false,
      modaltitle: "",
      modaltitleHint: "",
      modalresponse: "",
      modalresponseHint: "",
      statementresponse: "",
      problemstatementexpanded: false,
      buttonDisabled: false,
      checkMyWorkShowNextStepDisabled: false,
      show: false,
      currentHintIndex: 0,
      hintRemedialText: null,
      ckEditorMathML: '',
      editorHeight: '200px',
      showModalSolution: false
    }
  }
  anyWirisEditor = null;
  modalBody = null;
  tutormodalbody = null;
  modalTitle = null;
  showButton = false;
  customButton = null;
  loadTranscriptTimer = null;
  draggable = false;

  closeModal() {
    this.setState({ showModal: false });
  }

  closeModalHint() {
    this.setState({ showModalHint: false });
  }

  closeModalSolution() {
    this.setState({ showModalSolution: false });
  }

  anyPoblemStatementHandler = (statement) => {

    this.askAnyProblemproblemstatement('', statement);
    this.setState({ anyProblemStatement: statement, actionBtnDisabled: false }, () => {
      if (!isNil(document.getElementById('anyWirisEditorContainer')) && isNil(this.anyWirisEditor) && !isNil(window.com)) {

        this.anyWirisEditor = window.com.wiris.jsEditor.JsEditor.newInstance({ 'language': 'en' });
        this.anyWirisEditor.insertInto(document.getElementById('anyWirisEditorContainer'));
        this.anyWirisEditor.setMathML('<math xmlns="http://www.w3.org/1998/Math/MathML"></math>')
      }
    })
  }
  openWiris = () => {   
    this.anyWirisEditor.setMathML('<math xmlns="http://www.w3.org/1998/Math/MathML"></math>');
    document.getElementById('anyWirisModalMobile').style.display = 'block';
    this.anyWirisEditor.focus();
  }

  closeWiris = () => {
    this.anyWirisEditor.setMathML('<math xmlns="http://www.w3.org/1998/Math/MathML"></math>');
    document.getElementById('anyWirisModalMobile').style.display = 'none';
  }

  // anyPoblemStatementHandler = (statement) => {

  //   this.askAnyProblemproblemstatement('', statement);
  //   this.setState({ anyProblemStatement: statement, actionBtnDisabled: false })
  //   /*
  //   , () => {
  //        if (!isNil(document.getElementById('anyWirisEditorContainer')) && isNil(this.anyWirisEditor) && !isNil(window.com)) {

  //       this.anyWirisEditor = window.com.wiris.jsEditor.JsEditor.newInstance({ 'language': 'en' });
  //       this.anyWirisEditor.insertInto(document.getElementById('anyWirisEditorContainer'));
  //       this.anyWirisEditor.setMathML('<math xmlns="http://www.w3.org/1998/Math/MathML"></math>')
  //     }
  //   })
  //   */
  // }

  handleCancel = () => {
    this.anyWirisEditor = null
    this.setState({ anyProblemStatement: null, equations: [] })
  }

  // openWiris = () => {
  //   const domEditableElement = document.querySelector('.ck-editor__editable');
  //   // Get the editor instance from the editable element.
  //   const editorInstance = domEditableElement.ckeditorInstance;
  //   // Use the editor instance API.
  //   editorInstance.setData('');
  //   document.getElementById('anyWirisModalMobile').style.display = 'block';
  // }

  // closeWiris = () => {
  //   document.getElementById('anyWirisModalMobile').style.display = 'none';
  // }

  getMathmlFromWiris = () => {
    let question = this.anyWirisEditor.getMathML(); //this.state.ckEditorMathML
    console.log("question - " + question)
    this.askAnyProblem('', question); 
  }

  askAnyProblemproblemstatement = (mathml, question) => {
    this.setState({ loader: true, buttonDisabled: true })

    let params = new URLSearchParams();
    params.append('InputMessage', question);
    params.append('isNew', false);
    params.append('sessionid', this.props.session_id);
    params.append('userid', getCurrentUserId());


    askAnyProblemQuestion(params).then(res => {
      let abc = formatAnyProblemAPIResponse(res.data.response)
      this.setState({ statementresponse: abc });
      this.setState({ loader: false, problemstatementexpanded: true, buttonDisabled: false })
    }).catch((err) => {
      return err;
    })
  }

  askAnyProblem = (mathml, question) => {
    this.setState({ loader: true })
    let equationArry = [];

    // let isFirstCall = true
    if (this.state.equations.length > 0) {
      // isFirstCall = false;
      equationArry = this.state.equations
    }
    equationArry.push({ class: '', type: CONTENT_TYPE.EQUATION, mathml: question, eqindex: equationArry.length, 'ans': '' })

    let params = new URLSearchParams();
    params.append('InputMessage', question);
    params.append('isNew', false);
    params.append('sessionid', this.props.session_id);
    params.append('userid', getCurrentUserId());

    askAnyProblemQuestion(params).then(res => {
      if (!isNil(res.data.response)) {
        this.setState({ loader: false })
        equationArry[equationArry.length - 1]['ans'] = formatAnyProblemAPIResponse(res.data.response);
        this.setState({ equations: equationArry });
        this.closeWiris();
        this.setState({ loader: false })
      }
    }).catch((err) => {
      return err;
    })
  }

  getHint = (event, inputmessage, modaltitle) => {
    this.setState({ loader: true })

    if (inputmessage === "Check My Work") {
      this.resetHintCount()
    }

    let params = new URLSearchParams();
    if (inputmessage === "show me a hint") {
      params.append('InputMessage', inputmessage);
      params.append('isNew', false);
      params.append('sessionid', this.props.session_id);
      params.append('userid', getCurrentUserId());
      params.append('hintCount', this.state.currentHintIndex);
      params.append('prevsiousHintRemedialText', this.state.hintRemedialText)
    }
    else {
      params.append('InputMessage', inputmessage);
      params.append('isNew', false);
      params.append('sessionid', this.props.session_id);
      params.append('userid', getCurrentUserId());
    }

    askAnyProblemQuestion(params).then(res => {
      debugger;
      this.setState({ loader: false })
      if (res.data.ui_json !== undefined && res.data.ui_json !== null && res.data.ui_json !== "") {

        let finalresponse = formatAnyProblemAPIResponse(res.data.ui_json)
        this.setState({ modalresponse: finalresponse, showModal: false, showModalSolution: true, modaltitle: modaltitle, checkMyWorkShowNextStepDisabled: false })
      }
      else if (res.data.response !== undefined && res.data.response !== null && res.data.response !== "") {
        let finalresponse = formatAnyProblemAPIResponse(res.data.response)
        this.setState({ modalresponse: finalresponse, showModal: true, showModalSolution: false, modaltitle: modaltitle })
      }
      else if (inputmessage === "show me a hint" && !isNil(res.data.hint_remedial_text)) {

        let finalsubstitutionslist = ""
        if (!isNil(res.data.substitution_map)) {
          finalsubstitutionslist = obtainSubstitution(res.data.substitution_map)
        }

        if (res.data.hint_remedial_text || res.data.hint_remedial_source) {
          if (!(this.state.hintLastMatchedCheckpointId === res.data.last_matched_checkpoint_id)) {
            console.log("HINT1")
            this.setState((state, props) => ({
              currentHintIndex: 1,
              hintRemedialText: res.data.hint_remedial_text,
              hintRemedialSource: res.data.hint_remedial_source,
              hintLastMatchedCheckpointId: res.data.last_matched_checkpoint_id,
              totalHints: res.data.total_hints_for_the_challenge,
            }));
          }
          else if (this.state.hintLastMatchedCheckpointId ===
            res.data.last_matched_checkpoint_id)// && !(this.state.hintRemedialSource === res.data.hint_remedial_source && this.state.hintRemedialText === res.data.hint_remedial_text))
          {
            console.log("HINT2");
            this.setState((state, props) => ({
              currentHintIndex: res.data.total_hints_for_the_challenge > state.currentHintIndex ? state.currentHintIndex + 1 : state.currentHintIndex,
              hintRemedialText: res.data.hint_remedial_text,
              hintRemedialSource: res.data.hint_remedial_source,
              hintLastMatchedCheckpointId: res.data.last_matched_checkpoint_id,
              totalHints: res.data.total_hints_for_the_challenge,
            }));

          }

          this.openHintResponseModel(event, finalsubstitutionslist, res, this.state.totalHints)

          if (this.props.location.state.hintUsed) {
            this.props.location.state.hintUsed = true;
          } else {
            this.props.location.state = {
              ...this.props.location.state,
              hintUsed: true
            }
          }
        } else if (res.data.message) {
          toast(res.data.message, {
            type: toast.TYPE.INFO,
            position: toast.POSITION.BOTTOM_RIGHT
          })
        }
      }
    }).catch((err) => {
      return err;
    })
  }

  openHintResponseModel = (event, finalsubstitutionslist, res) => {
    let modalBody = (
      <Aux>
        <p className='modal-p' dangerouslySetInnerHTML={{ __html: res.data.hint_remedial_text ? res.data.hint_remedial_text : '' }}></p>
        <a className='mt-2' href={res.data.hint_remedial_source} target='_blank' rel="noopener noreferrer" >{res.data.hint_remedial_resource_display_name ? res.data.hint_remedial_resource_display_name : res.data.hint_remedial_source}</a>

        {
          finalsubstitutionslist !== "" && finalsubstitutionslist !== '' && finalsubstitutionslist !== undefined && finalsubstitutionslist !== null && finalsubstitutionslist !== "null" && finalsubstitutionslist !== "null" ? (
            <>
              <div style={{ fontSize: "11px", marginTop: "10px", maxWidth: "500px" }}>
                {substituteText} {finalsubstitutionslist}
              </div>
            </>
          ) : null
        }
      </Aux>
    );

    this.setState({ modalresponseHint: modalBody, showModal: false, showModalSolution: false, showModalHint: true, modaltitleHint: "Hint" })
  }

  handleClose = () => {
    this.setState({ show: false });
  }

  handleAccordionChange = () => {
    this.setState({ problemstatementexpanded: !this.state.problemstatementexpanded })
  }

  resetHintCount = () => {
    this.setState({ hintRemedialText: "", currentHintIndex: 0, totalHints: -1 });

  }

  ckEditorDataChangedEvent = (changedData) => {
    this.setState({ ckEditorMathML: changedData })
  }

  render() {
    const totalHints = this.state.totalHints
    const currentHint = this.state.currentHintIndex

    let shouldShowHint = this.state.currentHintIndex >= totalHints ? true : false


    return (
      <>
        <Modal show={this.state.show} onHide={this.handleClose}>
          <Modal.Header closeButton>
            <Modal.Title>Submit Feedback</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Feedback />
          </Modal.Body>
        </Modal>

        <Modal
          show={this.state.showModalSolution} onHide={this.closeModalSolution.bind(this)}
          size="sm"
          aria-labelledby="contained-modal-title-vcenterSolution"
          centered
        >
          <Modal.Header closeButton>
            <Modal.Title id="contained-modal-title-vcenterSolution">
              {this.state.modaltitle}
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Aux>
              <div className="solutionGraphCotainer">
                <p className="sidebar-heading mt-2">Solution Graph</p>
                <Flow readOnly={true} uiJson={this.state.modalresponse} hasCheckMyWOrk={false} ></Flow>
              </div>
            </Aux>
          </Modal.Body>
          <Modal.Footer>
            <Button onClick={this.closeModalSolution.bind(this)} >Ok</Button>
          </Modal.Footer>
        </Modal>

        <Modal
          show={this.state.showModal} onHide={this.closeModal.bind(this)}
          size="sm"
          aria-labelledby="contained-modal-title-vcenter"
          centered
        >
          <Modal.Header closeButton>
            <Modal.Title id="contained-modal-title-vcenter">
              {this.state.modaltitle}
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Aux>
              <div className='mt-3' style={{ width: "auto" }}>
                <MathJaxRenderer math={this.state.modalresponse}></MathJaxRenderer>
              </div>
            </Aux>
          </Modal.Body>
          <Modal.Footer>
            <Button onClick={this.closeModal.bind(this)} >Ok</Button>
          </Modal.Footer>
        </Modal>

        <Modal
          show={this.state.showModalHint} onHide={this.closeModalHint.bind(this)}
          size="sm"
          aria-labelledby="contained-modal-title-vcenterHint"
          centered
        >
          <Modal.Header closeButton>
            <Modal.Title id="contained-modal-title-vcenterHint">
              <div className='mt-3' style={{ width: "auto" }}>
                {this.state.modaltitleHint}
              </div>
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Aux>
              <div className='mt-9'>
                {this.state.modalresponseHint}
              </div>
            </Aux>
          </Modal.Body>
          <Modal.Footer>
            <Button onClick={this.closeModalHint.bind(this)} >Ok</Button>
          </Modal.Footer>
        </Modal>
        <div>
          {
            this.state.anyProblemStatement !== undefined && this.state.anyProblemStatement !== null && this.state.anyProblemStatement !== "" ?
              (
                <>
                  <div className="probStatementContainer">
                    <div className="px-3" style={{ paddingBottom: "100px" }}>
                      <div style={{ display: "flex", justifyContent: "space-between" }}>
                        <p className="screentitle pt-3">Problem Statement</p>
                        <p className="screentitle pt-3 cursorPointer" onClick={this.handleCancel}>Cancel</p>
                      </div>
                      <div className="probStatement">
                        <Accordion
                        // onChange={this.handleAccordionChange()}
                        >
                          <AccordionSummary
                            expandIcon={<ExpandMoreIcon />}
                            aria-controls="panel1a-content"
                            id="panel1a-header"
                          >
                            <Typography>
                              {/* <MathRendererComponent tex={this.state.anyProblemStatement} /> */}
                              {parse(this.state.anyProblemStatement)}
                            </Typography>
                          </AccordionSummary>
                          <AccordionDetails>
                            <Typography>
                              {/* <MathRendererComponent tex={this.state.statementresponse} /> */}
                              {parse(this.state.statementresponse)}
                            </Typography>
                          </AccordionDetails>
                        </Accordion>
                      </div>
                      {
                        this.state.equations !== undefined && this.state.equations !== null && this.state.equations.length > 0 ? (
                          this.state.equations.map((item, index) => {
                            return (
                              <EquationDisplay setid={index + 1} totalEquation={this.state.equations.length + 1} responseText={item.ans === undefined ? '' : item.ans} isanyProb={this.props.anyProblem} mathml={item.mathml} />
                            )
                          })
                        ) : null
                      }
                      {/* <div className="noErrorLine">
                      <span style={{ textWrap: "nowrap" }}>Line 1:</span>
                      <div style={{ marginLeft: "10px" }}>
                        <div className="d-flex">
                          <div>EquationDisplay</div>
                          <div className="pl-3 EditDel">
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width="16"
                              height="16"
                              viewBox="0 0 24 24"
                              fill="#404C3D"
                              id="edit">
                              <path d="M21.3103 6.87842L17.1216 2.68873C16.9823 2.5494 16.8169 2.43888 16.6349 2.36348C16.4529 2.28808 16.2578 2.24927 16.0608 2.24927C15.8638 2.24927 15.6687 2.28808 15.4867 2.36348C15.3047 2.43888 15.1393 2.5494 15 2.68873L3.43969 14.25C3.2998 14.3888 3.18889 14.554 3.11341 14.736C3.03792 14.918 2.99938 15.1132 3.00001 15.3103V19.5C3.00001 19.8978 3.15804 20.2793 3.43935 20.5606C3.72065 20.8419 4.10218 21 4.50001 21H8.6897C8.88675 21.0006 9.08197 20.9621 9.26399 20.8866C9.44602 20.8111 9.61122 20.7002 9.75001 20.5603L21.3103 8.99998C21.4496 8.86069 21.5602 8.69531 21.6356 8.5133C21.711 8.33129 21.7498 8.13621 21.7498 7.9392C21.7498 7.74219 21.711 7.5471 21.6356 7.36509C21.5602 7.18308 21.4496 7.01771 21.3103 6.87842ZM18 10.1887L13.8103 5.99998L16.0603 3.74998L20.25 7.93873L18 10.1887Z" />
                            </svg>
                          </div>
                        </div>
                      </div>
                    </div> */}
                    </div>
                    <div style={{ width: '91.2%' }} className={`solFooterBtns ${this.state.buttonDisabled ? ' disabledDiv' : ''} `}>
                      <Tooltip title="Show Hint">
                        <IconButton disabled={shouldShowHint} onClick={(event) => this.getHint(event, "show me a hint", " Hint ")}>
                          <div className="footerBtns">
                            <img src={HintIcon} />
                          </div>
                          <span>
                            Show
                            <br />
                            Hint {totalHints >= 0 ? '(' + currentHint + '/' + totalHints + ')' : ''}
                          </span>
                        </IconButton>
                      </Tooltip>
                      <Tooltip title="Show Next Step">
                        {
                          this.state.checkMyWorkShowNextStepDisabled ? (
                            <IconButton disabled={true}>
                              <div className="footerBtns">
                                <img src={ShowNextStepIcon} />
                              </div>
                              <span>
                                Show
                                <br />
                                Next Step
                              </span>
                            </IconButton>) : (<IconButton onClick={(event) => this.getHint(event, "Show Next Step", "Next Step")}>
                              <div className="footerBtns">
                                <img src={ShowNextStepIcon} />
                              </div>
                              <span>
                                Show
                                <br />
                                Next Step
                              </span>
                            </IconButton>)
                        }

                      </Tooltip>
                      <Tooltip title="Equation Keyboard">
                        <IconButton onClick={() => this.openWiris()}>
                          <div className="footerBtns equationBtn">
                            <img src={CalcIcon} />
                          </div>
                          <span>
                            EQUATION
                            <br />
                            EDITOR
                          </span>
                        </IconButton>
                      </Tooltip>
                      <Tooltip title="Show Solution">
                        <IconButton onClick={(event) => this.getHint(event, "Show Full Solution", "Full Solution")}>
                          <div className="footerBtns">
                            <img src={ShowFullSolutionIcon} />
                          </div>
                          <span>
                            Show
                            <br />
                            Solution
                          </span>
                        </IconButton>
                      </Tooltip>
                      <Tooltip title="Check My Work">
                        {
                          this.state.checkMyWorkShowNextStepDisabled ?
                            (
                              <IconButton disabled="true">
                                <div className="footerBtns">
                                  <img src={checkWorkIcon} />
                                </div>
                                <span>
                                  Check
                                  <br />
                                  My Work
                                </span>
                              </IconButton>
                            ) : (<IconButton onClick={(event) => this.getHint(event, "Check My Work", "Check My Work")}
                            >
                              <div className="footerBtns">
                                <img src={checkWorkIcon} />
                              </div>
                              <span>
                                Check
                                <br />
                                My Work
                              </span>
                            </IconButton>)
                        }


                      </Tooltip>
                    </div>
                  </div>

                  <div className="handwriting-content wirisMobile" id="anyWirisModalMobile">
                    <div id="anyWirisEditorContainer"></div>
                    <div className='d-flex justify-content-center mt-2'>
                      <button className='mr-2 wiris-button' onClick={() => this.getMathmlFromWiris()} >Submit</button>
                      <button className='wiris-button' onClick={() => this.closeWiris()}>Cancel</button>
                    </div>
                  </div>
                  {/* <div className="handwriting-content wirisMobile" id="anyWirisModalMobile">
                    <div id="anyWirisEditorContainer"></div>
                    <CkEditorComponent ckEditorId="editorInitialSB"
                      editorHeight={this.state.editorHeight}
                      placeHolderText=""
                      onCKEditorDataChange={this.ckEditorDataChangedEvent}
                    />
                    <div className='d-flex justify-content-center mt-2'>
                      <button className='mr-2 wiris-button' onClick={() => this.getMathmlFromWiris()} >Submit</button>
                      <button className='wiris-button' onClick={() => this.closeWiris()}>Cancel</button>
                    </div>
                  </div> */}
                </>
              ) :
              (
                <ProblemStatement sessionId={this.props.session_id} frommobile={true} onSubmitProblemStatement={this.anyPoblemStatementHandler}>
                </ProblemStatement>
              )
          }

          <ToastContainer />
          {
            this.state.loader && (
              <CircularProgress
                size={24}
                sx={{
                  color: "#164b99",
                  position: 'absolute',
                  top: '50%',
                  left: '50%',
                  marginTop: '-12px',
                  marginLeft: '-12px',
                  zIndex: 9999
                }}
              />
            )
          }
          {/* <Modal
            popUpSize="small"
            show={this.state.showModal}
            handleClose={this.closeModal}
            title={this.modalTitle}
            body={this.modalBody}
            showButton={this.showButton}
            customButton={this.customButton}
            draggable={this.draggable} /> */}
        </div>
      </>
    );
  }
}
export default SolutionBoard;