import React from 'react';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Tooltip from 'react-bootstrap/Tooltip';
import { Button } from 'react-bootstrap';

export default function ChallengeComments(props) {    
    return (
        <>
            {
                props.commentvalue !== "null" && props.commentvalue !== "" && props.commentvalue !== undefined ? (
                    <OverlayTrigger
                        placement="right"
                        //delay={{ show: 250, hide: 400 }}                       
                        overlay={

                            <Tooltip id="button-tooltip" wrapperStyle={{ backgroundColor: '#fff' }}>
                                <textarea
                                    style={{backgroundColor:"#000", color:"#fff", overflow:"auto", resize:"none"}}
                                    rows="6"                                    
                                    value={props.commentvalue}
                                />
                            </Tooltip>                           
                        }
                    >
                        {
                            props.iscolorchange === true ? (<Button
                                className="commentcss"
                                variant="link" onClick={props.handlecomments}>
                                <i class="fa fa-comments" style={{ fontSize: "18px", boxShadow: "none", textDecoration: 'none', }}></i>
                            </Button>) : (<Button
                                className="commentcsswithouttext"                                
                                variant="link" onClick={props.handlecomments}>
                                <i class="fa fa-comments" style={{ fontSize: "18px", boxShadow: "none", textDecoration: 'none', }}></i>
                            </Button>)
                        }
                    </OverlayTrigger>
                ) :
                    (<Button
                        className="commentcsswithouttext"                        
                        variant="link" onClick={props.handlecomments}>
                        <i class="fa fa-comments" style={{ fontSize: "18px", boxShadow: "none", textDecoration: 'none', }}></i>
                    </Button>)
            }
        </>
    )
}