import React from 'react'
import Ratings from '../Common/Ratings/Ratings';
import Tooltip from '@material-ui/core/Tooltip';
import { Row, Col } from 'react-bootstrap';
import { imgURL } from '../../common/API';

function TutorProfile(props) {
  return (
    <>
      <div className='tuorporfile-content-card'>
        <div className="headertitle">
          <div><h4>Tutor Profile</h4></div>
          <div className='closeIcon' onClick={props.handleclose}> <i class="fa fa-close" style={{ fontSize: "18px", boxShadow: "none", textDecoration: 'none', }} onClick={props.handleclose}></i></div>
        </div>
        <div className='content-card'>
          <div className='video-thumb'>
            {props.data.profile_pic.length > 0 ?
              <img src={imgURL + props.data.profile_pic} alt="" style={{ height: "100px", width: "100px" }} />
              :
              <img src='/images/ic-challenge-placeholder.svg' alt="" style={{ height: "100px", width: "100px" }} />
            }
          </div>
          <div className='profile-card-text'>
            <Tooltip title={props.data.name}>
              <h3>{props.data.name}</h3>
            </Tooltip>
            <p>{props.data.current_profession} {props.data.education ? <> | {props.data.education} </> : ''}</p>
            <Row>
              <Col>
                <div className="clearfix rating-hover">
                  <Ratings stars={props.data.overall_rating} className='ratings main-video-rating mr-2' readOnly />
                </div>
              </Col>
            </Row>
          </div>

        </div>
      </div >
    </>
  )
}
export default TutorProfile
