import React, { Component } from 'react';
import Breadcrumb from '../../components/Breadcrumb/Breadcrumb';
import Container from 'react-bootstrap/Container'
import Row from 'react-bootstrap/Row'
import CuriosityExplorationLeftPart from '../../components/CurriculumContent/CuriosityExploration/CuriosityExplorationLeftPart/CuriosityExplorationLeftPart'
import curriculumImage from '../../../public/images/curriculum_content_img.svg'
import './CuriosityExploration.scss'
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { withNamespaces } from 'react-i18next';
import imageMapResize from 'image-map-resizer'
import Modal from '../../components/Common/ModalComponent/ModalComponent';
import CurriculumContentCard from '../../components/CurriculumContent/CurriculumContentCard/CurriculumContentCard';
import Spinner from 'react-bootstrap/Spinner'
import { fetchSubSectionContent } from '../../common/API';

class CuriosityExploration extends Component {

    state = {
        showModal: false,
    }

    componentDidMount() {
        imageMapResize()
    }

    modalBody = null;
    modalTitle = null;
    showButton = false;

    openModal = (event, modalTitle, modalBody, showButton = false) => {
        event.preventDefault();
        this.modalBody = modalBody;
        this.modalTitle = modalTitle;
        this.showButton = showButton;
        this.setState({ showModal: true });
    }

    closeModal = () => {
        this.setState({ showModal: false });
    }

    openContentOptionsModal = (e, subSectionId) => {
        const getContentOptionsJSX = (data) => {
            return (
                <div className="content-options">
                    <CurriculumContentCard
                        title="Recommended Content for Your Cognitive Profile"
                        data={[data[0]]}
                        linkStateObj={{ prevPath: ['/curiosity-exploration'], prevNode: ['Curiosity Exploration'] }} />
                    {data.length > 1 ?
                        <CurriculumContentCard
                            title="Other Curated Content Options"
                            data={data.splice(1)}
                            linkStateObj={{ prevPath: ['/curiosity-exploration'], prevNode: ['Curiosity Exploration'] }} />
                        : null}
                </div>
            )
        }

        const reqData = { params: { subSectionId } }
        this.openModal(e,
            "Content Options",
            <div className="progress-bar-container"><Spinner animation="border" role="status" /></div>)

        fetchSubSectionContent(reqData)
            .then(res => {
                res.data.content.length > 0 ?
                    this.openModal(e,
                        "Content Options",
                        getContentOptionsJSX(res.data.content))
                    : this.openModal(e,
                        "Content Options",
                        <p>No Content Found.</p>,
                        true)
            })
            .catch(err => {
                console.error(err.message)
                if (err.response.status === 404) {
                    this.openModal(e,
                        "Content Options",
                        <p>No Content Found.</p>,
                        true)
                }
            })
    }

    render() {
        return (
            <>
                <ToastContainer />
                <Breadcrumb
                    currentNode='Curiosity Exploration'
                    prevNode={this.props.location.state ? this.props.location.state : []} />
                <Container>
                    <Row>
                        <div className="curiosity-left-container col-6">
                            <CuriosityExplorationLeftPart />
                        </div>
                        <div className="curiosity-right-container col-6">
                            <img className="curriculum-image" alt="" src={curriculumImage} usemap="#links"></img>
                            <map name="links">
                                <area onClick={(e) => this.openContentOptionsModal(e, 2)}
                                    coords="256,72,42" shape="circle" alt="" />
                                <area onClick={(e) => this.openContentOptionsModal(e, 3)}
                                    shape="circle" coords="434,158,68" alt="" />
                                <area onClick={(e) => this.openContentOptionsModal(e, 8)}
                                    shape="circle" coords="394,350,61" alt="" />
                            </map>
                        </div>
                    </Row>
                </Container>
                <Modal
                    show={this.state.showModal}
                    handleClose={this.closeModal}
                    title={this.modalTitle}
                    body={this.modalBody}
                    showButton={this.showButton} />
            </>
        )
    }
}

export default withNamespaces()(CuriosityExploration);