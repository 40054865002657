import React, { useState, useEffect } from "react";
import { Container, Row, Col } from 'react-bootstrap';
import { withNamespaces } from 'react-i18next';
import './datacollections.scss'
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import dayjs from 'dayjs';
import {
    Avatar,
    makeStyles,

} from '@material-ui/core';
import { CSVLink } from "react-csv"
import DownloadIcon from '../../assets/images/download-icon.svg';
import MathJaxRenderer from '../../components/Common/MathJaxRenderer/MathJaxRenderer';
import { isNil } from 'lodash';
import TextField from '@mui/material/TextField';
import Stack from '@mui/material/Stack';
import { Typography } from "@mui/material";
import { Button } from "@mui/material";
import {
    datacollectionssteps, getstudent_List, datacollection_UserActions
    , datacollection_UserEquations, imgURL, datacollection_UserFeedback
    , datacollection_NewUserdata, datacollection_visitorsummary
} from '../../common/API'
import { styled } from '@mui/material/styles';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import CircularProgress from '@mui/material/CircularProgress';
import Pagination from '@mui/material/Pagination';
import { MultiSelectAll } from "./MultiSelectAll";
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';

const useStyles = makeStyles((theme) => ({
    root: {},
    avatar: {
        height: 42,
        width: 42,
        marginRight: theme.spacing(1)
    },
    avatarlarge: {
        height: 150,
        width: 150,
    },
    linkbutton: {
        fontSize: "10px"
    },
    datepickerc: {
        backgroundColor: "#fff"
    },
    textfieldc: {
        backgroundColor: "#fff",
        width: "550px"

    }
}));

const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
        backgroundColor: "#c1c0c0 !important",
        color: theme.palette.common.black,
        padding: "10px",
        fontSize: "14px !important",
        fontWeight: "bold",
        textTransform: "none !important",
        border: "1px solid #ccc"
    },
    [`&.${tableCellClasses.body}`]: {
        fontSize: "12px !important",
        padding: "7px",
        textTransform: "none !important",
        border: "1px solid #ccc"
    },
}));


const StyledTableCellbody = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
        //backgroundColor: theme.palette.common.black,
        //color: theme.palette.common.white,
        padding: "10px",
        textTransform: "none !important",
        border: "1px solid #ccc"

    },
    [`&.${tableCellClasses.body}`]: {
        fontSize: "12px !important",
        padding: "7px",
        border: "1px solid #ccc"
    },
}));
const StyledTableRow = styled(TableRow)(({ theme }) => ({
    '&:nth-of-type(even)': {
        backgroundColor: theme.palette.action.hover,

    },
    // hide last border
    '&:last-child td, &:last-child th': {
        border: "1px solid #ccc"
    },
}));
const DataCollections = (props) => {
    const classes = useStyles();
    const [loading, setloading] = useState(false)
    const [valueStart, setvalueStart] = React.useState();
    const [valueEnd, setvalueEnd] = React.useState();
    const [error, setError] = useState("");
    const [enderror, setEndError] = useState("");
    const [filteredOptions, setFilteredOptions] = useState(null);
    const [selectedOptions, setSelectedOptions] = useState([]);
    const [selectedExcludeOptions, setselectedExcludeOptions] = useState([]);
    const [studentList, setStudentList] = useState([])

    const [filterUsers, setFilterUsers] = useState("")
    const [actionData, setactionData] = useState()
    const [page, setpage] = useState(1)
    const [startPage, setstartPage] = useState(0)
    const [rowsPerPage, setrowsPerPage] = useState(10)
    const [totalPages, settotalPages] = useState(1)


    const [equationData, setequationData] = useState()
    const [pageEq, setpageEq] = useState(1)
    const [startPageEq, setstartPageEq] = useState(0)
    const [rowsPerPageEq, setrowsPerPageEq] = useState(10)
    const [totalPagesEq, settotalPagesEq] = useState(1)

    const [feedbackData, setfeedbackData] = useState()
    const [newuserdata, setnewuserdata] = useState()
    const [pagefeedback, setpagefeedback] = useState(1)
    const [pagenewusersdata, setpagenewusersdata] = useState(1)
    const [startPagefeedback, setstartPagefeedback] = useState(0)
    const [rowsPerPagefeedback, setrowsPerPagefeedback] = useState(10)
    const [totalPagesfeedback, settotalPagesfeedback] = useState(1)
    const [startPagenewusersdata, setstartPagenewusersdata] = useState(0)
    const [rowsPerPagenewusersdata, setrowsPerPagenewusersdata] = useState(10)
    const [totalPagesnewusersdata, settotalPagesnewusersdata] = useState(1)

    const [showTotalCountDiv, setshowTotalCountDiv] = useState(false)
    const [showActiontable, setActiontable] = useState(false)
    const [showEquationtable, setEquationtable] = useState(false)
    const [showFeedbacktable, setFeedbacktable] = useState(false)
    const [showNewuserdatatable, setNewuserdatatable] = useState(false)
    const [showVisitorSummary, setShowVisitorSummary] = useState(false)

    const [totalCountData, settotalCountData] = useState({
        nextstepstudentcount: 0
        , withoutcheckmyworkcorrectanscount: 0
        , totalcheckmyworkcount: 0
        , successfullcorrectnodecheckmywork: 0
        , unsuccessfullcorrectnodecheckmywork: 0
        , successfullycorrectafteroneattepmt: 0
    })

    const [totalVisitorCountData, setTotalVisitorCountData] = useState({
        totalvisitors: 0
        , totaltryitout: 0
        , totalsignup: 0
        , totalgooglesignup: 0
    })

    const [showImage, setShowImage] = useState(false)
    const [imageString, setImageString] = useState("")
    const [maxWidth, setMaxWidth] = React.useState('md');
    const [csvuserActionData, setcsvuserActionData] = useState([["Id", "Name", "Email", "Challenge", "Action", "Description", "Result", "Date"]])

    const [csvuserEquationData, setcsvuserEquationData] = useState([["Id", "Name", "Email", "Challenge", "Input Data", "Output Data", "Latex", "Check Equation", "Date"]])
    const [csvuserFeedbackData, setcsvuserFeedbackData] = useState([["Id", "Name", "Email", "User Feedback", "Rating", "Date"]])
    const [csvnewuserData, setcsvnewuserData] = useState([["Id", "Name", "Email", "Role", "SignUp Method", "Ref URL", "Device/OS", "Content Accessed", "Date"]])

    const handleChangePage = (event, page) => {
        setpage(page)
        setstartPage(page - 1)
    }
    const handleChangePageEq = (event, page) => {
        setpageEq(page)
        setstartPageEq(page - 1)
    }
    const handleChangePagefeedback = (event, page) => {
        setpagefeedback(page)
        setstartPagefeedback(page - 1)
    }
    const handleChangePagenewusersdata = (event, page) => {
        setpagenewusersdata(page)
        setstartPagenewusersdata(page - 1)
    }

    useEffect(() => {
        getstudent_List().then(res => {
            if (res.status === 200) {
                if (res.data.data !== undefined && res.data.data !== null) {
                    setStudentList(res.data.data)
                    // clearSelected(0)
                    //setSelectedOptions([{value: '<SELECT_ALL>', label: 'All Included Students'}])
                }
            }
        })

    }, []);

    const onKeyDown = (e) => {
        e.preventDefault();
    };

    const handleChangestart = (newValue) => {
        setvalueStart(new Date(newValue));
    };
    const handleChangeend = (newValue) => {
        setvalueEnd(new Date(newValue));
        const today = dayjs(new Date(newValue));
        const pastDate = dayjs(new Date(valueStart));

        if (pastDate.isAfter(today)) {
            return setEndError("End time must be greater then start time!");
        }
        else {
            setEndError("");
        }
    };
    const handleselectusers = (selected) => {
        if (selected.length > 0) {
            setSelectedOptions(selected)            
        }
        else {
            setSelectedOptions([])
        }
    }
    const getexcludeuserIds = (selectedStudentsIds) => {
        let exludeuser_ids = null
        // let useremails = null
        if (selectedStudentsIds.length > 0) {
            selectedStudentsIds.forEach(element => {
                if (exludeuser_ids === null) {
                    exludeuser_ids = element.value
                    // useremails = element.label
                }
                else {
                    exludeuser_ids = exludeuser_ids + "," + element.value
                    // useremails = useremails + "," + element.label
                }
            });
        }
        return exludeuser_ids;
    }
    const handlefetchdata = (event, buttonActionName) => {
        setloading(true)
        let includeduser_ids = null
        if (filterUsers !== "" && filterUsers !== undefined && filterUsers !== null) {
            setSelectedOptions([])
        }
        else {
            includeduser_ids = getexcludeuserIds(selectedOptions)
        }
        const reqData = {
            params: {
                "start_date": dayjs(valueStart).format('YYYY-MM-DD'), //dayjs(valueStart).year() + "-" + monthstart.toString() + "-" + dayjs(valueStart).,
                "end_date": dayjs(valueEnd).format('YYYY-MM-DD'), //dayjs(valueEnd).year() + "-" + monthend.toString() + "-" + dayjs(valueEnd).getDate(),               
                "user_ids": includeduser_ids === null ? "" : includeduser_ids,
                "filter_users": filterUsers
            }
        }

        if (buttonActionName === "totalcount") {
            datacollectionssteps(reqData).then(res => {
                setloading(false)
                if (res.status === 200 && res.data !== undefined && res.data !== null) {

                    settotalCountData(prevState => ({
                        ...prevState,
                        nextstepstudentcount: res.data.nextstepstudentcount
                        , withoutcheckmyworkcorrectanscount: res.data.withoutcheckmyworkcorrectanscount
                        , totalcheckmyworkcount: res.data.totalcheckmyworkcount
                        , successfullcorrectnodecheckmywork: res.data.successfullcorrectnodecheckmywork
                        , unsuccessfullcorrectnodecheckmywork: res.data.unsuccessfullcorrectnodecheckmywork
                        , successfullycorrectafteroneattepmt: res.data.successfullycorrectafteroneattepmt
                    }));

                    setshowTotalCountDiv(true)
                    setActiontable(false)
                    setEquationtable(false)
                    setFeedbacktable(false)
                    setNewuserdatatable(false)
                    setShowVisitorSummary(false)
                }
            }).catch(err => {
                setloading(false)
                console.error(err.message);
            })
        }
        else if (buttonActionName === "useraction") {
            datacollection_UserActions(reqData).then(res => {
                setloading(false)
                if (res.status === 200) {
                    setshowTotalCountDiv(false)
                    setActiontable(true)
                    setEquationtable(false)
                    setFeedbacktable(false)
                    setNewuserdatatable(false)
                    setShowVisitorSummary(false)
                    if (!isNil(res.data.data) && res.data.data.length > 0) {
                        setactionData(res.data.data)
                        settotalPages(Math.ceil(res.data.data.length / rowsPerPage))

                    }
                    else {
                        setactionData([])
                    }

                    let csvdata = [["Id", "Name", "Email", "Challenge", "Action", "Description", "Result", "Date"]]
                    if (!isNil(res.data.data) && res.data.data.length > 0) {
                        res.data.data.map((student) => {
                            csvdata.push([student.user_id, student.studentName, student.studentEmail, student.challengeName, student.Action, student.description, student.checkmyworkresult, student.created_ts])
                        })

                        setcsvuserActionData(csvdata)
                    }

                }

            }).catch(err => {
                console.error(err.message);
                setloading(false)
            })
        }

        else if (buttonActionName === "newusersdata") {
            datacollection_NewUserdata(reqData).then(res => {
                setloading(false)
                if (res.status === 200) {
                    setshowTotalCountDiv(false)
                    setActiontable(false)
                    setEquationtable(false)
                    setFeedbacktable(false)
                    setNewuserdatatable(true)
                    setShowVisitorSummary(false)
                    if (!isNil(res.data.data) && res.data.data.length > 0) {
                        setnewuserdata(res.data.data)
                        settotalPagesnewusersdata(Math.ceil(res.data.data.length / rowsPerPage))

                    }
                    else {
                        setnewuserdata([])
                    }

                    let csvdata = [["Id", "Name", "Email", "Role", "SignUpMethod", "Ref URL", "Device/OS", "Content Accessed", "Date"]]
                    if (!isNil(res.data.data) && res.data.data.length > 0) {
                        res.data.data.map((student) => {
                            csvdata.push([student.user_id, student.studentName, student.studentEmail, student.role, student.signupmethod, student.refurl, student.useragent, student.accessed_content, student.created_ts])
                        })

                        setcsvnewuserData(csvdata)
                    }

                }

            }).catch(err => {
                console.error(err.message);
                setloading(false)
            })
        }
        else if (buttonActionName === "userfeedback") {
            datacollection_UserFeedback(reqData).then(res => {
                setloading(false)
                if (res.status === 200) {
                    setshowTotalCountDiv(false)
                    setActiontable(false)
                    setEquationtable(false)
                    setFeedbacktable(true)
                    setNewuserdatatable(false)
                    setShowVisitorSummary(false)
                    if (!isNil(res.data.data) && res.data.data.length > 0) {
                        setfeedbackData(res.data.data)
                        settotalPagesfeedback(Math.ceil(res.data.data.length / rowsPerPage))

                    }
                    else {
                        setfeedbackData([])
                    }

                    let csvdata = [["Id", "Name", "Email", "User Feedback", "Rating", "Date"]]
                    if (!isNil(res.data.data) && res.data.data.length > 0) {
                        res.data.data.map((student) => {
                            csvdata.push([student.user_id, student.studentName, student.studentEmail, student.userfeedback, student.rating, student.created_ts])
                        })

                        setcsvuserFeedbackData(csvdata)
                    }

                }

            }).catch(err => {
                console.error(err.message);
                setloading(false)
            })
        }
        else if (buttonActionName === "userequation") {
            datacollection_UserEquations(reqData).then(res => {
                setloading(false)
                if (res.status === 200) {
                    setshowTotalCountDiv(false)
                    setActiontable(false)
                    setEquationtable(true)
                    setFeedbacktable(false)
                    setNewuserdatatable(false)
                    setShowVisitorSummary(false)
                    if (!isNil(res.data.data) && res.data.data.length > 0) {
                        setequationData(res.data.data)
                        settotalPagesEq(Math.ceil(res.data.data.length / rowsPerPageEq))
                    }
                    else {
                        setequationData([])
                    }

                    let csvdata = [["Id", "Name", "Email", "Challenge", "Input Data", "Output Data", "Latex", "Check Equation", "Date"]]
                    if (!isNil(res.data.data) && res.data.data.length > 0) {
                        res.data.data.map((student) => {
                            csvdata.push([student.user_id, student.studentName, student.studentEmail, student.challengeName, student.inputdata, student.outputdata, student.userinputlatex, student.CheckEquationsOutput, student.created_ts])
                        })

                        setcsvuserEquationData(csvdata)
                    }
                }

            }).catch(err => {
                console.error(err.message);
                setloading(false)
            })
        }
        else if (buttonActionName === "visitorsummary") {
            datacollection_visitorsummary(reqData).then(res => {
                setloading(false)
                setshowTotalCountDiv(false)
                setActiontable(false)
                setEquationtable(false)
                setFeedbacktable(false)
                setNewuserdatatable(false)
                setShowVisitorSummary(true)
                if (res.data !== undefined && res.data !== null) {
                    setTotalVisitorCountData(prevState => ({
                        ...prevState,
                        totalvisitors: res.data.totalvisitors
                        , totaltryitout: res.data.totaltryitout
                        , totalsignup: res.data.totalsignup
                        , totalgooglesignup: res.data.totalgooglesignup

                    }));
                }

            }).catch(err => {
                console.error(err.message);
                setloading(false)
            })
        }
    }
    const getFormattedLatex = (inputLatex) => {
        return (
            <div>
                {inputLatex.map((item) => (
                    <div>
                        {/* <MathJax.Context
                            key={item}  // Add a unique key for each item
                            input='tex'
                            onError={(MathJax, error) => {
                                console.warn(error);
                                console.log("Encountered a MathJax error, re-attempting a typeset!");
                                MathJax.Hub.Queue(
                                    MathJax.Hub.Typeset()
                                );
                            }}
                            script="https://cdnjs.cloudflare.com/ajax/libs/mathjax/2.7.1/MathJax.js?config=TeX-MML-AM_CHTML">
                            <MathJax.Node>{item}</MathJax.Node>
                        </MathJax.Context> */}
                        <MathJaxRenderer math={"$$" + item + "$$"}></MathJaxRenderer>
                    </div>
                ))}
            </div>
        );
    };
    const opencaptureimage = (e, description) => {
        e.preventDefault();
        setShowImage(true)
        setImageString(description)
    }
    const closecaptureimage = () => {
        setShowImage(false)
    }
    const handlefilterusers = (e) => {
        setFilterUsers(e.target.value)
        // setSelectedOptions([])
    }
    return (
        <>
            <Dialog
                fullWidth={true}
                open={showImage}
                onClose={closecaptureimage}
                maxWidth={maxWidth}>
                <DialogTitle sx={{ m: 0, p: 2 }} id="customized-dialog-title">
                    Equation Captured Image
                </DialogTitle>
                <IconButton
                    aria-label="close"
                    onClick={closecaptureimage}
                    sx={{
                        position: 'absolute',
                        right: 8,
                        top: 8,
                        color: (theme) => theme.palette.grey[500],
                    }}>
                    <CloseIcon />
                </IconButton>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        <img
                            style={{ height: "350px" }}
                            src={imageString}
                            alt="no image"
                        />
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <button
                        onClick={closecaptureimage}>
                        Ok
                    </button>
                </DialogActions>
            </Dialog>
            <Container className="datacollection_container">
                <Row className='teacher-ui'>
                    <h3 className='title my-3'>Data Collection</h3>
                    <div>
                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                            <Stack direction="row"
                                spacing={3}>
                                <Typography sx={{ paddingTop: "15px", marginRight: "17px" }}>Start Date</Typography>
                                <DatePicker
                                    className={classes.datepickerc}
                                    value={valueStart}
                                    onChange={handleChangestart}
                                    renderInput={(params) => <TextField {...params} />}
                                />
                                <Typography sx={{ paddingTop: "15px" }}>End Date</Typography>
                                <DatePicker
                                    className={classes.datepickerc}
                                    value={valueEnd}
                                    onChange={handleChangeend}
                                    renderInput={(params) => <TextField onKeyDown={onKeyDown} {...params} error={!!enderror}
                                        helperText={enderror} />}
                                />
                            </Stack>
                        </LocalizationProvider>
                        <Stack direction="row" mt={1}
                            spacing={1}>
                            <Typography sx={{ paddingTop: "15px" }}>Select Students</Typography>
                            <div style={{ width: "550px" }}>
                                <MultiSelectAll placehodertext="Select Students"
                                    options={studentList} alllables="All Students"
                                    value={selectedOptions} onChange={handleselectusers}></MultiSelectAll>
                            </div>
                        </Stack>
                        <Stack direction="row"
                            spacing={4}>
                            <Typography sx={{ paddingTop: "15px", visibility:"hidden" }} >OR condition</Typography>
                            <div id="or">OR</div>
                        </Stack>
                        <Stack direction="row" 
                            spacing={4}>
                            <Typography sx={{ paddingTop: "15px" }}>Filter Users</Typography>
                            <div style={{ width: "550px" }}>
                                <TextField size="small" className={classes.textfieldc} id="outlined-basic" variant="outlined" onChange={(e) => handlefilterusers(e)} />
                            </div>
                        </Stack>
                        <Stack direction="row" mt={1}
                            spacing={1}>
                            <Typography sx={{ paddingTop: "15px", marginRight: "100px" }}>&nbsp;</Typography>
                            <Button
                                sx={{ textTransform: "none", backgroundColor: "#4252AF", height: "35px", marginTop: "10px !important", fontSize: "12px" }}
                                variant="contained" onClick={(e) => handlefetchdata(e, "totalcount")}>Display User Metrics</Button>
                            <Button
                                sx={{ textTransform: "none", backgroundColor: "#4252AF", height: "35px", marginTop: "10px !important", fontSize: "12px" }}
                                variant="contained" onClick={(e) => handlefetchdata(e, "useraction")}>Get User Actions</Button>
                            <Button
                                sx={{ textTransform: "none", backgroundColor: "#4252AF", height: "35px", marginTop: "10px !important", fontSize: "12px" }}
                                variant="contained" onClick={(e) => handlefetchdata(e, "userequation")}>Get User Equations</Button>
                            <Button
                                sx={{ textTransform: "none", backgroundColor: "#4252AF", height: "35px", marginTop: "10px !important", fontSize: "12px" }}
                                variant="contained" onClick={(e) => handlefetchdata(e, "userfeedback")}>Get User Feedback</Button>
                            <Button
                                sx={{ textTransform: "none", backgroundColor: "#4252AF", height: "35px", marginTop: "10px !important", fontSize: "12px" }}
                                variant="contained" onClick={(e) => handlefetchdata(e, "newusersdata")}>Get New Users Data</Button>
                            {/* <Button
                                sx={{ textTransform: "none", backgroundColor: "#4252AF", height: "35px", marginTop: "10px !important", fontSize: "12px" }}
                                variant="contained" onClick={(e) => handlefetchdata(e, "visitorsummary")}>Visitor Data</Button> */}
                        </Stack>

                    </div>
                </Row>
                {
                    showTotalCountDiv ? (
                        <div className="container bg-white-with-shadow my-3 my-md-5">
                            <Row className="rowclasstotalcount">
                                <Col sm="12">
                                    <h3 className='title my-3'>User Metrics</h3>
                                </Col>
                            </Row>
                            <Row className="rowclasstotalcount">
                                <Col sm="4">
                                    <span className="totalcountheadingheader">Description</span>
                                </Col>
                                <Col sm="4">
                                    <span className="totalcountheadingheader">Count</span>
                                </Col>
                            </Row>
                            <Row className="rowclasstotalcount">
                                <Col sm="4">
                                    <Typography>
                                        <span className="totalcountheading">Next Step Count</span>
                                    </Typography>
                                </Col>
                                <Col sm="4">
                                    <Typography>
                                        {totalCountData.nextstepstudentcount}
                                    </Typography>
                                </Col>
                            </Row>
                            <Row className="rowclasstotalcount">
                                <Col sm="4">
                                    <Typography>
                                        <span className="totalcountheading">Without Checkmy work Correct Answers Count</span>
                                    </Typography>
                                </Col>
                                <Col sm="4">
                                    <Typography>
                                        {totalCountData.withoutcheckmyworkcorrectanscount}
                                    </Typography>
                                </Col>
                            </Row>
                            <Row className="rowclasstotalcount">
                                <Col sm="4">
                                    <Typography>
                                        <span className="totalcountheading">Check My Work Count</span>
                                    </Typography>
                                </Col>
                                <Col sm="4">
                                    <Typography>
                                        {totalCountData.totalcheckmyworkcount}
                                    </Typography>
                                </Col>
                            </Row>
                            <Row className="rowclasstotalcount">
                                <Col sm="4">
                                    <Typography>
                                        <span className="totalcountheading">Successfully Correct After One Attempt</span>
                                    </Typography>
                                </Col>
                                <Col sm="4">
                                    <Typography>
                                        {totalCountData.successfullycorrectafteroneattepmt}
                                    </Typography>
                                </Col>
                            </Row>
                            <Row className="rowclasstotalcount">
                                <Col sm="4">
                                    <Typography>
                                        <span className="totalcountheading">Successfully Correct More Than One Attempt</span>
                                    </Typography>
                                </Col>
                                <Col sm="4">
                                    <Typography>
                                        {totalCountData.successfullcorrectnodecheckmywork}
                                    </Typography>
                                </Col>
                            </Row>
                            <Row className="rowclasstotalcount">
                                <Col sm="4">
                                    <Typography>
                                        <span className="totalcountheading">Unsuccessfully Correct More Than One Attempt</span>
                                    </Typography>
                                </Col>
                                <Col sm="4">
                                    <Typography>
                                        {totalCountData.unsuccessfullcorrectnodecheckmywork}
                                    </Typography>
                                </Col>
                            </Row>
                        </div>
                    ) : null
                }
                {
                    showActiontable ? (
                        <>
                            <Row className="rowclasstotalcount">
                                <Col sm="12">
                                    <h3 className='title my-3'>User Actions Data</h3>
                                </Col>
                            </Row>
                            <Row className=' csvBtnContainer'>
                                <Col className='col-12 text-right'>
                                    <CSVLink data={csvuserActionData} className='exportCSVbtn exportStudent' filename={dayjs().format('YYYY-MM-DD hh:mm:ss') + "_useractions.csv"}>Export User Action Data<img src={DownloadIcon}></img></CSVLink>
                                </Col>
                            </Row>
                            <Row style={{ marginTop: "10px", marginBottom: "50px" }}>
                                <TableContainer component={Paper} sx={{ userSelect: "text" }}>
                                    <Table aria-label="collapsible table">
                                        <TableHead>
                                            <TableRow>
                                                <StyledTableCell width="10%">Name</StyledTableCell>
                                                <StyledTableCell width="10%">Email</StyledTableCell>
                                                <StyledTableCell width="10%">Challenge</StyledTableCell>
                                                <StyledTableCell width="10%">Action</StyledTableCell>
                                                <StyledTableCell width="20%">Description</StyledTableCell>
                                                <StyledTableCell width="20%">Result</StyledTableCell>
                                                <StyledTableCell width="10%">Date</StyledTableCell>
                                            </TableRow>
                                        </TableHead>
                                        <TableBody>
                                            {
                                                actionData !== undefined && actionData !== null && actionData.length > 0 ? (
                                                    actionData.slice(startPage * rowsPerPage, startPage * rowsPerPage + rowsPerPage).map((row) => {
                                                        return (
                                                            <StyledTableRow sx={{ '& > *': { borderBottom: 'unset' } }} width="10%">
                                                                <StyledTableCellbody component="th" scope="row">
                                                                    <div style={{ display: "flex", justifyContent: "flex-start", alignItems: "center" }}>
                                                                        <Avatar
                                                                            className={classes.avatar}
                                                                            src={imgURL + row.profile_pic}>
                                                                        </Avatar>
                                                                        <div className='mt-2'>{row.studentName}</div>
                                                                    </div>
                                                                </StyledTableCellbody>
                                                                <StyledTableCellbody width="10%">
                                                                    {row.studentEmail}
                                                                </StyledTableCellbody>
                                                                <StyledTableCellbody width="10%">
                                                                    {row.challengeName}
                                                                </StyledTableCellbody>
                                                                <StyledTableCellbody width="10%">
                                                                    {row.Action}

                                                                </StyledTableCellbody>
                                                                <StyledTableCellbody width="20%">
                                                                    {
                                                                        row.Action === "equationimage" ?
                                                                            (
                                                                                <>
                                                                                    <div className="upgrateSubscriptionBox"
                                                                                        onClick={(e) => opencaptureimage(e, row.description)}>Preview</div>
                                                                                </>
                                                                            ) :
                                                                            (
                                                                                <span>{row.description}</span>
                                                                            )
                                                                    }

                                                                </StyledTableCellbody>
                                                                <StyledTableCellbody width="20%">
                                                                    {row.checkmyworkresult}
                                                                </StyledTableCellbody>
                                                                <StyledTableCellbody width="10%">
                                                                    {row.created_ts}
                                                                </StyledTableCellbody>

                                                            </StyledTableRow>
                                                        )
                                                    }
                                                    )
                                                ) : (
                                                    <StyledTableRow>
                                                        <StyledTableCellbody width="10%"></StyledTableCellbody>
                                                        <StyledTableCellbody width="10%"></StyledTableCellbody>
                                                        <StyledTableCellbody width="10%"></StyledTableCellbody>
                                                        <StyledTableCellbody width="10%"></StyledTableCellbody>
                                                        <StyledTableCellbody width="20%">No Actions Found!</StyledTableCellbody>
                                                        <StyledTableCellbody width="20%"></StyledTableCellbody>
                                                        <StyledTableCellbody width="10%"></StyledTableCellbody>
                                                    </StyledTableRow>
                                                )
                                            }
                                        </TableBody>
                                    </Table>
                                </TableContainer>
                                <Pagination
                                    sx={{
                                        "& .MuiTablePagination-selectLabel": {
                                            marginBottom: "0px !important"
                                        },
                                        "& .MuiTablePagination-displayedRows": {
                                            marginBottom: "0px !important"
                                        }
                                    }}
                                    variant="outlined"
                                    shape="rounded"
                                    color="primary"
                                    count={totalPages}
                                    page={page}
                                    onChange={handleChangePage}
                                />
                            </Row>
                        </>
                    ) : null
                }



                {
                    showEquationtable ? (
                        <>
                            <Row className="rowclasstotalcount">
                                <Col sm="12">
                                    <h3 className='title my-3'>User Equations Data</h3>
                                </Col>
                            </Row>
                            <Row className=' csvBtnContainer'>
                                <Col className='col-12 text-right'>
                                    <CSVLink data={csvuserEquationData} className='exportCSVbtn exportStudent' filename={dayjs().format('YYYY-MM-DD hh:hh:ss') + "_userequations.csv"}>Export User Equations Data<img src={DownloadIcon}></img></CSVLink>
                                </Col>
                            </Row>
                            <Row style={{ marginTop: "10px", marginBottom: "50px" }}>
                                <TableContainer component={Paper} sx={{ userSelect: "text" }}>
                                    <Table aria-label="collapsible table">
                                        <TableHead>
                                            <TableRow>
                                                <StyledTableCell width="10%">Name</StyledTableCell>
                                                <StyledTableCell width="10%">Email</StyledTableCell>
                                                <StyledTableCell width="10%">Challenge</StyledTableCell>
                                                <StyledTableCell width="10%">Input Data</StyledTableCell>
                                                <StyledTableCell width="15%">Output Data</StyledTableCell>
                                                <StyledTableCell width="10%">Latex</StyledTableCell>
                                                <StyledTableCell width="15%">Check Equation</StyledTableCell>
                                                <StyledTableCell width="10%">Date</StyledTableCell>
                                            </TableRow>
                                        </TableHead>
                                        <TableBody>
                                            {
                                                equationData !== undefined && equationData !== null && equationData.length > 0 ? (
                                                    equationData.slice(startPageEq * rowsPerPageEq, startPageEq * rowsPerPageEq + rowsPerPageEq).map((row) => {
                                                        return (
                                                            <StyledTableRow sx={{ '& > *': { borderBottom: 'unset' } }} width="10%">
                                                                <StyledTableCellbody component="th" scope="row">
                                                                    <div style={{ display: "flex", justifyContent: "flex-start", alignItems: "center" }}>
                                                                        <Avatar
                                                                            className={classes.avatar}
                                                                            src={imgURL + row.profile_pic}>
                                                                        </Avatar>
                                                                        <div className='mt-2'>{row.studentName}</div>
                                                                    </div>
                                                                </StyledTableCellbody>
                                                                <StyledTableCellbody width="10%">
                                                                    {row.studentEmail}
                                                                </StyledTableCellbody>
                                                                <StyledTableCellbody width="10%">
                                                                    {row.challengeName}
                                                                </StyledTableCellbody>
                                                                <StyledTableCellbody width="10%">
                                                                    {row.inputdata}
                                                                </StyledTableCellbody>
                                                                <StyledTableCellbody width="15%">
                                                                    {row.outputdata}
                                                                </StyledTableCellbody>
                                                                <StyledTableCellbody width="10%">
                                                                    {getFormattedLatex(row.userinputlatex)}
                                                                </StyledTableCellbody>
                                                                <StyledTableCellbody width="15%">
                                                                    {row.CheckEquationsOutput}
                                                                </StyledTableCellbody>
                                                                <StyledTableCellbody width="10%">
                                                                    {row.created_ts}
                                                                </StyledTableCellbody>

                                                            </StyledTableRow>
                                                        )
                                                    }
                                                    )
                                                ) : (<StyledTableRow>
                                                    <StyledTableCellbody width="10%"></StyledTableCellbody>
                                                    <StyledTableCellbody width="10%"></StyledTableCellbody>
                                                    <StyledTableCellbody width="10%"></StyledTableCellbody>
                                                    <StyledTableCellbody width="10%"></StyledTableCellbody>
                                                    <StyledTableCellbody width="15%">No Equations Found!</StyledTableCellbody>
                                                    <StyledTableCellbody width="10%"></StyledTableCellbody>
                                                    <StyledTableCellbody width="15%"></StyledTableCellbody>
                                                    <StyledTableCellbody width="10%"></StyledTableCellbody>
                                                </StyledTableRow>)
                                            }
                                        </TableBody>
                                    </Table>
                                </TableContainer>
                                <Pagination
                                    sx={{
                                        "& .MuiTablePagination-selectLabel": {
                                            marginBottom: "0px !important"
                                        },
                                        "& .MuiTablePagination-displayedRows": {
                                            marginBottom: "0px !important"
                                        }
                                    }}
                                    variant="outlined"
                                    shape="rounded"
                                    color="primary"
                                    count={totalPagesEq}
                                    page={pageEq}
                                    onChange={handleChangePageEq}
                                />
                            </Row>
                        </>
                    ) : null
                }

                {
                    showFeedbacktable ? (
                        <>
                            <Row className="rowclasstotalcount">
                                <Col sm="12">
                                    <h3 className='title my-3'>User Feedback Data</h3>
                                </Col>
                            </Row>
                            <Row className=' csvBtnContainer'>
                                <Col className='col-12 text-right'>
                                    <CSVLink data={csvuserFeedbackData} className='exportCSVbtn exportStudent' filename={dayjs().format('YYYY-MM-DD hh:hh:ss') + "_userfeedback.csv"}>Export User Feedback<img src={DownloadIcon}></img></CSVLink>
                                </Col>
                            </Row>
                            <Row style={{ marginTop: "10px", marginBottom: "50px" }}>
                                <TableContainer component={Paper} sx={{ userSelect: "text" }}>
                                    <Table aria-label="collapsible table">
                                        <TableHead>
                                            <TableRow>
                                                <StyledTableCell width="10%">Name</StyledTableCell>
                                                <StyledTableCell width="10%">Email</StyledTableCell>
                                                <StyledTableCell width="10%">Feedback</StyledTableCell>
                                                <StyledTableCell width="15%">Rating</StyledTableCell>
                                                <StyledTableCell width="10%">Date</StyledTableCell>
                                            </TableRow>
                                        </TableHead>
                                        <TableBody>
                                            {
                                                feedbackData !== undefined && feedbackData !== null && feedbackData.length > 0 ? (
                                                    feedbackData.slice(startPagefeedback * rowsPerPagefeedback, startPagefeedback * rowsPerPagefeedback + rowsPerPagefeedback).map((row) => {
                                                        return (
                                                            <StyledTableRow sx={{ '& > *': { borderBottom: 'unset' } }} width="10%">
                                                                <StyledTableCellbody component="th" scope="row">
                                                                    <div style={{ display: "flex", justifyContent: "flex-start", alignItems: "center" }}>
                                                                        <Avatar
                                                                            className={classes.avatar}
                                                                            src={imgURL + row.profile_pic}>
                                                                        </Avatar>
                                                                        <div className='mt-2'>{row.studentName}</div>
                                                                    </div>
                                                                </StyledTableCellbody>
                                                                <StyledTableCellbody width="10%">
                                                                    {row.studentEmail}
                                                                </StyledTableCellbody>
                                                                <StyledTableCellbody width="10%">
                                                                    {row.userfeedback}
                                                                </StyledTableCellbody>
                                                                <StyledTableCellbody width="15%">
                                                                    {row.rating}
                                                                </StyledTableCellbody>
                                                                <StyledTableCellbody width="10%">
                                                                    {row.created_ts}
                                                                </StyledTableCellbody>

                                                            </StyledTableRow>
                                                        )
                                                    }
                                                    )
                                                ) : (<StyledTableRow>
                                                    <StyledTableCellbody width="10%"></StyledTableCellbody>
                                                    <StyledTableCellbody width="10%"></StyledTableCellbody>
                                                    <StyledTableCellbody width="15%">No Feedback Found!</StyledTableCellbody>
                                                    <StyledTableCellbody width="10%"></StyledTableCellbody>
                                                    <StyledTableCellbody width="15%"></StyledTableCellbody>
                                                </StyledTableRow>)
                                            }
                                        </TableBody>
                                    </Table>
                                </TableContainer>
                                <Pagination
                                    sx={{
                                        "& .MuiTablePagination-selectLabel": {
                                            marginBottom: "0px !important"
                                        },
                                        "& .MuiTablePagination-displayedRows": {
                                            marginBottom: "0px !important"
                                        }
                                    }}
                                    variant="outlined"
                                    shape="rounded"
                                    color="primary"
                                    count={totalPagesfeedback}
                                    page={pagefeedback}
                                    onChange={handleChangePagefeedback}
                                />
                            </Row>
                        </>
                    ) : null
                }

                {
                    showNewuserdatatable ? (
                        <>
                            <Row className="rowclasstotalcount">
                                <Col sm="12">
                                    <h3 className='title my-3'>New Users Data</h3>
                                </Col>
                            </Row>
                            <Row className=' csvBtnContainer'>
                                <Col className='col-12 text-right'>
                                    <CSVLink data={csvnewuserData} className='exportCSVbtn exportStudent' filename={dayjs().format('YYYY-MM-DD hh:hh:ss') + "_newusersdata.csv"}>Export New User Data<img src={DownloadIcon}></img></CSVLink>
                                </Col>
                            </Row>
                            <Row style={{ marginTop: "10px", marginBottom: "50px" }}>
                                <TableContainer component={Paper} sx={{ userSelect: "text" }}>
                                    <Table aria-label="collapsible table">
                                        <TableHead>
                                            <TableRow>
                                                <StyledTableCell width="10%">Id</StyledTableCell>
                                                <StyledTableCell width="10%">Name</StyledTableCell>
                                                <StyledTableCell width="10%">Email</StyledTableCell>
                                                <StyledTableCell width="10%">Role</StyledTableCell>
                                                <StyledTableCell width="15%">SignUP Method</StyledTableCell>
                                                <StyledTableCell width="15%">Ref URL</StyledTableCell>
                                                <StyledTableCell width="15%">Device/OS</StyledTableCell>
                                                <StyledTableCell width="15%">Content Accessed</StyledTableCell>
                                                <StyledTableCell width="10%">Date</StyledTableCell>
                                            </TableRow>
                                        </TableHead>
                                        <TableBody>
                                            {
                                                newuserdata !== undefined && newuserdata !== null && newuserdata.length > 0 ? (
                                                    newuserdata.slice(startPagenewusersdata * rowsPerPagenewusersdata, startPagenewusersdata * rowsPerPagenewusersdata + rowsPerPagenewusersdata).map((row) => {
                                                        return (
                                                            <StyledTableRow sx={{ '& > *': { borderBottom: 'unset' } }} width="10%">

                                                                <StyledTableCellbody width="5%">
                                                                    {row.user_id}
                                                                </StyledTableCellbody>
                                                                <StyledTableCellbody width="10%">
                                                                    {row.studentName}
                                                                </StyledTableCellbody>
                                                                <StyledTableCellbody width="10%">
                                                                    {row.studentEmail}
                                                                </StyledTableCellbody>
                                                                <StyledTableCellbody width="5%">
                                                                    {row.role}
                                                                </StyledTableCellbody>
                                                                <StyledTableCellbody width="10%">
                                                                    {row.signupmethod}
                                                                </StyledTableCellbody>
                                                                <StyledTableCellbody width="15%">
                                                                    {row.refurl}
                                                                </StyledTableCellbody>
                                                                <StyledTableCellbody width="10%">
                                                                    {row.useragent}
                                                                </StyledTableCellbody>
                                                                <StyledTableCellbody width="15%">
                                                                    {row.accessed_content}
                                                                </StyledTableCellbody>
                                                                <StyledTableCellbody width="15%">
                                                                    {row.created_ts}
                                                                </StyledTableCellbody>

                                                            </StyledTableRow>
                                                        )
                                                    }
                                                    )
                                                ) : (<StyledTableRow>
                                                    <StyledTableCellbody width="10%"></StyledTableCellbody>
                                                    <StyledTableCellbody width="10%"></StyledTableCellbody>
                                                    <StyledTableCellbody width="15%">No New Users Found!</StyledTableCellbody>
                                                    <StyledTableCellbody width="10%"></StyledTableCellbody>
                                                    <StyledTableCellbody width="15%"></StyledTableCellbody>
                                                </StyledTableRow>)
                                            }
                                        </TableBody>
                                    </Table>
                                </TableContainer>
                                <Pagination
                                    sx={{
                                        "& .MuiTablePagination-selectLabel": {
                                            marginBottom: "0px !important"
                                        },
                                        "& .MuiTablePagination-displayedRows": {
                                            marginBottom: "0px !important"
                                        }
                                    }}
                                    variant="outlined"
                                    shape="rounded"
                                    color="primary"
                                    count={totalPagesnewusersdata}
                                    page={pagenewusersdata}
                                    onChange={handleChangePagenewusersdata}
                                />
                            </Row>
                        </>
                    ) : null
                }
                {
                    showVisitorSummary ? (
                        <>
                            <div className="container bg-white-with-shadow my-3 my-md-5">
                                <Row className="rowclasstotalcount">
                                    <Col sm="12">
                                        <h3 className='title my-3'>Visitor Data</h3>
                                    </Col>
                                </Row>
                                <Row className="rowclasstotalcount">
                                    <Col sm="4">
                                        <span className="totalcountheadingheader">Description</span>
                                    </Col>
                                    <Col sm="4">
                                        <span className="totalcountheadingheader">Count</span>
                                    </Col>
                                </Row>
                                <Row className="rowclasstotalcount">
                                    <Col sm="4">
                                        <Typography>
                                            <span className="totalcountheading">Total Visitors</span>
                                        </Typography>
                                    </Col>
                                    <Col sm="4">
                                        <Typography>
                                            {totalVisitorCountData.totalvisitors}
                                        </Typography>
                                    </Col>
                                </Row>
                                <Row className="rowclasstotalcount">
                                    <Col sm="4">
                                        <Typography>
                                            <span className="totalcountheading">Total Try It Out</span>
                                        </Typography>
                                    </Col>
                                    <Col sm="4">
                                        <Typography>
                                            {totalVisitorCountData.totaltryitout}
                                        </Typography>
                                    </Col>
                                </Row>
                                <Row className="rowclasstotalcount">
                                    <Col sm="4">
                                        <Typography>
                                            <span className="totalcountheading">Total Signup</span>
                                        </Typography>
                                    </Col>
                                    <Col sm="4">
                                        <Typography>
                                            {totalVisitorCountData.totalsignup}
                                        </Typography>
                                    </Col>
                                </Row>
                                <Row className="rowclasstotalcount">
                                    <Col sm="4">
                                        <Typography>
                                            <span className="totalcountheading">Total Google Signup</span>
                                        </Typography>
                                    </Col>
                                    <Col sm="4">
                                        <Typography>
                                            {totalVisitorCountData.totalgooglesignup}
                                        </Typography>
                                    </Col>
                                </Row>
                            </div>
                        </>
                    ) : null
                }


            </Container >
            {
                loading && (
                    <CircularProgress
                        size={24}
                        sx={{
                            color: "#164b99",
                            position: 'absolute',
                            top: '50%',
                            left: '50%',
                            marginTop: '-12px',
                            marginLeft: '-12px',
                        }}
                    />
                )
            }
        </>
    );
};
export default withNamespaces()(DataCollections);