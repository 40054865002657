import React, { useState, useEffect } from "react";
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { makeStyles } from "@material-ui/core/styles";
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import GraphComponent from '../../container/Homepage/Graph/GraphComponent';
import { checkTextAnswer, saveModelAnswer, getTutorAnswer, deletetextanswer } from '../../common/API'
import { useParams } from 'react-router-dom';
import CircularProgress from '@mui/material/CircularProgress';
import Button from '../Common/Button/Button';

const useStyles = makeStyles((theme) => ({
  root: {
    "& > *": {
      margin: theme.spacing(3),
      width: "25ch"
    }
  },
  summarycss: {
    display: "flex !important",
    justifyContent: "space-between !important",
  }

}));


const TextFlow = (props) => {
  const params = useParams();
  const [opendeletemodal, setOpenDeleteModal] = useState(false)
  const [showaddform, setshowaddform] = useState(false)
  const [answervalue, setanswervalue] = useState("")
  const [isloading, setisloading] = useState(false)
  const [nodes, setNodes] = useState([])
  const [edges, setEdges] = useState([])
  const [answerEvaluation, setanswerEvaluation] = useState("")
  const [answerdata, setanswerdata] = useState([])
  const [modalid, setmodalid] = useState(null)
  const [savebtndisabled, setsavebtndisabled] = useState(true)
  const [mode, setmode] = useState("Add")
  

  const classes = useStyles();

  useEffect(() => {
    const reqData = { params: { 'ChallengeId': params.challengeId } }
    setisloading(true)
    getTutorAnswer(reqData).then(res => {
      if (res.status === 200) {
        if (res.data.Answers !== undefined && res.data.Answers.length > 0) {
          setanswerdata(res.data.Answers)
          setisloading(false)
        }
        else {
          setisloading(false)
        }

      }
    })
  }, [])

  

  const handleentryformshow = () => {
    setshowaddform(true)
    setanswervalue("")
    setNodes([])
    setEdges([])
    setsavebtndisabled(true)
    setmode("Add")
  }
  const handleentryformhide = () => {
    setshowaddform(false)

  }

  const handlecloseanswermodal = () => {
    setOpenDeleteModal(false)
  }
  const handleopenanswermodal = (id) => {
    setOpenDeleteModal(true)
    setmodalid(id)
  }

  const handlecheckTextAnswer = () => {
    //debugger;
    const reqData = { params: { 'ChallengeId': params.challengeId, 'S_Answer': answervalue } }
    setisloading(true)
    checkTextAnswer(reqData).then(res => {
      if (res.status === 200) {
        if (res.data.Edge !== undefined && res.data.Edge.length > 0) {
          setEdges(res.data.Edge)
        }
        if (res.data.Node !== undefined && res.data.Node.length > 0) {
          setNodes(res.data.Node)
        }
        setanswerEvaluation(res.data.answerEvaluation)
        setisloading(false)
        setsavebtndisabled(false)
      }
    })
  }
  const handleanswertext = e => {
    setanswervalue(e.target.value)
  }
  const handlesaveModelAnswer = () => {
    setisloading(true)
    const reqData = {
      ChallengeId: params.challengeId
      , TutorAnswer: answervalue
      , AnswerEvaluation: answerEvaluation
      , Nodes: JSON.stringify(nodes)
      , Edges: JSON.stringify(edges)
      , ModelID: modalid
      , mode: mode
    }
    saveModelAnswer(reqData).then(res => {
      if (res.status === 200) {
        const reqData = { params: { 'ChallengeId': params.challengeId } }
        getTutorAnswer(reqData).then(res => {
          if (res.status === 200) {
            if (res.data.Answers !== undefined && res.data.Answers.length > 0) {
              setanswerdata(res.data.Answers)
              setisloading(false)

              setshowaddform(false)
              setanswervalue("")
              setNodes([])
              setEdges([])
            }
            else {
              setisloading(false)
            }

          }
        })
      }
    })
  }
  const handledeleteanswer = () => {
    setisloading(true)
    const reqData = {
      id: modalid
    }
    deletetextanswer(reqData).then(res => {

      if (res.status === 200) {
        setOpenDeleteModal(false)
        const reqData = { params: { 'ChallengeId': params.challengeId } }

        getTutorAnswer(reqData).then(res => {
          if (res.status === 200) {
            if (res.data.Answers !== undefined && res.data.Answers.length > 0) {
              setanswerdata(res.data.Answers)
              setisloading(false)
            }
            else {
              setshowaddform(false)
              setanswerdata([])
              setisloading(false)
            }

          }
        })
      }
    })
  }
  const handleditdata = (answers, Node, Edge, answerEvaluation, id) => {
    setshowaddform(true)
    setanswervalue(answers)
    setEdges(JSON.parse(Edge))
    setNodes(JSON.parse(Node))
    setanswerEvaluation(answerEvaluation)
    setsavebtndisabled(true)
    setmode("Edit")
    setmodalid(id)
  }
  return (
    <>
      <Dialog
        hideBackdrop={false}
        open={opendeletemodal}
        //onClose={handlecloseanswermodal()}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          Alert
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Are you sure you want to remove this answer?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <button className="dragLabel"
            onClick={handledeleteanswer}
          >
            Yes
          </button>
          <button className="dragLabelcancel leftmargin"
            onClick={handlecloseanswermodal}
          >
            No
          </button>
        </DialogActions>
      </Dialog>
      {
        showaddform ? (<div style={{ marginTop: "5px", textAlign: "right", cursor: "pointer" }}>
          <i class="fa fa-close" style={{ fontSize: "18px", boxShadow: "none", textDecoration: 'none', cursor: "pointer" }} onClick={handleentryformhide}></i>
        </div>) : null
      }

      <div style={{ display: showaddform ? "flex" : "none", justifyContent: "space-between" }} >
        <div style={{ width: "49%" }} className="answerform">
          <div  >
            <label className="labels">Answer</label> <br />
            <textarea
              cols="30"
              rows="10"
              required
              className="textareaanswer"
              onChange={handleanswertext}
              value={answervalue}
            ></textarea>
          </div>
          <div style={{ display: "flex", justifyContent: "space-between" }}>
            <Button className="dragLabel" clicked={handlecheckTextAnswer}>
              Evaluate
            </Button>
            {
              savebtndisabled ? (<Button
                className="dragLabel" disabled={savebtndisabled}
              //clicked={handlesaveModelAnswer}
              >
                Save
              </Button>) : (<Button className="dragLabel" clicked={handlesaveModelAnswer} >
                Save
              </Button>)
            }

          </div>
        </div>
        <div style={{ width: "49%" }} >
          <label className="labels">Graph View</label> <br />
          <div className="textprocessing" style={{ position: "relative", overflow: "hidden", height: "340px", backgroundColor: "#BFD4FF" }}>
            {
              nodes.length > 0 ? (<GraphComponent isPT={false} componentId="evaluateGraphChart" chartType="basic" nodes={nodes} edges={edges}></GraphComponent>) : null
            }
          </div>
        </div>
      </div>
      <div className="solutiongraphtest" style={{ marginTop: showaddform ? "20px" : "20px", height: showaddform ? "350px" : "600px", overflowY: "auto" }} >
        <div style={{ marginBottom: "10px", textAlign: "right" }}>
          {
            !showaddform ? (<button className="dragLabel" onClick={handleentryformshow}>
              Add Answer
            </button>) : null
          }
        </div>

        {
          answerdata.length > 0 ? (
            answerdata.map((item, index) => {

              return (
                <Accordion
                  style={{ marginTop: "5px" }}
                //expanded={expand}
                >
                  <AccordionSummary
                    // {...accordionProps}
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel1a-content"
                    id="panel1a-header"
                    className={classes.summarycss}

                  >
                    <div>
                      <Typography>Answer -{parseInt(index + 1)}</Typography>
                    </div>
                  </AccordionSummary>
                  <AccordionDetails>
                    <Typography>
                      <div style={{ whiteSpace: "pre-wrap" }} dangerouslySetInnerHTML={{ __html: item.Answer }}></div>
                    </Typography>
                    <div style={{ paddingRight: "30px", paddingTop: "30px" }}>
                      <i class="fa fa-edit" style={{ fontSize: "18px", boxShadow: "none", textDecoration: 'none', cursor: "pointer" }}
                        onClick={() => handleditdata(item.Answer, item.Nodes, item.Edges, item.answerEvaluation, item.ModelID)}></i>
                      <i class="fa fa-trash" style={{ fontSize: "18px", boxShadow: "none", textDecoration: 'none', paddingLeft: "10px", cursor: "pointer" }}
                        aria-hidden="true" onClick={() => handleopenanswermodal(item.ModelID)}></i>
                    </div>
                  </AccordionDetails>
                </Accordion>

              )
            })

          ) : (<Accordion style={{ marginTop: "5px" }}>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel1a-content"
              id="panel1a-header"
              className={classes.summarycss}
            >
              <div>
                <Typography>No data found</Typography>
              </div>
            </AccordionSummary>

          </Accordion>)
        }


      </div >
      {isloading && (
        <CircularProgress
          size={24}
          sx={{
            color: "#fdbd17",
            position: 'absolute',
            top: '50%',
            left: '50%',
            marginTop: '-12px',
            marginLeft: '-12px',
          }}
        />
      )
      }
    </>
  );
};
export default TextFlow;