import React, { Component } from "react";
import "./SessionSynopsis.scss";
import synopsisImage from "../../../assets/images/synopsis_image.svg";
import synopsisGraph from "../../../assets/images/synopsis_graph.jpg";
import questionImage from "../../../assets/images/eqQuestion_1.png";
import eq1 from "../../../assets/images/synopsis_eq1.svg";
import Draggable from "react-draggable";
import Button from "../../Common/Button/Button";
import ReactDOMServer from "react-dom/server";
import { toast } from "react-toastify";
import HtmlContent from "../../CurriculumContent/MainContent/HtmlContent/HtmlContent";
import HighlightText from "../../CurriculumContent/HighlightText/HighlightText";

class SessionSynopsis extends Component {
  state = {
    isFinalStage: false,
    finalSynopsis: {
      question: "",
      answer: "",
      remedial: "",
    },
    // Highlighter
    showHighlighter: false,
    highlightedText: "",
    editHighlight: {},
    highlightedObject: null,
    highlightedObjectList: [
      {
        selectionStart: 0,
        selectionEnd: 19,
        focusNodeText: "Diagnostic Question",
        highlightedText: "Diagnostic Question",
        elementClass: "1",
        highlighterColor: "yellow",
        ignore: true,
        note: "",
      },
      {
        selectionStart: 8,
        selectionEnd: 77,
        focusNodeText:
          " Tutor: What is distance between the two centers of gravity in this diagram?​",
        highlightedText:
          "What is distance between the two centers of gravity in this diagram?​",
        elementClass: "",
        highlighterColor: "yellow",
        note: "",
      },
      {
        selectionStart: 0,
        selectionEnd: 17,
        focusNodeText: "Diagnostic Answer",
        highlightedText: "Diagnostic Answer",
        elementClass: "2",
        highlighterColor: "grey",
        ignore: true,
        note: "",
      },
      {
        "selectionStart": 31,
        "selectionEnd": 32,
        "focusNodeText": " Student: R. Oh no, it’s small r. Are you saying that I should use that, instead of capital R?",
        "highlightedText": "r",
        "elementClass": "",
        "highlighterColor": "grey",
        "note": ""
      },
      {
        selectionStart: 0,
        selectionEnd: 13,
        focusNodeText: "Remedial Text",
        highlightedText: "Remedial Text",
        elementClass: "",
        highlighterColor: "green",
        ignore: true,
        note: "",
      },
      {
        selectionStart: 79,
        selectionEnd: 214,
        focusNodeText:
          " Tutor: Well, What do you think? What is the meaning of small ‘r’? Recall that Newton’s Law of Universal Gravitation states that the force is inversely proportional to square of the distance between the two masses.",
        highlightedText:
          "Newton’s Law of Universal Gravitation states that the force is inversely proportional to square of the distance between the two masses.",
        elementClass: "",
        highlighterColor: "green",
        note: "",
      },
      {
        selectionStart: 0,
        selectionEnd: 17,
        focusNodeText: "Remedial Resource",
        highlightedText: "Remedial Resource",
        elementClass: "4",
        highlighterColor: "green",
        ignore: true,
        note: "",
      },
      {
        selectionStart: 0,
        selectionEnd: 48,
        focusNodeText: "Newton’s Universal Law of Gravitation - OpenStax",
        highlightedText: "Newton’s Universal Law of Gravitation - OpenStax",
        elementClass: "6",
        highlighterColor: "green",
        ignore: true,
        note: "",
      },
      {
        selectionStart: 1,
        selectionEnd: 46,
        focusNodeText: " Think about what volume you should use here!?",
        highlightedText: "Think about what volume you should use here!?",
        elementClass: "",
        highlighterColor: "yellow",
        note: "",
      },
      {
        selectionStart: 0,
        selectionEnd: 19,
        focusNodeText: "Diagnostic Question",
        highlightedText: "Diagnostic Question",
        elementClass: "5",
        highlighterColor: "yellow",
        ignore: true,
        note: "",
      },
    ],
    highlighterPosition: {
      top: 0,
      left: 0,
    },
  };

  content = (
    <>
      <p>
        <span className="1 red">Diagnostic Question</span> Tutor: What is
        distance between the two centers of gravity in this diagram?
      </p>

      <img src={questionImage} alt="Question Image" />

      <p>
        <span className="2 red">Diagnostic Answer</span> Student: R. Oh no, it’s
        small r. Are you saying that I should use that, instead of capital R?
      </p>

      <p>
        <span className="3 red">Remedial Text</span> Tutor: Well, What do you
        think? What is the meaning of small ‘r’? Recall that Newton’s Law of
        Universal Gravitation states that the force is inversely proportional to
        square of the distance between the two masses.
      </p>

      <img src={eq1} alt="Equation" />

      <p className="mb-0">
        <span className="4 red">Remedial Resource</span> Teacher: Check out this
        resource.
      </p>

      <a
        className="6"
        href="https://openstax.org/books/college-physics/pages/6-5-newtons-universal-law-of-gravitation#:~:text=Stated%20in%20modern%20language%2C%20Newton's,of%20the%20distance%20between%20them."
        target="_blank"
      >
        Newton’s Universal Law of Gravitation - OpenStax
      </a>

      <p className="mt-2">
        Student: Got it! So I did the rest of my stuff fine, I just didn’t pay
        attention to small r versus big R. I know how to do this.
      </p>

      <p>
        Tutor: Great! I know you can do this. And remember to substitute for V.{" "}
        <span className="5 red">Diagnostic Question</span> Think about what
        volume you should use here!?
      </p>

      <p>Student: Yep, thanks! Bye!​</p>

      <p>Tutor: Keep it up! Bye!​</p>
    </>
  );

  onMouseUpHandler = (e) => {
    let isTouchEvent = false;
    if (e.nativeEvent instanceof TouchEvent) {
      isTouchEvent = true;
    }

    e.preventDefault();
    const selectionObj = window.getSelection && window.getSelection();
    const selection = selectionObj.toString();

    let getSelectionHtml = () => {
      var html = "";
      if (typeof window.getSelection != "undefined") {
        var sel = window.getSelection();
        if (sel.rangeCount) {
          var container = document.createElement("div");
          for (var i = 0, len = sel.rangeCount; i < len; ++i) {
            container.appendChild(sel.getRangeAt(i).cloneContents());
          }
          html = container.innerHTML;
        }
      } else if (typeof document.selection != "undefined") {
        if (document.selection.type == "Text") {
          html = document.selection.createRange().htmlText;
        }
      }
      return html;
    };

    if (selection !== "") {
      const htmlSelection = getSelectionHtml();
      console.log("htmlSelection: ", htmlSelection);
      console.log("selection: ", selection);

      if (selection !== htmlSelection) {
        toast(
          "Oops, the system doesn't support mixed content highlights as of now.",
          {
            type: toast.TYPE.WARNING,
            position: toast.POSITION.BOTTOM_RIGHT,
          }
        );
        return;
      }

      const anchorNode = selectionObj.anchorNode;
      const focusNode = selectionObj.focusNode;
      const anchorOffset = selectionObj.anchorOffset;
      const focusOffset = selectionObj.focusOffset;
      const position = anchorNode.compareDocumentPosition(focusNode);
      let forward = false;

      if (position === anchorNode.DOCUMENT_POSITION_FOLLOWING) {
        forward = true;
      } else if (position === 0) {
        forward = focusOffset - anchorOffset > 0;
      }

      let selectionStart = forward ? anchorOffset : focusOffset;

      if (forward) {
        if (
          anchorNode.parentNode.getAttribute("data-order") &&
          anchorNode.parentNode.getAttribute("data-order") === "middle"
        ) {
          selectionStart += this.state.selectionStart;
        }
        if (
          anchorNode.parentNode.getAttribute("data-order") &&
          anchorNode.parentNode.getAttribute("data-order") === "last"
        ) {
          selectionStart += this.state.selectionEnd;
        }
      } else {
        if (
          focusNode.parentNode.getAttribute("data-order") &&
          focusNode.parentNode.getAttribute("data-order") === "middle"
        ) {
          selectionStart += this.state.selectionStart;
        }
        if (
          focusNode.parentNode.getAttribute("data-order") &&
          focusNode.parentNode.getAttribute("data-order") === "last"
        ) {
          selectionStart += this.state.selectionEnd;
        }
      }

      const selectionEnd = selectionStart + selection.length;

      if (e.target.hasAttribute("class")) {
        var cList = e.target.classList;
        cList = cList[0];
      } else {
        var cList = e.target.id;
      }

      let rect = e.target.getBoundingClientRect();

      if (cList == null || cList === "hint" || cList === "highlight") {
        // Ignore selection if it is along with the hint.
        return;
      }

      let oRange = window.getSelection().getRangeAt(0); //get the text range
      let oRect = oRange.getBoundingClientRect();

      let topPosition = rect.top + 15; //e.pageY + 15
      let leftPosition = oRect.left - rect.left + 200;
      if (isTouchEvent) {
        var touch = e.changedTouches[0];
        topPosition = touch.pageY + 15;
        leftPosition = touch.pageX;
      }

      this.setState({
        showHighlighter: true,
        highlightedText: selection,
        highlighterPosition: {
          top: topPosition,
          left: leftPosition,
        },
        highlightedObject: {
          selectionStart,
          selectionEnd,
          focusNodeText: anchorNode.nodeValue,
          highlightedText: selection,
          elementClass: cList,
        },
      });
    } else if (this.state.showHighlighter && !isTouchEvent) {
      this.setState({
        showHighlighter: false,
        highlightedText: "",
        highlightedObject: null,
        editHighlight: {},
      });
    }
  };

  saveHighlight = (highlightObj, saveNote = false) => {
    if (highlightObj.highlighterColor) {
      if (
        this.state.highlightedObjectList.filter(
          (o) => o.focusNodeText === this.state.highlightedObject.focusNodeText
        ).length > 0
      ) {
        // When changing color of the same highlighting
        this.setState({
          highlightedObjectList: [
            ...this.changeListObject(
              this.state.highlightedObjectList,
              this.state.highlightedText,
              this.state.highlightedObject.selectionStart,
              {
                ...this.state.highlightedObject,
                highlighterColor: highlightObj.highlighterColor,
                note: highlightObj.note,
              }
            ),
          ],
          editHighlight:
            Object.keys(this.state.editHighlight).length !== 0
              ? {
                note: highlightObj.note,
                highlighterColor: highlightObj.highlighterColor,
              }
              : this.state.editHighlight,
        });
      } else {
        // When highlighting first time
        this.setState({
          highlightedObjectList: [
            ...this.state.highlightedObjectList,
            {
              ...this.state.highlightedObject,
              highlighterColor: highlightObj.highlighterColor,
              note: highlightObj.note,
            },
          ],
        });
      }
    } else {
      // When highlighting removed
      this.setState({
        highlightedObjectList: [
          ...this.changeListObject(
            this.state.highlightedObjectList,
            this.state.highlightedText,
            this.state.highlightedObject.selectionStart,
            null,
            true
          ),
        ],
        editHighlight:
          Object.keys(this.state.editHighlight).length !== 0
            ? {
              note: highlightObj.note,
              highlighterColor: highlightObj.highlighterColor,
            }
            : this.state.editHighlight,
      });
    }

    if (saveNote) {
      // Close highlighter when only inline note added.
      this.closeHighlighter();
    }
  };

  closeHighlighter = () => {
    this.setState({
      showHighlighter: false,
      highlightedText: "",
      highlightedObject: null,
      highlighterPosition: {
        top: 0,
        left: 0,
      },
      editHighlight: {},
    });
  };

  showHighlight = (event, range) => {
    let rect = event.target.getBoundingClientRect();
    let topPosition = rect.top + 15;
    let leftPosition = rect.left / 2 + 100;
    if (event.nativeEvent instanceof TouchEvent) {
      var touch = event.changedTouches[0];
      topPosition = touch.pageY + 15;
      leftPosition = touch.pageX;
    }

    this.state.highlightedObjectList.forEach((highlighObj) => {
      if (
        highlighObj.selectionStart === Number(range[0]) &&
        highlighObj.selectionEnd === Number(range[1])
      ) {
        this.setState({
          showHighlighter: true,
          highlightedText: highlighObj.highlightedText,
          highlighterPosition: {
            top: topPosition,
            left: leftPosition,
          },
          highlightedObject: highlighObj,
          editHighlight: {
            note: highlighObj.note,
            highlighterColor: highlighObj.highlighterColor,
          },
        });
      }
    });
  };

  deleteHighlight = (noteObj) => {
    this.setState({
      highlightedObjectList: [
        ...this.changeListObject(
          this.state.highlightedObjectList,
          this.state.highlightedText,
          this.state.highlightedObject.selectionStart,
          null,
          true
        ),
      ],
      editHighlight: {},
    });
    this.closeHighlighter();
  };

  changeListObject = (
    arr,
    highlightedText,
    startPosition,
    newVal,
    removeValue = false
  ) => {
    var i = arr.length;
    while (i--) {
      if (
        arr[i] &&
        arr[i].hasOwnProperty("highlightedText") &&
        arr[i].hasOwnProperty("selectionStart") &&
        arr[i]["highlightedText"] === highlightedText &&
        arr[i]["selectionStart"] === startPosition
      ) {
        if (removeValue) {
          arr.splice(i, 1);
        } else {
          arr[i] = newVal;
        }
      }
    }
    return arr;
  };

  onSynopsisFinalize = (e) => {
    let question = "";
    let answer = "";
    let remedial = "";

    this.state.highlightedObjectList.map((obj) => {
      if (!obj.ignore) {
        switch (obj.highlighterColor) {
          case "yellow":
            question += "\n" + obj.highlightedText;
            break;
          case "green": // blue
            remedial += "\n" + obj.highlightedText;
            break;
          case "grey":
            answer += "\n" + obj.highlightedText;
            break;
          default:
            break;
        }
      }
    });
    this.setState({
      finalSynopsis: {
        question: question.trim(),
        answer: answer.trim(),
        remedial: remedial.trim(),
      },
      showHighlighter: false,
      isFinalStage: true,
    });
  };

  componentDidUpdate(prevProps) {
    if (this.props !== prevProps) {
      this.setState({ isFinalStage: false });
    }
  }

  render() {
    let classes = null;
    if (this.props.show) {
      classes = ["session-synopsis-container", "show"];
    } else {
      classes = ["session-synopsis-container", "hide"];
    }

    return (
      <Draggable cancel=".right-part">
        <div className={classes.join(" ")}>
          <h3 className="mb-3">
            {this.state.isFinalStage ? "Finalize Resource" : "Session Synopsis"}
          </h3>
          <HighlightText
            showLegends
            hasGreyHighlight={true}
            highlighterPosition={this.state.highlighterPosition}
            showHighlighter={this.state.showHighlighter}
            saveHighlight={this.saveHighlight}
            closeHighlighter={this.closeHighlighter}
            deleteHighlight={this.deleteHighlight}
            editMode={this.state.editHighlight}
          />

          <div className="d-flex data">
            <div className="left-part">
              <h3>Student Error</h3>
              <img src={synopsisImage} alt="Error" />

              <h3 className="mt-2">Knowledge Graph</h3>
              <p className="desc">Error Root Cause for Remedy or Diagnostics</p>
              <img src={synopsisGraph} alt="Graph" />
            </div>

            <div className="right-part">
              <span className="sample-data-label text-right flex-grow-1">* Sample Data</span>

              {!this.state.isFinalStage ? (
                <>
                  <p className="desc">
                    Please confirm or reject each suggested interpretation:
                  </p>

                  <div
                    className="content"
                    onMouseUp={this.onMouseUpHandler}
                    onTouchEnd={this.onMouseUpHandler}
                  >
                    <HtmlContent
                      content={ReactDOMServer.renderToStaticMarkup(
                        this.content
                      )}
                      hintObjectList={[]}
                      highlightedObjectList={this.state.highlightedObjectList}
                      showHighlight={this.showHighlight}
                      showHint={() => { }}
                      isHintVisible={false}
                      hideHint={() => { }}
                    />
                  </div>

                  <Button
                    clicked={this.onSynopsisFinalize}
                    className="mr-2 yellow-btn"
                  >
                    Finalize
                  </Button>
                </>
              ) : (
                  <>
                    <p className="desc">Please edit and confirm:</p>

                    <div className="content">
                      <div class="form-group">
                        <label
                          for="exampleFormControlTextarea1"
                          className="bg-yellow"
                        >
                          Diagnostic Question 1
                      </label>
                        <textarea
                          class="form-control bg-yellow"
                          id="exampleFormControlTextarea1"
                          rows="3"
                          value={this.state.finalSynopsis.question}
                          onChange={(e) => {
                            this.setState({
                              finalSynopsis: {
                                ...this.state.finalSynopsis,
                                question: e.target.value,
                              },
                            });
                          }}
                        />
                      </div>

                      <img
                        src={questionImage}
                        alt="Question Image"
                        className="mt-2"
                      />

                      <div class="form-group">
                        <label
                          for="exampleFormControlTextarea1"
                          className="bg-grey"
                        >
                          Diagnostic Answer
                      </label>
                        <textarea
                          class="form-control bg-grey"
                          id="exampleFormControlTextarea1"
                          rows="2"
                          value={this.state.finalSynopsis.answer}
                          onChange={(e) => {
                            this.setState({
                              finalSynopsis: {
                                ...this.state.finalSynopsis,
                                answer: e.target.value,
                              },
                            });
                          }}
                        />
                      </div>

                      <div class="form-group">
                        <label
                          for="exampleFormControlTextarea1"
                          className="bg-blue mb-4"
                        >
                          ID Of Existing Remedial Content
                      </label>
                      </div>

                      <div class="form-group">
                        <label
                          for="exampleFormControlTextarea1"
                          className="bg-blue"
                        >
                          Remedial Text 1
                      </label>
                        <textarea
                          class="form-control bg-blue"
                          id="exampleFormControlTextarea1"
                          rows="3"
                          value={this.state.finalSynopsis.remedial}
                          onChange={(e) => {
                            this.setState({
                              finalSynopsis: {
                                ...this.state.finalSynopsis,
                                remedial: e.target.value,
                              },
                            });
                          }}
                        />
                      </div>

                      <div class="form-group">
                        <label
                          for="exampleFormControlTextarea1"
                          className="bg-blue mb-5"
                        >
                          Remedial Image 1
                      </label>
                      </div>

                      <div class="form-group">
                        <label
                          for="exampleFormControlTextarea1"
                          className="bg-blue"
                        >
                          Remedial Equation 1
                      </label>
                        <br />
                        <img src={eq1} alt="Equation" className="mb-2" />
                      </div>

                      <div class="form-group">
                        <label
                          for="exampleFormControlTextarea1"
                          className="bg-blue"
                        >
                          Remedial Resource 1
                      </label>
                        <br />
                        <a
                          className="6 bg-blue"
                          href="https://openstax.org/books/college-physics/pages/6-5-newtons-universal-law-of-gravitation#:~:text=Stated%20in%20modern%20language%2C%20Newton's,of%20the%20distance%20between%20them."
                          target="_blank"
                        >
                          Newton’s Universal Law of Gravitation - OpenStax
                      </a>
                      </div>

                      <div className="d-flex btn-group">
                        <Button disabled className="yellow-btn">
                          Add Diagnostics
                      </Button>

                        <Button disabled className="yellow-btn">
                          Add Remedy
                      </Button>
                      </div>

                      <div className="d-flex btn-group mt-3 justify-content-between">
                        <Button
                          clicked={(event) => {
                            this.setState({
                              isFinalStage: false,
                            });
                          }}
                          className="yellow-btn"
                        >
                          Back to Synopsis
                      </Button>

                        <Button
                          clicked={(event) => {
                            toast("Synopsis confirmed successfully.", {
                              type: toast.TYPE.INFO,
                              position: toast.POSITION.BOTTOM_RIGHT,
                            });
                            this.props.onClose();
                          }}
                          className="yellow-btn"
                        >
                          Confirm
                      </Button>
                      </div>
                    </div>
                  </>
                )}
            </div>
          </div>
        </div>
      </Draggable>
    );
  }
}

export default SessionSynopsis;
