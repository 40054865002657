import React from 'react';
import './ModalComponent.scss';
import './MAI.scss';
import { Modal } from 'react-bootstrap';

export default class ModalComponentAI extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            size: 'small'
        }
    }
    pos1 = 0;
    pos2 = 0;
    pos3 = 0;
    pos4 = 0;

    final_posX = 0;
    final_posY = 0;


    modalResize = (size) => {
        this.setState({ size: size });
    }
    modalDragHandler = (e) => {
        e.persist();
        this.pos1 = this.pos3 - e.clientX;
        this.pos2 = this.pos4 - e.clientY;
        this.pos3 = e.clientX;
        this.pos4 = e.clientY;

        e.target.style.top = (e.target.offsetTop - this.pos2) + "px";
        e.target.style.left = (e.target.offsetLeft - this.pos1) + "px";
        this.final_posX = e.target.style.left;
        this.final_posY = e.target.style.top;
    }

    modalDragDropHandler = (e) => {
        e.persist();
        this.pos1 = this.pos3 - e.clientX;
        this.pos2 = this.pos4 - e.clientY;

        e.target.style.top = (e.target.offsetTop - this.pos2) + "px";
        e.target.style.left = (e.target.offsetLeft - this.pos1) + "px";
    }

    modalDragStartHandler = (e) => {
        e.persist();
        this.pos3 = e.clientX;
        this.pos4 = e.clientY;
    }

    componentWillReceiveProps(newProps) {
        if (newProps.popUpSize)
            this.modalResize(newProps.popUpSize)
    }

    render() {
        let draggable = null;
        let modalClasses = 'modal-width cus-modal modal-size-' + this.state.size
        if (this.props.draggable) {
            draggable = {
                dialogClassName: modalClasses + " draggable-modal",
                draggable: 'true',
                onDragStart: (e) => this.modalDragStartHandler(e),
                onDragEnd: (e) => this.modalDragDropHandler(e),
                onDrag: (e) => this.modalDragHandler(e)
            };
        } else {
            draggable = {
                dialogClassName: modalClasses
            }
        }


        return (
            
                <Modal
                    style={{borderRadius:".8rem"}}
                    show={this.props.show}
                    onHide={this.props.handleClose}
                    animation={false}
                    backdrop={this.props.setStatic ? 'static' : true} // setStatic=true : don't hide when click outside
                    {...draggable} >
                    {this.props.hideHeader ? null :
                        <Modal.Header closeButton={this.props.disableClose ? false : true}>
                            {this.props.title ?
                                <Modal.Title style={{ width: this.props.resizable ? 'calc(60%)' : '' }}>{this.props.title}
                                </Modal.Title>
                                : null}
                            {this.props.resizable ?
                                <div className='resize-container ml-auto'>
                                    <span className='resizer-small' onClick={() => this.setState({ size: 'small' })}></span>
                                    <span className='resizer-medium' onClick={() => this.modalResize('medium')}></span>
                                    <span className='resizer-large' onClick={() => this.modalResize('large')}></span >
                                </div> : null}
                        </Modal.Header>
                    }
                    <Modal.Body className="test-modal-body">
                        {this.props.body}
                    </Modal.Body>
                    {
                        this.props.showButton ?
                            <Modal.Footer>
                                <button
                                    type='button'
                                    className='modal-footer-button btn mx-auto'
                                    onClick={this.props.handleClose}>OK</button>
                            </Modal.Footer> : null
                    }
                    {
                        this.props.customButton ?
                            <Modal.Footer>
                                {this.props.customButton}
                            </Modal.Footer> : null
                    }
                </Modal >

               
           
        );
    }
}