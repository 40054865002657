import React from 'react';
import './Button.scss';

const Button = (props) => {
    return props.disabled ?
        <button disabled className={['button', 'disabled-btn', props.className ? props.className : ''].join(' ')}>{props.children}</button>
        : <button onClick={props.clicked} className={['button', props.className ? props.className : ''].join(' ')}>{props.children}</button>
};

export default Button;
