import React from 'react';
import './Answerboard.scss';
import Aux from '../../hoc/Wrapper';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import CancelIcon from '@material-ui/icons/Cancel';
import { isNil } from "lodash";

import { formatAnyProblemAPIResponse } from '../../common/Functions'

class CheckMyworkResultCNonFRQ extends React.Component {

    render() {
        let contentdatafinal = []
        let incorrectHints = [];
        if (this.props.checkmyworkuseddata !== undefined && this.props.checkmyworkuseddata !== null && this.props.checkmyworkuseddata !== "" && this.props.checkmyworkuseddata.length > 0) {
            this.props.checkmyworkuseddata.forEach((items) => {
                items.result.data.forEach((rem) => {
                    let data = ""
                    let data3 = ""
                    let data4 = ""
                    data = rem.remedial_text
                    data3 = rem.remedial_source_url
                    data4 = rem.remedial_source_title
                    if (data && data !== null && data !== 'null' && data !== "null" && data != "undefined" && data !== "" && data !== '') {
                        data = data.toString().replace(/^,/, '').replace(/,\s*$/, "");
                        data = data.replace(".,", ".")
                    }
                    if (data3 && data3 !== null && data3 !== 'null' && data3 !== "null" && data3 != "undefined" && data3 !== "" && data3 !== '') {
                        data3 = data3.toString().replace(/^,/, '').replace(/,\s*$/, "");
                        data3 = data3.replace(".,", ".")
                    }
                    if (data4 && data4 !== null && data4 !== 'null' && data4 !== "null" && data4 != "undefined" && data4 !== "" && data4 !== '') {
                        data4 = data4.toString().replace(/^,/, '').replace(/,\s*$/, "");
                        data4 = data4.replace(".,", ".")
                    }

                    let contentdata = (
                        <Aux>
                            {
                                (data && data !== null && data !== 'null' && data !== "null" && data != "undefined" && data !== "" && data !== '' && data !== ',' && data !== ",") ?
                                    <div className="mb-1 equationTxtnonfrqsmall red" dangerouslySetInnerHTML={{ __html: data.toString().replace(/^,/, '').replace(/,\s*$/, "") }}></div>
                                    : null
                            }

                            {
                                (data3 && data3 !== null && data3 !== 'null' && data3 !== "null" && data3 != "undefined" && data3 !== "" && data3 !== '') ?
                                    <a href={data3}
                                        target='_blank' rel="noopener noreferrer">
                                        <div dangerouslySetInnerHTML={{ __html: data4 ? data4.toString().replace(/^,/, '').replace(/,\s*$/, "") : data3.toString().replace(/^,/, '').replace(/,\s*$/, "") }}></div></a>
                                    : null
                            }

                        </Aux>
                    );
                    contentdatafinal.push(contentdata)
                })
            })
        }

        return (
            <>
                {
                    this.props.checkmyworkuseddata !== undefined && this.props.checkmyworkuseddata !== "" && this.props.checkmyworkuseddata !== "[]" && this.props.checkmyworkuseddata.length > 0 ?
                        (
                            <>
                                <div >
                                    {contentdatafinal}
                                </div>
                            </>
                        ) : null

                }
            </>

        )
    }
}
export default React.memo(CheckMyworkResultCNonFRQ);