import React from 'react';
import './HtmlContent.scss';
import './TextbookEquationsStyles.css'

export default class HtmlContent extends React.Component {

    clickHandler = (e) => {
        const el = e.target.closest(".highlight");
        if (el && e.currentTarget.contains(el)) {
            const range = e.target.getAttribute('name')
            this.props.showHighlight(e, range.split('##'))
        }
    }

    getHTMLContent = () => {
        let { highlightedObjectList, hintObjectList } = this.props
        let innerHTML = this.props.content;

        // Add hints to the HTML content
        if (hintObjectList) {
            hintObjectList.forEach(hintObj => {
                let start = hintObj.selectionStart
                let end = hintObj.selectionEnd
                let focusNodeText = hintObj.focusNodeText

                let index = innerHTML.indexOf(hintObj.elementClass);

                if (index >= 0) {
                    let subHTML = innerHTML.substring(index, innerHTML.length)

                    let mainContentStartIndex = subHTML.indexOf(focusNodeText)
                    let mainContentEndIndex = mainContentStartIndex + focusNodeText.length
                    let mainContent = subHTML.substring(mainContentStartIndex, mainContentEndIndex)
                    let highlightedText = mainContent.substring(start, end)
                    if (highlightedText === hintObj.hintText) {
                        let iconClass = hintObj.hintText === '' ? 'has-note' : ''
                        let modifiedMainContent = mainContent.substring(0, start) + `<span class='hint ${iconClass}' name='${start}##${end}'>` + highlightedText + "</span>" + mainContent.substring(end, subHTML.length)
                        let modifiedSubHTML = subHTML.substring(0, mainContentStartIndex) + modifiedMainContent + subHTML.substring(mainContentEndIndex, subHTML.length)

                        innerHTML = innerHTML.substring(0, index) + modifiedSubHTML
                    }
                }
            });
        }

        // Add highlights to the HTML content
        if (highlightedObjectList.length) {
            highlightedObjectList.forEach(highlightObj => {
                let start = highlightObj.selectionStart
                let end = highlightObj.selectionEnd
                let { focusNodeText, highlighterColor } = highlightObj

                let index = innerHTML.indexOf(highlightObj.elementClass);

                if (index >= 0) {
                    let subHTML = innerHTML.substring(index, innerHTML.length)

                    let mainContentStartIndex = subHTML.indexOf(focusNodeText)
                    let mainContentEndIndex = mainContentStartIndex + focusNodeText.length
                    let mainContent = subHTML.substring(mainContentStartIndex, mainContentEndIndex)
                    let highlightedText = mainContent.substring(start, end)
                    let noteClass = highlightObj.note ? 'has-note' : ''
                    if (highlightedText === highlightObj.highlightedText) {
                        let modifiedMainContent = mainContent.substring(0, start) + `<span class='highlight highlight-${highlighterColor} ${noteClass}' name='${start}##${end}'>` + highlightedText + "</span>" + mainContent.substring(end, subHTML.length)
                        let modifiedSubHTML = subHTML.substring(0, mainContentStartIndex) + modifiedMainContent + subHTML.substring(mainContentEndIndex, subHTML.length)

                        innerHTML = innerHTML.substring(0, index) + modifiedSubHTML
                    }
                }
            });
        }

        return innerHTML
    }

    componentDidUpdate() {
        // Set listeners on hint section
        try {
            let hint = window.$('.hint');
            hint.on('mouseover', this.mouseOver);

            let hintDetail = window.$('.textbook-hint')
            hintDetail.on('mouseleave', () => {
                if (this.props.isHintVisible && this.shouldHideHint) {
                    this.props.hideHint()
                    this.shouldHideHint = false
                }
            });

        } catch (e) {
            console.log(e.message);
        }
    }

    mouseOver = (e) => {
        if (!this.props.isHintVisible) {
            const hintText = e.target.innerHTML
            const range = e.target.getAttribute('name')
            this.props.showHint(e, range.split('##'), hintText)
            setTimeout(() => { this.shouldHideHint = true }, 100)
        }
    }

    shouldHideHint = 0

    render() {

        return (
            <div
                onMouseOver={(e) => {
                    if (this.props.isHintVisible && this.shouldHideHint) {
                        this.props.hideHint()
                        this.shouldHideHint = false
                    }
                }}
                className='html-content'
                onClick={this.clickHandler}
                onTouchStart={this.clickHandler}
                dangerouslySetInnerHTML={{ __html: this.getHTMLContent() }}>
            </div>
        )
    }
}