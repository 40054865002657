import React from 'react';
import './OuterBox.scss';

export default (props) => {
    return (
        <div className='box-outer'>
            <div className='title'>
                {props.title}
            </div>
            {props.children}
        </div>
    );
}
