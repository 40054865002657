import React from "react";
import "./SolutionBoard.scss";
import { toast } from 'react-toastify';
import {
    Accordion,
    AccordionDetails,
    AccordionSummary,
    Typography
} from "@mui/material";

import IconNotification from '../../assets/images/icon_notification.svg';
import LogoSign from '../../assets/images/logo-sign.svg';
import CircularProgress from '@mui/material/CircularProgress';
import { Row, Col } from 'react-bootstrap';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import SketchpadBoard from "../../container/SketchPad/SketchpadBoard";
import IconHintDescription from '../../assets/images/remember-this.png';
import { isEmpty, isNil } from 'lodash';
import Modal from '../Common/ModalComponent/ModalComponent';
import Spinner from 'react-bootstrap/Spinner'
import ProgressSidebar from "../Answerboard/ProgressSidebar/ProgressSidebar";
import Button from '../Common/Button/Button';
import MathJaxRenderer from '../Common/MathJaxRenderer/MathJaxRenderer';
import Aux from '../../hoc/Wrapper';
import { ReactSortable } from 'react-sortablejs';
import { getCurrentUserId, obtainSubstitution, getCurrentUserRole, User_Role, Previous_Node_Name, Problem_source } from '../../common/Functions';
import {
    checkChallengeAnswer, fetchChallengeHint, fetchChallengeHintnonfrq, getNextStep, generateSVGFromMathML,
    getEntireSolution, fetchDiagnosticQuestions, checkDiagnosticQuestionAnswer
    , checkEquation, imgURL
    , checkStudentAnswer, getTutorAnswer, studentnonfrqhomework_detail, studenthomework_review, SaveStudentChallenge

} from '../../common/API';
import GraphComponent from '../../container/Homepage/Graph/GraphComponent'
import Flow from '../SolutionGraph/flow';
import parse from 'html-react-parser';
import { substituteText } from '../../common/Constants';
import CloseIcon from '@mui/icons-material/Close';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import CancelIcon from '@material-ui/icons/Cancel';
import DnsIcon from '@material-ui/icons/Dns';
import { Link, withRouter } from 'react-router-dom';
import configData from '../../common/config.json';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import HomeworkReviewDetail from '../Answerboard/HomeworkReviewDetail'
import HomeworkReviewDetailNonFRQ from '../Answerboard/HomeworkReviewDetailNonFRQ';
import HomeworkReviewDetailNonFRQText from '../Answerboard/HomeworkReviewDetailNonFRQText';
import Stack from '@mui/material/Stack';
import TrapFocus from '@mui/material/Unstable_TrapFocus';
import Fade from '@mui/material/Fade';
import MuiButton from '@mui/material/Button';
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
class AnswerBoardMobile extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            showModal: false,

            hints: [
                {
                    title: "Remember this?",
                    subTitle: "See if you can use your previous work to solve your problem.",
                    hintImage: IconHintDescription
                }
            ],
            currentHintIndex: 0,
            rankingOptionList: [],
            hintRemedialText: null,
            hintRemedialSource: null,
            hintLastMatchedCheckpointId: null,
            totalHints: -1,
            result: null,
            tutorFlag: false,
            tutorAvailableFlag: false,
            openBackdrop: false,
            showProgressSidebar: false,
            showKnowledgeGraphSidebar: false,
            showSessionSynopsis: false,
            progressSidebarContent: '',
            knowledgeGraphSidebarContent: '',
            boardLoaded: false,
            isSessionSynopsisLoading: false,
            disabledSynopsisButton: false,
            diagnosticQuestions: undefined,
            diagnosticQuestionIndex: undefined,
            diagnosticQuestionOption: undefined,
            participantsForTranscript: [],
            transcripts: {},
            synopsisData: {},
            mappingId: "",
            tutorName: '',
            tutors_arr: [],
            no_tutor: false,
            tutor_in_tutors_arr: null,
            selectedEquation: undefined,
            availabletutorlist: false,
            availablecurrenttutordata: [],
            availablenext24tutordata: [],
            showAnswerModal: false,
            showContentOptions: false,
            isContentOptionsLoading: false,
            contentOptionsPosition: {
                top: 0,
                left: 0
            },
            tutorfinallist: [],
            page: 0,
            rowsPerPage: 5,
            tutorid: null,
            nextStepCounter: 0,
            totalStepCounter: 0,
            roomId: null,
            isText: false,
            showSolution: null,
            checkMyAnswer: null,
            homeworkreviewdata: [],
            homeworknonfrqreviewdata: [],
            equationdata: [],
            hint_data: null,
            footerbuttondisplay: true,
            is_review_complete: false,
            showsubmitbutton: true,
            showreviewdModal: false,
            showreviewdModalnonFRQ: false,
            loader: false,
            leftpanelbuttonhide: false,
            homeworkproblems_id: null,
            nonfrqhintdata: [],
            anyProblemStatement: '',
            actionBtnDisabled: this.props.anyProblem ? true : false,
            anyproblemBackbuttonText: "",
            anyproblemBackbuttonlink: "",
            sketchpadDivcolor: this.props.anyProblem ? "transparent" : "#fff",
            checkMyWorkClickedOnce: false,
            askTutorTextYes: false,
            afterSubscriptionMessage: false,
            askTutorButton: false,
            upperSectionHide: false,
            isScreenFull: false,
            cHeight: "88vh",
            eHeight: "190px",
            showEquationPanel: true,
            backPath: "/curriculum-problems",
            problem_source: Problem_source.interactiveproblems,
            issubmitdialog: false,
            studentAnswer: "",
            validationMessage: false,
            dButtonText: "",
            mcqcorrectAnswerReview: null,
            mainDivDisabled: false,
            msqCorrectAnswerReview: new Array(this.props.question.options).fill(false),
            selectedAnswersByUser: "",
            bannerOpen: false,
            checkMyWorkCounter: 0

        }
    }
    refs = []
    modalBody = null;
    tutormodalbody = null;
    modalTitle = null;
    showButton = false;
    customButton = null;
    loadTranscriptTimer = null;
    draggable = false;

    componentDidMount() {
        this.setState({ loader: true })
        if (!isNil(this.props.location.state["problemSource"]) && !isEmpty(this.props.location.state["problemSource"])) {

            this.setState({ problem_source: this.props.location.state["problemSource"][0] })
            if (window.location.pathname.includes("answerboardpt")) {
                this.setState({ problem_source: Problem_source.apptesthomework, showsubmitbutton: false })

            }
            else if (window.location.pathname.includes("answerboardhow")) {
                this.setState({ showsubmitbutton: true, problem_source: Problem_source.homework })

            }
        }
        else {

            this.pSource = Problem_source.interactiveproblems
            if (window.location.pathname.includes("answerboardpt")) {
                this.setState({ problem_source: Problem_source.apptesthomework, showsubmitbutton: false })
                this.pSource = Problem_source.apptesthomework

            }
            else if (window.location.pathname.includes("answerboardhow")) {
                this.setState({ problem_source: Problem_source.homework, showsubmitbutton: true })
                this.pSource = Problem_source.homework
            }
        }
        if (!isNil(this.props.location.state["HomeworkId"]) && !isEmpty(this.props.location.state["HomeworkId"])) {
            this.setState({ homeworkproblems_id: parseInt(this.props.location.state["HomeworkId"][0]) }
            )
        }
        else {
            this.setState({ homeworkproblems_id: parseInt(this.props.hw_id) })
        }

        if (!isNil(this.props.location.state["prevNode"]) && !isEmpty(this.props.location.state["prevNode"])) {
            if (this.props.location.state["prevNode"][0] === Previous_Node_Name.interactiveproblems) {
                this.setState({ showsubmitbutton: false })
            }
            else if (this.props.location.state["prevNode"][0] === Previous_Node_Name.learningcontent) {
                this.setState({ showsubmitbutton: false })
            }
            else if (this.props.location.state["prevNode"][0] === Previous_Node_Name.homeworkproblems) {
                this.setState({ showsubmitbutton: true })
            }
            else {
                this.setState({ showsubmitbutton: true })
            }

            if (this.props.location.state["prevNode"][0] === Previous_Node_Name.practicetest) {
                this.setState({ backPath: "/practicetest" })
            }
            else if (this.props.location.state["prevNode"][0] === Previous_Node_Name.homeworkproblems) {
                this.setState({ backPath: "/homeworkproblems" })
            }
            else {
                this.setState({ backPath: "/curriculum-problems" })
            }

        }
        else {
            this.setState({ showsubmitbutton: false })
        }
        localStorage.removeItem("studentroomid")
        this.props.location.state = {
            ...this.props.location.state,
            resetHintCount: this.resetHintCount
        }
        window.$(document).scroll(() => {
            this.listenScrollEvent();
        });

        if (this.props.question.type === 'Ranking') {
            let rankingOptionList = this.props.question.options.map((opt, index) => ({ id: String(index + 1), name: opt.trim() }))
            this.setState({ rankingOptionList: rankingOptionList });
        }
        // debugger;

        if (getCurrentUserRole() === User_Role.Student && this.pSource !== Problem_source.interactiveproblems) {


            if (!isNil(this.props.location.state["HomeworkId"]) && !isEmpty(this.props.location.state["HomeworkId"])) {
                this.setState({ homeworkproblems_id: parseInt(this.props.location.state["HomeworkId"][0]) }, () => {

                    if (this.props.question.type === 'FRQ') {
                        let reqData = {
                            challenge_id: this.props.challengeId,
                            studentId: getCurrentUserId(),
                            homeworkproblemsId: parseInt(this.props.location.state["HomeworkId"][0]),
                            problem_source: this.props.location.state["problemSource"][0]
                            // boardId:this.props.location.state.boardId
                        }
                        studenthomework_review({ params: reqData }).then(res => {
                            this.setState({ loader: false })
                            if (res.data.data !== undefined && res.data.data.length > 0) {
                                this.setState({ homeworkreviewdata: res.data.data, is_review_complete: res.data.data[0].is_review_complete }, () => {
                                    this.setState({ footerbuttondisplay: false, showreviewdModalnonFRQ: false, showreviewdModal: true, showsubmitbutton: false, leftpanelbuttonhide: true })
                                    if (res.data.data[0].ta_feedback !== undefined && res.data.data[0].ta_feedback !== null) {

                                        if (res.data.data[0].ta_feedback["result"] !== undefined &&
                                            res.data.data[0].ta_feedback["result"] !== null && res.data.data[0].ta_feedback["result"].length > 0)
                                            this.setState({ equationdata: res.data.data[0].ta_feedback["result"] })

                                        else
                                            this.setState({ equationdata: res.data.data[0].ta_feedback })

                                    }
                                    if (this.pSource === Problem_source.homework) {
                                        this.setState({ showsubmitbutton: true })

                                    }
                                })
                            }
                            else {
                                this.setState({ leftpanelbuttonhide: false })
                                if (this.pSource === Problem_source.homework) {
                                    this.setState({ showsubmitbutton: true })

                                }
                            }
                        })
                    }
                    else {

                        this.setState({ loader: true })
                        let reqData = {
                            challenge_id: this.props.challengeId,
                            studentId: getCurrentUserId(),
                            homeworkproblems_id: parseInt(this.props.location.state["HomeworkId"][0]),
                            problem_source: this.props.location.state["problemSource"][0]
                            // boardId:this.props.location.state.boardId
                        }
                        studentnonfrqhomework_detail({ params: reqData }).then(res => {
                            this.setState({ loader: false })
                            if (res.data.data !== undefined && res.data.data.length > 0) {

                                let questionanswertext = res.data.data[0].answertext
                                this.setState({ selectedAnswersByUser: questionanswertext })
                                this.userAnswer = res.data.data[0].answertext
                                let originalarray = this.props.question.checkedOption
                                let finalanswerarray = new Array(this.props.question.checkedOption.length).fill(false)
                                if (this.props.question.type === "MSQ") {
                                    let answertextfinal = ""
                                    if (questionanswertext.includes("###")) {
                                        // originalarray.forEach((element, index) => {
                                        //     debugger;
                                        //     if (questionanswertext.includes(element)) {
                                        //         finalanswerarray[index] = true
                                        //     }
                                        //     else {
                                        //         finalanswerarray[index] = false
                                        //     }

                                        // });
                                        let textstring = questionanswertext.split("###")
                                        textstring.forEach((element) => {
                                            debugger;
                                            const answert = this.props.question.options.find((e) => e.id == parseInt(element));
                                            if (answert != undefined && answert !== null) {
                                                if (answertextfinal === "")
                                                    answertextfinal = answert.text.replace(/(<([^>]+)>)/ig, '')
                                                else
                                                    answertextfinal = answertextfinal + "###" + answert.text.replace(/(<([^>]+)>)/ig, '')
                                            }
                                        })

                                        this.userAnswerText = answertextfinal
                                    }
                                    else {
                                        // originalarray.forEach((element, index) => {
                                        //     debugger;
                                        //     if (parseInt(questionanswertext) === parseInt(element)) {
                                        //         finalanswerarray[index] = true
                                        //     }
                                        //     else {
                                        //         finalanswerarray[index] = false
                                        //     }
                                        // });
                                        //debugger;
                                        const answert = this.props.question.options.find((element) => element.id == parseInt(questionanswertext));
                                        if (answert != undefined && answert !== null) {
                                            this.userAnswerText = answert.text.replace(/(<([^>]+)>)/ig, '')
                                        }

                                    }
                                }
                                else {
                                    //debugger;
                                    const answert = this.props.question.options.find((element) => element.id == parseInt(questionanswertext));
                                    if (answert != undefined && answert !== null) {
                                        this.userAnswerText = answert.text.replace(/(<([^>]+)>)/ig, '')
                                    }
                                }
                                //debugger;

                                if (parseInt(res.data.data[0].is_finalSubmission) === 1) {

                                    this.setState({
                                        homeworknonfrqreviewdata: res.data.data, mcqcorrectAnswerReview: questionanswertext
                                        // , msqCorrectAnswerReview: finalanswerarray
                                    }, () => {
                                        this.setState({
                                            footerbuttondisplay: false, showreviewdModal: false
                                            , showreviewdModalnonFRQ: true
                                            , showsubmitbutton: false
                                            , leftpanelbuttonhide: true
                                            , mainDivDisabled: true
                                        })
                                    })
                                    if (this.pSource === Problem_source.homework) {
                                        this.setState({ showsubmitbutton: true })

                                    }
                                }
                                else {
                                    this.setState({
                                        homeworknonfrqreviewdata: res.data.data, mcqcorrectAnswerReview: questionanswertext
                                        // , msqCorrectAnswerReview: finalanswerarray 
                                    }, () => {
                                        this.setState({
                                            footerbuttondisplay: false, showreviewdModal: false
                                            , showreviewdModalnonFRQ: false
                                            , showsubmitbutton: false
                                            , leftpanelbuttonhide: true
                                            , mainDivDisabled: true
                                        })
                                    })
                                    if (this.pSource === Problem_source.homework) {
                                        this.setState({ showsubmitbutton: true })

                                    }
                                }


                            }
                            else {
                                this.setState({
                                    leftpanelbuttonhide: false, mainDivDisabled: false
                                })
                                if (this.pSource === Problem_source.homework) {
                                    this.setState({ showsubmitbutton: true })

                                }
                            }
                        })
                    }

                })
            }

            SaveStudentChallenge({ user_id: getCurrentUserId(), challenge_id: this.props.challengeId })
                .then(res => { })
                .catch(err => {
                    this.setState({ loader: false })
                    console.error(err.message);
                })
        }

        let params = new URLSearchParams(window.location.search);
        let roomId = params.get('roomId');
        this.setState({ roomId: roomId });
        this.setState({ loader: false })
    }


    openModal = (event, modalTitle, modalBody, customButton = null, showButton = false, draggable = false) => {
        if (event) {
            event.preventDefault();
        }
        this.modalBody = modalBody;
        this.modalTitle = modalTitle;
        this.showButton = showButton;
        this.customButton = customButton;
        this.draggable = draggable;
        this.setState({ showModal: true });
    }
    closeModal = () => {
        // this.setState({ showModal: false, askTutorTextYes: false, afterSubscriptionMessage: false, askTutorButton: false });
        // const cookies = new Cookies();
        // let cookiebannervalue = cookies.get("feedbackbanner")
        // if (cookiebannervalue === false || cookiebannervalue === "false") {
        //     this.setState({ bannerOpen: false })
        // }
        // else {
        this.setState({ bannerOpen: true })
        // }

    }
    closeAnswerModal = () => {
        this.setState({ showAnswerModal: false });
    }

    moreToggleVisibility = () => {
        this.setState({ hintoptionsshow: !this.state.hintoptionsshow });
    };

    setBoardLoaded = (value) => {
        console.log("val - " + value)
        this.setState({ boardLoaded: value });
    }

    mcqChangeHandler = (ev, selectedtext) => {
        this.userAnswer = ev.target.value;
        this.userAnswerText = selectedtext.replace(/(<([^>]+)>)/ig, '')
        this.setState({ mcqcorrectAnswerReview: ev.target.value })
        this.checkChallengeAnswerCommon(ev, "MCQ")
    }

    msqChangeHandler = (ev, position, opttext) => {
        debugger;
        // let data1 = [...this.state.msqCorrectAnswerReview]

        // const updatedCheckedState = this.state.msqCorrectAnswerReview.map((item, index) =>
        //     index === position ? !item : item
        // );

        // // debugger;
        // this.setState({ msqCorrectAnswerReview: updatedCheckedState })

        // var isChecked = ev.target.checked;  
        // var item = ev.target.value;  

        // this.setState(prevState => ({ checkedItems: prevState.checkedItems.set(item, isChecked) }));  


        // if (this.userAnswer === undefined) {
        //     this.userAnswer = [];
        // }
        // if (this.userAnswerText === undefined) {
        //     this.userAnswerText = [];
        // }

        ev.persist();
        let targetValue = ev.target.value
        let textvalue = opttext.replace(/(<([^>]+)>)/ig, '')
        if (ev.target.checked) {

            if (this.userAnswer === undefined || this.userAnswer === null || this.userAnswer == "") {
                this.userAnswer = targetValue
            }
            else {
                this.userAnswer = this.userAnswer + "###" + targetValue
            }
            if (this.userAnswerText === undefined || this.userAnswerText === null || this.userAnswerText == "") {
                this.userAnswerText = textvalue
            }
            else {
                this.userAnswerText = this.userAnswerText + "###" + textvalue
            }
            // this.userAnswer.push(targetValue);
            // this.userAnswerText.push(textvalue)

        } else {

            if (this.userAnswer.includes(targetValue)) {

                let finaldata = ""
                if (this.userAnswer.includes("###")) {

                    let arr = this.userAnswer.split("###")
                    arr.forEach((rem) => {
                        if (targetValue !== rem) {
                            // finalarray.push(rem)
                            if (finaldata !== "" && finaldata !== null && finaldata !== undefined) {
                                finaldata = finaldata + "###" + rem
                            }
                            else {
                                finaldata = rem
                            }
                        }
                    })
                }
                this.userAnswer = finaldata
            }
            if (this.userAnswerText.includes(textvalue)) {
                debugger;

                let finaldata = ""
                if (this.userAnswerText.includes("###")) {

                    let arr = this.userAnswerText.split("###")
                    arr.forEach((rem) => {
                        if (textvalue !== rem) {
                            // finalarray.push(rem)
                            if (finaldata !== "" && finaldata !== null && finaldata !== undefined) {
                                finaldata = finaldata + "###" + rem
                            }
                            else {
                                finaldata = rem
                            }
                        }
                    })
                }
                this.userAnswerText = finaldata
            }
        }

        this.setState({ selectedAnswersByUser: this.userAnswer })
        this.checkChallengeAnswerCommon(ev, "MSQ")
    }

    questionChallengeClickHandler = (s) => {
        if (s === 'yes') {
            this.openModal(undefined,
                'Please wait...',
                <p>Thanks for submitting your solution! Taking you to more challenges...</p>);
            setTimeout(() => {
                this.submitModalClose = true;
                this.closeModal();
            }, 5000)

        } else {
            this.closeModal();
        }
    }
    getAnswerText = (anstext) => {
        this.userAnswer = anstext
    }
    resetNextStepCounter = () => {
        this.setState({ nextStepCounter: 0, totalStepCounter: 0 })
    }

    handleShowEquation = () => {
        this.setState({ showEquationPanel: true })
    }
    handleHideShowEquation = () => {

        this.setState({ showEquationPanel: false })
    }

    setSidebarContent = (content, header = 'Your progress so far:') => {
        content = <>
            <p className='sidebar-heading mt-2 pl-3'>{header}</p>
            {content}
        </>
        this.progressSidebarContent = content
        this.setState({
            showProgressSidebar: true,
        })
    }
    setKnowledgeGraphSidebarContent = (content, header = 'Your progress so far:') => {
        content = <>
            <p className='sidebar-heading mt-2 pl-3'>{header}</p>
            {content}
        </>
        this.setState({ knowledgeGraphSidebarContent: content });
        this.setState({
            showKnowledgeGraphSidebar: true,
        })
    }
    getKnowledgeGraph = () => {
        if (this.props.location.state.knowledgeGraphFunction) {
            this.props.location.state.knowledgeGraphFunction();
        }
    }
    closeProgressSidebar = () => {
        this.resetNextStepCounter();
        this.setState((prevState) => ({ showProgressSidebar: false }));

        // this.setState({ bannerOpen: true })
        if (this.state.checkMyWorkCounter === 1) {
            this.setState({ bannerOpen: true })
        }
        else {
            let counter = this.state.checkMyWorkCounter
            if (counter % 3 === 0) {
                this.setState({ bannerOpen: true })
            }
        }

    }

    closeKnowledgeGraphSidebar = () => {
        this.setState({ knowledgeGraphSidebarContent: '' });
        this.setState((prevState) => ({ showKnowledgeGraphSidebar: false }));
    }
    svg2img = svg => {
        var placeholder = document.createElement('div');
        placeholder.innerHTML = svg
        var elem = placeholder.firstChild;

        var xml = new XMLSerializer(svg).serializeToString(elem);
        var svg64 = ''
        try {
            svg64 = btoa(xml); //for utf8: btoa(unescape(encodeURIComponent(xml)))
        } catch (e) {
            svg64 = btoa(unescape(encodeURIComponent(xml)))
        }
        var b64start = 'data:image/svg+xml;base64,';
        var image64 = b64start + svg64;
        return image64;
    }
    submitEquations = () => {
        // Commented as per Khyati's request to show it when the board has more then 5 equations.so the code is in sketchpadboard now.
        // if (!this.state.checkMyWorkClickedOnce) {
        //     this.setState({ checkMyWorkClickedOnce: true })
        //     toast('Please streamline your steps just the way you would when showing it to tutor. This also helps reduce AI tutors response time as you check your work!', {
        //         type: toast.TYPE.INFO,
        //         title: "Is your solution streamlined ?",
        //         position: toast.POSITION.BOTTOM_RIGHT,
        //         autoClose: false
        //     })
        // }

        this.resetNextStepCounter();
        this.setState({ hintRemedialText: "", currentHintIndex: 0, bannerOpen: false, checkMyWorkCounter: parseInt(this.state.checkMyWorkCounter) + 1 });
        if (this.props.location.state.submitFunction) {
            this.props.location.state.submitFunction();
        }
    }

    showLoadingModal = (event, title) => {

        let modalTitle = (
            <div className='d-flex align-items-center'>
                <h4 className='m-0'>{title}</h4>
            </div>);

        let modalContent = (<div className="progress-bar-container">
            <Spinner animation="border" role="status" />
        </div>)

        this.openModal(event,
            modalTitle,
            modalContent,
            null,
            false,
            true);
    }
    handleOnIdle = (inactive = true) => {
        // perform stuff when user is idle
        if (!this.props.vcState && !this.state.showModal) {
            const modalContent = (
                <div className='text-center modal-content' style={{ display: "block" }}>
                    <img src={IconNotification} alt='NotificationIcon' style={{ width: "30px" }} />
                    {inactive ? <div className='iconNotification mt-2'>Hmm... You haven’t interacted for a while.</div> : null}
                    <div className='iconNotification'>Would you like help from a tutor?</div>
                </div>
            );
            const modalButton = (
                <div className='modal-button text-center'>
                    {this.props.question.type === 'FRQ' ?
                        <Button clicked={(event) => this.getHint(event)} className="mr-2 yellow-btn">Use Hint</Button>
                        : null}
                    <Button clicked={this.openVCCloseModal} className="mr-2 yellow-btn">Yes</Button>
                    <Button clicked={this.closeModal} className="mr-2 grey-btn">No Thanks</Button>
                </div>
            );
            this.openModal(null,
                null,
                modalContent,
                modalButton,
                false,
                true)
        }
    }
    showDiagnosticQuestionModal = (event, diagnosticQuestionObj, extraContent = null, extraContentButton = null) => {
        const onDiagnosticQuestionOptionChangeHandler = (e, diagnosticQuestionObj) => {
            this.setState({
                diagnosticQuestionOption: e.target.value
            }, () => {
                this.showDiagnosticQuestionModal(e, diagnosticQuestionObj)
            })
        }

        const submitDiagnosticQuestion = (e, diagnosticQuestionObj) => {

            if (!this.state.diagnosticQuestionOption) {
                toast('Please select any option.', {
                    type: toast.TYPE.ERROR,
                    position: toast.POSITION.BOTTOM_RIGHT
                })
                return
            }

            const reqData = {
                questionId: diagnosticQuestionObj.questionId,
                userResponse: this.state.diagnosticQuestionOption,
            }

            checkDiagnosticQuestionAnswer(reqData)
                .then(res => {

                    if (res.data.status === 'correct') {
                        if (this.state.diagnosticQuestions.length > 1 && this.state.diagnosticQuestionIndex < this.state.diagnosticQuestions.length - 1) {
                            // another question to show...
                            const extraContent = <div className='mt-2 green'>
                                <p>Seems that you know this concept well.</p>
                                <p>Let’s check another concept required to solve this problem.</p>
                            </div>

                            const extraContentButton = <div className='modal-button text-center'>
                                <Button
                                    clicked={(e) => {
                                        this.setState({
                                            diagnosticQuestionIndex: this.state.diagnosticQuestionIndex + 1,
                                            diagnosticQuestionOption: undefined,
                                        }, () => {
                                            this.showDiagnosticQuestionModal(e, this.state.diagnosticQuestions[this.state.diagnosticQuestionIndex])
                                        })
                                    }}
                                    className="mr-2 yellow-btn width-btn">Next Diagnostic Question</Button>
                            </div>
                            this.showDiagnosticQuestionModal(e, diagnosticQuestionObj, extraContent, extraContentButton)
                        } else {
                            const extraContent = <div className='mt-2 green'>
                                <p>Seems that you know this concept well.</p>
                                <p>I’ve checked the concepts I could think of, would you like to talk to a tutor?</p>
                            </div>

                            const extraContentButton = <div className='modal-button text-center'>
                                <Button
                                    clicked={this.closeModal}
                                    className="mr-2 yellow-btn width-btn">Return to Challenge</Button>
                                <Button tooltip="Start a live video call with a human tutor. Share your board to collaborate."
                                    clicked={this.openVCCloseModal}
                                    className="mr-2 yellow-btn">Ask Tutor</Button>
                            </div>
                            this.showDiagnosticQuestionModal(e, diagnosticQuestionObj, extraContent, extraContentButton)
                        }
                    } else {
                        const extraContent = <div className='mt-2'>
                            <div className="red mb-3">
                                <p>This isn’t the correct answer.</p>

                                {res.data.remedial_text ?
                                    <>
                                        <p>{res.data.remedial_text}</p>
                                    </>
                                    : null}

                                {res.data.remedial_resource ?
                                    <>
                                        <a href={res.data.remedial_resource} target='_blank' rel="noopener noreferrer"><p>{res.data.remedial_resource}</p></a>
                                    </>
                                    : null}
                            </div>
                            <span>Would you like to talk to a tutor?</span>
                            <Button tooltip="Start a live video call with a human tutor. Share your board to collaborate."
                                clicked={this.openVCCloseModal}
                                className="ml-2 yellow-btn">Ask Tutor</Button>

                        </div>

                        const extraContentButton = <div className='modal-button text-center'>
                            <Button
                                clicked={this.closeModal}
                                className="mr-2 yellow-btn width-btn">Return to Challenge</Button>
                        </div>

                        this.showDiagnosticQuestionModal(e, diagnosticQuestionObj, extraContent, extraContentButton)
                    }

                }).catch(err => {
                    console.error(err.message);
                    alert('Error occurred while submitting the answer.');
                })
        }

        let modalTitle = (
            <div className='d-flex align-items-center'>
                <h4 className='m-0'>Diagnostic Question</h4>
            </div>);

        let modalContent = (
            <div className='diagnostic-question-content'>
                <p>
                    {/* <MathJax.Context
                        input='ascii'
                        onError={(MathJax, error) => {
                            console.warn(error);
                            console.log("Encountered a MathJax error, re-attempting a typeset!");
                            MathJax.Hub.Queue(
                                MathJax.Hub.Typeset()
                            );
                        }}
                        script="https://cdnjs.cloudflare.com/ajax/libs/mathjax/2.7.4/MathJax.js?config=default"
                        options={{
                            asciimath2jax: {
                                useMathMLspacing: true,
                                delimiters: [["$$", "$$"]],
                                preview: "none",
                            }
                        }}
                    >
                        <MathJax.Text text={diagnosticQuestionObj.question} />

                    </MathJax.Context> */}
                    <MathJaxRenderer math={diagnosticQuestionObj.question}></MathJaxRenderer>
                    {/* <MathpixLoader>
                        <MathpixMarkdown text={diagnosticQuestionObj.question} />
                    </MathpixLoader>                        */}
                </p>

                {diagnosticQuestionObj.options.map((opt, index) => (
                    <Aux key={'a' + index}>
                        <input
                            type='radio'
                            name='option'
                            id={'opt' + index}
                            key={'k' + index}
                            value={opt}
                            checked={this.state.diagnosticQuestionOption === opt}
                            className='mr-2 summative-option'
                            onChange={(event) => onDiagnosticQuestionOptionChangeHandler(event, diagnosticQuestionObj)}
                            style={{ cursor: 'pointer' }} />
                        <label htmlFor={'opt' + index} key={'l' + index} style={{ cursor: 'pointer' }}>
                            <p className='mb-0'>
                                {/* <MathJax.Context
                                    input='ascii'
                                    onError={(MathJax, error) => {
                                        console.warn(error);
                                        console.log("Encountered a MathJax error, re-attempting a typeset!");
                                        MathJax.Hub.Queue(
                                            MathJax.Hub.Typeset()
                                        );
                                    }}
                                    script="https://cdnjs.cloudflare.com/ajax/libs/mathjax/2.7.4/MathJax.js?config=default"
                                    options={{
                                        asciimath2jax: {
                                            useMathMLspacing: true,
                                            delimiters: [["$$", "$$"]],
                                            preview: "none",
                                        }
                                    }}
                                >
                                    <MathJax.Text text={opt} />
                                </MathJax.Context> */}

                                <MathJaxRenderer math={opt}></MathJaxRenderer>

                                {/* <MathpixLoader>
                                <MathpixMarkdown text={opt} />
                            </MathpixLoader> */}
                            </p>
                        </label>
                        <br />
                    </Aux>
                ))}
            </div>
        )

        let modalCustomButton = (
            <div className='modal-button text-center'>
                <Button
                    clicked={(e) => submitDiagnosticQuestion(e, diagnosticQuestionObj)}
                    disabled={!this.state.diagnosticQuestionOption}
                    className="mr-2 yellow-btn">Submit</Button>
            </div>
        )

        this.openModal(event,
            modalTitle,
            extraContent ? <>
                {modalContent}
                {extraContent}
            </> : modalContent,
            extraContentButton ? extraContentButton : modalCustomButton,
            false,
            true);
    }
    closeAnswerDeviders = (deviderName) => {
        if (deviderName === "checkMyAnswer") {
            this.state.checkMyAnswer = null;
            this.setState({ checkMyAnswer: null })
        }

        if (deviderName === "showSolution") {
            this.state.showSolution = null;
            this.setState({ showSolution: null })
        }

        if (this.state.checkMyAnswer === null && this.state.showSolution === null)
            this.state.isText = false
    }
    submitNonFRQHandler = (event) => {
        debugger;
        if (this.userAnswerText || this.props.question.type === 'Ranking') {

            if (this.props.question.type === 'Ranking') {
                this.userAnswerText = this.state.rankingOptionList.map(item => item.name)
            }
        }
        if (this.userAnswerText !== undefined && this.userAnswerText !== null && this.userAnswerText !== "") {
            let userselectedanswer = ""
            //debugger;
            if (this.userAnswerText.includes("###")) {
                let splitString = this.userAnswerText.split("###")
                splitString.forEach(element => {
                    if (userselectedanswer !== "") {
                        userselectedanswer = userselectedanswer + " " + element
                    }
                    else {
                        userselectedanswer = element
                    }

                });

                // userselectedanswer = userselectedanswer.replace("###", " ")
            }
            //debugger;
            this.setState({ studentAnswer: "Your final answer: " + userselectedanswer, validationMessage: false, dButtonText: "Yes" }, () => {
                this.setState({ issubmitdialog: true })
            })
        }
        else {
            this.setState({ studentAnswer: "Please Select Answer First!" }, () => {
                this.setState({ issubmitdialog: true, validationMessage: true, dButtonText: "Ok" })
            })
        }
    }
    checkChallengeAnswerCommon = (event, qtype) => {
        let question_id = this.props.question.id.slice(1, this.props.question.id.length);
        if (this.userAnswer || this.props.question.type === 'Ranking') {

            if (this.props.question.type === 'Ranking') {
                this.userAnswer = this.state.rankingOptionList.map(item => item.name)
            }

            let reqData = {
                userAnswer: this.userAnswer,
                questionId: question_id,
                user_id: getCurrentUserId(),
                homeworkproblems_id: this.state.homeworkproblems_id,
                hints_used: this.state.nonfrqhintdata,
                problem_source: this.state.problem_source,
                boardId: this.props.location.state.boardId,
                final_submission: 0,
                optionselected: 1,
                qtype: qtype,
                fromTestPaper: 0

            }
            checkChallengeAnswer(reqData).then(res => {
                this.setState({ loader: false })
                let modalContent = null;
                let modalTitle = '';
                let modalCustomButton = null;
                if (res.data.status === 'correct') {
                    modalContent = (
                        <div className='answer-model-correct d-flex justify-content-center'>
                            <span className='correct-answer mr-2'></span>
                            <h5>Correct Answer</h5>
                        </div>
                    )
                } else {

                    modalTitle = (
                        <div className='d-flex align-items-center'>
                            <span className='incorrect-answer mr-2'></span>
                            <h4 className='m-0'>"This isn’t the correct answer."</h4>
                        </div>);
                    modalContent = (
                        <div className='answer-modal-incorrect'>
                            {
                                res.data.data !== undefined && res.data.data.length > 0 ? (
                                    res.data.data.map(item => {
                                        return (
                                            <>
                                                {
                                                    item.remedial_text !== "" && item.remedial_text !== '' && item.remedial_text !== null ? (
                                                        <>
                                                            <h5>Explanation:</h5>
                                                            <span className='d-block'>
                                                                <p dangerouslySetInnerHTML={{ __html: item.remedial_text }}></p>
                                                            </span>
                                                        </>) : null
                                                }
                                                {
                                                    item.remedial_source_url !== "" && item.remedial_source_url !== '' && item.remedial_source_url !== null ?
                                                        (<>
                                                            <h5>Recommended Resources:</h5>
                                                            <p className='mb-4'>
                                                                <a href={item.remedial_source_url} target='_blank' rel="noopener noreferrer">
                                                                    <p dangerouslySetInnerHTML={{ __html: item.remedial_source_title ? item.remedial_source_title : item.remedial_source_url }}></p></a></p>
                                                        </>) : null
                                                }


                                            </>
                                        )
                                    })

                                ) : null
                            }

                        </div>
                    )
                    const tutorModalContent = (
                        <div className='text-center modal-content'>
                            <img src={IconNotification} alt='NotificationIcon' />
                            <div className='iconNotification'>Would you like help from a tutor?</div>
                        </div>
                    );
                    const tutorModalButton = (
                        <div className='modal-button text-center'>
                            <Button clicked={this.openVCCloseModal} className="mr-2 yellow-btn">Ok</Button>
                            <Button clicked={this.closeModal} className="mr-2 grey-btn">No Thanks</Button>
                        </div>
                    );
                    modalCustomButton = (
                        <div className='modal-button text-center'>
                            <Button clicked={(event) => this.openModal(event,
                                null,
                                tutorModalContent,
                                tutorModalButton,
                                false,
                                true)} tooltip="Start a live video call with a human tutor. Share your board to collaborate." className="mr-2 yellow-btn">Ask Tutor</Button>
                            <Button clicked={this.closeModal} className="mr-2 yellow-btn">Try Again</Button>
                        </div>
                    )
                }
                this.openModal(event,
                    modalTitle,
                    modalContent,
                    modalCustomButton,
                    false,
                    true);
                // this.submitModalClose = true;
            }).catch(err => {
                this.setState({ loader: false })
                console.error(err.message);
                alert('Error occurred while submitting the answer.');
            })
        } else {
            this.setState({ loader: false })
            //alert('Please answer the question.');
            this.setState({ studentAnswer: "Please Select Answer First!" }, () => {
                this.setState({ issubmitdialog: true, validationMessage: true, dButtonText: "Ok" })
            })
        }
    }

    submitFinalAnswer = (event) => {
        if (this.state.leftpanelbuttonhide) {

            const modalContent = (
                <div className='text-center modal-content'>
                    Your Homework is Submmitted.
                </div>
            );
            this.openModal(null,
                null,
                modalContent,
                null,
                false,
                true)

        }
        else {
            if (this.props.question.type !== 'FRQ') {
                this.setState({ loader: true })

                let question_id = this.props.question.id.slice(1, this.props.question.id.length);

                if (this.props.question.type.toLowerCase() === "text") {
                    this.setState({ loader: false })
                    let modalContent = null;
                    if (!isNil(this.userAnswer) && this.userAnswer !== undefined) {
                        this.state.isText = true
                        modalContent = (
                            <Aux>
                                <div className="progress-bar-container">
                                    <Spinner animation="border" role="status" />
                                </div>
                            </Aux>
                        )

                        this.setState({ checkMyAnswer: modalContent });
                        //    this.openModal(event,modalTitle, modalContent,modalCustomButton,false,true)                 
                        checkStudentAnswer(this.userAnswer, question_id, getCurrentUserId(), this.state.homeworkproblems_id, this.state.problem_source).then(res => {

                            if (res.status === 200) {
                                this.state.isText = true
                                var correctFacts = [];
                                var missingFacts = [];
                                var extraFacts = [];

                                const correct_step_feedback = ["Good job covering the following concepts. ", "Well done covering the following concepts.", "Well done outlining the key points related to following concepts: "];
                                let ind_correct_step_feedback = Math.floor(Math.random() * 3);
                                const correct_step_feedback_ending = ["! 😊", "! 👍"];
                                let ind_correct_step_feedback_ending = Math.floor(Math.random() * 2);

                                const missing_step_feedback = ["Consider covering the following concepts. It provides important context.", "Don't forget to mention the following concepts as it provides important context."];
                                let ind_missing_step_feedback = Math.floor(Math.random() * 2);
                                const missing_step_feedback_ending = [" ", " ", " "];
                                let ind_missing_step_feedback_ending = Math.floor(Math.random() * 3);

                                const extra_step_feedback = ["The following concepts are not necessary in the answer. ", "While  the following information is interesting, it may not be directly relevant to the question."];
                                let ind_extra_step_feedback = Math.floor(Math.random() * 2);
                                const extra_step_feedback_ending = ["", ""]
                                let ind_extra_step_feedback_ending = Math.floor(Math.random() * 2);

                                if (res.data.Covered.length > 0) {
                                    correctFacts.push(
                                        <div className="green">
                                            <CheckCircleIcon className='greenIcon' />
                                            {correct_step_feedback[ind_correct_step_feedback] + correct_step_feedback_ending[ind_correct_step_feedback_ending]}
                                        </div>
                                    )
                                    res.data.Covered.forEach(correct => {
                                        correctFacts.push(
                                            <p className='feedbackmodal p'>{correct}</p>
                                        )
                                    });
                                }

                                if (res.data.Missing.length > 0) {
                                    missingFacts.push(
                                        <div className="red">
                                            <CancelIcon className='redIcon' />
                                            {missing_step_feedback[ind_missing_step_feedback] + '' + missing_step_feedback_ending[ind_missing_step_feedback_ending]}
                                        </div>
                                    )
                                    res.data.Missing.forEach(missing => {

                                        let data = res.data.ConceptResource[missing]
                                        if (data !== undefined && data !== null && data.length > 0 && data !== "" && data !== 'undefined' && data !== 'null') {

                                            let conceptresourcediv = []
                                            data.forEach(item => {

                                                let dataitem
                                                dataitem = <li> <a href={item.ResourceUrl !== null && item.ResourceUrl !== undefined && item.ResourceUrl !== "" ? item.ResourceUrl : "#"}
                                                    target='_blank' rel="noopener noreferrer">
                                                    <div dangerouslySetInnerHTML={{ __html: item.ResourceText }}></div></a></li>

                                                conceptresourcediv.push(dataitem)
                                            })
                                            missingFacts.push(
                                                <>
                                                    <p className='feedbackmodal p' style={{ marginBottom: "2px" }}>{missing}</p>
                                                    <ul
                                                    //style={{ paddingLeft: "35px" }}
                                                    >{conceptresourcediv}</ul>
                                                    {/* <p style={{ paddingLeft: "15px" }}>{conceptresourcediv}</p> */}
                                                </>

                                            );

                                        }
                                        else {
                                            missingFacts.push(
                                                <p className='feedbackmodal p'>{missing}</p>

                                            );
                                        }
                                    })
                                }

                                if (res.data.Other.length > 0) {
                                    extraFacts.push(
                                        <div className='gray'>
                                            <DnsIcon className='grayIcon' />
                                            {extra_step_feedback[ind_extra_step_feedback] + '' + extra_step_feedback_ending[ind_extra_step_feedback_ending]}
                                        </div>
                                    )
                                    res.data.Other.forEach(extra => {
                                        extraFacts.push(
                                            <p className='feedbackmodal p'>{extra}</p>
                                        )
                                    });
                                }

                                modalContent = (
                                    <div className='p-1' >
                                        <div className='showSolutionTitle'>
                                            Your Answer
                                            <a className='float-right text-secondary' onClick={(e) => this.closeAnswerDeviders("checkMyAnswer")}>
                                                {/* <i class="fa fa-close" aria-hidden="true"></i> */}
                                                <CloseIcon className='' />
                                            </a>
                                        </div>

                                        <div className='modal-content divFacts'>
                                            {(correctFacts.length > 0 ? (Object.values(correctFacts)) : "")}
                                        </div>
                                        <div className='modal-content divFacts'>
                                            {(missingFacts.length > 0 ? (Object.values(missingFacts)) : "")}
                                        </div>
                                        <div className='modal-content divFacts'>
                                            {(extraFacts.length > 0 ? (Object.values(extraFacts)) : "")}
                                        </div>

                                    </div>
                                )

                                this.setState({ checkMyAnswer: modalContent });
                                //    this.openModal(event,modalTitle, modalContent,modalCustomButton,false,true)                    
                            }
                        }).catch(err => {

                            this.setState({ loader: false })
                            this.closeAnswerDeviders("checkMyAnswer")
                            console.error(err.message);
                            alert('Error occurred while submitting the answer.');
                        })
                    }
                    else {
                        // alert('Please enter the answer.');
                        this.setState({ studentAnswer: "Please Select Answer First!" }, () => {
                            this.setState({ issubmitdialog: true, validationMessage: true, dButtonText: "Ok" })
                        })
                    }
                }
                else {
                    // this.setState({ loader: true })
                    if (this.userAnswer || this.props.question.type === 'Ranking') {

                        if (this.props.question.type === 'Ranking') {
                            this.userAnswer = this.state.rankingOptionList.map(item => item.name)
                        }

                        let reqData = {
                            userAnswer: this.userAnswer,
                            questionId: question_id,
                            user_id: getCurrentUserId(),
                            homeworkproblems_id: this.state.homeworkproblems_id,
                            hints_used: this.state.nonfrqhintdata,
                            boardId: this.props.location.state.boardId,
                            problem_source: this.state.problem_source,
                            final_submission: 0,
                            optionselected: 0,
                            qtype: this.props.question.type,
                            fromTestPaper: 0
                        }
                        checkChallengeAnswer(reqData).then(res => {
                            this.setState({ loader: false })
                            let modalContent = null;
                            let modalTitle = '';
                            let modalCustomButton = null;
                            if (res.data.status === 'correct') {
                                modalContent = (
                                    <div className='answer-model-correct d-flex justify-content-center'>
                                        <span className='correct-answer mr-2'></span>
                                        <h5>Correct Answer</h5>
                                    </div>
                                )
                            } else {

                                modalTitle = (
                                    <div className='d-flex align-items-center'>
                                        <span className='incorrect-answer mr-2'></span>
                                        <h4 className='m-0'>"This isn’t the correct answer."</h4>
                                    </div>);
                                modalContent = (
                                    <div className='answer-modal-incorrect'>
                                        {
                                            res.data.data !== undefined && res.data.data.length > 0 ? (
                                                res.data.data.map(item => {
                                                    return (
                                                        <>
                                                            {
                                                                item.remedial_text !== "" && item.remedial_text !== '' && item.remedial_text !== null ? (
                                                                    <>
                                                                        <h5>Explanation:</h5>
                                                                        <span className='d-block'>
                                                                            <p dangerouslySetInnerHTML={{ __html: item.remedial_text }}></p>
                                                                        </span>
                                                                    </>) : null
                                                            }
                                                            {
                                                                item.remedial_source_url !== "" && item.remedial_source_url !== '' && item.remedial_source_url !== null ?
                                                                    (<>
                                                                        <h5>Recommended Resources:</h5>
                                                                        <p className='mb-4'>
                                                                            <a href={item.remedial_source_url} target='_blank' rel="noopener noreferrer">
                                                                                <p dangerouslySetInnerHTML={{ __html: item.remedial_source_title ? item.remedial_source_title : item.remedial_source_url }}></p></a></p>
                                                                    </>) : null
                                                            }


                                                        </>
                                                    )
                                                })

                                            ) : null
                                        }

                                    </div>
                                )

                            }

                            this.openModal(event,
                                modalTitle,
                                modalContent,
                                modalCustomButton,
                                false,
                                true);
                            // this.submitModalClose = true;
                        }).catch(err => {
                            this.setState({ loader: false })
                            console.error(err.message);
                            alert('Error occurred while submitting the answer.');
                        })
                    } else {
                        this.setState({ loader: false })
                        //alert('Please answer the question.');
                        this.setState({ studentAnswer: "Please Select Answer First!" }, () => {
                            this.setState({ issubmitdialog: true, validationMessage: true, dButtonText: "Ok" })
                        })

                    }
                }
            }
            else {
                // debugger;
                this.setState({ loader: true })
                let modalBody = null
                let customButton = null;
                if (this.props.location.state) {
                    // if (this.props.location.state.challengeCompleted) {
                    // debugger;
                    let searchParams = new URLSearchParams(this.props.location.search);
                    let teacherStudentId = searchParams.get('student')
                    checkEquation({
                        'board': this.props.location.state.boardId,
                        'userId': this.props.teacher ? teacherStudentId : getCurrentUserId(),
                        'challengeMapId': this.props.location.state.challengeMapId,
                        'hintUsed': this.props.location.state.hintUsed ? 1 : 0,
                        'is_finalsubmission': 1,
                        'homeworkproblems_id': this.state.homeworkproblems_id,
                        'problem_source': this.state.problem_source
                    }).then(async res => {
                        this.setState({ loader: false })
                        if (this.state.problem_source === Problem_source.apptesthomework) {
                            toast("Your PT Test problem is submitted successfully!", {
                                type: toast.TYPE.INFO,
                                position: toast.POSITION.TOP_RIGHT
                            })
                        }
                        else if (this.state.problem_source === Problem_source.homework) {
                            toast("Your Homework is submitted successfully!", {
                                type: toast.TYPE.INFO,
                                position: toast.POSITION.TOP_RIGHT
                            })
                        }
                        else {
                            toast("Your Homework is submitted successfully!", {
                                type: toast.TYPE.INFO,
                                position: toast.POSITION.TOP_RIGHT
                            })
                        }
                    })

                } else {
                    modalBody = (
                        <p>Challenge is not completed. Are you sure you want to submit?</p>
                    )
                    customButton = (
                        <div className='modal-button text-center'>
                            <Button clicked={() => this.questionChallengeClickHandler('no')} className="mr-2 yellow-btn">No</Button>
                            <Button clicked={() => this.questionChallengeClickHandler('yes')} className="mr-2 yellow-btn">Yes</Button>
                        </div>
                    )
                    this.openModal(event, 'Submit Final Answer', modalBody, customButton, customButton ? null : true, false);
                }
            }
        }
    }

    getHint = (event) => {
        this.setState({ loader: true, bannerOpen: false })
        if (this.props.location.state.challengeMapId) {
            if (this.props.question.type === 'FRQ') {
                let reqData = {
                    boardId: this.props.location.state.boardId,
                    user_id: getCurrentUserId(),
                    challengeMapId: this.props.location.state.challengeMapId,
                    hintCount: this.state.currentHintIndex,
                    prevsiousHintRemedialText: this.state.hintRemedialText,
                    problem_source: this.state.problem_source
                }
                fetchChallengeHint({ params: reqData }).then(res => {
                    this.setState({ loader: false })
                    console.log("********************HINT")
                    console.log(this.state.hintLastMatchedCheckpointId)
                    console.log(res.data.last_matched_checkpoint_id)

                    let finalsubstitutionslist = ""
                    if (!isNil(res.data.substitution_map)) {
                        finalsubstitutionslist = obtainSubstitution(res.data.substitution_map)
                    }

                    if (res.data.hint_remedial_text || res.data.hint_remedial_source) {
                        if (!(this.state.hintLastMatchedCheckpointId === res.data.last_matched_checkpoint_id)) {
                            console.log("HINT1")
                            this.setState((state, props) => ({
                                currentHintIndex: 1,
                                hintRemedialText: res.data.hint_remedial_text,
                                hintRemedialSource: res.data.hint_remedial_source,
                                hintLastMatchedCheckpointId: res.data.last_matched_checkpoint_id,
                                totalHints: res.data.total_hints_for_the_challenge,
                            }));
                        }
                        else if (this.state.hintLastMatchedCheckpointId ===
                            res.data.last_matched_checkpoint_id)// && !(this.state.hintRemedialSource === res.data.hint_remedial_source && this.state.hintRemedialText === res.data.hint_remedial_text))
                        {
                            console.log("HINT2");
                            this.setState((state, props) => ({
                                currentHintIndex: res.data.total_hints_for_the_challenge > state.currentHintIndex ? state.currentHintIndex + 1 : state.currentHintIndex,
                                hintRemedialText: res.data.hint_remedial_text,
                                hintRemedialSource: res.data.hint_remedial_source,
                                hintLastMatchedCheckpointId: res.data.last_matched_checkpoint_id,
                                totalHints: res.data.total_hints_for_the_challenge,
                            }));

                        }

                        let modalBody = (
                            <Aux>
                                <p className='modal-p' dangerouslySetInnerHTML={{ __html: res.data.hint_remedial_text ? res.data.hint_remedial_text : '' }}></p>
                                <a className='mt-2' href={res.data.hint_remedial_source} target='_blank' rel="noopener noreferrer" >{res.data.hint_remedial_resource_display_name ? res.data.hint_remedial_resource_display_name : res.data.hint_remedial_source}</a>

                                {
                                    finalsubstitutionslist !== "" && finalsubstitutionslist !== '' && finalsubstitutionslist !== undefined && finalsubstitutionslist !== null && finalsubstitutionslist !== "null" && finalsubstitutionslist !== "null" ? (
                                        <>
                                            <div style={{ fontSize: "11px", marginTop: "10px", maxWidth: "500px" }}>
                                                {substituteText} {finalsubstitutionslist}
                                            </div>

                                        </>
                                    ) : null
                                }
                            </Aux>
                        );

                        this.openModal(event, 'Hint', modalBody, null, true, true);

                        if (this.props.location.state.hintUsed) {
                            this.props.location.state.hintUsed = true;
                        } else {
                            this.props.location.state = {
                                ...this.props.location.state,
                                hintUsed: true
                            }
                        }
                    } else if (res.data.message) {
                        toast(res.data.message, {
                            type: toast.TYPE.INFO,
                            position: toast.POSITION.BOTTOM_RIGHT
                        })
                    }
                }).catch(err => {
                    this.setState({ loader: false })
                    console.error(err.message);
                    // alert('Error occurred while fetching the hint')
                    toast("No more hints found!", {
                        type: toast.TYPE.INFO,
                        position: toast.POSITION.BOTTOM_RIGHT
                    })
                })
            }
            else {
                let reqData = {
                    boardId: this.props.location.state.boardId,
                    user_id: getCurrentUserId(),
                    challengeMapId: this.props.location.state.challengeMapId,
                    problem_source: this.state.problem_source
                }
                fetchChallengeHintnonfrq({ params: reqData }).then(res => {
                    this.setState({ loader: false })
                    if (res.data !== undefined && res.data !== null && res.data.length > 0) {
                        let contentdatafinal = []
                        this.setState({ nonfrqhintdata: res.data })

                        if (res.data[0].remedial_text === "" || res.data[0].remedial_text === null || res.data[0].remedial_text === undefined) {
                            toast("No more hints found!", {
                                type: toast.TYPE.INFO,
                                position: toast.POSITION.BOTTOM_RIGHT
                            })
                        }
                        else {
                            res.data.forEach((rem) => {
                                let contentdata = (
                                    <Aux>
                                        <p className='modal-p mt-3' dangerouslySetInnerHTML={{ __html: rem.remedial_text ? rem.remedial_text : '' }}></p>
                                        <a className='mt-2 ' href={rem.remedial_source_url} target='_blank' rel="noopener noreferrer" >{rem.remedial_source_title ? rem.remedial_source_title : rem.remedial_source_url}</a>
                                    </Aux>
                                )
                                contentdatafinal.push(contentdata)
                            })

                            let modalBody = (
                                <>{contentdatafinal}</>
                            );
                            this.openModal(event, 'Hint', modalBody, null, true, true);
                        }


                        if (this.props.location.state.hintUsed) {
                            this.props.location.state.hintUsed = true;
                        } else {
                            this.props.location.state = {
                                ...this.props.location.state,
                                hintUsed: true
                            }
                        }
                    } else if (res.data.message) {
                        toast(res.data.message, {
                            type: toast.TYPE.INFO,
                            position: toast.POSITION.BOTTOM_RIGHT
                        })
                    }
                }).catch(err => {
                    console.error(err.message);
                    this.setState({ loader: false })
                    // alert('Error occurred while fetching the hint')
                    toast("No more hints found!", {
                        type: toast.TYPE.INFO,
                        position: toast.POSITION.BOTTOM_RIGHT
                    })
                })
            }
        }


    }

    componentWillUnmount() {
        if (this.loadTranscriptTimer) {
            clearInterval(this.loadTranscriptTimer)
        }
        try {
            window.$(document).unbind('scroll');
        } catch (e) {
            console.log(e.message);
        }
    }
    listenScrollEvent = () => {
        // Keep buttons above footer
        var socialFloat = document.querySelector('.whiteboard-bottom-buttons')
        var footer = document.querySelector('.footer');

        function getRectTop(el) {
            var rect = el.getBoundingClientRect();
            return rect.top;
        }

        if (socialFloat && footer) {
            if ((getRectTop(socialFloat) + document.body.scrollTop) + socialFloat.offsetHeight >= (getRectTop(footer) + document.body.scrollTop) - 10)
                socialFloat.style.position = 'absolute';
            if (document.body.scrollTop + window.innerHeight < (getRectTop(footer) + document.body.scrollTop))
                socialFloat.style.position = 'fixed'; // restore when scroll up}
        }
    }

    showNextStepHandler = async (ev) => {

        this.setState({ loader: true, bannerOpen: false })
        let hasNextStep = true;
        const nextStepCounter = this.state.nextStepCounter;
        if (nextStepCounter != 0) {
            if (nextStepCounter >= this.state.totalStepCounter) {
                hasNextStep = false;
                toast('No next step available.', {
                    type: toast.TYPE.INFO,
                    position: toast.POSITION.BOTTOM_RIGHT,
                    autoClose: 1500
                })
            }
        }
        if (!hasNextStep) return;
        const params = new URLSearchParams(window.location.search);
        this.progressSidebarContent = null;
        // Uncomment when use aww board
        // const boardId = params.get('awwBoard');

        const boardId = this.props.location.state.boardId;
        const challengeMapId = this.props.location.state.challengeMapId;


        let content = null;
        let finalsubstitutionslistnext = ""
        if (boardId && challengeMapId && hasNextStep) {
            let reqData = {
                'userId': getCurrentUserId(),
                'board': boardId,
                'challengeMapId': challengeMapId,
                'currentstepno': nextStepCounter,
                'problem_source': this.state.problem_source
            }
            let latex = null
            let explanation = null

            if (this.props.location.state.challengeCompleted) {
                this.setState({ loader: false })
                content = <p>No step remaining.</p>
            } else {
                await getNextStep(reqData).then(res => {
                    this.setState({ loader: false })
                    if (res.status === 200) {
                        latex = res.data.nextStep;
                        explanation = parse(res.data.explanation);

                        if (!isNil(res.data.substitute_map)) {
                            finalsubstitutionslistnext = obtainSubstitution(res.data.substitute_map)
                        }

                        this.setState({ nextStepCounter: this.state.nextStepCounter + 1, totalStepCounter: res.data.totalstepsno })
                    } else if (res.status === 204) {
                        content = <p>No next step to display.</p>
                    }

                }).catch(err => {
                    console.error(err.message)
                })
                if (latex) {
                    const latexReqData = {
                        params: {
                            format: 'svg',
                            fontSize: '18px',
                            latex: latex
                        }
                    }
                    const response = await generateSVGFromMathML(latexReqData)
                    if (response.status === 200) {
                        const svg = response.data;
                        const base64 = this.svg2img(svg);
                        content = (
                            <>
                                {/* <MathJax.Context
                                    input='ascii'
                                    onError={(MathJax, error) => {
                                        console.warn(error);
                                        console.log("Encountered a MathJax error, re-attempting a typeset!");
                                        MathJax.Hub.Queue(
                                            MathJax.Hub.Typeset()
                                        );
                                    }}
                                    script="https://cdnjs.cloudflare.com/ajax/libs/mathjax/2.7.4/MathJax.js?config=default"
                                    options={{
                                        asciimath2jax: {
                                            useMathMLspacing: true,
                                            delimiters: [["$$", "$$"]],
                                            preview: "none",
                                        }
                                    }}>
                                    <MathJax.Text text={explanation} />


                                </MathJax.Context> */}

                                <span>{explanation}</span>
                                <MathJaxRenderer math={'$$' + latex + '$$'}></MathJaxRenderer>

                                {/* <MathpixLoader>
                                        <MathpixMarkdown text={explanation} />
                                    </MathpixLoader>                                   */}
                                {/* <img src={base64} alt="Next Step Equation" className='mt-2' style={{ width: 'max-content' }} /> */}



                            </>
                        )
                    } else {
                        alert("Some error occurred.");
                        console.error('Unable to convert latex to svg');
                    }

                }
            }



            if (content) {
                this.progressSidebarContent = (
                    <div className='d-flex flex-column mx-3 my-3'>
                        <h5><u>Next Step</u>:</h5>
                        {content}
                        {
                            finalsubstitutionslistnext !== "" && finalsubstitutionslistnext !== '' && finalsubstitutionslistnext !== undefined && finalsubstitutionslistnext !== null && finalsubstitutionslistnext !== "null" && finalsubstitutionslistnext !== "null" ? (
                                <>
                                    <div style={{ fontSize: "11px", marginTop: "10px", maxWidth: "500px" }}>
                                        {substituteText} {finalsubstitutionslistnext}
                                    </div>

                                </>
                            ) : null
                        }
                    </div>
                )
            }
            if (this.progressSidebarContent !== null) {
                this.setState((prevState) => ({ showProgressSidebar: true }));
            }
        }
    }

    showEntireSolution = async (ev) => {

        this.resetNextStepCounter();
        this.setState({ loader: true, bannerOpen: false })
        //ev.preventDefault();
        let content = null;
        let remedial_resources = []
        const challengeMapId = this.props.location.state.challengeMapId;
        this.progressSidebarContent = null;

        if (this.props.question.type.toLowerCase() === "text") {
            let challengeId = this.props.question.id.slice(1, this.props.question.id.length);
            const reqData = { params: { 'ChallengeId': challengeId } }

            this.state.isText = true
            content = (
                <Aux>
                    <div className="progress-bar-container">
                        <Spinner animation="border" role="status" />
                    </div>
                </Aux>
            )

            this.setState({ showSolution: content });


            getTutorAnswer(reqData).then(res => {
                this.setState({ loader: false })
                if (res.status === 200) {
                    this.state.isText = true
                    if (res.data.Answers.length > 0) {
                        let answerData = res.data.Answers[0];
                        content = (
                            <div className='bg-light p-1' >
                                <div className='showSolutionTitle'>
                                    Model Answer
                                    <a className='float-right text-secondary' onClick={(e) => this.closeAnswerDeviders("showSolution")}>
                                        {/* <i class="fa fa-close" aria-hidden="true"></i> */}
                                        <CloseIcon className='' />
                                    </a>
                                </div>
                                <div className='divFacts'>
                                    {(answerData.Answer !== null ? answerData.Answer.split('\n').map(paragraph =>
                                        <p className='feedbackmodal p'>
                                            {paragraph.split('\n').reduce((total, line) => [total, <br />, line])}
                                        </p>
                                    ) : "")}
                                </div>
                                {/* <div style={{ marginBottom: '0.5rem!important', border: '#ccc solid 1px', marginTop: '0.5rem!important' }}>
                                </div> */}
                                <div style={{ width: '100%', height: '100%' }} className='graphdiv'>
                                    <div className='showSolutionTitle border-0'> Graph View : </div>
                                    {(answerData.Nodes !== null && answerData.Edges !== null ? <GraphComponent isPT={false} isauthordashboard={false} componentId="showSolution" chartType="basic" nodes={JSON.parse(answerData.Nodes)} edges={JSON.parse(answerData.Edges)}></GraphComponent> : "")}
                                </div>
                            </div>
                        )

                        this.setState({ showSolution: content });
                        //this.openModal(ev,"Solution",content,null,false,true)               
                    }
                }
            }).catch(err => {
                this.setState({ loader: false })
                this.closeAnswerDeviders("showSolution")
                console.error(err.message);
                alert('Error occurred while showing solution.');
            })
        }
        else {
            if (challengeMapId) {
                let allEquations = null;
                let challengeType = null;
                let ui_json;
                await getEntireSolution({
                    params: {
                        'challengeMapId': challengeMapId,
                        'user_id': getCurrentUserId(),
                        'problem_source': this.state.problem_source,
                        'boardId': this.props.location.state.boardId
                    }
                }).then(res => {
                    this.setState({ loader: false })
                    ui_json = res.data.ui_json;
                    remedial_resources = res.data.remedial_resources;
                    challengeType = res.data.challengeType;
                    if (res.data.challengeType !== 'frq') {
                        let correctAnswer = null;
                        if (res.data.challengeType === 'mcq') {
                            correctAnswer = res.data.solution[0]
                        } else if (res.data.challengeType === 'msq') {
                            correctAnswer = (
                                <ul key='answer'>
                                    {res.data.solution.map((ans, index) => <li key={'msq' + index}>{ans}</li>)}
                                </ul>
                            )
                        } else if (res.data.challengeType === 'ranking') {
                            correctAnswer = (
                                <ol>
                                    {res.data.solution.map((ans, index) => <li key={'ranking' + index}>{ans}</li>)}
                                </ol>
                            )
                        }
                        content = correctAnswer;
                    } else {
                        allEquations = res.data.solution;
                    }

                }).catch(err => {
                    this.setState({ loader: false })
                    console.error(err.message);
                })


                if (allEquations && challengeType === "frq") {
                    let imgOfSolution = []
                    for (let equation of allEquations) {
                        const latexReqData = {
                            params: {
                                format: 'svg',
                                fontSize: '18px',
                                latex: equation.latex
                            }
                        }
                        const response = await generateSVGFromMathML(latexReqData)
                        if (response.status === 200) {
                            const svg = response.data;
                            imgOfSolution.push({
                                image: this.svg2img(svg),
                                explanation: equation.explanation
                            });
                        } else {
                            console.error('Unable to convert latex to svg');
                        }
                    }
                    content = (
                        <Aux>

                            <div className="solutionGraphCotainer">
                                <p className="sidebar-heading mt-2">Solution Graph</p>
                                <div className="legendContainer">
                                    <div className="legendItemContainer">
                                        <div className="correct"></div>
                                        <span>Expected Step</span>
                                    </div>

                                    <div className="legendItemContainer">
                                        <div className="optional"></div>
                                        <span>Intermediate Step</span>
                                    </div>
                                </div>
                                <Flow readOnly={true} uiJson={ui_json} hasCheckMyWOrk={false} ></Flow>
                            </div>

                        </Aux>
                    )

                } else if (content === null) {
                    content = (
                        <p>No solutions found</p>
                    )
                    // Show no solutions found
                }

                if (challengeType === 'frq') {
                    this.progressSidebarContent = (
                        <div className='d-flex flex-column mx-3 my-3 solutionContainer'>
                            <h5 className='mb-2'><u>Entire Solution</u>:</h5>
                            {content}
                        </div>
                    )
                } else {
                    this.progressSidebarContent = (
                        <div className='d-flex flex-column mx-3 my-3'>
                            <h5 className='mb-2'><u>Correct Answer</u>:</h5>
                            {content}

                            {remedial_resources !== null && remedial_resources.length > 0 ?
                                <Aux>
                                    <h5 className='mb-2 mt-3'><u>Resources</u>:</h5>
                                    {remedial_resources.map(resource => {

                                        if (resource.remedial_source_url !== null && resource.remedial_source_url.length > 0) {
                                            return <a href={resource.remedial_source_url} target="_blank" rel="noopener noreferrer"><p dangerouslySetInnerHTML={{ __html: resource.remedial_source_title }}></p></a>
                                        }
                                        return null
                                    })}
                                </Aux>
                                : null}
                        </div>
                    )
                }

            }
            if (this.progressSidebarContent !== null) {
                this.setState((prevState) => ({ showProgressSidebar: true }));
            }
        }


    }

    onEquationSelectListener = (imagePath, mathMl) => {
        if (imagePath && mathMl) {
            this.setState({
                selectedEquation: {
                    imagePath,
                    mathMl,
                }
            });
        } else {
            this.setState({ selectedEquation: undefined });
        }
    }

    showreviewdModalclose = () => {
        this.setState({ showreviewdModal: false })
    }

    showreviewdnonFRQModalclose = () => {
        this.setState({ showreviewdModalnonFRQ: false })
    }

    showDiagnosticQuestion = (event, diagnosticObj) => {
        const reqParams = {
            params: {
                checkpointId: diagnosticObj.checkpointId,
                challengeId: diagnosticObj.challengeId,
            }
        }

        this.showLoadingModal(event, "Diagnostic Question")

        fetchDiagnosticQuestions(reqParams)
            .then(res => {
                if (res.data.diagnosticQuestions.length > 0) {
                    this.setState({
                        diagnosticQuestions: res.data.diagnosticQuestions,
                        diagnosticQuestionIndex: 0,
                        diagnosticQuestionOption: undefined,
                    })

                    this.showDiagnosticQuestionModal(event, res.data.diagnosticQuestions[0])
                }
            }).catch(err => {
                console.error(err.message);
                alert('Error occurred while submitting the answer.');
            })
    }
    closeDialogHandler = () => {
        this.setState({ issubmitdialog: false })
    }
    calculategradinghandler = (e, bText) => {
        if (bText === "Yes") {
            this.setState({ loader: true })
            let question_id = this.props.question.id.slice(1, this.props.question.id.length);
            let reqData = {
                userAnswer: this.userAnswer,
                questionId: question_id,
                user_id: getCurrentUserId(),
                homeworkproblems_id: this.state.homeworkproblems_id,
                hints_used: this.state.nonfrqhintdata,
                problem_source: this.state.problem_source,
                boardId: this.props.location.state.boardId,
                final_submission: 1,
                optionselected: 1,
                qtype: this.props.question.type,
                fromTestPaper: 0
            }
            checkChallengeAnswer(reqData).then(res => {
                this.setState({ loader: false })
                if (this.state.problem_source === Problem_source.apptesthomework) {
                    toast("Your PT Test problem is submitted successfully!", {
                        type: toast.TYPE.INFO,
                        position: toast.POSITION.TOP_RIGHT
                    })
                }
                else if (this.state.problem_source === Problem_source.homework) {
                    toast("Your Homework is submitted successfully!", {
                        type: toast.TYPE.INFO,
                        position: toast.POSITION.TOP_RIGHT
                    })
                }
                else {
                    toast("Your Homework is submitted successfully!", {
                        type: toast.TYPE.INFO,
                        position: toast.POSITION.TOP_RIGHT
                    })
                }

            }).catch(err => {
                this.setState({ loader: false })
                console.error(err.message);
                alert('Error occurred while submitting the answer.');
            })

        }
        this.setState({ issubmitdialog: false })
    }
    closeBanner = () => {

        this.setState({ bannerOpen: false })
    }
    feedbackLinkHandler = () => {
        this.setState({ bannerOpen: false }, () => {
            let url = configData.WEBSITE_URL + "" + "feedback";
            window.open(url, '_blank');
        })

    }

    render() {
        const totalHints = this.state.totalHints
        const currentHint = this.state.currentHintIndex

        if (this.props.question.custom && !this.props.location.state) {
            this.props.location.state = {}
        }


        if (!this.props.question.custom) {
            const ques = this.props.question.question_image;
            this.props.location.state = {
                ...this.props.location.state,
                image: ques
            };
        }

        let options = [];
        let rankingOptions = null;
        if (!this.props.teacher) {
            if (this.props.question.type === 'MCQ') {
                this.props.question.options.map((opt, index) => {
                    options.push(
                        <div key={'d' + index} style={{ display: "flex", justifyContent: "flex-start" }} className="mt-2">
                            <input
                                type='radio'
                                name='option'
                                id={'opt' + index}
                                key={'o' + index}
                                value={opt}
                                className='mr-2'
                                checked={this.state.mcqcorrectAnswerReview === opt}
                                onChange={(event) => this.mcqChangeHandler(event, opt.text)} />
                            <label htmlFor={'opt' + index} key={'l' + index}>{parse(opt)}</label>
                        </div>
                    );
                })
            } else if (this.props.question.type === 'MSQ') {
                this.props.question.options.map((opt, index) => {

                    let selectionans = this.state.selectedAnswersByUser
                    if (selectionans.includes("###")) {
                        let str = selectionans.split("###")
                        // debugger;
                        str.forEach((rem) => {
                            if (parseInt(opt.id) === parseInt(rem)) {
                                selectionans = rem.trim()
                            }
                        })
                    }
                    // debugger;
                    options.push(
                        <div key={'d' + index} style={{ display: "flex", justifyContent: "flex-start" }}>
                            <input
                                type='checkbox'
                                name={'choice' + index}
                                id={'opt' + index}
                                key={'o' + index}
                                value={opt}
                                className='mr-2'
                                checked={parseInt(selectionans) === parseInt(opt.id)}
                                onChange={(event) => this.msqChangeHandler(event, index, opt.text)} />
                            <label htmlFor={'opt' + index} key={'l' + index}>{parse(opt)}</label>
                        </div>
                    );
                })
            } else if (this.props.question.type === 'Ranking') {
                rankingOptions = (
                    <ReactSortable
                        list={this.state.rankingOptionList}
                        setList={newState => this.setState({ rankingOptionList: newState })}
                        animation={150}
                        id='ranking-selector'
                        ghostClass='blue-background-class'
                        className='d-flex flex-column list-group-ranking ml-5 mr-5'
                    >
                        {
                            this.state.rankingOptionList.map(item => (
                                <div key={item.id} className='list-group-item-ranking'>{item.name}</div>
                            ))
                        }
                    </ReactSortable >
                );
            }
        }

        let shouldShowHint = true

        if (this.props.question.type === 'FRQ') {
            // shouldShowHint = this.state.currentHintIndex >= totalHints ? true : false
            shouldShowHint = false
        }

        const idleEvents = [
            //'mousemove',
            'keydown',
            'wheel',
            'DOMMouseScroll',
            'mouseWheel',
            'mousedown',
            'touchstart',
            'touchmove',
            'MSPointerDown',
            'MSPointerMove',
            'visibilitychange'
        ]
        const timeForIdle = 1000 * 60 * 2 // in Milliseconds
        const isText = this.state.isText
        const checkMyAnswer = this.state.checkMyAnswer;
        const showSolution = this.state.showSolution;
        return (
            <>
                <Dialog
                    hideBackdrop={false}
                    open={this.state.issubmitdialog}
                    onClose={this.closeDialogHandler}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                >

                    <DialogContent>
                        <DialogContentText id="alert-dialog-description">

                            {this.state.studentAnswer}
                            {
                                !this.state.validationMessage ? (
                                    <div style={{ marginTop: "10px", color: "#164b99" }}>
                                        Do you want to submit this?
                                    </div>
                                ) : null
                            }
                        </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                        <button className="dragLabelcancel leftmargin" onClick={(e) => this.calculategradinghandler(e, this.state.dButtonText)}>
                            {this.state.dButtonText}
                        </button>
                        {
                            !this.state.validationMessage ? (
                                <button className="dragLabelcancel leftmargin" onClick={this.closeDialogHandler}>
                                    No
                                </button>
                            ) : null
                        }

                    </DialogActions>
                </Dialog>
                <Modal
                    show={this.state.showreviewdModalnonFRQ}
                    onHide={this.showreviewdnonFRQModalclose}
                    draggable={false}
                    popUpSize="medium"
                    body=
                    {
                        this.state.homeworknonfrqreviewdata !== undefined && this.state.homeworknonfrqreviewdata !== null && this.state.homeworknonfrqreviewdata.length > 0 ?
                            (
                                <>
                                    <div className='w-100 d-flex bg-white pr-2' >
                                        <div className={isText ? 'div40' : 'div100'}>
                                            {this.state.homeworknonfrqreviewdata[0].isgraded ?
                                                <div style={{ paddingLeft: "20px", background: "#fff" }} className='popupSectionTitle'>
                                                    Total Points: {this.state.homeworknonfrqreviewdata[0].totalgrade} /{parseFloat(this.state.homeworknonfrqreviewdata[0].totalnonfrqpoints).toFixed(2)}
                                                </div>
                                                : null}
                                            {
                                                !this.state.homeworknonfrqreviewdata[0].is_review_complete_common && this.pSource === Problem_source.homework ? (
                                                    <div className='popupSectionTitlemediumwithoutborder' style={{ paddingLeft: "40px", background: "#fff", marginTop: "10px", marginBottom: "10px" }}>
                                                        Your homework is submitted, review is pending
                                                    </div>
                                                ) : (

                                                    null
                                                )
                                            }
                                            {
                                                this.state.homeworknonfrqreviewdata[0].children !== undefined && this.state.homeworknonfrqreviewdata[0].children !== null && this.state.homeworknonfrqreviewdata[0].children.length > 0 ?
                                                    (
                                                        this.state.homeworknonfrqreviewdata[0].children.map(item => {

                                                            if (this.state.homeworknonfrqreviewdata[0].children[0].challenge_type === "Text") {
                                                                return <HomeworkReviewDetailNonFRQText item={item}></HomeworkReviewDetailNonFRQText>
                                                            }
                                                            else {
                                                                return <HomeworkReviewDetailNonFRQ item={item}></HomeworkReviewDetailNonFRQ>
                                                            }
                                                        })
                                                    ) : null

                                            }
                                        </div>
                                    </div>

                                </>
                            ) : null
                    }
                    handleClose={this.showreviewdnonFRQModalclose}
                    title="Last Submission: Review Detail"
                    showButton={false}
                    customButton={
                        <div className='modal-button text-center'>
                            <Button className="yellow-btn" clicked={this.showreviewdnonFRQModalclose}> Ok </Button>
                        </div>
                    }
                >
                </Modal>
                <Modal
                    show={this.state.showreviewdModal}
                    onHide={this.showreviewdModalclose}
                    draggable={false}
                    popUpSize="medium"
                    body=
                    {
                        this.state.homeworkreviewdata !== undefined && this.state.homeworkreviewdata.length > 0 ?
                            (
                                <>
                                    <div className='w-100 d-flex bg-white pr-2' >
                                        <div className={isText ? 'div40' : 'div100'}>
                                            {this.state.homeworkreviewdata[0].isgraded ?
                                                <div style={{ paddingLeft: "20px", background: "#fff" }} className='popupSectionTitle'>
                                                    Total Points: {this.state.homeworkreviewdata[0].totalgrade}/{this.state.homeworkreviewdata[0].totalpointscore}
                                                </div>
                                                : null}
                                            {
                                                !this.state.is_review_complete && this.pSource === Problem_source.homework ? (
                                                    <div className='popupSectionTitlemediumwithoutborder' style={{ paddingLeft: "40px", background: "#fff", marginTop: "10px", marginBottom: "10px" }}>
                                                        Your homework is submitted, review is pending
                                                    </div>
                                                ) : (

                                                    null
                                                )
                                            }
                                            {
                                                this.state.equationdata !== undefined && this.state.equationdata !== null && this.state.equationdata.length > 0 ?
                                                    (
                                                        this.state.equationdata.map(item => {
                                                            if (Number.isInteger(item.line_match)) {
                                                                return <HomeworkReviewDetail
                                                                    graded={this.state.homeworkreviewdata[0].isgraded}
                                                                    is_review_complete={this.state.is_review_complete}
                                                                    item={item}></HomeworkReviewDetail>
                                                            }

                                                        })
                                                    ) : null

                                            }
                                        </div>
                                    </div>
                                </>
                            ) : null
                    }
                    handleClose={this.showreviewdModalclose}
                    title="Last Submission: Review Detail"
                    showButton={false}
                    customButton={
                        <div className='modal-button text-center'>
                            <Button className="yellow-btn" clicked={this.showreviewdModalclose}> Ok </Button>
                        </div>
                    }
                >
                </Modal>
                <>
                    {
                        this.props.question.type === 'Text' && getCurrentUserRole() === User_Role.Student ? (<div
                            className='bg-white-with-shadow11'>
                            <div className=""
                                style={{ marginBottom: "30px", fontSize: "16px", marginTop: "20px" }}
                                dangerouslySetInnerHTML={{ __html: `For Subscription Users Only - <a href=${configData.APP_URL}signup>subscribe</a>` }} />
                        </div>) : null
                    }

                </>
                <div>
                    <>
                        <div style={{ marginTop: '0px !important' }}>
                            <Link
                                to={this.state.backPath}
                                style={{ textAlign: 'left !important', fontSize: "12px", boxShadow: "none", textDecoration: 'none', marginTop: '0px !important', paddingRight: "20px" }}>
                                <i class="fa fa-back" style={{ fontSize: "12px", boxShadow: "none", textDecoration: 'none', }}></i> Back</Link>
                        </div>
                        <div
                            className={`probStatementContainer mt-1 
                            ${this.props.question.type === 'Text' && getCurrentUserRole() === User_Role.Student ? "disabledDivText"
                                    : this.state.mainDivDisabled ? "disabledDivText" : ""}`}>
                            <div style={{ paddingBottom: '5px' }} className="probStatement">
                                <Accordion
                                >
                                    <AccordionSummary
                                        expandIcon={<ExpandMoreIcon />}
                                        aria-controls="panel1a-content"
                                        id="panel1a-header"
                                    >
                                        <Typography>
                                            <div style={{ fontFamily: 'inherit', fontSize: '13px', fontWeight: '500' }}>
                                                <p> {this.props.question.section_name} - {this.props.question.title}</p>
                                                {this.props.question.sub_section_name ?
                                                    <div>
                                                        {this.props.question.title ?
                                                            <>
                                                                {
                                                                    parseInt(this.props.question.ismultipart) === 1 ?
                                                                        (
                                                                            <div className='pmstatementdiv'>Statement<span style={{ fontSize: "12px !important" }} className='pmstatement' id="divproblemstatement"
                                                                                dangerouslySetInnerHTML={{ __html: this.props.question.problem_statement }}>
                                                                            </span></div>
                                                                        ) : null
                                                                }
                                                            </>
                                                            : null}
                                                    </div>
                                                    : null
                                                }
                                            </div>
                                        </Typography>
                                    </AccordionSummary>
                                    <AccordionDetails>
                                        <Typography>
                                            <Row className='mx-0 mb-2 divflex'>
                                                <Col sm="9">
                                                    <div className='curiosity-title'>
                                                        {/* {this.props.question.section_name ?
                                                            <h5 className='breadcrumbH5 pl-0'>                                                              
                                                                {
                                                                    parseInt(this.props.question.ismultipart) === 1 ?
                                                                        (
                                                                            <div className='pmstatementdiv'>Statement<span style={{ fontSize: "12px !important" }} className='pmstatement' id="divproblemstatement"
                                                                                dangerouslySetInnerHTML={{ __html: this.props.question.problem_statement }}>
                                                                            </span></div>
                                                                        ) : null
                                                                }

                                                            </h5>
                                                            : null
                                                        } */}
                                                        {this.props.question.type !== 'FRQ' && !this.props.teacher && !this.props.question.custom ?
                                                            this.props.question.type === 'MCQ' ?
                                                                <p class="first">Multiple Choice Question: Please select your answer.</p>
                                                                : this.props.question.type === 'MSQ' ?
                                                                    <p class="first">Multiple Select Question. Please select all choices that apply.</p>
                                                                    : this.props.question.type === 'Ranking' ?
                                                                        <p class="first">Ranking Problem: Please drag and drop blocks in the right order</p>
                                                                        :
                                                                        null
                                                            : null
                                                        }
                                                        <strong className='questiondescription'>Description:</strong>
                                                        <h5 class="mobileviewSpec" style={{ fontSize: "12px !important" }} dangerouslySetInnerHTML={{ __html: this.props.question ? this.props.question.question : '' }}></h5>
                                                    </div>

                                                </Col>
                                                {this.props.question.question_image ?
                                                    <Col sm="3">
                                                        <img src={imgURL + this.props.question.question_image} alt="Question Image" className='question-image-answerboardmobile' />
                                                    </Col>
                                                    : null}
                                            </Row>
                                        </Typography>
                                    </AccordionDetails>
                                </Accordion>
                                {
                                    this.props.question.type !== 'FRQ' ? (<div className="mcqoptionscards mt-2">
                                        {this.props.question.flag == "ALPHA" ?
                                            <Row>
                                                <span className="alpha-data-label text-right flex-grow-1">
                                                    * Alpha-Test Content
                                                </span>
                                            </Row> :
                                            null
                                        }
                                        {options.length > 0 ?
                                            <div className='d-flex flex-column'>
                                                {options.map(opt => opt)}
                                            </div>
                                            : null}
                                        {rankingOptions ?
                                            <Row>
                                                <Col sm={12} md={this.props.location.state.image ? 12 : 6}>
                                                    {rankingOptions}
                                                </Col>
                                            </Row>
                                            : null}
                                    </div>) : null
                                }


                            </div>

                            <SketchpadBoard
                                roomId={this.state.roomId}
                                teacher={this.props.teacher}
                                setBoardId={this.props.setBoardId}
                                setSidebarContent={this.setSidebarContent}
                                setKnowledgeGraphContent={this.setKnowledgeGraphSidebarContent}
                                showDiagnosticQuestion={this.showDiagnosticQuestion}
                                setBoardLoaded={this.setBoardLoaded}
                                showHintAndConferenceDialog={this.handleOnIdle}
                                onEquationSelectListener={this.onEquationSelectListener}
                                resetNextStepCounter={this.resetNextStepCounter}
                                hasActiveConference={this.props.hasActiveConference}
                                getAnswerText={this.getAnswerText}
                                checkMyWorkHandler={this.submitEquations}
                                hintHandler={this.getHint}
                                submitHandler={this.submitFinalAnswer}
                                submitNonFRQHandler={this.submitNonFRQHandler}
                                questiontype={this.props.question.type}
                                showsubmitbutton={this.state.showsubmitbutton}
                                showNextStepHandler={this.showNextStepHandler}
                                showEntireSolutionHandler={this.showEntireSolution}
                                getKnowledgeGraphHandler={this.getKnowledgeGraph}
                                isScreenFull={this.state.isScreenFull}
                                leftpanelbuttonhide={this.state.leftpanelbuttonhide}
                                isAnyProblem={this.props.anyProblem}
                                sessionId={this.props.session_id}
                                actionBtnDisabled={this.state.actionBtnDisabled}
                                anyProblemStatement={this.state.anyProblemStatement}
                                sketchpadDivcolor={this.state.sketchpadDivcolor}
                                challengeId={this.props.challengeId}
                                cHeight={this.state.cHeight}
                                hintText={`Hints ${this.props.question.type === 'FRQ' && totalHints >= 0 ? '(' + currentHint + '/' + totalHints + ')' : ''}`}
                                eHeight={this.state.eHeight}
                                handleShowEquation={this.handleShowEquation}
                                showEquationPanel={this.state.showEquationPanel}
                                handleHideShowEquation={this.handleHideShowEquation}
                                problem_source={this.state.problem_source}
                                homeworkproblems_id={this.state.homeworkproblems_id}
                                {...this.props}
                                forMobile={true}
                            />


                        </div>
                        <Modal
                            show={this.state.showModal}
                            handleClose={this.closeModal}
                            title={this.modalTitle}
                            body={this.modalBody}
                            showButton={this.showButton}
                            customButton={this.customButton}
                            draggable={this.draggable} />

                        <Modal
                            show={this.state.showAnswerModal}
                            handleClose={this.closeAnswerModal}
                            body={this.answerModalBody}
                            showButton={this.showAnswerButton}
                            customButton={this.customAnswerButton}
                        //title={this.title} 
                        />

                        <ProgressSidebar
                            show={this.state.showProgressSidebar}
                            closeSidebar={this.closeProgressSidebar}
                        >
                            {this.progressSidebarContent}
                        </ProgressSidebar>



                    </>
                    {
                        this.state.loader && (
                            <CircularProgress
                                size={24}
                                sx={{
                                    color: "#164b99",
                                    position: 'absolute',
                                    top: '50%',
                                    left: '50%',
                                    marginTop: '-12px',
                                    marginLeft: '-12px',
                                    zIndex: 9999
                                }}
                            />
                        )
                    }

                </div >

                <TrapFocus open disableAutoFocus disableEnforceFocus>
                    <Fade appear={false} in={this.state.bannerOpen}>
                        <Paper
                            elevation={24}
                            role="dialog"
                            aria-modal="false"
                            aria-label="Cookie banner"
                            square
                            variant="outlined"
                            tabIndex={-1}
                            sx={{
                                position: 'fixed',
                                right: 0,
                                m: 0,
                                p: 2,
                                borderWidth: "1px",
                                borderColor: "black",
                                // borderTopWidth: 1,
                                zIndex: 9999,
                                width: "20% !important",
                                right: "30px",
                                bottom: "40px",
                                borderRadius: "10px",
                                '@media (max-width: 600px)': {
                                    width: '100% !important',
                                    right: "0px !important"
                                },
                                '@media only screen and (min-device-width: 768px) and (max-device-width: 1024px) and (-webkit-device-pixel-ratio: 2)': {
                                    width: '34% !important'
                                }
                            }}
                        >
                            <Stack
                                direction={{ xs: 'column', sm: 'row' }}
                                justifyContent="flex-start"
                                gap={2}

                            >
                                <img src={LogoSign} alt="" className="feebackcompanyLogo" />

                                <Typography sx={{
                                    fontSize: "18px !important"
                                }}>Please help us improve aiPlato.</Typography>



                            </Stack>
                            <Stack
                                direction={{ xs: 'column', sm: 'row' }}
                                justifyContent="flex-end"
                                gap={2}
                                sx={{ mt: 1 }}
                            >
                                <MuiButton size="small" onClick={this.closeBanner} variant="outlined"
                                    sx={{

                                        textTransform: "capitalize",
                                        fontSize: "15px"
                                    }}>
                                    No Thanks
                                </MuiButton>
                                <MuiButton size="small"
                                    // target='_blank ' 
                                    // href={`${configData.WEBSITE_URL}feedback`} 
                                    onClick={this.feedbackLinkHandler}
                                    variant="contained"
                                    sx={{

                                        textTransform: "capitalize",
                                        fontSize: "15px",
                                        '&:hover': {
                                            color: '#ffffff',

                                        },
                                    }}>
                                    Feedback
                                </MuiButton>

                            </Stack>


                        </Paper>
                    </Fade>
                </TrapFocus>
            </>
        );
    }
}
export default withRouter(React.memo(AnswerBoardMobile));