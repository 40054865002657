import React, { useEffect, useState } from 'react';
import './TestInstructions.scss'
import { makeStyles } from '@material-ui/core/styles';
import { Button } from '@mui/material';
import VolunteerActivismOutlinedIcon from '@mui/icons-material/VolunteerActivismOutlined';
import examImg from "../../../assets/images/Exam.jpg";
import whiteBoardVideo from "../../../assets/videos/Quiz_HelpCenter.mp4";
import VideocamIcon from '@mui/icons-material/Videocam';
import SchoolIcon from '@mui/icons-material/School';
import AutoAwesomeIcon from '@mui/icons-material/AutoAwesome';
import Cookies from 'universal-cookie';
import { ArrowBack } from '@material-ui/icons';
import { Link } from 'react-router-dom';
import { halfTestMarksLimit, fullPTTimeInString, halfPTTimeInString, Institute, testInstructionText } from '../../../common/Constants';
import { getInstituteId, getCurrentUserId, getTimeDifference, convertDurationToSeonds, convertSecondsToHMS } from '../../../common/Functions';
import { savepttimer } from '../../../common/API';
import parse from 'html-react-parser';
import Modal1 from '../../Common/ModalComponent/ModalComponent';

const newStyles = makeStyles((theme) => ({
    root: {},
    btnSubmit: {
        width: '100%',
        height: '100%',
        paddingLeft: "22px",
        paddingRight: "22px",
        paddingTop: "8px",
        paddingBottom: "8px",
        background: '#4353B0',
        boxShadow: '0px 3px 1px -2px rgba(0, 0, 0, 0.20)',
        borderRadius: "100px",
        overflow: 'hidden',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        display: 'inline-flex',
        color: "#fff"
    },
    btnSelected: {
        textAlign: 'center',
        color: 'white',
        fontSize: '18px',
        fontFamily: 'Average Sans',
        fontWeight: '400',
        lineHeight: '27px',
        letterSpacing: '0.15px',
        wordWrap: 'break-word',
        textTransform: 'capitalize'
    },
    btnNonSelected: {
        textAlign: 'center',
        color: '#1B262F',
        fontSize: '18px',
        fontFamily: 'Average Sans',
        fontWeight: '400',
        lineHeight: '27px',
        letterSpacing: '0.15px',
        wordWrap: 'break-word',
        textTransform: 'capitalize'
    },
    handHeart: {
        color: '#CF49A2',
        width: '140.65px',
        height: '107.81px',
        left: '0px',
        top: '18.75px',
        position: 'absolute',
        background: 'transperant'
    }
}));
const cookies = new Cookies();

const TestInstructions = (props) => {
    const classes = newStyles();

    const [isGuidedMode, setisGuidedMode] = useState(false)
    const [isExamMode, setisExamMode] = useState(false)
    const [testTitle, settestTitle] = useState("")
    const [timeCounter, settimeCounter] = useState("")
    const [showModalVideo, setshowModalVideo] = useState(false)
    const [whiteBoardVideoDiv, setwhiteBoardVideoDiv] = useState("")

    useEffect(() => {
        settestTitle(cookies.get("practiceTitle"))

        if (props.match.params.practiceMode !== undefined) {
            if (props.match.params.practiceMode === "guided") {
                setisGuidedMode(true)
                setisExamMode(false)
            }
            else {
                setisExamMode(true)
                setisGuidedMode(false)
            }
        }

        if (props.match.params.practiceMode === "exam") {
            let initialTime;
            let totalChallenges = parseInt(cookies.get("totalchallengeperPtTest", { pathname: "/" }))
            if (totalChallenges > halfTestMarksLimit) {
                initialTime = fullPTTimeInString
            }
            else {
                initialTime = halfPTTimeInString
            }

            if (parseInt(cookies.get("epoch" + parseInt(props.match.params.practiceId))) === 0) {
                settimeCounter(initialTime)
            }
            else {
                let initialTimeInSec = convertDurationToSeonds(initialTime)
                let epochValue = parseInt(cookies.get("epoch" + parseInt(props.match.params.practiceId)))
                let now = new Date();
                let currentEpoch = Math.floor(now.getTime() / 1000);
                let getTimeDiffArray = getTimeDifference(epochValue, currentEpoch)
                let timeDiffString = getTimeDiffArray.hours + ":" + getTimeDiffArray.minutes + ":" + getTimeDiffArray.seconds
                let timeDiffInSec = convertDurationToSeonds(timeDiffString)

                let timeLapseInSec = initialTimeInSec - timeDiffInSec
                let timeLapseString = convertSecondsToHMS(timeLapseInSec)

                settimeCounter(timeLapseString)
            }
        }
    }, [])

    const startExamClick = () => {
        if (isExamMode) {
            if (parseInt(cookies.get("epoch" + parseInt(props.match.params.practiceId))) === 0) {
                let initialTime;
                let totalChallenges = parseInt(cookies.get("totalchallengeperPtTest", { pathname: "/" }))
                if (totalChallenges > halfTestMarksLimit) {
                    initialTime = fullPTTimeInString
                }
                else {
                    initialTime = halfPTTimeInString
                }

                let now = new Date();
                let gmtEpoch = Math.floor(now.getTime() / 1000);
                const reqData = {
                    "user_id": getCurrentUserId(),
                    "unique_id": parseInt(props.match.params.practiceId),
                    "timelapse": cookies.get("timelapse", { pathname: "/" }) !== "" ? cookies.get("timelapse", { pathname: "/" }) : null,
                    "epoch": gmtEpoch
                }

                savepttimer(reqData).then(res => {
                    if (res.data !== undefined && res.data !== null) {
                        cookies.set("epoch" + parseInt(props.match.params.practiceId), gmtEpoch, { path: "/" })
                        window.location = "/questionRenderer/" + props.match.params.practiceId + "/exam"
                    }
                })
            }
            else {
                window.location = "/questionRenderer/" + props.match.params.practiceId + "/exam"
            }
        }
        else {
            window.location = "/questionRenderer/" + props.match.params.practiceId + "/guided"
        }
    }

    const btnSetModeClickHandler = (selectedMode) => {
        if (selectedMode === "exam") {
            setisExamMode(true)
            setisGuidedMode(false)
        }
        else {
            setisExamMode(false)
            setisGuidedMode(true)
        }
    }
    const usageguidehandler = () => {
        setshowModalVideo(true)
        setwhiteBoardVideoDiv(
            <div className='videoModalDiv'>
                <div className='videoDiv'>
                    <video playsinline muted loop={true} autoPlay={true} width="590" height="270" controls>
                        <source src={whiteBoardVideo} type="video/mp4" />
                    </video>
                </div>
                {/* <div className='videoHeader'>
                    <span>Welcome to Interactive Problems <SchoolIcon className='schoolIcon' /> <AutoAwesomeIcon className='awesomeIcon' /></span>
                </div> */}
                 <div className='videoDescription mt-4'>
                    <p>Welcome to the Quiz Guide! In this video, we will walk you through everything you need to know to successfully complete your quiz.</p>
                </div>
                <div className='modal-button text-center'>
                    <Button className="videoClosebtn" onClick={closeusagaeguidehandler}> CLOSE </Button>
                </div>
            </div>
        )
    }
    const closeusagaeguidehandler = () => {
        setshowModalVideo(false)
    }

    return (
        <>
            <div className='examPaper'>
                <div className='divBackLink container'>
                    <ArrowBack
                        style={{
                            color: "#4252AF",
                            width: "0.75em",
                            height: "0.98em",
                            marginLeft: "10px"
                        }}
                    />
                    <Link className='backlinkbutton'
                        to={{
                            pathname: "/practicetest"
                        }}
                    > {getInstituteId() === Institute.Rice ? "Back to the Quiz" : "Back to Practice Test"}</Link>
                </div>

                <div className='container-fluid instruction'>
                    <div className='container bg-white-with-shadow my-3 my-md-5'>
                        <div className="testTitleDiv">
                            <div className="d-flex justify-content-between" style={{ width: "100%" }}>
                                <p style={{ paddingLeft: '0' }} className="testTitleText">{testTitle}</p>
                                <div>
                                    <button
                                        onClick={usageguidehandler}
                                        className="btnLightBlue" >Usage Guide</button>
                                </div>
                            </div>

                            <div className="lineBreak"></div>

                        </div>

                        <div className="examInstMainDiv">
                            {parse(testInstructionText)}

                            {getInstituteId() === Institute.Rice ? null :
                                <>
                                    <div className="divChooseMode"></div>
                                    <div className="divInstruction">
                                        <div className="instructionTitle">Choose Your Test Mode</div>
                                        <div className="divLis">
                                            <div className="divLi instructiondiv">
                                                <span className='spanLiTitle'>Guided Mode:<br /></span>
                                                <span className='spanLi'>
                                                    <ul>
                                                        <li>Receive step-by-step explanations to enhance your understanding as you tackle each question. </li>
                                                        <li>This mode is perfect for learning at your own pace and solidifying your knowledge.</li>
                                                        <li>Remember that the requesting for hints and suggestions affect your scoring negatively.</li>
                                                    </ul>
                                                </span>
                                            </div>
                                            <div className="divLi instructiondiv">
                                                <span className='spanLiTitle'>Exam Mode:<br /></span>
                                                <span className='spanLi'>
                                                    <ul>
                                                        <li>Simulate the real AP exam experience with no guidance, testing your preparation under timed conditions.</li>
                                                        <li>Ideal for assessing your readiness for the actual test.</li>
                                                    </ul>
                                                </span>
                                            </div>
                                        </div>
                                    </div>
                                </>
                            }
                        </div>
                        {getInstituteId() === Institute.Rice ? null :
                            <div className="examInstMainDivModes">
                                <div className="divMode">
                                    <div className="divModeBox" onClick={() => btnSetModeClickHandler("guided")}>
                                        <div className={isGuidedMode ? "divRectangleSelected" : "divRectangleNonSelected"} />
                                        <div className="Handheart" style={{ width: 150, height: 150, left: 60, top: 60, position: 'absolute' }}>
                                            <VolunteerActivismOutlinedIcon className={classes.handHeart} />
                                        </div>
                                    </div>
                                    <div className={isGuidedMode ? "examModeSelected" : "examModeNonSelected"} onClick={() => btnSetModeClickHandler("guided")}>
                                        <Button onClick={() => btnSetModeClickHandler("guided")} className={isGuidedMode ? classes.btnSelected : classes.btnNonSelected}>Guided Mode</Button>
                                    </div>
                                </div>
                                <div className="divMode">
                                    <div className="divModeBox" onClick={() => btnSetModeClickHandler("exam")}>
                                        <div className={isExamMode ? "divRectangleSelected" : "divRectangleNonSelected"} />
                                        <div className="Exam" style={{ width: 150, height: 150, left: 60, top: 60, position: 'absolute' }}>
                                            <img alt='Exam Mode' className={classes.handHeart} src={examImg} />
                                        </div>
                                    </div>
                                    <div className={isExamMode ? "examModeSelected" : "examModeNonSelected"} onClick={() => btnSetModeClickHandler("exam")} >
                                        <Button onClick={() => btnSetModeClickHandler("exam")} className={isExamMode ? classes.btnSelected : classes.btnNonSelected}>Exam Mode</Button>
                                    </div>
                                </div>
                            </div>
                        }
                    </div>

                    <div className='examPaperFooter'>
                        <div className='progressFooter'>
                            <div className="countDownDiv">
                                {isGuidedMode ? null :
                                    <div className="divTimeCounter">
                                        <div className="timeLeft">Total Time</div>
                                        {cookies.get("challengeStatus", { pathname: "/" }) === "Not Started" ?
                                            cookies.get("totalchallengeperPtTest", { pathname: "/" }) > halfTestMarksLimit ?
                                                <div className="timeNumber">{fullPTTimeInString}</div>
                                                :
                                                <div className="timeNumber">{halfPTTimeInString}</div>
                                            :
                                            cookies.get("timelapse", { pathname: "/" }) !== "" ? <div className="timeNumber">{cookies.get("timelapse", { pathname: "/" })}</div>
                                                :
                                                cookies.get("totalchallengeperPtTest", { pathname: "/" }) > halfTestMarksLimit ?
                                                    <div className="timeNumber">{fullPTTimeInString}</div>
                                                    :
                                                    <div className="timeNumber">{halfPTTimeInString}</div>
                                        }
                                    </div>
                                }
                            </div>
                            <div className="submitExamDiv">
                                <div className="questionsAttempted">Attempted Questions: {cookies.get("totalattepmtedchallenge", { pathname: "/" })}/{cookies.get("totalchallengeperPtTest", { pathname: "/" })}</div>
                                <div className='btnSubmitDiv'>
                                    <Button className={classes.btnSubmit} onClick={() => startExamClick()}> {cookies.get("challengeStatus", { pathname: "/" }) === "Not Started" ? "Start Quiz" : "Resume Quiz"} </Button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div >
            <Modal1 className='modalVideo'
                show={showModalVideo}
                onHide={closeusagaeguidehandler}
                draggable={false}
                body={whiteBoardVideoDiv}
                handleClose={closeusagaeguidehandler}
                hideHeader={true}
                showButton={false}
            >
            </Modal1>
        </>
    )

}
export default (TestInstructions)