import styled from 'styled-components';

export const Container = styled.div`
display: flex;
font-size: 68px;
`
export const RadioRate = styled.input`
display: none;
`
export const Rating = styled.div`
cursor: pointer;
margin-right: 5px;
`
