import React from 'react';
import Button from '../../../../Common/Button/Button';
import { Row, Col } from 'react-bootstrap';
import Modal from '../../../../Common/ModalComponent/ModalComponent';
import ModalAI from '../../../../Common/ModalComponent/ModalComponentAI';
import Aux from '../../../../../hoc/Wrapper';
import './RaiseHandSkipAhead.scss';
import Acc2eImg from '../../../../../assets/images/Acc-2e.png';
import { withRouter } from 'react-router-dom';
import { toast } from 'react-toastify';
import { getCurrentUserId, getDisplayTextHolder, getTryThisPinsEnabledFlag, getCurrentUserEmail, isRiceUser, getCurrentUserRole } from '../../../../../common/Functions';
import {
    saveUserQuestion, fetchKnowledgeBaseQuestions, setKnowledgeBaseQuestion, apiURL
    , fetchTranscript, initiateTranscription, createRoomParticipantMapping, studentSubscription
} from '../../../../../common/API';
import TryThisPin from '../../../../Common/TryThisPin/TryThisPin';
import VideoConferenceSideBar from '../../../../../components/VideoConferenceSideBar/VideoConferenceSideBar';
import Breadcrumb from '../../../../../components/Breadcrumb/Breadcrumb';
import Spinner from 'react-bootstrap/Spinner'
import { Container } from 'react-bootstrap';
import Radiobutton from '../../../../Common/Radiobutton/Radiobutton';
import Ratings from '../../../../Common/Ratings/Ratings';
import RatingsHover from '../../../../Common/Ratings/RatingsHover';
import arrowImage from '../../../../../assets/images/icon_arrow.svg';
import IconNotification from '../../../../../assets/images/icon_notification.svg';
import brainIcon from '../../../../../assets/icons/brain_icon.ico';
import SessionSynopsisWithData from '../../../../Answerboard/SessionSynopsis/SessionSynopsisWithData';
import ChatBotComponent from '../../../../Common/Chatbot/ChatBot';
import { isNil } from 'lodash';
import Tour from '../../../../../container/Tour/Tour';

class RaiseHandSkipAhead extends React.Component {

    state = {
        showModal: false,
        showAnswerModal: false,
        showAIModal: false,
        isWaitTimeRunning: false,
        targetWaitTime: '',
        showSkipAheadPin: this.props.match.params.topicId !== '16',
        showRaiseHandPin: this.props.match.params.topicId === '16',
        toggleVideoConfShowModal: false,
        showModalWriteReview: false,
        questionId: null,
        isSessionSynopsisLoading: false,
        transcripts: {},
        synopsisData: {},
        showSessionSynopsis: false,
        askTutorButtonVisible: true,
        questionvalueforasktutor: "",
        askAI: false,

        showModalAskTutor: false,
        askTutorTextYes: false,
        afterSubscriptionMessage: false,
        askTutorButton: false

    }
    player = this.props.player;
    content = this.props.content;
    textareaRef = React.createRef();
    studentQuestion = ''
    knowledgeBaseContent = null
    isRaiseHandPopup = false
    loadTranscriptTimer = null;

    skipAheadOptionsJSX = <div className='d-flex flex-column'>
        <a href='/' onClick={(e) => this.skipToNextQuestion(e)}>Skip ahead to next “Progress Checkpoint” question</a>
        <a href='/' onClick={(e) => this.skipToNextEndOfSection(e)} className='mt-2'>Skip ahead to next “End of Section Quiz” question</a>
        <a href='/' onClick={(e) => this.skipToNextConcept(e)} className='mt-2'>Skip ahead to “Next Section”</a>
    </div>
    toggleVideoConfModal = () => {
        this.setState({
            // boardWidth: !this.state.toggleVideoConfShowModal ? '75vw' : '100vw',
            toggleVideoConfShowModal: !this.state.toggleVideoConfShowModal,
        })
    }
    openModal = () => {
        try {
            this.player.pauseVideo();
        } catch {
        }

        this.setState({ showModal: true });
    }


    handleasktutoryes = () => {
        // this.raiseHandClickHandler()
        this.setState({ askTutorTextYes: true, askTutorButton: true, showModal: false }, () => {
            this.openModalaskTutor()
        })
    }
    handleasktutorsubscription = () => {
        this.setState({ loader: true })
        const reqData = {
            "user_id": getCurrentUserId(),
            "user_email": getCurrentUserEmail(),
            "challenge_content_id": this.props.match.params.topicId,
            "action_type": "ask tutor content",
            "institute_name": ""
        }
        studentSubscription(reqData).then(res => {
            if (res.status === 200) {
                // this.raiseHandClickHandler()
                this.setState({ loader: false, afterSubscriptionMessage: true, showModal: false }, () => {
                    this.openModalaskTutor()
                })
            }
        }).catch(err => {
            this.setState({ loader: false, afterSubscriptionMessage: false, showModal: false })
            console.error(err.message);
        })

    }
    closeModalAskTutor = () => {

        this.setState({
            showModalAskTutor: false,
            showAnswerModal: false,
            showModal: true,
            showAIModal: false,
            afterSubscriptionMessage: false,
            askTutorTextYes: false,
            askTutorButton: false
        }, () => {
            this.raiseHandClickHandler()
        })
        //alert(this.studentQuestion)
    }
    openModalaskTutor = () => {
        // event.preventDefault();
        // this.setState({
        //     showModalAskTutor: true,
        //     showAnswerModal: false,
        //     showModal: false
        // })

        this.modalTitleAskTutor = 'Ask Tutor';
        this.customButtonAskTutor = (
            <div className='mx-auto'>
                {
                    !this.state.askTutorButton ? (<> <Button clicked={this.handleasktutoryes} className="mr-2 yellow-btn">Yes</Button>
                        <Button clicked={this.closeModalAskTutor} className="mr-2 grey-btn">No</Button></>) : (
                        <>
                            {
                                this.state.afterSubscriptionMessage ? (<Button clicked={this.closeModalAskTutor} className="mr-2 grey-btn">Ok</Button>) : null
                            }
                        </>

                    )
                }
            </div>
        );
        this.modalBodyAskTutor = (
            <>
                <div className='text-center '>
                    {
                        !this.state.askTutorTextYes ? (<> <img src={IconNotification} alt='NotificationIcon' /><div className='iconNotification'>We recommend you to ask AI first. Still want to connect to Tutor?</div></>) :
                            (<>
                                <img src={Acc2eImg} className='askTutorImageContainer' alt='NotificationIcon' />
                                {
                                    isRiceUser() ? <>
                                        <div className='iconNotification'>This service is not available for your institution for now.</div>
                                    </> :
                                        !this.state.afterSubscriptionMessage ? (<>
                                            <div className='iconNotification'>Sorry, no tutor is available at this time for your subscription level.</div>
                                            <div className="upgrateSubscriptionBox" onClick={this.handleasktutorsubscription}>Please
                                                contact us to upgrade your subscription</div>
                                        </>) : (<div>Thanks for your request. We will get back soon.</div>)
                                }
                            </>)
                    }

                </div>

            </>
        );
        this.setState({
            showModalAskTutor: true,
            showAnswerModal: false,
            showModal: false,
            showAIModal: false
        })
    }

    openModalWriteReview = (event) => {
        event.preventDefault();
        this.setState({
            showModalWriteReview: true,
            showAnswerModal: false
        })

        this.modalTitleReview = 'Write a Review';
        this.customButtonReview = (
            <div className='mx-auto'>
                <Button className='mr-2 yellow-btn' clicked={this.closeModalWriteReview}>Submit</Button>
                <Button className="grey-btn" clicked={this.closeModalWriteReview}>Cancel</Button>
            </div>
        );
        this.modalBodyReview = (
            <div className='review-modal'>
                <div className='write-review'>
                    <h2>Overall Rating</h2>
                    <div className='rating'>
                        <Ratings stars={3} className='ratings' />
                    </div>
                </div>
                <div className='write-review'>
                    <h2>Clear Explanation</h2>
                    <div className='rating'>
                        <Ratings stars={2} className='ratings' />
                    </div>
                </div>
                <div className='write-review'>
                    <h2>Engaging Presentation</h2>
                    <div className='rating'>
                        <Ratings stars={1} className='ratings' />
                    </div>
                </div>
                <div className='write-review'>
                    <h2>Well-paced, Fast or Slow</h2>
                    <div className='slide-container'>
                        <input type="range" min="1" max="100" value={3} id="myRange" />
                        <label className='slow'>Slow</label>
                        <label className='fast'>Fast</label>
                    </div>
                </div>
                <div className='write-review well-paced-checkbox'>
                    <div className='checkbox'>
                        <fieldset >
                            <div className="abstractReview">
                                <Radiobutton name="abstractreview" value='Needs Improvement' text='Needs Improvement' />
                                <Radiobutton name="abstractreview" value='Good' text='Good' />
                                <Radiobutton name="abstractreview" value='Very Good' text='Very Good' />
                                <Radiobutton name="abstractreview" value='Exceptional' text='Exceptional' />
                                <Radiobutton name="abstractreview" value='Irresistible' text='Irrestible' />
                            </div>
                        </fieldset>
                    </div>
                </div>
                <textarea rows="3" class="review-text" placeholder='Write a review' ></textarea>
            </div>
        );
    }

    closeModalWriteReview = () => {
        this.setState({ showModalWriteReview: false, showAnswerModal: true });
    }

    openAnswerModal = () => {
        this.customAnswerButton = <div>
            <Row>
                <Col>
                    <Button className='modal-footer-button btn mx-auto' clicked={() => {
                        this.closeAnswerModal();
                        this.closeModal();
                    }}>
                        Continue Lecture
                    </Button>
                </Col>
                <Col>
                    <Button className='modal-footer-button btn mx-auto' clicked={this.closeAnswerModal}>Another Question</Button>
                </Col>
                <Col>
                    {
                        getCurrentUserEmail() === "student@aiplato.ai" ? (<Button className='modal-footer-button btn mx-auto' clicked={this.askTutor}>Ask a Human Tutor</Button>) :
                            (<Button className='modal-footer-button btn mx-auto' clicked={this.openModalaskTutor}>Ask a Human Tutor</Button>)
                    }
                </Col>
                <Col>
                    <Button className='modal-footer-button btn mx-auto' clicked={() => {
                        this.closeModal();
                        this.closeAnswerModal();
                        this.askToChatGPT(true);
                    }}>Ask GenerativeAI</Button>
                </Col>
            </Row>
        </div>;

        // this.title = <div>
        //     <Row>
        //         <Col>
        //             <div className="clearfix rating-hover">
        //                 <Ratings stars={2} className='ratings main-video-rating mr-2' readOnly />
        //                 <img src={arrowImage} alt="" style={{ height: "auto", width: "auto" }} />
        //                 <RatingsHover
        //                     explanationStars={0}
        //                     presentationStars={0}
        //                     paceRange={2} />
        //             </div>
        //         </Col>
        //         <Col>
        //             <Button className='rating-button yellow-btn' clicked={(event) => this.openModalWriteReview(event)}>Write a Review</Button>
        //         </Col>
        //     </Row>
        // </div>;



        this.setState({ showAnswerModal: true, showModal: false });
    }

    closeModal = (pauseVideo = false) => {
        if (this.isRaiseHandPopup) {
            this.isRaiseHandPopup = false;
            this.studentQuestion = ''
        }

        this.player.playVideo();
        this.state.askAI = false;
        this.setState({ showModal: false, askTutorButtonVisible: true, askAI: false });
    }

    closeAnswerModal = () => {
        this.raiseHandClickHandler()
        this.state.askAI = false;
        this.setState({ showAnswerModal: false, askAI: false });
    }

    closeAnswerModalAI = () => {
        this.raiseHandClickHandler()
        this.setState({ showAIModal: false, showModal: true });
    }

    getKnowledgeBaseAnswer = (answerData) => {
        let answer = "";
        if (answerData.substring(0, 6) == "TL;DR:") {
            let tdlr = ""// "<b>TL;DR:</b>";
            let indexOfLongAnswer;
            let longAnswer = "<br /><b>Long Answer:</b>"

            indexOfLongAnswer = answerData.indexOf("Long Answer:");
            if (indexOfLongAnswer == -1) {
                indexOfLongAnswer = answerData.indexOf("Long answer:");
            }

            if (indexOfLongAnswer == -1) {
                tdlr += (answerData.substring(6, answerData.length));
            }
            else {
                tdlr += (answerData.substring(6, indexOfLongAnswer))

                longAnswer += answerData.substring(indexOfLongAnswer + 12, answerData.length)

            }
            answer += (tdlr + longAnswer);
        }
        else {
            answer = answerData;
        }
        return { __html: answer.replace(/\\n/g, "<br />") };
    }

    searchKnowledgeBase = async () => {
        let quesValue = this.textareaRef.current.value.trim();
        this.setState({ questionvalueforasktutor: quesValue })
        if (quesValue.length <= 0) {
            toast('Please enter a question.', {
                type: toast.TYPE.ERROR,
                position: toast.POSITION.BOTTOM_RIGHT
            })
        } else {
            this.state.askAI = true;
            this.setState({ askAI: true })
            this.raiseHandClickHandler()

            let quetionid = await this.saveQuestionToDatabase(quesValue)
            fetchKnowledgeBaseQuestions({ params: { question: quesValue } })
                .then(res => {
                    this.setState({ askTutorButtonVisible: false })
                    const showAnswer = (quesValue, dataObj) => {
                        if (!isNil(dataObj)) {
                            this.answerModalBody = <div className="progress-bar-container"><Spinner animation="border" role="status" /></div>
                            this.openAnswerModal()

                            let answer = this.getKnowledgeBaseAnswer(dataObj.answer)

                            let answerModalBody =
                                <div className="answer-content">
                                    <div>
                                        <Row>
                                            <Col sm="4"></Col>
                                            <Col sm="4">
                                                <div className="clearfix rating-hover">
                                                    <Ratings stars={dataObj.rateValue} className='ratings main-video-rating mr-2' readOnly />
                                                    <img src={arrowImage} alt="" style={{ height: "auto", width: "auto" }} />
                                                    <RatingsHover
                                                        explanationStars={0}
                                                        presentationStars={0}
                                                        paceRange={2} />
                                                </div>
                                            </Col>
                                            <Col sm="4">
                                                <Button className='rating-button yellow-btn' clicked={(event) => this.openModalWriteReview(event)}>Write a Review</Button>
                                            </Col>
                                        </Row>
                                    </div>
                                    <p className='your-question font-16'>Your Question: <span>{quesValue}</span></p>
                                    <p className='selected-question font-16'>Selected Question: <span>{dataObj.question}</span></p>
                                    <p className='answer font-16'>Answer: <span><div dangerouslySetInnerHTML={answer} /></span></p>
                                    {dataObj.resource === '' ? null : <p className='resource font-16'>Resource: <a href={dataObj.resource} target="_blank">{dataObj.resource}</a></p>}
                                </div>

                            this.answerModalBody = answerModalBody
                            this.showAnswerButton = false
                            this.openAnswerModal()
                        }

                        // fetchKnowledgeBaseQuestions({ params: { question: quesValue, index: dataObj.index } })
                        //     .then(res => {
                        //         let answer = this.getKnowledgeBaseAnswer(res.data.knowledgebase.answer)

                        //         let answerModalBody =
                        //             <div className="answer-content">
                        //                 <div>
                        //                     <Row>
                        //                         <Col sm="4"></Col>
                        //                         <Col sm="4">
                        //                             <div className="clearfix rating-hover">
                        //                                 <Ratings stars={dataObj.rateValue} className='ratings main-video-rating mr-2' readOnly />
                        //                                 <img src={arrowImage} alt="" style={{ height: "auto", width: "auto" }} />
                        //                                 <RatingsHover
                        //                                     explanationStars={0}
                        //                                     presentationStars={0}
                        //                                     paceRange={2} />
                        //                             </div>
                        //                         </Col>
                        //                         <Col sm="4">
                        //                             <Button className='rating-button yellow-btn' clicked={(event) => this.openModalWriteReview(event)}>Write a Review</Button>
                        //                         </Col>
                        //                     </Row>
                        //                 </div>
                        //                 <p className='your-question font-16'>Your Question: <span>{quesValue}</span></p>
                        //                 <p className='selected-question font-16'>Selected Question: <span>{res.data.knowledgebase.question}</span></p>
                        //                 <p className='answer font-16'>Answer: <span><div dangerouslySetInnerHTML={answer} /></span></p>
                        //                 {res.data.knowledgebase.resource === '' ? null : <p className='resource font-16'>Resource: <a href={res.data.knowledgebase.resource} target="_blank">{res.data.knowledgebase.resource}</a></p>}
                        //             </div>

                        //         this.answerModalBody = answerModalBody
                        //         this.showAnswerButton = false
                        //         this.openAnswerModal()
                        //     })
                        //     .catch(err => {
                        //         console.error(err.message);
                        //     })
                    }

                    let knowledgeBaseContent = res.data.knowledgebase.length > 0 ? res.data.knowledgebase.map((dataObj, index) => (
                        <div className="d-flex knowledgebase-question-container">
                            <p className="question font-16" onClick={() => showAnswer(quesValue, dataObj)}>{dataObj.question}</p>
                            <div className="d-flex align-items-center pl-2">
                                <p className="theta">{dataObj.theta}</p>
                                <img src={apiURL + "/knowledgebase/plot.png/?angle=" + dataObj.rawTheta + "&index=" + index} alt="Graph" />
                            </div>
                            <div className="clearfix rating-hover">
                                <Ratings stars={dataObj.rateValue} className='ratings main-video-rating mr-2' readOnly />
                                <img src={arrowImage} alt="" style={{ height: "auto", width: "auto" }} />
                                <RatingsHover
                                    explanationStars={0}
                                    presentationStars={0}
                                    paceRange={2} />
                            </div>
                            {dataObj.entry_source === "internal" ?
                                <div className="d-flex align-items-center pl-2">
                                    <img src={brainIcon} alt="" style={{ height: "20px", width: "20px" }} />
                                </div> : <div className="d-flex align-items-center pl-2" style={{ height: "20px", width: "20px" }} />
                            }

                        </div>
                    )) : null

                    this.knowledgeBaseContent = knowledgeBaseContent ? <div className="knowledge-base-content">
                        <p className="title">Related KnowledgeGraph Content</p>
                        <p className='sub-title font-16'>From the following list, please select the question that best matches yours:</p>
                        {knowledgeBaseContent}

                        <p className='titlenone'>None of the Above:</p>
                        <span className="question font-16 questionbutton" style={{ marginRight: '10px', marginLeft: '10px', marginTop: "15px" }} onClick={() => this.askToChatGPT(true)}>Ask GenerativeAI</span>
                        <span style={{ marginLeft: '10px' }}>OR</span>

                        {
                            getCurrentUserEmail() === "student@aiplato.ai" ? (<span className="question font-16 questionbutton" style={{ marginRight: '0', marginTop: "15px" }}
                                onClick={this.askTutor}>Ask a Human Tutor </span>) : (<span className="question font-16 questionbutton" style={{ marginRight: '0', marginTop: "15px" }}
                                    onClick={this.openModalaskTutor}>Ask a Human Tutor </span>)
                        }
                        {/*  */}



                        {/* OR<span className="question font-14" style={{ marginRight: '10px', marginLeft: '10px' }} onClick={() => this.askToChatGPT(false)}>Ask ChatGPT</span> */}


                    </div>
                        : <div className="knowledge-base-content">
                            <p className='sub-title titlenone'>No question found that best matches yours!</p>
                            <span className="question font-14 questionbutton" style={{ marginRight: '10px', marginLeft: '10px', marginTop: "15px" }} onClick={() => this.askToChatGPT(true)}>Ask GenerativeAI</span>
                            OR

                            {
                                getCurrentUserEmail() === "student@aiplato.ai" ? (<span className="question font-14 questionbutton" style={{ marginTop: "15px" }}
                                    onClick={this.askTutor} > Ask a Human Tutor </span>) : (<span className="question font-14 questionbutton" style={{ marginTop: "15px" }}
                                        onClick={this.openModalaskTutor} > Ask a Human Tutor </span>)
                            }
                            {/*  */}


                            {/* OR<span className="question font-14" style={{ marginRight: '10px', marginLeft: '10px' }} onClick={() => this.askToChatGPT(false)}>Ask ChatGPT</span> */}


                        </div>

                    this.raiseHandClickHandler()
                })
                .catch(err => {
                    console.error(err.message);
                })
        }
    }


    openAnswerModalAI = () => {
        this.customAnswerButton = <div>
            <Row>
                <Col>
                    <Button className='modal-footer-button btn mx-auto' clicked={() => {
                        this.closeAnswerModalAI();
                        this.closeAnswerModal();
                        this.closeModal();

                    }}>
                        Continue Lecture
                    </Button>
                </Col>
                <Col>
                    <Button className='modal-footer-button btn mx-auto' clicked={this.closeAnswerModalAI}>Another Question</Button>
                </Col>
                <Col>
                    {
                        getCurrentUserEmail() === "student@aiplato.ai" ? (
                            <Button className='modal-footer-button btn mx-auto' clicked={this.askTutor}>Ask a Human Tutor</Button>) :
                            (<Button className='modal-footer-button btn mx-auto' clicked={this.openModalaskTutor}>Ask a Human Tutor</Button>)
                    }
                </Col>
            </Row>
        </div>;

        this.setState({ showAIModal: true, showModal: false });
    }

    // Temporary solution for chatGPT needs to be code cleaning 
    askToChatGPT = (aiplatotrained) => {
        let quesValue = ''
        if (this.textareaRef.current === null) {
            quesValue = this.state.questionvalueforasktutor;
        }
        else {
            quesValue = this.textareaRef.current.value.trim();
        }

        // let quesValue = this.textareaRef.current.value.trim();
        this.setState({ questionvalueforasktutor: quesValue })
        let modalBOdy = <div className="progress-bar-container">
            <Spinner animation="border" role="status" />
        </div>
        this.answerModalBody = modalBOdy;
        this.showAnswerButton = false;
        this.openAnswerModalAI()
        let answerModalBody =
            <div style={{ width: '430px', height: '450px' }}>
                <ChatBotComponent handleclose={this.closeAnswerModalAI} isInline={true} question={quesValue}
                    aiPlatoTrained={false} videoTimeStamp={this.player.getCurrentTime().toFixed(0)}
                    Content_type={this.props.Content_type}
                    SubSection_id={this.props.SubSection_id}></ChatBotComponent>
            </div>

        this.answerModalBody = answerModalBody
        this.showAnswerButton = false
        this.openAnswerModalAI()
        // askChatGPT(quesValue, aiplatotrained).then(res => {
        //     const ansObj = JSON.parse(res.data.chatgpt);
        //     let answer = this.getKnowledgeBaseAnswer(ansObj.answer)
        //     let answerModalBody =

        //         <div className="answer-content">
        //             <div>
        //                 <Row>
        //                     <Col sm="4"></Col>
        //                     <Col sm="4">
        //                         <div className="clearfix rating-hover">
        //                             {/* <Ratings stars={dataObj.rateValue} className='ratings main-video-rating mr-2' readOnly />
        //                             <img src={arrowImage} alt="" style={{ height: "auto", width: "auto" }} />
        //                             <RatingsHover
        //                                 explanationStars={0}
        //                                 presentationStars={0}
        //                                 paceRange={2} /> */}
        //                         </div>
        //                     </Col>
        //                     <Col sm="4">
        //                         {/* <Button className='rating-button yellow-btn' clicked={(event) => this.openModalWriteReview(event)}>Write a Review</Button> */}
        //                     </Col>
        //                 </Row>
        //             </div>
        //             <p className='your-question font-16'>Your Question: <span>{quesValue}</span></p>
        //             {/* <p className='selected-question'>Selected Question: <span>{ansObj.question}</span></p> */}
        //             <p className='answer font-16'>Answer: <span><div dangerouslySetInnerHTML={answer} /></span></p>
        //             <p className='resource font-16'>Resource: <a href={ansObj.resource} target="_blank">{ansObj.resource}</a></p>
        //         </div>

        //     this.answerModalBody = answerModalBody
        //     this.showAnswerButton = false
        //     this.openAnswerModal()
        // })

    }
    studentQuestionChangeHandler = (ev) => {
        this.studentQuestion = ev.target.value;
    }

    raiseHandClickHandler = () => {
        this.isRaiseHandPopup = true;
        if (this.props.hasQuestion) {
            return;
        }
        let resources = [];
        let imageResources = [];
        let playerCurrentTime = this.player.getCurrentTime().toFixed(0);

        for (let item of this.content) {
            if (playerCurrentTime >= item.start_time && playerCurrentTime <= item.end_time) {

                if (item.resource_link && item.anticipated_question) {
                    if (item.anticipated_question) {
                        resources.push(
                            <p className="anticipatedQuestion font-16" onClick={() => {
                                let answerModalBody = <div className="answer-content">
                                    <p className='your-question'>Question: <span>{item.anticipated_question}</span></p>
                                    <p className='answer'>Answer: <span>{item.text_answer}</span></p>
                                    See more on <a href={item.resource_link} target='_blank' rel="noopener noreferrer" className='mt-2'><p>{getDisplayTextHolder(item.resource_summary)}</p></a>
                                </div>

                                this.answerModalBody = answerModalBody
                                this.showAnswerButton = false;
                                this.openAnswerModal()
                            }}>{item.anticipated_question}</p>
                        );
                    }
                }
                else {

                    if (item.resource_link) {
                        resources.push(
                            <a href={item.resource_link} target='_blank' rel="noopener noreferrer" className='mt-2'><p>{getDisplayTextHolder(item.resource_summary)}</p></a>
                        );
                    }

                    if (item.anticipated_question) {
                        resources.push(
                            <p className="anticipatedQuestion" onClick={() => {
                                let answerModalBody = <div className="answer-content">
                                    <p className='your-question'>Question: <span>{item.anticipated_question}</span></p>
                                    <p className='answer'>Answer: <span>{item.text_answer}</span></p>
                                </div>

                                this.answerModalBody = answerModalBody
                                this.showAnswerButton = false
                                this.openAnswerModal()
                            }}>{item.anticipated_question}</p>
                        );
                    }
                }
                if (item.resource_image) {
                    imageResources.push(
                        <img className="img-fluid resource-img" src={item.resource_image} alt="resource" />
                    )
                }
                // resources.push({
                //     link: item.resource_link,
                //     text: item.resource_summary
                // });
            }
        }

        this.modalBody = (
            <>
                <div className='raise-hand-content'>
                    {resources.length > 0 ? (
                        <Aux>
                            <h5>Would you like a refresher or clarification on:</h5>
                        </Aux>
                    ) : null}
                    <div className={`d-flex ${resources.length > 0 ? 'mb-3' : ''}`}>
                        <div>
                            {resources.map(resource => resource)}
                        </div>
                        {imageResources.length > 0 ? <div>
                            {imageResources.map(resource => resource)}
                        </div> : null}
                    </div>

                    <div className='ask-tutor font-16'>
                        <p>If these resources don’t answer your question, you can search our Knowledge Base or connect to a tutor.  Please enter your question, please be as specific as possible.</p>
                        <textarea
                            ref={this.textareaRef}
                            rows={3}
                            defaultValue={this.studentQuestion}
                            onChange={(ev) => this.studentQuestionChangeHandler(ev)}
                            placeholder='Enter your question here' />
                        <Button className='mt-1 mb-3 mr-3 yellow-btn search-knowledgebase-btn' clicked={this.searchKnowledgeBase}>Ask AI</Button>
                        {
                            this.state.askTutorButtonVisible ? (
                                <>
                                    {
                                        getCurrentUserEmail() === "student@aiplato.ai" ? (<Button tooltip="Start a live video call with a human tutor. Share your board to collaborate." className='mt-1 mb-3 yellow-btn' clicked={this.askTutor}>Ask tutor</Button>) :
                                            (<Button tooltip="Start a live video call with a human tutor. Share your board to collaborate." className='mt-1 mb-3 yellow-btn' clicked={this.openModalaskTutor}>Ask tutor</Button>)
                                    }
                                </>

                            ) : null
                        }

                        {this.state.askAI ? (<div className="progress-bar-container"><Spinner animation="border" role="status" /></div>) : null}
                    </div>

                    {this.knowledgeBaseContent != null ?
                        this.knowledgeBaseContent : null}
                </div>

            </>
        );





        this.state.askAI = false;
        this.setState({ askAI: false })
        this.showButton = false;
        this.customButton = <button
            type='button'
            className='modal-footer-button btn mx-auto'
            onClick={this.closeModal}>Back to Lecture
        </button>;
        this.openModal();
    }

    skipAheadClickHandler = () => {
        if (this.props.hasQuestion) {
            return;
        }
        this.modalBody = this.skipAheadOptionsJSX

        this.showButton = true;

        this.openModal();
    }

    askTutor = async () => {

        let quesValue = ""
        if (this.textareaRef.current === null) {
            quesValue = this.state.questionvalueforasktutor;
        }
        else {
            quesValue = this.textareaRef.current.value.trim();
        }
        if (quesValue.length <= 0) {
            toast('Please enter a question.', {
                type: toast.TYPE.ERROR,
                position: toast.POSITION.BOTTOM_RIGHT
            })
        } else {
            let quetionid = await this.saveQuestionToDatabase(quesValue)

            setKnowledgeBaseQuestion({ question: quesValue })
                .then(res => {
                    console.log(res.data);
                })
                .catch(err => {
                    console.error(err.message);
                })

            this.setState({ showModal: false, askTutorButtonVisible: true, showAIModal: false, showAnswerModal: false })
            if (this.isRaiseHandPopup) {
                this.isRaiseHandPopup = false;
                this.studentQuestion = ''
            }
            //const url = '/answerboard/question?ques=' + quesValue;            

            this.props.toggleVC(quetionid);

            //window.open(url, '_blank');
            //console.log(window.location.pathname + window.location.search + window.location.hash);
            // let url = window.location.pathname + window.location.search + window.location.hash
            // this.props.history.push({ pathname: `${url}/question?ques=${quetionid}` });
        }
    }

    saveQuestionToDatabase = async (question) => {
        let quetionid = null
        const reqData = {
            question,
            source_type: "video",
            videoTimeStamp: this.player.getCurrentTime().toFixed(0),
            userId: getCurrentUserId(),
            videoContentId: this.props.videoContentId,
        }

        await saveUserQuestion(reqData)
            .then(res => {
                if (res.data.message !== undefined && res.data.message !== null);
                {
                    quetionid = res.data.message
                    this.setState({ questionId: res.data.message })
                }
            })
            .catch(err => {
                console.error(err.message);
            })

        return quetionid

    }

    skipToNextEndOfSection = (event) => {
        event.preventDefault();
        let playerCurrentTime = this.player.getCurrentTime().toFixed(0);
        let timeToSkip = null;
        let found = false;

        for (let question of this.props.questions) {
            if (question.interval > playerCurrentTime && question.answered === false) {
                for (let ques of question.questions) {
                    if (ques.type === 'EndOfSection') {
                        timeToSkip = question.interval
                        found = true;
                        break;
                    }
                }
                if (found) {
                    break
                }
            }
        }

        if (!found) {
            toast("No more end of section question found.", {
                type: toast.TYPE.WARNING,
                position: toast.POSITION.BOTTOM_RIGHT
            });
        } else {
            for (let question of this.props.questions) {
                if (question.interval < timeToSkip) {
                    // question.answered = 'true';
                    question.skipped = 'true';
                }
            }
            for (let question of this.props.summativeQuestions) {
                if (question.interval <= timeToSkip && question.skipped && !question.answered) {
                    question.skipped = false
                }
            }
            this.props.changeSkipAheadSkip(true);
            this.player.pauseVideo();
            this.player.seekTo(timeToSkip);
        }
        this.closeModal();
    }

    skipToNextQuestion = (event) => {
        event.preventDefault();
        let found = false;
        let playerCurrentTime = this.player.getCurrentTime().toFixed(0);
        for (let question of this.props.filteredItems) {
            if (question.interval > playerCurrentTime && question.answered === false) {
                if (question.type === 'Q') {
                    for (let ques of question.questions) {
                        if (ques.time2skip && ques.showDuringSkipAhead) {
                            this.player.seekTo(ques.time2skip);
                            if (ques.waitTime) {
                                this.setState({ isWaitTimeRunning: true, targetWaitTime: ques.time })
                                setTimeout(() => {
                                    this.setState({ isWaitTimeRunning: false, targetWaitTime: '' })
                                }, Number(ques.waitTime) * 1000)
                            }
                            found = true;
                            break;
                        }
                    }
                    if (found) {
                        break;
                    }
                } else { // SQ
                    if (question.showSummativeDuringSkipAhead) {
                        this.player.seekTo(question.interval - 1);
                        found = true;
                        break;
                    }
                }
            }
        }
        if (!found) {
            toast('No more "Progress Checkpoint" interactions available in the lecture.', {
                type: toast.TYPE.WARNING,
                position: toast.POSITION.BOTTOM_RIGHT
            });
            // alert('No more questions found.');
        }
        // this.props.changeSkipAheadSkip(true);
        this.closeModal();
    }


    skipToNextConcept = (event) => {
        event.preventDefault();

        let playerCurrentTime = this.player.getCurrentTime().toFixed(0);
        let skipTime = null;
        let found = false;
        for (let question of this.props.questions) {
            if (question.interval > playerCurrentTime && question.answered === false) {
                let foundQues = false;
                for (let ques of question.questions) {
                    if (ques.type === 'EndOfSection') {
                        skipTime = question.interval;
                        // this.player.seekTo(question.interval);
                        foundQues = true;
                        found = true;
                        break;
                    }
                }
                if (foundQues) {
                    break;
                }
            }
        }
        if (!found) {
            toast("This is the last concept in this lecture.", {
                type: toast.TYPE.WARNING,
                position: toast.POSITION.BOTTOM_RIGHT
            });
        } else {
            for (let question of this.props.questions) {
                if (question.interval <= skipTime) {
                    for (let ques of question.questions) {
                        if (ques.type !== 'EndOfSection') {
                            question.answered = 'true';
                            // break;
                        }
                    }
                }
            }
            for (let question of this.props.summativeQuestions) {
                if (question.interval <= skipTime) {
                    question.answered = 'true';
                }
            }
            this.props.changeSkipAheadSkip(true);
            this.player.seekTo(skipTime);
        }
        this.closeModal();

    }

    closeSessionSynopsis = () => {
        this.setState({ showSessionSynopsis: false });
    }
    endConferenceHandler = () => {
        const callFetchTranscriptApi = () => {
            const apiHandler = (participantId, transcriptIds) => {
                console.log("1", participantId)
                fetchTranscript({
                    transcriptIds: transcriptIds
                })
                    .then(res => {
                        let transcripts = { ...this.state.transcripts }

                        for (const transcriptId in res.data.response) {
                            if (res.data.response.hasOwnProperty(transcriptId)) {
                                transcripts[participantId] = res.data.response
                            }
                        }
                        console.log("transcripts.......", transcripts)

                        this.setState({ transcripts });
                    }).catch(err => {
                        console.error(err.message);
                    })
            }

            let apiCalled = false

            for (const participantId in this.state.transcripts) {
                console.log("2", participantId)
                if (this.state.transcripts.hasOwnProperty(participantId)) {
                    const participant = this.state.transcripts[participantId];
                    let transcriptIds = []

                    for (const transcriptId in participant) {
                        if (participant.hasOwnProperty(transcriptId)) {
                            const transcriptObj = participant[transcriptId];

                            if (transcriptObj.status === 'InProgress') {
                                transcriptIds.push(transcriptId)
                            }
                        }
                    }

                    if (transcriptIds.length > 0) {
                        console.log("3", participantId)
                        apiHandler(participantId, transcriptIds)
                        apiCalled = true
                    }
                }
            }

            if (!apiCalled) {
                this.setState({ isSessionSynopsisLoading: false });
                clearInterval(this.loadTranscriptTimer)
            }

        }

        const callInitiateTranscriptionApi = (participantId1, participantId2, mappingId) => {
            console.log("participant1:", participantId1)
            console.log("participantId2:", participantId2)
            initiateTranscription({
                participantId1,
                participantId2,
                mappingId
            })
                .then(res => {
                    let transcriptObj = {}

                    res.data.transcriptIds.map(id => {
                        transcriptObj = {
                            ...transcriptObj,
                            [id]: { status: 'InProgress' },
                        }
                    })

                    this.setState({
                        transcripts: {
                            ...this.state.transcripts,
                            [participantId1]: transcriptObj,
                        }
                    }, () => {
                        if (this.loadTranscriptTimer === null) {
                            this.loadTranscriptTimer = setInterval(callFetchTranscriptApi, 5000);
                        }
                    });
                }).catch(err => {
                    console.error(err.message);
                })
        }

        this.props.toggleVC();
        this.setState({ showSessionSynopsis: true, isSessionSynopsisLoading: true, disabledSynopsisButton: true });
        let params = new URLSearchParams(window.location.search);
        let roomId = params.get('roomId');
        const reqData = {
            roomId: roomId
        }
        createRoomParticipantMapping(reqData)
            .then(res => {
                let participantsForTranscript = []

                this.setState({
                    synopsisData: {
                        mappingId: res.data.mappingId,
                        roomId
                    }
                });

                if (this.loadTranscriptTimer !== null) {
                    clearInterval(this.loadTranscriptTimer)
                }
                let participant_one = ""
                res.data.participants.map(participant => {
                    participantsForTranscript.push(participant)
                    //callInitiateTranscriptionApi(participant.participant_sid, res.data.mappingId)
                })

                setTimeout(() => {
                    console.log("Calling INITIATE TRASCRIPTION-----------------", participantsForTranscript)
                    callInitiateTranscriptionApi
                        (participantsForTranscript[0].participant_sid, participantsForTranscript[1].participant_sid, res.data.mappingId)
                }, 5000)

                this.setState({
                    mappingId: res.data.mappingId,
                    participantsForTranscript
                });

            }).catch(err => {
                console.error(err.message);
            })
    }
    componentWillUnmount() {
        if (this.loadTranscriptTimer) {
            clearInterval(this.loadTranscriptTimer)
        }
        try {
            window.$(document).unbind('scroll');
        } catch (e) {
            console.log(e.message);
        }
    }
    render() {
        let raiseHandClass = ['yellow-btn', 'raiseBtn'];
        if (this.props.hasQuestion) {
            raiseHandClass.push('disabled-btn');
        }

        let skipAheadClass = ['yellow-btn', 'skipButton'];
        if (this.props.hasQuestion) {
            skipAheadClass.push('disabled-btn');
        }

        return (
            <Aux>
                {this.state.isWaitTimeRunning ?
                    <p className="text-center mt-2 mb-0">Setting the context for the upcoming question at {this.state.targetWaitTime}</p> : null}
                <Row className={`${getTryThisPinsEnabledFlag() && (this.state.showSkipAheadPin || this.state.showRaiseHandPin) ? '' : ''} flex-column`}>
                    <div style={{ display: "flex", justifyContent: "space-between" }}>
                        <div className='d-flex raise-hand-button-section raisehand_tour'>
                            {getTryThisPinsEnabledFlag() && this.props.match.params.topicId === '16' ?
                                <TryThisPin showPin={this.state.showRaiseHandPin} style={{
                                    position: 'absolute',
                                    left: '10px', top: '-26px'
                                }}>
                                    <Button
                                        disabled={this.props.raisebuttondisabled}
                                        className={raiseHandClass.join(' ')} clicked={() => {
                                            this.setState({ showRaiseHandPin: false })
                                            this.knowledgeBaseContent = null
                                            this.raiseHandClickHandler()

                                        }}>Raise hand</Button>
                                </TryThisPin> :
                                <Button
                                    disabled={this.props.raisebuttondisabled}
                                    className={raiseHandClass.join(' ')} clicked={() => {
                                        this.knowledgeBaseContent = null
                                        this.raiseHandClickHandler()
                                    }}>Raise hand</Button>}

                            {getTryThisPinsEnabledFlag() && this.props.match.params.topicId !== '16' ?
                                <TryThisPin showPin={this.state.showSkipAheadPin} style={{
                                    position: 'absolute',
                                    left: '0', top: '-20px'
                                }}>
                                    <div className={`skip-ahead-container${this.props.hasQuestion ? ' disabled' : ''}`}
                                        onClick={() => this.setState({ showSkipAheadPin: false })}>
                                        <Button className={skipAheadClass.join(' ')} clicked={this.skipAheadClickHandler}>Skip ahead</Button>
                                        <div className="skip-ahead-list">
                                            {this.skipAheadOptionsJSX}
                                        </div>
                                    </div>
                                </TryThisPin> :
                                <div className={`skip-ahead-container${this.props.hasQuestion ? ' disabled' : ''}`}
                                    onClick={() => this.setState({ showSkipAheadPin: false })}>
                                    <Button className={skipAheadClass.join(' ')} clicked={this.skipAheadClickHandler}>Skip ahead</Button>
                                    <div className="skip-ahead-list">
                                        {this.skipAheadOptionsJSX}
                                    </div>
                                </div>}

                        </div>
                        {
                            this.props.isTeacher ? (<div className='raise-hand-button-section'>
                                <Button
                                    className={raiseHandClass.join(' ')}
                                    clicked={this.endConferenceHandler}
                                >Synopsis</Button>
                            </div>) : null
                        }

                    </div>
                </Row>
                {this.props.isTeacher ? <SessionSynopsisWithData
                    show={this.state.showSessionSynopsis}
                    onClose={this.closeSessionSynopsis}
                    isLoading={this.state.isSessionSynopsisLoading}
                    transcripts={this.state.transcripts}
                    synopsisData={this.state.synopsisData}
                    isHistoryView={false}
                    call_type={this.props.call_type}
                    iseditable={(getCurrentUserRole() === "Tutor" || getCurrentUserRole() === "Professor") ? true : false}
                /> : null}

                <Container fluid>
                    <Breadcrumb toggleVideoConfModal={this.toggleVideoConfModal} />
                    <VideoConferenceSideBar
                        showModal={this.state.toggleVideoConfShowModal}
                        toggleVideoConfModal={this.toggleVideoConfModal} />

                </Container>

                <Modal
                    show={this.state.showModal}
                    handleClose={this.closeModal}
                    body={this.modalBody}
                    showButton={this.showButton}
                    customButton={this.customButton} />

                <Modal
                    show={this.state.showAnswerModal}
                    handleClose={this.closeAnswerModal}
                    body={this.answerModalBody}
                    showButton={this.showAnswerButton}
                    customButton={this.customAnswerButton}
                //hideHeader={true}
                //title={this.title} 
                />

                <ModalAI
                    show={this.state.showAIModal}
                    handleClose={this.closeAnswerModalAI}
                    body={this.answerModalBody}
                    showButton={this.showAnswerButton}
                    customButton={this.customAnswerButton}
                    hideHeader={true}
                    title={this.title}
                />


                <Modal
                    show={this.state.showModalWriteReview}
                    handleClose={this.closeModalWriteReview}
                    title={this.modalTitleReview}
                    body={this.modalBodyReview}
                    customButton={this.customButtonReview}
                />

                <Modal
                    show={this.state.showModalAskTutor}
                    handleClose={this.closeModalAskTutor}
                    title={this.modalTitleAskTutor}
                    body={this.modalBodyAskTutor}
                    customButton={this.customButtonAskTutor}
                />

                {this.props.isLimitExceed ? null : <Tour pagename="curriculumcontent"></Tour>}
            </Aux>
        );
    }
}

export default withRouter(RaiseHandSkipAhead);
