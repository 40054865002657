import React, { useState, useEffect, useRef } from "react";
import ReactFlow, {
  ReactFlowProvider,
  addEdge,
  removeElements,
  Controls,

} from "react-flow-renderer";
import ReactDOMServer from 'react-dom/server';
import { useParams } from 'react-router-dom';
import Select, { components } from 'react-select';
import { MathComponent } from "mathjax-react";
import { getTextWidth } from 'get-text-width';
import { filter, isNil, clone, isEmpty, isNull } from "lodash";
import { FaBars, FaAngleRight, FaAngleLeft } from "react-icons/fa";
import EquationNode from "./CustomNodes/EquationNode";
import "./index.css";
//import useUndo from "./use-history";
import { toast } from 'react-toastify';
import { ToastContainer } from 'react-toastify';
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from 'ckeditor5-classic-with-mathtype';
import parse from 'html-react-parser';
import ChallengeComments from '../Challenges/AddChallenges/ChallengeComments';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Tooltip from 'react-bootstrap/Tooltip';
import { Challenge_status, getCurrentUserRole, User_Role, HintRequredMessage } from '../../common/Functions';
import { Link } from 'react-router-dom';
import Draggable from "react-draggable";
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import AccordionSummary from '@mui/material/AccordionSummary';
import {
  fetchResources,
  getChallengenodes,
  getchallengesubsection,
  updateResources,
  updateChallengenodes,
  SaveAllResource,
  getHintText,
  checkCombination,
  getresourcetopic,
  getChallengeMCQ,
  deleteChallengeMCQ,
  UpdateChallengeMCQ,
  generateLatexFromMathML,
  getSympyEquation,
} from "../../common/API"
import { graphCkConfig } from "../../utils/CkEditorConfig";
import { Col, Row, Modal } from 'react-bootstrap';
import Button from 'react-bootstrap/Button';
import dagre from 'dagre';
import Accordion from '@mui/material/Accordion';
//import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import CircularProgress from '@mui/material/CircularProgress';
import GridComponent from '../GridComponent/GridComponent'
import { makeStyles } from "@material-ui/core/styles";
import MathJax from 'react-mathjax2';

const nodeBackground = "#bfd4ff";

const nodeTypes = {
  nodeWithOnlyText: EquationNode
};

const WIRIS_FOR = {
  EQUATION: 'equation',
  UNIT: 'unit'
}
const BASIC_ERROR = 'basic';
const AUTHOR_COMBINATION = 'author_combination';
const SYSTEM_COMBINATION = 'system_combination';
const PROPAGATION_ERROR = 'propagation_error';
const PRIMARY = 'primary';
const NON_PRIMARY = 'nonPrimary';
const OPTIONAL = 'optional';
const NODE_BACKGROUND = [{ type: PRIMARY, color: nodeBackground }, { type: NON_PRIMARY, color: nodeBackground }, { type: OPTIONAL, color: "#cccccc" }];
const INCORRECT_COLOR = "#ff6666";
const SHOW_MORE = "Show more";
const SHOW_LESS = "Show less";
let requisiteKnowledgeResponse;
let initialElements = [];
//let isAutoPositioningRequired = false;
let maxNodeId = 0;
let maxOptionId = 0;
let challengeType = "";
let challengeId = ""
let isCorrectFlag = false;
let addnewAnswer = false;
let correctOptionId = 0;
let correctCount = 0;
let mcqArray = [];
let optionIds = [];
let mcqAddNewObj = {
  optionId: "",
  tempOptionId: "",
  challenge_id: "",
  optionText: "",
  requisiteKnowledgeText: "",
  requisiteKnowledge: '',
  isCorrect: true,
  isCorrectText: "",
  errorRootCause: "",
  resourceids: "",
  remedialText: "",
  remedialResources: "",
  remedialResourcedisplay: "",
  isRemedialResoueReusable: false,
  remedialResourceURLs: "",
  hints: [],
  subsection_id: "",
};
let mcqDataObject = {
  optionId: "",
  tempOptionId: "",
  challenge_id: "",
  optionText: "",
  requisiteKnowledgeText: "",
  requisiteKnowledge: '',
  isCorrect: true,
  isCorrectText: "",
  errorRootCause: "",
  resourceids: "",
  remedialText: "",
  remedialResources: "",
  remedialResourcedisplay: "",
  isRemedialResoueReusable: false,
  remedialResourceURLs: "",
  hints: [],
  subsection_id: "",
  resourcetopicmasterid: "",
};

let data = {
  label: `Add Equation`,
  equation: "",
  background: nodeBackground,
  isRequired: true,
  isPrimary: true,
  isnonPrimary: false,
  isOptional: false,
  isNodeCorrect: true,
  explanation: "",
  requisiteKnowledge: "",
  hintText: "",
  remedialText: "",
  remedialResources: "",
  remedialResourcedisplay: "",
  isRemedialResoueReusable: false,
  remedialResourceURLs: "",
  incorrect_variants: "",
  importance: PRIMARY,
  errortype: BASIC_ERROR,
  parent: "",
  child: "",
  optional_parent: "",
  optional_child: "",
  border: "#eeeeee",
  hints: [],
  equation_comment: "",
  equation_latex_comment: "",
  explanation_comment: "",
  mathml: '',
  unitMathml: '',
  is_ue: false
};
let lastSelectedElement;
let hasWiris = false;
let wirisEditor;
const dagreGraph = new dagre.graphlib.Graph();
dagreGraph.setDefaultEdgeLabel(() => ({}));

const Input = (props) => (
  <components.Input {...props} readOnly={props.selectProps.isReadOnly} />
);


const useStyles = makeStyles((theme) => ({

  summarycss: {
    backgroundColor: "#e4e3e3 !important"
  }

}));

const editorConfiguration = {
  toolbar: {
    items: [
      'undo', 'redo',
      '|', 'heading',
      '|', 'fontfamily', 'fontsize', 'fontColor', 'fontBackgroundColor',
      '|', 'bold', 'italic', 'strikethrough', 'subscript', 'superscript', 'code',
      '-', // break point
      '|', 'alignment',
      'link', 'uploadImage', 'blockQuote', 'codeBlock',
      '|', 'bulletedList', 'numberedList', 'todoList', 'outdent', 'indent',
      '|', 'MathType',
    ],
    shouldNotGroupWhenFull: false,
    allowedContent: true
  }
};

const DnDFlow = (props) => {

  const classes = useStyles();
  const params = useParams();
  const [hasWiris, setHasWiris] = useState(false);
  const [wirisSize, setWirisSize] = useState('medium');
  const [debounceTimer, setDebounceTimer] = useState(null);
  const [reactFlowInstance, setReactFlowInstance] = useState(null);
  const [elements, setElements] = useState(initialElements);
  const [aftersave, setaftersave] = useState(false)
  const [nodeName, setNodeName] = useState("");
  const [nodeMathml, setNodeMathml] = useState("");
  const [unitMathml, setUnitMathml] = useState("");
  const [unitLatex, setUnitLatex] = useState("");
  const [equation, setEquation] = useState("");
  const [wirisOpenFor, setWirisOpenFor] = useState(WIRIS_FOR.EQUATION);
  const [unit, setUnit] = useState("");
  const [points, setPoints] = useState(0);
  const [decimalPoints, setdecimalPoints] = useState(2);
  const [hints_perc, sethints_perc] = useState(0);
  const [resourceids, setresourceids] = useState("");
  const [nodeErrorType, setErrorType] = useState(BASIC_ERROR);
  const [nodeImportance, setNodeImportance] = useState(PRIMARY);
  const [nodeExplanation, setNodeExplanation] = useState("");
  const [explanationEditor, setExplanationEditor] = useState(null);
  const [IsCorrect, setIsCorrect] = useState(true);
  const [isloading, setisloading] = useState(false);
  const [nodeRootCause, setNodeRootCause] = useState([]);
  const [nodehintRemedialText, setNodehintRemedialText] = useState("");
  const [hintEditor, setHintEditor] = useState(null);
  const [remedialText, setRemedialText] = useState("");
  const [nodehintRemedialResource, setNodehintRemedialResource] = useState("");
  const [nodeBg, setNodeBg] = useState(nodeBackground);
  const [nodeX, setNodeX] = useState("");
  const [nodeY, setNodeY] = useState("");
  const [element, setElement] = useState({});
  //const [nodeHidden, setNodeHidden] = useState(true);
  const [hideText1, setHideText1] = useState(false);
  const [width, setWidth] = useState(400);
  const [height, setHeight] = useState(200);
  const [border, setRadius] = useState(nodeBackground);
  const [nodeRemedialResourcedisplay, setNodeRemedialResourcedisplay] = useState("");


  const [isreusable, setIsReusable] = useState(false);
  const [hidden, setHidden] = useState(false);
  // Edge Properties
  const [edgeLabel, setEdgeLabel] = useState("Edge Label");
  const [edgeLabelStyle, setEdgeLabelStyle] = useState({});
  const [edgeType, setEdgeType] = useState("default");
  const [edgeAnimated, setEdgeAnimated] = useState(true);
  // const [edgeStyle, setEdgeStyle] = useState({});
  const [edgeArrowHead, setEdgeArrowHead] = useState("arrow");
  const [showEdgeProperties, setShowEdgeProperties] = useState(false);

  //const [addresource, setAddResource] = useState(false)
  const [addresourcelable, setAddresourcelable] = useState("Add Hint");
  //const [hinttext, setHintText] = useState("Hint Text")

  const [remedialResourceOption, setRemedialResourceOption] = useState([]);
  const [remedialResourceSelected, setRemedialResourceSelected] = useState([]);

  const [hintTextOptions, setHintTextOptionsOptions] = useState(null);
  const [hintTextSelected, setHintTextSelectedOptions] = useState([]);

  const [errorRootCauseOption, setErrorRootCauseOption] = useState(null);
  const [errorRootCauseValue, setErrorRootCauseValue] = useState(null);


  const [resourcetopicvalue, setresourcetopicvalue] = useState(null);
  const [resourcesvalue, setresourcesvalue] = useState(null);
  const [resourcesdata, setresourcesdata] = useState([])
  const [noderesourcetopicsubsectionid, setnoderesourcetopicsubsectionid] = useState("");
  const [resourcenamedisabled, setresourcenamedisabled] = useState(false)
  const [resourceurldisabled, setresourceurldisabled] = useState(false)
  const [isnewmode, setisnewmode] = useState(1)
  const [resourcetopicId, setresourcetopicId] = useState("");
  const [neweditbuttonvisible, setneweditbuttonvisible] = useState(false)
  const [addeditmode, setaddeditmode] = useState("Add")

  const [resourcetopicdisbaled, setresourcetopicdisbaled] = useState(false)


  const [defualtRootCauseOption, setDefaultErrorRootCauseOption] = useState(null);
  const [newnodeid, setNewNodeId] = useState(null);
  const [nodeHints, setNodeHints] = useState([]);
  const [dataSizeLabel, setShowDataSizeLabel] = useState(SHOW_MORE)
  const [layoutDirection, setLayoutDirection] = useState('')
  const [seletedResourceIdForEdit, setSeletedResourceIdForEdit] = useState("")

  const [showhintmodel, setShowhintmodel] = useState(false)
  const [informationtext, setInformationtext] = useState(false)
  const [statustext, setStatusText] = useState(false)
  const [opensavehintmodal, setopensavehintmodal] = useState(false)

  const [editonlyfornode, seteditonlyfornode] = useState(false)

  const [challengedata, setChallengeData] = useState({
    challenge_state: null
    , user_id: null
    , reviewer_user_id: null
    , expert_user_id: null
    , modified_user_id: null
  });

  const [equation_comment, setequation_comment] = useState("")
  const [equation_latex_comment, setequation_latex_comment] = useState("")
  const [explanation_comment, setexplanation_comment] = useState("")
  //const [hints_comment, sethints_comment] = useState("")
  const [showcommentmodal, setshowcommentmodal] = useState(false)

  const [modelheadertitle, setmodelheadertitle] = useState("")
  const [fieldname, setfieldname] = useState("")
  const [reviewcomments, setreviewcomments] = useState("")

  const [equation_comment_color_change, setequation_comment_color_change] = useState(false)
  const [equation_latex_comment_color_change, setequation_latex_comment_color_change] = useState(false)
  const [explanation_comment_color_change, setexplanation_comment_color_change] = useState(false)
  //const [hints_comment_color_change, sethints_comment_color_change] = useState(false)
  const [showcommentsfields, setshowcommentsfields] = useState(false)

  // const [equation_previous_comment, setequation_previous_comment] = useState("")
  // const [equation_latex_previous_comment, setequation_latex_previous_comment] = useState("")
  // const [explanation_previous_comment, setexplanation_previous_comment] = useState("")

  const [isread_equation_comment, setisread_equation_comment] = useState(false)
  const [isread_equation_latex_comment, setisread_equation_latex_comment] = useState(false)
  const [isread_explanation_comment, setisread_explanation_comment] = useState(false)
  //const [userrole, setUserRole] = useState(getCurrentUserRole())
  const [buttondisabled, setButtondisabled] = useState(false)
  const [isHintDetailsSelected, setIsHintDetailsSelected] = useState(false)

  const [hintmodalheadertext, setHintmodalheadertext] = useState("")
  const [requiredmessage, setrequiredmessage] = useState(false)

  const [isDisabledNotForUE, setisDisabledNotForUE] = useState(false);

  ///////////////// MCQ Changes New Functions START ////////////////////

  const [nodeRootCauseText, setRequisiteText] = useState([]);
  const [newoptionId, setnewoptionId] = useState(null);
  const [tempOptionId, setTempOptionId] = useState(null);
  const [isCorrectText, setIsCorrectText] = useState("");
  const [noderesourcetopicid, setnoderesourcetopicid] = useState("");
  const [expand, setExpand] = useState(true);
  const [openHideMCQGrid, setOpenMCQGrid] = useState(true);
  const [openHideReactFlow, setOpenHideReactFlow] = useState(true);
  const [hideNodeFieldsForMCQ, setHideNodeFieldsForMCQ] = useState(true);
  const [showNodeFieldsForMCQ, setShowNodeFieldsForMCQ] = useState(false);
  const [gridApi, setGridApi] = useState(null);
  const [rowData, setRowData] = useState();
  const [hintpershow, sethintpershow] = useState(true)


  const [expanded, setExpanded] = useState(false)
  const [uejsondata, setuejsondata] = useState([])
  const [rIncorrectjsondata, setRIncorrectjsondata] = useState([])
  const [isUEdragdrop, setisUEdragdrop] = useState(false)
  const [uejsonelement, setuejsonelement] = useState([])
  const [draggedUEIds, setdraggedUEIds] = useState([])

  const [savegraphDisabled, setsavegraphDisabled] = useState(false)
  // if (!isNil(document.getElementById('wirisEditorContainer')) && !hasWiris) {
  //   wirisEditor = window.com.wiris.jsEditor.JsEditor.newInstance({ 'language': 'en', 'toolbar': '<toolbar ref="general" removeLinks="true"><removeTab ref="arrows"/><removeTab ref="symbols"/><tab ref="general"><removeItem ref="numberPi"/></tab></toolbar>' });
  //   wirisEditor.insertInto(document.getElementById('wirisEditorContainer'));
  //   setHasWiris(true)
  // }
  const handleClose = () => setshowcommentmodal(false);
  const handlehintClose = () => setopensavehintmodal(false);
  const handleClosehintmodal = () => setShowhintmodel(false);

  useEffect(() => {
    if (!isNil(document.getElementById('wirisEditorContainer')) && !hasWiris) {
      if (window.com !== undefined) {
        wirisEditor = window.com.wiris.jsEditor.JsEditor.newInstance({ 'language': 'en' });
        wirisEditor.insertInto(document.getElementById('wirisEditorContainer'));
        setHasWiris(true)
      }
    }
  }, [])


  useEffect(() => {
    if (!props.readOnly && challengeId !== '') {
      getchallengesubsection(challengeId).then(res => {
        if (res.status === 200) {
          requisiteKnowledgeResponse = res.data;
          setErrorRootCauseOption(res.data['Current']);
        } else {
          console.log("No data found");
        }
      })
      getRemedialResources();
    }
  }, [challengeId])



  /*  MCQ Changes  */

  function btnCellRenderer(data) {
    let eGui = null;

    let editingCells = data.api.getEditingCells();
    let isCurrentRowEditing = editingCells.some((cell) => {
      return cell.rowIndex === data.node.rowIndex;
    });

    eGui = document.createElement("div")
    eGui.innerHTML = `
    <svg data-action="edit" xmlns="http://www.w3.org/2000/svg" width="15" height="15" data-name="Layer 1" viewBox="0 0 24 24" id="editrow">
            <path d="M3.5,24h15A3.51,3.51,0,0,0,22,20.487V12.95a1,1,0,0,0-2,0v7.537A1.508,1.508,0,0,1,18.5,22H3.5A1.508,1.508,0,0,1,2,20.487V5.513A1.508,1.508,0,0,1,3.5,4H11a1,1,0,0,0,0-2H3.5A3.51,3.51,0,0,0,0,5.513V20.487A3.51,3.51,0,0,0,3.5,24Z"></path>
            <path d="M9.455,10.544l-.789,3.614a1,1,0,0,0,.271.921,1.038,1.038,0,0,0,.92.269l3.606-.791a1,1,0,0,0,.494-.271l9.114-9.114a3,3,0,0,0,0-4.243,3.07,3.07,0,0,0-4.242,0l-9.1,9.123A1,1,0,0,0,9.455,10.544Zm10.788-8.2a1.022,1.022,0,0,1,1.414,0,1.009,1.009,0,0,1,0,1.413l-.707.707L19.536,3.05Zm-8.9,8.914,6.774-6.791,1.4,1.407-6.777,6.793-1.795.394Z"></path>
          </svg> &nbsp;&nbsp;&nbsp;&nbsp;
  
          <svg data-action="delete" xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-trash" viewBox="0 0 16 16">
          <path d="M5.5 5.5A.5.5 0 0 1 6 6v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5zm2.5 0a.5.5 0 0 1 .5.5v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5zm3 .5a.5.5 0 0 0-1 0v6a.5.5 0 0 0 1 0V6z" />
          <path fill-rule="evenodd" d="M14.5 3a1 1 0 0 1-1 1H13v9a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2V4h-.5a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1H6a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1h3.5a1 1 0 0 1 1 1v1zM4.118 4 4 4.059V13a1 1 0 0 0 1 1h6a1 1 0 0 0 1-1V4.059L11.882 4H4.118zM2.5 3V2h11v1h-11z" />
        </svg> `

    return (eGui);
  }

  function gridHintTextRenderer(params) {
    var eGui = [];
    let counter = 0;
    let nodeData = [];

    nodeData = params.data.hints;
    if (!isNil(nodeData)) {
      nodeData.forEach((hint) => {
        counter += 1;

        eGui.push(<li key={{ counter }} id={hint.Resource_ID} class="list-group-itemGrid hintItems">
          <div className="hintsRenderItem1">
            <div className="resourceDisplay">
              {
                (hint.Resource_Text === null || hint.Resource_Text === undefined
                  || hint.Resource_Text === 'undefined' || hint.Resource_Text === 'null') ? '' :
                  <div style={{ display: "flex" }}>
                    <div style={{ fontWeight: "500", width: "35%" }}>
                      {params.data.isCorrect ? "Hint Text:" : "Remedial Text:"}
                    </div>
                    <div style={{ paddingLeft: "5px", width: "65%" }}>{parse(hint.Resource_Text)}
                    </div>
                  </div>
              }
            </div>
            {/*  <div className="resourceDisplay">
                      {(hint.resourcetopicsubsectionname === null || hint.resourcetopicsubsectionname === undefined
                        || hint.resourcetopicsubsectionname === 'undefined' || hint.resourcetopicsubsectionname === 'null') ? '' :
    
                        <div style={{ display: "flex" }}>
                          <div style={{ fontWeight: "500", width: "35%" }}>
                            {"Resource Topic:"}
                          </div>
                          <div style={{ paddingLeft: "5px", width: "65%" }}>{hint.resourcetopicsubsectionname}
                          </div>
                        </div>
                      }
                    </div>
                    <div className="resourceDisplay">
                      {
                        (hint.Resource_Display === null || hint.Resource_Display === undefined
                          || hint.Resource_Display === 'null' || hint.Resource_Display === 'undefined')
                          ? '' :
                          <div style={{ display: "flex" }}>
                            <div style={{ fontWeight: "500", width: "35%" }}>Resource Display:
                            </div>
                            <div style={{ paddingLeft: "5px", width: "65%" }}>{hint.Resource_Display}
                            </div>
                          </div>
                      }
                    </div>
                    <div className="hintsUrlDisplay"><a href={hint.Resource_URL} target="_blank">
                      {(hint.Resource_URL !== undefined && hint.Resource_URL !== null && hint.Resource_URL !== 'undefined' && hint.Resource_URL !== 'null') ?
                      <div style={{ display: "flex" }}>
                        <div style={{ fontWeight: "500", width: "35%" }}>Resource URL:
                        </div>
                        <div style={{ paddingLeft: "5px", width: "65%" }}>{hint.Resource_URL}
                        </div>
                      </div> : ""
                      }</a>
                    </div> */}

          </div>
        </li>)
      });

      return (ReactDOMServer.renderToStaticMarkup(<ul className="list-group list">{Object.values(eGui)}</ul>));
    }

    return null;
  }

  function getAnswerTextRenderer(params) {
    var eGui = [];
    let answerData = params.data.optionText;

    if (!isNil(answerData)) {
      eGui.push(<span>{parse(answerData)}</span>)
    }

    return (ReactDOMServer.renderToStaticMarkup(Object.values(eGui)));
  }

  const [gridColumnData] = useState([
    { headerName: 'Index', field: 'tempOptionId', width: 20, sortable: true, sortingOrder: ["desc"], hide: true },
    { headerName: 'Index', field: 'optionId', width: 20, sortable: true, sortingOrder: ["desc"], hide: true },
    { headerName: 'Answers', field: 'optionText', cellRenderer: getAnswerTextRenderer, autoHeight: true, width: 250, sortable: true, wrapText: true, resizable: true },
    { headerName: 'Is Correct', field: 'isCorrectText', sortable: true, width: 70 },
    { headerName: 'Root cause', field: 'requisiteKnowledgeText', sortable: true, autoHeight: true, width: 300, wrapText: true, resizable: true },
    { headerName: 'Hints', field: 'hints', cellRenderer: gridHintTextRenderer, autoHeight: true, width: 350, sortable: true, wrapText: true, resizable: true },
    { headerName: 'Action', sortable: false, cellRenderer: btnCellRenderer, width: 70, editable: false, colId: "action" }
  ]);

  const compareG = (a, b) => {
    return parseInt(a.optionId) - parseInt(b.optionId);
  }

  useEffect(() => {
    setTimeout(() => {
      if (!isNil(params.challengeId)) {
        if (params.challengeId.includes("-")) {
          challengeId = params.challengeId.split('-')[0];
          challengeType = params.challengeId.split('-')[1];

          if (params.challengeId.split('-')[1].toLowerCase().includes("mcq") || params.challengeId.split('-')[1].toLowerCase().includes("msq")) {
            setOpenMCQGrid(true);
            setOpenHideReactFlow(false);

            if (!props.readOnly) {
              mcqArray = [];
              optionIds = [];

              getChallengeMCQ(params.challengeId.split('-')[0]).then(res => {
                if (res.status === 200 && !isNil(res.data.UI_Json)) {
                  res.data.UI_Json.forEach((obj) => {
                    let mcqShowDataObj = {
                      optionId: "",
                      tempOptionId: "",
                      challenge_id: "",
                      optionText: "",
                      requisiteKnowledgeText: "",
                      requisiteKnowledge: '',
                      isCorrect: true,
                      isCorrectText: "",
                      errorRootCause: "",
                      resourceids: "",
                      remedialText: "",
                      remedialResources: "",
                      remedialResourcedisplay: "",
                      isRemedialResoueReusable: false,
                      remedialResourceURLs: "",
                      hints: [],
                      subsection_id: "",
                    };
                    mcqShowDataObj = obj
                    mcqShowDataObj.challenge_id = res.data.challenge_id
                    fetchHintText(res.data.challenge_id, mcqShowDataObj.isCorrect)

                    setnewoptionId(mcqShowDataObj.optionId)
                    setTempOptionId(null)
                    setEquation(mcqShowDataObj.optionText)
                    setresourceids(mcqShowDataObj.resourceids)

                    if (!isNil(mcqShowDataObj.requisiteKnowledge)) {
                      let defaultRequisiteKnowledge = mcqShowDataObj.requisiteKnowledge.split(',')
                      let tempArr = []
                      if (!isNil(defaultRequisiteKnowledge) && !isEmpty(defaultRequisiteKnowledge)) {
                        defaultRequisiteKnowledge.forEach((item) => {
                          if (requisiteKnowledgeResponse !== undefined) {
                            const requisite = filter(requisiteKnowledgeResponse['nodes'], ['subSectionId', parseInt(item)])
                            if (!isNil(requisite) && !isEmpty(requisite)) {
                              tempArr.push(requisite[0])
                            }
                          }
                          else {
                            setErrorRootCauseValue([])
                            setRequisiteText("");
                          }
                        })
                      }

                      setErrorRootCauseValue(tempArr)
                      let strText = "";
                      if (!isNil(tempArr) && !isEmpty(tempArr)) {
                        tempArr.forEach((item, index) => {
                          strText += index === 0 ? item.name : '\n' + item.name
                        })
                      }

                      mcqShowDataObj.requisiteKnowledgeText = strText;
                      setRequisiteText(strText);
                    }
                    else {
                      setErrorRootCauseValue([])
                      setRequisiteText("");
                    }

                    setIsCorrect(mcqShowDataObj.isCorrect)
                    setNodeHints(mcqShowDataObj.hints === undefined ? [] : mcqShowDataObj.hints);
                    setAddresourcelable(mcqShowDataObj.isCorrect ? "Add Hint" : "Add Remedial")
                    if (mcqShowDataObj.isCorrect && challengeType.toLowerCase() === "mcq") {
                      correctCount += 1
                      isCorrectFlag = true
                      correctOptionId = mcqShowDataObj.optionId
                    }

                    mcqShowDataObj.isCorrectText = mcqShowDataObj.isCorrect ? "Yes" : "No"
                    setIsCorrectText(mcqShowDataObj.isCorrectText)

                    mcqArray.push(clone(mcqShowDataObj));
                  });

                  gridOptions.api.setRowData(mcqArray === undefined ? [] : mcqArray)
                }
                else {
                  gridOptions.api.setRowData(mcqArray === undefined ? [] : mcqArray)
                }
              })
            }
          }
        }
        else {
          challengeId = params.challengeId
          challengeType = "challengeType"
        }
      }
    }, 1500)
  }, [])

  const gridOptions = {
    getRowStyle(params) {
      if (params.node.data.isCorrect)
        return { 'background-color': '#6f9cf7' }

      return null;
    },
    onCellClicked(params) {
      if (params.column.colId === "action" && params.event.target.dataset.action) {
        let action = params.event.target.dataset.action;

        if (action === "edit") {
          addnewAnswer = false;
          // To show Node panel
          setHideText1(true);
          setShowEdgeProperties(false);
          setExpand(true);
          handleToggleSidebar(!toggled);
          setShowNodeFieldsForMCQ(true);
          setHideNodeFieldsForMCQ(false);

          setnewoptionId(params.node.data.optionId);
          setTempOptionId(params.node.data.tempOptionId);
          setEquation(params.node.data.optionText);
          setresourceids(params.node.data.resourceids)

          fetchHintText(challengeId, params.node.data.isCorrect)

          if (!isNil(params.node.data.requisiteKnowledge)) {
            let defaultRequisiteKnowledge = params.node.data.requisiteKnowledge.split(',');
            let tempArr = []
            if (!isNil(defaultRequisiteKnowledge) && !isEmpty(defaultRequisiteKnowledge)) {
              defaultRequisiteKnowledge.forEach((item) => {
                const requisite = filter(requisiteKnowledgeResponse['nodes'], ['subSectionId', parseInt(item)]);
                if (!isNil(requisite) && !isEmpty(requisite)) {
                  tempArr.push(requisite[0]);
                }
              })
            }

            setErrorRootCauseValue(tempArr);
          }
          else
            setErrorRootCauseValue([]);


          if (!isNil(params.node.data.hintText) && !isEmpty(params.node.data.hintText)) {
            if (!isNil(hintEditor)) {
              hintEditor.setData(params.node.data.hintText)
            }
          }
          else {
            if (!isNil(hintEditor)) {
              hintEditor.setData('');
            }
          }

          setRemedialText(params.node.data.remedialText);
          setIsCorrect(params.node.data.isCorrect);
          setNodeHints(params.node.data.hints === undefined ? [] : params.node.data.hints);
          setAddresourcelable(params.node.data.isCorrect ? "Add Hint" : "Add Remedial")
        }

        if (action === "delete") {
          if (params.node.data.optionId !== undefined)
            optionIds.push(params.node.data.optionId);

          params.api.applyTransaction({ remove: [params.node.data] });
          mcqArray.splice(params.node.rowIndex, 1)
        }
      }
    }
  };

  const addToList = () => {
    if (!isNil(equation) && equation !== "") {
      if (!isNil(tempOptionId)) {
        gridApi.setRowData(mcqArray === undefined ? [] : mcqArray);

        handleToggleSidebar(false);
        addnewAnswer = false;
      }
    }
    else {
      toast.error("Warning! Please enter Answer text first !", {
        position: toast.POSITION.BOTTOM_RIGHT
      });
    }
  }

  const backToList = () => {
    handleToggleSidebar(false);
  }

  const addNewOptionbtnClick = () => {

    // To show Node panel
    addnewAnswer = true;
    setHideText1(true);
    setShowEdgeProperties(false);
    setExpand(true);
    hideAddRemedials();
    handleToggleSidebar(true);
    setShowNodeFieldsForMCQ(true);
    setHideNodeFieldsForMCQ(false);

    mcqAddNewObj = {
      optionId: "",
      tempOptionId: "",
      challenge_id: "",
      optionText: "",
      requisiteKnowledgeText: "",
      requisiteKnowledge: '',
      isCorrect: true,
      isCorrectText: "",
      errorRootCause: "",
      resourceids: "",
      remedialText: "",
      remedialResources: "",
      remedialResourcedisplay: "",
      isRemedialResoueReusable: false,
      remedialResourceURLs: "",
      hints: [],
      subsection_id: "",
    };

    mcqArray = mcqArray.sort(compareG);
    if (mcqArray.length > 0) {
      maxOptionId = parseInt(mcqArray[mcqArray.length - 1]['optionId']);
    }

    maxOptionId = maxOptionId + 1;
    let nwoptionId = maxOptionId;

    mcqAddNewObj.challenge_id = challengeId;
    mcqAddNewObj.optionId = null;
    mcqAddNewObj.tempOptionId = nwoptionId;
    mcqAddNewObj.isCorrect = false;

    fetchHintText(challengeId, true);

    setnewoptionId(null);
    setTempOptionId(nwoptionId.toString())
    setEquation(mcqAddNewObj.optionText);

    if (!isNil(mcqAddNewObj.requisiteKnowledge)) {
      let defaultRequisiteKnowledge = mcqAddNewObj.requisiteKnowledge.split(',');
      let tempArr = []
      if (!isNil(defaultRequisiteKnowledge) && !isEmpty(defaultRequisiteKnowledge)) {
        defaultRequisiteKnowledge.forEach((item) => {
          const requisite = filter(requisiteKnowledgeResponse['nodes'], ['subSectionId', parseInt(item)]);
          if (!isNil(requisite) && !isEmpty(requisite)) {
            tempArr.push(requisite[0]);
          }
        })
      }
      setErrorRootCauseValue(tempArr);
    } else {
      setErrorRootCauseValue([]);
    }

    setNodehintRemedialText(mcqAddNewObj.hintText);
    if (!isNil(mcqAddNewObj.hintText) && !isEmpty(mcqAddNewObj.hintText)) {
      if (!isNil(hintEditor)) {
        hintEditor.setData(mcqAddNewObj.hintText)
      }
    } else {
      if (!isNil(hintEditor)) {
        hintEditor.setData('');
      }
    }

    setIsCorrect(false);
    mcqAddNewObj.isCorrectText = mcqAddNewObj.isCorrect ? "Yes" : "No"
    setIsCorrectText(mcqAddNewObj.isCorrectText)
    setRemedialText(mcqAddNewObj.remedialText);
    setRemedialResourceSelected(mcqAddNewObj.remedialResources);
    setNodehintRemedialResource(mcqAddNewObj.remedialResourceURLs);
    setNodeRemedialResourcedisplay(mcqAddNewObj.remedialResourcedisplay);
    setIsReusable(mcqAddNewObj.isRemedialResoueReusable);
    setnoderesourcetopicid(mcqAddNewObj.subsection_id);
    mcqAddNewObj.hints = [];
    setNodeHints(mcqAddNewObj.hints);

    mcqArray.unshift(clone(mcqAddNewObj));
    //   gridApi.setRowData(mcqArray === undefined ? [] :  mcqArray);
  };

  const onGridReady = (e) => {
    setGridApi(e.api);
    // e.api.setRowData(mcqArray === undefined ? [] :  mcqArray)
    e.api.sizeColumnsToFit();
  }

  useEffect(() => {
    if (newoptionId !== null && newoptionId !== undefined) {
      mcqDataObject.optionId = newoptionId;
    }
    else {
      mcqDataObject.optionId = null;
    }
  }, [newoptionId]);

  useEffect(() => {
    if (tempOptionId !== null && tempOptionId !== undefined) {
      mcqDataObject.tempOptionId = tempOptionId;
    }
    else {
      mcqDataObject.tempOptionId = null;
    }
  }, [tempOptionId]);

  ///////////////// MCQ Changes New Functions END ////////////////////
  const handlecomments = (event, commentfieldname, headername) => {
    event.preventDefault();
    setshowcommentmodal(true)
    setmodelheadertitle(headername)
    setfieldname(commentfieldname)
    setreviewcomments(null)
    switch (commentfieldname) {
      case "equation_comment":
        setreviewcomments(equation_comment)
        setequation_comment_color_change(false)
        setisread_equation_comment(true)
        break;
      case "equation_latex_comment":
        setreviewcomments(equation_latex_comment)
        setequation_latex_comment_color_change(false)
        setisread_equation_latex_comment(true)
        break;
      case "explanation_comment":
        setreviewcomments(explanation_comment)
        setexplanation_comment_color_change(false)
        setisread_explanation_comment(true)
        break;
      default:
        break;
    }
  }
  const handlereviewercomment = e => {
    let textvalue = e.target.value;
    switch (fieldname) {
      case "equation_comment":
        setequation_comment(textvalue)
        setreviewcomments(textvalue)
        break;
      case "equation_latex_comment":
        setequation_latex_comment(textvalue)
        setreviewcomments(textvalue)
        break;
      case "explanation_comment":
        setexplanation_comment(textvalue)
        setreviewcomments(textvalue)
        break;
      // case "hints_comment":
      //   sethints_comment(e.target.value)
      //   setreviewcomments(e.target.value)
      //   break;
      default:
        break;
    }
  }

  useEffect(() => {
    setElements((els) =>
      els.map((el) => {
        if (el.id === element.id) {
          el.data = {
            ...el.data,
            equation_comment: equation_comment
          };
        }
        return el;
      })
    );
  }, [equation_comment, setElements]);


  useEffect(() => {
    setElements((els) =>
      els.map((el) => {
        if (el.id === element.id) {
          el.data = {
            ...el.data,
            equation_latex_comment: equation_latex_comment
          };
        }
        return el;
      })
    );
  }, [equation_latex_comment, setElements]);

  useEffect(() => {
    setElements((els) =>
      els.map((el) => {
        if (el.id === element.id) {
          el.data = {
            ...el.data,
            explanation_comment: explanation_comment
          };
        }
        return el;
      })
    );
  }, [explanation_comment, setElements]);

  const commentColor = (fieldname, value) => {
    switch (fieldname) {
      case "equation_comment":
        setequation_comment_color_change(value)
        break;
      case "equation_latex_comment":
        setequation_latex_comment_color_change(value)
        break;
      case "explanation_comment":
        setexplanation_comment_color_change(value)
        break;
      default:
        break;
    }
  }


  const onConnect = (params) => {
    if (hasCyclicDependency(params.source, params.target)) {
      toast('Please check, there is chance of cyclic reference.', {
        type: toast.TYPE.WARNING,
        position: toast.POSITION.BOTTOM_RIGHT
      })
    }
    setElements((els) =>
      addEdge(
        {
          ...params,
          type: "default",
          animated: true,
          style: { stroke: IsCorrect ? "#000000" : INCORRECT_COLOR, cursor: "pointer" },
          label: props.readOnly ? "" : "Click to select",
          labelStyle: {
            fill: "#000",
            fontWeight: "100",
            fontSize: "12px",
            cursor: "pointer"
          },
          arrowHeadType: "arrow"
        },
        els
      )
    );
  }

  // const updateCyclicDependency = (elementsToRemove) => {
  //   let node = filter(clone(elements), { id: elementsToRemove.target });

  //   for (let i = 0; i < elements.length; i++) {
  //     let el = elements[i];
  //     if (el.type !== 'default') {
  //       if (!isNil(el.data.pc) && (el.data.pc).match(node[0]['data']['pc']) !== '') {
  //         el.data.pc = el.data.pc.slice(node[0]['data']['pc'].length, el.data.length);
  //       }
  //     }
  //   }
  // }
  const hasCyclicDependency = (source, target) => {
    if (props.readOnly) return;
    let hasDependency = false;
    let node = filter(elements, { "id": source });
    const pc = node[0]['data']['pc'];
    if (pc !== '' && !isNil(pc)) {
      const parents = pc.split(",");
      parents.forEach((id) => {
        if (id === target) {
          hasDependency = true;
        }
      })
    }
    if (!hasDependency) {
      let targetNode = filter(elements, { "id": target });
      targetNode[0]['data']['pc'] = node[0]['data']['pc'] !== '' ? node[0]['data']['pc'] + "," + source : source;

    }
    return hasDependency
  }

  const onElementsRemove = (elementsToRemove) => {
    if (!isNil(elementsToRemove) && elementsToRemove[0].type === 'default') {
      // updateCyclicDependency(elementsToRemove[0]);
      setElements((els) => removeElements(elementsToRemove, els));
    } else {

      let currentSteps = [...elements];
      const index = currentSteps.indexOf(element);

      setElements((els) => removeElements([elements[index]], els));
      setHideText1(false);
      handleToggleSidebar(!toggled)
      setNewNodeId(null)
    }


  }
  const onLoad = (_reactFlowInstance) => {
    setReactFlowInstance(_reactFlowInstance);
    setOpenMCQGrid(false);
    setOpenHideReactFlow(true);

    if (!isNil(params.challengeId)) {
      if (params.challengeId.includes("-")) {
        challengeId = params.challengeId.split('-')[0];
        challengeType = params.challengeId.split('-')[1];
      }
      else {
        challengeId = params.challengeId
        challengeType = "challengeType"
      }

      if (challengeType.toLowerCase().includes("mcq") || challengeType.toLowerCase().includes("msq")) {
        setOpenMCQGrid(true);
        setOpenHideReactFlow(false);
      }
    }
  }

  const onDragOver = (event) => {
    event.preventDefault();
    event.dataTransfer.dropEffect = "move";
  };

  const reactFlowWrapper = useRef(null);
  const [toggled, setToggled] = useState(false);
  const handleToggleSidebar = (value) => {
    setToggled(value);
  };

  const onDragStart = (event, nodeType, nodeDesign) => {
    setisUEdragdrop(false)
    event.dataTransfer.setData("application/reactflow", nodeType);
    event.dataTransfer.setData("application/reactflow2", nodeDesign);
    event.dataTransfer.effectAllowed = "move";
  };

  const onDragStartUE = (event, nodeType, nodeDesign, itemdata) => {
    setisUEdragdrop(true)
    setuejsonelement(itemdata)
    event.dataTransfer.setData("application/reactflow", nodeType);
    event.dataTransfer.setData("application/reactflow2", nodeDesign);
    event.dataTransfer.effectAllowed = "move";

  }


  const onDrop = (event) => {

    event.preventDefault();
    const reactFlowBounds = reactFlowWrapper.current.getBoundingClientRect();
    const type = event.dataTransfer.getData("application/reactflow");
    const design = event.dataTransfer.getData("application/reactflow2");
    const position = reactFlowInstance.project({
      x: event.clientX - reactFlowBounds.left,
      y: event.clientY - reactFlowBounds.top
    });



    maxNodeId = maxNodeId + 1;
    let nwnodeid = maxNodeId;

    if (!isUEdragdrop) {
      setElements((es) =>
        es.concat({
          id: nwnodeid.toString(),
          isue: false,
          type,
          position,
          data: { ...data, hints: [] },
          hidden: true,
          originalPosition: position,
          style: {
            wordWrap: "break-word",
            textAlign: "center",
            borderColor: "#00008B",
            maxHeight: "1400px"
          }
        })
      );
      setsavegraphDisabled(false)
    }
    else {

      if (uejsonelement !== undefined && uejsonelement !== null && uejsonelement !== "")
        setdraggedUEIds(draggedUEIds => [...draggedUEIds, parseInt(uejsonelement.id)]);

      let data1 = uejsonelement

      setElements((es) =>
        es.concat({
          id: nwnodeid.toString(),
          type: data1.type,
          position: position,
          data: { ...data1.data, hints: [], displaynodeid: "N" + maxNodeId.toString() },
          hidden: data1.hidden,
          originalPosition: position,
          isue: data1.isue,
          style: {
            wordWrap: "break-word",
            textAlign: "center",
            borderColor: "#00008B",
            maxHeight: "1400px"
          }
        })
      );
      setsavegraphDisabled(true)
    }
    setNewNodeId(nwnodeid.toString())
  };

  useEffect(() => {
    if (newnodeid !== null) {
      const timer = setTimeout(() => {
        elements.map((element) => {
          console.log("new drag drop element", elements)
          elementClickHandler(element, newnodeid)
        });
      }, 300);
      return () => clearTimeout(timer);
    }

  }, [newnodeid])


  const handleErrorType = (name, value) => {
    setErrorType(name);
  }
  const handleChange = (name, value) => {
    setNodeImportance(name);
  }
  const handlecorrect = (evt) => {

    setIsCorrect(evt.target.checked)
    if (!evt.target.checked) {
      setErrorType(BASIC_ERROR)
      sethintpershow(false)
    }
    else {
      sethintpershow(true)
    }
    fetchHintText(challengeId, evt.target.checked);

  }
  const handleError = () => {
    // if (IsCorrect) {
    //   setHintText("Error Root Cause (Requisite knowledge)")
    // }
    // else {
    //   setHintText("Hint Text")
    // }
  }
  // const callbackFunction = (childData) => {
  //   setWidth(childData);
  // };

  // const callbackFunction2 = (childData) => {
  //   setHeight(childData);
  // };

  const isEdge = (val) => {
    if (
      val === "default" ||
      val === "straight" ||
      val === "step" ||
      val === "smoothstep"
    ) {
      return true;
    } else {
      return false;
    }
  };


  const onElementClick = (event, elem) => {
    if (props.readOnly && props.isKnowledgeGraph && props.openContentPopup) {
      props.openContentPopup(event, elem.id);
    } if (props.readOnly) {
      return;
    } else {
      hideAddRemedials();
      elements.map((element) => {
        elementClickHandler(element, elem.id);
      });
      handleToggleSidebar(isEdge(elem.type) ? false : true);
    }
  };

  const handleNodeMouseEvents = (eventType, element) => {
    setElements((els) =>
      els.map((el) => {
        if (el.id === element.id) {
          const showExplanation = eventType === 'onNodeMouseEnter' ? true : false;
          el.data = {
            ...el.data,
            showExplanation: showExplanation
          };
        }
        return el;
      })
    );
  }
  const elementClickHandler = (element, id) => {
    if (element.id === id) {

      if (challengedata.challenge_state === Challenge_status.released && uejsondata !== undefined && uejsondata !== null && uejsondata.length > 0) {

        if (element.data.is_ue) {

          if (element.data.uenodedisable !== undefined && element.data.uenodedisable !== null) {
            if (element.data.uenodedisable) {
              setisDisabledNotForUE(true)
            }
            else {
              setisDisabledNotForUE(false)
            }
          }
          else {
            setisDisabledNotForUE(true)
          }

        }
        else {
          setisDisabledNotForUE(true)
        }
      }
      else {
        setisDisabledNotForUE(false)
      }
      handleToggleSidebar(isEdge(element.type) ? false : true);

      if (isEdge(element.type)) {
        toast('Press delete/backspace key to delete selected edge', {
          type: toast.TYPE.WARNING,
          position: toast.POSITION.BOTTOM_RIGHT
        });
      }

      fetchHintText(challengeId, element.data.isNodeCorrect);
      setElement(element);
      if (isEdge(element.type)) {
        setEdgeType(element.type);
        setEdgeLabel(element.label);
        setEdgeLabelStyle(element.labelStyle);
        setEdgeAnimated(element.animated);
        setEdgeArrowHead(element.arrowHeadType);
      } else {

        lastSelectedElement = element;
        setNodeX(element.position.x);
        setNodeY(element.position.y);
        setNodeName(element.data.label);
        setEquation(element.data.equation);
        setresourceids(element.data.resourceids)
        setErrorType(element.data.errortype);
        setNodeImportance(element.data.importance);
        setNodeBg(element.data.background);
        if (!isNil(element.data.mathml)) {
          setNodeMathml(element.data.mathml)
        } else {
          setNodeMathml('');
        }

        if (!isNil(element.data.unitMathml)) {
          setUnitMathml(element.data.unitMathml)
        } else {
          setUnitMathml('');
        }

        if (!isNil(element.data.unit) && element.data.unit !== '') {
          setUnit(element.data.unit)
        } else {
          setUnit('');
        }
        if (!isNil(element.data.points) && element.data.points !== '') {
          setPoints(Number(element.data.points))
        } else {
          setPoints(0);
        }

        if (!isNil(element.data.decimalPoints) && element.data.decimalPoints !== '') {
          setdecimalPoints(Number(element.data.decimalPoints))
        } else {
          setdecimalPoints(2);
        }


        if (!isNil(element.data.hints_perc) && element.data.hints_perc !== '') {
          sethints_perc(Number(element.data.hints_perc))
        } else {
          sethints_perc(0);
        }

        setNodeExplanation(element.data.explanation);
        if (!isNil(element.data.explanation) && !isEmpty(element.data.explanation)) {
          if (!isNil(explanationEditor)) {
            explanationEditor.setData(element.data.explanation);
          }
        }

        else {
          if (!isNil(explanationEditor)) {
            explanationEditor.setData('');
          }

        }

        if (!isNil(element.data.requisiteKnowledge)) {

          let defaultRequisiteKnowledge = element.data.requisiteKnowledge.split(',');
          let tempArr = []
          if (!isNil(defaultRequisiteKnowledge) && !isEmpty(defaultRequisiteKnowledge) && !isNil(requisiteKnowledgeResponse)) {
            defaultRequisiteKnowledge.forEach((item) => {
              const requisite = filter(requisiteKnowledgeResponse['nodes'], ['subSectionId', parseInt(item)]);
              if (!isNil(requisite) && !isEmpty(requisite)) {
                tempArr.push(requisite[0]);
              }
            })
          }
          setErrorRootCauseValue(tempArr);
        } else {
          setErrorRootCauseValue([]);
        }

        setNodehintRemedialText(element.data.hintText)
        if (!isNil(element.data.hintText) && !isEmpty(element.data.hintText)) {
          if (!isNil(hintEditor)) {
            hintEditor.setData(element.data.hintText)
          }
        } else {
          if (!isNil(hintEditor)) {
            hintEditor.setData('');
          }
        }

        setRemedialText(element.data.RemedialText);
        setRemedialResourceSelected(element.data.remedialResources);
        setNodehintRemedialResource(element.data.hintRemedialResource);
        setNodeRemedialResourcedisplay(element.data.nodeRemedialResourcedisplay)
        setRadius(element.data.border);
        setIsReusable(element.data.isRemedialResoueReusable)
        setHidden(element.data.hidden);

        // if(nodeHints.length > 0)
        // {
        //   let temphints = nodeHints.filter(ele => ele.node_id.toString() === element.id.toString());         
        //   setNodeHints(temphints === undefined ? [] : temphints);
        // }
        // else
        // {

        setNodeHints(element.data.hints === undefined ? [] : element.data.hints);
        // }

        setIsCorrect(element.data.isNodeCorrect);

        if (element.data.equation_latex_comment === undefined) {
          setequation_latex_comment("");
        }
        else {
          setequation_latex_comment(element.data.equation_latex_comment);
        }

        if (element.data.equation_comment === undefined) {
          setequation_comment("");
        }
        else {
          setequation_comment(element.data.equation_comment);
        }
        if (element.data.explanation_comment === undefined) {
          setexplanation_comment("");
        }
        else {
          setexplanation_comment(element.data.explanation_comment);
        }
        if (element.data.equation_comment !== element.data.equation_previous_comment && isread_equation_comment === false) {
          commentColor("equation_comment", true)
        }
        if (element.data.equation_latex_comment !== element.data.equation_latex_previous_comment && isread_equation_latex_comment === false) {
          commentColor("equation_latex_comment", true)
        }
        if (element.data.explanation_comment !== element.data.explanation_previous_comment && isread_explanation_comment === false) {
          commentColor("explanation_comment", true)
        }
        // setequation_previous_comment(element.data.equation_previous_comment)
        // setequation_latex_previous_comment(element.data.equation_latex_previous_comment)
        // setexplanation_previous_comment(element.data.explanation_previous_comment)
      }

      // Edge
      if (isEdge(element.type)) {
        setShowEdgeProperties(true);
      }
      // Node
      if (element.type === "nodeWithOnlyText") {
        setHideText1(true);
        setRadius("#ffc107");
        setShowEdgeProperties(false);
      }
    }

  }

  const getLayoutedElements = (direction = 'TB') => {

    const isHorizontal = false;
    dagreGraph.setGraph({ rankdir: direction });
    elements.forEach((node) => {

      if (!isEdge(node.type)) {
        dagreGraph.setNode(node.id, { width: node.data.width, height: node.data.height });
      } else {
        dagreGraph.setEdge(node.source, node.target);
      }

    });

    dagre.layout(dagreGraph);
    elements.forEach((node) => {

      if (!isEdge(node.type)) {
        const nodeWithPosition = dagreGraph.node(node.id);
        node.targetPosition = isHorizontal ? 'left' : 'top';
        node.sourcePosition = isHorizontal ? 'right' : 'bottom';
        node.position = {
          x: nodeWithPosition.x,
          y: nodeWithPosition.y,
        };

        return node;
      }


      // We are shifting the dagre node position (anchor=center center) to the top left
      // so it matches the React Flow node anchor point (top left).

    });
    setElements([...elements]);

  };

  const onDragEnd = (event, element) => {
    setElement(element);
    setNodeX(element.position.x);
    setNodeY(element.position.y);
    if (!isNil(lastSelectedElement)) {
      setElement(lastSelectedElement);
    }
  };

  // Edge
  useEffect(() => {
    setElements((els) =>
      els.map((el) => {
        if (el.id === element.id) {
          el = { ...el, type: edgeType };
        }
        return el;
      })
    );
  }, [edgeType, setElements]);

  useEffect(() => {

    setElements((els) =>
      els.map((el) => {
        if (el.id === element.id) {
          el = { ...el, label: edgeLabel };
        }
        return el;
      })
    );
  }, [edgeLabel, setElements]);

  useEffect(() => {

    setElements((els) =>
      els.map((el) => {
        if (el.id === element.id) {
          el = { ...el, animated: edgeAnimated };
        }
        return el;
      })
    );
  }, [edgeAnimated, setElements]);

  useEffect(() => {

    setElements((els) =>
      els.map((el) => {
        if (el.id === element.id) {
          el = { ...el, arrowHeadType: edgeArrowHead };
        }
        return el;
      })
    );
  }, [setEdgeArrowHead, setElements]);

  useEffect(() => {

    setElements((els) =>
      els.map((el) => {
        if (el.id === element.id) {
          el.labelStyle = {
            ...el.labelStyle,
            fontSize: edgeLabelStyle.fontSize
          };
        }
        return el;
      })
    );
  }, [edgeLabelStyle, setElements]);

  useEffect(() => {

    setElements((els) =>
      els.map((el) => {
        if (el.id === element.id) {
          el.labelStyle = {
            ...el.labelStyle,
            fill: edgeLabelStyle.fill
          };
        }
        return el;
      })
    );
  }, [edgeLabelStyle, setElements]);

  useEffect(() => {

    setElements((els) =>
      els.map((el) => {
        if (el.id === element.id) {
          el.labelStyle = {
            ...el.labelStyle,
            fontWeight: edgeLabelStyle.fontWeight
          };
        }
        return el;
      })
    );
  }, [edgeLabelStyle, setElements]);

  // Node
  useEffect(() => {

    let color = "#ffc107"
    setElements((els) =>
      els.map((el) => {
        el.data = {
          ...el.data,
          border: el.id === element.id ? color : nodeBackground
        };
        return el;
      })
    );
  }, [border, setElements]);


  useEffect(() => {
    if (showNodeFieldsForMCQ || openHideMCQGrid) {
      var optionIdIndex = null
      var tempOptionIdIndex = null
      if (!isNil(mcqDataObject.tempOptionId)) {
        if (!isNil(gridApi.getRowNode(mcqDataObject.tempOptionId)))
          tempOptionIdIndex = mcqArray.map(function (obj) { return obj.tempOptionId; }).indexOf(mcqDataObject.tempOptionId)
      }
      else
        optionIdIndex = mcqArray.map(function (obj) { return obj.optionId; }).indexOf(mcqDataObject.optionId)

      let rowNodeIndex = optionIdIndex === null ? (tempOptionIdIndex === null ? 0 : tempOptionIdIndex) : optionIdIndex
      let row = mcqArray[mcqArray.length === -1 ? 0 : rowNodeIndex];
      if (row !== undefined) {
        row.isCorrect = IsCorrect
        gridApi.refreshCells();
      }

      setAddresourcelable(IsCorrect ? "Add Hint" : "Add Remedial")
      if (showNodeFieldsForMCQ && IsCorrect) {
        if (isCorrectFlag && challengeType.toLowerCase() === "mcq" && correctCount > 1) {
          toast.error("Warning! correct option already added, please check!", {
            position: toast.POSITION.BOTTOM_RIGHT
          });
        }
      }
    }
    else {
      setElements((els) =>
        els.map((el) => {
          if (el.id === element.id) {
            el.data = {
              ...el.data,
              isNodeCorrect: IsCorrect,
              background: !IsCorrect ? INCORRECT_COLOR : (filter(NODE_BACKGROUND, ['type', nodeImportance]))[0]['color']
            };
            setAddresourcelable(IsCorrect ? "Add Hint" : "Add Remedial")
            changeEdgeForIncorrect(IsCorrect, element); //Error in this function
          }
          return el;
        })
      );
    }
  }, [IsCorrect, setElements]);

  useEffect(() => {

    setElements((els) =>
      els.map((el) => {
        if (el.id === element.id) {

          el.data = {
            ...el.data,
            importance: nodeImportance,
            background: !IsCorrect ? INCORRECT_COLOR : (filter(NODE_BACKGROUND, ['type', nodeImportance]))[0]['color']
          };
        }
        return el;
      })
    );

  }, [nodeImportance, setElements]);

  useEffect(() => {

    setElements((els) =>
      els.map((el) => {
        if (el.id === element.id) {
          el.data = {
            ...el.data,
            errortype: nodeErrorType
          };

        }
        return el;
      })
    );

  }, [nodeErrorType, setElements]);


  useEffect(() => {

    setElements((els) =>
      els.map((el) => {
        if (el.id === element.id) {
          el.data = {
            ...el.data,
            explanation: nodeExplanation
          };

        }
        return el;
      })
    );

  }, [nodeExplanation, setElements]);

  useEffect(() => {
    setElements((els) =>
      els.map((el) => {
        if (el.id === element.id) {
          el.data = {
            ...el.data,
            label: nodeName,
            style: {
              width: getTextWidth(nodeName)
            }
          };
        }
        return el;
      })
    );
  }, [nodeName, setElements]);

  useEffect(() => {
    setElements((els) =>
      els.map((el) => {
        if (el.id === element.id) {
          el.data = {
            ...el.data,
            mathml: nodeMathml
          };
        }
        return el;
      })
    );
  }, [nodeMathml, setElements]);

  useEffect(() => {
    setElements((els) =>
      els.map((el) => {
        if (el.id === element.id) {
          el.data = {
            ...el.data,
            unitMathml: unitMathml
          };
        }
        return el;
      })
    );
  }, [unitMathml, setElements]);

  useEffect(() => {
    if (showNodeFieldsForMCQ) {
      var optionIdIndex = null
      var tempOptionIdIndex = null
      if (!isNil(mcqDataObject.tempOptionId)) {
        if (!isNil(gridApi.getRowNode(mcqDataObject.tempOptionId)))
          tempOptionIdIndex = mcqArray.map(function (obj) { return obj.tempOptionId; }).indexOf(mcqDataObject.tempOptionId)
      }
      else
        optionIdIndex = mcqArray.map(function (obj) { return obj.optionId; }).indexOf(mcqDataObject.optionId)

      let rowNodeIndex = optionIdIndex === null ? (tempOptionIdIndex === null ? 0 : tempOptionIdIndex) : optionIdIndex
      let row = mcqArray[mcqArray.length === -1 ? 0 : rowNodeIndex];
      if (row !== undefined)
        row.resourceId = resourceids;

      gridApi.refreshCells();
    }
    else {
      setElements((els) =>
        els.map((el) => {
          if (el.id === element.id) {
            el.data = {
              ...el.data,
              resourceids: resourceids,

            };
          }
          return el;
        })
      );
    }
  }, [resourceids, setElements]);

  useEffect(() => {
    if (showNodeFieldsForMCQ) {
      var optionIdIndex = null
      var tempOptionIdIndex = null
      if (!isNil(mcqDataObject.tempOptionId)) {
        if (!isNil(gridApi.getRowNode(mcqDataObject.tempOptionId)))
          tempOptionIdIndex = mcqArray.map(function (obj) { return obj.tempOptionId; }).indexOf(mcqDataObject.tempOptionId)
      }
      else
        optionIdIndex = mcqArray.map(function (obj) { return obj.optionId; }).indexOf(mcqDataObject.optionId)

      let rowNodeIndex = optionIdIndex === null ? (tempOptionIdIndex === null ? 0 : tempOptionIdIndex) : optionIdIndex
      let row = mcqArray[mcqArray.length === -1 ? 0 : rowNodeIndex];
      if (row !== undefined)
        row.optionText = equation;

      gridApi.refreshCells();
    }
    else {
      setElements((els) =>
        els.map((el) => {
          if (el.id === element.id) {
            el.data = {
              ...el.data,
              equation: equation,

            };
          }
          return el;
        })
      )
    }
  }, [equation, setElements]);

  useEffect(() => {
    setElements((els) =>
      els.map((el) => {
        if (el.id === element.id) {
          el.data = { ...el.data, unit: unit };
        }
        return el;
      })
    );
  }, [unit, setElements]);

  useEffect(() => {
    setElements((els) =>
      els.map((el) => {
        if (el.id === element.id) {
          el.data = { ...el.data, hints_perc: hints_perc };
        }
        return el;
      })
    );
  }, [hints_perc, setElements]);

  useEffect(() => {
    setElements((els) =>
      els.map((el) => {
        if (el.id === element.id) {
          el.data = { ...el.data, points: points };
        }
        return el;
      })
    );
  }, [points, setElements]);

  useEffect(() => {
    setElements((els) =>
      els.map((el) => {
        if (el.id === element.id) {
          el.data = { ...el.data, decimalPoints: decimalPoints };
        }
        return el;
      })
    );
  }, [decimalPoints, setElements]);

  useEffect(() => {
    setElements((els) =>
      els.map((el) => {
        if (el.id === element.id) {
          el.data = { ...el.data, background: nodeBg };
          if (!IsCorrect) {
            el.data = { ...el.data, background: INCORRECT_COLOR };
          }
        }
        return el;
      })
    );
  }, [nodeBg, setElements]);

  useEffect(() => {
    setElements((els) =>
      els.map((el) => {
        if (el.id === element.id) {
          el.data = { ...el.data, width: width };
        }

        return el;
      })
    );
  }, [width, setElements]);

  useEffect(() => {
    setElements((els) =>
      els.map((el) => {
        if (el.id === element.id) {
          el.data = { ...el.data, height: height };
        }

        return el;
      })
    );
  }, [height, setElements]);

  useEffect(() => {
    setElements((els) =>
      els.map((el) => {
        if (el.id === element.id) {
          el.position = {
            ...el.position,
            x: nodeX
          };
          el.originalPosition = {
            ...el.originalPosition,
            x: nodeX
          };
        }

        return el;
      })
    );
  }, [nodeX, setElements]);

  useEffect(() => {
    setElements((els) =>
      els.map((el) => {
        if (el.id === element.id) {
          el.position = {
            ...el.position,
            y: nodeY
          };
          el.originalPosition = {
            ...el.originalPosition,
            y: nodeY
          };
        }

        return el;
      })
    );
  }, [nodeY, setElements]);


  useEffect(() => {
    if (showNodeFieldsForMCQ) {
      var optionIdIndex = null
      var tempOptionIdIndex = null
      if (!isNil(mcqDataObject.tempOptionId)) {
        if (!isNil(gridApi.getRowNode(mcqDataObject.tempOptionId)))
          tempOptionIdIndex = mcqArray.map(function (obj) { return obj.tempOptionId; }).indexOf(mcqDataObject.tempOptionId)
      }
      else
        optionIdIndex = mcqArray.map(function (obj) { return obj.optionId; }).indexOf(mcqDataObject.optionId)

      let rowNodeIndex = optionIdIndex === null ? (tempOptionIdIndex === null ? 0 : tempOptionIdIndex) : optionIdIndex
      let row = mcqArray[mcqArray.length === -1 ? 0 : rowNodeIndex];
      if (row !== undefined)
        row.requisiteKnowledge = nodeRootCause;

      gridApi.refreshCells();
    }
    else {
      setElements((els) =>
        els.map((el) => {
          if (el.id === element.id) {
            el.data = { ...el.data, requisiteKnowledge: nodeRootCause };
          }

          return el;
        })
      );
    }
  }, [nodeRootCause, setElements]);

  useEffect(() => {
    if (showNodeFieldsForMCQ) {
      var optionIdIndex = null
      var tempOptionIdIndex = null
      if (!isNil(mcqDataObject.tempOptionId)) {
        if (!isNil(gridApi.getRowNode(mcqDataObject.tempOptionId)))
          tempOptionIdIndex = mcqArray.map(function (obj) { return obj.tempOptionId; }).indexOf(mcqDataObject.tempOptionId)
      }
      else
        optionIdIndex = mcqArray.map(function (obj) { return obj.optionId; }).indexOf(mcqDataObject.optionId)

      let rowNodeIndex = optionIdIndex === null ? (tempOptionIdIndex === null ? 0 : tempOptionIdIndex) : optionIdIndex
      let row = mcqArray[mcqArray.length === -1 ? 0 : rowNodeIndex];
      if (row !== undefined)
        row.requisiteKnowledgeText = nodeRootCauseText;

      gridApi.refreshCells();
    }
    else {
      setElements((els) =>
        els.map((el) => {

        })
      );
    }
  }, [nodeRootCauseText, setElements]);

  useEffect(() => {
    if (showNodeFieldsForMCQ) {
      var optionIdIndex = null
      var tempOptionIdIndex = null
      if (!isNil(mcqDataObject.tempOptionId)) {
        if (!isNil(gridApi.getRowNode(mcqDataObject.tempOptionId)))
          tempOptionIdIndex = mcqArray.map(function (obj) { return obj.tempOptionId; }).indexOf(mcqDataObject.tempOptionId)
      }
      else
        optionIdIndex = mcqArray.map(function (obj) { return obj.optionId; }).indexOf(mcqDataObject.optionId)

      let rowNodeIndex = optionIdIndex === null ? (tempOptionIdIndex === null ? 0 : tempOptionIdIndex) : optionIdIndex
      let row = mcqArray[mcqArray.length === -1 ? 0 : rowNodeIndex];
      if (row !== undefined)
        row.isCorrectText = IsCorrect ? "Yes" : "No";


      gridApi.refreshCells();
    }
    else {
      setElements((els) =>
        els.map((el) => {
        })
      );
    }
  }, [isCorrectText, setElements]);

  useEffect(() => {
    if (openHideMCQGrid) {
      mcqDataObject.hintText = nodehintRemedialText;
    }
    else {
      setElements((els) =>
        els.map((el) => {
          if (el.id === element.id) {
            el.data = { ...el.data, hintText: nodehintRemedialText };
          }
          return el;
        })
      );
    }
  }, [nodehintRemedialText, setElements]);

  useEffect(() => {
    if (openHideMCQGrid) {
      mcqDataObject.remedialText = remedialText;
    }
    else {
      setElements((els) =>
        els.map((el) => {
          if (el.id === element.id) {
            el.data = { ...el.data, remedialText: remedialText };
          }

          return el;
        })
      );
    }
  }, [remedialText, setElements]);

  useEffect(() => {
    if (openHideMCQGrid) {
      mcqDataObject.remedialResources = remedialResourceSelected;
    }
    else {
      setElements((els) =>
        els.map((el) => {
          if (el.id === element.id) {
            el.data = { ...el.data, remedialResources: remedialResourceSelected };
          }

          return el;
        })
      );
    }
  }, [remedialResourceSelected, setElements]);

  useEffect(() => {
    if (openHideMCQGrid) {
      mcqDataObject.remedialResourcedisplay = nodeRemedialResourcedisplay;
    }
    else {
      setElements((els) =>
        els.map((el) => {
          if (el.id === element.id) {
            el.data = { ...el.data, remedialResourcedisplay: nodeRemedialResourcedisplay };
          }

          return el;
        })
      );
    }
  }, [nodeRemedialResourcedisplay, setElements]);

  useEffect(() => {
    if (openHideMCQGrid) {
      mcqDataObject.subsection_id = noderesourcetopicsubsectionid;
    }
    else {
      setElements((els) =>
        els.map((el) => {
          if (el.id === element.id) {

            el.data = { ...el.data, sub_section_id: noderesourcetopicsubsectionid };
          }

          return el;
        })
      );
    }
  }, [noderesourcetopicsubsectionid, setElements]);

  useEffect(() => {
    if (openHideMCQGrid) {
      mcqDataObject.resourcetopicmasterid = resourcetopicId;
    }
    else {
      setElements((els) =>
        els.map((el) => {
          if (el.id === element.id) {

            el.data = { ...el.data, resourcetopicmasterid: resourcetopicId };
          }

          return el;
        })
      );
    }
  }, [resourcetopicId, setElements]);

  useEffect(() => {
    if (openHideMCQGrid) {
      //  let row = mcqArray[mcqArray.length === -1 ? 0 : mcqArray.length - 1];
      mcqDataObject.isRemedialResoueReusable = isreusable;
    }
    else {
      setElements((els) =>
        els.map((el) => {
          if (el.id === element.id) {
            el.data = { ...el.data, isRemedialResoueReusable: isreusable };
          }

          return el;
        })
      );
    }
  }, [isreusable, setElements]);

  useEffect(() => {
    if (openHideMCQGrid) {
      //let row = mcqArray[mcqArray.length === -1 ? 0 : mcqArray.length - 1];
      mcqDataObject.remedialResourceURLs = nodehintRemedialResource;
    }
    else {
      setElements((els) =>
        els.map((el) => {
          if (el.id === element.id) {
            el.data = { ...el.data, remedialResourceURLs: nodehintRemedialResource };
          }

          return el;
        })
      );
    }
  }, [nodehintRemedialResource, setElements]);

  useEffect(() => {
    if (openHideMCQGrid) {
      mcqDataObject.hints = nodeHints;
      if (nodeHints !== undefined) {
        if (showNodeFieldsForMCQ) {
          var optionIdIndex = null
          var tempOptionIdIndex = null
          if (!isNil(mcqDataObject.tempOptionId)) {
            if (!isNil(gridApi.getRowNode(mcqDataObject.tempOptionId)))
              tempOptionIdIndex = mcqArray.map(function (obj) { return obj.tempOptionId; }).indexOf(mcqDataObject.tempOptionId)
          }
          else
            optionIdIndex = mcqArray.map(function (obj) { return obj.optionId; }).indexOf(mcqDataObject.optionId)

          let rowNodeIndex = optionIdIndex === null ? (tempOptionIdIndex === null ? 0 : tempOptionIdIndex) : optionIdIndex
          let row = mcqArray[mcqArray.length === -1 ? 0 : rowNodeIndex];
          if (row !== undefined)
            row.hints = nodeHints;

          gridApi.refreshCells();
        }
      }
    }
    else {
      setElements((els) =>
        els.map((el) => {
          if (el.id === element.id) {

            el.data = { ...el.data, hints: nodeHints };
          }

          return el;
        })
      );
    }
  }, [nodeHints, setElements]);

  useEffect(() => {

    console.log("layout direction.....", layoutDirection)
    if (layoutDirection !== '') {
      setTimeout(() => {
        getLayoutedElements();
      }, 1000)
      //isAutoPositioningRequired = false;
      setLayoutDirection("");
    }

  }, [layoutDirection, setElements]);

  const clearElements = () => {
    setElements((els) => els.splice(0, els.length));
  }

  useEffect(() => {

    clearElements();
    if (!isNil(props) && (props.readOnly)) {

      clearTimeout(debounceTimer);
      setDebounceTimer(setTimeout(() => {
        setElements(parseUIJson(JSON.parse(props.uiJson)));
        setTimeout(() => {
          setLayoutDirection('TB')
        }, 1000)
      }, 700));

    } else {
      getNodes(params.challengeId.split('-')[0])
    }
  }, [props])

  useEffect(() => {
    if (aftersave) {
      clearElements();
      getNodes(params.challengeId.split('-')[0])
    }

  }, [aftersave])

  // useEffect(() => {    
  //   if(statustext)
  //   {
  //    setDsipsplaystatustext(<div><span class="statustext">Kindly change the challenge status to "Under Development" using the<Link
  //         to={`/addchallenges/${params.challengeId}`}
  //         style={{ fontSize: "12px", boxShadow: "none", textDecoration: 'none' }}> Edit Challenge</Link> link to request edits to the solution!
  //     </span></div>)
  //   }
  //   else
  //   {
  //     setDsipsplaystatustext("")
  //   }
  // }, [statustext])

  const getNodes = (challengeId) => {
    setisloading(true)
    let isMounted = true;
    getChallengenodes(challengeId).then(res => {

      if (isMounted) {
        if (res.status === 200 && res.data.UI_Json !== null && res.data.UI_Json !== "") {

          setElements(parseUIJson(JSON.parse(res.data.UI_Json)));
          //setTimeout(setLayoutDirection('TB'), 1000);
        }
        if (res.status === 200 && res.data.UE_Json !== null && res.data.UE_Json !== "") {
          setuejsondata(parseUIJsonUE(JSON.parse(res.data.UE_Json)));
          //setTimeout(setLayoutDirection('TB'), 1000);
        }
        if (res.status === 200 && res.data.RIncorrect_Json !== null && res.data.RIncorrect_Json !== "") {
          setRIncorrectjsondata(parseUIJsonUE(JSON.parse(res.data.RIncorrect_Json)));
        }
        if (res.data.challenge_state === Challenge_status.released) {
          setStatusText(<div class="middlediv"><span class="statustext">Kindly change the challenge status to "Under Development" using the<Link
            to={`/addchallenges/${challengeId}`}
            style={{ fontSize: "12px", boxShadow: "none", textDecoration: 'none' }}> Edit Challenge</Link> link to request edits to the solution!
          </span></div>)
        }
        else {
          setStatusText("")
        }

        setChallengeData(prevState => ({
          ...prevState,
          challenge_state: res.data.challenge_state
          , user_id: res.data.user_id
          , reviewer_user_id: res.data.reviewer_user_id
          , expert_user_id: res.data.expert_user_id
          , modified_user_id: res.data.modified_user_id
        }));
        fetchHintText(challengeId, true);

        if (res.data.reviewer_user_id !== null && (res.data.challenge_state === Challenge_status.underreview || res.data.challenge_state === Challenge_status.reviewed || res.data.challenge_state === Challenge_status.reviewinprogress)) {
          setshowcommentsfields(true)
        }

        switch (getCurrentUserRole()) {
          case User_Role.ContentAuthor:
            if (res.data.challenge_state === undefined || res.data.challenge_state === null || res.data.challenge_state === Challenge_status.underdevelopment || res.data.challenge_state === Challenge_status.reviewed) {
              setButtondisabled(false)
            }
            else if (res.data.challenge_state === Challenge_status.reviewinprogress || res.data.challenge_state === Challenge_status.betareleased || res.data.challenge_state === Challenge_status.released || res.data.challenge_state === Challenge_status.underreview) {
              setButtondisabled(true)
            }
            break;
          case User_Role.ContentReviewer:
            if (res.data.challenge_state === Challenge_status.underdevelopment || res.data.challenge_state === Challenge_status.released || res.data.challenge_state === Challenge_status.betareleased || res.data.challenge_state === Challenge_status.reviewed) {
              setButtondisabled(true)
            }
            else if (res.data.challenge_state === Challenge_status.underreview || res.data.challenge_state === Challenge_status.reviewinprogress) {
              setButtondisabled(false)
            }
            break;
          case User_Role.ContentExpert:
            if (res.data.challenge_state === Challenge_status.underdevelopment || res.data.challenge_state === Challenge_status.reviewed || res.data.challenge_state === Challenge_status.released) {
              setButtondisabled(true)
            }
            else if (res.data.challenge_state === Challenge_status.underreview || res.data.challenge_state === Challenge_status.reviewinprogress || res.data.challenge_state === Challenge_status.betareleased) {
              setButtondisabled(false)
            }
            break;
          case User_Role.Professor:
            if (res.data.challenge_state === undefined || res.data.challenge_state === null || res.data.challenge_state === Challenge_status.underdevelopment || res.data.challenge_state === Challenge_status.reviewed) {
              setButtondisabled(false)
            }
            else if (res.data.challenge_state === Challenge_status.reviewinprogress || res.data.challenge_state === Challenge_status.betareleased || res.data.challenge_state === Challenge_status.released || res.data.challenge_state === Challenge_status.underreview) {
              setButtondisabled(true)
            }
            break;
          default:
            break;
        }

      }
      isMounted = false
      setaftersave(false)
      setisloading(false)
    })
  }
  const compare = (a, b) => {
    return parseInt(a.id) - parseInt(b.id);
  }




  const parseUIJsonUE = (uiJson) => {


    let count = 0;
    uiJson.forEach((element) => {

      let importance = '';
      if (element.data.isPrimary) {
        importance = PRIMARY
      } else if (element.data.isnonPrimary) {
        importance = NON_PRIMARY
      } else {
        importance = OPTIONAL
      }
      if (element.data.error_source === 0) {
        element.data.errortype = BASIC_ERROR
      } else if (element.data.error_source === 1)
        element.data.errortype = AUTHOR_COMBINATION
      else if (element.data.error_source === 2) {
        element.data.errortype = SYSTEM_COMBINATION
      } else {

        element.data.errortype = PROPAGATION_ERROR
      }
      element.originalPosition = clone(element.position);
      if (parseInt(element.position.x) === 0 && parseInt(element.position.y) === 0) {
        count++;
      }
      // if (!isNil(props.isKnowledgeGraph) && props.isKnowledgeGraph) {
      //   element.data.content = element.data.label;
      //   element.data.label = '';
      // }
      element.data.isReadOnly = props.readOnly;
      element.data.isresourceidsdisplay = !element.data.isNodeCorrect ? true : false

      element.data.isnodeiddisplay = true
      if (props.readOnly) {
        console.log(element.position)
        element.position = { x: -100, y: -100 }
      }

      element.data.hidden = false;
      element.data.importance = importance;
      element.data.background = element.data.isNodeCorrect ? (filter(NODE_BACKGROUND, ['type', importance]))[0]['color'] : INCORRECT_COLOR;
      element.data.border = nodeBackground;
      if (!isNil(element.user_added)) {
        element.data.user_added = element.user_added
      }
    })

    //if (count > 1) isAutoPositioningRequired = true;
    return createEdges(uiJson);
  }

  const parseUIJson = (uiJson) => {

    uiJson = uiJson.sort(compare);
    if (uiJson.length > 0) {

      maxNodeId = parseInt(uiJson[uiJson.length - 1]['id']);
    }
    let count = 0;
    uiJson.forEach((element) => {

      let importance = '';
      if (element.data.isPrimary) {
        importance = PRIMARY
      } else if (element.data.isnonPrimary) {
        importance = NON_PRIMARY
      } else {
        importance = OPTIONAL
      }
      if (element.data.error_source === 0) {
        element.data.errortype = BASIC_ERROR
      } else if (element.data.error_source === 1)
        element.data.errortype = AUTHOR_COMBINATION
      else if (element.data.error_source === 2) {
        element.data.errortype = SYSTEM_COMBINATION
      } else {

        element.data.errortype = PROPAGATION_ERROR
      }
      element.originalPosition = clone(element.position);
      if (parseInt(element.position.x) === 0 && parseInt(element.position.y) === 0) {
        count++;
      }
      // if (!isNil(props.isKnowledgeGraph) && props.isKnowledgeGraph) {
      //   element.data.content = element.data.label;
      //   element.data.label = '';
      // }
      element.data.isReadOnly = props.readOnly;
      element.data.isresourceidsdisplay = !element.data.isNodeCorrect ? true : false

      element.data.isnodeiddisplay = true
      if (props.readOnly) {
        console.log(element.position)
        element.position = { x: -100, y: -100 }
      }

      element.data.hidden = false;
      element.data.importance = importance;
      element.data.background = element.data.isNodeCorrect ? (filter(NODE_BACKGROUND, ['type', importance]))[0]['color'] : INCORRECT_COLOR;
      element.data.border = nodeBackground;
      if (!isNil(element.user_added)) {
        element.data.user_added = element.user_added
      }
    })

    //if (count > 1) isAutoPositioningRequired = true;
    return createEdges(uiJson);
  }

  const createEdges = (uiJson) => {
    let edges = [];
    let edgeId = 1000;
    uiJson.forEach((element) => {
      let parents = [];

      const optional_prereqs = element.data.optional_prereqs.split(',');
      const incrorrect_variants = element.data.incorrect_variants.split(',');
      if (element.data.prereqs.length > 0 && (element.data.isNodeCorrect || props.hasCheckMyWOrk)) {
        parents = [...element.data.prereqs.split(',')];
      }
      if (element.data.optional_prereqs.length > 0 && element.data.isNodeCorrect) {
        parents = [...parents, ...optional_prereqs];
      }

      if (!isNil(element.data.combination_node) && element.data.combination_node.length > 0 && !element.data.isNodeCorrect) {
        parents = [...element.data.combination_node.split(',')];
      }

      for (let i = 0; i < parents.length; i++) {
        const edgeObj = createEdge(element, parents[i], element.id, edgeId)
        edges.push(edgeObj);
        edgeId++;
      }

      if (element.data.incorrect_variants.length > 0 && !(props.readOnly)) {
        for (let i = 0; i < incrorrect_variants.length; i++) {
          const childElement = filter(uiJson, ['id', incrorrect_variants[i]])[0];
          if (!isNil(childElement)) {
            const edgeObj = createEdge(filter(uiJson, ['id', incrorrect_variants[i]])[0], element.id, incrorrect_variants[i], edgeId)
            edges.push(edgeObj);
            edgeId++;
          }

        }
      }
    })
    return [...uiJson, ...edges];
  }

  const createEdge = (element, source, target, edgeId) => {

    return {
      source: source,
      target: target,
      type: "default",
      animated: true,
      sourceHandle: "source_handle",
      targetHandle: "target_handle",
      id: source + "_" + target,
      style: { stroke: element.data.isNodeCorrect ? "#000000" : INCORRECT_COLOR, cursor: "pointer" },
      label: props.readOnly ? "" : "Click to select",
      labelStyle: {
        fill: "#000",
        fontWeight: "100",
        fontSize: "12px",
        cursor: "pointer"
      },
      arrowHeadType: "arrow"
    }
  }

  //After reviewer change the status from under review to Review In Progress after that 
  //Comments fields should be shown
  useEffect(() => {
    if (challengedata.reviewer_user_id !== null) {
      setshowcommentsfields(true)
    }
  }, [challengedata.reviewer_user_id]);

  useEffect(() => {
    const selectedNode = filter(elements, { id: element.id });
    if (!isNil(selectedNode) && selectedNode.length > 0) {
      const edges = filter(elements, { source: element.id });
      let i = 1;
      edges.forEach((edge) => {
        const targetId = edge.target
        let node = elements.filter(el => el.id === targetId);
        if (!isNil(node) && node.length > 0 && hidden && !node[0].data.isNodeCorrect) {
          node[0].style.zIndex = '-1';
          node[0].position = {
            x: selectedNode[0].position.x,
            y: selectedNode[0].position.y - (i * 5)
          }

        } else if (!isNil(node) && node.length > 0 && !hidden) {
          if (!isNil(node[0]) && !isNil(node[0].originalPosition)) {
            node[0].position = {
              x: Number(node[0].originalPosition.x),
              y: Number(node[0].originalPosition.y)
            }
          }

        }
        i++;
      })
      setElements([...elements]);
      setElements((els) =>
        els.map((el) => {
          if (el.id === element.id) {
            el.data = { ...el.data, hidden: hidden };
          }

          return el;
        })
      );
    }

  }, [hidden]);


  const hide = (hidden) => (nodeOrEdge) => {
    nodeOrEdge.hidden = hidden;
    return nodeOrEdge;
  };

  const getRemedialResources = () => {
    fetchResources(params.challengeId.split('-')[0]).then(res => {
      if (res.status === 200) {
        let result = [];
        if (!isNil(res.data.Resources)) {
          res.data.Resources.forEach((resource) => {
            result.push({
              display: "Resource: " + resource.name + "\n URL:" + resource.url,
              value: resource
            })
          })
        }
        setRemedialResourceOption(result);
      } else {
        console.log("No data found");
      }
    })
  }

  const fetchHintText = (challengeId, iscorrect) => {
    if (challengeType.toLowerCase().includes("mcq") || challengeType.toLowerCase().includes("msq")) {
      getHintText(challengeId, iscorrect).then(res => {
        if (res.status === 200) {
          let hintTexts = []
          if (!isNil(res.data.HintText)) {
            res.data.HintText.forEach((resource) => {
              let htext = ""
              let rd = ""
              let ru = ""

              htext = "Text: " + resource.hinttext
              rd = "| Resource: " + resource.Resource_Display
              ru = "| URL: " + resource.Resource_url
              hintTexts.push({
                name: htext + "" + rd + "" + ru,
                text: resource
              })
            })
          }
          setHintTextOptionsOptions(hintTexts);
        } else {
          console.log("No data found");
        }
      })
    }
    else {
      if (!isNil(element) && !props.readOnly) {
        getHintText(challengeId, iscorrect).then(res => {
          if (res.status === 200) {

            let hintTexts = []
            if (!isNil(res.data.HintText)) {
              res.data.HintText.forEach((resource) => {
                let htext = ""
                let rd = ""
                let ru = ""

                htext = "Text: " + resource.hinttext
                rd = "| Resource: " + resource.Resource_Display
                ru = "| URL: " + resource.Resource_url

                hintTexts.push({
                  name: htext + "" + rd + "" + ru,
                  text: resource
                })
              })
            }
            setHintTextOptionsOptions(hintTexts);
          } else {
            console.log("No data found");
          }
        })
      }
    }
  }

  const changeEdgeForIncorrect = (isCorrect, element) => {
    let edge = filter((elements), { target: element.id });
    if (!isNil(edge) && edge.length > 0) {
      const edgeStyle = { stroke: isCorrect ? "#000000" : INCORRECT_COLOR, cursor: "pointer" };
      edge[0].style = edgeStyle;
    }

  }

  const showAddRemedials = (isedit) => {

    setIsHintDetailsSelected(true)
    setShowhintmodel(true)

    if (!isedit) {
      setaddeditmode("Add")
      setNodehintRemedialResource("")
      setHintTextSelectedOptions([])
      setNodehintRemedialText("")
      setRemedialResourceOption([])
      setIsReusable(false)
      setNodeRemedialResourcedisplay("")
      setnoderesourcetopicsubsectionid("")
      setresourcetopicId("")
      setNodehintRemedialResource("")
      setresourcetopicvalue(null)
      setresourcesvalue(null)
      setresourcesdata([]);

      setresourcenamedisabled(false)
      setneweditbuttonvisible(false)
      setresourceurldisabled(false)
      if (IsCorrect) {
        setInformationtext("Hint")
        setHintmodalheadertext("Add Hint")
      }
      else {
        setInformationtext("Remedial")
        setHintmodalheadertext("Add Remedial")
      }

      if (errorRootCauseValue.length === 0) {
        setresourcetopicdisbaled(true)
      }
      else {
        setresourcetopicdisbaled(false)
      }
    }
    else {
      setaddeditmode("Edit")
      if (IsCorrect) {
        setInformationtext("Hint")
        setHintmodalheadertext("Edit Hint")
      }
      else {
        setInformationtext("Remedial")
        setHintmodalheadertext("Edit Remedial")
      }
    }
    //setAddResource(true)
  }

  const hideAddRemedials = () => {
    setAddresourcelable(IsCorrect ? "Add Hint" : "Add Remedial")
    setIsHintDetailsSelected(false)
    setShowhintmodel(false)
    //setAddResource(false)
  }
  const handleShowMore = () => {
    if (dataSizeLabel === SHOW_MORE) {
      setShowDataSizeLabel(SHOW_LESS);
      setErrorRootCauseOption(requisiteKnowledgeResponse['nodes']);
    } else {
      setShowDataSizeLabel(SHOW_MORE)
      setErrorRootCauseOption(requisiteKnowledgeResponse['Current']);
    }
  }

  const hintRenderer = (nodeHints) => {
    let response = [];
    if (!isNil(nodeHints)) {

      for (let i = 0; i < nodeHints.length; i++) {
        const hint = nodeHints[i];

        if (showNodeFieldsForMCQ || openHideMCQGrid) {
          response.push(<li key={i} class="list-group-item hintItems">
            <div className="hintsRenderItem">
              {
                !IsCorrect ? (<div className="resourceDisplay">
                  {(hint.TempResource_ID === null || hint.TempResource_ID === undefined || hint.TempResource_ID === 'undefined' || hint.TempResource_ID === 'null') ? '' : hint.TempResource_ID}
                </div>) : null
              }

              <div className="resourceDisplay">
                {(hint.Resource_Text === null || hint.Resource_Text === undefined
                  || hint.Resource_Text === 'undefined' || hint.Resource_Text === 'null') ? '' :
                  <div style={{ display: "flex" }}>
                    <div style={{ fontWeight: "500", width: "35%" }}>
                      {IsCorrect ? "Hint Text:" : "Remedial Text:"}
                    </div>
                    <div style={{ paddingLeft: "5px", width: "65%" }}>{parse(hint.Resource_Text)}
                    </div>
                  </div>
                }
              </div>
              <div className="resourceDisplay">
                {(hint.resourcetopicsubsectionname === null || hint.resourcetopicsubsectionname === undefined
                  || hint.resourcetopicsubsectionname === 'undefined' || hint.resourcetopicsubsectionname === 'null') ? '' :

                  <div style={{ display: "flex" }}>
                    <div style={{ fontWeight: "500", width: "35%" }}>
                      {"Resource Topic:"}
                    </div>
                    <div style={{ paddingLeft: "5px", width: "65%" }}>{hint.resourcetopicsubsectionname}
                    </div>
                  </div>
                }
              </div>
              <div className="resourceDisplay">
                {
                  (hint.Resource_Display === null || hint.Resource_Display === undefined
                    || hint.Resource_Display === 'null' || hint.Resource_Display === 'undefined')
                    ? '' :
                    <div style={{ display: "flex" }}>
                      <div style={{ fontWeight: "500", width: "35%" }}>Resource Display:
                      </div>
                      <div style={{ paddingLeft: "5px", width: "65%" }}>{hint.Resource_Display}
                      </div>
                    </div>
                }
              </div>
              <div className="hintsUrlDisplay"><a href={hint.Resource_URL} target="_blank">{(hint.Resource_URL !== undefined && hint.Resource_URL !== null && hint.Resource_URL !== 'undefined' && hint.Resource_URL !== 'null') ?
                <div style={{ display: "flex" }}>
                  <div style={{ fontWeight: "500", width: "35%" }}>Resource URL:
                  </div>
                  <div style={{ paddingLeft: "5px", width: "65%" }}>{hint.Resource_URL}
                  </div>
                </div> : ""
              }</a></div>
              {/* {hint.Requisite_Text ? <div>{hint.Requisite_Text}</div> : null} */}
            </div>

            {
              ((mcqDataObject.errortype === BASIC_ERROR || mcqDataObject.isCorrect)) ? (<>
                <div className="deleteHintItem" >
                  <svg onClick={() => editHint(hint.Resource_ID)} xmlns="http://www.w3.org/2000/svg" width="15" height="15" data-name="Layer 1" viewBox="0 0 24 24" id="edit">
                    <path d="M3.5,24h15A3.51,3.51,0,0,0,22,20.487V12.95a1,1,0,0,0-2,0v7.537A1.508,1.508,0,0,1,18.5,22H3.5A1.508,1.508,0,0,1,2,20.487V5.513A1.508,1.508,0,0,1,3.5,4H11a1,1,0,0,0,0-2H3.5A3.51,3.51,0,0,0,0,5.513V20.487A3.51,3.51,0,0,0,3.5,24Z"></path>
                    <path d="M9.455,10.544l-.789,3.614a1,1,0,0,0,.271.921,1.038,1.038,0,0,0,.92.269l3.606-.791a1,1,0,0,0,.494-.271l9.114-9.114a3,3,0,0,0,0-4.243,3.07,3.07,0,0,0-4.242,0l-9.1,9.123A1,1,0,0,0,9.455,10.544Zm10.788-8.2a1.022,1.022,0,0,1,1.414,0,1.009,1.009,0,0,1,0,1.413l-.707.707L19.536,3.05Zm-8.9,8.914,6.774-6.791,1.4,1.407-6.777,6.793-1.795.394Z"></path>
                  </svg>
                </div>
                <div className="deleteHintItem" >
                  <svg onClick={() => removeHint(hint.Resource_ID)} xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-trash" viewBox="0 0 16 16">
                    <path d="M5.5 5.5A.5.5 0 0 1 6 6v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5zm2.5 0a.5.5 0 0 1 .5.5v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5zm3 .5a.5.5 0 0 0-1 0v6a.5.5 0 0 0 1 0V6z" />
                    <path fill-rule="evenodd" d="M14.5 3a1 1 0 0 1-1 1H13v9a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2V4h-.5a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1H6a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1h3.5a1 1 0 0 1 1 1v1zM4.118 4 4 4.059V13a1 1 0 0 0 1 1h6a1 1 0 0 0 1-1V4.059L11.882 4H4.118zM2.5 3V2h11v1h-11z" />
                  </svg>
                </div>
              </>) : (<>
                <div className="deleteHintItem" >
                  <svg xmlns="http://www.w3.org/2000/svg" fill="gray" width="15" height="15" data-name="Layer 1" viewBox="0 0 24 24" id="edit">
                    <path d="M3.5,24h15A3.51,3.51,0,0,0,22,20.487V12.95a1,1,0,0,0-2,0v7.537A1.508,1.508,0,0,1,18.5,22H3.5A1.508,1.508,0,0,1,2,20.487V5.513A1.508,1.508,0,0,1,3.5,4H11a1,1,0,0,0,0-2H3.5A3.51,3.51,0,0,0,0,5.513V20.487A3.51,3.51,0,0,0,3.5,24Z"></path>
                    <path d="M9.455,10.544l-.789,3.614a1,1,0,0,0,.271.921,1.038,1.038,0,0,0,.92.269l3.606-.791a1,1,0,0,0,.494-.271l9.114-9.114a3,3,0,0,0,0-4.243,3.07,3.07,0,0,0-4.242,0l-9.1,9.123A1,1,0,0,0,9.455,10.544Zm10.788-8.2a1.022,1.022,0,0,1,1.414,0,1.009,1.009,0,0,1,0,1.413l-.707.707L19.536,3.05Zm-8.9,8.914,6.774-6.791,1.4,1.407-6.777,6.793-1.795.394Z"></path>
                  </svg>
                </div>
                <div className="deleteHintItem" >
                  <svg xmlns="http://www.w3.org/2000/svg" fill="gray" width="16" height="16" class="bi bi-trash" viewBox="0 0 16 16">
                    <path d="M5.5 5.5A.5.5 0 0 1 6 6v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5zm2.5 0a.5.5 0 0 1 .5.5v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5zm3 .5a.5.5 0 0 0-1 0v6a.5.5 0 0 0 1 0V6z" />
                    <path fill-rule="evenodd" d="M14.5 3a1 1 0 0 1-1 1H13v9a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2V4h-.5a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1H6a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1h3.5a1 1 0 0 1 1 1v1zM4.118 4 4 4.059V13a1 1 0 0 0 1 1h6a1 1 0 0 0 1-1V4.059L11.882 4H4.118zM2.5 3V2h11v1h-11z" />
                  </svg>
                </div>
              </>)

            }

          </li>)
        }
        else {
          response.push(<li class="list-group-item hintItems">
            <div className="hintsRenderItem">
              {
                !element.data.isNodeCorrect ? (<div className="resourceDisplay">
                  {(hint.TempResource_ID === null || hint.TempResource_ID === undefined || hint.TempResource_ID === 'undefined' || hint.TempResource_ID === 'null') ? '' : hint.TempResource_ID}
                </div>) : null
              }

              <div className="resourceDisplay">
                {(hint.Resource_Text === null || hint.Resource_Text === undefined
                  || hint.Resource_Text === 'undefined' || hint.Resource_Text === 'null') ? '' :

                  <div style={{ display: "flex" }}>
                    <div style={{ fontWeight: "500", width: "35%" }}>
                      {element.data.isNodeCorrect ? "Hint Text:" : "Remedial Text:"}
                    </div>
                    <div style={{ paddingLeft: "5px", width: "65%" }}>{parse(hint.Resource_Text)}
                    </div>
                  </div>
                }
              </div>
              <div className="resourceDisplay">
                {(hint.resourcetopicsubsectionname === null || hint.resourcetopicsubsectionname === undefined
                  || hint.resourcetopicsubsectionname === 'undefined' || hint.resourcetopicsubsectionname === 'null') ? '' :

                  <div style={{ display: "flex" }}>
                    <div style={{ fontWeight: "500", width: "35%" }}>
                      {"Resource Topic:"}
                    </div>
                    <div style={{ paddingLeft: "5px", width: "65%" }}>{hint.resourcetopicsubsectionname}
                    </div>
                  </div>
                }
              </div>
              <div className="resourceDisplay">
                {
                  (hint.Resource_Display === null || hint.Resource_Display === undefined
                    || hint.Resource_Display === 'null' || hint.Resource_Display === 'undefined')
                    ? '' :
                    <div style={{ display: "flex" }}>
                      <div style={{ fontWeight: "500", width: "35%" }}>Resource Display:
                      </div>
                      <div style={{ paddingLeft: "5px", width: "65%" }}>{hint.Resource_Display}
                      </div>
                    </div>
                }
              </div>
              <div className="hintsUrlDisplay"><a href={hint.Resource_URL} target="_blank">{(hint.Resource_URL !== undefined && hint.Resource_URL !== null && hint.Resource_URL !== 'undefined' && hint.Resource_URL !== 'null') ?
                <div style={{ display: "flex" }}>
                  <div style={{ fontWeight: "500", width: "35%" }}>Resource URL:
                  </div>
                  <div style={{ paddingLeft: "5px", width: "65%" }}>{hint.Resource_URL}
                  </div>
                </div> : ""
              }</a></div>
              {/* {hint.Requisite_Text ? <div>{hint.Requisite_Text}</div> : null} */}
            </div>

            {
              ((element.data.errortype === BASIC_ERROR || element.data.isNodeCorrect)) ? (<>
                <div className="deleteHintItem" >
                  {
                    isDisabledNotForUE ? (<svg disabled xmlns="http://www.w3.org/2000/svg" width="15" height="15" data-name="Layer 1" viewBox="0 0 24 24" id="edit">
                      <path d="M3.5,24h15A3.51,3.51,0,0,0,22,20.487V12.95a1,1,0,0,0-2,0v7.537A1.508,1.508,0,0,1,18.5,22H3.5A1.508,1.508,0,0,1,2,20.487V5.513A1.508,1.508,0,0,1,3.5,4H11a1,1,0,0,0,0-2H3.5A3.51,3.51,0,0,0,0,5.513V20.487A3.51,3.51,0,0,0,3.5,24Z"></path>
                      <path d="M9.455,10.544l-.789,3.614a1,1,0,0,0,.271.921,1.038,1.038,0,0,0,.92.269l3.606-.791a1,1,0,0,0,.494-.271l9.114-9.114a3,3,0,0,0,0-4.243,3.07,3.07,0,0,0-4.242,0l-9.1,9.123A1,1,0,0,0,9.455,10.544Zm10.788-8.2a1.022,1.022,0,0,1,1.414,0,1.009,1.009,0,0,1,0,1.413l-.707.707L19.536,3.05Zm-8.9,8.914,6.774-6.791,1.4,1.407-6.777,6.793-1.795.394Z"></path>
                    </svg>) : (<svg onClick={() => editHint(hint.Resource_ID)} xmlns="http://www.w3.org/2000/svg" width="15" height="15" data-name="Layer 1" viewBox="0 0 24 24" id="edit">
                      <path d="M3.5,24h15A3.51,3.51,0,0,0,22,20.487V12.95a1,1,0,0,0-2,0v7.537A1.508,1.508,0,0,1,18.5,22H3.5A1.508,1.508,0,0,1,2,20.487V5.513A1.508,1.508,0,0,1,3.5,4H11a1,1,0,0,0,0-2H3.5A3.51,3.51,0,0,0,0,5.513V20.487A3.51,3.51,0,0,0,3.5,24Z"></path>
                      <path d="M9.455,10.544l-.789,3.614a1,1,0,0,0,.271.921,1.038,1.038,0,0,0,.92.269l3.606-.791a1,1,0,0,0,.494-.271l9.114-9.114a3,3,0,0,0,0-4.243,3.07,3.07,0,0,0-4.242,0l-9.1,9.123A1,1,0,0,0,9.455,10.544Zm10.788-8.2a1.022,1.022,0,0,1,1.414,0,1.009,1.009,0,0,1,0,1.413l-.707.707L19.536,3.05Zm-8.9,8.914,6.774-6.791,1.4,1.407-6.777,6.793-1.795.394Z"></path>
                    </svg>)
                  }
                </div>
                <div className="deleteHintItem" >
                  {
                    isDisabledNotForUE ? (<svg disabled xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-trash" viewBox="0 0 16 16">
                      <path d="M5.5 5.5A.5.5 0 0 1 6 6v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5zm2.5 0a.5.5 0 0 1 .5.5v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5zm3 .5a.5.5 0 0 0-1 0v6a.5.5 0 0 0 1 0V6z" />
                      <path fill-rule="evenodd" d="M14.5 3a1 1 0 0 1-1 1H13v9a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2V4h-.5a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1H6a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1h3.5a1 1 0 0 1 1 1v1zM4.118 4 4 4.059V13a1 1 0 0 0 1 1h6a1 1 0 0 0 1-1V4.059L11.882 4H4.118zM2.5 3V2h11v1h-11z" />
                    </svg>) : (<svg onClick={() => removeHint(hint.Resource_ID)} xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-trash" viewBox="0 0 16 16">
                      <path d="M5.5 5.5A.5.5 0 0 1 6 6v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5zm2.5 0a.5.5 0 0 1 .5.5v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5zm3 .5a.5.5 0 0 0-1 0v6a.5.5 0 0 0 1 0V6z" />
                      <path fill-rule="evenodd" d="M14.5 3a1 1 0 0 1-1 1H13v9a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2V4h-.5a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1H6a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1h3.5a1 1 0 0 1 1 1v1zM4.118 4 4 4.059V13a1 1 0 0 0 1 1h6a1 1 0 0 0 1-1V4.059L11.882 4H4.118zM2.5 3V2h11v1h-11z" />
                    </svg>)
                  }
                </div>
              </>) : (<>
                <div className="deleteHintItem" >
                  <svg xmlns="http://www.w3.org/2000/svg" fill="gray" width="15" height="15" data-name="Layer 1" viewBox="0 0 24 24" id="edit">
                    <path d="M3.5,24h15A3.51,3.51,0,0,0,22,20.487V12.95a1,1,0,0,0-2,0v7.537A1.508,1.508,0,0,1,18.5,22H3.5A1.508,1.508,0,0,1,2,20.487V5.513A1.508,1.508,0,0,1,3.5,4H11a1,1,0,0,0,0-2H3.5A3.51,3.51,0,0,0,0,5.513V20.487A3.51,3.51,0,0,0,3.5,24Z"></path>
                    <path d="M9.455,10.544l-.789,3.614a1,1,0,0,0,.271.921,1.038,1.038,0,0,0,.92.269l3.606-.791a1,1,0,0,0,.494-.271l9.114-9.114a3,3,0,0,0,0-4.243,3.07,3.07,0,0,0-4.242,0l-9.1,9.123A1,1,0,0,0,9.455,10.544Zm10.788-8.2a1.022,1.022,0,0,1,1.414,0,1.009,1.009,0,0,1,0,1.413l-.707.707L19.536,3.05Zm-8.9,8.914,6.774-6.791,1.4,1.407-6.777,6.793-1.795.394Z"></path>
                  </svg>
                </div>
                <div className="deleteHintItem" >
                  <svg xmlns="http://www.w3.org/2000/svg" fill="gray" width="16" height="16" class="bi bi-trash" viewBox="0 0 16 16">
                    <path d="M5.5 5.5A.5.5 0 0 1 6 6v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5zm2.5 0a.5.5 0 0 1 .5.5v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5zm3 .5a.5.5 0 0 0-1 0v6a.5.5 0 0 0 1 0V6z" />
                    <path fill-rule="evenodd" d="M14.5 3a1 1 0 0 1-1 1H13v9a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2V4h-.5a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1H6a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1h3.5a1 1 0 0 1 1 1v1zM4.118 4 4 4.059V13a1 1 0 0 0 1 1h6a1 1 0 0 0 1-1V4.059L11.882 4H4.118zM2.5 3V2h11v1h-11z" />
                  </svg>
                </div>
              </>)

            }

          </li>)
        }
      }
    }
    return response;
  }

  const removeHint = (resourceId) => {

    if (resourceId !== "" && resourceId !== null && resourceId !== undefined) {
      setNodeHints(filter(nodeHints, function (o) { return o.Resource_ID !== resourceId; }))

      if (openHideMCQGrid) {
        mcqDataObject.hints = nodeHints;
        if (nodeHints !== undefined) {
          if (showNodeFieldsForMCQ) {
            var optionIdIndex = null
            var tempOptionIdIndex = null
            if (!isNil(mcqDataObject.tempOptionId)) {
              if (!isNil(gridApi.getRowNode(mcqDataObject.tempOptionId)))
                tempOptionIdIndex = mcqArray.map(function (obj) { return obj.tempOptionId; }).indexOf(mcqDataObject.tempOptionId)
            }
            else
              optionIdIndex = mcqArray.map(function (obj) { return obj.optionId; }).indexOf(mcqDataObject.optionId)

            let rowNodeIndex = optionIdIndex === null ? (tempOptionIdIndex === null ? 0 : tempOptionIdIndex) : optionIdIndex
            let row = mcqArray[mcqArray.length === -1 ? 0 : rowNodeIndex];
            if (row !== undefined)
              row.hints = nodeHints;

            gridApi.refreshCells();
          }
        }
      }
      else {
        setElements((els) =>
          els.map((el) => {
            if (el.id === element.id) {
              el.data = {
                ...el.data,
                hints: nodeHints
              };
            }
            return el;
          })
        );
      }
      console.log(elements)
    }
  }

  const editHint = (resourceId) => {
    let resource = filter(nodeHints, function (o) { return o.Resource_ID === resourceId });

    setNodehintRemedialText(resource[0].Resource_Text);
    if (!isNil(hintEditor)) {
      hintEditor.setData(resource[0].Resource_Text);
    }
    setSeletedResourceIdForEdit(resourceId)
    setNodeRemedialResourcedisplay(resource[0].Resource_Display)
    setnoderesourcetopicsubsectionid(resource[0].sub_section_id)
    setNodehintRemedialResource(resource[0].Resource_URL);
    setIsReusable(parseInt(resource[0].Is_Resource_Reusable) === 1 ? true : false)

    // Hint dropdown selected value------------------------------------------------------------------------------------------
    let datart = {
      name: `Text: ${parse(resource[0].Resource_Text.replace(/(<([^>]+)>)/ig, ''))} | Resource: ${resource[0].Resource_Display}\r\n | URL: ${resource[0].Resource_URL}`
      , text: {
        Resource_ID: resourceId, hinttext: `${parse(resource[0].Resource_Text.replace(/(<([^>]+)>)/ig, ''))}\r\n`
        , hint: `${resource[0].Resource_Text}\r\n`, Resource_Display: resource[0].Resource_Display, Resource_url: resource[0].Resource_URL
      }
    }
    setHintTextSelectedOptions(datart)
    // Hint dropdown selected value END---------------------------------------------------------------------------------------

    //check if subsection id is there bind resourses as per subsection id , new/edit button visible,
    //also set selected value for resource topic and resources
    // resource name and URL make it disbaled-------------------------------------------------------------------------
    if (errorRootCauseValue.length === 0) {
      setresourcetopicdisbaled(true)
    }
    else {
      setresourcetopicdisbaled(false)
    }
    if (resource[0].sub_section_id !== undefined && resource[0].sub_section_id !== null && resource[0].sub_section_id !== "" && resource[0].sub_section_id !== "null" && resource[0].sub_section_id !== "undefined") {
      bindresources(resource[0].sub_section_id)

      let dataresourcetopic = {
        name: resource[0].sub_sectiondata
        , subSectionId: resource[0].sub_section_id
      }
      setresourcetopicvalue(dataresourcetopic)

      if (parseInt(resource[0].Is_Resource_Reusable) !== 0) {
        let data = {
          name: `Resource: ${resource[0].Resource_Display}| URL: ${resource[0].Resource_URL}`
          , text: resource[0].resourcetopicmasterid + "#" + resource[0].sub_section_id
        }

        setresourcesvalue(data)
      }
      else {
        setresourcesvalue(null)
      }
      if (resource[0].resourcetopicmasterid !== undefined && resource[0].resourcetopicmasterid !== null
        && resource[0].resourcetopicmasterid !== "" && resource[0].resourcetopicmasterid !== "null"
        && resource[0].resourcetopicmasterid !== "undefined") {
        setresourcetopicId(resource[0].resourcetopicmasterid)
        setresourcenamedisabled(true)
        setresourceurldisabled(true)
        setneweditbuttonvisible(true)
      }
    }
    //-----------------------------------------------------------------------------------------------


    //if subsection id is not there resource name and url make it enabled, new/edit button visible=false
    //--------------------------------------------------------------------------------------------------
    else {
      if (resource[0].resourcetopicmasterid === null) {
        setneweditbuttonvisible(false)
        setresourcenamedisabled(false)
        setresourceurldisabled(false)
      }
      setresourcetopicvalue(null)
      setresourcesvalue(null)
      setresourcesdata([]);
    }
    //------------------------------------------------------------------------------------------------------------
    showAddRemedials(true);
  }
  const deleteNode = (step) => {
    let currentSteps = [...elements];
    const index = currentSteps.indexOf(step);
    setElements((els) => removeElements([elements[index]], els));
    setHideText1(false);
    handleToggleSidebar(!toggled)
    setNewNodeId(null)

  }
  const cloneNode = (event, step) => {
    maxNodeId = maxNodeId + 1;
    const tempdata = [...step.data.hints];
    const position = {
      x: parseInt(element.position.x) + 50,
      y: parseInt(element.position.y) + 60
    };
    let cloneNode = clone(element);
    let data1 = element.data;

    const clonedata1 = { ...data1 };

    clonedata1.displaynodeid = "N" + maxNodeId.toString()

    let finaldata = []

    if (data1.hints.length > 0) {

      let data2 = data1.hints.filter(el => el.node_id === step.id)
      let final = data2.map(({ Requisite_Text, Resource_Display, Resource_ID,
        Resource_Text, Resource_URL, sub_section_id
        , resourcetopicsubsectionname
        , Is_Resource_Reusable, resourcetopicmasterid, sub_sectiondata }) => ({ Requisite_Text, Resource_Display, Resource_ID, Resource_Text, Resource_URL, sub_section_id, resourcetopicsubsectionname, Is_Resource_Reusable, resourcetopicmasterid, sub_sectiondata, node_id: maxNodeId }))
      finaldata = Array.from(new Set(tempdata.concat(final)))//data1.concat(data2.filter((item) => data1.indexOf(item) < 0));

    }

    if (finaldata.length > 0) {
      let elemetdata = finaldata.filter(el => el.node_id.toString() === maxNodeId.toString())

      setElements((es) =>
        es.concat({
          ...cloneNode,
          id: maxNodeId.toString(),
          data: { ...clonedata1, border: nodeBackground, hints: elemetdata },
          position

        })
      );

      //setNodeHints(finaldata)
    }
    else {
      setElements((es) =>
        es.concat({
          ...cloneNode,
          id: maxNodeId.toString(),
          data: { ...clonedata1, border: nodeBackground },
          position

        })
      );
    }


    // if (props.readOnly && props.isKnowledgeGraph && props.openContentPopup) {
    //   props.openContentPopup(event, maxNodeId.toString());
    // }
    // if (props.readOnly) {
    //   return;
    // }
    // else {


    //   hideAddRemedials();
    //   elements.map((element) => {
    //     elementClickHandler(element, maxNodeId.toString());
    //   });
    //   handleToggleSidebar(isEdge(element.type) ? false : true);
    // }
    // console.log("here elemets", elements)

  }
  const handleChangeRequisiteKnowledge = (selected) => {
    let str = "";
    let strText = "";
    // let listdata = { name: "Select", subSectionId: 0 }
    // listdata.push(selected)

    setErrorRootCauseValue(selected)


    if (!isNil(selected) && !isEmpty(selected)) {
      selected.forEach((item, index) => {
        str += index === 0 ? item.subSectionId : ',' + item.subSectionId
        strText += index === 0 ? item.name : ',' + item.name
      })

    }

    if (showNodeFieldsForMCQ)
      setRequisiteText(strText);

    setNodeRootCause(str);
    getRemedialResources(str);

    //console.log(errorRootCauseValue)

  }

  const bindresources = (subsectionid) => {

    ///resource topic master dropdown --------------------------------------------------------------------------------------
    const reqData = { params: { 'subsection_id': subsectionid } }
    setresourcesdata([]);
    setresourcesvalue(null)
    getresourcetopic(reqData).then(res => {
      if (res.status === 200) {

        if (res.data.resdata !== null && res.data.resdata.length > 0) {
          let hintTexts = []
          //let counter = 0
          res.data.resdata.forEach((resource) => {

            // let htext = ""
            let rd = ""
            let ru = ""
            // if (resource.resourcetopicsubsectionname !== "" && resource.resourcetopicsubsectionname !== 'null' && resource.resourcetopicsubsectionname !== undefined && resource.resourcetopicsubsectionname !== "null" && resource.resourcetopicsubsectionname !== null) {
            //   htext = resource.resourcetopicsubsectionname
            // }
            if (resource.Resource_Display !== "" && resource.Resource_Display !== 'null' && resource.Resource_Display !== undefined && resource.Resource_Display !== "null" && resource.Resource_Display !== null) {
              rd = "Resource: " + resource.Resource_Display
              //rd = resource.Resource_Display

            }
            else {
              rd = "Resource: "
            }
            if (resource.Resource_url !== "" && resource.Resource_url !== 'null' && resource.Resource_url !== undefined && resource.Resource_url !== "null" && resource.Resource_url !== null) {
              ru = " | URL: " + resource.Resource_url
              //ru = resource.Resource_url

            }
            else {
              ru = " | URL: "
            }
            hintTexts.push({
              name: rd + ru,
              text: resource.Resource_ID + "#" + resource.sub_section_id

            })


          })
          setresourcesdata(hintTexts);
        }

      }
    })
    ///resource topic master dropdown end---------------------------------------------------------------------------------
  }


  const handleresourcetopic = (selected) => {
    setresourcetopicvalue(selected)
    if (selected == null) {
      setresourcesdata([]);
      setresourcesvalue(null)
      setNodeRemedialResourcedisplay("")
      setNodehintRemedialResource("")
      setIsReusable(false)
      setresourcenamedisabled(false)
      setresourceurldisabled(false)
      setneweditbuttonvisible(false)

    }
    else {
      if (!isNil(selected)) {
        setnoderesourcetopicsubsectionid(selected.subSectionId)
        bindresources(selected.subSectionId)

      }
    }
  }
  const handleresources = (selected) => {

    setresourcesvalue(selected)
    if (!isNil(selected)) {

      let name = selected.name
      let text = selected.text
      let resourceurl = ""

      if (name !== undefined) {
        let str = name.split("|")
        if (str.length > 1) {
          let resourcename = str[0].toString().split(":")
          if (resourcename.length > 0) {
            setNodeRemedialResourcedisplay(resourcename[1])
          }
          if (str[1] !== undefined && str[1] !== null) {
            resourceurl = str[1].toString().replace("URL: ", "").trim();

            setNodehintRemedialResource(resourceurl)
          }

          // }
        }
      }
      setresourcenamedisabled(true)
      setresourceurldisabled(true)
      setIsReusable(true)
      setneweditbuttonvisible(true)
      if (text !== undefined) {
        let str = text.split("#")
        if (str.length > 0) {
          setresourcetopicId(str[0])
        }
      }
    }
    else {
      setNodeRemedialResourcedisplay("")
      setNodehintRemedialResource("")
      setresourcenamedisabled(false)
      setresourceurldisabled(false)
      setIsReusable(false)
      setneweditbuttonvisible(false)
    }

  }

  const handleeditresource = () => {
    setresourcenamedisabled(false)
    //setneweditbuttonvisible(false)
    setresourceurldisabled(false)
    setisnewmode(0)
  }
  const handlenewresource = () => {
    setresourcenamedisabled(false)
    //setneweditbuttonvisible(false)
    setresourceurldisabled(false)
    setisnewmode(1)
    setNodeRemedialResourcedisplay("")
    setNodehintRemedialResource("")
    setresourcesvalue(null)
  }
  const handleHintTextResource = (selected) => {
    setHintTextSelectedOptions(selected)
    if (!isNil(selected)) {
      setNodehintRemedialText(selected.text.hint);
      if (!isNil(hintEditor)) {
        hintEditor.setData(selected.text.hint)
      }
      setNodeRemedialResourcedisplay(selected.text.Resource_Display);
      setNodehintRemedialResource(selected.text.Resource_url)
      setnoderesourcetopicsubsectionid(selected.text.sub_section_id)

      setIsReusable(selected.text.Is_Resource_Reusable)

      if (errorRootCauseValue.length === 0) {
        setresourcetopicdisbaled(true)
      }
      else {
        setresourcetopicdisbaled(false)
      }
      if (selected.text.sub_section_id !== undefined && selected.text.sub_section_id !== null && selected.text.sub_section_id !== "" && selected.text.sub_section_id !== "null" && selected.text.sub_section_id !== "undefined") {
        bindresources(selected.text.sub_section_id)
        let dataresourcetopic = {
          name: selected.text.sub_sectiondata
          , subSectionId: selected.text.sub_section_id
        }
        setresourcetopicvalue(dataresourcetopic)
        if (parseInt(selected.text.Is_Resource_Reusable) !== 0) {
          let data = {
            name: `Resource: ${selected.text.Resource_Display}| URL: ${selected.text.Resource_url}`
            , text: selected.text.resourcetopicmasterid + "#" + selected.text.sub_section_id
          }
          setresourcesvalue(data)
        }
        else {
          setresourcesvalue(null)
        }


        if (selected.text.resourcetopicmasterid !== undefined && selected.text.resourcetopicmasterid !== null
          && selected.text.resourcetopicmasterid !== "" && selected.text.resourcetopicmasterid !== "null"
          && selected.text.resourcetopicmasterid !== "undefined") {
          setresourcenamedisabled(true)
          setresourceurldisabled(true)
          setneweditbuttonvisible(true)
          setresourcetopicId(selected.text.resourcetopicmasterid)
        }
      }
      else {
        if (selected.text.resourcetopicmasterid === null) {
          setneweditbuttonvisible(false)
          setresourcenamedisabled(false)
          setresourceurldisabled(false)
        }
        setresourcetopicvalue(null)
        setresourcesvalue(null)
        setresourcesdata([]);
      }

    }
    else {
      setNodehintRemedialText("");
      setNodeRemedialResourcedisplay("");
      setnoderesourcetopicsubsectionid("")
      setresourcetopicId("")
      setNodehintRemedialResource("")
    }
  }

  const handlehintapplyforallnodes = async () => {
    let previousdata = hintTextSelected;
    let form_data = new FormData();
    if (showNodeFieldsForMCQ) {
      form_data.append('remedial_resource_display', mcqDataObject.remedialResourcedisplay);
      form_data.append('is_remedial_resoue_reusable', mcqDataObject.isRemedialResoueReusable ? 1 : 0);
      form_data.append('remedial_resource_urls', mcqDataObject.remedialResourceURLs);
      form_data.append('requisite_knowledge', '');
      form_data.append('hint_text', mcqDataObject.hintText);
      form_data.append('node_id', mcqDataObject.optionId);
      form_data.append("resource_id", previousdata.text.Resource_ID);
      if (mcqDataObject.sub_section_id !== undefined && mcqDataObject.sub_section_id !== null)
        form_data.append('subsection_id', mcqDataObject.sub_section_id);
      form_data.append('isnewmode', isnewmode);
      if (mcqDataObject.resourcetopicmasterid !== undefined && mcqDataObject.resourcetopicmasterid !== null)
        form_data.append('topic_resource_id', mcqDataObject.resourcetopicmasterid);
    }
    else {
      form_data.append('remedial_resource_display', element.data.remedialResourcedisplay);
      form_data.append('is_remedial_resoue_reusable', element.data.isRemedialResoueReusable ? 1 : 0);
      form_data.append('remedial_resource_urls', element.data.remedialResourceURLs);
      form_data.append('requisite_knowledge', '');
      form_data.append('hint_text', element.data.hintText);
      form_data.append('node_id', element.id);
      form_data.append("resource_id", previousdata.text.Resource_ID)
      if (element.data.sub_section_id !== undefined && element.data.sub_section_id !== null)
        form_data.append('subsection_id', element.data.sub_section_id);
      form_data.append('isnewmode', isnewmode);
      if (element.data.resourcetopicmasterid !== undefined && element.data.resourcetopicmasterid !== null)
        form_data.append('topic_resource_id', element.data.resourcetopicmasterid);
    }

    await SaveAllResource(form_data).then(res => {


      if (res.status === 200) {
        let nodedata = [...nodeHints]
        if (nodedata.length === 0) {
          nodedata.push({ ...res.data })
        }
        const newState = nodedata.map(obj => {
          if (obj.Resource_ID === res.data.Resource_ID) {
            return { ...obj, Resource_Display: res.data.Resource_Display, Resource_Text: res.data.Resource_Text, Resource_URL: res.data.Resource_URL, resourcetopicsubsectionname: res.data.resourcetopicsubsectionname, sub_section_id: res.data.sub_section_id, Is_Resource_Reusable: res.data.Is_Resource_Reusable, resourcetopicmasterid: res.data.resourcetopicmasterid, sub_sectiondata: res.data.sub_sectiondata };
          }
          return obj;
        });


        setNodeHints(newState);
        setSeletedResourceIdForEdit("")
        toast('Data saved successfully', {
          type: toast.TYPE.WARNING,
          position: toast.POSITION.BOTTOM_RIGHT
        });
        setShowhintmodel(false)
        setopensavehintmodal(false)

        if (showNodeFieldsForMCQ) {
          mcqDataObject.hints = newState;
          gridApi.refreshCells();
        }
        else {
          setElements((els) =>
            els.map((el) => {


              if (el.id === element.id) {
                el.data = {
                  ...el.data,
                  hints: newState
                };
              }
              else {
                if (el.data.hints !== undefined) {
                  if (el.data.hints.length > 0) {
                    let data = el.data.hints.map(obj => {

                      if (obj.Resource_ID === res.data.Resource_ID) {
                        return { ...obj, Resource_Display: res.data.Resource_Display, Resource_Text: res.data.Resource_Text, Resource_URL: res.data.Resource_URL, resourcetopicsubsectionname: res.data.resourcetopicsubsectionname, sub_section_id: res.data.sub_section_id, Is_Resource_Reusable: res.data.Is_Resource_Reusable, resourcetopicmasterid: res.data.resourcetopicmasterid, sub_sectiondata: res.data.sub_sectiondata };
                      }
                      return obj;
                    });
                    el.data = {
                      ...el.data,
                      hints: data
                    };
                  }
                }

              }
              return el;
            })
          );
        }

        let resourceids = newState.map(item => item.Resource_ID).join(',')
        setresourceids(resourceids);
      }
    }).catch(err => {
      console.error(err.message)
    })

  }
  const savehintonlyforselectednode = async () => {
    fnsaveresource(1)
    setopensavehintmodal(false)
    setShowhintmodel(false)
  }
  const fnsaveresource = async (onlyforcurrentnode) => {
    let form_data = new FormData();
    if (showNodeFieldsForMCQ) {
      form_data.append('remedial_resource_display', mcqDataObject.remedialResourcedisplay);
      form_data.append('is_remedial_resoue_reusable', mcqDataObject.isRemedialResoueReusable ? 1 : 0);
      form_data.append('remedial_resource_urls', mcqDataObject.remedialResourceURLs);
      form_data.append('requisite_knowledge', '');
      form_data.append('hint_text', mcqDataObject.hintText);
      form_data.append('node_id', mcqDataObject.optionId);
      if (mcqDataObject.sub_section_id !== undefined && mcqDataObject.sub_section_id !== null)
        form_data.append('subsection_id', mcqDataObject.sub_section_id);
      if (mcqDataObject.resourcetopicmasterid !== undefined && mcqDataObject.resourcetopicmasterid !== null)
        form_data.append('topic_resource_id', mcqDataObject.resourcetopicmasterid);
      form_data.append('isnewmode', isnewmode);
      form_data.append('onlyforcurrentnode', onlyforcurrentnode)
    }
    else {
      form_data.append('remedial_resource_display', element.data.remedialResourcedisplay);
      form_data.append('is_remedial_resoue_reusable', element.data.isRemedialResoueReusable ? 1 : 0);
      form_data.append('remedial_resource_urls', element.data.remedialResourceURLs);
      form_data.append('requisite_knowledge', '');
      form_data.append('hint_text', element.data.hintText);
      form_data.append('node_id', element.id);
      if (element.data.sub_section_id !== undefined && element.data.sub_section_id !== null)
        form_data.append('subsection_id', element.data.sub_section_id);
      if (element.data.resourcetopicmasterid !== undefined && element.data.resourcetopicmasterid !== null)
        form_data.append('topic_resource_id', element.data.resourcetopicmasterid);
      form_data.append('isnewmode', isnewmode);
      form_data.append('onlyforcurrentnode', onlyforcurrentnode)
    }

    await updateResources(form_data).then(res => {
      if (res.status === 200) {

        // setNodeHints([...nodeHints, res.data]);
        // let tempArr = nodeHints;
        // tempArr.push({ ...res.data })
        // setNodeHints(tempArr);

        // if(onlyforcurrentnode === 1)        {
        //   setTimeout(() => {
        //     removeHint(seletedResourceIdForEdit);
        //   }, 100)
        // }


        // setSeletedResourceIdForEdit('')
        // toast('Data saved successfully', {
        //   type: toast.TYPE.WARNING,
        //   position: toast.POSITION.BOTTOM_RIGHT
        // });
        // setShowhintmodel(false)

        // setElements((els) =>
        //   els.map((el) => {
        //     if (el.id === element.id) {
        //       el.data = {
        //         ...el.data,
        //         hints: tempArr
        //       };
        //     }
        //     return el;
        //   })
        // );


        let nodedata = [...nodeHints]
        if (nodedata.length === 0) {
          nodedata.push({ ...res.data })
        }
        else {
          if (nodedata.some(e => e.Resource_ID !== res.data.Resource_ID)) {
            nodedata.push({ ...res.data })
          }
        }
        //}
        const newState = nodedata.map(obj => {
          if (obj.Resource_ID === res.data.Resource_ID) {
            return {
              ...obj, Resource_Display: res.data.Resource_Display, Resource_Text: res.data.Resource_Text
              , Resource_URL: res.data.Resource_URL, resourcetopicsubsectionname: res.data.resourcetopicsubsectionname
              , sub_section_id: res.data.sub_section_id, Is_Resource_Reusable: res.data.Is_Resource_Reusable
              , resourcetopicmasterid: res.data.resourcetopicmasterid, sub_sectiondata: res.data.sub_sectiondata
            };
          }
          return obj;
        });

        let newstatedata = [...newState]
        if (onlyforcurrentnode === 1) {
          if (seletedResourceIdForEdit !== "" && seletedResourceIdForEdit !== null && seletedResourceIdForEdit !== undefined) {
            let objectdata = newstatedata.filter(i => i.Resource_ID !== seletedResourceIdForEdit)
            newstatedata = objectdata
          }
        }
        setNodeHints(newstatedata);
        setSeletedResourceIdForEdit("")
        toast('Data saved successfully', {
          type: toast.TYPE.WARNING,
          position: toast.POSITION.BOTTOM_RIGHT
        });
        setShowhintmodel(false)

        if (showNodeFieldsForMCQ) {
          mcqDataObject.hints = newstatedata;
          var optionIdIndex = null
          var tempOptionIdIndex = null
          if (!isNil(mcqDataObject.tempOptionId)) {
            if (!isNil(gridApi.getRowNode(mcqDataObject.tempOptionId)))
              tempOptionIdIndex = mcqArray.map(function (obj) { return obj.tempOptionId; }).indexOf(mcqDataObject.tempOptionId)
          }
          else
            optionIdIndex = mcqArray.map(function (obj) { return obj.optionId; }).indexOf(mcqDataObject.optionId)

          let rowNodeIndex = optionIdIndex === null ? (tempOptionIdIndex === null ? 0 : tempOptionIdIndex) : optionIdIndex
          let row = mcqArray[mcqArray.length === -1 ? 0 : rowNodeIndex];
          if (row !== undefined)
            row.hints = newstatedata;

          gridApi.refreshCells();
        }
        else {
          setElements((els) =>
            els.map((el) => {

              if (el.id === element.id) {
                el.data = {
                  ...el.data,
                  hints: newstatedata
                };
              }
              return el;
            })
          );
        }

        let resourceids = newstatedata.map(item => item.Resource_ID).join(',')
        setresourceids(resourceids);
      }
    }).catch(err => {
      console.error(err.message)
    })
  }

  const handleaddRemedialresource = async () => {
    if (nodehintRemedialText === '' && hintTextSelected.text === undefined) {
      setrequiredmessage(true)
    }
    else {
      if (addeditmode === "Edit") {
        let previousdata = hintTextSelected;
        if (showNodeFieldsForMCQ) {
          if (previousdata.text !== undefined) {
            if (mcqDataObject.remedialResourcedisplay === "" && mcqDataObject.hintText.replace(/(<([^>]+)>)/ig, '').replace(/[\r\n]+/gm, "") === "" && mcqDataObject.remedialResourceURLs === "") {
              setrequiredmessage(true)
            }
            else {
              let prhinttext = parse(previousdata.text.hint.replace(/(<([^>]+)>)/ig, ''))
              prhinttext = prhinttext.replace(/[\r\n]+/gm, "");

              let curhinttext = mcqDataObject.hintText.toString().replace(/(<([^>]+)>)/ig, '')
              curhinttext = curhinttext.replace(/[\r\n]+/gm, "");
              if (previousdata.text.Resource_Display !== mcqDataObject.remedialResourcedisplay ||
                previousdata.text.Resource_url !== mcqDataObject.remedialResourceURLs ||
                prhinttext !== curhinttext
              ) {
                setrequiredmessage(false)
                setopensavehintmodal(true)

              }
              else {
                if (mcqDataObject.remedialResourcedisplay === "" && mcqDataObject.hintText.replace(/(<([^>]+)>)/ig, '').replace(/[\r\n]+/gm, "") === "" && mcqDataObject.remedialResourceURLs === "") {
                  setrequiredmessage(true)
                }
                else {
                  setrequiredmessage(false)
                  fnsaveresource(0)
                  setopensavehintmodal(false)
                }
              }
            }
          }
          else {
            if (mcqDataObject.remedialResourcedisplay === "" && mcqDataObject.hintText.replace(/(<([^>]+)>)/ig, '') === "" && mcqDataObject.remedialResourceURLs === "") {
              setrequiredmessage(true)
            }
            else {
              setrequiredmessage(false)
              fnsaveresource(0)
              setopensavehintmodal(false)
            }
          }
        }
        else {
          if (previousdata.text !== undefined) {
            if (element.data.remedialResourcedisplay === "" && element.data.hintText.replace(/(<([^>]+)>)/ig, '').replace(/[\r\n]+/gm, "") === "" && element.data.remedialResourceURLs === "") {
              setrequiredmessage(true)
            }
            else {

              let prhinttext = parse(previousdata.text.hint.replace(/(<([^>]+)>)/ig, ''))
              prhinttext = prhinttext.replace(/[\r\n]+/gm, "");

              let curhinttext = element.data.hintText.toString().replace(/(<([^>]+)>)/ig, '')
              curhinttext = curhinttext.replace(/[\r\n]+/gm, "");
              if (previousdata.text.Resource_Display !== element.data.remedialResourcedisplay ||
                previousdata.text.Resource_url !== element.data.remedialResourceURLs ||
                prhinttext !== curhinttext
              ) {
                setrequiredmessage(false)
                setopensavehintmodal(true)

              }
              else {
                if (element.data.remedialResourcedisplay === "" && element.data.hintText.replace(/(<([^>]+)>)/ig, '').replace(/[\r\n]+/gm, "") === "" && element.data.remedialResourceURLs === "") {
                  setrequiredmessage(true)
                }
                else {
                  setrequiredmessage(false)
                  fnsaveresource(0)
                  setopensavehintmodal(false)
                }

              }
            }

          }
          else {
            if (element.data.remedialResourcedisplay === "" && element.data.hintText.replace(/(<([^>]+)>)/ig, '') === "" && element.data.remedialResourceURLs === "") {
              setrequiredmessage(true)
            }
            else {
              setrequiredmessage(false)
              fnsaveresource(0)
              setopensavehintmodal(false)
            }

          }
        }
      }
      else {
        setrequiredmessage(false)
        fnsaveresource(0)
        setopensavehintmodal(false)
      }
    }
  }

  const getErrorType = (error) => {
    if (error === BASIC_ERROR) {
      return 0;
    } else if (error === AUTHOR_COMBINATION)
      return 1;
    else if (error === SYSTEM_COMBINATION) {
      return 2;
    } else {
      return 3;
    }
  }

  const saveMCQsbtnClick = async () => {
    if (optionIds.length !== 0) {
      let stepsArr = [];
      optionIds.forEach(element => {
        let o = {
          optionId: parseInt(element)
        }
        stepsArr.push(o);
      });

      let form_data = new FormData();
      form_data.append("Challenge_ID", challengeId);
      form_data.append("answer_ids", JSON.stringify(stepsArr));

      //console.log("form Data to Delete - " + JSON.stringify(stepsArr));
      await deleteChallengeMCQ(form_data).then(res => {
        if (res.status === 200) {
          optionIds = [];
          toast.success("Answers removed successfully!", {
            position: toast.POSITION.BOTTOM_RIGHT
          });
        }
      }).catch(err => {
        toast.error("Error!", {
          position: toast.POSITION.BOTTOM_RIGHT
        });
        console.error(err.message)
      })
    }

    if (showNodeFieldsForMCQ || openHideMCQGrid) {
      let mcqElements = [];
      mcqArray.forEach(element => {
        let ResourceID = element.hints;
        let rmString = "";

        ResourceID && ResourceID.forEach((value) => {
          rmString += rmString === "" ? value.Resource_ID : "," + value.Resource_ID;
        })

        let o = {
          optionId: (element.tempOptionId === undefined ? parseInt(element.optionId) : null),
          optionText: element.optionText,
          requisiteKnowledge: element.requisiteKnowledge === undefined ? "" : element.requisiteKnowledge,
          isCorrect: element.isCorrect ? 1 : 0,
          resourceId: rmString
        }

        mcqElements.push(o);
      });

      let form_data = new FormData();
      form_data.append("Challenge_ID", challengeId);
      form_data.append("MCQElements", JSON.stringify(mcqElements));

      //console.log("form Data" + JSON.stringify(mcqElements));
      await UpdateChallengeMCQ(form_data).then(res => {
        if (res.status === 200) {
          toast.success("Answers updated successfully!", {
            position: toast.POSITION.BOTTOM_RIGHT
          });

          // window.location.reload()
        }
      }).catch(err => {
        toast.error("Error!", {
          position: toast.POSITION.BOTTOM_RIGHT
        });
        console.error(err.message)
      })
    }
  }

  const exportJson = async () => {

    elements.forEach(element => {
      if (element.type !== 'default') {
        element.data.parent = "";
        element.data.optional_parent = "";
        element.data.child = "";
        element.data.incorrect_variants = "";
        element.data.optional_child = "";
      }
    })

    let isValid = true;
    elements.forEach(element => {
      if (element.type === 'default') {
        const p = filter(elements, { "id": element["source"] });
        const c = filter(elements, { "id": element["target"] });

        if (!isNil(p)) {
          if (!p[0]['data']['importance'] !== OPTIONAL) {
            c[0]['data']['parent'] += c[0]['data']['parent'] === "" ? p[0]['id'] : "," + p[0]['id'];
          } else {
            c[0]['data']['optional_parent'] += c[0]['data']['optional_parent'] === "" ? p[0]['id'] : "," + p[0]['id'];
          }

        }
        if (!isNil(c)) {
          if (!c[0]['data']['importance'] !== OPTIONAL && c[0]['data']['isNodeCorrect']) {
            p[0]['data']['child'] += p[0]['data']['child'] === "" ? c[0]['id'] : "," + c[0]['id'];
          } else if (!c[0]['data']['isNodeCorrect']) {
            p[0]['data']['incorrect_variants'] += p[0]['data']['incorrect_variants'] === "" ? c[0]['id'] : "," + c[0]['id'];
          } else if (c[0]['data']['importance'] === OPTIONAL) {
            p[0]['data']['optional_child'] += p[0]['data']['optional_child'] === "" ? c[0]['id'] : "," + c[0]['id'];
          }
        }
      } else {
        if (!element.data.isValid && isValid) {
          isValid = false;
          toast('Please fill required information for nodes', {
            type: toast.TYPE.WARNING,
            position: toast.POSITION.BOTTOM_RIGHT
          });

        }
      }
    });

    if (!isValid) return;
    let stepsArr = [];

    elements.forEach(element => {
      if (element.type !== 'default') {
        let ResourceID = element.data.hints;
        let rmString = "";
        // let temphints = ResourceID.filter(ele => ele.node_id === element.id);

        ResourceID && ResourceID.forEach((value) => {
          //if (parseInt(value.node_id) === parseInt(element.id)) {
          rmString += rmString === "" ? value.Resource_ID : "," + value.Resource_ID;
          //}

        })

        let equationsString = element.data.equation
        if (equationsString !== "" && equationsString !== null && equationsString !== undefined) {
          equationsString = equationsString.replace(/(<([^>]+)>)/ig, '')
        }
        let o = {
          node_id: parseInt(element.id),
          is_required: element.data.importance !== OPTIONAL ? 1 : 0,
          is_primary: element.data.importance === PRIMARY ? 1 : 0,
          is_optional: element.data.importance === OPTIONAL ? 1 : 0,
          is_nonPrimary: element.data.importance === NON_PRIMARY ? 1 : 0,
          error_source: getErrorType(element.data.errortype),
          prereqs: element.data.parent,
          optional_prereqs: element.data.optional_parent,
          equation_latex: element.data.label,
          equation: equationsString,
          unit: element.data.unit,
          points: element.data.points,
          eq_id: element.data.eq_id,
          decimalPoints: element.data.decimalPoints,
          hints_perc: element.data.hints_perc,
          explanation: element.data.explanation,
          incorrect_variants: element.data.incorrect_variants,
          remedial_resources: element.data.remedialResources,
          requisite_knowledge: element.data.requisiteKnowledge === undefined ? "" : element.data.requisiteKnowledge,
          final: 0,
          step: 1,
          correct: element.data.isNodeCorrect ? 1 : 0,
          hint_error_root_cause: element.data.hintErrorRootCause,
          Resource_ID: rmString,
          mathml: element.data.mathml,
          unitMathml: element.data.unitMathml,
          xPos: element.originalPosition.x,
          yPos: element.originalPosition.y,
          equation_latex_comment: element.data.equation_latex_comment,
          explanation_comment: element.data.explanation_comment,
          equation_comment: element.data.equation_comment,
          explanation_previous_comment: element.data.explanation_previous_comment,
          equation_latex_previous_comment: element.data.equation_latex_previous_comment,
          equation_previous_comment: element.data.equation_previous_comment,
          is_ue: element.data.is_ue
        }
        stepsArr.push(o);
        element.data.incorrect_variants = element.data.parent = element.data.child = element.data.optional_parent = element.data.optional_child = "";
      }
    });
    // debugger;

    let draggedUE_Ids = draggedUEIds
    let form_data = new FormData();
    form_data.append("Challenge_ID", (challengeId));
    form_data.append("ui_json", JSON.stringify(elements));
    form_data.append("nodes", JSON.stringify(stepsArr));
    form_data.append('challenge_status', challengedata.challenge_state);
    form_data.append("draggedUE_Ids", draggedUE_Ids);

    if (challengeId !== null) {
      await updateChallengenodes(form_data).then(res => {
        if (res.status === 200) {
          getRemedialResources();
          toast.success("Graph saved successfully!", {
            position: toast.POSITION.BOTTOM_RIGHT
          });
          checkCombination(challengeId).then(res => {
            console.log('check combination success')
          }).catch(err => {
            console.error(err.message)
          })
          // setaftersave(true)
          // setisloading(false)
          // handleToggleSidebar(false);
          window.location.reload()
        }
      }).catch(err => {

        toast.error("Error!", {
          position: toast.POSITION.BOTTOM_RIGHT
        });
        console.error(err.message)

      })
    }
  };

  const openWiris = (openFor) => {
    setWirisOpenFor(openFor);
    // debugger;
    if (openFor === WIRIS_FOR.EQUATION && !isEmpty(nodeMathml)) {
      if (nodeMathml !== undefined)
        wirisEditor.setMathML(nodeMathml);
    } else if (openFor === WIRIS_FOR.UNIT && !isEmpty(unitMathml)) {
      if (unitMathml !== undefined)
        wirisEditor.setMathML(unitMathml);
    } else {
      wirisEditor.setMathML('<math xmlns="http://www.w3.org/1998/Math/MathML"></math>');
    }
    document.getElementById('wirisModal').style.display = 'block';
    wirisEditor.focus();
  }

  const closeWiris = () => {
    document.getElementById('wirisModal').style.display = 'none'
  }


  const loadSympyEquation = (latex, unitLatex, mathml, unitMathml) => {
    const latexReqData = {
      params: {
        equation_latex: latex,
        unit_latex: unitLatex,
        challengeId: challengeId,
        mathml: mathml,
        unit_mathml: unitMathml

      },
    };
    getSympyEquation(latexReqData)
      .then((res) => {
        // debugger;
        // if(wirisOpenFor === WIRIS_FOR.EQUATION){
        //   setEquation(res.data.equation)
        // }else {
        //   setUnitLatex(res.data.equation);
        // }  
        setEquation(res.data.equation)
      })
      .catch((err) => {
        console.error("Unable to convert latex to svg");
      });
  }

  const getMathmlFromWiris = () => {
    let mathml = wirisEditor.getMathML();
    if (wirisOpenFor === WIRIS_FOR.EQUATION) {
      setNodeMathml(mathml)
    } else {
      setUnitMathml(mathml);

    }
    closeWiris();
    const latexReqData = {
      params: {
        mml: mathml
      },
    };
    generateLatexFromMathML(latexReqData)
      .then((res) => {
        if (wirisOpenFor === WIRIS_FOR.EQUATION) {
          setNodeName(res.data);
          loadSympyEquation(res.data, unit, mathml, unitMathml);
        } else {
          // debugger;
          setUnit(res.data);
          loadSympyEquation(nodeName, res.data, nodeMathml, mathml);
        }


      })
      .catch((err) => {
        console.error("Unable to convert latex to svg");
      });

  }

  return (
    <>

      <Modal show={showcommentmodal} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>{modelheadertitle}</Modal.Title>
        </Modal.Header>
        <Modal.Body><textarea
          class="form-control bg-yellow"
          rows="10"
          value={reviewcomments}
          onChange={handlereviewercomment}
        /></Modal.Body>
        <Modal.Footer>
          <Button variant="primary" onClick={handleClose}>
            Save
          </Button>
        </Modal.Footer>
      </Modal>
      <Dialog
        hideBackdrop={false}
        open={opensavehintmodal}
        onClose={handlehintClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          Alert
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            This change needs to be applied to all nodes?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <button className="dragLabel" onClick={() => handlehintapplyforallnodes()}>
            Yes
          </button>
          <button className="dragLabelcancel leftmargin" onClick={() => savehintonlyforselectednode()}>
            No
          </button>
        </DialogActions>
      </Dialog>
      <Dialog disableEnforceFocus={true} hideBackdrop={false} open={showhintmodel} onClose={handleClosehintmodal} aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description">
        <DialogTitle closeButton style={{ paddingTop: "0.50rem", paddingBottom: "0.50rem" }}>
          <Modal.Title>{hintmodalheadertext}</Modal.Title>
        </DialogTitle>
        <DialogContent>
          <Accordion style={{ width: '100%' }} expanded={isHintDetailsSelected} >
            <AccordionDetails>
              <Typography>
                <div style={{ height: '100%' }}>
                  {/* <label className="labels">{IsCorrect ? 'Hint details' : 'Remedial details'}:</label>  */}
                  <div className="name-node" style={{ width: '400px' }} title={IsCorrect ? "Reuse a Hint written previously within this solution" : "Reuse a remedial text written previously within this solution"}>
                    <label className="labels">{IsCorrect ? "Select Hint" : "Select Remedial"}:</label> <br />
                    <Select
                      className="dropDown"
                      placeholder={IsCorrect ? "Reuse a Hint written previously within this solution" : "Reuse a Remedial written previously within this solution"}
                      getOptionLabel={option => option.name}
                      getOptionValue={option => option.text}
                      onChange={handleHintTextResource}
                      options={hintTextOptions}
                      value={hintTextSelected}
                      isSearchable={true}
                      isClearable="true"
                      formatOptionLabel={function (data) {
                        return (
                          <span dangerouslySetInnerHTML={{ __html: data.name }} />
                        );
                      }}
                    />
                  </div>
                  <span className="noteText">Note: Select existing {informationtext} text as is/edit it in textbox below or Enter a new {informationtext} text in textbox</span>
                  <div className="name-node addedHintsContainer" style={{ height: "200px", overflowY: "auto" }}>
                    <label className="labels">{IsCorrect ? "Hint Text" : "Remedial Text"}</label> <br />
                    <CKEditor
                      data={nodehintRemedialText}
                      editor={ClassicEditor}
                      onReady={(e) => {
                        setHintEditor(e.editor)
                      }}
                      config={editorConfiguration}
                      onChange={(e, editor) => { setNodehintRemedialText(editor.getData()) }}
                    />
                  </div>

                  <hr className="horizontalRuler" />
                  {/* <div className="name-node" style={{ width: '400px' }}>
                    <label className="labels">{IsCorrect ? "Select hint resource" : "Select remedial resource"}:</label> <br />
                    <Select
                      placeholder={IsCorrect ? "Select hint resource" : "Select remedial resource"}
                      getOptionLabel={option => option.display}
                      getOptionValue={option => option.value}
                      onChange={handleChangeRemedialResource}
                      options={remedialResourceOption}
                      value={remedialResourceOption && remedialResourceOption.length > 0 ? remedialResourceSelected : null}
                      formatOptionLabel={function (data) {
                        return (
                          <span dangerouslySetInnerHTML={{ __html: data.display }} />
                        );
                      }}
                    />
                  </div>
                  <span className="noteText">Note: Select existing {informationtext} resource to use as is/edit it in textbox below or Enter a new {informationtext} resource in textbox</span> */}
                  <div className="name-node" style={{ width: '400px' }} >
                    <label className="labels">Select Resource Topic:</label> <br />
                    <Select
                      className="dropDown"
                      placeholder="Resource Topic"
                      isSearchable={false}
                      isClearable="true"
                      getOptionLabel={option => option.name}
                      getOptionValue={option => option.subSectionId}
                      onChange={handleresourcetopic}
                      options={errorRootCauseValue}
                      value={resourcetopicvalue}
                      menuPortalTarget={document.body}
                      isDisabled={resourcetopicdisbaled}
                      styles={{ menuPortal: base => ({ ...base, zIndex: 9999 }) }}
                    />
                  </div>

                  <div className="name-node" style={{ width: '400px' }} >
                    <div style={{ display: "flex", justifyContent: "space-between" }}>
                      <div><label className="labels">Select Resources:</label></div>
                      {
                        neweditbuttonvisible ? (<div>
                          <button
                            className="linkButton"
                            onClick={handlenewresource}>
                            New
                          </button>
                          <button
                            className="linkButton"
                            onClick={handleeditresource}
                            style={{ marginLeft: "5px" }}>
                            Edit
                          </button>
                        </div>) : null
                      }

                    </div>

                    <Select
                      className="dropDown"
                      placeholder="Resources"
                      getOptionLabel={option => option.name}
                      getOptionValue={option => option.text}
                      onChange={handleresources}
                      options={resourcesdata}
                      value={resourcesvalue}
                      //isSearchable={true}
                      isClearable="true"
                      formatOptionLabel={function (data) {
                        return (
                          <span dangerouslySetInnerHTML={{ __html: data.name }} />
                        );
                      }}

                      menuPortalTarget={document.body}
                      styles={{ menuPortal: base => ({ ...base, zIndex: 9999 }) }}

                    // getOptionLabel={option => option.name}
                    // getOptionValue={option => option.subSectionId}
                    // onChange={handleresourcetopic}
                    // options={errorRootCauseValue}
                    // value={resourcetopicvalue}
                    />
                  </div>
                  <div className="name-node">
                    <div style={{ display: "flex", justifyContent: "space-between" }}>
                      <label className="labels padding0">Resource Name:</label>
                      {/* <label className="labels padding0">{IsCorrect ? "Hint Resource Display" : "Remedial Resource Display"}:</label> */}
                    </div>
                    <input
                      style={{ width: '400px', border: "1px solid #ccc", background: resourceurldisabled ? "#efefef" : "transparent" }}
                      value={nodeRemedialResourcedisplay || ""}
                      disabled={resourcenamedisabled}
                      onChange={(evt) => {
                        setNodeRemedialResourcedisplay(evt.target.value);
                      }}
                    />

                  </div>
                  <div className="name-node" >
                    {/* <label className="labels">{IsCorrect ? "Hint Resource URL" : "Remedial Resource URL"}:</label> <br /> */}
                    <label className="labels">Resource URL:</label> <br />
                    <textarea
                      cols="30"
                      rows="5"
                      required
                      className="textarea"
                      disabled={resourceurldisabled}
                      style={{ width: '400px', border: "1px solid #ccc", background: resourceurldisabled ? "#efefef" : "transparent" }}
                      value={nodehintRemedialResource || ""}
                      onChange={(evt) => setNodehintRemedialResource(evt.target.value)}
                    ></textarea>
                  </div>
                  <div className="name-node">
                    <div style={{ display: "flex", justifyContent: "space-between" }}>

                      <label className="labels padding0">
                        <input
                          type="checkbox"
                          required
                          checked={isreusable}
                          onChange={() => { setIsReusable((prev) => !prev) }}
                        />
                        {IsCorrect ? " Is Resource Reusable ?" : " Is Resource Reusable ?"}
                      </label>
                    </div>
                  </div>
                  {
                    requiredmessage ? (
                      <div className="name-node">
                        <div style={{ paddingLeft: "5px", color: "red" }}>
                          {HintRequredMessage.message}
                        </div>
                      </div>
                    ) : null
                  }
                  {/* <hr className="horizontalRuler" /> */}
                </div>
              </Typography>
            </AccordionDetails>
          </Accordion>
        </DialogContent>
        <DialogActions>
          <div className="name-node saveHintNode" >
            <button className="dragLabel" onClick={() => handleaddRemedialresource()}>
              Save
            </button>
            <button className="dragLabelcancel leftmargin" onClick={() => hideAddRemedials()}>
              Cancel
            </button>
          </div>
        </DialogActions>
      </Dialog>

      <div id="dndflow" className="dndflow" ref={reactFlowWrapper} style={props.readOnly ? { minHeight: '100px' } : { minHeight: 'calc(100vh - 100px)' }}>
        {!props.readOnly ?
          (<div className="bottomcontent">

            <div className="backdiv">

              <OverlayTrigger
                key="top"
                placement="top"
                overlay={
                  <Tooltip id={`tooltip-top`}>
                    Back to Interactive Problems
                  </Tooltip>
                }
              >
                <Link style={{ paddingLeft: "0px", color: "#000" }} to="/curriculum-problems"><i class="fa fa-arrow-left" aria-hidden="true"></i></Link>
              </OverlayTrigger>

              {
                openHideReactFlow ? (<>
                  {
                    !savegraphDisabled ? (
                      <div className="dndnode output">
                        {
                          buttondisabled ? (<button className="dragLabeldisabled leftmargin" draggable disabled={buttondisabled} > Drag New Node</button>) : (
                            <button className="dragLabel leftmargin" onDragStart={(event) => onDragStart(event, "nodeWithOnlyText", "text")} draggable>Drag New Node</button>)
                        }
                        {
                          buttondisabled ? (
                            <button className="dragLabeldisabled leftmargin" disabled={buttondisabled}>Save</button>) :
                            (<button className="dragLabel leftmargin" onClick={exportJson}>Save</button>)
                        }
                      </div>
                    )
                      :
                      (

                        <div className="dndnode output">
                          <button className="dragLabel leftmargin" onClick={exportJson}>Save</button>
                        </div>


                      )
                  }
                </>) : null
              }

              {openHideMCQGrid &&
                <div className="dndnode output">
                  {/* <button className="dragLabel leftmargin" onClick={addNewOptionbtnClick}> Add Challenge Options </button>
                <button id="btnSaveOptions" className="dragLabel leftmargin" onClick={saveMCQsbtnClick}> Save Options </button><br /> */}
                  {buttondisabled ? (<button className="dragLabeldisabled leftmargin" disabled={buttondisabled}> Add Challenge Options </button>) : (<button className="dragLabel leftmargin" onClick={addNewOptionbtnClick}> Add Challenge Options </button>)}
                  {buttondisabled ? (<button id="btnSaveOptions" className="dragLabeldisabled leftmargin" disabled={buttondisabled}> Save </button>) : (<button id="btnSaveOptions" className="dragLabel leftmargin" onClick={saveMCQsbtnClick}> Save </button>)}  &nbsp;&nbsp;
                </div>}
            </div>
            {statustext}
            <div className="legendContainer">
              <span className="statuslabel" style={{ marginLeft: "10px", }}>Status : {challengedata.challenge_state}</span>
              {openHideReactFlow &&
                <div className="legendItemContainer">
                  <div className="correct"></div>
                  <span>Correct/Intermediate step </span>
                </div>}
              {openHideReactFlow &&
                <div className="legendItemContainer">
                  <div className="optional"></div>
                  <span>Optional step</span>
                </div>}
              {openHideReactFlow &&
                <div className="legendItemContainer">
                  <div className="incorrect"></div>
                  <span>Incorrect step</span>
                </div>}

            </div>
          </div >) : null
        }

        {
          !props.readOnly && !openHideMCQGrid ? (<div style={{ position: "absolute", top: 60, zIndex: "99999" }}>
            <Accordion >
              <AccordionSummary
                className={classes.summarycss}
                expandIcon={<ExpandMoreIcon />} aria-controls="panel1a-content" id="panel1a-header">
                <Typography>
                  <Row>
                    <Col className='col-12 col-md-12'>
                      <p style={{ float: 'left' }} className='unreheader'>
                        Common Student Errors
                      </p>
                    </Col>
                  </Row>
                </Typography>
              </AccordionSummary>
              <AccordionDetails sx={{ height: "400px" }}>
                <div className='questions' style={{ display: "flex", justifyContent: "space-between", borderBottom: "1px solid #ccc", fontWeight: "500" }}>
                  <div style={{ paddingBottom: "10px" }}>Equations</div>
                  <div style={{ paddingBottom: "10px" }}>Count</div>
                </div>
                {
                  uejsondata !== undefined && uejsondata !== null && uejsondata.length > 0 ? (
                    uejsondata.map((item, index) => {
                      let equation = ""
                      let equationcount = ""
                      if (item.data !== undefined && item.data.label !== "" && item.data.label !== undefined && item.data.label !== null) {
                        equation = item.data.label
                      }
                      if (item.data !== undefined && item.data.cnteq !== "" && item.data.cnteq !== undefined && item.data.cnteq !== null) {
                        equationcount = item.data.cnteq
                      }
                      return (
                        <Typography
                          sx={{
                            borderBottom: "1px solid #ccc",
                            marginTop: "10px",

                          }}>
                          <div className='questions' style={{ display: "flex", justifyContent: "space-between", marginTop: "10px", marginBottom: "10px" }}>
                            <button
                              style={{ backgroundColor: "#4252af", padding: "10px", color: "#fff", fontSize: "14px", fontWeight: "500", width: "80%" }}
                              onDragStart={(event) => onDragStartUE(event, "nodeWithOnlyText", "text", item)}
                              draggable>

                              <MathComponent tex={equation}></MathComponent>
                            </button>

                            <span style={{ width: "19%", marginLeft: "30px", borderLeft: "1px solid #ccc", padding: "10px" }}>{equationcount}</span>
                          </div>
                        </Typography>)
                    })
                  ) : <div style={{ marginTop: "10px" }}>No data found!</div>
                }
              </AccordionDetails>
            </Accordion>
          </div>) : null
        }






        {
          !props.readOnly && !openHideMCQGrid ? (<div style={{ position: "absolute", top: 150, zIndex: "9999" }}>
            <Accordion >
              <AccordionSummary
                className={classes.summarycss}
                expandIcon={<ExpandMoreIcon />} aria-controls="panel1a-content" id="panel1a-header">
                <Typography>
                  <Row>
                    <Col className='col-12 col-md-12'>
                      <p style={{ float: 'left' }} className='unreheader'>
                        Common Recognize Incorrect Equations
                      </p>
                    </Col>
                  </Row>
                </Typography>
              </AccordionSummary>
              <AccordionDetails sx={{ height: "400px" }}>
                <div className='questions' style={{ display: "flex", justifyContent: "space-between", borderBottom: "1px solid #ccc", fontWeight: "500" }}>
                  <div style={{ paddingBottom: "10px" }}>Equations</div>
                  <div style={{ paddingBottom: "10px" }}>Count</div>
                </div>
                {
                  rIncorrectjsondata !== undefined && rIncorrectjsondata !== null && rIncorrectjsondata.length > 0 ? (
                    rIncorrectjsondata.map((item, index) => {
                      let equation = ""
                      let equationcount = ""
                      if (item.data !== undefined && item.data.label !== "" && item.data.label !== undefined && item.data.label !== null) {
                        equation = item.data.label
                      }
                      if (item.data !== undefined && item.data.cnteq !== "" && item.data.cnteq !== undefined && item.data.cnteq !== null) {
                        equationcount = item.data.cnteq
                      }
                      return (
                        <Typography
                          sx={{
                            borderBottom: "1px solid #ccc",
                            marginTop: "10px",

                          }}>
                          <div className='questions' style={{ display: "flex", justifyContent: "space-between", marginTop: "10px", marginBottom: "10px" }}>
                            <button
                              style={{ backgroundColor: "#4252af", padding: "10px", color: "#fff", fontSize: "14px", fontWeight: "500", width: "80%"}}
                              //onDragStart={(event) => onDragStartUE(event, "nodeWithOnlyText", "text", item)}
                              //draggable
                              >

                              <MathComponent tex={equation}></MathComponent>
                            </button>

                            <span style={{ width: "19%", marginLeft: "30px", borderLeft: "1px solid #ccc", padding: "10px" }}>{equationcount}</span>
                          </div>
                        </Typography>)
                    })
                  ) : <div style={{ marginTop: "10px" }}>No data found!</div>
                }
              </AccordionDetails>
            </Accordion>
          </div>) : null
        }


        <ReactFlowProvider>
          {openHideReactFlow && <div className="reactflow-wrapper" id="reactflow-wrapper">
            <ReactFlow
              elements={elements}
              onConnect={onConnect}
              onElementClick={onElementClick}
              onElementsRemove={onElementsRemove}
              nodeTypes={nodeTypes}
              onLoad={onLoad}
              onDrop={onDrop}
              onDragOver={onDragOver}
              onNodeDragStop={onDragEnd}
              onNodeMouseEnter={(event, node) =>
                handleNodeMouseEvents('onNodeMouseEnter', node)
              }
              onNodeMouseLeave={(event, node) =>
                handleNodeMouseEvents('onNodeMouseLeave', node)
              }
              defaultZoom={0}
            >
              {/* <Background color="#000" gap={12} />
              {nodeHidden ? (
                ""
              ) : (
                <MiniMap
                  nodeColor={(n) => {
                    if (n.type === "input") return "blue";
                    return "#FFCC00";
                  }}
                />
              )} */}
              <Controls />
            </ReactFlow>
          </div>}
          {openHideMCQGrid &&
            <div style={{ height: '95%', width: '95%', paddingBottom: '2%' }} class="ag-theme-alpine">

              <div style={{ height: '95%', width: '95%', paddingBottom: '1%' }} class="ag-theme-alpine">
                <GridComponent
                  gridOptions={gridOptions}
                  columns={gridColumnData}
                  rows={rowData}
                  gridReady={onGridReady}>
                </GridComponent>
              </div>
            </div>
          }

          <div className="nodedata">
            {props.readOnly ? null : (
              <div className="toggleContainer2" onClick={() => handleToggleSidebar(!toggled)}>
                {toggled ? <FaAngleRight /> : <FaAngleLeft />}
              </div>
            )}

            {props.readOnly ? null : (
              <div className="propertyWindowContainer">
                <aside className={`${toggled ? "toggledfront" : ""}`} style={{ textAlign: "center", display: "none" }}>
                  {!openHideMCQGrid && <div className="description">
                    {
                      element.id !== -1 && element.id !== undefined && element.id !== null && element.id !== "" && element.id !== 0 ?
                        <>
                          <div className="buttondiv">
                            {
                              isDisabledNotForUE ? (<>
                                <button className="dragLabel"
                                  disabled>
                                  Save Node
                                </button>
                                <button className="dragLabel"
                                  disabled>
                                  Delete Node
                                </button>
                                <button className="dragLabel"
                                  disabled>
                                  Duplicate Node
                                </button>
                              </>)
                                : (<>
                                  <button className="dragLabel"
                                  //onClick={() => { deleteNode(element) }}
                                  >
                                    Save Node
                                  </button>
                                  <button className="dragLabel"
                                    onClick={() => { deleteNode(element) }}>
                                    Delete Node
                                  </button>
                                  <button className="dragLabel"
                                    onClick={(evt) => { cloneNode(evt, element) }}>
                                    Duplicate Node
                                  </button>
                                </>)}

                          </div>
                        </>
                        : <><div>Please select the Node to enter more details</div></>
                    }
                  </div>}
                  {showEdgeProperties ? null : (
                    <div>
                      {hideText1 ? (
                        <>
                          <Accordion style={{ width: '100%' }} expanded={true}>

                            <AccordionDetails>
                              <Typography>
                                <div style={{ height: '100%' }}>
                                  <div>
                                    <div className="checkboxwrapper">
                                      {
                                        isDisabledNotForUE ? (<input
                                          type="checkbox"
                                          checked={IsCorrect}
                                          disabled
                                        />) : (<input
                                          type="checkbox"
                                          checked={IsCorrect}
                                          //style={{bottom:"-3px"}}
                                          onChange={(evt) => { handlecorrect(evt); handleError() }}
                                        />)
                                      }

                                      <label className="labels">Is Correct</label>
                                    </div>
                                    {hideNodeFieldsForMCQ && <div className="name-node">
                                      <label className="labels">Add/Edit Equation *</label>
                                      {
                                        showcommentsfields === true ?
                                          (
                                            <ChallengeComments
                                              commentvalue={equation_latex_comment}
                                              iscolorchange={equation_latex_comment_color_change}
                                              handlecomments={event => handlecomments(event, "equation_latex_comment", "Review Comment")}> </ChallengeComments>
                                          ) : null
                                      }
                                      <div className="equationBox">
                                        {nodeName !== '' && nodeName !== 'Add Equation' ? <MathComponent tex={nodeName} /> : null}
                                        {
                                          <button className="dragLabel" onClick={() => openWiris(WIRIS_FOR.EQUATION)}>{nodeMathml === '' ? 'Add Equation' : 'Edit Equation'}</button>
                                          // isDisabledNotForUE ? (<button className="dragLabel" disabled>{nodeMathml === '' ? 'Add Equation' : 'Edit Equation'}</button>) 
                                          // : (<button className="dragLabel" onClick={() => openWiris(WIRIS_FOR.EQUATION)}>{nodeMathml === '' ? 'Add Equation' : 'Edit Equation'}</button>)
                                        }

                                      </div>
                                    </div>}

                                    <div className="name-node">
                                      {hideNodeFieldsForMCQ && <label className="labels">Equation *</label>}
                                      {showNodeFieldsForMCQ && (IsCorrect ? <label className="labels">Correct Answer *</label> : <label className="labels">In-correct Answer *</label>)}
                                      {
                                        showcommentsfields === true ?
                                          (
                                            <ChallengeComments
                                              commentvalue={equation_comment}
                                              iscolorchange={equation_comment_color_change}
                                              handlecomments={event => handlecomments(event, "equation_comment", "Review Comment")}> </ChallengeComments>
                                          ) : null
                                      }
                                      {/* <input
                                        id="nodeText"
                                        style={{ width: "100%", border: "1px solid #ccc" }}
                                        value={equation || ""}
                                        onChange={(evt) => {
                                          setEquation(evt.target.value);
                                        }}
                                        className="labels"
                                      /> */}

                                      <CKEditor
                                        ckEditorId="nodeText"
                                        data={equation || ""}
                                        editor={ClassicEditor}
                                        config={editorConfiguration}
                                        onChange={(e, editor) => { setEquation(editor.getData()) }}
                                      />

                                    </div>
                                    {hideNodeFieldsForMCQ && <div className="name-node">
                                      <label className="labels">Unit:</label>
                                      <div className="equationBox">
                                        <MathComponent tex={unit} />
                                        {
                                          isDisabledNotForUE ? (<button className="dragLabel" disabled>
                                            {unitMathml === '' ? 'Add Unit' : 'Edit Unit'}
                                          </button>) : (<button className="dragLabel" onClick={() => openWiris(WIRIS_FOR.UNIT)}>
                                            {unitMathml === '' ? 'Add Unit' : 'Edit Unit'}
                                          </button>)
                                        }

                                      </div>
                                    </div>}
                                    {hideNodeFieldsForMCQ && <div className="name-node">
                                      <label className="labels">Decimal Precision:</label>

                                      {
                                        isDisabledNotForUE ? (<input type="number"
                                          id="decimalPoints"
                                          name="decimalPoints"
                                          min="1" max="10"
                                          step="1"
                                          disabled
                                          style={{ width: "100%", border: "1px solid #ccc" }}
                                          value={decimalPoints || ""}

                                          className="labels" />) : (<input type="number"
                                            id="decimalPoints"
                                            name="decimalPoints"
                                            min="1" max="10"
                                            step="1"
                                            style={{ width: "100%", border: "1px solid #ccc" }}
                                            value={decimalPoints || ""}
                                            onChange={(evt) => {
                                              setdecimalPoints(evt.target.value);
                                            }}
                                            className="labels" />)
                                      }



                                    </div>}
                                    {hideNodeFieldsForMCQ && <div className="name-node">
                                      <label className="labels">Points:</label>

                                      {
                                        isDisabledNotForUE ? (<input type="number"
                                          id="pointsInput"
                                          name="pointsInput"
                                          min="1" max="100"
                                          step="1"
                                          style={{ width: "100%", border: "1px solid #ccc" }}
                                          value={points || ""}
                                          disabled
                                          className="labels" />) : (<input type="number"
                                            id="pointsInput"
                                            name="pointsInput"
                                            min="1" max="100"
                                            step="1"
                                            style={{ width: "100%", border: "1px solid #ccc" }}
                                            value={points || ""}
                                            onChange={(evt) => {
                                              setPoints(evt.target.value);
                                            }}
                                            className="labels" />)
                                      }



                                    </div>}
                                    {hideNodeFieldsForMCQ && IsCorrect && <div className="name-node">
                                      <label className="labels">Hint Per:</label>
                                      {
                                        isDisabledNotForUE ? (<input type="number"
                                          id="hintsperInput"
                                          name="hintsperInput"
                                          min="1" max="100"
                                          step="1"
                                          style={{ width: "100%", border: "1px solid #ccc" }}
                                          value={hints_perc || ""}
                                          disabled
                                          className="labels" />) : (<input type="number"
                                            id="hintsperInput"
                                            name="hintsperInput"
                                            min="1" max="100"
                                            step="1"
                                            style={{ width: "100%", border: "1px solid #ccc" }}
                                            value={hints_perc || ""}
                                            onChange={(evt) => {
                                              sethints_perc(evt.target.value);
                                            }}
                                            className="labels" />)
                                      }



                                    </div>}

                                    <hr className="horizontalRuler" />



                                    {hideNodeFieldsForMCQ && <label className="labels">Select node type:</label>}
                                    {hideNodeFieldsForMCQ && <div className={IsCorrect ? 'checkboxwrapper' : 'checkboxwrapper-disabled'}>

                                      {
                                        isDisabledNotForUE ? (<input
                                          type="radio"
                                          name="NodeOption"
                                          checked={nodeImportance === PRIMARY}
                                          value={true}
                                          className="radiowrapper"
                                          disabled
                                        />) : (<input
                                          type="radio"
                                          name="NodeOption"
                                          checked={nodeImportance === PRIMARY}
                                          value={true}
                                          className="radiowrapper"
                                          onChange={(evt) => handleChange(PRIMARY, evt.target.value)}
                                        />)
                                      }

                                      <label className="labels">Primary Step(Basic Step)</label>
                                    </div>}
                                    {hideNodeFieldsForMCQ && <div className={IsCorrect ? 'checkboxwrapper' : 'checkboxwrapper-disabled'}>

                                      {
                                        isDisabledNotForUE ? (<input
                                          name="NodeOption"
                                          type="radio"
                                          value={true}
                                          checked={nodeImportance === NON_PRIMARY}
                                          disabled
                                        />) : (<input
                                          name="NodeOption"
                                          type="radio"
                                          value={true}
                                          checked={nodeImportance === NON_PRIMARY}
                                          onChange={(evt) => handleChange(NON_PRIMARY, evt.target.value)}
                                        />)
                                      }

                                      <label className="labels">Intermediate Step (Non-Primary Step -for detailed explanation)</label>
                                    </div>}
                                    {hideNodeFieldsForMCQ && <div className={IsCorrect ? 'checkboxwrapper' : 'checkboxwrapper-disabled'}>
                                      {
                                        isDisabledNotForUE ? (<input
                                          name="NodeOption"
                                          type="radio"
                                          value={true}
                                          checked={nodeImportance === OPTIONAL}
                                          disabled
                                        />) : (<input
                                          name="NodeOption"
                                          type="radio"
                                          value={true}
                                          checked={nodeImportance === OPTIONAL}
                                          onChange={(evt) => handleChange(OPTIONAL, evt.target.value)}
                                        />)
                                      }

                                      <label className="labels">Optional Step (students' extra steps - recognized as correct)</label>
                                    </div>}
                                    {hideNodeFieldsForMCQ && (IsCorrect ? null : <hr className="horizontalRuler" />)}
                                    {hideNodeFieldsForMCQ && (IsCorrect ? null : <label className="labels">Error type:</label>)}
                                    {hideNodeFieldsForMCQ && (IsCorrect ? null :
                                      <div className={IsCorrect ? null : 'checkboxwrapper'}>

                                        {
                                          isDisabledNotForUE ? (<input
                                            type="radio"
                                            name="NodeErrorType"
                                            checked={nodeErrorType === BASIC_ERROR}
                                            value={true}
                                            className="radiowrapper"
                                            disabled
                                          />) : (<input
                                            type="radio"
                                            name="NodeErrorType"
                                            checked={nodeErrorType === BASIC_ERROR}
                                            value={true}
                                            className="radiowrapper"
                                            onChange={(evt) => handleErrorType(BASIC_ERROR, evt.target.value)}
                                          />)
                                        }

                                        <label className="labels">Basic Error</label>
                                      </div>)}

                                    {hideNodeFieldsForMCQ && (IsCorrect ? null :
                                      <div className={IsCorrect ? null : 'checkboxwrapper'}>
                                        {
                                          isDisabledNotForUE ? (<input
                                            name="NodeErrorType"
                                            type="radio"
                                            value={true}
                                            checked={nodeErrorType === AUTHOR_COMBINATION}
                                            disabled
                                          />) : (<input
                                            name="NodeErrorType"
                                            type="radio"
                                            value={true}
                                            checked={nodeErrorType === AUTHOR_COMBINATION}
                                            onChange={(evt) => handleErrorType(AUTHOR_COMBINATION, evt.target.value)}
                                          />)
                                        }

                                        <label className="labels">Combination Error (Author Entered)</label>
                                      </div>)
                                    }
                                    {hideNodeFieldsForMCQ && (IsCorrect ? null :
                                      <div className={IsCorrect ? null : 'checkboxwrapper'}>
                                        {
                                          isDisabledNotForUE ? (<input
                                            name="NodeErrorType"
                                            type="radio"
                                            value={true}
                                            checked={nodeErrorType === SYSTEM_COMBINATION}
                                            disabled
                                          />) : (<input
                                            name="NodeErrorType"
                                            type="radio"
                                            value={true}
                                            checked={nodeErrorType === SYSTEM_COMBINATION}
                                            onChange={(evt) => handleErrorType(SYSTEM_COMBINATION, evt.target.value)}
                                          />)
                                        }
                                        <label className="labels">Combination Error (System Generated)</label>
                                      </div>)
                                    }
                                    {hideNodeFieldsForMCQ && (IsCorrect ? null :
                                      <div className={IsCorrect ? null : 'checkboxwrapper'}>
                                        {
                                          isDisabledNotForUE ? (<input
                                            name="NodeErrorType"
                                            type="radio"
                                            value={true}
                                            //style={{bottom:"-3px"}}
                                            checked={nodeErrorType === PROPAGATION_ERROR}
                                            disabled
                                          />) : (<input
                                            name="NodeErrorType"
                                            type="radio"
                                            value={true}
                                            //style={{bottom:"-3px"}}
                                            checked={nodeErrorType === PROPAGATION_ERROR}
                                            onChange={(evt) => handleErrorType(PROPAGATION_ERROR, evt.target.value)}
                                          />)
                                        }

                                        <label className="labels">Propagation Error</label>
                                      </div>)
                                    }

                                    {hideNodeFieldsForMCQ && (IsCorrect ? <hr className="horizontalRuler" /> : null)}
                                    {hideNodeFieldsForMCQ && (IsCorrect ?
                                      <div className="checkboxwrapper" >
                                        <label className="labels" htmlFor="ishidden">

                                          {
                                            isDisabledNotForUE ? (<input
                                              id="ishidden"
                                              type="checkbox"
                                              checked={hidden}
                                              //style={{bottom:"-3px"}}
                                              disabled

                                            />) : (<input
                                              id="ishidden"
                                              type="checkbox"
                                              checked={hidden}
                                              //style={{bottom:"-3px"}}
                                              onChange={(event) => setHidden(event.target.checked)}

                                            />)
                                          }
                                          Hide Incorrect Nodes
                                        </label>
                                      </div> : null)}

                                    {hideNodeFieldsForMCQ && (IsCorrect ? <hr className="horizontalRuler" /> : null)}
                                    {hideNodeFieldsForMCQ && (IsCorrect ?
                                      <div className="name-node addedHintsContainer" >
                                        {hideNodeFieldsForMCQ && <label className="labels">Explanation *</label>}
                                        {hideNodeFieldsForMCQ &&
                                          showcommentsfields === true ?
                                          (
                                            <ChallengeComments
                                              commentvalue={explanation_comment}
                                              iscolorchange={explanation_comment_color_change}
                                              handlecomments={event => handlecomments(event, "explanation_comment", "Review Comment")}> </ChallengeComments>
                                          ) : null
                                        }
                                        <br />
                                        {hideNodeFieldsForMCQ && (
                                          isDisabledNotForUE ? (
                                            <CKEditor
                                              style={{ width: "100%" }}
                                              data={nodeExplanation}
                                              editor={ClassicEditor}
                                              onReady={(e) => {
                                                setExplanationEditor(e.editor)
                                              }}
                                              config={editorConfiguration}
                                              disabled
                                            />
                                          ) : (

                                            <CKEditor
                                              style={{ width: "100%" }}
                                              data={nodeExplanation}
                                              editor={ClassicEditor}
                                              onReady={(e) => {
                                                setExplanationEditor(e.editor)
                                              }}
                                              config={editorConfiguration}
                                              onChange={(e, editor) => { setNodeExplanation(editor.getData()) }}
                                            />
                                          )

                                        )
                                        }

                                      </div> : null)
                                    }
                                    {hideNodeFieldsForMCQ && <hr className="horizontalRuler" />}
                                    <div className="name-node" style={{ width: '400px' }} >
                                      <label className="labels" >{IsCorrect ? "Requisite knowledge" : "Error root cause"}:</label>

                                      {
                                        isDisabledNotForUE ? (<>
                                          <button className="linkButton"
                                            title={IsCorrect ? "Add a new Requisite Knowledge Unit for this Solution, and for this Step." : "Add a new Error Root Cause for this Solution, and for this Step."}
                                            disabled>{dataSizeLabel}</button> <br />
                                          <Select className="dropDownSelect" title={IsCorrect ? "Select Requisite Knowledge identified previously for this Solution" : "Select Error Root Cause identified previously for this Solution"}
                                            placeholder={IsCorrect ? "Select Requisite Knowledge identified previously for this Solution" : "Select Error Root Cause identified previously for this Solution"}
                                            isClearable="true"
                                            isMulti
                                            getOptionLabel={option => option.name}
                                            getOptionValue={option => option.subSectionId}
                                            disabled
                                            value={errorRootCauseValue}
                                            options={errorRootCauseOption}
                                          />
                                        </>) : (<>
                                          <button className="linkButton"
                                            title={IsCorrect ? "Add a new Requisite Knowledge Unit for this Solution, and for this Step." : "Add a new Error Root Cause for this Solution, and for this Step."}
                                            onClick={() => { handleShowMore() }}>{dataSizeLabel}</button> <br />
                                          <Select className="dropDownSelect" title={IsCorrect ? "Select Requisite Knowledge identified previously for this Solution" : "Select Error Root Cause identified previously for this Solution"}
                                            placeholder={IsCorrect ? "Select Requisite Knowledge identified previously for this Solution" : "Select Error Root Cause identified previously for this Solution"}
                                            isClearable="true"
                                            isMulti
                                            getOptionLabel={option => option.name}
                                            getOptionValue={option => option.subSectionId}
                                            onChange={handleChangeRequisiteKnowledge}
                                            value={errorRootCauseValue}
                                            options={errorRootCauseOption}
                                          />
                                        </>)
                                      }


                                    </div>

                                    <hr className="horizontalRuler" />
                                    <div className="name-node addedHintsContainer">
                                      <div style={{ display: "flex", justifyContent: "space-between" }}>
                                        <div><label className="labels">{IsCorrect ? "Hints" : "Remedials"}:</label></div>
                                        {
                                          (openHideMCQGrid) ? (((mcqDataObject.isCorrect || challengeType.toLowerCase() === "msq" || challengeType.toLowerCase() === "mcq")) ? (
                                            <div><Typography><span className="accordianTitle">
                                              {
                                                isDisabledNotForUE ? (<button
                                                  className="linkButton"
                                                  disabled
                                                >
                                                  {addresourcelable}
                                                </button>) : (<button
                                                  className="linkButton"
                                                  onClick={() => { addresourcelable === 'Close' ? hideAddRemedials() : showAddRemedials(false) }}
                                                >
                                                  {addresourcelable}
                                                </button>)

                                              }</span></Typography>
                                            </div>
                                          ) : (<div><Typography><span className="accordianTitle">
                                            {<button
                                              className="linkButtongray"

                                            >
                                              {addresourcelable}
                                            </button>}</span></Typography>
                                          </div>))
                                            : (
                                              ((element.data.errortype === BASIC_ERROR || element.data.isNodeCorrect)) ? (

                                                <div><Typography><span className="accordianTitle">
                                                  {
                                                    isDisabledNotForUE ? (<button
                                                      className="linkButton"
                                                      disabled
                                                    >
                                                      {addresourcelable}
                                                    </button>) : (<button
                                                      className="linkButton"
                                                      onClick={() => { addresourcelable === 'Close' ? hideAddRemedials() : showAddRemedials(false) }}
                                                    >
                                                      {addresourcelable}
                                                    </button>)

                                                  }</span></Typography>
                                                </div>
                                              ) : (<div><Typography><span className="accordianTitle">{<button
                                                className="linkButtongray"

                                              >
                                                {addresourcelable}
                                              </button>}</span></Typography>
                                              </div>)
                                            )
                                        }


                                      </div>

                                      <ul class="list-group list">{hintRenderer(nodeHints)}</ul>
                                    </div>
                                    {showNodeFieldsForMCQ && (
                                      (addnewAnswer ?
                                        <div>
                                          <hr className="horizontalRuler" />
                                          <div style={{ height: '30px' }}></div>
                                          <div>
                                            <button className="dragLabel leftmargin" onClick={addToList}> Add To List </button>
                                          </div>
                                        </div>
                                        :
                                        <div>
                                          <hr className="horizontalRuler" />
                                          <div style={{ height: '30px' }}></div>
                                          <div>
                                            <button className="dragLabel leftmargin" onClick={backToList}> Update To List </button>
                                          </div>
                                        </div>
                                      )
                                    )
                                    }
                                  </div>
                                </div>
                              </Typography>
                            </AccordionDetails>
                          </Accordion>
                        </>
                      ) : (
                        ""
                      )}


                    </div>
                  )}

                </aside>
              </div>
            )}
          </div>
        </ReactFlowProvider>
        <ToastContainer />

        {
          isloading && (
            <CircularProgress
              size={24}
              sx={{
                color: "#fdbd17",
                position: 'absolute',
                top: '50%',
                left: '50%',
                marginTop: '-12px',
                marginLeft: '-12px',
              }}
            />
          )
        }
        <Draggable
          cancel=".wrs_editor ,.wrs_canvasContainer, .wrs_focusElement, .wiris-resizer-small, .wiris-resizer-medium, .wiris-resizer-large, .wiris-button"
        >
          <div className="handwriting-content wirisBox" id="wirisModal">
            <div className='wiris-resize-container ml-auto'>
              <span className='wiris-resizer-small' onClick={() => setWirisSize('small')}></span>
              <span className='wiris-resizer-medium' onClick={() => setWirisSize('medium')}></span>
              <span className='wiris-resizer-large' onClick={() => setWirisSize('large')}></span >
            </div>
            <div id="wirisEditorContainer" className={'wiris-size-' + wirisSize}></div>
            <div className='d-flex justify-content-center mt-2'>
              <button className='mr-2 wiris-button' onClick={() => getMathmlFromWiris()} >Add Equation</button>
              <button className='wiris-button' onClick={() => closeWiris()}>Cancel</button>
            </div>
          </div>
        </Draggable>
      </div >
    </>
  );
};
export default DnDFlow;