import React from 'react'
import './VideoConference.scss'
import { connectToTwilio } from '../../common/API'
import ScreenShare from '@material-ui/icons/ScreenShare'
import StopScreenShare from '@material-ui/icons/StopScreenShare'
import Mic from '@material-ui/icons/Mic'
import MicOff from '@material-ui/icons/MicOff'
import Videocam from '@material-ui/icons/Videocam'
import VideocamOff from '@material-ui/icons/VideocamOff'
import VideoPopup from './VideoPopup'
import VideoLabelIcon from '@material-ui/icons/VideoLabel';
import Tooltip from '@material-ui/core/Tooltip';

const Video = require('twilio-video')

class VideoConference extends React.Component {

    connected = false
    room = null
    screenTrack = null
    reference = React.createRef()
    modalTitle = null;
    track = null

    state = {
        isAudioMute: false,
        isVideoMute: false,
        shareScreen: false,
        isCallConnected: false,
        name: '',
        userRole: '',
        userId: '',
        roomName: '',
        showModal: false,
        isSomeoneSharingScreen: false,
        screenSharingPerson: '',
    }

    disconnectUserFromRoom = () => {
        // Exit room on tab close
        if (this.room) {
            if (this.state.shareScreen) {
                this.room.localParticipant.unpublishTrack(this.screenTrack)
                this.screenTrack.stop()
                this.screenTrack = null
            }
            this.room.disconnect()
            this.offLocalParticipantVideo()
        }
    }

    offLocalParticipantVideo = () => {
        if (this.room) {
            this.room.localParticipant.videoTracks.forEach((videoTracks) => {
                videoTracks.track.stop();
                videoTracks.track.detach().forEach(element => element.remove());
                this.room.localParticipant.unpublishTrack(videoTracks.track);
            })
        }
    }

    setupBeforeUnloadListener = () => {
        window.addEventListener("beforeunload", (ev) => {
            ev.preventDefault()
            return this.disconnectUserFromRoom()
        })
    }

    componentDidMount() {
        this.setState({
            name: this.props.user,
            userId: this.props.userId,
            userRole: this.props.userRole,
            roomName: this.props.roomName,
        }, () => {
            this.connectButtonHandler(false)
        })

        this.setupBeforeUnloadListener()
    }

    componentWillUnmount() {
        this.disconnectUserFromRoom()
    }

    openModal = (modalTitle, track) => {
        this.track = track;
        this.modalTitle = modalTitle;
        this.setState({
            showModal: true,
            isSomeoneSharingScreen: true,
            screenSharingPerson: modalTitle,
        });
    }

    closeModal = () => {
        this.setState({ showModal: false });
    }

    connectButtonHandler = (event) => {
        if (event) {
            event.preventDefault()
        }
        if (!this.connected) {
            let params = new URLSearchParams()
            params.append('username', this.state.name)
            params.append('userId', this.state.userId)
            params.append('userRole', this.state.userRole)
            params.append('boardId', this.props.boardId)
            params.append('call_type', this.props.call_type)
            params.append('problem_source', this.props.problem_source)
            if (this.props.topicId !== undefined)
                params.append('topicId', this.props.topicId)
            if (this.props.questionId !== undefined)
                params.append('questionId', this.props.questionId)
            if (this.props.challengeId !== undefined)
                params.append('challengeId', this.props.challengeId)
            if (this.props.challengeMapId !== undefined)
                params.append('challengeMapId', this.props.challengeMapId)
            if (this.props.tutorid !== undefined && this.props.tutorid !== null) {
                params.append('tutorid', this.props.tutorid)
            }
            if (this.state.roomName) {
                params.append('roomName', this.state.roomName)
            } else {
                params.append('boardURL', window.location.href)
            }
            connectToTwilio(params)
                .then(res => {
                    console.log(res)
                    return res.data
                }).then(data => {
                    console.log(data)
                    // join video call
                    return Video.connect(data.token, {
                        audio: true,
                        video: { width: 640 },
                    })
                }).then(_room => {
                    this.room = _room
                    console.log(this.room)
                    console.log("before roomid......", this.room.sid)
                    localStorage.setItem("studentroomid", this.room.sid)
                    this.setState({ isCallConnected: true })

                    let video = document.getElementById('local').firstChild
                    const cameraTrack = [...this.room.localParticipant.videoTracks.values()][0].track
                    let trackElement = cameraTrack.attach()
                    video.appendChild(trackElement)

                    this.room.participants.forEach(this.participantConnected)
                    this.room.on('participantConnected', this.participantConnected)
                    this.room.on('participantDisconnected', this.participantDisconnected)
                    this.connected = true
                }).catch((err) => {
                    console.error(err.message)
                })
        }
        else {
            this.connected = false
            this.disconnectUserFromRoom()

            let container = this.reference.current
            while (container.lastChild.id !== 'local')
                container.removeChild(container.lastChild)
            this.setState({
                isAudioMute: false,
                isVideoMute: false,
                isCallConnected: false,
                shareScreen: false,
            })
        }
    }

    participantConnected = (participant) => {
        console.log(participant)

        let participantDiv = document.createElement('div')
        participantDiv.setAttribute('id', participant.sid)
        participantDiv.setAttribute('class', 'participant col-6')

        let tracksDiv = document.createElement('div')
        participantDiv.appendChild(tracksDiv)

        let labelDiv = document.createElement('p')
        labelDiv.innerHTML = participant.identity
        this.props.setPartnerName(participant.identity)
        participantDiv.appendChild(labelDiv)

        let container = this.reference.current
        if (!container) {
            console.error('container not found', container)
            return
        }
        container.appendChild(participantDiv)

        participant.tracks.forEach(publication => {
            if (publication.isSubscribed)
                this.trackSubscribed(tracksDiv, publication.track, participant.identity)
        })
        participant.on('trackSubscribed', track => this.trackSubscribed(tracksDiv, track, participant.identity))
        participant.on('trackUnsubscribed', track => this.trackUnsubscribed(track))
        participant.on('trackDisabled', track => {
            // hide or remove the media element related to this track
            if (track.kind === 'video') {
                tracksDiv.classList.add("mute")
            }
        })
        participant.on('trackEnabled', track => {
            // show the track again
            if (track.kind === 'video') {
                tracksDiv.classList.remove("mute")
            }
        })
    }

    participantDisconnected = (participant) => {
        this.props.partnerDisconnected()
        document.getElementById(participant.sid).remove()
    }

    trackSubscribed = (div, track, participantName) => {
        console.log('trackSubscribed', div, track);

        if (track.name === 'screen') {
            this.openModal(
                participantName + "'s screen",
                track)
        } else {
            let trackElement = track.attach()
            div.appendChild(trackElement)
        }
    }

    trackUnsubscribed = (track) => {
        console.log('trackUnsubscribed', track);
        if (track.name === 'screen') {
            track.detach()
            this.track = null
            this.setState({ isSomeoneSharingScreen: false, screenSharingPerson: '' })
            this.closeModal()
        } else {
            track.detach().forEach(element => {
                element.remove()
            })
        }
    }

    muteAudio = (event) => {
        event.preventDefault()
        if (this.room) {
            console.log(this.room.localParticipant)

            this.room.localParticipant.audioTracks.forEach((audioTrack) => {
                if (this.state.isAudioMute) {
                    audioTrack.track.enable()
                } else {
                    audioTrack.track.disable()
                }
                this.setState((prevState) => ({ isAudioMute: !prevState.isAudioMute }))
            })
        }
    }

    muteVideo = (event) => {
        event.preventDefault()
        if (this.room) {
            if (this.state.isVideoMute) {
                Video.createLocalVideoTrack().then(track => {
                    let video = document.getElementById('local').firstChild
                    video.appendChild(track.attach())
                    return this.room.localParticipant.publishTrack(track);
                });
            } else {
                this.offLocalParticipantVideo()
            }
            this.setState((prevState) => ({ isVideoMute: !prevState.isVideoMute }))
        }
    }

    shareScreenHandler = (event) => {
        if (event) {
            event.preventDefault()
        }
        if (this.room) {
            if (!this.screenTrack) {
                navigator.mediaDevices.getDisplayMedia().then(stream => {
                    this.screenTrack = new Video.LocalVideoTrack(stream.getTracks()[0], { name: 'screen' })
                    this.room.localParticipant.publishTrack(this.screenTrack)
                    this.screenTrack.mediaStreamTrack.onended = () => { this.shareScreenHandler() }
                    this.setState({ shareScreen: true })
                    console.log(this.screenTrack)
                }).catch((err) => {
                    console.error('Could not share the screen.' + err.message)
                })
            }
            else {
                this.room.localParticipant.unpublishTrack(this.screenTrack)
                this.screenTrack.stop()
                this.screenTrack = null
                this.setState({ shareScreen: false })
            }
        }
    }

    showScreenSharePopup = (event) => {
        event.preventDefault()
        this.openModal(this.state.screenSharingPerson, this.track)
    }

    render() {
        return (
            <div className='vc d-flex flex-column mt-4'>
                <div id="container" class="row" ref={this.reference}>
                    <div id="local" class="participant col-6">
                        <div className='localVideo'></div>
                        <p>{this.state.name} (You)</p>
                    </div>
                </div>

                <div className='controls mt-2'>
                    {this.state.name === 'Me' ? <button id="join_leave" onClick={this.connectButtonHandler}>{this.state.isCallConnected ? 'Leave Call' : 'Join Call'}</button> : null}
                    <div onClick={this.muteAudio}>
                        {this.state.isAudioMute ?
                            <Tooltip title='Unmute Audio'>
                                <MicOff />
                            </Tooltip>
                            :
                            <Tooltip title='Mute Audio'>
                                <Mic />
                            </Tooltip>}
                    </div>

                    <div onClick={this.muteVideo}>
                        {this.state.isVideoMute ?
                            <Tooltip title='Unmute Video'>
                                <VideocamOff />
                            </Tooltip>
                            :
                            <Tooltip title='Mute Video'>
                                <Videocam />
                            </Tooltip>}
                    </div>

                    <div onClick={this.shareScreenHandler}>
                        {this.state.shareScreen ?
                            <Tooltip title='Stop Sharing'>
                                <StopScreenShare />
                            </Tooltip>
                            :
                            <Tooltip title='Share Screen'>
                                <ScreenShare />
                            </Tooltip>}
                    </div>

                    {this.state.isSomeoneSharingScreen ?
                        <div onClick={this.showScreenSharePopup}>
                            <Tooltip title='Show Screen Sharing'>
                                <VideoLabelIcon />
                            </Tooltip>
                        </div> : null}
                </div>
                {this.props.winMinimize ? null :
                    <VideoPopup
                        show={this.state.showModal}
                        handleClose={this.closeModal}
                        title={this.modalTitle}
                        track={this.track} />
                }
            </div>
        )
    }
}

export default VideoConference