import React, { Component } from "react";
import { withNamespaces } from 'react-i18next';
import PropTypes from 'prop-types';
import ReactJoyride, { ACTIONS, EVENTS, LIFECYCLE, STATUS } from 'react-joyride';
import { withRouter } from 'react-router-dom';
import {
    setTourCookies,
    convertDurationToSeonds,
    getTourCookies,
    showFullApp,
    getCurrentUserId
} from "../../common/Functions";
import { demoUserId } from '../../common/Constants';
import TourContent from "./TourContent";
import "./Tour.scss";
import game_1 from "../../assets/images/img-onboard-interactive-prob.svg";
import graphImg from "../../assets/images/img-onboard-graph.svg";
import eraseWorkImg from "../../assets/images/img-onboard-erase-work.svg";
import welcomeImg from "../../assets/images/img-onboard-welcome.svg";
import aiPlatoReadImg from "../../assets/images/img-onboard-aiplato-read.svg";
import dashboardGraphVideo from "../../assets/videos/dashboard_graph1.mp4";
import penToolDeleteVideo from "../../assets/videos/Pen_tool_delete.mp4";
import penToolWritingVideo from "../../assets/videos/Pen_tool_writing1.mp4";
import {
    getUIConfigDetails
    , saveUIConfigDetails
} from '../../common/API';

class Tour extends Component {

    state = {
        run: false,
        urlpathname: "",
        steps: [],
        stepIndex: 0,
        showPracticeProblemsTour: false,
        showDashboardTour: false,
        showCurriculumContentTour: false
    };

    initialValues = {
        strength: -1040,
        distance: 150,
    };
    static propTypes = {
        joyride: PropTypes.shape({
            callback: PropTypes.func
        })
    };

    static defaultProps = {
        joyride: {}
    };

    componentDidMount() {
        //    this.setState({ run: true })
        //   let datavalue = getTourCookies(this.props.pagename)

        // console.log("dataval = " + datavalue)
        // if (datavalue === "true" || datavalue === 'true') {
        //     this.setState({ run: false })
        // }
        // else {
        //console.log("page - " + this.props.pagename)
        if (this.props.pagename === "dashboard") {
            this.checkAndSaveTourFlag("showDashboardTour")
        }
        else if (this.props.pagename === "answerboard") {
            this.checkAndSaveTourFlag("showPracticeProblemsTour")
        }
        else if (this.props.pagename === "curriculumcontent") {
            this.checkAndSaveTourFlag("showCurriculumContentTour")
        }
        else {
            this.setState({ run: true })
        }
        //   }
    }

    checkAndSaveTourFlag = async (fieldName) => {
        if (getCurrentUserId() !== demoUserId) {

            await getUIConfigDetails({
                params: {
                    'userId': getCurrentUserId()
                }
            }).then(res => {
                if (res.status === 200) {
                    if (JSON.stringify(res.data) === "[]") {
                        this.saveTourData("save", fieldName, res.data)
                    }
                    else {
                        if (fieldName === "showPracticeProblemsTour" && !res.data.showPracticeProblemsTour) {
                            this.saveTourData("update", fieldName, res.data)
                        }
                        else if (fieldName === "showDashboardTour" && !res.data.showDashboardTour) {
                            this.saveTourData("update", fieldName, res.data)
                        }
                        else if (fieldName === "showCurriculumContentTour" && !res.data.showCurriculumContentTour) {
                            this.saveTourData("update", fieldName, res.data)
                        }
                    }
                }
            })
        }
    }

    saveTourData = async (mode, fieldToSaveORUpdate, data) => {
        let reqData
        if (mode === "save") {
            reqData = {
                userId: getCurrentUserId(),
                isShowStreamlineMsg: false,
                showPracticeProblemsTour: fieldToSaveORUpdate === "showPracticeProblemsTour" ? true : false,
                showDashboardTour: fieldToSaveORUpdate === "showDashboardTour" ? true : false,
                showCurriculumContentTour: fieldToSaveORUpdate === "showCurriculumContentTour" ? true : false,
                isWhiteBoardVideoAutoPlayDone: false
            }
        }
        else {
            if (fieldToSaveORUpdate === "showPracticeProblemsTour") {
                reqData = {
                    userId: getCurrentUserId(),
                    isShowStreamlineMsg: data.isShowStreamlineMsg,
                    showPracticeProblemsTour: true,
                    showDashboardTour: data.showDashboardTour,
                    showCurriculumContentTour: data.showCurriculumContentTour,
                    isWhiteBoardVideoAutoPlayDone: data.isWhiteBoardVideoAutoPlayDone
                }
            }
            else if (fieldToSaveORUpdate === "showDashboardTour") {
                reqData = {
                    userId: getCurrentUserId(),
                    isShowStreamlineMsg: data.isShowStreamlineMsg,
                    showPracticeProblemsTour: data.showPracticeProblemsTour,
                    showDashboardTour: true,
                    showCurriculumContentTour: data.showCurriculumContentTour,
                    isWhiteBoardVideoAutoPlayDone: data.isWhiteBoardVideoAutoPlayDone
                }
            }
            else if (fieldToSaveORUpdate === "showCurriculumContentTour") {
                reqData = {
                    userId: getCurrentUserId(),
                    isShowStreamlineMsg: data.isShowStreamlineMsg,
                    showPracticeProblemsTour: data.showPracticeProblemsTour,
                    showDashboardTour: data.showDashboardTour,
                    showCurriculumContentTour: true,
                    isWhiteBoardVideoAutoPlayDone: data.isWhiteBoardVideoAutoPlayDone
                }
            }
        }

        let stateVal = await saveUIConfigDetails(reqData).then(res => {
            if (res.status === 200) {
                // flag stored in DB successfully !
                console.log("Config values " + mode + " successfully")
                if (fieldToSaveORUpdate === "showPracticeProblemsTour") {
                    this.setState({ showPracticeProblemsTour: true })
                }
                else if (fieldToSaveORUpdate === "showDashboardTour") {
                    this.setState({ showDashboardTour: true })
                }
                else if (fieldToSaveORUpdate === "showCurriculumContentTour") {
                    this.setState({ showCurriculumContentTour: true })
                }
            }
        })

        if (this.state.showDashboardTour || this.state.showPracticeProblemsTour || this.state.showCurriculumContentTour) {
            this.setState({ run: true })
        }
    }

    static getDerivedStateFromProps(props, state) {
        if (showFullApp()) {
            let data = window.location.pathname
            if (data !== state.urlpathname) {
                if (data === "/") {
                    return {
                        steps: [
                            {
                                content: <TourContent
                                    tourTitle="This should take 2min!"
                                    contentImg={null}
                                    contentVideo={null}
                                    contentHeader="Welcome to Dashboard!"
                                    contentDetails="Get ready to embark on a personalized 1-1 tutoring journey that's tailored just for you."
                                />,
                                placement: "center",
                                locale: { skip: "SKIP" },
                                target: ".dashboard_tour"
                            },
                            {
                                content: <TourContent
                                    tourTitle="This should take 2min!"
                                    contentImg={game_1}
                                    contentVideo={null}
                                    contentHeader="Interactive Problems"
                                    contentDetails="Access various problems sets carefully to help you practice the
                                 topics that you need to get the perfect scores! <br /><br />
                                 Click <a href='/curriculum-problems'>here</a> to try out one yourself."
                                />,
                                placement: "bottom",
                                target: ".interactiveproblem_tour"
                            },
                            {
                                content: <TourContent
                                    tourTitle="This should take 1min!"
                                    contentImg={graphImg}
                                    contentVideo={dashboardGraphVideo}
                                    contentHeader="Proficiency Graph"
                                    contentDetails="Double-tap any circular node to find tailored chapters! Remember, the blue and red rings fill up as you finish more lessons."
                                />,
                                placementBeacon: "bottom",
                                placement: "top-bottom",
                                target: ".graph_tour",
                                // title: "Graph",
                                offset: "-3"
                            },
                            {
                                content: <TourContent
                                    tourTitle="This should take 2min!"
                                    contentImg={null}
                                    contentVideo={null}
                                    contentHeader="Progress Update"
                                    contentDetails="You can see your total percentage of completed and mastered curriculum and 
                                compare your levels with that of your peers ! "
                                />,
                                placement: "bottom-right",
                                locale: { skip: "SKIP" },
                                target: ".product_tour"
                            }
                        ]
                    }
                }
                else if (data.includes("/answerboard/")) {
                    return {
                        steps: [
                            {
                                content: <TourContent
                                    tourTitle="This should take 2min!"
                                    contentImg={null}
                                    contentVideo={null}
                                    contentHeader="Welcome to Interactive Problems!"
                                    contentDetails="Learn from step-by-step Feedback, in real-time, while solving problems 24/7. Build your Mastery."
                                />,
                                placement: "center",
                                locale: { skip: "SKIP" },
                                target: ".answerboard_tour"
                            },
                            {
                                content: <TourContent
                                    tourTitle="This should take 2min!"
                                    contentImg={welcomeImg}
                                    contentVideo={penToolWritingVideo}
                                    contentHeader="Welcome To The Workpad"
                                    contentDetails="This is your space to write your answers, sketch diagrams and 
                                write down as you solve the given problem."
                                />,
                                placement: "right",
                                target: ".canvas_tour"
                            },
                            {
                                content: <TourContent
                                    tourTitle="Please note"
                                    contentImg={null}
                                    contentVideo={null}
                                    contentHeader="Check My Work"
                                    contentDetails="This is where aiPlato guides you work step-by-step and gives your the guided feedback you need to ace your concepts. "
                                />,
                                placement: "right",
                                target: ".checkMyWork_tour"
                            },
                            {
                                content: <TourContent
                                    tourTitle="This should take 2min!"
                                    contentImg={eraseWorkImg}
                                    contentVideo={penToolDeleteVideo}
                                    contentHeader="Erase Work"
                                    contentDetails="Feel free to redo or undo your work or simple scratch it out to delete it from your canvas."
                                />,
                                placement: "top",
                                target: ".eraseBtn_tour"
                            },

                            // {
                            //     content: <TourContent
                            //         tourTitle="Please note"
                            //         contentImg={aiPlatoReadImg}
                            //         contentHeader="See How aiPlato Reads It"
                            //         contentDetails="Check your handwritten notes as they get interpreted by aiPlato to ensure your work stays on the right track."
                            //     />,
                            //     placement: "top",
                            //     target: ".equation_tour"
                            // },
                        ]
                    }
                }
                else if (data.includes("/curriculum-content/")) {
                    return {
                        steps: [
                            {
                                content: <TourContent
                                    tourTitle="This should take 2min!"
                                    contentImg={null}
                                    contentVideo={null}
                                    contentHeader="Welcome to AI-Interactive Content!"
                                    contentDetails="Never struggle with confusions in a lecture. Experience personalized, Interactive Learning."
                                />,
                                placement: "center",
                                locale: { skip: "SKIP" },
                                target: ".curriculum_tour"
                            },
                            {
                                content: <TourContent
                                    tourTitle="Please note"
                                    contentImg={null}
                                    contentVideo={null}
                                    contentHeader="Raise Hand"
                                    contentDetails="Click the raise hand button any time while streaming your video to clarify doubts or get answers to any of your queries related to the ongoing subject."
                                />,
                                placement: "left",
                                locale: { skip: "SKIP" },
                                target: ".raisehand_tour"
                            },
                            {
                                content: <TourContent
                                    tourTitle="Please note"
                                    contentImg={null}
                                    contentVideo={null}
                                    contentHeader="Upcoming Interactions"
                                    contentDetails="Pay note to your video as you have progress checkpoint that tests 
                                your grasp on the concepts so far!"
                                />,
                                placement: "top",
                                target: ".upcoming_Interactions_tour"
                            },
                            {
                                content: <TourContent
                                    tourTitle="Please note"
                                    contentImg={null}
                                    contentVideo={null}
                                    contentHeader="Problem Sets"
                                    contentDetails="Find questions related to this lecture over here and practice them 
                                to feel more confident about the subject!"
                                />,
                                placement: "top",
                                target: ".problemSets_tour"
                            },
                        ]
                    }
                }
                return {
                    urlpathname: data
                }
            } else {
                return {}
            }
        }
    }

    handleJoyrideCallback = (data) => {
        const { action, index, status, type } = data;
        if ([EVENTS.STEP_AFTER, EVENTS.TARGET_NOT_FOUND].includes(type)) {
            // Update state to advance the tour
            this.setState({ stepIndex: index + (action === ACTIONS.PREV ? -1 : 1) });
        }
        else if ([STATUS.FINISHED, STATUS.SKIPPED].includes(status)) {
            // Need to set our running state to false, so we can restart if we click start again.
            this.setState({ run: false });
            setTourCookies(convertDurationToSeonds("09:35:41.947483"), this.props.pagename, true)
        }
        console.groupCollapsed(type);
        console.log(data); //eslint-disable-line no-console
        console.groupEnd();
    };
    render() {

        return (
            <>
                <ReactJoyride
                    continuous
                    scrollToFirstStep
                    showProgress
                    showSkipButton
                    run={this.state.run}
                    steps={this.state.steps}
                    styles={{
                        options: {
                            zIndex: 10000,
                            primaryColor: '#4252AF',
                        }
                    }}
                    callback={this.handleJoyrideCallback}
                />
            </>
        );
    }

};


export default withRouter(Tour);