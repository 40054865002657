import React from "react";
import { Row, Col } from "react-bootstrap";
import "./HtmlContent.scss";
import "./HtmlContentLearningContent.scss";
import "./TextbookEquationsStyles.css";
import Modal from "react-bootstrap/Modal";
import ModalAI from "../../../Common/ModalComponent/ModalComponentAI";
import {
  saveinteractivetextbookquestions,
  getAQQuestions,
  imgURL,
  updateinteractivetextbookcontent,
  fetchKnowledgeBaseQuestions,
  apiURL,
  saveUserQuestion,
  setKnowledgeBaseQuestion,
  fetchTranscript,
  initiateTranscription,
  createRoomParticipantMapping,
  get_textbooksummativequestions,
} from "../../../../common/API";
import {
  getDisplayTextHolder,
  getCurrentUserId,
} from "../../../../common/Functions";
import Aux from "../../../../hoc/Wrapper";
import Spinner from "react-bootstrap/Spinner";
import ModalCom from "../../../Common/ModalComponent/ModalComponent";
import Button from "../../../Common/Button/Button";
import { toast } from "react-toastify";
import Ratings from "../../../Common/Ratings/Ratings";
import RatingsHover from "../../../Common/Ratings/RatingsHover";
import arrowImage from "../../../../assets/images/icon_arrow.svg";
import brainIcon from "../../../../assets/icons/brain_icon.ico";
import Radiobutton from "../../../Common/Radiobutton/Radiobutton";
import ChatBotComponent from "../../../Common/Chatbot/ChatBot";
import SessionSynopsisWithData from "../../../Answerboard/SessionSynopsis/SessionSynopsisWithData";
import SummativeQuestion from "../VideoContent/CheckpointContent/SummativeQuestion/SummativeQuestion";
import Tabs from "react-bootstrap/Tabs";
import Tab from "react-bootstrap/Tab";
import PanToolIcon from "@mui/icons-material/PanTool";

export default class HtmlContentLearningContent extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      tabValue: "anticipated",
      modalopen: false,
      selectiondata: undefined,
      rangedata: undefined,
      question: "",
      answer: "",
      resource_image: "",
      resource: "",
      display_name_anticipated_error: "",
      openstudentpopup: false,
      lastpdataoffsetpoistion: 0,
      questionscontent: [],
      showModal: false,
      showAnswerModal: false,
      showAIModal: false,
      isWaitTimeRunning: false,
      targetWaitTime: "",

      toggleVideoConfShowModal: false,
      showModalWriteReview: false,
      questionId: null,
      isSessionSynopsisLoading: false,
      transcripts: {},
      synopsisData: {},
      showSessionSynopsis: false,
      asktutorbuttonvisible: true,
      questionvalueforasktutor: "",
      askAI: false,
      question_type: "Anticipated",
      summativeQuestions: [],
      currentSummativeQuestion: null,
      currentSummativeQuestionIndex: null,
      showSummativeQuestion: false,
      summativequestiondivposition: 0,
    };
  }

  textareaRef = React.createRef();
  studentQuestion = "";
  knowledgeBaseContent = null;
  isRaiseHandPopup = false;
  loadTranscriptTimer = null;

  componentDidMount() {
    var myElement = document.querySelectorAll(".questionclassespcmcq");
    console.log(myElement);
    let data = [];
    for (var i = 0; i < myElement.length; i++) {
      let strsplit = myElement[i].id.split("##");
      data.push(strsplit[1]);
    }
    if (data.length > 0) {
      const strValue = data.join(",");
      console.log(strValue);

      const reqData = {
        params: {
          questionids: strValue,
        },
      };
      get_textbooksummativequestions(reqData).then((res) => {
        if (res.data.summative_questions !== undefined) {
          let summativeQuestions = res.data.summative_questions.map((item) => {
            return {
              ...item,
              answered: false,
              skipped: false,
              type: "SQ",
            };
          });
          this.setState({ summativeQuestions: summativeQuestions });
        }
      });
    }
  }

  handlecloseopenstudentpopup = () => {
    this.setState({ openstudentpopup: false });
  };
  clickHandler = (e) => {
    const el = e.target.closest(".highlight");
    if (el && e.currentTarget.contains(el)) {
      const range = e.target.getAttribute("name");
      this.props.showHighlight(e, range.split("##"));
    }

    debugger;

    if (e.target.id !== undefined && e.target.id !== null) {
      if (e.target.id.includes("textbook_mcq_question_ids##")) {
        let splitstr = e.target.id.split("##");
        let questionid = splitstr[1];
        this.setState(
          { summativequestiondivposition: parseInt(e.target.offsetTop) - 80 },
          () => {
            if (this.state.summativeQuestions.length > 0) {
              let selectedquestion = this.state.summativeQuestions.filter(
                (obj) => obj.questionId === parseInt(questionid)
              );
              if (
                selectedquestion !== undefined &&
                selectedquestion !== null &&
                selectedquestion.length > 0
              ) {
                this.setState({
                  currentSummativeQuestion: selectedquestion[0],
                  showSummativeQuestion: true,
                });
              }
            }
          }
        );
      }
    }
  };

  getHTMLContent = () => {
    console.log("here......");

    let { highlightedObjectList, hintObjectList } = this.props;
    let innerHTML = this.props.content;

    // Add hints to the HTML content
    if (hintObjectList) {
      hintObjectList.forEach((hintObj) => {
        let start = hintObj.selectionStart;
        let end = hintObj.selectionEnd;
        let focusNodeText = hintObj.focusNodeText;

        let index = innerHTML.indexOf(hintObj.elementClass);

        if (index >= 0) {
          let subHTML = innerHTML.substring(index, innerHTML.length);

          let mainContentStartIndex = subHTML.indexOf(focusNodeText);
          let mainContentEndIndex =
            mainContentStartIndex + focusNodeText.length;
          let mainContent = subHTML.substring(
            mainContentStartIndex,
            mainContentEndIndex
          );
          let highlightedText = mainContent.substring(start, end);
          if (highlightedText === hintObj.hintText) {
            let iconClass = hintObj.hintText === "" ? "has-note" : "";
            let modifiedMainContent =
              mainContent.substring(0, start) +
              `<span class='hint ${iconClass}' name='${start}##${end}'>` +
              highlightedText +
              "</span>" +
              mainContent.substring(end, subHTML.length);
            let modifiedSubHTML =
              subHTML.substring(0, mainContentStartIndex) +
              modifiedMainContent +
              subHTML.substring(mainContentEndIndex, subHTML.length);

            innerHTML = innerHTML.substring(0, index) + modifiedSubHTML;
          }
        }
      });
    }
    // Add highlights to the HTML content
    if (highlightedObjectList.length) {
      highlightedObjectList.forEach((highlightObj) => {
        let start = highlightObj.selectionStart;
        let end = highlightObj.selectionEnd;
        let { focusNodeText, highlighterColor } = highlightObj;
        let index = innerHTML.indexOf(highlightObj.elementClass);
        if (index >= 0) {
          let subHTML = innerHTML.substring(index, innerHTML.length);
          let mainContentStartIndex = subHTML.indexOf(focusNodeText);
          let mainContentEndIndex =
            mainContentStartIndex + focusNodeText.length;
          let mainContent = subHTML.substring(
            mainContentStartIndex,
            mainContentEndIndex
          );
          let highlightedText = mainContent.substring(start, end);
          let noteClass = highlightObj.note ? "has-note" : "";
          if (highlightedText === highlightObj.highlightedText) {
            let modifiedMainContent =
              mainContent.substring(0, start) +
              `<span class='highlight highlight-${highlighterColor} ${noteClass}' name='${start}##${end}'>` +
              highlightedText +
              "</span>" +
              mainContent.substring(end, subHTML.length);
            let modifiedSubHTML =
              subHTML.substring(0, mainContentStartIndex) +
              modifiedMainContent +
              subHTML.substring(mainContentEndIndex, subHTML.length);

            innerHTML = innerHTML.substring(0, index) + modifiedSubHTML;
          }
        }
      });
    }

    return innerHTML;
  };

  componentDidUpdate() {
    // Set listeners on hint section
    try {
      let hint = window.$(".hint");
      hint.on("mouseover", this.mouseOver);

      let hintDetail = window.$(".textbook-hint");
      hintDetail.on("mouseleave", () => {
        if (this.props.isHintVisible && this.shouldHideHint) {
          this.props.hideHint();
          this.shouldHideHint = false;
        }
      });
    } catch (e) {
      console.log(e.message);
    }
  }

  mouseOver = (e) => {
    if (!this.props.isHintVisible) {
      const hintText = e.target.innerHTML;
      const range = e.target.getAttribute("name");
      this.props.showHint(e, range.split("##"), hintText);
      setTimeout(() => {
        this.shouldHideHint = true;
      }, 100);
    }
  };

  setCursor = (pos) => {
    if (document.getElementById("editablediv") !== null) {
      var tag = document.getElementById("editablediv");

      // Creates range object
      var setpos = document.createRange();

      // Creates object for selection
      var set = window.getSelection();

      // Set start position of range
      setpos.setStart(tag.childNodes[0], 45);

      // Collapse range within its boundary points
      // Returns boolean
      setpos.collapse(true);

      // Remove all ranges set
      set.removeAllRanges();

      // Add range with respect to range object.
      set.addRange(setpos);

      // Set cursor on focus
      tag.focus();
    }
  };

  handlemodalopen = () => {
    // let text = "xyz"
    var sel, range;
    // debugger;
    if (window.getSelection) {
      sel = window.getSelection();
      if (sel.getRangeAt && sel.rangeCount) {
        range = sel.getRangeAt(0);
        // range.deleteContents();
        // range.insertNode(document.createTextNode(text));
      }
    }
    // else if (document.selection && document.selection.createRange) {
    //     document.selection.createRange().text = text;
    // }

    this.setState({ modalopen: true, rangedata: range });
  };

  handleClosemodal = () => {
    this.setState({ modalopen: false });
  };

  handleclosecheckpoint = () => {
    this.setState({ showSummativeQuestion: false });
  };

  // componentWillUnmount() {
  //     window.removeEventListener('scroll', this.handleScroll, true);
  // }

  // handleScroll = (event) => {

  // }

  inViewport(element) {
    // Get the elements position relative to the viewport
    var bb = element.getBoundingClientRect();
    // Check if the element is outside the viewport
    // Then invert the returned value because you want to know the opposite

    return !(bb.top > window.innerHeight || bb.bottom < 0);
  }

  handleclickraisehand = async (e, tabvalue) => {
    this.knowledgeBaseContent = null;
    var myElement = document.querySelectorAll(".questionclasses");
    console.log(myElement);
    let data = [];
    for (var i = 0; i < myElement.length; i++) {
      if (this.inViewport(myElement[i])) {
        let strsplit = myElement[i].id.split("##");
        data.push(strsplit[1]);
      }
    }
    if (data.length > 0) {
      const strValue = data.join(",");
      console.log(strValue);

      const reqData = {
        params: {
          questionids: strValue,
        },
      };
      getAQQuestions(reqData).then((res) => {
        if (res.data.data !== undefined) {
          this.setState({ questionscontent: res.data.data }, () => {
            this.openraisehandpopup();
          });
        }
      });
    } else {
      this.openraisehandpopup();
    }
  };
  openraisehandpopup = () => {
    this.isRaiseHandPopup = true;
    let resources = [];
    let imageResources = [];
    for (let item of this.state.questionscontent) {
      if (item.resource_link && item.anticipated_question) {
        if (item.anticipated_question) {
          resources.push(
            <p
              className="anticipatedQuestion font-16"
              onClick={() => {
                let answerModalBody = (
                  <div className="answer-content">
                    <p className="your-question">
                      Question: <span>{item.anticipated_question}</span>
                    </p>
                    <p className="answer">
                      Answer: <span>{item.text_answer}</span>
                    </p>
                    {item.resource_summary !== "" &&
                      item.resource_summary !== null &&
                      item.resource_summary !== undefined ? (
                      <>
                        See more on{" "}
                        <a
                          href={item.resource_link}
                          target="_blank"
                          rel="noopener noreferrer"
                          className="mt-2"
                        >
                          <p>{getDisplayTextHolder(item.resource_summary)}</p>
                        </a>
                      </>
                    ) : null}
                  </div>
                );
                this.answerbodytitle = "Question Summary";
                this.answerModalBody = answerModalBody;
                this.showAnswerButton = false;
                this.openAnswerModal();
              }}
            >
              {item.anticipated_question}
            </p>
          );
        }
      } else {
        if (item.resource_link) {
          if (
            item.resource_summary !== "" &&
            item.resource_summary !== null &&
            item.resource_summary !== undefined
          ) {
            resources.push(
              <a
                href={item.resource_link}
                target="_blank"
                rel="noopener noreferrer"
                className="mt-2"
              >
                <p>{getDisplayTextHolder(item.resource_summary)}</p>
              </a>
            );
          } else {
            resources.push(
              <a
                href={item.resource}
                target="_blank"
                rel="noopener noreferrer"
                className="mt-2"
              >
                <p>{item.resource}</p>
              </a>
            );
          }
        }

        if (item.anticipated_question) {
          resources.push(
            <p
              className="anticipatedQuestion"
              onClick={() => {
                let answerModalBody = (
                  <div className="answer-content">
                    <p className="your-question">
                      Question: <span>{item.anticipated_question}</span>
                    </p>
                    <p className="answer">
                      Answer: <span>{item.text_answer}</span>
                    </p>
                  </div>
                );
                this.answerbodytitle = "Question Summary";
                this.answerModalBody = answerModalBody;
                this.showAnswerButton = false;
                this.openAnswerModal();
              }}
            >
              {item.anticipated_question}
            </p>
          );
        }
      }
      if (item.resource_image) {
        let imageurl = imgURL + item.resource_image;
        imageResources.push(
          <img
            className="img-fluid resource-img"
            src={imageurl}
            alt="resource"
          />
        );
      }
      // resources.push({
      //     link: item.resource_link,
      //     text: item.resource_summary
      // });
    }

    this.bodytitle = "Anticipated Questions";
    this.modalBody = (
      <>
        <div className="raise-hand-content">
          {resources.length > 0 ? (
            <Aux>
              <h5>Would you like a refresher or clarification on:</h5>
            </Aux>
          ) : null}
          <div className={`d-flex ${resources.length > 0 ? "mb-3" : ""}`}>
            <div>{resources.map((resource) => resource)}</div>
            {imageResources.length > 0 ? (
              <div>{imageResources.map((resource) => resource)}</div>
            ) : null}
          </div>

          <div className="ask-tutor font-16">
            <p>
              If these resources don’t answer your question, you can search our
              Knowledge Base or connect to a tutor. Please enter your question,
              please be as specific as possible.
            </p>
            <textarea
              ref={this.textareaRef}
              rows={3}
              defaultValue={this.studentQuestion}
              // onChange={(ev) => this.studentQuestionChangeHandler(ev)}
              placeholder="Enter your question here"
            />
            <Button
              className="mt-1 mb-3 mr-3 yellow-btn search-knowledgebase-btn"
              clicked={this.searchKnowledgeBase}
            >
              Ask AI
            </Button>
            {this.state.asktutorbuttonvisible ? (
              <Button tooltip="Start a live video call with a human tutor. Share your board to collaborate." className="mt-1 mb-3 yellow-btn" clicked={this.askTutor}>
                Ask tutor
              </Button>
            ) : null}

            {this.state.askAI ? (
              <div className="progress-bar-container">
                <Spinner animation="border" role="status" />
              </div>
            ) : null}
          </div>

          {this.knowledgeBaseContent != null ? this.knowledgeBaseContent : null}
        </div>
      </>
    );

    this.state.askAI = false;
    this.setState({ askAI: false });
    this.showButton = false;
    this.openModal();
  };
  openAnswerModal = () => {
    this.customAnswerButton = (
      <div>
        <Row>
          <Col>
            <Button
              className="modal-footer-button btn mx-auto"
              clicked={() => {
                this.closeAnswerModal();
                this.closeModal();
              }}
            >
              Continue Reading
            </Button>
          </Col>
          <Col>
            <Button
              className="modal-footer-button btn mx-auto"
              clicked={this.closeAnswerModal}
            >
              Another Question
            </Button>
          </Col>
          <Col>
            <Button className="modal-footer-button btn mx-auto">
              Ask a Human Tutor
            </Button>
          </Col>
        </Row>
      </div>
    );

    this.setState({ showAnswerModal: true, showModal: false });
  };

  closeAnswerModal = () => {
    this.openraisehandpopup();
    this.state.askAI = false;
    this.setState({ showAnswerModal: false, askAI: false });
  };
  openModal = () => {
    this.setState({ showModal: true });
  };
  closeModal = () => {
    this.setState({
      showModal: false,
      asktutorbuttonvisible: true,
      askAI: false,
    });
  };
  closeAnswerModalAI = () => {
    this.openraisehandpopup();
    this.setState({ showAIModal: false, showModal: true });
  };
  openModalWriteReview = (event) => {
    event.preventDefault();
    this.setState({
      showModalWriteReview: true,
      showAnswerModal: false,
    });

    this.modalTitleReview = "Write a Review";
    this.customButtonReview = (
      <div className="mx-auto">
        <Button
          className="mr-2 yellow-btn"
          clicked={this.closeModalWriteReview}
        >
          Submit
        </Button>
        <Button className="grey-btn" clicked={this.closeModalWriteReview}>
          Cancel
        </Button>
      </div>
    );
    this.modalBodyReview = (
      <div className="review-modal">
        <div className="write-review">
          <h2>Overall Rating</h2>
          <div className="rating">
            <Ratings stars={3} className="ratings" />
          </div>
        </div>
        <div className="write-review">
          <h2>Clear Explanation</h2>
          <div className="rating">
            <Ratings stars={2} className="ratings" />
          </div>
        </div>
        <div className="write-review">
          <h2>Engaging Presentation</h2>
          <div className="rating">
            <Ratings stars={1} className="ratings" />
          </div>
        </div>
        <div className="write-review">
          <h2>Well-paced, Fast or Slow</h2>
          <div className="slide-container">
            <input type="range" min="1" max="100" value={3} id="myRange" />
            <label className="slow">Slow</label>
            <label className="fast">Fast</label>
          </div>
        </div>
        <div className="write-review well-paced-checkbox">
          <div className="checkbox">
            <fieldset>
              <div className="abstractReview">
                <Radiobutton
                  name="abstractreview"
                  value="Needs Improvement"
                  text="Needs Improvement"
                />
                <Radiobutton name="abstractreview" value="Good" text="Good" />
                <Radiobutton
                  name="abstractreview"
                  value="Very Good"
                  text="Very Good"
                />
                <Radiobutton
                  name="abstractreview"
                  value="Exceptional"
                  text="Exceptional"
                />
                <Radiobutton
                  name="abstractreview"
                  value="Irresistible"
                  text="Irrestible"
                />
              </div>
            </fieldset>
          </div>
        </div>
        <textarea
          rows="3"
          class="review-text"
          placeholder="Write a review"
        ></textarea>
      </div>
    );
  };

  closeModalWriteReview = () => {
    this.setState({ showModalWriteReview: false, showAnswerModal: true });
  };

  openAnswerModalAI = () => {
    this.customAnswerButton = (
      <div>
        <Row>
          <Col>
            <Button
              className="modal-footer-button btn mx-auto"
              clicked={() => {
                this.closeAnswerModalAI();
                this.closeAnswerModal();
                this.closeModal();
              }}
            >
              Continue Reading
            </Button>
          </Col>
          <Col>
            <Button
              className="modal-footer-button btn mx-auto"
              clicked={this.closeAnswerModalAI}
            >
              Another Question
            </Button>
          </Col>
          <Col>
            <Button
              className="modal-footer-button btn mx-auto"
              clicked={this.askTutor}
            >
              Ask a Human Tutor
            </Button>
          </Col>
        </Row>
      </div>
    );

    this.setState({ showAIModal: true, showModal: false });
  };

  // Temporary solution for chatGPT needs to be code cleaning
  askToChatGPT = (aiplatotrained) => {
    let quesValue = this.textareaRef.current.value.trim();
    this.setState({ questionvalueforasktutor: quesValue });
    let modalBOdy = (
      <div className="progress-bar-container">
        <Spinner animation="border" role="status" />
      </div>
    );
    this.answerModalBody = modalBOdy;
    this.showAnswerButton = false;
    this.openAnswerModalAI();
    let answerModalBody = (
      <div className="chatbotComponentDiv">
        <ChatBotComponent
          handleclose={this.closeAnswerModalAI}
          isInline={true}
          question={quesValue}
          aiPlatoTrained={false}
          Content_type={this.props.Content_type}
          SubSection_id={this.props.SubSection_id}
        ></ChatBotComponent>
      </div>
    );

    this.answerModalBody = answerModalBody;
    this.showAnswerButton = false;
    this.openAnswerModalAI();
  };
  studentQuestionChangeHandler = (ev) => {
    this.studentQuestion = ev.target.value;
  };

  skipAheadClickHandler = () => {
    if (this.props.hasQuestion) {
      return;
    }
    this.modalBody = this.skipAheadOptionsJSX;

    this.showButton = true;

    this.openModal();
  };

  askTutor = async () => {
    let quesValue = "";
    if (this.textareaRef.current === null) {
      quesValue = this.state.questionvalueforasktutor;
    } else {
      quesValue = this.textareaRef.current.value.trim();
    }
    if (quesValue.length <= 0) {
      toast("Please enter a question.", {
        type: toast.TYPE.ERROR,
        position: toast.POSITION.BOTTOM_RIGHT,
      });
    } else {
      let quetionid = await this.saveQuestionToDatabase(quesValue);

      setKnowledgeBaseQuestion({ question: quesValue })
        .then((res) => {
          console.log(res.data);
        })
        .catch((err) => {
          console.error(err.message);
        });

      this.setState({
        showModal: false,
        asktutorbuttonvisible: true,
        showAIModal: false,
        showAnswerModal: false,
      });
      if (this.isRaiseHandPopup) {
        this.isRaiseHandPopup = false;
        this.studentQuestion = "";
      }
      //const url = '/answerboard/question?ques=' + quesValue;

      this.props.toggleVC(quetionid);

      //window.open(url, '_blank');
      //console.log(window.location.pathname + window.location.search + window.location.hash);
      // let url = window.location.pathname + window.location.search + window.location.hash
      // this.props.history.push({ pathname: `${url}/question?ques=${quetionid}` });
    }
  };
  closeSessionSynopsis = () => {
    this.setState({ showSessionSynopsis: false });
  };
  endConferenceHandler = () => {
    const callFetchTranscriptApi = () => {
      const apiHandler = (participantId, transcriptIds) => {
        console.log("1", participantId);
        fetchTranscript({
          transcriptIds: transcriptIds,
        })
          .then((res) => {
            let transcripts = { ...this.state.transcripts };

            for (const transcriptId in res.data.response) {
              if (res.data.response.hasOwnProperty(transcriptId)) {
                transcripts[participantId] = res.data.response;
              }
            }
            console.log("transcripts.......", transcripts);

            this.setState({ transcripts });
          })
          .catch((err) => {
            console.error(err.message);
          });
      };

      let apiCalled = false;

      for (const participantId in this.state.transcripts) {
        console.log("2", participantId);
        if (this.state.transcripts.hasOwnProperty(participantId)) {
          const participant = this.state.transcripts[participantId];
          let transcriptIds = [];

          for (const transcriptId in participant) {
            if (participant.hasOwnProperty(transcriptId)) {
              const transcriptObj = participant[transcriptId];

              if (transcriptObj.status === "InProgress") {
                transcriptIds.push(transcriptId);
              }
            }
          }

          if (transcriptIds.length > 0) {
            console.log("3", participantId);
            apiHandler(participantId, transcriptIds);
            apiCalled = true;
          }
        }
      }

      if (!apiCalled) {
        this.setState({ isSessionSynopsisLoading: false });
        clearInterval(this.loadTranscriptTimer);
      }
    };

    const callInitiateTranscriptionApi = (
      participantId1,
      participantId2,
      mappingId
    ) => {
      console.log("participant1:", participantId1);
      console.log("participantId2:", participantId2);
      initiateTranscription({
        participantId1,
        participantId2,
        mappingId,
      })
        .then((res) => {
          let transcriptObj = {};

          res.data.transcriptIds.map((id) => {
            transcriptObj = {
              ...transcriptObj,
              [id]: { status: "InProgress" },
            };
          });

          this.setState(
            {
              transcripts: {
                ...this.state.transcripts,
                [participantId1]: transcriptObj,
              },
            },
            () => {
              if (this.loadTranscriptTimer === null) {
                this.loadTranscriptTimer = setInterval(
                  callFetchTranscriptApi,
                  5000
                );
              }
            }
          );
        })
        .catch((err) => {
          console.error(err.message);
        });
    };

    this.props.toggleVC();
    this.setState({
      showSessionSynopsis: true,
      isSessionSynopsisLoading: true,
      disabledSynopsisButton: true,
    });
    let params = new URLSearchParams(window.location.search);
    let roomId = params.get("roomId");
    const reqData = {
      roomId: roomId,
    };
    createRoomParticipantMapping(reqData)
      .then((res) => {
        let participantsForTranscript = [];

        this.setState({
          synopsisData: {
            mappingId: res.data.mappingId,
            roomId,
          },
        });

        if (this.loadTranscriptTimer !== null) {
          clearInterval(this.loadTranscriptTimer);
        }
        let participant_one = "";
        res.data.participants.map((participant) => {
          participantsForTranscript.push(participant);
          //callInitiateTranscriptionApi(participant.participant_sid, res.data.mappingId)
        });

        setTimeout(() => {
          console.log(
            "Calling INITIATE TRASCRIPTION-----------------",
            participantsForTranscript
          );
          callInitiateTranscriptionApi(
            participantsForTranscript[0].participant_sid,
            participantsForTranscript[1].participant_sid,
            res.data.mappingId
          );
        }, 5000);

        this.setState({
          mappingId: res.data.mappingId,
          participantsForTranscript,
        });
      })
      .catch((err) => {
        console.error(err.message);
      });
  };
  componentWillUnmount() {
    if (this.loadTranscriptTimer) {
      clearInterval(this.loadTranscriptTimer);
    }
    try {
      window.$(document).unbind("scroll");
    } catch (e) {
      console.log(e.message);
    }
  }
  handlesavedata = () => {
    let form_data = new FormData();
    form_data.append("question", this.state.question);
    form_data.append("text_answer", this.state.answer);
    form_data.append("interactive_textbook_id", this.props.textbook_id);
    form_data.append("instructional_content_id", this.props.contentId);
    form_data.append("resource", this.state.resource);
    form_data.append(
      "display_name_anticipated_error",
      this.state.display_name_anticipated_error
    );
    form_data.append("question_type", this.state.question_type);

    if (this.state.resource_image !== null) {
      form_data.append("resource_image", this.state.resource_image);
    }
    saveinteractivetextbookquestions(form_data)
      .then((res) => {
        if (res.status === 200) {
          let newNode = document.createElement("span");
          if (this.state.question_type === "Anticipated") {
            newNode.id = "hidden##" + res.data.data;
            newNode.className = "questionclasses";
          } else if (this.state.question_type === "Progress Checkpoint MCQ") {
            newNode.id = "hiddenpc##" + res.data.data;
            newNode.className = "questionclassespcmcq";
            newNode.innerHTML = `<i class='fa fa-question-circle' aria-hidden='true' id=textbook_mcq_question_ids##${res.data.data}></i>`;
          }

          this.state.rangedata.insertNode(newNode);
          var editabledivdata = document.getElementById("editablediv");
          console.log(
            "check hidddenvalue append or not",
            editabledivdata.innerHTML
          );

          let form_data = new FormData();
          form_data.append("interactive_textbook_id", this.props.textbook_id);
          form_data.append("content", editabledivdata.innerHTML);
          updateinteractivetextbookcontent(form_data).then((res) => {
            if (res.status === 200) {
              this.setState({ modalopen: false });
            }
          });
        }
      })
      .catch((err) => {
        console.error(err.message);
      });
  };

  handlequestion = (event) => {
    this.setState({ question: event.target.value });
  };

  handleimageupload = (e) => {
    this.setState({
      resource_image: e.target.files[0],
    });
  };
  shouldHideHint = 0;

  saveQuestionToDatabase = async (question) => {
    let quetionid = null;
    const reqData = {
      question,
      source_type: "text",
      userId: getCurrentUserId(),
      interactive_textbook_id: this.props.textbook_id,
    };

    await saveUserQuestion(reqData)
      .then((res) => {
        if (res.data.message !== undefined && res.data.message !== null);
        {
          quetionid = res.data.message;
          this.setState({ questionId: res.data.message });
        }
      })
      .catch((err) => {
        console.error(err.message);
      });

    return quetionid;
  };
  getKnowledgeBaseAnswer = (answerData) => {
    let answer = "";
    if (answerData.substring(0, 6) == "TL;DR:") {
      let tdlr = ""; // "<b>TL;DR:</b>";
      let indexOfLongAnswer;
      let longAnswer = "<br /><b>Long Answer:</b>";

      indexOfLongAnswer = answerData.indexOf("Long Answer:");
      if (indexOfLongAnswer == -1) {
        indexOfLongAnswer = answerData.indexOf("Long answer:");
      }

      if (indexOfLongAnswer == -1) {
        tdlr += answerData.substring(6, answerData.length);
      } else {
        tdlr += answerData.substring(6, indexOfLongAnswer);

        longAnswer += answerData.substring(
          indexOfLongAnswer + 12,
          answerData.length
        );
      }
      answer += tdlr + longAnswer;
    } else {
      answer = answerData;
    }
    return { __html: answer.replace(/\\n/g, "<br />") };
  };

  searchKnowledgeBase = async () => {
    let quesValue = this.textareaRef.current.value.trim();
    this.setState({ questionvalueforasktutor: quesValue });
    if (quesValue.length <= 0) {
      toast("Please enter a question.", {
        type: toast.TYPE.ERROR,
        position: toast.POSITION.BOTTOM_RIGHT,
      });
    } else {
      this.state.askAI = true;
      this.setState({ askAI: true });
      this.openraisehandpopup();

      let quetionid = await this.saveQuestionToDatabase(quesValue);
      fetchKnowledgeBaseQuestions({ params: { question: quesValue } })
        .then((res) => {
          this.setState({ asktutorbuttonvisible: false });
          const showAnswer = (quesValue, dataObj) => {
            this.answerModalBody = (
              <div className="progress-bar-container">
                <Spinner animation="border" role="status" />
              </div>
            );
            this.openAnswerModal();

            fetchKnowledgeBaseQuestions({
              params: { question: quesValue, index: dataObj.index },
            })
              .then((res) => {
                let answer = this.getKnowledgeBaseAnswer(
                  res.data.knowledgebase.answer
                );

                let answerModalBody = (
                  <div className="answer-content">
                    <div>
                      <Row>
                        <Col sm="4"></Col>
                        <Col sm="4">
                          <div className="clearfix rating-hover">
                            <Ratings
                              stars={dataObj.rateValue}
                              className="ratings main-video-rating mr-2"
                              readOnly
                            />
                            <img
                              src={arrowImage}
                              alt="Arrow Image"
                              className="arrowIcon"
                            />
                            <RatingsHover
                              explanationStars={0}
                              presentationStars={0}
                              paceRange={2}
                            />
                          </div>
                        </Col>
                        <Col sm="4">
                          <Button
                            className="rating-button yellow-btn"
                            clicked={(event) =>
                              this.openModalWriteReview(event)
                            }
                          >
                            Write a Review
                          </Button>
                        </Col>
                      </Row>
                    </div>
                    <p className="your-question font-16">
                      Your Question: <span>{quesValue}</span>
                    </p>
                    <p className="selected-question font-16">
                      Selected Question:{" "}
                      <span>{res.data.knowledgebase.question}</span>
                    </p>
                    <p className="answer font-16">
                      Answer:{" "}
                      <span>
                        <div dangerouslySetInnerHTML={answer} />
                      </span>
                    </p>
                    {res.data.knowledgebase.resource === "" ? null : (
                      <p className="resource font-16">
                        Resource:{" "}
                        <a
                          href={res.data.knowledgebase.resource}
                          target="_blank"
                        >
                          {res.data.knowledgebase.resource}
                        </a>
                      </p>
                    )}
                  </div>
                );

                this.answerModalBody = answerModalBody;
                this.showAnswerButton = false;
                this.openAnswerModal();
              })
              .catch((err) => {
                console.error(err.message);
              });
          };

          let knowledgeBaseContent =
            res.data.knowledgebase.length > 0
              ? res.data.knowledgebase.map((dataObj, index) => (
                <div className="d-flex knowledgebase-question-container">
                  <p
                    className="question font-16"
                    onClick={() => showAnswer(quesValue, dataObj)}
                  >
                    {dataObj.question}
                  </p>
                  <div className="d-flex align-items-center pl-2">
                    <p className="theta">{dataObj.theta}</p>
                    <img
                      src={
                        apiURL +
                        "/knowledgebase/plot.png/?angle=" +
                        dataObj.rawTheta +
                        "&index=" +
                        index
                      }
                      alt="Graph"
                    />
                  </div>
                  <div className="clearfix rating-hover">
                    <Ratings
                      stars={dataObj.rateValue}
                      className="ratings main-video-rating mr-2"
                      readOnly
                    />
                    <img src={arrowImage} alt="" className="arrowIcon" />
                    <RatingsHover
                      explanationStars={0}
                      presentationStars={0}
                      paceRange={2}
                    />
                  </div>
                  {dataObj.entry_source === "internal" ? (
                    <div className="d-flex align-items-center pl-2">
                      <img src={brainIcon} alt="" className="brainIcon" />
                    </div>
                  ) : (
                    <div className="d-flex align-items-center pl-2 brainIcon" />
                  )}
                </div>
              ))
              : null;

          this.knowledgeBaseContent = knowledgeBaseContent ? (
            <div className="knowledge-base-content">
              <p className="title">Related KnowledgeGraph Content</p>
              <p className="sub-title font-16">
                From the following list, please select the question that best
                matches yours:
              </p>
              {knowledgeBaseContent}

              <p className="titlenone">None of the Above:</p>
              <span
                className="question font-16 questionbutton humanTutorBtnMargin"
                onClick={this.askTutor}
              >
                Ask a Human Tutor{" "}
              </span>
              <span className="spSpace">OR</span>
              <span
                className="question font-16 questionbutton generativeAIBtnMargin"
                onClick={() => this.askToChatGPT(true)}
              >
                Ask GenerativeAI
              </span>
              {/* OR<span className="question font-14" style={{ marginRight: '10px', marginLeft: '10px' }} onClick={() => this.askToChatGPT(false)}>Ask ChatGPT</span> */}
            </div>
          ) : (
            <div className="knowledge-base-content">
              <p className="sub-title titlenone">
                No question found that best matches yours!
              </p>
              <span
                className="question font-14 questionbutton humanTutorBtnMarginNoMatch"
                onClick={this.askTutor}
              >
                {" "}
                Ask a Human Tutor{" "}
              </span>
              OR
              <span
                className="question font-14 questionbutton generativeAIBtnMargin"
                onClick={() => this.askToChatGPT(true)}
              >
                Ask GenerativeAI
              </span>
              {/* OR<span className="question font-14" style={{ marginRight: '10px', marginLeft: '10px' }} onClick={() => this.askToChatGPT(false)}>Ask ChatGPT</span> */}
            </div>
          );

          this.openraisehandpopup();
        })
        .catch((err) => {
          console.error(err.message);
        });
    }
  };

  handleTabChange = (newValue) => {
    this.setState({ tabValue: newValue });
  };

  render() {
    let raiseHandClass = ["yellow-btn", "raiseBtn", "synopsisbuttonfixed"];
    if (this.props.hasQuestion) {
      raiseHandClass.push("disabled-btn");
    }

    // let skipAheadClass = ['yellow-btn', 'skipButton'];
    // if (this.props.hasQuestion) {
    //     skipAheadClass.push('disabled-btn');
    // }

    return (
      <>
        <Modal show={this.state.modalopen} onHide={this.handleClosemodal}>
          <Modal.Header closeButton>
            <Modal.Title>Questions</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Tabs
              activeKey={this.state.tabValue}
              onSelect={this.handleTabChange}
              className="tabs userSelectEvent learningcontenttextbookcss learningcontenttextbookTabsFont"
            >
              <Tab eventKey="anticipated" title="Anticipated">
                <div className="tabDiv">
                  <div className="name-node addedHintsContainer">
                    <div className="divLearningContent">
                      <div className="divLearningContentLabel">
                        <label className="labels">Question</label>
                      </div>
                      <div className="divLearningContentControl">
                        <textarea
                          class="form-control bg-yellow"
                          rows="2"
                          value={this.state.question}
                          onChange={this.handlequestion}
                        />
                      </div>
                    </div>
                  </div>
                  <div className="name-node addedHintsContainer">
                    <div className="divLearningContent">
                      <div className="divLearningContentLabel">
                        <label className="labels">Answer</label>
                      </div>
                      <div className="divLearningContentControl">
                        <textarea
                          class="form-control bg-yellow"
                          rows="2"
                          value={this.state.answer}
                          onChange={(evt) => {
                            this.setState({ answer: evt.target.value });
                          }}
                        />
                      </div>
                    </div>
                  </div>
                  <div className="name-node addedHintsContainer">
                    <div className="divLearningContent">
                      <div className="divLearningContentLabel">
                        <label className="labels">Resource:</label>
                      </div>
                      <div className="divLearningContentControl">
                        <input
                          className="inputResource"
                          value={this.state.resource || ""}
                          onChange={(evt) => {
                            this.setState({ resource: evt.target.value });
                          }}
                        />
                      </div>
                    </div>
                  </div>
                  <div className="name-node addedHintsContainer">
                    <div className="divLearningContent">
                      <div className="divLearningContentLabel">
                        <label className="labels">Display Resource:</label>
                      </div>
                      <div className="divLearningContentControl">
                        <textarea
                          class="form-control bg-yellow"
                          rows="2"
                          value={this.state.display_name_anticipated_error}
                          onChange={(evt) => {
                            this.setState({
                              display_name_anticipated_error: evt.target.value,
                            });
                          }}
                        />
                      </div>
                    </div>
                  </div>
                  <div className="name-node addedHintsContainer">
                    <div className="divLearningContent">
                      <div className="divLearningContentLabel">
                        <label className="labels">Resource Image:</label>
                      </div>
                      <div className="divLearningContentControl">
                        <input
                          type="file"
                          id="myFile"
                          name="filename"
                          onChange={this.handleimageupload.bind(this)}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </Tab>
              <Tab eventKey="progresscheckpoint" title="Progress Checkpoint">
                <div className="tabDiv">Progress Checkpoint Form</div>
              </Tab>
            </Tabs>
          </Modal.Body>
          <Modal.Footer>
            <Button className="yellow-btn" clicked={this.handlesavedata}>
              Save
            </Button>
          </Modal.Footer>
        </Modal>
        <Button
          clicked={(e) => this.handleclickraisehand(e, this.state.tabValue)}
          className="yellow-btn-sticky"
        >
          <PanToolIcon />
          Raise Hand
        </Button>
        {this.props.isTeacher ? (
          <Button
            className={raiseHandClass.join(" ")}
            clicked={this.endConferenceHandler}
          >
            Synopsis
          </Button>
        ) : null}
        {this.props.iseditablecontent ? (
          <>
            <div
              contentEditable="true"
              id="editablediv"
              onDoubleClick={this.handlemodalopen}
              className="html-content editableContentDiv"
              dangerouslySetInnerHTML={{ __html: this.getHTMLContent() }}
            ></div>
          </>
        ) : (
          <div
            onMouseOver={(e) => {
              if (this.props.isHintVisible && this.shouldHideHint) {
                this.props.hideHint();
                this.shouldHideHint = false;
              }
            }}
            id="editablediv"
            className="html-content noEditableContentDiv"
            onClick={this.clickHandler}
            onTouchStart={this.clickHandler}
            dangerouslySetInnerHTML={{ __html: this.getHTMLContent() }}
          ></div>
        )}
        {this.props.isTeacher ? (
          <SessionSynopsisWithData
            show={this.state.showSessionSynopsis}
            onClose={this.closeSessionSynopsis}
            isLoading={this.state.isSessionSynopsisLoading}
            transcripts={this.state.transcripts}
            synopsisData={this.state.synopsisData}
            isHistoryView={false}
            call_type={this.props.call_type}
          />
        ) : null}

        <ModalCom
          title={this.bodytitle}
          show={this.state.showModal}
          handleClose={this.closeModal}
          body={this.modalBody}
          showButton={this.showButton}
          customButton={this.customButton}
        />
        <ModalCom
          title={this.answerbodytitle}
          show={this.state.showAnswerModal}
          handleClose={this.closeAnswerModal}
          body={this.answerModalBody}
          showButton={this.showAnswerButton}
          customButton={this.customAnswerButton}
        //hideHeader={true}
        //title={this.title}
        />

        <ModalAI
          show={this.state.showAIModal}
          handleClose={this.closeAnswerModalAI}
          body={this.answerModalBody}
          showButton={this.showAnswerButton}
          customButton={this.customAnswerButton}
          hideHeader={true}
          title={this.title}
        />
        <div
          id="questionsContinertb"
          className="questionsContinerTableDiv"
          style={{
            padding: "20px",
            width: "98%",
            marginLeft: "5px",
            height: "463px",
            background: "white",
            position: "absolute",
            border: "1px solid #5A5A5A",
            borderRadius: "10px",
            top: this.state.summativequestiondivposition,
            visibility:
              this.state.showSummativeQuestion &&
                this.state.summativeQuestions !== undefined &&
                this.state.summativeQuestions.length > 0
                ? "visible"
                : "hidden",
          }}
        >
          {this.state.showSummativeQuestion &&
            this.state.summativeQuestions !== undefined &&
            this.state.summativeQuestions.length > 0 ? (
            <SummativeQuestion
              question={this.state.currentSummativeQuestion}
              totalquestions={this.state.summativeQuestions}
              sourceType="Text"
              closecheckpoint={this.handleclosecheckpoint}
              content_time={0}
            />
          ) : null}
        </div>
        <ModalCom
          show={this.state.showModalWriteReview}
          handleClose={this.closeModalWriteReview}
          title={this.modalTitleReview}
          body={this.modalBodyReview}
          customButton={this.customButtonReview}
        />
      </>
    );
  }
}
