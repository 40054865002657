import React, { useState } from 'react';
import Cookies from 'universal-cookie';
import configData from './config.json';

export const APP_URL = configData.APP_URL;
export const WEBSITE_URL = configData.WEBSITE_URL
const cookies = new Cookies()
//const [sessionId, setSessionId] = useState('');

export const DESKTOP = 'desktop';
export const MOBILE = 'mobile';
export const TABLET = 'tablet';


export const getRoutes = (array, item) => {
    let newArray = []

    if (array.length === 0) {
        newArray.push(item)
    } else {
        newArray = [...array, item]
    }

    return newArray
}

export const getCurrentUserName = () => {
    return cookies.get('name')
}

export const getIsPTTeacher = () => {
    return cookies.get('isPtTeacher')
}

export const getCurrentUserId = () => {
    if (cookies.get('userId')) {
        return Number(cookies.get('userId'))
    } else {
        console.log('user session ends!');
        window.location.href = process.env.REACT_APP_BASE_URL
    }
}

export const getCurrentUserEmail = () => {
    return cookies.get('email')
}

export const getCurrentUserRole = () => {
    return cookies.get('role')
}

export const isDemoUser = () => {
    return cookies.get('is_DemoUser')
}

export const isDemoUserFlagged = () => {
    let flag = cookies.get('isDemoUserFlagged')
    return flag === "false" ? false : true
}

export const getUserFingerprint = () => {
    return cookies.get('user_fingerprint')
}

export const getInstituteId = () => {
    return cookies.get('institute_id')
}

export const getCurrentUserStatus = () => {
    return cookies.get('status')
}

export const getSessionId = () => {
    return cookies.get('session');
}

export const setSession = (id) => {
    return cookies.set('session', id)
}

export const getusertimezone = () => {
    // let timezone = cookies.get('usertimezone')
    // if(timezone === undefined)
    // {
    let timezone = getlocalsystemtimezone()
    // }    
    return timezone
}

export const hasAiPlatoUser = () => {

    const regex = /@([a-zA-Z0-9.-]+)$/;
    const match = getCurrentUserEmail().match(regex);
    return (match[1] !== 'rice.edu' && match[1] !== 'saes.org' && getCurrentUserEmail() !== 'mstanger116@gmail.com' && getCurrentUserEmail() !== 'mstanger@aiplato.ai') ? true : false;
}

export const isRiceUser = () => {
    const regex = /@([a-zA-Z0-9.-]+)$/;
    const match = getCurrentUserEmail().match(regex);
    return match[1] === 'rice.edu' ? true : false
}

export const isMattUser = () => {
    const regex = /@([a-zA-Z0-9.-]+)$/;
    const match = getCurrentUserEmail().match(regex);
    //    console.log(((match[1] === 'saes.org' || getCurrentUserEmail() === 'mstanger116@gmail.com') && match[1] !== 'rice.edu') ? true : false)
    return ((match[1] === 'saes.org' || getCurrentUserEmail() === 'mstanger116@gmail.com' || getCurrentUserEmail() == 'mstanger@aiplato.ai') && match[1] !== 'rice.edu') ? true : false;
}
export const getTryThisPinsEnabledFlag = () => {
    return cookies.get('tryThisPinsEnabled') ? cookies.get('tryThisPinsEnabled') === 'yes' ? true : false : false
}

export const getQATestFlag = () => {
    return cookies.get('QATestFlag') ? cookies.get('QATestFlag') === 'true' ? true : false : false
}


export const getDisplayTextHolder = (text) => {
    let texts = text.split(' from ')
    if (texts.length > 1) {
        if (texts.length === 2) {
            return <>{texts[0]} <i>from {texts[1]}</i></>
        } else {
            return <>{texts[0]} <i>from {texts.splice(1).join(' from ')}</i></>
        }
    } else {
        return <>{text}</>
    }
}

export const Challenge_status = {
    underdevelopment: 'Under Development',
    underreview: 'Under Review',
    reviewinprogress: 'Review In Progress',
    reviewed: 'Reviewed',
    betareleased: 'Beta Released',
    released: 'Released',
}

export const User_Role = {
    Admin: 'Admin',
    Tutor: 'Tutor',
    Student: 'Student',
    ContentAuthor: 'ContentAuthor',
    ContentReviewer: 'ContentReviewer',
    ContentExpert: 'ContentExpert',
    Professor: 'Professor'
}
export const getlocalsystemtimezone = () => {
    const timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
    return timezone;
}
export const Call_Type = {
    Challenge: 'challenge',
    LearningContent: 'learningcontent'

}
export const HintRequredMessage = {
    message: 'Please enter above field values',
}

export const InteractiveType = {
    Video: 'Video',
    Textbook: 'Textbook',
    Problems: 'Problems',
    AnyProblem: 'AnyProblem',
    TestProblems: "TestProblems"
}

export const InteractiveMainMenuPageType = {
    InteractiveContent: 'AI-Interactive Content',
    InteractiveProblems: 'Interactive Problems'
}

// Function to detect the operating system
export const detectOS = () => {
    const userAgent = window.navigator.userAgent;
    const platform = window.navigator.platform;

    if (/Win/i.test(platform)) {
        return "Windows";
    } else if (/Mac/i.test(platform)) {
        return "MacOS";
    } else if (/Linux/i.test(platform)) {
        return "Linux";
    } else if (/Android/i.test(userAgent)) {
        return "Android";
    } else if (/iOS/i.test(userAgent)) {
        return "iOS";
    } else {
        return "Unknown";
    }
}

// Function to detect the device type
export const showFullApp = () => {
    return (detectDevice() === DESKTOP || detectDevice() === TABLET) ? true : false
}

export const obtainSubstitution = (substitution_map) => {
    let sub_keys = []
    let sub_values = []
    let substitution_mapkey = null
    let substitution_mapvalue = null
    let finalsubstitutionslist = ""

    for (let value of Object.values(substitution_map)) {
        sub_values.push(value);
    }
    //to get keys
    for (let key of Object.keys(substitution_map)) {
        sub_keys.push(key)
    }

    if (sub_keys.length > 0 && sub_values.length > 0) {
        substitution_mapkey = sub_keys
        substitution_mapvalue = sub_values
    }
    if (substitution_mapkey !== undefined && substitution_mapkey !== null && substitution_mapkey !== "null" && substitution_mapkey !== 'null') {

        for (var i = 0; i < substitution_mapkey.length; i++) {
            if (finalsubstitutionslist === "") {
                finalsubstitutionslist = substitution_mapvalue[i] + " in place of " + substitution_mapkey[i];
            }
            else {
                finalsubstitutionslist = finalsubstitutionslist + ", " + substitution_mapvalue[i] + " in place of " + substitution_mapkey[i]
            }

        }
    }
    return finalsubstitutionslist;
}

export const formatAnyProblemAPIResponse = (response) => {
    let res = ''
    if (!Array.isArray(response)) {
        res = response;
    } else {
        res = response[0];
    }
    let formatedresponse = res.replace(/</g, '&lt;').replace(/>/g, '&gt;').split("\n") //join("<br />")
    return formatedresponse;
}

const base64ToImage = (base64, callback) => {
    const img = new Image();
    img.onload = () => callback(img);
    img.src = base64;
}

const compressImage = (img, quality, maxWidth, maxHeight, callback) => {
    const canvas = document.createElement('canvas');
    const ctx = canvas.getContext('2d');

    // Calculate the new dimensions
    let width = img.width;
    let height = img.height;

    if (width > maxWidth) {
        height = Math.round((maxWidth / width) * height);
        width = maxWidth;
    }

    if (height > maxHeight) {
        width = Math.round((maxHeight / height) * width);
        height = maxHeight;
    }

    // Set canvas dimensions
    canvas.width = width;
    canvas.height = height;

    // Draw the image on the canvas
    ctx.drawImage(img, 0, 0, width, height);

    // Convert the canvas to a base64 string
    const compressedBase64 = canvas.toDataURL('image/jpeg', quality);

    callback(compressedBase64);
}

export const compressBase64Image = (base64, quality, maxWidth, maxHeight, callback) => {
    base64ToImage(base64, (img) => {
        compressImage(img, quality, maxWidth, maxHeight, callback);
    });
}

export const setTourCookies = (timeLimit, pagename, isfirst = false) => {
    const cookies = new Cookies();
    const hostnameParts = window.location.hostname.split('.');
    const domainName = hostnameParts.length > 1 ? hostnameParts[hostnameParts.length - 2] + "." + hostnameParts[hostnameParts.length - 1] : hostnameParts[hostnameParts.length - 1];
    cookies.set('istourcomplete' + "" + pagename, isfirst, { path: '/', domain: domainName, maxAge: timeLimit });


}
export const getTourCookies = (pagename) => {
    return cookies.get('istourcomplete' + "" + pagename)
}

export const convertDurationToSeonds = (duration) => {
    //09:35:41.947483
    const a = duration.split(':');
    return (+a[0]) * 60 * 60 + (+a[1]) * 60 + (+a[2]);
}

export const convertSecondsToHMS = (seconds) => {
    // Calculate hours, minutes, and seconds
    let hours = Math.floor(seconds / 3600);
    let minutes = Math.floor((seconds % 3600) / 60);
    let remainingSeconds = seconds % 60;

    // Format the time components to ensure two digits
    let formattedHours = String(hours).padStart(2, '0');
    let formattedMinutes = String(minutes).padStart(2, '0');
    let formattedSeconds = String(remainingSeconds).padStart(2, '0');

    // Return the formatted time string
    return `${formattedHours}:${formattedMinutes}:${formattedSeconds}`;
}

export const Problem_source = {
    homework: 'homework',
    apptesthomework: 'apptesthomework',
    interactiveproblems: 'interactiveproblems',
    assignment: "assignment"

}
export const Previous_Node_Name = {
    practicetest: 'Practice Test',
    homeworkproblems: 'Homework Problems',
    interactiveproblems: 'Interactive Problems',
    learningcontent: "Learning Content"

}
export const equationContainerClass = {
    errorContainer: 'errorContainer',
    correctContainer: 'correctContainer',


}
export const assignment_Type = {
    homework: 'homework',
    quiz: 'quiz',  

}
export const UserEvaluateFeedback = {
    thumbsup: 1,
    thumbsdown: 2
}

export const formatInteractionResponse = (response) => {
    let res = ''
    if (!Array.isArray(response)) {
        res = response;
    } else {
        res = response[0];
    }
    let formatedresponse = res.replace(/</g, '&lt;').replace(/>/g, '&gt;').split("\n").join("<br />")
    return formatedresponse;
}

export const getTimeDifference = (epoch1, epoch2) => {
    // Calculate the difference in seconds
    let diffInSeconds = Math.abs(epoch2 - epoch1);

    // Calculate hours, minutes, and seconds
    let hours = Math.floor(diffInSeconds / 3600);
    let minutes = Math.floor((diffInSeconds % 3600) / 60);
    let seconds = diffInSeconds % 60;

    return { hours: hours, minutes: minutes, seconds: seconds }
}

export const formatDate = (date) => {
    const formattedDate = new Date(date).toLocaleDateString('en-US', {
        day: '2-digit',
        month: 'long',
        year: 'numeric',
    });

    return formattedDate
}

export const formatDateUs = (date) => {
    const formattedDate = new Date(date).toLocaleDateString('en-US');

    return formattedDate
}

function isPhone() {
    const userAgent = navigator.userAgent || navigator.vendor || window.opera;
    return /iPhone|iPod|Android.*Mobile|Windows Phone|BlackBerry|IEMobile|Opera Mini/i.test(userAgent);
}

function isTablet() {
    const userAgent = navigator.userAgent || navigator.vendor || window.opera;
    // Check for iPad specifically, and for Android tablets (Android without 'Mobile')
    return /iPad|Android(?!.*Mobile)|Silk|Kindle|PlayBook|Tablet|Nexus 7|Nexus 10/i.test(userAgent) ||
        (navigator.platform === 'MacIntel' && navigator.maxTouchPoints > 1);
}

function isDesktop() {
    return !isPhone() && !isTablet();
}
export const detectDevice = () => {

    if (isPhone()) {
        return MOBILE;
    } else if (isTablet()) {
        return TABLET
    } else if (isDesktop()) {
        return DESKTOP;
    }
}
export const formatNumber = (value) => {
    // Check if the number has a decimal part
    if (value % 1 !== 0) {
        // If it does, round it to two decimal places
        return parseFloat(value).toFixed(2);
    } else {
        // If it doesn't, return the number as an integer
        return value.toString();
    }
}
export const QuestionType = {
    frq: 'FRQ',
    mcq: 'MCQ',
    msq: 'MSQ',
}

export const getMenuSelectedItem = () => {
    let course_id = null
    let menuselecteditem = cookies.get("menuselecteditem")
    if (menuselecteditem !== undefined && menuselecteditem !== null && menuselecteditem !== "") {
        course_id = parseInt(menuselecteditem)
    }
    return course_id
}