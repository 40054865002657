import React from 'react';
import Rating from '@material-ui/lab/Rating';
import Box from '@material-ui/core/Box';
import './Ratings.scss'

export default function Ratings(props) {
  const value = props.stars

  return (
    <div className={props.className}>
      {props.readOnly ?
        <Box style={{ margin: '0' }} component="fieldset" mb={3} borderColor="transparent">
          <Rating name="half-rating-read" value={value} precision={0.5} readOnly />
        </Box> :
        <Box style={{ margin: '0' }} component="fieldset" mb={3} borderColor="transparent">
          <Rating
            name={"simple-controlled" + Math.random()}
            value={value}
            precision={0.5}
            onChange={(event, newValue) => {
              props.onChange(newValue);
            }}
          />
        </Box>
      }
    </div>
  );
}
