import React from 'react';
import './Challenges.scss';
import Aux from '../../hoc/Wrapper';
import { Container, Row, Col } from 'react-bootstrap';
import Breadcrumb from '../../components/Breadcrumb/Breadcrumb';
import TopicChallenges from '../../components/Challenges/TopicChallenges/TopicChallenges';
import OuterBox from '../../components/NotesBookmarks/LeftPanel/OuterBox/OuterBox';
import TreeView from 'deni-react-treeview';
import RadioButton from '../../components/Common/Radiobutton/Radiobutton'
import { fetchChallenges } from '../../common/API';
import { withNamespaces } from 'react-i18next';
import { getCurrentUserRole, getCurrentUserId, User_Role } from '../../common/Functions';
import '../../common/theming-customizations.scss'
//import { data } from "./test";
import CircularProgress from '@mui/material/CircularProgress';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Tooltip from 'react-bootstrap/Tooltip';

class Challenges extends React.Component {

    constructor(props) {
        super(props);
        this.changeTreeViewStyle = this.changeTreeViewStyle.bind(this);
        this.treeviewref = React.createRef(TreeView);
    }
    state = {
        challenges: [],
        treeViewData: null,
        filteredChallenges: [],
        challengesData: null,
        no_challenge: false,
        sortBy: 'Easy',
        treeFilter: true,
        time: 0,
        isLoading: true,
        userrole: getCurrentUserRole(),
        selectednode: null,
        treeViewObject: null,
        finalchallenge: {},
        loading: false
    }
    titlesOfData = []

    componentDidMount() {
        this.setState({ loading: true })
        window.addEventListener('load', this.changeTreeViewStyle);

        const reqData = { params: { 'user_id': getCurrentUserId(), 'user_role': this.state.userrole } }

        fetchChallenges(reqData).then(res => {
            if (res.status === 204) {
                this.setState({ no_challenge: true });
            } else {

                if (res.data.titleOfContent !== undefined) {

                    let challengesData = res.data;
                    let treeViewData = res.data.treeViewData;
                    this.titlesOfData = res.data.titleOfContent

                    this.setState({
                        challengesData: challengesData,
                        challenges: this.getChallenges(challengesData),
                        filteredChallenges: this.getChallenges(challengesData),
                        isLoading: false,
                        treeViewData: treeViewData
                    }, () => {
                        this.sortChallenges(this.state.sortBy)
                    });
                }
                else {
                    let treeViewData = res.data.treeViewData;
                    this.setState({
                        treeViewData: treeViewData
                    }, () => {
                        this.sortChallenges(this.state.sortBy)
                    });
                }
                this.setState({ loading: false })

            }

        }).catch(err => {
            console.error(err.message);
            alert('Some error occurred');
            //this.props.history.goBack();
        })


    }

    componentWillUnmount() {
        window.removeEventListener('load', this.changeTreeViewStyle)
    }

    changeTreeViewStyle() {
        window.$('.deni-react-treeview-container .text-inner').each((index, element) => {
            if (this.titlesOfData.includes(element.innerHTML)) {
                element.style.color = 'black'
            }
        });
    }
    groupbyget = (list, keyGetter) => {
        const map = new Map();
        list.forEach((item) => {
            const key = keyGetter(item);
            const collection = map.get(key);
            if (!collection) {
                map.set(key, [item]);
            } else {
                collection.push(item);
            }
        });
        let array = Array.from(map, ([key, value]) => ({ key, value }));
        return array
    }
    sortbypartnumber = (list) => {

        list.sort((a, b) => {

            return a.part_number - b.part_number;
        });
    }

    getChallenges = (challengesData) => {

        let challengesList = [];
        challengesData.chapters.map(sec => {
            challengesData.section[sec.id].map(toc => {
                challengesData.topics[toc.id].map(challenge => {
                    if (challengesData.challenges[challenge.id] !== undefined) {                       
                        let kValue = toc.name//challenge.name
                        let filterkvalue = toc.name
                        
                        // if (filterkvalue === "General") {
                        //     kValue = challenge.name;
                        //     filterkvalue = toc.name //challenge.filtername + " | " + toc.name + " | " + sec.name;
                        // }
                        let ob = {}
                        ob['topic'] = kValue;
                        ob['filtertopic'] = filterkvalue;
                        ob['displaytext'] = kValue;
                        ob['tId'] = challenge.id;
                        ob['chapter_id'] = sec.id;
                        ob['section_id'] = toc.id;
                        ob['subsection_id'] = challenge.id.substring(1);
                        ob['sub_section_name'] = challenge.name;
                        ob['section_name'] = toc.name;
                        ob['chapter_name'] = sec.name;
                        //ob['challenges'] = [...challengesData.challenges[challenge.id]];   

                        var notmultipart = challengesData.challenges[challenge.id].filter(e => e.ismultipart === 0)
                        var multipart = challengesData.challenges[challenge.id].filter(e => e.ismultipart === 1)
                        if (multipart.length > 0)
                            multipart = multipart.sort((a, b) => parseInt(a.part_number) - parseInt(b.part_number));
                        //var n(omultipartdatagroupby = _.groupBy(notmultipart, 'id');

                        let nomultipartdatagroupby = this.groupbyget(notmultipart, el => el.id)
                        let multipartdatagroupby = this.groupbyget(multipart, el => el.multipartgroupid)
                        //var multipartdatagroupby = _.groupBy(multipart, 'multipartgroupid');
                        if (multipartdatagroupby.length > 0) {
                            // let data = { ...nomultipartdatagroupby, ...multipartdatagroupby }
                            // Object.assign(nomultipartdatagroupby, multipartdatagroupby)
                            // debugger;                            
                            //nomultipartdatagroupby.concat(multipartdatagroupby)
                            //let data = Object.assign({}, nomultipartdatagroupby);
                            Array.prototype.push.apply(nomultipartdatagroupby, multipartdatagroupby);
                        }
                        //let data = this.groupbyget(challengesData.challenges[challenge.id], el => el.problem_statement+"#"+el.multipartgroupid) 
                        ob['challenges'] = nomultipartdatagroupby//this.groupbyget(challengesData.challenges[challenge.id], el => el.problem_statement + "#" + el.multipartgroupid)
                        challengesList.push(ob)
                    }
                });
            });
        });

        let sortedchallenge = challengesList.sort((a, b) => {
            if (a.topic < b.topic) {
                return -1;
            }
            if (a.topic > b.topic) {
                return 1;
            }
            return 0;
        });
        return sortedchallenge;
        // return challengesList;
    }

    getLeafItem = (item) => {
        if (item.isLeaf) {
            this.listTreeChallenges.push(item);
            return item;
        }
        item.children.map(newItem => {
            this.getLeafItem(newItem);
        })
    }
    setFilteredChallenged = (itemList) => {

        const isOnlyChild = (item) => {

            for (let index = 0; index < this.state.treeViewData.length; index++) {
                const chapter = this.state.treeViewData[index];
                if (chapter.text === item) {
                    return ''
                }
                for (let index1 = 0; index1 < chapter.children.length; index1++) {
                    const section = chapter.children[index1];

                    if (section.text === item) {
                        return ''
                    }
                    if (!section.isLeaf) {
                        for (let index2 = 0; index2 < section.children.length; index2++) {
                            const subSection = section.children[index2];
                            if (subSection.text === item) {
                                return section.text
                            }
                        }
                    }
                }
            }
            return ''
        }
        let newActiveChallenges = !this.state.treeFilter ? [] : [...this.state.filteredChallenges];
        this.setState({ treeFilter: true })
        itemList.map(item => {
            this.state.challenges.map(t => {
                let selecteditemtext = item.section_name
                //debugger;

                if (t.filtertopic === selecteditemtext) {
                    let isOnlyChildOfParent = isOnlyChild(selecteditemtext)
                    if (item.state === 1) {
                        if (isOnlyChildOfParent) {
                            if (newActiveChallenges.findIndex(el => el.filtertopic === isOnlyChildOfParent) === -1) {
                                let itemList = this.state.challenges.filter(t => t.filtertopic === isOnlyChildOfParent)
                                if (itemList.length > 0)
                                    newActiveChallenges.push(itemList[0]);
                            }
                        }
                        if (newActiveChallenges.findIndex(el => el.filtertopic === t.filtertopic) === -1) {
                            newActiveChallenges.push(t);
                        }
                    } else {
                        //newActiveChallenges.splice(newActiveChallenges.findIndex(el => el.topic === t.topic), 1);
                        newActiveChallenges = newActiveChallenges.filter(item => item.filtertopic !== t.filtertopic)
                        // if (isOnlyChildOfParent) {
                        //     newActiveChallenges.splice(newActiveChallenges.findIndex(el => el.topic === isOnlyChildOfParent), 1);
                        // }
                    }
                }
            })
        })
        let sortedchallenge = newActiveChallenges.sort((a, b) => {
            if (a.topic < b.topic) {
                return -1;
            }
            if (a.topic > b.topic) {
                return 1;
            }
            return 0;
        });
        //return sortedchallenge;
        this.setState({ filteredChallenges: sortedchallenge });
    }

    editItemClick(item) {
        this.setState({
            selectednode: item
        }, () => {
            this.props.history.push({
                pathname: '/addchallenges/',
                state: item // your data array of objects
            })
        })
    }
    onRenderItem(item, treeview) {
        if (!item.islastnode) {
            return (
                <div className="treeview-item-example">
                    <span className="treeview-item-example-text">{item.text}</span>
                </div>
            )
        } else {
            return (
                <div className="treeview-item-example">
                    <span className="treeview-item-example-text">{item.text}</span>
                    {
                        (this.state.userrole === User_Role.ContentAuthor || this.state.userrole === User_Role.Professor) ?
                            (
                                <OverlayTrigger
                                    key="bottom"
                                    placement="bottom"
                                    overlay={
                                        <Tooltip id={`tooltip-bottom`}>
                                            Add Challenge
                                        </Tooltip>
                                    }
                                >
                                    <span className="actionButton edit" onClick={this.editItemClick.bind(this, item)}><i class="fa fa-plus" style={{ fontSize: "12px", boxShadow: "none", textDecoration: 'none', }}></i>
                                    </span>
                                </OverlayTrigger>
                            ) : null
                    }
                </div>
            )
        }
    }
    onCheckItemHandler = (item) => {
        if (item.children) {
            this.listTreeChallenges = [item,]
            this.getLeafItem(item);
            this.setFilteredChallenged(this.listTreeChallenges);
        }
        if (item.isLeaf) {
            this.setFilteredChallenged([item,]);
        }
    }

    handleSortByOnClick = (e) => {
        let sortBy = e.target.value
        this.sortChallenges(sortBy)
    }

    sortChallenges = (sortBy) => {
        let challenges = this.state.filteredChallenges
        let level = { Medium: { Easy: 100, Medium: 1, Hard: 2 }, Hard: { Easy: 100, Medium: 2, Hard: 1 }, Easy: { Easy: 0, Medium: 1, Hard: 2 } }

        challenges.forEach(obj => obj.challenges.sort(function (a, b) {
            let difficultyLevelA = level[sortBy][a.complexity]
            let difficultyLevelB = level[sortBy][b.complexity]
            return difficultyLevelA - difficultyLevelB
        }))

        this.setState({
            filteredChallenges: challenges,
            sortBy: sortBy
        })
    }

    clearFilter = () => {
        let treeViewData = this.state.treeViewData
        let newTreeViewData = []
        treeViewData.map(element => {
            let sectionList = []
            if (element.children) {
                element.children.map(section => {
                    let subSectionList = []
                    if (section.children) {
                        section.children.map(subSection => {
                            subSectionList.push({
                                text: subSection.text,
                                isLeaf: subSection.isLeaf,
                                islastnode: subSection.islastnode,
                                section_name: subSection.section_name,
                                chapter_name: subSection.chapter_name,
                                sub_section_name: subSection.sub_section_name,
                                id: subSection.id,
                                parent_id: subSection.section_id,
                                state: subSection.state
                            })
                        })
                    }
                    let item = {
                        text: section.text,
                        islastnode: section.islastnode,
                        chapter_name: section.chapter_name,
                        section_name: section.section_name,
                        id: section.section_id,
                        sub_section_name: section.sub_section_name,
                        parent_id: section.chapter_id,
                    }
                    if (subSectionList.length > 0) {
                        item.children = subSectionList
                    } else {
                        item.isLeaf = true
                    }
                    sectionList.push(item)
                })
            }
            newTreeViewData.push({
                text: element.text,
                id: element.id,
                chapter_name: element.chapter_name,
                children: sectionList,
                //state: element.state,
                islastnode: element.islastnode,
                parent_id: element.parent_id,
                section_name: element.section_name,
                sub_section_name: element.sub_section_name

            })
        })
        this.setState({
            filteredChallenges: [], //this.state.challenges,
            treeViewData: newTreeViewData,
            time: this.state.time + 1,
            treeFilter: false,

        }, () => {
            if (this.state.time < 2) {
                this.clearFilter()
            } else {
                this.setState({ time: 0 })
            }
        })
    }
    render() {
        setTimeout(this.changeTreeViewStyle, 500)
        let finalChallenges = this.state.filteredChallenges;
        let hasNoChallenge = finalChallenges.length > 0 ? false : true;
        return (
            <Aux>
                <Breadcrumb
                    currentNode='Challenges'
                    className="challenges-container"
                    prevNode={this.props.location.state ? this.props.location.state : []} />

                <Container className='challenges-container'>
                    <Row className='challenges'>
                        <div className='challange-left'>
                            <OuterBox title='Sort By Complexity:'>
                                <fieldset onChange={this.handleSortByOnClick.bind(this)}>
                                    <RadioButton text="Ascending" name="radio" value="Easy" checked={this.state.sortBy === "Easy"} />

                                    <RadioButton text="Descending" name="radio" value="Hard" checked={this.state.sortBy === "Hard"} />
                                </fieldset>
                            </OuterBox>

                            <div className='tree-view-notes'>
                                {this.state.treeViewData ?
                                    <>
                                        <div className='divflex'>
                                            <div className='titletreeview' >Lesson Navigator</div>
                                            <div className='clear-filter' onClick={this.clearFilter}>Clear Filter</div>
                                        </div>
                                        <div className="theme-customization">
                                            <TreeView
                                                className="onrenderitem-example"
                                                selectRow={true}
                                                showCheckbox={true}
                                                ref={this.treeviewref}
                                                items={this.state.treeViewData}
                                                onCheckItem={this.onCheckItemHandler.bind(this)}
                                                onRenderItem={this.onRenderItem.bind(this)}

                                            />
                                        </div>
                                    </>
                                    : null}
                            </div>
                        </div>
                        <div className='challange-right'>

                            <Row>
                                <Col>
                                    {this.state.isLoading ?
                                        <h5 className='mt-5'>Finding Challenges - Personalized Recommendations for You</h5>
                                        :
                                        this.state.no_challenge || hasNoChallenge ?
                                            <h5 className='mt-5'>No challenge found{this.state.treeFilter ? ' from the specified filter!' : '!'}</h5>
                                            :
                                            <div className='mt-3'>
                                                {
                                                    finalChallenges ? finalChallenges.map((item, index) => {

                                                        return (
                                                            <TopicChallenges
                                                                index={index + 1}
                                                                title={item.displaytext}
                                                                section_id={item.section_id}
                                                                subsection_id={item.subsection_id}
                                                                section_name={item.section_name}
                                                                sub_section_name={item.sub_section_name}
                                                                chapter_name={item.chapter_name}
                                                                data={item.challenges}
                                                                userrole={this.state.userrole}
                                                                allchallenges = {this.state.filteredChallenges}
                                                            />
                                                        )

                                                    }) : null
                                                }
                                            </div>
                                    }
                                </Col>

                            </Row>
                        </div>
                    </Row>
                    {this.state.loading && (
                        <CircularProgress
                            size={24}
                            sx={{
                                color: "#fdbd17",
                                position: 'absolute',
                                top: '50%',
                                left: '50%',
                                marginTop: '-12px',
                                marginLeft: '-12px',
                            }}
                        />
                    )}
                </Container>
            </Aux>
        );
    }
}


export default withNamespaces()(Challenges);