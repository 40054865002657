import React from 'react';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { Col, Row } from 'react-bootstrap';
import { formatAnyProblemAPIResponse, formatInteractionResponse } from '../../common/Functions';
import { getInteractionEvaluation, getTranscriptIdsInteractionEvaluate, getChallengesInteractionEvaluate } from '../../common/API'
import './InteractionEvaluation.scss'
import { withRouter } from 'react-router-dom';
import MathJaxRenderer from '../../components/Common/MathJaxRenderer/MathJaxRenderer';
import { TableHead } from '@mui/material';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import Box from '@mui/material/Box';
import { isNil, isEmpty } from 'lodash';
import Aux from "../../hoc/Wrapper";

class InteractionEvaluationItem extends React.Component {



    render() {
        return (
            <div style={{ margin: '30px' }} key={this.props.item.id}>
                <Accordion expanded={this.props.isExpand === this.props.item.interaction_id} onChange={this.props.handleAccordionChange}>
                    <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel1a-content" id="panel1a-header">
                        <Typography >
                            <Row>
                                <Col className='col-12 col-md-12'>
                                    <p style={{ paddingRight: '10px', float: 'left', display: '-webkit-box' }} className='sessionNameText'>
                                        <span style={{ marginRight: '5px' }}>   Interaction {this.props.item.interaction_id} : </span>
                                        {/* <MathJaxRenderer math={this.props.inputType}></MathJaxRenderer> */}
                                        <div dangerouslySetInnerHTML={{ __html: this.props.inputType }} />;
                                    </p>
                                </Col>
                            </Row>
                        </Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                        <Typography>
                            <div className='acc-content'>
                                <div>
                                    <div className='fieldHeaderText'>Input Type</div>
                                    <div className='interactionTextwithMargin'> {this.props.item.input_type}</div>
                                </div>

                                {this.props.eqData.length > 0 ?
                                    <div>
                                        <div className='fieldHeaderText'>Equations</div>
                                        <div className='interactionEquations'>
                                            {this.props.eqfinaldata}
                                        </div>
                                    </div>
                                    : null
                                }
                                <br />
                                <div>
                                    <div className='fieldHeaderText'>Response Comparison</div>
                                    <div className='interactionTextwithMargin'>
                                        
                                        <Box sx={{ width: '100%' }}>
                                            <Paper sx={{ width: '100%' }}>
                                                <TableContainer style={{ height: '400px' }}>
                                                    <Table
                                                        className='interactiveTable'
                                                        aria-labelledby="tableTitle"
                                                        size={'medium'}
                                                    >
                                                        <TableBody>
                                                            <TableHead style={{ display: 'contents', width: '100%' }}>
                                                                <TableRow>
                                                                    <TableCell
                                                                        style={{ width: '25%' }}
                                                                        key="aiPlatoTutor"
                                                                        align={'left'}
                                                                        padding={'normal'}
                                                                    >
                                                                        AiPlato Tutor
                                                                    </TableCell>
                                                                    <TableCell
                                                                        style={{ width: '25%' }}
                                                                        key="aiPlatoTutor"
                                                                        align={'left'}
                                                                        padding={'normal'}
                                                                    >
                                                                        GPT 4o
                                                                    </TableCell>
                                                                    <TableCell
                                                                        style={{ width: '25%' }}
                                                                        key="aiPlatoTutor"
                                                                        align={'left'}
                                                                        padding={'normal'}
                                                                    >
                                                                        GEMINI
                                                                    </TableCell>
                                                                    <TableCell
                                                                        style={{ width: '25%' }}
                                                                        key="aiPlatoTutor"
                                                                        align={'left'}
                                                                        padding={'normal'}
                                                                    >
                                                                        Claude
                                                                    </TableCell>
                                                                </TableRow>
                                                            </TableHead>
                                                            <TableRow>
                                                                <TableCell style={{ verticalAlign: 'top' }} align="left">
                                                                    {this.props.modalBodyaiTutorfinal}
                                                                </TableCell>
                                                                <TableCell style={{ verticalAlign: 'top' }} align="left">
                                                                    {/* <MathJaxRenderer math={this.props.gpt4Res} /> */}
                                                                    <div dangerouslySetInnerHTML={{ __html: this.props.gpt4Res }} />;
                                                                </TableCell>
                                                                <TableCell style={{ verticalAlign: 'top' }} align="left">
                                                                    {/* <MathJaxRenderer math={this.props.geminiRes} /> */}
                                                                    <div dangerouslySetInnerHTML={{ __html: this.props.geminiRes }} />;
                                                                    
                                                                </TableCell>
                                                                <TableCell style={{ verticalAlign: 'top' }} align="left">
                                                                    {/* <MathJaxRenderer math={this.props.claudeRes} /> */}
                                                                    <div dangerouslySetInnerHTML={{ __html: this.props.claudeRes }} />;
                                                                    
                                                                </TableCell>
                                                            </TableRow>
                                                        </TableBody>
                                                    </Table>
                                                </TableContainer>
                                            </Paper>
                                        </Box>

                                    </div>
                                </div>



                            </div>
                        </Typography>
                    </AccordionDetails>
                </Accordion>
            </div>

        )
    }
}

export default withRouter(InteractionEvaluationItem)