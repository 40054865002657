import React from 'react'

const interactionText = (props) => {
    return(
        <div style={{fontSize: '14px', marginBottom: '20px'}}>
            <div style={{fontWeight: 600, marginBottom: '2px'}}>{props.day}</div>
            <div>{props.study}</div>
            <div>{props.topic}</div>
        </div>
    );
}

export default interactionText;