import React from 'react';
import HomeWorkForm from '../../components/Homework/HomeWorkForm';
import { Problem_source } from '../../common/Functions';

const PracticeTest = () => {
    return (
        <HomeWorkForm problem_source = {Problem_source.apptesthomework}></HomeWorkForm>
    )
}

export default PracticeTest