import React, { memo } from "react";
import { getCurrentUserRole, User_Role } from '../../../common/Functions';
import { Handle } from "react-flow-renderer";
import { MathComponent } from "mathjax-react";
//import { MathpixLoader, MathpixMarkdown } from 'mathpix-markdown-it'
import MathJax from 'react-mathjax2';
import { isNil } from "lodash";
import parse from 'html-react-parser';
import MathJaxRenderer from "../../Common/MathJaxRenderer/MathJaxRenderer";

export default memo(({ id, data }) => {
  const getToolTip = () => {

    if (data.showExplanation) {
      return <MathJaxRenderer math={data.explanation}/>
      // <MathpixLoader>
      //   <MathpixMarkdown text={parse(data.explanation)} />
      // </MathpixLoader>
    } else {
      return null;
    }

  }

  const getToolTipResource = () => {
    if (data.displayresourceids) {
      return <MathJaxRenderer math={data.displayresourceids}></MathJaxRenderer>
     
    } else {
      return null;
    }

  }

  const getToolTipNode = () => {
    if (data.displaynodeid) {
      return <MathJaxRenderer math={data.displaynodeid}></MathJaxRenderer>
    } else {
      return null;
    }

  }

  const isNodeValid = () => {


    if (!isNil(data.user_added) && !data.user_added) {
      data.background = "#cccccc";
    }
    if (data.isNodeCorrect) {
      data.isValid = (data.label === '' || data.equation === '' || data.explanation === '') ? false : true;
    } else {
      data.isValid = (data.label === '' || data.equation === '') ? false : true;
    }

    setTimeout(() => {
      let container = document.getElementById(id);
      if (!isNil(container)) {
        data['height'] = container.clientHeight
        data['width'] = container.clientWidth
      } else {
        data['height'] = 150
        data['width'] = 150
      }
    }, 500)
    return data.isValid;
  }


  return (
    <>
      <Handle
        type="target"
        position="top"
        id="target_handle"
        style={(isNodeValid() || data.isReadOnly) ? { background: '#555' } : { background: '#ff0000' }}

      />
      <div
        className="nodeStyle"
        style={{
          background: `${data.background}`,
          display: "inline-table",
          padding: "0 5px 0 5px",
          width: "100%",
          borderRadius: `5px`,
          border: `2px solid ${data.border}`,
          height: "50px"
        }}
      >

        <div id={id} className="nodeContentContainer" style={(data.isNodeCorrect) ? { color: '#000' } : { color: "#fff" }}>
          <div className="contentContainer">{data.isReadOnly ? data.explanation ? getToolTip() : null : null}</div>
          <div style={{ display: "flex", justifyContent: "space-between" }}>
            {

              (getCurrentUserRole() === User_Role.ContentAuthor
                || getCurrentUserRole() === User_Role.Professor
                || getCurrentUserRole() === User_Role.ContentExpert
                || getCurrentUserRole() === User_Role.ContentReviewer)
                && data.isnodeiddisplay !== null ? (<div>{data.isnodeiddisplay ? getToolTipNode() : null}</div>) : null
            }
            <div lassName="contentcontainerresource"><span>&nbsp;&nbsp;</span></div>
            {

              (getCurrentUserRole() === User_Role.ContentAuthor
                || getCurrentUserRole() === User_Role.Professor
                || getCurrentUserRole() === User_Role.ContentExpert
                || getCurrentUserRole() === User_Role.ContentReviewer)
                && data.isresourceidsdisplay !== null ? (<div className="contentcontainerresource">{data.isresourceidsdisplay ? getToolTipResource() : null}
                </div>) : null
            }
          </div>
          <div className="contentContainer">{data.content}</div>
          {data.label !== '' ?
            <MathComponent tex={data.label} /> : null}
        </div>
      </div>
      <Handle id="source_handle" type="source" position="bottom" style={(isNodeValid() || data.isReadOnly) ? { background: '#555' } : { background: '#ff0000' }} />

    </>
  );

});
