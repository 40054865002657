import React from "react";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from 'ckeditor5-classic-with-mathtype';
import './ckEditor.scss'

const toolbar = ['MathType'];

class CkEditorComponent extends React.Component {
    timer;
    constructor(props) {
        super(props);
        this.state = {
            minheight: ""
        };
    }

    componentDidMount() {
        if (this.props.frompage !== undefined && this.props.frompage !== null) {
            this.setState({ minheight: this.props.editorHeight })
        }
        else {
            this.setState({ minheight: "calc(100vh - 242px)" })
        }
    }

    handleChange = (e, editor) => {
        clearTimeout(this.timer)
        let editorData = editor.getData()

        this.props.onCKEditorDataChange(editorData)
        this.timer = setTimeout(() => {
            this.extractMathML(editorData)
        }, 2000)
    }

    extractMathML = (editorData) => {

        var tempContainer = document.createElement('div');
        tempContainer.innerHTML = editorData
        if (tempContainer) {
            var mathMLElements = tempContainer.querySelectorAll("math");
            mathMLElements.forEach(function (mathElement, index) {
                var mathMLContent = mathElement.outerHTML;
                console.log("MathML content " + (index + 1) + ":", mathMLContent);
            });
        }
        else {
            console.error("Container not found");
        }
    }

    render() {

        return (
            <div className="divCKEditor" >
                <CKEditor
                    id={this.props.ckEditorId}
                    editor={ClassicEditor}
                    onChange={this.handleChange}
                    onReady={(editor) => {
                        editor.editing.view.change((writer) => {
                            writer.setStyle(
                                "min-height",
                                this.state.minheight,
                                editor.editing.view.document.getRoot()
                            );
                        });
                    }}
                    config={{
                        placeholder: this.props.placeHolderText,
                        toolbar: toolbar,
                        iframe: true,
                        charCounterCount: false,
                        htmlAllowedTags: ['.*'],
                        htmlAllowedAttrs: ['.*'],
                        htmlAllowedEmptyTags: ['mprescripts'],
                        imageResize: false,
                        useClasses: false
                    }}
                />
            </div>
        )
    }
}
export default (CkEditorComponent)