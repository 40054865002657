import axios from "axios";

const getConfig = () => {
  const config = {
    headers: {
      app_id: "support_aiplato_ai_bf34f7_15b824",
      app_key: "e15d3ed6a024f0d8a225ecf9df52e3beb2ff3333c035b332d961a5ce7ebfb8a8",
      "Content-Type": "application/json",
    }
  };
  return config;
}

const getConfigWithToken = (tokenContext) => {
  const config = {
    headers: {
      app_token: tokenContext.app_token,
      strokes_session_id: tokenContext.strokes_session_id,
      "Content-Type": "application/json",
    }
  };
  return config;
}

export const getStrokesToken = async () => {
  const config = getConfig();
  const payload = { include_strokes_session_id: true };
  const response = await axios.post("https://api.mathpix.com/v3/app-tokens", payload, config);
  if (response.status === 200) {
    return {
      app_token: response.data.app_token,
      strokes_session_id: response.data.strokes_session_id,
      app_token_expires_at: response.data.app_token_expires_at
    };
  }
  return null;

}

export const getLatex = async (tokenContext, strokes) => {
  const config = getConfigWithToken(tokenContext);
  var X = [];
  strokes.map(stroke => { X.push(stroke.points.map(point => point.x)) });
  var Y = [];
  strokes.map(stroke => { Y.push(stroke.points.map(point => point.y)) });

  return axios.post('https://api.mathpix.com/v3/strokes',
    {
      "strokes": {
        "strokes": {
          "x": X,
          "y": Y
        }
      },
      "formats": ["text", "data", "latex_styled"],
      "data_options": {
        "include_latex": true,
        include_mathml: true
      },
      include_line_data: true,
      idiomatic_braces: true
    }, config)
}

export const getLatexFromImage = async (tokenContext, image) => {
  const config = getConfigWithToken(tokenContext);

  return axios.post('https://api.mathpix.com/v3/text',
    {
      "src": image,
      "formats": ["text", "data", "latex_styled"],
      "data_options": {
        "include_latex": true,
        "include_mathml": true,
      },
      include_line_data: true,

    }, config)
}

export const getLatexFromFromProblemStatement = async (tokenContext, image) => {
  const config = getConfigWithToken(tokenContext);

  return axios.post('https://api.mathpix.com/v3/text',
    {
      "src": image,
      "formats": ["text", "data", "html"],
      "data_options": {
        "include_mathml": true,
      }

    }, config)
}
