import React from 'react';
import './LeftPanel.scss';
import Aux from '../../../hoc/Wrapper';
import OuterBox from '../../../components/NotesBookmarks/LeftPanel/OuterBox/OuterBox';
import TreeView from 'deni-react-treeview';
import Radiobutton from '../../../components/Common/Radiobutton/Radiobutton';

export default class LeftPanel extends React.PureComponent {

    state = {
        sortBy: 'Curriculum Sequence',
        filterBy: 'Show all'
    }

    handleSortByOnClick = (e) => {
        this.setState({
            sortBy: e.target.value
        })
        this.props.sortData(e.target.value)
    }

    handleFilterChange = (e) => {
        this.setState({ filterBy: e.target.value })
        this.props.filterData(e.target.value)
    }

    handleTreeViewOnClick = (item) => {
        this.props.handleOnCheckTreeViewItem(item)
    }

    render() {
        return (
            <Aux>
                <OuterBox title='Sort By:'>
                    <fieldset onChange={this.handleSortByOnClick.bind(this)}>
                        <Radiobutton text="Chronological" value="Chronological" name="radio" checked={this.state.sortBy === 'Chronological'} />
                        <Radiobutton text="Reverse Chronological" value="Reverse Chronological" name="radio" checked={this.state.sortBy === 'Reverse Chronological'} />
                        <Radiobutton text="Curriculum Sequence" value="Curriculum Sequence" name="radio" checked={this.state.sortBy === 'Curriculum Sequence'} />
                    </fieldset>
                </OuterBox>

                <OuterBox title={`Filter: ${this.state.filterBy}`}>
                    <form>
                        <div class="drop-down-list">
                            <select value={this.state.filterBy} onChange={this.handleFilterChange.bind(this)}>
                                <option value="Show all">Show all</option>
                                <option value="Review">Review</option>
                                <option value="Clarification">Clarification</option>
                                <option value="Notes">Notes</option>
                                <option value="Highlights">Highlights</option>
                                <option value="Bookmarks">Bookmarks</option>
                            </select>
                        </div>
                    </form>
                </OuterBox>

                <div className='tree-view-notes'>
                    <div className='divflex'>
                        <div className='titletreeview' >Lesson Navigator</div>
                        <div className='clear-filter' onClick={this.clearFilter}>Clear Filter</div>
                    </div>
                    <div className="theme-customization">
                        <TreeView showCheckbox={true}
                            items={this.props.treeViewData}
                            onExpanded={() => { setTimeout(this.props.changeTreeViewStyle, 200) }}
                            onCheckItem={this.handleTreeViewOnClick.bind(this)} />
                    </div>.
                </div>

            </Aux>
        );
    }
}
