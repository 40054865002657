import React, { useEffect, useState } from 'react';
import './TestReport.scss';
import Button from '@mui/material/Button'
import PropTypes from 'prop-types';
import {
    Avatar,
    makeStyles
} from '@material-ui/core';
import Box from '@mui/material/Box';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import TableSortLabel from '@mui/material/TableSortLabel';
import Paper from '@mui/material/Paper';
import { Row, Col } from 'react-bootstrap';
import { visuallyHidden } from '@mui/utils';
import {formatNumber} from '../../common/Functions'

export const DEFAULT_COLOR = '#5470C6';
export const SELECTED_COLOR = '#FFA756';



function descendingComparator(a, b, orderBy) {
    if (b[orderBy] < a[orderBy]) {
        return -1;
    }
    if (b[orderBy] > a[orderBy]) {
        return 1;
    }
    return 0;
}

function getComparator(order, orderBy) {
    return order === 'desc'
        ? (a, b) => descendingComparator(a, b, orderBy)
        : (a, b) => -descendingComparator(a, b, orderBy);
}


function stableSort(array, comparator) {
    const stabilizedThis = array.map((el, index) => [el, index]);
    stabilizedThis.sort((a, b) => {
        const order = comparator(a[0], b[0]);
        if (order !== 0) {
            return order;
        }
        return a[1] - b[1];
    });
    return stabilizedThis.map((el) => el[0]);
}



function EnhancedTableHead(props) {


    const { onSelectAllClick, order, orderBy, numSelected, rowCount, onRequestSort } =
        props;
    const createSortHandler = (property) => (event) => {
        onRequestSort(event, property);
    };

    return (
        <TableHead>
            <TableRow>
                <TableCell
                    key="challenge_name"
                    align={'left'}
                    padding={'normal'}
                    sortDirection={orderBy === "challenge_name" ? order : false}
                    sx={{ cursor: 'pointer' }}
                >
                    <TableSortLabel
                        active={orderBy === "challenge_name"}
                        direction={orderBy === "challenge_name" ? order : 'asc'}
                        onClick={createSortHandler("challenge_name")}
                    >
                        Assignments
                        {orderBy === "challenge_name" ? (
                            <Box component="span" sx={visuallyHidden}>
                                {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                            </Box>
                        ) : null}
                    </TableSortLabel>
                </TableCell>
                <TableCell>Percent Attempted</TableCell>
                <TableCell>Average Score</TableCell>
                <TableCell>Min Score</TableCell>
                <TableCell>Max Score</TableCell>
                <TableCell>Students with Max Score</TableCell>
            </TableRow>
        </TableHead>
    );
}

EnhancedTableHead.propTypes = {
    numSelected: PropTypes.number.isRequired,
    onRequestSort: PropTypes.func.isRequired,
    // onSelectAllClick: PropTypes.func.isRequired,
    order: PropTypes.oneOf(['asc', 'desc']).isRequired,
    orderBy: PropTypes.string.isRequired,
    // rowCount: PropTypes.number.isRequired,
};

const useStyles = makeStyles((theme) => ({
    root: {},
    avatar: {
        height: 35,
        width: 35,
        // marginRight: theme.spacing(1),
        margin: "7px"
    },
    avatarlarge: {
        height: 100,
        width: 100,
    },
    linkbutton: {
        fontSize: "10px"
    },
    tableRow: {
        "&:hover": {
            backgroundColor: "#1976d214 !important"
        }
    },
    tableRowhw: {
        "&:hover": {
            backgroundColor: "#e0e0e0 !important"
        }
    },
    rootAbsolute: {
        "& .MuiButton-startIcon": {
            marginRight: "1px"
        }
    }

}));

export default function TestReport({ problemList }) {
    const classes = useStyles();
    const [avgScore, setAvgScore] = useState(0);
    const [minScore, setMinScore] = useState(0);
    const [maxScore, setMaxScore] = useState(0);
    const [highScore, setHighScore] = useState(0);
    const [selectedProblem, setSelectedProblem] = useState('');
    const [selected, setSelected] = React.useState([]);
    const [showBarChart, setshowBarChart] = useState(true);
    const [totalPages, settotalPages] = useState(1)
    const [order, setOrder] = React.useState('asc');
    const [orderBy, setOrderBy] = React.useState('title');
    const [displayMessage, setDisplayMessage] = useState("")

    const [tableIconColor, settableIconColor] = useState("currentColor")
    const [barchartIconColor, setbarchartIconColor] = useState("#4252af")

    useEffect(() => {

        drawBarchart()

    }, [problemList])

    const drawBarchart = () => {

        let xAxisLabel = [];
        let yAxisData = [];

        problemList.forEach((element, index) => {
            if (index === 0) {
                setAvgScore(element.avg_score);
                setMinScore(element.min_score);
                setMaxScore(element.max_score);
                setHighScore(element.students_max_score)
                setSelectedProblem(element.challenge_name)
            }
            xAxisLabel.push(element.challenge_name);
            yAxisData.push({ value: element.percentage_attempted, avg: element.avg_score, min: element.min_score, max: element.max_score, high: element.students_max_score, problem: element.challenge_name });
        });
        console.log(problemList)
        let option = {
            scale: true,
            scaleSize: 20,
            tooltip: {
                trigger: "axis",
                axisPointer: {
                    type: "shadow"
                }
            },
            xAxis: {
                type: 'category',
                data: xAxisLabel,
                // name:'Problem Name',
                // nameLocation: "middle",
                // nameGap: 60,
                interval: 0,
                axisLabel: {
                    rotate: 22
                }
            },
            yAxis: {
                type: 'value',
                name: 'Percent Attempted',
                nameRotate: 90,
                nameLocation: "middle",
                nameGap: 40,
                axisLabel: {
                    formatter: function (item) {
                        return item + '%'; // Append '%' to the value
                    },

                }
            },
            tooltip: {
                trigger: 'item', // Trigger the tooltip on each item
                formatter: function (params) {
                    // Customize the tooltip content
                    // Assuming values are in decimal format and need to be displayed as percentages
                    return `Problem Name: ${params.name} <br/> Percent Attempted: ${params.value}%`//params.name + ': ' + (params.value) + '%'; // Format the value as percentage
                }
            },
            series: [
                {
                    data: yAxisData,
                    type: 'bar',
                    barWidth: "30%",
                    label: {
                        show: true,
                        position: 'top',
                        formatter: function (params) {
                            return params.value + '%'; // Append '%' to the value
                        }
                    },
                    itemStyle: {
                        color: function (params) {
                            // Set the default color of all bars
                            return params.dataIndex === 0 ? SELECTED_COLOR : DEFAULT_COLOR;
                        }
                    }
                }
            ]
        };
        debugger;
        var chartDom = document.getElementById('challengesBarChart');
        var myChart = window.echarts.init(chartDom);
        option && myChart.setOption(option);
        myChart.on('click', function (params) {
            try {
                var newColor = option.series[0].data.map((item, index) => {
                    return index === params.dataIndex ? SELECTED_COLOR : DEFAULT_COLOR;
                });

                setAvgScore(params.data.avg);
                setMinScore(params.data.min);
                setMaxScore(params.data.max);
                setHighScore(params.data.high)
                setSelectedProblem(params.data.problem);
                // Update the chart option with the new color settings
                myChart.setOption({
                    series: [{
                        itemStyle: {
                            color: function (params) {
                                return newColor[params.dataIndex];
                            }
                        }
                    }]
                });
            } catch (e) {

            }

        });
    }

    const handleclickbarchart = () => {
        setshowBarChart(true)
        settableIconColor("currentColor")
        setbarchartIconColor("#4252af")
        setTimeout(() => { drawBarchart() }, 200)

    }
    const handletable = () => {
        setshowBarChart(false)
        settableIconColor("#4252af")
        setbarchartIconColor("currentColor")
    }

    const handleRequestSort = (event, property) => {

        const isAsc = orderBy === property && order === 'asc';
        setOrder(isAsc ? 'desc' : 'asc');
        setOrderBy(property);
    };
    const visibleRowshomeworkList = React.useMemo(
        () =>
            stableSort(problemList, getComparator(order, orderBy)),
        [problemList, order, orderBy],
    );

    return (

        <div className="reportContainer">
            <div className='d-flex justify-content-end' style={{ marginRight: "2%" }}>
                <div onClick={handleclickbarchart} style={{ cursor: "pointer" }} title='Chart View'>
                    <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill={barchartIconColor} class="bi bi-bar-chart" viewBox="0 0 16 16">
                        <path d="M4 11H2v3h2zm5-4H7v7h2zm5-5v12h-2V2zm-2-1a1 1 0 0 0-1 1v12a1 1 0 0 0 1 1h2a1 1 0 0 0 1-1V2a1 1 0 0 0-1-1zM6 7a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1v7a1 1 0 0 1-1 1H7a1 1 0 0 1-1-1zm-5 4a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1v3a1 1 0 0 1-1 1H2a1 1 0 0 1-1-1z" />
                    </svg></div>
                <div onClick={handletable} style={{ marginLeft: "10px", cursor: "pointer" }} title='Table View'>
                    <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill={tableIconColor} class="bi bi-table" viewBox="0 0 16 16">
                        <path d="M0 2a2 2 0 0 1 2-2h12a2 2 0 0 1 2 2v12a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2zm15 2h-4v3h4zm0 4h-4v3h4zm0 4h-4v3h3a1 1 0 0 0 1-1zm-5 3v-3H6v3zm-5 0v-3H1v2a1 1 0 0 0 1 1zm-4-4h4V8H1zm0-4h4V4H1zm5-3v3h4V4zm4 4H6v3h4z" />
                    </svg></div>
            </div>

            {
                showBarChart ? (
                    <>
                        <div id='challengesBarChart' className='challengesBarChart'>

                        </div>
                        <div className='problemNameHeader'>
                            <span>Selected Problem: {selectedProblem} </span>
                        </div>

                        <div className='kpiConainter'>
                            <div>
                                <span>Average Score</span>
                                <span>{avgScore !== null && avgScore !== undefined ? formatNumber(avgScore) : "0"}</span>
                            </div>
                            <div>
                                <span>Min Score</span>
                                <span>{minScore !== null && minScore !== undefined ? formatNumber(minScore) : "0"}</span>
                            </div>
                            <div>
                                <span>Max Score</span>
                                <span>{maxScore !== null && maxScore !== undefined ? formatNumber(maxScore) : "0"}</span>
                            </div>
                            <div>
                                <span>Students with Max Score</span>
                                <span>{highScore}</span>
                            </div>
                        </div>
                    </>
                ) :
                    (
                        <Box sx={{ width: '94%', mt: 3, ml: 7 }}>
                            <Paper sx={{ width: '100%', mb: 2, overflow: 'hidden' }}>


                                <TableContainer sx={{ maxHeight: 400 }}>
                                    <Table
                                        sx={{ minWidth: 650 }}
                                        stickyHeader aria-label="sticky table"
                                        size={'medium'}
                                    >
                                        <EnhancedTableHead
                                            numSelected={selected.length}
                                            order={order}
                                            orderBy={orderBy}
                                            onRequestSort={handleRequestSort}

                                        />
                                        <TableBody>
                                            {
                                                visibleRowshomeworkList !== undefined && visibleRowshomeworkList !== null && visibleRowshomeworkList.length > 0 ?

                                                    (visibleRowshomeworkList.map((row, index) => {
                                                        return (
                                                            <TableRow className={classes.tableRowhw}
                                                                tabIndex={-1}
                                                                key={row.id}
                                                                sx={{ cursor: 'pointer' }}>
                                                                <TableCell align="left">
                                                                    {row.challenge_name}
                                                                </TableCell>
                                                                <TableCell align="left" style={{ fontWeight: "bolder" }}>                                                                    
                                                                    {`${row.percentage_attempted !== null && row.percentage_attempted !== undefined ? formatNumber(row.percentage_attempted) : 0.00}%`}
                                                                </TableCell>
                                                                <TableCell align="left">
                                                                    {row.avg_score !== null && row.avg_score !== undefined ? formatNumber(row.avg_score) : 0.00}
                                                                </TableCell>
                                                                <TableCell align="left">
                                                                    {row.min_score !== null && row.min_score !== undefined ? formatNumber(row.min_score) : 0.00}                                                                   
                                                                </TableCell>
                                                                <TableCell align="left">                                                                   
                                                                    {row.max_score !== null && row.max_score !== undefined ? formatNumber(row.max_score) : 0.00}
                                                                </TableCell>
                                                                <TableCell align="left">                                                                    
                                                                    {row.students_max_score !== null && row.students_max_score !== undefined ? formatNumber(row.students_max_score) : 0.00}
                                                                </TableCell>
                                                            </TableRow>
                                                        );
                                                    })) :
                                                    (
                                                        // <div style={{ padding: "20px" }}>{displayMessage}</div>
                                                        <TableRow>
                                                            <TableCell colSpan={5} style={{ padding: "20px", textAlign: "center", fontSize: "17px" }}>{displayMessage}</TableCell>
                                                        </TableRow>
                                                    )
                                            }

                                        </TableBody>
                                    </Table>
                                </TableContainer>
                            </Paper>

                        </Box>

                    )
            }

        </div>
    )
}