import React from 'react';
import './FeatureIcon.scss';
import { Link } from 'react-router-dom';

const FeatureIcon = (props) => {
    return (
        <div className='bottom-icon'>
            <Link to='/' className={props.textClassName || ''}>
                <img src={props.image} alt={props.text} className='iconSize'/>
            </Link>
            <label className={props.textClassName || ''} dangerouslySetInnerHTML={{ __html: props.text }}>
            </label>
            <div className='rating-box'>
                <h4>{props.title}</h4>
                <div className='write-review'>
                    {props.children}
                </div>
            </div>
        </div>
    );
}

export default FeatureIcon;