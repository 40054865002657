import React from 'react'
import { Modal } from 'react-bootstrap';
import './VideoPopup.scss'

class VideoPopup extends React.Component {

    reference = React.createRef()


    componentDidUpdate() {
        if (this.props.track) {
            const el = this.reference.current;
            if (el) {
                el.muted = true
                this.props.track.attach(el)
            }
        }
    }

    render() {
        return (
            <Modal
                dialogClassName="video-popup-modal"
                show={this.props.show}
                onHide={this.props.handleClose}
                animation={false}
                backdrop={'static'} >
                {this.props.hideHeader ? null :
                    <Modal.Header closeButton={this.props.disableClose ? false : true}>
                        {this.props.title ?
                            <Modal.Title>{this.props.title}</Modal.Title>
                            : null}
                    </Modal.Header>
                }
                <Modal.Body>
                    <div className="popup-modal-body">
                        <video ref={this.reference}></video>
                    </div>
                </Modal.Body>
            </Modal >
        )
    }
}

export default VideoPopup