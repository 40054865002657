import React from 'react';
import Aux from '../../hoc/Wrapper';
import { withNamespaces } from 'react-i18next';
import './tutor.scss';
// import AvailabilityScheduler from '../../components/Tutor/Scheduler/AvailabilityScheduler';


class TutorAvailability extends React.Component {    
    render() {               
        return (
            <Aux>

                <div className='tutorprofile'>
                    <div className='tutorprofile-right'>
                        {/* <AvailabilityScheduler ></AvailabilityScheduler> */}
                    </div>
                </div>

            </Aux>
        );
    }
}
export default withNamespaces()(TutorAvailability);
