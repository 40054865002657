import React from "react";
import { isNil, isEmpty } from 'lodash';
import "./ProblemStatement.scss";
import logOutlineImg from "../../assets/images/outline-logo.svg";
import { getLatexFromFromProblemStatement, getStrokesToken } from "../canvas/Api";
import CircularProgress from '@mui/material/CircularProgress';
import { getCurrentUserId } from '../../common/Functions';
import { askAnyProblemQuestion } from '../../common/API';
import CkEditorComponent from "../CkEditorComponent/CkEditorComponent";
import { showFullApp } from '../../common/Functions';


class ProblemStatement extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isloader: false,
      textProblemStatement: '',
      placeHolderText: '',
      buttonDisabled: false,
      ckEditor: null,
      editorHeight: '150px',
      showEditor: false
    }
  }

  componentDidMount() {
    this.setState({ isloader: true, buttonDisabled: true })

    let params = new URLSearchParams();
    params.append('InputMessage', 'hello');
    params.append('isNew', true);
    params.append('sessionid', this.props.sessionId);
    params.append('userid', getCurrentUserId());

    askAnyProblemQuestion(params).then(res => {
      this.setState({ isloader: false })
      this.state.placeHolderText = res.data.response
      this.setState({ placeHolderText: res.data.response, buttonDisabled: false })

      this.setState({
        showEditor: true,
        ckEditor: <CkEditorComponent
          ckEditorId="PSeditor1"
          editorHeight={this.state.editorHeight}
          placeHolderText={this.state.placeHolderText}
          onCKEditorDataChange={this.ckEditorDataChangedEvent}
          frompage="problemstatement"
        />
      })

      let editor = document.getElementById("editorInitial")
      editor.style.display = "none"

    }).catch((err) => {
      this.setState({ isloader: false })
      return err;
    })
  }

  submitProblemStatement = () => {
    if (!isEmpty(this.state.textProblemStatement)) {
      if (this.props.isWOP) {
        this.props.AnyProbleemStatementReceived(this.state.textProblemStatement)
      }
      else {
        this.props.onSubmitProblemStatement(this.state.textProblemStatement)
      }
    }
  }

  onCustomeProblemUpload = () => {
    var file = document.querySelector('input[type=file]').files[0];
    var reader = new FileReader();
    this.setState({ isloader: true })
    reader.onloadend = () => {
      this.setState({ problemImage: reader.result });
      this.fetchToken()
        .then(response => {
          if (!isNil(response)) {
            getLatexFromFromProblemStatement(response, reader.result)
              .then(async response => {
                if (!response.data.error) {
                  if (this.props.isWOP) {
                    this.props.AnyProbleemStatementReceived(response.data.html.replace(/<mathml[^>]*>|<\/mathml>/gs, ''))
                  }
                  else {
                    this.props.onSubmitProblemStatement(response.data.html.replace(/<mathml[^>]*>|<\/mathml>/gs, ''));
                  }

                  this.setState({ isloader: false })
                }
              }).catch(err => {
                this.setState({ isloader: false })
                console.log("API Error: ", err);
              })
          }
        }).catch(err => {
          this.setState({ isloader: false })
          console.log("API Error: ", err);
        })
    }
    if (file) {
      reader.readAsDataURL(file);
    }
  }

  fetchToken = async () => {
    return await getStrokesToken();
  }


  ckEditorDataChangedEvent = (changedData) => {
    this.setState({ textProblemStatement: changedData })
  }

  render() {
    // console.log(this.props.frommobile)
    return (
      <div className="px-3">
        {/* <div className="brainOutlineImg">
          <div>
            <p className="mainLineText">
              Empowering Solutions through Your Choice of Options</p>
            <p className="font-16">
              By utilizing the following options, you can help us better understand
              your problem and provide you with the most appropriate assistance or
              solutions.
            </p>
          </div>
          <img src={logOutlineImg} alt="" />
        </div> */}
        <div className="statementBtns">
          {this.state.showEditor ? this.state.ckEditor :
            <CkEditorComponent ckEditorId="editorInitialPS1"
              editorHeight={this.state.editorHeight}
              placeHolderText={this.state.placeHolderText}
              onCKEditorDataChange={this.ckEditorDataChangedEvent}
              frompage="problemstatement"
            />
          }

          {/* <textarea placeholder={this.state.placeHolderText} rows="6"
            onChange={(e) => this.setState({ textProblemStatement: e.target.value })}></textarea> */}
          <div className={`actionBtns ${this.state.buttonDisabled ? ' disabledDiv' : ''}  `}>
            <div className="uploadScanBtnsContainer">

              {/* <button>Upload Your Problem</button> */}
              <div className="uploadScanBtns">
                <div>

                  <div className='uploadImgHolder'>
                    <label for="file-upload" style={{ display: 'flex' }} class="browseImageBtn">
                      Upload Your Problem
                    </label>
                    <input id="file-upload" className='imageUploadInput' type="file" onChange={() => this.onCustomeProblemUpload()} />
                  </div>
                  {showFullApp() ?
                    <button disabled title="Coming soon" style={{ opacity: '0.5' }}>Scan Your Problem</button>
                    : null}
                </div>
                <p className="text-center m-0 font-16 pt-2 ml-2"> - OR -</p>
              </div>
            </div>
            <button onClick={() => this.submitProblemStatement()} className="submitProbBtn">Submit Your Problem</button>
          </div>

        </div>

        {
          this.state.isloader && (
            <CircularProgress
              size={24}
              sx={{
                color: "#164b99",
                position: 'absolute',
                top: '50%',
                left: '50%',
                marginTop: '-12px',
                marginLeft: '-12px',
                zIndex: "9999"
              }}
            />
          )
        }
      </div>


    );
  }
}

export default ProblemStatement;