import React, { useEffect, useState } from 'react';
import './TestAssessment.scss'
import { makeStyles } from '@material-ui/core/styles';
import { Button } from '@mui/material';
import VolunteerActivismOutlinedIcon from '@mui/icons-material/VolunteerActivismOutlined';
import examImg from "../../../assets/images/Exam.jpg";
import Cookies from 'universal-cookie';

const newStyles = makeStyles((theme) => ({
    root: {},
}));
const cookies = new Cookies();

const TestAssessment = (props) => {
    const classes = newStyles();

    const [isGuidedMode, setisGuidedMode] = useState(true)
    const [isExamMode, setisExamMode] = useState(false)
    const [testTitle, settestTitle] = useState("")
    const [testId, settestId] = useState(0)
    const [AttemptedQues, setattemptedQues] = useState(0)

    useEffect(() => {
        settestTitle(cookies.get("practiceTitle"))
    }, [])

    return (
        <>
            <div className='examPaper'>
                <div className='container-fluid'>
                    <div className='container bg-white-with-shadow my-3 my-md-5'>
                        <div className="testTitleDiv">
                            <p className="testTitleText">Assessment For {testTitle}</p>
                            <div className="lineBreak"></div>
                        </div>

                        <div style={{ height: '100%', width: '100%', float: 'left', marginBottom: '50px' }}>
                            <div className="divOverallResult">
                                <div className="divHeader">Overall Test Results</div>
                                <div className="divProgressT">
                                    <div className="divPointsCircle">
                                        <div className="Sharts" style={{ width: 149, height: 149, justifyContent: 'center', alignItems: 'center', display: 'flex' }}>
                                            <div className="ShartsCharts1" style={{ width: 149, height: 149, position: 'relative', flexDirection: 'column', justifyContent: 'flex-start', alignItems: 'flex-start', display: 'flex' }}>
                                                <div className="ElementsOval" style={{ width: 149, height: 149, position: 'relative' }}>
                                                    <div className="Color" style={{ width: 149, height: 149, left: 0, top: 0, position: 'absolute', background: '#E8F5D9' }}></div>
                                                </div>
                                                <div className="divPoints">
                                                    <div className="divScore">60/100</div>
                                                    <div className="divPointText">points</div>
                                                </div>
                                                <div className="Oval" style={{ width: 127.86, height: 149, background: '#76B72E' }}></div>
                                            </div>
                                        </div>
                                        <div className="divPointDesc">You are doing well! But there’s still room for improvement.</div>
                                    </div>
                                    <div className="divProficiencyProgress">
                                        <div className="divTitle">Your Proficiency</div>
                                        <div className="divColorBarBar">
                                            <div className="divLightGreenColorBar"></div>
                                        </div>
                                        <div className="divDarkGreenBar">
                                            <div className="divYourProgresBars"></div>
                                        </div>
                                        <div className="divYourDigits">95%</div>
                                    </div>
                                    <div className="divProficiencyProgress">
                                        <div className="divTitle">Peer Proficiency</div>
                                        <div className="divColorBarBar">
                                            <div className="divLightBlueColorBar"></div>
                                        </div>
                                        <div className="divDarkBlueBar">
                                            <div className="divPeerProgressBar"></div>
                                        </div>
                                        <div className="divPeerDigits">98%</div>
                                    </div>
                                </div>
                            </div>

                            <div className="divProfiencyResult">
                                <div className="divHeader">Your Proficiency Graph</div>
                                <div className="divProgressGraphT">

                                </div>
                            </div>
                        </div>

                        <div style={{ height: '100%', width: '100%', float: 'left', marginBottom: '50px' }}>
                            <div className="divResult">
                                <div className="divHeader">Problem Level Result</div>
                                <div className="divProgressT">

                                </div>
                            </div>
                        </div>


                    </div>
                </div>
            </div >
        </>
    )

}
export default (TestAssessment)