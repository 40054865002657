import React from 'react';
import './NotesColumns.scss';
import { Row, Col } from 'react-bootstrap';
import NotesCard from '../../../components/NotesBookmarks/NotesColumns/NotesCards/NotesCard';
import '../../../assets/css/icons.scss';


export default class Columns extends React.Component {
    render() {
        return (
            <Row className='mb-5 pt-4'>
                <Col lg={4} sm={12} className='border-grey-r'>
                    <div className='title'>
                        {/* <img src={Icon_Note} alt='Notes' /> */}
                        <div className='ai-icon ic-note '></div>
                        My Notes
                    </div>
                    {this.props.data.notes.length > 0 ?
                        this.props.data.notes.map((item, index) => (
                            <NotesCard {...item} key={index} deleteNote={this.props.deleteNote} />
                        ))
                        :
                        <p className='mt-3'>No notes...</p>
                    }
                </Col>
                <Col lg={4} sm={12} className='border-grey-r'>
                    <div className='title mt-md-5 mt-lg-0'>
                        <div className='ai-icon ic-highlight'></div>
                        My Highlights
                    </div>
                    {this.props.data.highlights.length > 0 ?
                        this.props.data.highlights.map((item, index) => (
                            <NotesCard {...item} highlight={true} deleteHighlight={this.props.deleteHighlight} key={index} />
                        ))
                        :
                        <p className='mt-3'>No highlights...</p>
                    }
                </Col>
                <Col lg={4} sm={12}>
                    <div className='title mt-md-5 mt-lg-0'>
                        <div className='ai-icon ic-video'></div>
                        My Video Bookmarks
                    </div>
                    {this.props.data.videoBookmarks.length > 0 ?
                        this.props.data.videoBookmarks.map((item) => {
                            let bookmarks = item.bookmarkedItems
                            if (this.props.sortBy === 'Chronological') {
                                bookmarks.sort((a, b) => { return new Date(a.date) - new Date(b.date) })
                            } else if (this.props.sortBy === 'Reverse Chronological') {
                                bookmarks.sort((a, b) => { return new Date(a.date) - new Date(b.date) }).reverse()
                            } else if (this.props.sortBy === 'Curriculum Sequence') {
                                bookmarks.sort((a, b) => { return new Number(a.time) - new Number(b.time) })
                            }
                            
                            return bookmarks.map((aVideo, index) => (
                                <NotesCard
                                    {...item}
                                    time={aVideo.time}
                                    bookmarkId={aVideo.bookmarkId}
                                    displayTime={aVideo.displayTime}
                                    video={true}
                                    deleteBookmark={this.props.deleteBookmark}
                                    key={index} />
                            ))
                        })
                        :
                        <p className='mt-3'>No video bookmarks...</p>
                    }
                </Col>
            </Row>
        );
    }
}