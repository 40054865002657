import React from 'react';
import './Summary.scss';
import DeleteIcon from '@material-ui/icons/Delete';
import Tooltip from '@material-ui/core/Tooltip';
import { Link } from 'react-router-dom';

export default (props) => {

    let descriptions = [];
    if (props.type === 'highlight') {
        props.description.map(desc => {
            descriptions.push(
                <div className="highlight-content">
                    <Link to={{
                        pathname: `/curriculum-content/${props.link}`,
                        state: { prevPath: ['/notes-bookmarks'], prevNode: ['Notes/Bookmarks'] }
                    }}>
                        <p className='mb-1'>{desc.text}</p>
                        <p className='font-italic'>{desc.inlineNote ? 'Note:' + desc.inlineNote : ''}</p>
                    </Link>
                    <Tooltip title='Delete'>
                        <DeleteIcon color="action" className='delete-highlight' onClick={() => { props.deleteHighlight(desc.highlightId, props.link) }} />
                    </Tooltip>
                </div>)
        });
    } else if (props.type === 'video') {
        let bookmarks = props.bookmarkedItems
        if (props.sortBy === 'Chronological') {
            bookmarks.sort((a, b) => { return new Date(a.date) - new Date(b.date) })
        } else if (props.sortBy === 'Reverse Chronological') {
            bookmarks.sort((a, b) => { return new Date(a.date) - new Date(b.date) }).reverse()
        } else if (props.sortBy === 'Curriculum Sequence') {
            bookmarks.sort((a, b) => { return new Number(a.time) - new Number(b.time) })
        }

        bookmarks.map(desc => {
            descriptions.push(
                <div className='video-content'>
                    <Link to={{
                        pathname: '/curriculum-content/' + props.link,
                        search: "?" + new URLSearchParams({ t: desc.time }).toString(),
                        state: { prevPath: ['/notes-bookmarks'], prevNode: ['Notes/Bookmarks'] }
                    }}>
                        <p>{props.description} at <small>{desc.displayTime}</small></p>
                    </Link>
                    <Tooltip title='Delete'>
                        <DeleteIcon color="action" className='delete-bookmark' onClick={() => { props.deleteBookmark(desc.bookmarkId, props.link) }} />
                    </Tooltip>
                </div>
            )
        });
    } else {
        descriptions.push(<p>{props.description}</p>)
    }

    return (
        <div className='summary'>
            <div className='text'>
                {props.type === 'note' ?
                    <div className='video-content'>
                        <Link to={{
                            pathname: `/curriculum-content/${props.link}`,
                            state: { prevPath: ['/notes-bookmarks'], prevNode: ['Notes/Bookmarks'] }
                        }}>
                            <h3>{props.title}</h3>
                        </Link>
                        <Tooltip title='Delete'>
                            <DeleteIcon color="action" className='delete-bookmark' onClick={() => { props.deleteNote(props.noteId) }} />
                        </Tooltip>
                    </div> :
                    <Link to={{
                        pathname: `/curriculum-content/${props.link}`,
                        state: { prevPath: ['/notes-bookmarks'], prevNode: ['Notes/Bookmarks'] }
                    }}><h3>{props.title}</h3></Link>}
                <p>{props.overview}</p>
                {descriptions.length > 1 ?
                    <ul>
                        {descriptions.map(desc => (<li>{desc}</li>))}
                    </ul>
                    :
                    descriptions.map(desc => desc)
                }
            </div>
        </div>
    );
}