import React from 'react';
import './Radiobutton.scss';

export default (props) => {
    return (
        <label class="radio-container">
            {props.text}
            <input type="radio" name={props.name} value={props.value} checked={props.checked} />
            <span class="checkmark"></span>
        </label>
    );
}
