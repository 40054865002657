import React, { useEffect, useState } from 'react';
import {
    Card,
    CardContent,
    CardMedia,
    Paper, CardActions, Typography
} from "@material-ui/core";
import { getCurrentUserId, formatDate, Problem_source, convertDurationToSeonds, getTimeDifference, getCurrentUserRole, User_Role } from '../../common/Functions';
import { saveAssignmentUserTimer, saveAssgnmentUserStatus, updatePTAutoSubmit } from '../../common/API';

import { makeStyles } from "@material-ui/core/styles";
import Chip from '@mui/material/Chip';
import Stack from '@mui/material/Stack';
import cardmediaTestImage from '../../assets/images/PracticeTestImage.png';

import { Link } from 'react-router-dom';
import TryThisPin from '../Common/TryThisPin/TryThisPin';
import { Row, Col, Button } from 'react-bootstrap';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import parse from 'html-react-parser';
import Dropdown from "react-bootstrap/Dropdown";
import './AssignmentCards.scss'


const useStyles = makeStyles((theme) => ({
    root: {

    },
    cards: {
        width: '100%',
        height: '100%',
        flexDirection: 'column',
        justifyContent: 'flex-start',
        alignItems: 'flex-start',
        display: 'inline-flex',

    },
    paper: {
        alignSelf: 'stretch', height: 535, background: 'white',
        boxShadow: '0px 3px 1px -2px rgba(0, 0, 0, 0.20)', borderRadius: 8, overflow: 'hidden',
        flexDirection: 'column', justifyContent: 'flex-start', alignItems: 'flex-start', display: 'flex'
    },
    cardMedia: {
        alignSelf: 'stretch',
        borderRadius: 4,
        flexDirection: 'column',
        justifyContent: 'flex-start',
        alignItems: 'flex-start',
        display: 'flex'
    },
    mediaImage: {
        width: "100%",
        height: 180,
        // background: 'linear-gradient(0deg, rgba(27, 38, 47, 0.56) 0%, rgba(27, 38, 47, 0.56) 100%)',
        justifyContent: 'flex-end',
        alignItems: 'end',
        display: 'inline-flex',
        padding: "8px",

    },
    guidedChip: {
        color: '#1B262F',
        background: '#F2CEE6 !important',
        cursor: "pointer",
        '&:hover': {
            // opacity: "0.38",
            background: '#F2CEE6 !important',
        },
    },
    instructionChip: {
        color: '#1B262F',
        background: '#CCCCFF',
        cursor: "pointer",
        '&:hover': {
            // opacity: "0.38",
            background: '#CCCCFF !important',
        },
    },
    guidedBox: {
        color: '#1B262F',
        background: '#F2CEE6',
        opacity: "0.70",
        cursor: 'none',
        fontStyle: 'italic',
        borderRadius: '10px',
        textAlign: 'center',
        margin: '2px',
        alignSelf: 'center',
        marginRight: '5px',
        display: 'flex'
    },
    instructionBox: {
        color: '#1B262F',
        background: '#B9E28C',
        opacity: "0.70",
        cursor: 'none',
        width: "120px",
        marginBottom: "10px",
    },
    statusChip: {
        color: '#1B262F',
        background: '#B9E28C',
    },
    contentContainer: {
        height: '100%'
    },
    scoreDiv: {
        alignSelf: 'stretch', color: '#1F7EB1', fontSize: 14, fontFamily: 'Average Sans !important',
        fontWeight: '400 !important', letterSpacing: 0.10, wordWrap: 'break-word',
        marginBottom: "5px"
    },
    chTitle: {
        alignSelf: 'stretch', color: 'rgba(27, 38, 47, 0.87)', fontSize: 20,
        fontFamily: 'Open Sans !important', fontWeight: '600 !important', letterSpacing: 0.15,
        wordWrap: 'break-word',
        marginBottom: "10px",
        display: "flex !important",
        justifyContent: "space-between",
        alignItems: "end"
    },
    chDescription: {
        alignSelf: 'stretch', color: 'rgba(27, 38, 47, 0.60)', fontSize: 18,
        fontFamily: 'Average Sans !important', fontWeight: '400 !important', letterSpacing: 0.15,
        wordWrap: 'break-word',
        //minWidth: 335,
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        display: '-webkit-box',
        WebkitLineClamp: '2',
        WebkitBoxOrient: 'vertical',
        marginBottom: '10px'
    },
    chDate: {
        alignSelf: 'stretch', color: 'rgba(27, 38, 47, 0.87)', fontSize: 20,
        fontFamily: 'Open Sans !important', fontWeight: '400 !important', letterSpacing: 0.15,
        wordWrap: 'break-word',
        marginBottom: "10px"
    },
}));

const CustomToggle = React.forwardRef(({ children, onClick }, ref) => (
    <a style={{ boxShadow: "none", textDecoration: 'none' }}
        href="#" box
        ref={ref}
        onClick={e => {
            e.preventDefault();
            onClick(e);
        }}
    >
        {children}
        <span className="threedots" />
    </a>
));

const AssignmentCards = (props) => {
    const classes = useStyles()

    const [showModalVideo, setshowModalVideo] = useState(false)
    const [whiteBoardVideoDiv, setwhiteBoardVideoDiv] = useState("")





    let isPTDueDateEnded = false
    let stardate = formatDate(props.item.start_date)
    let enddate = formatDate(props.item.end_date)
    if (Date.parse(enddate) < Date.parse(formatDate(Date.now()))) {
        isPTDueDateEnded = true
    }

    useEffect(() => {
        if (props.item.istimed) {
            const interval = setInterval(() => {
                let startEpoch = props.item.epoch
                if (startEpoch > 0 && props.item.challengeStatus === "inprogress") {
                    let totalTime = (props.item.assignment_time * 60)
                    let now = new Date();
                    let currentEpoch = Math.floor(now.getTime() / 1000);
                    let getTimeDiffArray = getTimeDifference(currentEpoch, startEpoch)
                    let currentTimeDiff = getTimeDiffArray.hours + ":" + getTimeDiffArray.minutes + ":" + getTimeDiffArray.seconds
                    if (totalTime < convertDurationToSeonds(currentTimeDiff)) {
                        clearInterval(interval);
                        debugger;
                        /// Update Remaining TimeLapse in DB
                        const reqData1 = {
                            "user_id": getCurrentUserId(),
                            "assignment_id": parseInt(props.item.id),
                            "timelapse": "00:00:00",
                            "epoch": props.item.epoch
                        }

                        saveAssignmentUserTimer(reqData1).then(res => {
                            if (res.data !== undefined && res.data !== null) {
                                /// PT TimeLapse updated successfully..!
                            }
                        })
                        const reqData = {
                            "user_id": getCurrentUserId(),
                            "assignment_id": parseInt(props.item.id),
                            "problem_source": Problem_source.assignment
                        }

                        updatePTAutoSubmit(reqData).then(res => {
                            if (res.data !== undefined && res.data !== null) {
                                props.itemchanged()
                            }
                        })
                    }
                }
            }, 5000)
        }
    }, [])

    const btnGuidedORExamModeClickHandler = () => {
        if (props.item.challengeStatus === "notstarted" || props.item.challengeStatus === "inprogress") {

            if (props.item.challengeStatus === "notstarted") {
                const reqData = {
                    "assignment_id": parseInt(props.item.id),
                    "user_id": getCurrentUserId()
                }

                saveAssgnmentUserStatus(reqData).then(res => {
                    if (res.data !== undefined && res.data !== null) {
                        // Create table entry to indicate Assignment Started
                    }
                })
            }

            if (props.item.istimed) {
                if (props.item.epoch === 0 || props.item.epoch === -1) {
                    let timeLapse = props.item.epoch === 0 ? null : props.item.timelapse

                    let now = new Date();
                    let gmtEpoch = Math.floor(now.getTime() / 1000);
                    const reqData = {
                        "assignment_id": parseInt(props.item.id),
                        "user_id": getCurrentUserId(),
                        "epoch": gmtEpoch,
                        "timelapse": timeLapse
                    }

                    saveAssignmentUserTimer(reqData).then(res => {
                        if (res.data !== undefined && res.data !== null) {
                            // Create/Edit table entry to indicate current epoch/Time lapse entry on start after reset
                        }
                    })
                }
            }
        }

        window.location = "/assignmentdetails/" + props.item.id
    }

    const closeVideoModal = () => {
        setshowModalVideo(false)
    }

    const handleHelpCenterClickEvent = () => {
        setshowModalVideo(true)
        setwhiteBoardVideoDiv(
            <div style={{ whiteSpace: 'pre', marginTop: "10px", color: "#164b99" }}>
                <div id={"view_" + props.item.id} className="examInstMainDiv">
                    {props.item.general_instructions !== undefined && props.item.general_instructions !== "" && props.item.general_instructions !== null ?
                        parse(props.item.general_instructions)
                        :
                        null
                    }
                </div>
            </div>
        )
    }




    return (
        <>


            <Card className={classes.cards}>
                <Paper className={classes.paper} elevation={3}>
                    <CardMedia
                        image={cardmediaTestImage}
                        className={classes.cardMedia}>
                        <div
                            className={classes.mediaImage}>
                            <Chip label={props.item.challengeStatus === "notstarted" ? "Not Started" : (props.item.challengeStatus === "inprogress" ? "In Progress" : "Completed")} className={classes.statusChip} />
                        </div>
                    </CardMedia>
                    <CardContent className={classes.contentContainer}>
                        {props.item.isgraded ?
                            <Typography className={classes.scoreDiv}>Scored {props.item.totalhwscore}/{props.item.totalpoints} </Typography>
                            :
                            null
                        }
                        <Typography className={classes.chTitle}>

                            <div style={{ fontSize: "12px", boxShadow: "none", textDecoration: 'none', fontWeight: "600" }}>
                                <span className="ch-title"> {props.item.title}</span>
                            </div>

                            {
                                (getCurrentUserRole() !== User_Role.Student) ?
                                    (<Dropdown  >
                                        <Dropdown.Toggle as={CustomToggle} />
                                        <Dropdown.Menu size="sm" title="" >
                                            <>
                                                <Dropdown.Item>
                                                    <Link to={"#"} onClick={props.openeditmodeModal}
                                                        style={{ fontSize: "12px", boxShadow: "none", textDecoration: 'none', color: "#1b262fde" }}>
                                                        <i class="fa fa-edit" style={{ fontSize: "12px", boxShadow: "none", textDecoration: 'none', }}></i> Edit Assignment
                                                    </Link>
                                                </Dropdown.Item>
                                                <Dropdown.Item >
                                                    <Link
                                                        to={"#"}
                                                        onClick={props.handledeleteconfirmation}
                                                        style={{ fontSize: "12px", boxShadow: "none", textDecoration: 'none', color: "#1b262fde" }}>
                                                        <i class="fa fa-trash" style={{ fontSize: "12px", boxShadow: "none", textDecoration: 'none', }}></i> Delete Assignment</Link>
                                                </Dropdown.Item>
                                            </>
                                        </Dropdown.Menu>
                                    </Dropdown>) : null
                            }

                        </Typography>
                        <Typography className={classes.chDescription}>
                            {props.item.description}
                        </Typography>
                        {props.item.id === 46 || props.item.id === 215 || props.item.id === 116 ?
                            <TryThisPin showPin /> : null
                        }
                        <Typography className={classes.chDate}>
                            <Row className='no-gutters'>
                                <Col><span >Duration : </span>{stardate} - {enddate}</Col>
                            </Row>
                        </Typography>
                        <Typography className={classes.chDate}>
                            <Row className='no-gutters'>
                                <Col>Assignement Type : {props.item.assignment_type === "quiz" ? "Quiz" : "Homework"}</Col>
                            </Row>
                        </Typography>
                        <Typography>
                            <Row style={{ margin: '5px' }} className='no-gutters'>
                                <div className={classes.guidedBox}>
                                    {props.item.isguided ?
                                        <span style={{ width: '70px' }}>Guided</span>
                                        : null}
                                </div>
                            </Row>
                        </Typography>
                        <Typography>
                            <Row style={{ margin: '5px' }} className='no-gutters'>
                                <div className={classes.guidedBox}>
                                    {props.item.isgraded && parseInt(props.item.total_trials) > 0 ?
                                        <span style={{ width: '70px' }}>Graded</span>
                                        : null}
                                </div>
                                <div className={classes.guidedBox}>
                                    {props.item.isgraded && parseInt(props.item.total_trials) > 0 ?
                                        <span style={{ width: '152px' }}>Check Score Trials : {parseInt(props.item.total_trials)}</span>
                                        : null}
                                </div>
                            </Row>
                        </Typography>
                        <Typography>
                            <Row style={{ margin: '5px' }} className='no-gutters'>
                                <div className={classes.guidedBox}>
                                    {props.item.istimed ?
                                        <span style={{ width: '120px' }}>Time : {parseInt(props.item.assignment_time)} Min.</span>
                                        : null}
                                </div>
                            </Row>
                        </Typography>
                    </CardContent>
                    <CardActions style={{ flexDirection: 'column', flexGrow: '1' }}>
                        <Stack direction="row" spacing={2}>
                            {props.item.challengeStatus === "completed" ?
                                <Link
                                    to={{
                                        pathname: "/assignmentdetails/" + props.item.id,
                                        state: { prevPath: ['/assignmentlist/'], prevNode: 'Assignment' }
                                    }}
                                >
                                    <Chip label="Assessment Summary" className={classes.guidedChip} />
                                </Link>
                                :
                                <Chip disabled={isPTDueDateEnded} label={props.item.challengeStatus === "inprogress" ? "Resume Assignment" : (isPTDueDateEnded ? "Assignment Not Attempted" : "Start Assignment")} onClick={() => btnGuidedORExamModeClickHandler()} className={classes.guidedChip} />
                            }
                            <Chip disabled={
                                props.item.general_instructions !== undefined && props.item.general_instructions !== "" && props.item.general_instructions !== null ? false : true
                            } label="View Instructions" onClick={() => handleHelpCenterClickEvent()} className={classes.instructionChip} />
                        </Stack>
                    </CardActions>
                </Paper>
            </Card >

            <Dialog
                hideBackdrop={false}
                open={showModalVideo}
                onClose={(e) => closeVideoModal(e)}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        {whiteBoardVideoDiv}
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <button style={{
                        width: "150px",
                        height: '100%',
                        paddingLeft: "22px",
                        paddingRight: "22px",
                        paddingTop: "8px",
                        paddingBottom: "8px",
                        background: '#4353B0',
                        boxShadow: '0px 3px 1px -2px rgba(0, 0, 0, 0.20)',
                        borderRadius: "100px",
                        overflow: 'hidden',
                        flexDirection: 'column',
                        justifyContent: 'center',
                        alignItems: 'center',
                        display: 'inline-flex',
                        color: "#fff",
                        '&:hover': {
                            background: '#4353B0 !important',
                        },
                    }} className="dragLabelcancel leftmargin" onClick={(e) => closeVideoModal(e)}>
                        OK
                    </button>
                </DialogActions>
            </Dialog>
        </>
    )

}
export default AssignmentCards