import React from 'react';
import Aux from '../../hoc/Wrapper';
import { Container, Row, Col } from 'react-bootstrap';
import { withNamespaces } from 'react-i18next';
import Textflow from '../../components/SolutionGraph/TextFlow';
import "../../styles/App.scss";
import { getTutorAnswer } from '../../common/API';


class SolutionGraphText extends React.Component {
    constructor(props) {
        super(props);
    }
    state = {
        answerdata: [],
        isloading: false

    }
    componentDidMount() {
        const reqData = { params: { 'ChallengeId': this.props.match.params.challengeId } }
        this.setState({ isloading: true })
        getTutorAnswer(reqData).then(res => {
            if (res.status === 200) {                
                if (res.data.Answers !== undefined && res.data.Answers.length > 0) {
                    this.setState({ answerdata: res.data.Answers, isloading: false })
                }
                else
                {
                    this.setState({ isloading: false })
                }

            }
        })
    }

    render() {
        return (
            <Aux>
                <Container className='mainDiv'>
                    <Row className='challenges'>
                        <div className='challange-right'>
                            <Row>
                                <Col>
                                    <Textflow answerdata={this.state.answerdata}
                                    ></Textflow>
                                </Col>

                            </Row>
                        </div>
                    </Row>
                </Container>

                {/* {this.state.isloading && (
                    <CircularProgress
                        size={24}
                        sx={{
                            color: "#fdbd17",
                            position: 'absolute',
                            top: '50%',
                            left: '50%',
                            marginTop: '-12px',
                            marginLeft: '-12px',
                        }}
                    />
                )
                } */}
            </Aux>
        );
    }
}
export default withNamespaces()(SolutionGraphText);