import React from 'react'
import './VideoThumbnail.scss'
import Aux from '../../../hoc/Wrapper';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import ExpandLessIcon from '@material-ui/icons/ExpandLess';

export default (props) => {
    const [collapsed, setCollapsed] = React.useState(false);
    return (
        <div className="video-thumbnail">
            <div className="video-title d-flex justify-content-between align-items-center flex-grow-1">
                <label>{props.title}{props.type === 'Games' ? <span> - Interactive Game</span> : ''}</label>

                {props.title === 'See It In Action!' ?
                    collapsed ?
                        <ExpandMoreIcon onClick={() => setCollapsed(!collapsed)} /> :
                        <ExpandLessIcon onClick={() => setCollapsed(!collapsed)} />
                    : null}

            </div>

            <div className={`content-holder ${collapsed ? 'hide-content' : 'show-content'}`}>
                {props.display_mode === "external" ?
                    <a href={props.content_link} target='_blank' rel="noopener noreferrer" ><img class="responsiv-img" src={props.thumbnail} alt="" /></a>
                    :
                    props.access_mode === 'iframe' ?
                        <div className='imageContainer' onClick={(event) => props.modelOpen(event,
                            props.title,
                            (<Aux>
                                <iframe src={props.content_link} width="600" height="600" scrolling="no" allowFullScreen title="Practical Learning"></iframe>
                            </Aux>
                            ),
                            props.popup_size)}>
                            <img class="responsiv-img" src={props.thumbnail} alt="" />
                        </div>

                        : null
                }
            </div>
        </div>
    )
}