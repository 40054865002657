import React from 'react';
import './Footer.scss';
import Logo from '../../assets/images/footer-logo.svg';
import { FooterLinks, FooterLink } from './FooterLinks/FooterLinks';

import { withNamespaces } from 'react-i18next';
import i18next from 'i18next';

const Footer = (props) => {

    return (
        <div className='footer' id="div_footer">
            <div className='footer-logo'>
                <img src={Logo} alt='Footer Logo' />
            </div>
            <FooterLinks>
                <FooterLink
                    link='#'
                    text={i18next.t("footer", { returnObjects: 'true' }).contact}
                />
                <FooterLink
                    link='#'
                    text={i18next.t("footer", { returnObjects: 'true' }).privacy}
                />
                <FooterLink
                    link='#'
                    text={i18next.t("footer", { returnObjects: 'true' }).terms}
                />
                <li><span></span></li>
                <li><a href="mailto:support@aiplato.ai">Support</a></li>
            </FooterLinks>
            <div className='copyright-text'>
                {/* {i18next.t("footer",{ returnObjects: 'true'}).copyright} */}
                © {new Date().getFullYear()} aiPlato. All rights reserved
            </div>
        </div>
    );
}


export default withNamespaces()(Footer);