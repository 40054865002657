import React, { Component } from 'react';
import '../CuriosityExplorationLeftPart/CuriosityExplorationLeftPart.scss'
import arrowImage from '../../../../assets/images/icon_arrow.svg';
import linkIcon from '../../../../assets/images/icon_link.svg';
import Ratings from '../../../Common/Ratings/Ratings';
import RatingsHover from '../../../Common/Ratings/RatingsHover';
import WriteReview from '../WriteReview/WriteReview';
import './VideoComponent.scss';
import { withRouter } from 'react-router-dom'
import Modal from '../../../Common/ModalComponent/ModalComponent';

export default withRouter(class VideoComponent extends Component {

    state = {
        showModal: false,
        toggleDescription: true,
        toggleReviewModal: false,
    }

    toggleReviewModalHandler = () => {
        this.setState({
            toggleReviewModal: !this.state.toggleReviewModal
        })
    }

    expandTextOnClick = (event) => {
        this.state.toggleDescription ? event.target.style.transform = 'rotateZ(-180deg)' : event.target.style.transform = ''
        this.setState({
            toggleDescription: !this.state.toggleDescription
        })
    }

    openVideoLinkModal = () => {
        this.setState({ showModal: true })
    }

    closeModal = () => {
        this.setState({ showModal: false })
    }

    clickHandler = (e) => {
        e.preventDefault()
        const el = e.target.closest("a");
        if (el && e.currentTarget.contains(el)) {
            const url = e.target.getAttribute('href')
            this.props.history.push({
                pathname: url,
                state: { prevPath: ['/curiosity-exploration'], prevNode: ['Curiosity Exploration'] }
            })
        }
    }

    render() {
        const modalBody = (
            <div className="video-link-modal">
                <img src='/images/video-01-large.png' alt="" />
            </div>
        );
        const modalTitle = this.props.videoTitle

        return (
            <div className="video-component">
                {this.props.videoDescription ?
                    <WriteReview
                        reviewerName="Jennifer Smith"
                        setVideoDetailsRatings={this.props.setVideoDetailsRatings}
                        explorationId={this.props.explorationId}
                        showModal={this.state.toggleReviewModal}
                        toggleReviewModal={this.toggleReviewModalHandler} />
                    : null}

                <div className="preview-video clearfix">
                    <div className="video-img">
                        <img src={this.props.videoThumbnail} alt="" onClick={this.openVideoLinkModal} />
                    </div>
                    <div className="video-text">
                        <h2>{this.props.videoTitle}</h2>
                        <p className="second">{this.props.videoSubtitle}</p>
                    </div>
                    <div className="rating clearfix rating-hover">
                        <Ratings stars={this.props.ratings || 0} className='main-video-rating' readOnly />
                        <a><img src={arrowImage} alt="" /></a>
                        <RatingsHover
                            explanationStars={this.props.explanationRatings || 0}
                            presentationStars={this.props.presentationRatings || 0}
                            paceRange={this.props.paceRange} />
                    </div>
                </div>

                {this.props.videoDescription ? (<div className={this.state.toggleDescription ? "video-discription setheight" : "video-discription"}
                    onClick={this.clickHandler}
                    onTouchStart={this.clickHandler}>
                    <div>
                        <h3 className="description-title">{this.props.videoTitle}</h3>
                        <img className="description-expand" src={arrowImage} alt="" onClick={this.expandTextOnClick} />
                    </div>
                    {this.props.videoDescription}
                    <div className="rating-button-box clearfix">
                        <button className="mr-2 yellow-btn" onClick={this.toggleReviewModalHandler}>Write a Review</button>
                    </div>
                </div>) : null}

                <Modal
                    show={this.state.showModal}
                    handleClose={this.closeModal}
                    title={modalTitle}
                    body={modalBody}
                    customButton={false} />
            </div>
        )
    }
})

export const SimpleVideoComponent = (props) => {

    return (
        <div className="simple-video-component row">
            <div className="video-thumbnail-container col-3 pr-0" >
                <div className="image-container" style={{ backgroundImage: `url(${props.videoThumbnail})`, height: '100px' }}>
                </div>
            </div>
            <div className="video-details col-9 py-2">
                <div>
                    <h2>{props.videoTitle}</h2>

                    {props.videoLink ?
                        <div className="video-link-container">
                            <a href={props.videoLink} target="_blank" className="video-link">{props.videoLink}</a>
                            <img src={linkIcon} alt="" />
                        </div>
                        :
                        <p className="video-subtitle">{props.videoSubTitle}</p>}
                </div>

                {props.ratings ? <div className='rating clearfix rating-hover' style={{ margin: 0, width: 'fit-content' }}>
                    <Ratings className='main-video-rating' stars={props.ratings} readOnly={true} />
                    <a><img src={arrowImage} alt="" /></a>
                    <RatingsHover
                        explanationStars={props.explanationRatings}
                        presentationStars={props.presentationRatings}
                        paceRange={props.paceRange} />
                </div> : null}
            </div>
        </div>
    )
}
