import React from 'react'
import Box from '@mui/material/Box';
import Collapse from '@mui/material/Collapse';
import IconButton from '@mui/material/IconButton';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import TableRow from '@mui/material/TableRow';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import Ratings from '../Common/Ratings/Ratings';
import Tooltip from '@material-ui/core/Tooltip';
import { Row, Col } from 'react-bootstrap';
import { imgURL } from '../../common/API';
import { styled } from '@mui/material/styles';
import Button from '@mui/material/Button';
import {
    Avatar,
    makeStyles

} from '@material-ui/core';


const useStyles = makeStyles((theme) => ({
    root: {},
    avatar: {
        height: 42,
        width: 42,
        marginRight: theme.spacing(1)
    },
    avatarlarge: {
        height: 150,
        width: 150,
    },
    linkbutton: {
        fontSize: "10px"
    },
}));
const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
        //backgroundColor: theme.palette.common.black,
        //color: theme.palette.common.white,
        padding: "10px",
    },
    [`&.${tableCellClasses.body}`]: {
        fontSize: 12,
        padding: "7px"
    },
}));
const StyledTableRow = styled(TableRow)(({ theme }) => ({
    '&:nth-of-type(odd)': {
        //backgroundColor: theme.palette.action.hover,
    },
    // hide last border
    '&:last-child td, &:last-child th': {
        border: 0,
    },
}));


function Tutoritem(props) {
    const classes = useStyles();
    const { row } = props;
    const [open, setOpen] = React.useState(false);
    return (
        <React.Fragment>
            <StyledTableRow sx={{ '& > *': { borderBottom: 'unset' } }}>
                <StyledTableCell>
                    <IconButton
                        aria-label="expand row"
                        size="small"
                        onClick={() => setOpen(!open)}
                    >
                        {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
                    </IconButton>
                </StyledTableCell>
                <StyledTableCell>
                    <Avatar
                        className={classes.avatar}
                        src={imgURL + row.profile_pic}
                    >
                    </Avatar>
                </StyledTableCell>
                <StyledTableCell component="th" scope="row">
                    {row.name}
                </StyledTableCell>
                <StyledTableCell >
                    {
                        row.availabilitytime === "Now" ?
                            (<Button variant="text" onClick={props.handleconnect} style={{ fontSize: "12px" }}>{row.availabilitytime}</Button>) :
                            (<Button variant="text" onClick={props.handlebookappointment} style={{ fontSize: "12px" }}>{row.availabilitytime}</Button>)
                    }

                </StyledTableCell>
            </StyledTableRow >
            <StyledTableRow >
                <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={6}>
                    <Collapse in={open} timeout="auto" unmountOnExit>
                        <Box sx={{ margin: 1 }}>
                            <div className='tuorporfile-content-card'>
                                <div className='content-card'>
                                    <div className='video-thumb'>
                                        {row.profile_pic.length > 0 ?
                                            <img src={imgURL + row.profile_pic} alt=""
                                                style={{ height: "100px", width: "100px" }}
                                            />
                                            :
                                            <img src='/images/ic-challenge-placeholder.svg' alt=""
                                                style={{ height: "100px", width: "100px" }}
                                            />
                                        }
                                    </div>
                                    <div className='profile-card-text'>
                                        <Tooltip title={row.name}>
                                            <h3>{row.name}</h3>
                                        </Tooltip>
                                        <p>{row.current_profession} {row.education ? <> | {row.education} </> : ''}</p>
                                        <Row>
                                            <Col>
                                                <div className="clearfix rating-hover">
                                                    <Ratings stars={row.overall_rating} className='ratings main-video-rating mr-2' readOnly />
                                                </div>
                                            </Col>
                                        </Row>
                                    </div>
                                </div>
                            </div>
                        </Box>
                    </Collapse>
                </TableCell>
            </StyledTableRow>
        </React.Fragment>
    );
}
export default Tutoritem