import React, { Component } from 'react'
import './ContentOptionsPopup.scss'

class ContentOptionsPopup extends Component {

    constructor(props) {
        super(props);

        this.state = {
            newLeft: 0,
            newTop: 0,
        }

        this.contentOptionsRef = React.createRef();
        this.handleClickOutsideOfContentOptions = this.handleClickOutsideOfContentOptions.bind(this);
    }

    componentDidMount() {
        document.addEventListener('mousedown', this.handleClickOutsideOfContentOptions);
    }

    componentWillUnmount() {
        document.removeEventListener('mousedown', this.handleClickOutsideOfContentOptions);
    }

    handleClickOutsideOfContentOptions(event) {
        if (this.contentOptionsRef && this.contentOptionsRef.current && !this.contentOptionsRef.current.contains(event.target)) {
            this.props.handleClose()
        }
    }

    positionPopover = () => {
        let rect = this.contentOptionsRef.current.getBoundingClientRect()
        let { left, top } = this.props.contentOptionsPosition

        if ((left + rect.width + 15) >= window.innerWidth) { // Out on the right
            let newLeft = left - rect.width - 45
            this.setState({
                newLeft: newLeft,
                newTop: top - rect.height + 30
            })
        } else {
            this.setState({ newTop: top - rect.height - 30 })
        }
    }

    componentDidUpdate(prevProps) {
        if (this.props !== prevProps) {
            this.setState({ newLeft: 0, newTop: 0 })
        }
    }

    render() {
        let { top, left } = this.props.contentOptionsPosition

        if (this.state.newLeft !== 0 || this.state.newTop !== 0) {
            if (this.state.newLeft !== 0) {
                left = this.state.newLeft
            }
            if (this.state.newTop !== 0) {
                top = this.state.newTop
            }
        } else if (this.props.showContentOptions) {
            this.positionPopover()
        }

        return (
            <div className={`content-options-popup ${this.props.showContentOptions ? 'show-content-options-popup' : 'hide-content-options-popup'}`}
                style={{ top, left }}
                ref={this.contentOptionsRef}>
                {this.props.body}
            </div>
        );
    }
}

export default ContentOptionsPopup;