import React from 'react';
import './Search.scss';
import SearchResult from './SearchResult/SearchResult';
import Aux from '../../../hoc/Wrapper';

import search_icon from '../../../assets/images/icon_search.svg';

export default class Search extends React.Component {
    state = {
        placeholder: 'Ask your physics question'
    }

    onFocus = () => {
        this.setState({ placeholder: 'Coming soon...' })
    }

    onBlur = () => {
        this.setState({ placeholder: 'Ask your physics question' })

    }

    render() {
        let mxAutoClass = this.props.mxAuto ? 'mx-auto' : null;
        return (
            <Aux>
                <div className={'search-box input-group ' + mxAutoClass}>
                    <input
                        onFocus={this.onFocus}
                        onBlur={this.onBlur}
                        type='text'
                        className='form-control'
                        readOnly
                        placeholder={this.state.placeholder} />
                    <div className='input-group-append'>
                        <button style={{ outline: 'none' }}>
                            <img src={search_icon} alt="Search" />
                        </button>
                    </div>
                    <SearchResult className={mxAutoClass} />
                </div>             
            </Aux>
        );
    }
}