import React from 'react';
import './Answerboard.scss';
import MathJax from 'react-mathjax2';
import Aux from '../../hoc/Wrapper';
import { Row, Col, Image } from 'react-bootstrap';

class HomeworkReviewDetailNonFRQText extends React.Component {

    render() {
        let converedclassname = "mt-1 align-items-center eqfontText popupSectionTitlemediumlatex"
        let missingconceptclassname = "mt-1 align-items-center eqfontText popupSectionTitlemediumlatex"
        let containerclass = ""
        let spanclass = ""
        if (this.props.item.student_answer.covered_concepts !== undefined && this.props.item.student_answer.covered_concepts !== null && this.props.item.student_answer.covered_concepts.length > 0) {
            converedclassname = converedclassname + " " + "green"
            containerclass = "correctAnswerContainerstudent"
            spanclass = "answer-correct-sign"
        }
        if (this.props.item.student_answer.missing_concepts !== undefined && this.props.item.student_answer.missing_concepts !== null && this.props.item.student_answer.missing_concepts.length > 0) {
            missingconceptclassname = missingconceptclassname + " " + "red"
            // containerclass = "incorrectAnswerContainerstudent"
            // spanclass = "answer-incorrect-sign"
        }
        let converedtopics = []
        let missingconcepts = [];
        if (this.props.item.student_answer.covered_concepts !== undefined && this.props.item.student_answer.covered_concepts !== null && this.props.item.student_answer.covered_concepts.length > 0) {

            this.props.item.student_answer.covered_concepts.forEach((rem) => {
                let data = ""
                data = rem
                let contentdata1 = (
                    <Aux>
                        {
                            (data && data !== null && data !== 'null' && data !== "null" && data != "undefined" && data !== "" && data !== '' && data !== ',' && data !== ",") ?
                                <div className="mb-1 mt-1" dangerouslySetInnerHTML={{ __html: data }}></div>
                                : null
                        }


                    </Aux>
                );
                converedtopics.push(contentdata1)
            })
        }
        if (this.props.item.student_answer.missing_concepts !== undefined && this.props.item.student_answer.missing_concepts !== null && this.props.item.student_answer.missing_concepts.length > 0) {

            this.props.item.student_answer.missing_concepts.forEach((rem) => {
                let data = ""
                data = rem
                let contentdata1 = (
                    <Aux>
                        {
                            (data && data !== null && data !== 'null' && data !== "null" && data != "undefined" && data !== "" && data !== '' && data !== ',' && data !== ",") ?
                                <div className="mb-1 mt-1" dangerouslySetInnerHTML={{ __html: data }}></div>
                                : null
                        }
                    </Aux>
                );
                missingconcepts.push(contentdata1)
            })
        }
        return (
            <>
                <div className={containerclass}>
                    <span className={spanclass}></span>
                    <div className='answer-container'>
                        <div className='d-flex align-items-center justify-content-between pb-3'>
                            <div>
                                <p className='yrResTxt'>Your Response</p>
                                {/* <p>Correct Equation</p> */}
                            </div>
                            {
                                this.props.item.isgraded ? (<div className='pointsContainer'>
                                    <span>Points: </span>
                                    {this.props.item.points}
                                </div>) : null
                            }
                        </div>
                        <div className="feedbackBox popupSectionTitlemediumwithoutborder" style={{ width: "600px", fontSize: "14px" }}>
                            {this.props.item.answer_text}
                        </div>
                        <div>
                            <div className='popupSectionTitlemediumborder popupSectionTitlemedium' style={{ marginTop: "20px" }}>Covered Topics: </div>
                            <div className={converedclassname} style={{ width: "600px" }}>
                                {converedtopics}
                            </div>
                        </div>
                        {
                            missingconcepts !== undefined && missingconcepts !== null && missingconcepts.length > 0 ?
                                (
                                    <>
                                        <div className='popupSectionTitlemediumborder popupSectionTitlemedium' style={{ marginTop: "20px" }}>Missing Concepts: </div>
                                        <div className={missingconceptclassname} style={{ width: "600px" }}>
                                            {missingconcepts}
                                        </div></>) : null
                        }
                        {
                            this.props.item.ta_feedback && this.props.item.ta_feedback !== undefined && this.props.item.ta_feedback !== null ?
                                (
                                    <div className='feedbackBox popupSectionTitlemediumwithoutborder'
                                        style={{ marginTop: "10px" }}>Feedback: {this.props.item.ta_feedback}</div>
                                ) : null
                        }

                    </div>
                </div>
            </>
        )
    }
}
export default React.memo(HomeworkReviewDetailNonFRQText);