import React from 'react';
import './Recognition.scss';
import Modal from '../ModalComponent/ModalComponent';
import Ratings from '../../Common/Ratings/Ratings';
import { Row } from 'react-bootstrap';

class recognition extends React.Component {

    state = {
        showModal: false,
    }

    modalBody = null;
    modalTitle = null;
    showButton = false;

    openModal = (event, modalTitle, modalBody, showButton = false) => {
        this.modalBody = <>
            {modalBody}
            <Row>
                <span className="sample-data-label text-right flex-grow-1">* Sample Data</span>
            </Row>
        </>;
        this.modalTitle = modalTitle;
        this.showButton = showButton;
        this.setState({ showModal: true });
        event.stopPropagation();
    }

    closeModal = () => {
        this.setState({ showModal: false });
    }


    handleOnClick = (event, headingText) => {
        let modalBody = ''
        if (headingText === 'Curiosities Explored') {
            modalBody = (
                <div style={{ width: '600px' }}>
                    <div className="row border-bottom pb-2" >
                        {/* Changed to Ribbon according to the client - meeting on 24th Jul 2020 */}
                        <div className='ribbon-container'>
                            <div className="topReviewer">Top 25% Curiosity Explorer</div>
                        </div>

                    </div>
                    <div className="row">
                        <div className="col-12 my-2">
                            <h2 className="curiosities-title">Curiosities Explored Recently:</h2>
                            <p className="font-14">How is Physics related to Soccer?</p>
                            <p className="font-14">Why is gravitational force of earth not exerted Moon?</p>
                            <p className="font-14">Why we do not hear sound in space? </p>
                        </div>
                    </div>
                </div>
            )
        } else if (headingText === 'Content Reviews Written') {
            modalBody = (
                <div style={{ width: '600px' }}>
                    <div className="row border-bottom">
                        <div className="col-6 mb-2">
                            <div className="popup_count">60</div>
                            <h4 className="text-center ">Content Reviews Contributed by You</h4>
                        </div>
                        <div className="col-6 mb-2">
                            <div className="popup_count">22</div>
                            <h4 className="text-center">Reviews Written by Peers</h4>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-12">
                            <div className="topReviewer-small">Top 10% Reviewer</div>
                        </div>
                    </div>
                    <div className="row border-top pt-2">
                        <div className="col-12">
                            <p className="font-weight-bold">Recent Review</p>
                            <Ratings stars={4} className='ratings' readOnly />
                            <p className="font-italic text-data font-14">The lecture, while clear and detailed, gets dry and slow, especially in 5:00-9:00 minutes.  Needs more visuals, some real-life examples. Check out the cool example from <a href='https://www.walter-fendt.de/html5/phde/inclinedplane_de.htm' target='_blank'>walter-fendt.de</a>.</p>

                        </div>
                    </div>
                </div>
            )
        } else {
            modalBody = (
                <>
                    <div className="row" style={{ width: '600px' }}>
                        <div className="col-12">
                            <h1 className="text-primary">25 </h1>
                            <div className="w3-border">
                                <div className="w3-blue" style={{ width: "25%" }}></div>
                            </div>
                            <h4 className="text-muted mt-2">Peer Questions Answered by You</h4>
                        </div>
                        <div className="col-12 mt-3">
                            <h1 className="text-primary">34</h1>
                            <div className="w3-border">
                                <div className="w3-blue" style={{ width: "34%" }}></div>
                            </div>
                            <h4 className="text-muted mt-2">Your Questions Answered by Peers</h4>
                        </div>
                        <div className="col-12">
                            <hr />
                        </div>
                        <div className="col-12">
                            <h1 className="text-primary">26</h1>
                            <div className="w3-border">
                                <div className="w3-blue" style={{ width: "26%" }}></div>
                            </div>
                            <h4 className="text-muted mt-2 font-14">Average Peers Questions Answered by Students</h4>
                        </div>
                    </div>
                </>)
        }

        this.openModal(
            event,
            headingText,
            modalBody,
            true)
    }

    render() {
        return (
            <>
                <div className='recognition' onClick={(event) => { this.handleOnClick(event, this.props.text) }}>
                    <div className='count'>{this.props.count}</div>
                    <div className='text'>{this.props.text}</div>
                    {this.props.reviewer ? (
                        <div className='recognition-reviewer'>
                            <div className='top-reviewer'>
                                {this.props.reviewer}
                            </div>
                        </div>
                    ) : null}
                </div>
                <Modal
                    show={this.state.showModal}
                    handleClose={this.closeModal}
                    title={this.modalTitle}
                    body={this.modalBody}
                    showButton={this.showButton} />
            </>
        );
    }
}
export default recognition;