import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import './i18n';
import App from './App';
import * as serviceWorker from './serviceWorker';
// Importing the Bootstrap CSS
import 'bootstrap/dist/css/bootstrap.min.css';
import * as Sentry from '@sentry/react';
import { CaptureConsole } from '@sentry/integrations';
import { createStore } from "redux";
import { Provider } from "react-redux";
import VideoInteractionsReducer from './common/VideoInteractionsReducer';
//import dotenv from 'dotenv';

// if (process.env.NODE_ENV === 'development') {
//   dotenv.config({ path: '.env' });
// } else if (process.env.NODE_ENV === 'staging') {
//   dotenv.config({ path: '.env.staging' });
// } else {
//   dotenv.config({ path: '.env.production' });
// }


// if (process.env.NODE_ENV === 'production') {
//   Sentry.init({
//     dsn: "https://9447daa6a92a42048afb07f9ea7e3df8@o442412.ingest.sentry.io/5419420",
//     debug: true,
//     environment: process.env.NODE_ENV === 'production' ? 'staging' : 'development',
//     integrations: [
//       new CaptureConsole({
//         levels: ['error']
//       })
//     ],
//   });
// }

const store = createStore(VideoInteractionsReducer);

ReactDOM.render(
  <React.StrictMode>
    <Provider store={store}>
      <App />
    </Provider>
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
