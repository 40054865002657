import React, { Component } from 'react';
import './UpcomingInteractions.scss'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import ExpandLessIcon from '@material-ui/icons/ExpandLess';
import { toast } from 'react-toastify';
import PlayCircleFilledWhiteIcon from '@material-ui/icons/PlayCircleFilledWhite';
//import { MathpixLoader, MathpixMarkdown } from 'mathpix-markdown-it'
// import MathJax from 'react-mathjax2';
import { connect } from "react-redux";
import { mapStateToProps } from '../../../../../common/VideoInteractionsReducer';
import MathJaxRenderer from '../../../../Common/MathJaxRenderer/MathJaxRenderer';

class UpcomingInteractions extends Component {

    state = {
        questions: this.props.questions || [],
        summativeQuestions: this.props.summativeQuestions || [],
        allItems: this.props.allItems || [],
        collapsed: false,
        filteredItems: this.props.filteredItems || [],
        hasData: undefined,
    }

    videoInterval = null

    componentDidUpdate(prevProps) {
        if (this.props !== prevProps) {
            this.setState({
                questions: this.props.questions || [],
                summativeQuestions: this.props.summativeQuestions || [],
                allItems: this.props.allItems || [],
                filteredItems: this.props.filteredItems || [],
                hasData: (this.props.questions && this.props.questions.length > 0) || (this.props.summativeQuestions && this.props.summativeQuestions.length > 0) ? true : this.state.questions.length > 0 ? true : false
            })
        }
    }


    handleInteractionClick = (interactionObj) => {
        if (this.props.location.state.onUpcomingInteractionClickHandler) {
            this.props.location.state.onUpcomingInteractionClickHandler(interactionObj)
        } else {
            toast('Please wait until the video player starts.', {
                type: toast.TYPE.WARNING,
                position: toast.POSITION.BOTTOM_RIGHT
            })
        }
    }

    getAllInteractionsJSX = () => {
        const getSlide = (time, type, question, obj) => {
            return <div className="slide" onClick={() => this.handleInteractionClick(obj)}>
                <div className={["slide-content", obj.visited ? 'visited' : '',obj.current ? 'current':''].join(' ')}>
                    <PlayCircleFilledWhiteIcon />
                    <span className="time">{time} - </span>
                    <span className="type">{type}</span>
                </div>
                <div className="slide-hover-content">
                {/* <MathJax.Context
                        input='ascii'
                        onError={(MathJax, error) => {
                            console.warn(error);
                            console.log("Encountered a MathJax error, re-attempting a typeset!");
                            MathJax.Hub.Queue(
                                MathJax.Hub.Typeset()
                            );
                        }}
                        script="https://cdnjs.cloudflare.com/ajax/libs/mathjax/2.7.4/MathJax.js?config=default"
                        options={{
                            asciimath2jax: {
                                useMathMLspacing: true,
                                delimiters: [["$$", "$$"]],
                                preview: "none",
                            }
                        }}
                    >
                        <MathJax.Text text={question} />
                    </MathJax.Context>                 */}
                    <MathJaxRenderer math={question}></MathJaxRenderer>
                    {/* <MathpixLoader>
                        <MathpixMarkdown text={question} />
                    </MathpixLoader>  */}
                </div>
            </div>
        }

        if (this.state.filteredItems.length > 0) {
            return this.state.filteredItems.map(item => {
                let type = item.type

                if (type === 'Q') {
                    return item.questions.map(ques => (
                        getSlide(ques.time, ques.type, ques.content, item)
                    ))
                } else {
                    return getSlide(item.time, "Progress Checkpoint (MCQ)", item.question, item)
                }
            })
        } else {
            return <p className='text-center mb-0'>No further interactions match your profile.</p>
        }
    }

    render() {
        return (
            this.state.hasData ?
                <div className="upcoming-interactions-container upcoming_Interactions_tour">
                    <div className="video-title d-flex justify-content-between align-items-center flex-grow-1">
                        <label>Upcoming Interactions</label>

                        {this.state.collapsed ?
                            <ExpandMoreIcon onClick={() => this.setState({ collapsed: !this.state.collapsed })} /> :
                            <ExpandLessIcon onClick={() => this.setState({ collapsed: !this.state.collapsed })} />
                        }
                    </div>

                    <div className={`content ${this.state.collapsed ? 'hide-content' : 'show-content'}`}>
                        {this.getAllInteractionsJSX()}
                    </div>
                </div> : null
        );
    }
}

export default connect(mapStateToProps)(UpcomingInteractions);