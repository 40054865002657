import React from 'react';
import Aux from '../../../../../../hoc/Wrapper';
import { withRouter } from 'react-router-dom';
import Modal from '../../../../../Common/ModalComponent/ModalComponent';
import Button from "../../../../../Common/Button/Button";
import { checkSummativeQuestion } from '../../../../../../common/API'
import { toast } from 'react-toastify';
//import { MathpixLoader, MathpixMarkdown } from 'mathpix-markdown-it'
// import MathJax from 'react-mathjax2';
import { getDisplayTextHolder, getCurrentUserId } from '../../../../../../common/Functions';
import './SummativeQuestion.scss'
import CloseIcon from '@mui/icons-material/Close';
import MathJaxRenderer from '../../../../../Common/MathJaxRenderer/MathJaxRenderer';

class SummativeQuestion extends React.Component {


    state = {
        showModal: false,
        content: null,
        selectedOption: null,
        showAnswer: false,
        totalmcqquestionslength: 0,
        istryagain: 0
    }

    componentDidMount() {
        
        let question = this.props.question;

        let totalmcqdata = this.props.totalquestions.filter(obj => obj.type === "SQ")
        if (totalmcqdata.length > 0) {
            this.setState({ totalmcqquestionslength: totalmcqdata.length })
        }
        let content = (
            <Aux>
                {/* <MathJax.Context
                    input='ascii'
                    onError={(MathJax, error) => {
                        console.warn(error);
                        console.log("Encountered a MathJax error, re-attempting a typeset!");
                        MathJax.Hub.Queue(
                            MathJax.Hub.Typeset()
                        );
                    }}
                    script="https://cdnjs.cloudflare.com/ajax/libs/mathjax/2.7.2/MathJax.js?config=AM_HTMLorMML"
                    options={{
                        asciimath2jax: {
                            useMathMLspacing: true,
                            delimiters: [["$$", "$$"]],
                            preview: "none",
                        }
                    }}
                >
                    <MathJax.Text text={question.question} />
                </MathJax.Context> */}
                <MathJaxRenderer math={question.question}></MathJaxRenderer>
                {/* <MathpixLoader>
                          <MathpixMarkdown text={question.question} />
                      </MathpixLoader>  */}
                <br />
                {question.options.map((opt, index) => (
                    <Aux key={'a' + index}>
                        <div className='d-flex align-items-center py-2'>
                            <input
                                type='radio'
                                name='option'
                                id={'opt' + index}
                                key={'k' + index}
                                value={opt}
                                className='mr-2 summative-option'
                                onChange={(event) => this.onChangeHandler(event)}
                                style={{ cursor: 'pointer' }} />
                            <label htmlFor={'opt' + index} key={'l' + index} style={{ cursor: 'pointer', marginBottom: '2px', lineHeight: '1', textTransform:"none" }}>
                                {/* <MathJax.Context
                                    input='ascii'
                                    onError={(MathJax, error) => {
                                        console.warn(error);
                                        console.log("Encountered a MathJax error, re-attempting a typeset!");
                                        MathJax.Hub.Queue(
                                            MathJax.Hub.Typeset()
                                        );
                                    }}
                                    script="https://cdnjs.cloudflare.com/ajax/libs/mathjax/2.7.2/MathJax.js?config=AM_HTMLorMML"
                                    options={{
                                        asciimath2jax: {
                                            useMathMLspacing: true,
                                            delimiters: [["$$", "$$"]],
                                            preview: "none",
                                        }
                                    }}
                                >
                                    <MathJax.Text text={opt} />
                                </MathJax.Context> */}
                                <MathJaxRenderer math={opt}></MathJaxRenderer>
                                {/* <MathpixLoader>
                          <MathpixMarkdown text={opt} />
                      </MathpixLoader>   */}
                            </label>
                        </div>
                    </Aux>
                ))}
            </Aux>
        );
        this.setState({ content: content });
    }

    modalTitle = null;
    modalBody = null;
    showButton = false;
    customButton = null;
    correctAnsRef = React.createRef();
    continueButtonRef = React.createRef();
    openModal = (title, body, customButton, showButton = false) => {
        this.modalTitle = title;
        this.modalBody = body;
        this.customButton = customButton;
        this.showButton = showButton;
        this.setState({ showModal: true });
    }


    closeModal = () => {
        if (this.props.sourceType === "Video")
            this.continueWithVideo();
        this.setState({ showModal: false });
    }


    tryAgain = () => {
        let allOptions = [...document.getElementsByClassName('summative-option')];
        if (allOptions.length > 0) {
            for (let option of allOptions) {
                option.checked = false;
            }
        }
        this.setState({ selectedOption: null });
        this.setState({ showModal: false, showAnswer: false, istryagain: 1 });
    }

    onChangeHandler = (ev) => {
        this.setState({ selectedOption: ev.target.value });
    }

    continueClickHandler = () => {
        if (this.state.selectedOption === null) {
            toast('Please select an option.', {
                type: toast.TYPE.ERROR,
                position: toast.POSITION.BOTTOM_RIGHT
            })
        } else {
            var params = new URLSearchParams();
            params.append('questionId', this.props.question.questionId);
            params.append('selectedOption', this.state.selectedOption);
            params.append('content_id', this.props.match.params.topicId);
            params.append('total_mcq_question', this.state.totalmcqquestionslength);
            params.append('user_id', getCurrentUserId());
            params.append('istryagain', this.state.istryagain)
            params.append('content_time', this.props.content_time)
            params.append('source_type', this.props.sourceType)

            checkSummativeQuestion(params)
                .then(res => {
                    if (res.data.result === 'correct') {
                        let modalTitle = (
                            <div className='answer-model-correct d-flex justify-content-center'>
                                <span className='correct-answer mr-2'></span>
                                <h4>Correct Answer</h4>
                            </div>);
                        this.openModal(modalTitle, (
                            <div className='d-flex flex-column'>
                                {res.data.correct_answer_explanation ?
                                    <>
                                        <h5>Explanation: </h5>
                                        <p>{res.data.correct_answer_explanation}</p>
                                    </> : null}
                                <Button clicked={this.closeModal} className='mx-auto mt-3 yellow-btn'>Continue</Button>
                            </div>
                        ), false);
                        // this.continueWithVideo();
                    } else {
                        let modalTitle = (
                            <div className='d-flex align-items-center'>
                                <span className='incorrect-answer mr-2'></span>
                                <h4 className='m-0'>This isn’t the correct answer.</h4>
                            </div>);
                        let modalBody = (
                            <Aux>
                                {res.data.remedial_text ?
                                    <div className='mb-2'>
                                        <h5>Hint: </h5>
                                        <p>
                                            {/* <MathJax.Context
                                                input='ascii'
                                                onError={(MathJax, error) => {
                                                    console.warn(error);
                                                    console.log("Encountered a MathJax error, re-attempting a typeset!");
                                                    MathJax.Hub.Queue(
                                                        MathJax.Hub.Typeset()
                                                    );
                                                }}
                                                script="https://cdnjs.cloudflare.com/ajax/libs/mathjax/2.7.2/MathJax.js?config=AM_HTMLorMML"
                                                options={{
                                                    asciimath2jax: {
                                                        useMathMLspacing: true,
                                                        delimiters: [["$$", "$$"]],
                                                        preview: "none",
                                                    }
                                                }}>
                                                <MathJax.Text text={res.data.remedial_text} />
                                            </MathJax.Context> */}
                                            <MathJaxRenderer math={res.data.remedial_text}></MathJaxRenderer>
                                            {/* <MathpixLoader>
                                                <MathpixMarkdown text={res.data.remedial_text} />
                                            </MathpixLoader>   */}
                                        </p>
                                    </div> : null}
                                {res.data.incorrect_answer_explanation ?
                                    <>
                                        <h5>Explanation: </h5>
                                        <p>{res.data.incorrect_answer_explanation}</p>
                                    </> : null}
                                {res.data.remedial_hint ?
                                    <>
                                        <h5>Hint: </h5>
                                        <p>{res.data.remedial_hint}</p>
                                    </> : null}
                                {res.data.incorrect_response_summary ?
                                    <>
                                        <h5>Additional Resources: </h5>
                                        <a href={res.data.incorrect_response_link} target='_blank' rel='noopener noreferrer'><p>{getDisplayTextHolder(res.data.incorrect_response_summary)}</p></a>
                                    </> : null}

                                <div className="mt-2 correct-summative-answer" ref={this.correctAnsRef} style={{ display: 'none' }}>
                                    <h5>Correct Answer: </h5>
                                    {/* <MathJax.Context
                                        input='ascii'
                                        onError={(MathJax, error) => {
                                            console.warn(error);
                                            console.log("Encountered a MathJax error, re-attempting a typeset!");
                                            MathJax.Hub.Queue(
                                                MathJax.Hub.Typeset()
                                            );
                                        }}
                                        script="https://cdnjs.cloudflare.com/ajax/libs/mathjax/2.7.2/MathJax.js?config=AM_HTMLorMML"
                                        options={{
                                            asciimath2jax: {
                                                useMathMLspacing: true,
                                                delimiters: [["$$", "$$"]],
                                                preview: "none",
                                            }
                                        }}>
                                        <MathJax.Text text={res.data.correct_answer} />
                                    </MathJax.Context> */}
                                    <MathJaxRenderer math={res.data.correct_answer}></MathJaxRenderer>
                                    {/* <MathpixLoader>
                                        <MathpixMarkdown text={res.data.correct_answer} />
                                    </MathpixLoader>   */}
                                    {res.data.correct_answer_explanation ?
                                        <>
                                            <h5>Explanation: </h5>
                                            <p>{res.data.correct_answer_explanation}</p>
                                        </> : null}
                                </div>
                            </Aux>
                        );
                        let customButton = (
                            <div className='mx-auto mt-3 d-flex'>
                                <Button clicked={this.tryAgain} className='yellow-btn mr-4'>Try again</Button>
                                <button
                                    onClick={() => {
                                        if (!this.state.showAnswer) {
                                            this.setState({ showAnswer: true })
                                            this.correctAnsRef.current.style.display = 'block'
                                            this.continueButtonRef.current.style.display = 'none'
                                        } else {
                                            this.closeModal()
                                        }
                                    }}
                                    className='yellow-btn'
                                    ref={this.continueButtonRef}>Show Answer</button>
                                <Button clicked={() => this.closeModal()} className='yellow-btn ml-4'>Continue</Button>
                            </div>
                        )
                        this.openModal(modalTitle, modalBody, customButton);
                    }
                }).catch(err => {
                    console.error(err.message);
                    alert("Some error occurred! Please try again later.");
                    if (this.props.sourceType === "Video")
                        this.continueWithVideo();
                    // this.props.history.goBack();
                })
        }
    }

    continueWithVideo = () => {
        this.props.resumeVideo(this.props.currentIndex, true);
    }


    render() {
        let content = "This is some content $$4m/s$$"
        return (
            <Aux>
                <div>

                    <h4><u>Progress Checkpoint</u></h4>
                    {this.state.content ? this.state.content : null}
                    {
                        this.props.sourceType === "Text" ?
                         (
                         
                         <CloseIcon className='closebuttoncss' onClick={this.props.closecheckpoint} />
                         ) : null
                    }

                    <button  onClick={this.continueClickHandler} className='yellow-btn mt-5'>Enter Answer</button>

                </div>
                <Modal
                    show={this.state.showModal}
                    handleClose={this.closeModal}
                    title={this.modalTitle}
                    body={this.modalBody}
                    customButton={this.customButton}
                    showButton={this.showButton} />
            </Aux>
        );
    }
}

export default withRouter(SummativeQuestion);