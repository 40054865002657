import React, { useState } from 'react';
import Ratings from '../Common/Ratings/Ratings';
import {Modal,Button } from 'react-bootstrap';
import Radiobutton from "../Common/Radiobutton/Radiobutton";

function StudentRating() {

    const [showmodal, setShowmodal] = useState(false);

    const handleclosemodal = () => {
        setShowmodal(false)
    }
    const handleshowmodal = () => {
        setShowmodal(true)
    }
    return (
        <>
            <Modal
                show={showmodal} onHide={handleclosemodal}
                size="lg"
                aria-labelledby="contained-modal-title-vcenter"
                centered
            >
                <Modal.Header closeButton>
                    <Modal.Title id="contained-modal-title-vcenter">
                       Detail Feedback
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className='review-modal'>
                        <div className='write-review'>
                            <h2>Overall Rating</h2>
                            <div className='rating'>
                                <Ratings stars={3} className='ratings' />
                            </div>
                        </div>
                        <div className='write-review'>
                            <h2>Clear Explanation</h2>
                            <div className='rating'>
                                <Ratings stars={2} className='ratings' />
                            </div>
                        </div>
                        <div className='write-review'>
                            <h2>Engaging Presentation</h2>
                            <div className='rating'>
                                <Ratings stars={1} className='ratings' />
                            </div>
                        </div>
                        <div className='write-review'>
                            <h2>Well-paced, Fast or Slow</h2>
                            <div className='slide-container'>
                                <input type="range" min="1" max="100" id="myRange" />
                                <label className='slow'>Slow</label>
                                <label className='fast'>Fast</label>
                            </div>
                        </div>
                        <div className='write-review well-paced-checkbox'>
                            <div className='checkbox'>
                                <fieldset >
                                    <div className="abstractReview">
                                        <Radiobutton name="abstractreview" value='Needs Improvement' checked="false" text='Needs Improvement' />
                                        <Radiobutton name="abstractreview" value='Good' checked="false" text='Good' />
                                        <Radiobutton name="abstractreview" value='Very Good' checked="true" text='Very Good' />
                                        <Radiobutton name="abstractreview" value='Exceptional' checked="false" text='Exceptional' />
                                        <Radiobutton name="abstractreview" value='Irresistible' checked="false" text='Irrestible' />
                                    </div>
                                </fieldset>
                            </div>
                        </div>
                        <textarea rows="3" class="review-text" placeholder='Write a review' value="Test Review"></textarea>
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <Button onClick={handleclosemodal} >Close</Button>
                </Modal.Footer>
            </Modal>

            <div className="ratingBox">
                <h4 className="rating-modal-title">Student Ratings</h4>
                <div className="write-review clearfix" onClick={handleshowmodal}>
                    <h2>Sunil</h2>
                    <Ratings className='rating' stars={5} readOnly={true} />
                </div>
                <div className="write-review clearfix" onClick={handleshowmodal}>
                    <h2>Bhargav</h2>
                    <Ratings className='rating' stars={4} readOnly={true} />
                </div>
                <div className="write-review clearfix" onClick={handleshowmodal}>
                    <h2>Khyati</h2>
                    <Ratings className='rating' stars={3} readOnly={true} />
                </div>
                <div className="write-review clearfix" onClick={handleshowmodal}>
                    <h2>Hetal</h2>
                    <Ratings className='rating' stars={2} readOnly={true} />
                </div>
            </div>
        </>

    );
}
export default StudentRating