import React, { useEffect } from "react";
import './Notifications.scss';
import { withNamespaces } from 'react-i18next';
import { withStyles } from '@material-ui/core/styles';
import NotificationsIcon from '@mui/icons-material/Notifications';
// import Tooltip from '@mui/material/Tooltip';
// import BasicMenu from './BasicMenu';
import ClickAwayListener from "@material-ui/core/ClickAwayListener";
import { withRouter } from 'react-router-dom';

import {
    Alert,
    Badge,
    Box,
    IconButton,
    Popper,
    Fade,
    Button,
    Typography,
    Stack
} from "@mui/material";
import MarkChatReadIcon from "@mui/icons-material/MarkChatRead";
import CheckIcon from "@mui/icons-material/Check";
import { getTutornotifications, UpdateReadNotification, UpdateReadNotificationRandom, UpdateAllReadNotification, getStudentnotifications, getTutornotificationsrandom } from '../../../../common/API';
import { getCurrentUserId, getCurrentUserRole, User_Role } from '../../../../common/Functions';
import { useHistory } from 'react-router-dom';
import { toast } from 'react-toastify';


const StyledBadge = withStyles((theme) => ({
    badge: {
        right: 3,
        top: 3,
        border: `2px solid ${theme.palette.background.paper}`,
        padding: '0 4px',
    },
}))(Badge);

const Notifications = (props) => {

    const [open, setOpen] = React.useState(false);
    const [anchorEl, setAnchorEl] = React.useState(null);
    const [notifications, setNotifications] = React.useState([]);
    const [badgecount, setBadgecount] = React.useState(0)
    const history = useHistory();

    // Need to uncomment with better implementation once we enable tutor to assist student on prod.
    // useEffect(() => {
    //     const intervalId = setInterval(() => {
    //         if (getCurrentUserRole() === User_Role.Tutor || getCurrentUserRole() === User_Role.Professor) {
    //             const reqData = { params: { 'tutor_user_id': getCurrentUserId() } }
    //             getTutornotifications(reqData).then(res => {
    //                 if (res.status === 200) {
    //                     //if (res.data.data.length > 0) {
    //                     setNotifications(res.data.data)
    //                     setBadgecount(res.data.data.length)
    //                     //}                       

    //                 }
    //             })
    //             getTutornotificationsrandom(reqData).then(resran => {
    //                 if (resran.status === 200) {
    //                     if (resran.data.data !== undefined && resran.data.data.length > 0) {
    //                         resran.data.data.forEach(element => {
    //                             if (element.tutor_id === null && element.notificationtype === "connectnow") {
    //                                 const reqData1 = {
    //                                     id: element.id,
    //                                     tutor_id: getCurrentUserId(),
    //                                     user_role: getCurrentUserRole()
    //                                 }
    //                                 UpdateReadNotificationRandom(reqData1).then(res => {
    //                                     if (res.status === 200) {
    //                                         toast(`${element.name} wants to connect with you.`, {
    //                                             type: toast.TYPE.INFO,
    //                                             position: toast.POSITION.BOTTOM_RIGHT
    //                                         })
    //                                     }
    //                                 })
    //                             }
    //                         });
    //                     }
    //                 }
    //             })
    //         }
    //         else if (getCurrentUserRole() === User_Role.Student) {
    //             const reqData = { params: { 'student_user_id': getCurrentUserId() } }
    //             getStudentnotifications(reqData).then(res => {
    //                 if (res.status === 200) {
    //                     setNotifications(res.data.data)
    //                     setBadgecount(res.data.data.length)
                       

    //                 }
    //             })
    //         }

    //     }, 2000);
    //     return () => {
    //         clearInterval(intervalId);
    //     };
    // }, []);

    // useEffect(() => {
    //     if (getCurrentUserId() !== undefined) {
    //         const reqData = { params: { 'tutor_user_id': getCurrentUserId() } }
    //         getTutornotifications(reqData).then(res => {
    //             if (res.status === 200) {
    //                 //if (res.data.data.length > 0) {
    //                 setNotifications(res.data.data)
    //                 setBadgecount(res.data.data.length)
    //                 //}

    //             }
    //         })
    //     }

    // }, []);
    const handleOpen = (event) => {
        setAnchorEl(event.currentTarget)
        setOpen(!open);
    };

    const handleClose = () => {
        setOpen(false);
    };
    const handleredirect = async (pagelink, id) => {
        const reqData = {
            id: id,
            user_role: getCurrentUserRole()
        }
        await UpdateReadNotification(reqData).then(res => {
            if (res.status === 200) {
                const newList = notifications.map((item) => {
                    if (item.id === id) {
                        const updatedItem = {
                            ...item,
                            is_notification_read: true,
                        };

                        return updatedItem;
                    }

                    return item;
                });
                setNotifications(newList);

                var url = new URL(pagelink)
                //console.log(url.pathname)
                var newURL = url.href.replace(url.origin, '');
                history.push(newURL);
                setOpen(false);
            }
        })
    }
    const markAsRead = async (id) => {
        const reqData = {
            id: id,
            user_role: getCurrentUserRole()
        }
        await UpdateReadNotification(reqData).then(res => {
            if (res.status === 200) {
                const newList = notifications.map((item) => {
                    if (item.id === id) {
                        const updatedItem = {
                            ...item,
                            is_notification_read: true,
                        };
                        return updatedItem;
                    }
                    return item;
                });
                setNotifications(newList);
                if (notifications.length > 0) {
                    setBadgecount(parseInt(notifications.length - 1))
                }
                else {
                    setBadgecount(0)
                }
            }
        })

    }
    const markAllAsRead = async (id) => {
        const reqData = {
            tutor_user_id: getCurrentUserId(),
            user_role: getCurrentUserRole()
        }
        await UpdateAllReadNotification(reqData).then(res => {
            if (res.status === 200) {
                const newList = notifications.map((item) => {
                    const updatedItem = {
                        ...item,
                        is_notification_read: true,
                    };
                    return updatedItem;
                });
                setNotifications(newList);
                setBadgecount(0)

            }
        })
    }

    return (
        <>
            <ClickAwayListener onClickAway={handleClose}>
                <div className="user-notifications">
                    {/* <Tooltip 
                title={notifications.length ? newNotifications : noNotifications}
                > */}
                    <IconButton
                        onClick={notifications.length ? handleOpen : null}
                        anchorEl={anchorEl}
                    >
                        <StyledBadge
                            badgeContent={badgecount}
                            color="error"
                        >
                            <NotificationsIcon style={{ color: '#fff' }} />
                        </StyledBadge>
                    </IconButton>
                    {/* </Tooltip> */}
                    {/* <BasicMenu
                    open={open}
                    anchorEl={anchorEl}
                    handleClose={handleClose}
                    notificationsdata={notifications}
                /> */}
                    <Box sx={{ margin: "5px" }} onClose={handleClose}>
                        {
                            open ? (
                                <Popper open={open} anchorEl={anchorEl} transition >
                                    {({ TransitionProps }) => (
                                        <Fade {...TransitionProps} timeout={350}>
                                            <Box sx={{ boxShadow: 3 }}>
                                                <Stack
                                                    sx={{
                                                        height: "400px",
                                                        width: "400px",
                                                        padding: "12px",
                                                        background: "#fff",
                                                        borderRadius: "8px",
                                                        overflowY: "auto"
                                                    }}
                                                    spacing={2}
                                                >
                                                    <Box
                                                        sx={{
                                                            background: "transparent",
                                                            display: "flex",
                                                            justifyContent: "center",
                                                            alignItems: "center",
                                                            padding: 0,
                                                            height: "30px",
                                                            borderBottom: "1px solid rgb(209, 209, 209)"
                                                        }}
                                                    >
                                                        <Typography variant="h6" color="#000">
                                                            Notifications
                                                        </Typography>
                                                    </Box>
                                                    <Box
                                                        sx={{
                                                            height: "390px",
                                                            overflowY: "auto"
                                                        }}
                                                    >

                                                        {/* {(!notifications.length ) && (
                                                                <h6>
                                                                    Your queue is empty! you are all set{" "}
                                                                    <span role="img" aria-label="dunno what to put">
                                                                        🎉
                                                                    </span>
                                                                </h6>
                                                            )} */}
                                                        {
                                                            notifications.map((notification) => {
                                                                return (
                                                                    <Alert
                                                                        severity={(notification.type) || "info"}
                                                                        sx={{
                                                                            background: "transparent",
                                                                        }}
                                                                        action={
                                                                            notification.is_notification_read ? (
                                                                                <CheckIcon />
                                                                            ) : (
                                                                                <IconButton
                                                                                    color="primary"
                                                                                    aria-label="upload picture"
                                                                                    component="span"
                                                                                    onClick={() => markAsRead(notification.id)}
                                                                                >
                                                                                    <MarkChatReadIcon />
                                                                                </IconButton>
                                                                            )
                                                                        }
                                                                    >
                                                                        {
                                                                            notification.notificationtype === "connectnow" ? (
                                                                                <a
                                                                                    onClick={() => handleredirect(notification.pageredirect, notification.id)}
                                                                                    href="#"
                                                                                >
                                                                                    <b>{notification.name}</b>  {notification.notification_text}</a>
                                                                            ) : (

                                                                                <> <b>{notification.name}</b>  {notification.notification_text}</>
                                                                            )
                                                                        }

                                                                    </Alert>
                                                                );
                                                            })
                                                        }
                                                    </Box>
                                                    <Box
                                                        sx={{
                                                            //background: "#666",
                                                            //padding: "8px",
                                                            display: "flex",
                                                            justifyContent: "space-between",
                                                            alignItems: "center",
                                                            borderTop: "1px solid rgb(209, 209, 209)"
                                                        }}
                                                    >
                                                        {/* <Button href="#text-buttons"
                                                            onClick={handleclear}
                                                        >
                                                            Clear All
                                                        </Button> */}
                                                        <Button href="#text-buttons"
                                                            onClick={markAllAsRead}
                                                        >
                                                            Mark all as read
                                                        </Button>
                                                    </Box>
                                                </Stack>
                                            </Box>
                                        </Fade>
                                    )}
                                </Popper>

                            ) : null
                        }
                    </Box>
                </div>
            </ClickAwayListener>
        </>
    );
};

export default withNamespaces()(withRouter(Notifications));
