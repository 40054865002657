import React from 'react';
import './IconButton.scss';

export default (props) => {
    return (
        <button className={['btn-with-icon ', props.className ? props.className : ''].join(' ')} disabled={props.disabled} onClick={props.disabled ? null : props.clicked}>  
        <i className={props.icon}></i>      
        {/* <img src={props.image} alt='button' /> */}
        {props.text}</button>
    );
}