import React from "react";
import "./Progress.scss";
import Modal from "../ModalComponent/ModalComponent";
import Aux from "../../../hoc/Wrapper";
//import Table from "react-bootstrap/Table";
import progressData from "../../../data/user.json";
import lastProgress from "../../../assets/images/last-progres.png";
import totalProgress from "../../../assets/images/total-progres.png";
import { Topiccompletion } from '../../../common/API'
import { getCurrentUserRole, User_Role, getCurrentUserId } from '../../../common/Functions';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { styled } from '@mui/material/styles';
import TablePagination from '@mui/material/TablePagination';
import ProgressByTopic from "./ProgressByTopic";

const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
        //backgroundColor: theme.palette.common.black,	
        //color: theme.palette.common.white,	
        padding: "10px",
    },
    [`&.${tableCellClasses.body}`]: {
        fontSize: 12,
        padding: "7px"
    },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
    '&:nth-of-type(odd)': {
        //backgroundColor: theme.palette.action.hover,
    },
    // hide last border
    '&:last-child td, &:last-child th': {
        border: 0,
    },
}));


export default class Progress extends React.Component {
    state = {
        showModal: false,
        topiccompletiondata: [],
        page: 0,
        rowsPerPage: 5,
    };

    modalBody = null;
    modalTitle = null;
    showButton = false;


    componentDidMount() {

        // if (getCurrentUserRole() === User_Role.Student) {
        //     const reqData = {
        //         params: {
        //             user_id: getCurrentUserId()
        //         }
        //     }
        //     Topiccompletion(reqData).then(res => {
        //         if (res.data !== undefined && res.data.data !== undefined) {
        //             this.setState({ topiccompletiondata: res.data.data })
        //         }
        //     })
        // }

    }


    openModal = (event, modalTitle, modalBody, showButton = false) => {
        // if (event) {
        //     event.preventDefault();
        // }
        this.modalBody = (
            <>
                {modalBody}
                {/* <Row>
                    <span className="sample-data-label text-right flex-grow-1">
                        * Sample Data
                    </span>
                </Row> */}
            </>
        );
        this.modalTitle = modalTitle;
        this.showButton = showButton;
        this.setState({ showModal: true });
        event.stopPropagation();
    };

    closeModal = () => {
        this.setState({ showModal: false });
    };

    handleChangePage = (event, page) => {
        this.setState({ page }, () => {
            this.raiseHandClickHandler(event)
        });

    };

    handleChangeRowsPerPage = event => {
        this.setState({ rowsPerPage: event.target.value, page: 0 }, () => {
            this.raiseHandClickHandler(event)
        });


    };

    raiseHandClickHandler = (event) => {

        let listdata = null;
        // if (this.state.topiccompletiondata.length > 0) {
        listdata = <>
            <div style={{ width: '1000px', height: "550px", overflowY: "auto" }}>
                <ProgressByTopic></ProgressByTopic>
                {/* <TableContainer component={Paper}>
                        <Table aria-label="collapsible table">
                            <TableHead>
                                <TableRow>
                                    <StyledTableCell>Recent Topics</StyledTableCell>
                                    <StyledTableCell width="60">%&nbsp;Content Completed</StyledTableCell>
                                    <StyledTableCell width="60">%&nbsp;Challenges Attempted</StyledTableCell>
                                    <StyledTableCell width="60">%&nbsp;Challenges Completed</StyledTableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {
                                    this.state.topiccompletiondata.slice(this.state.page * this.state.rowsPerPage, this.state.page * this.state.rowsPerPage + this.state.rowsPerPage).map((row) => {
                                        return (
                                            <>
                                                <StyledTableRow sx={{ '& > *': { borderBottom: 'unset' } }}>
                                                    <StyledTableCell component="th" scope="row">
                                                        {row.subsectionname}
                                                    </StyledTableCell>
                                                    <StyledTableCell component="th" scope="row">
                                                        {row.contentcompletion}
                                                    </StyledTableCell>
                                                    <StyledTableCell component="th" scope="row">
                                                        {row.challengeattempt}
                                                    </StyledTableCell>
                                                    <StyledTableCell component="th" scope="row">
                                                        {row.challengecompletion}
                                                    </StyledTableCell>

                                                </StyledTableRow>
                                            </>
                                        )
                                    }
                                    )}
                            </TableBody>
                        </Table>
                    </TableContainer>
                    <TablePagination
                        rowsPerPageOptions={[5, 10]}
                        component="div"
                        count={this.state.topiccompletiondata.length}
                        rowsPerPage={this.state.rowsPerPage}
                        page={this.state.page}
                        onPageChange={this.handleChangePage}
                        onRowsPerPageChange={this.handleChangeRowsPerPage}
                    /> */}
            </div>
        </>
        // }
        // else{
        //     listdata = <>
        //     <div style={{color:"lightgrey" , fontSize:"20px"}}>No data</div>
        //     </>
        // }
        this.openModal(
            event,
            "Progress by Topics",
            listdata,
            true)
    }

    render() {


        const viewByWeeks = (
            <div style={{ width: '600px' }}>
                <img
                    src={progressData.progress_by_weeks}
                    alt="Progress by weeks"
                    className="responsive-img"
                />
                <span style={{ float: 'right', marginTop: '5px' }} className="sample-data-label text-right flex-grow-1">* Sample Data</span>
            </div>
        );

        return (
            <Aux>
                <div className="section-icon-text" onClick={this.props.clicked}>
                    <div className="progress-content">
                        <div className="peers-name">
                            <div className="mb-1 font-14">Yours</div>
                            <div className="font-14">Peers</div>
                        </div>
                        <div className="progress-update">
                            <label
                                data-toggle="modal"
                                data-target="#progressUpdate"
                            >
                                Completion
                            </label>
                            <ProgressUpdateBar
                                total={this.props.totalcompletion}
                                lastWeek={7}
                                addBottomMargin
                            />
                            <ProgressUpdateBar total={39} lastWeek={6} />
                        </div>
                    </div>
                    <div className="progress-content">
                        <div className="peers-name">
                            <div className="mb-1 font-14">Yours </div>
                            <div className="font-14">Peers </div>
                        </div>
                        <div className="progress-update">
                            <label
                                data-toggle="modal"
                                data-target="#progressUpdate"
                                className="text-danger"
                            >
                                Proficiency
                            </label>
                            <ProgressUpdateBar
                                total={this.props.totalproficiency}
                                lastWeek={7}
                                addBottomMargin
                                hasRedBackground
                            />
                            <ProgressUpdateBar
                                total={32}
                                lastWeek={5}
                                hasRedBackground
                            />
                        </div>
                    </div>
                    <div className="clearfix">
                        <div className="last-progress">
                            <img src={lastProgress} alt="" />
                            <label className="progres2 text-muted">
                                Last Week
                            </label>
                        </div>
                        <div className="total-progress">
                            <img src={totalProgress} alt="" />
                            <label className="progres1 text-muted">Total</label>
                        </div>
                    </div>
                    <div className="bottom-progress">
                        <div
                            onClick={
                                (event) => this.raiseHandClickHandler(event)
                            }
                        >
                            <a
                                href="#"
                                onClick={(e) => {
                                    e.preventDefault();
                                }}
                                className="progress_tab"
                            >
                                View By Topics
                            </a>
                        </div>
                        <div
                            onClick={(event) =>
                                this.openModal(
                                    event,
                                    "Progress by Week",
                                    viewByWeeks,
                                    true
                                )
                            }
                        >
                            <a
                                href="#"
                                onClick={(e) => {
                                    e.preventDefault();
                                }}
                                className="progress_tab"
                            >
                                View By Weeks
                            </a>
                        </div>
                    </div>
                </div>
                <Modal
                    show={this.state.showModal}
                    handleClose={this.closeModal}
                    title={this.modalTitle}
                    body={this.modalBody}
                    showButton={this.showButton}
                />
            </Aux>
        );
    }
}

const ProgressUpdateBar = (props) => {
    return (
        <div
            className="progress-update-bars"
            style={{ marginBottom: props.addBottomMargin ? "5px" : "0px" }}
        >
            <div class="progress">
                <div
                    role="progressbar"
                    class="progress-bar outer"
                    aria-valuenow={props.total}
                    aria-valuemin="0"
                    aria-valuemax="100"
                    style={{
                        width: `${props.total}%`,
                        backgroundColor: props.hasRedBackground
                            ? "red"
                            : "#3567FC",
                    }}
                >
                    <div
                        role="progressbar"
                        class="progress-bar inner"
                        aria-valuenow={props.lastWeek}
                        aria-valuemin="0"
                        aria-valuemax="100"
                        style={{ width: `${props.lastWeek}%` }}
                    >
                        <span className="inner-label">{props.lastWeek}%</span>
                    </div>
                    <span className="label">{props.total}%</span>
                </div>
            </div>
        </div>
    );
};
