import React from 'react';
import './ListCard.scss';

import Icon_Note from '../../../../assets/images/icon_note.svg';
import Icon_Highlight from '../../../../assets/images/icon_highlight_text.svg';
import Icon_Video from '../../../../assets/images/icon_video.svg';

export default (props) => {
    let img = null;

    switch (props.type) {
        case 'note':
            img = Icon_Note;
            break;
        case 'highlight':
            img = Icon_Highlight;
            break;
        case 'video':
            img = Icon_Video;
            break;
        default:
            break;
    }


    let noteClass = 'notes pl-2';
    noteClass += props.active ? ' active' : ' '
    let descriptions = [];
    if (props.type === 'highlight') {
        props.description.map(desc => {
            descriptions.push(<p>{desc.text}</p>)
        });
    } else {
        descriptions.push(<p>{props.description}</p>)
    }

    return (
        <div className={noteClass} onClick={props.clicked}>
            <div className='chapter-text'>
                <img src={img} alt={props.type} 
                //style={{width:"33px", height:"40px"}}
                />
                <div className="chapter">
                    <div className='text-container'>
                        <h3 className="chp-title">{props.title}</h3>
                        <div className='chp-description'>{descriptions.map(desc => desc)}</div>
                    </div>
                    <div className='d-flex align-items-center'>
                        <p className='date w-100'>{props.bookmarkedItems ? props.bookmarkedItems.length + " Bookmarks": props.date}</p>
                        {props.clarification || props.review ?
                        <p className='review'>{props.review ? 'For Review' : 'For Clarification'}</p> : null}
                    </div>
                </div>
            </div>
        </div>
    );
}