import React from 'react';
import { withRouter } from 'react-router-dom';
import './VideoContent.scss';
import CheckpointContent from './CheckpointContent/CheckpointContent';
import SummativeQuestion from './CheckpointContent/SummativeQuestion/SummativeQuestion';
import Aux from '../../../../hoc/Wrapper';
import CircularProgress from '@material-ui/core/CircularProgress';
import RaiseHandSkipAhead from './RaiseHandSkipAhead/RaiseHandSkipAhead';
import { fetchInteractiveVideo, learningcontentaction, deleteuseractionlearningcontent } from '../../../../common/API';
import { getTryThisPinsEnabledFlag, getCurrentUserId } from '../../../../common/Functions';
import { toast } from 'react-toastify';
import { connect } from "react-redux";
import { mapStateToProps, mapDispatchToProps } from '../../../../common/VideoInteractionsReducer';
import Modal from '../../../Common/ModalComponent/ModalComponent';
import Button from '../../../Common/Button/Button';
import { isNil } from 'lodash';
import Feedback from '../../../Feedback/Feedback';


class VideoContent extends React.Component {


    state = {
        isPlayerReady: false,
        questions: null,
        currentQuestionIndex: null,
        currentQuestion: null,
        summativeQuestions: null,
        raiseHandContent: null,
        currentSummativeQuestion: null,
        currentSummativeQuestionIndex: null,
        resumeUpdated: false,
        allItems: null,
        filteredItems: null,
        showModal: false,
        currentSelectedQuestion: null,
        show: false
    }

    videoInterval = null
    timeToSeek = null;
    pausedVideoByQuestion = false;

    currentTime = null;
    skipAheadSkip = false;
    modalBody = (
        <>
            <div className='text-center modal-content'>
                <div className='iconNotification'>You’ve left unanswered a few questions before this one. Are you sure you would like to skip them?</div>
            </div>
        </>
    );
    customButton = null

    componentDidUpdate(prevProps) {
        if (prevProps.videoId !== this.props.videoId && prevProps.videoContentId !== this.props.videoContentId) {

            let videoId = null;
            this.timeToSeek = null;
            const videoUrl = new URL(this.props.videoId);
            const urlParams = new URLSearchParams(videoUrl.search)
            if (urlParams.get('v')) {
                videoId = urlParams.get('v');
            } else {
                let urlPathNameArray = videoUrl.pathname.split('/');
                if (urlPathNameArray.length >= 2) {
                    videoId = urlPathNameArray[1];
                }
            }

            const reqData = {
                params: {
                    vId: this.props.videoContentId
                }
            }
            fetchInteractiveVideo(reqData)
                .then(res => {
                    this.processResponse(res)

                    if (this.player) {
                        let time2Seek = 1
                        if (urlParams.get('t')) {
                            time2Seek = urlParams.get('t');
                        }

                        const params = new URLSearchParams(window.location.search);
                        try {
                            if (params.get('t')) {
                                time2Seek = Number(params.get('t'));
                                time2Seek = Number(params.get('t'));
                                time2Seek = Number(params.get('t'));
                                time2Seek = Number(params.get('t'));
                                time2Seek = Number(params.get('t'));
                            }

                        } catch (err) {
                            console.error('Error parsing the URL parameters.');
                        }

                        try {
                            this.skipInteractionToTime(time2Seek);

                            this.player.loadVideoById({
                                videoId: videoId,
                                startSeconds: Number(time2Seek)
                            });

                            this.props.location.state = {
                                ...this.props.location.state,
                                'onUpcomingInteractionClickHandler': this.onUpcomingInteractionClickHandler,
                                'videoPlayer': this.player
                            }
                        } catch (e) {
                            console.error(e.message);

                        }

                        this.timeToSeek = time2Seek
                    }
                }).catch(err => {
                    console.error(err.message);
                    alert('Some error occurred! 2')
                    this.props.history.goBack();
                })
        }
    }

    processResponse = (res) => {
        let questions = res.data.data.map(item => {
            return {
                ...item,
                answered: false,
                skipped: false,
                current: false,
                type: 'Q',
            }
        });

        let summativeQuestions = res.data.summative_questions.map(item => {
            return {
                ...item,
                answered: false,
                skipped: false,
                type: 'SQ',
            }
        });

        let raiseHandContent = res.data.raise_hand_content.map(item => {
            return {
                ...item
            }
        });

        let allItems = [...questions, ...summativeQuestions].sort((a, b) => a.interval - b.interval)
        let filteredItems = []

        if (this.props.subPartStartTime !== null && this.props.subPartEndTime !== null) {
            filteredItems = allItems.filter(item => item.interval > this.props.subPartStartTime && item.interval <= this.props.subPartEndTime)
        } else {
            filteredItems = [...allItems]
        }

        this.props.updateInteractions({
            questions,
            summativeQuestions,
            allItems,
            filteredItems,
        })

        this.setState({
            questions: questions,
            summativeQuestions: summativeQuestions,
            raiseHandContent: raiseHandContent,
            allItems,
            filteredItems,
            currentQuestion: null,
            currentQuestionIndex: null,
            currentSummativeQuestionIndex: null,
            currentSummativeQuestion: null
        });
    }

    componentDidMount = () => {
        const reqData1 = {
            content_id: this.props.match.params.topicId,
            user_id: getCurrentUserId(),
        }
        deleteuseractionlearningcontent(reqData1)
            .then(res => {
            })

        if (this.props.location.search.length > 0) {
            const params = new URLSearchParams(this.props.location.search);
            try {
                this.timeToSeek = Number(params.get('t'));
            } catch (err) {
                console.error('Error parsing the URL parameters.');
            }
        }
        const reqData = {
            params: {
                vId: this.props.videoContentId
            }
        }
        fetchInteractiveVideo(reqData)
            .then(res => {
                this.processResponse(res)
            }).catch(err => {
                console.error(err.message);
                alert('Some error occurred! 1')
                this.props.history.goBack();
            })

        if (!this.props.isLimitExceed) {
            if (!window.YT) { // If not, load the script asynchronously
                const tag = document.createElement('script');
                tag.src = 'https://www.youtube.com/iframe_api';

                // onYouTubeIframeAPIReady will load the video after the script is loaded
                window.onYouTubeIframeAPIReady = this.loadVideo;

                const firstScriptTag = document.getElementsByTagName('script')[0];
                firstScriptTag.parentNode.insertBefore(tag, firstScriptTag);

            } else { // If script is already there, load the video directly
                this.loadVideo();
            }
        }

        this.customButton = (<div className='modal-button text-center'>

            <Button clicked={this.skipQuestions} className="mr-2 yellow-btn">Skip</Button>
            <Button clicked={this.closeModal} className="mr-2 grey-btn">Cancel</Button>


        </div>)
    };

    videoheight = '463';


    loadVideo = () => {
        let videoId = null;
        const videoUrl = new URL(this.props.videoId);
        const urlParams = new URLSearchParams(videoUrl.search)
        if (urlParams.get('v')) {
            videoId = urlParams.get('v');
        } else {
            let urlPathNameArray = videoUrl.pathname.split('/');
            if (urlPathNameArray.length >= 2) {
                videoId = urlPathNameArray[1];
            }
        }
        if (urlParams.get('t') && this.timeToSeek === null) {
            this.timeToSeek = urlParams.get('t');
        }
        // the Player object is created uniquely based on the id in props
        this.player = new window.YT.Player(`player`, {
            videoId: videoId,
            height: this.videoheight,
            width: "100%",
            events: {
                onReady: this.onPlayerReady,
                onError: this.onPlayerError,
                onStateChange: this.onPlayerStateChange
            },
            playerVars: {
                fs: 0
            }

        });
        this.setState({ playerLoaded: true })
        this.props.location.state = {
            ...this.props.location.state,
            'onUpcomingInteractionClickHandler': this.onUpcomingInteractionClickHandler,
            'videoPlayer': this.player
        }
    };

    onUpcomingInteractionClickHandler = (interactionObj) => {
        const status = this.getVideoQuestionsStatus()
        let showQuestion = status.showQuestion;
        let showSummativeQuestion = status.showSummativeQuestion;

        if (showQuestion || showSummativeQuestion) {
            toast('Please finish the current ongoing interaction before moving to another.', {
                type: toast.TYPE.WARNING,
                position: toast.POSITION.BOTTOM_RIGHT
            })
        } else {
            let hasSkippedQuestion = false;
            this.setState({ currentSelectedQuestion: interactionObj })
            for (let question of this.state.summativeQuestions) {
                if (question.interval < interactionObj.interval) {
                    if (!(question['skipped'] || question['answered'])) {
                        this.setState({ showModal: true });
                        hasSkippedQuestion = true;
                        break;
                    }
                }

            }
            if (!hasSkippedQuestion) {
                for (let question of this.state.questions) {
                    if (question.interval < interactionObj.interval) {
                        question.skipped = true
                    }

                    if (question.interval === interactionObj.interval) {
                        question.current = true;
                    } else {
                        question.current = false;
                    }
                }
                this.player.seekTo(interactionObj.interval - 1)
                this.player.playVideo();
            }


        }
    }

    skipQuestions = () => {
        var interactionObj = this.state.currentSelectedQuestion;
        for (let question of this.state.questions) {
            if (question.interval < interactionObj.interval) {
                question.skipped = true
            }

            if (question.interval === interactionObj.interval) {
                question.current = true;
            } else {
                question.current = false;
            }
        }
        for (let question of this.state.summativeQuestions) {
            if (question.interval < interactionObj.interval) {
                question.skipped = true;
            }
            if (question.interval === interactionObj.interval) {
                question.current = true;
            } else {
                question.current = false;
            }
        }

        this.player.seekTo(interactionObj.interval - 1)
        this.player.playVideo();
        this.closeModal();
    }
    updateReducer = () => {
        this.props.updateInteractions({
            questions: this.state.questions,
            summativeQuestions: this.state.summativeQuestions,
            allItems: this.state.allItems,
            filteredItems: this.state.filteredItems,
        })

    }
    onPlayerError = (status) => {
        console.error('Error', status.data)
        if (status.data === 5) {
            alert('YouTube: Unable to play video, please disable ad-blocker and refresh the page.')
        }
    }

    onPlayerStateChange = event => {
        //console.log("here..................", event)	
        let actionname = ""
        let totalduration = Number(this.player.getDuration().toFixed(0))
        let currentTime = Number(this.player.getCurrentTime().toFixed(0));
        console.log("time.....", this.player.getCurrentTime())
        if (event.data === 1 && this.pausedVideoByQuestion) {
            this.pausedVideoByQuestion = false;
        }
        if (currentTime > 0) {
            switch (event.data) {
                case 1:
                    actionname = "Play";
                    break;
                case 2:
                    actionname = "Pause"
                    break;
                case 0:
                    actionname = "Ended"
                    break;
            }

            if (actionname === "Ended") {
                this.setState({ show: true })
            }

            if (actionname !== "") {
                let contenttime = currentTime >= 60 ? (currentTime / 60).toFixed(2) : "0." + currentTime
                let totaltime = totalduration >= 60 ? (totalduration / 60).toFixed(2) : "0." + totalduration
                const reqData = {
                    user_id: getCurrentUserId(),
                    instructional_content_id: this.props.match.params.topicId,
                    actionname: actionname,
                    description: "",
                    result: "",
                    content_time: contenttime,
                    totaltime: totaltime

                }
                learningcontentaction(reqData)
                    .then(res => {
                    })
            }
        }
    }

    skipInteractionToTime = (time) => {
        if (!isNil(this.state) && !isNil(this.state.questions)) {
            for (let question of this.state.questions) {
                if (question.interval <= time) {
                    question.answered = 'true';
                }
            }
            let newSummativeQuestions = this.state.summativeQuestions.map(question => {
                if (question.interval <= Number(time)) {
                    question.answered = 'true';
                }
                return question;
            });
        }

    }

    onPlayerReady = event => {
        if (this.props.location.search.length > 0) {
            const params = new URLSearchParams(this.props.location.search);
            try {
                this.timeToSeek = Number(params.get('t'));
            } catch (err) {
                console.error('Error parsing the URL parameters.');
            }
        }

        this.setState({ isPlayerReady: true })
        this.skipInteractionToTime(this.timeToSeek);
        event.target.seekTo(this.timeToSeek ? this.timeToSeek : 0);
        this.currentTime = this.timeToSeek ? this.timeToSeek : 0;

        // Skip questions before the current time.
        if (!isNil(this.state) && !isNil(this.state.questions)) {
            for (let question of this.state.questions) {
                if (question.interval <= this.currentTime) {
                    question.skipped = true
                    question.answered = true;
                }
            }
        }

        if (!isNil(this.state) && !isNil(this.state.summativeQuestions)) {
            for (let question of this.state.summativeQuestions) {
                if (question.interval <= this.currentTime) {
                    question.answered = true;
                    question.skipped = true
                }
            }
        }


        event.target.playVideo();
        this.videoInterval = setInterval(this.monitorBySeconds, 500);
    };

    changeSkipAheadSkip = (value) => {
        this.skipAheadSkip = value;
    }

    youtubeSeekHandler = () => {
        let currentTime = Number(this.player.getCurrentTime().toFixed(0));
        let seekBias = 2
        let priorCondition = ((currentTime - this.currentTime) > seekBias)
        let isAnyItemOverridden = false

        for (let question of this.state.questions) {
            if (priorCondition && question.interval <= currentTime) {
                for (let ques of question.questions) {
                    if (getTryThisPinsEnabledFlag()) {
                        if (!ques.showDuringManualFastForward && ques.type !== 'EndOfSection') {
                            question.skipped = true;
                            break;
                        }
                    } else {
                        if (ques.type !== 'EndOfSection') {
                            question.skipped = true
                            break
                        }
                    }
                }
            } else if (this.currentTime - currentTime > seekBias && question.interval > currentTime && currentTime !== 0) {
                // Reset when going backwards
                if (question.skipped || question.answered) {
                    question.visited = question.answered ? true : false;
                    question.skipped = false;
                    question.answered = false;
                    question.overridden = true;
                    isAnyItemOverridden = true;

                    console.log('override question', this.currentTime, currentTime, question.interval);
                }
            }
        }

        for (let question of this.state.summativeQuestions) {
            if (priorCondition && question.interval <= currentTime) {
                if (!getTryThisPinsEnabledFlag()) {
                    question.skipped = true
                }
            } else if (this.currentTime - currentTime > seekBias && question.interval > currentTime && currentTime !== 0) {
                // Reset when going backwards
                if (question.skipped || question.answered) {
                    question.visited = question.answered ? true : false;
                    question.skipped = false;
                    question.answered = false;
                    question.overridden = true;
                    isAnyItemOverridden = true;

                    console.log('override summativeQuestion', this.currentTime, currentTime, question.interval);
                }
            }
        }

        // recalculate filteredItems
        if (isAnyItemOverridden) {
            let filteredItems = []

            if (this.props.subPartStartTime !== null && this.props.subPartEndTime !== null) {
                filteredItems = this.state.allItems.filter(item => item.interval > this.props.subPartStartTime && item.interval <= this.props.subPartEndTime)
            } else {
                filteredItems = [...this.state.allItems]
            }

            // filteredItems = filteredItems.filter(item => item.interval >= currentTime)
            this.setState({ filteredItems }, this.updateReducer)
        }
    }

    monitorBySeconds = () => {
        try {
            if (!this.skipAheadSkip) {
                this.youtubeSeekHandler();
            } else {
                this.changeSkipAheadSkip(false);
            }
            this.currentTime = Number(this.player.getCurrentTime().toFixed(0));
            if (this.player.getPlayerState() === 1) {
                let hasContent = false;
                let cQ = null;
                let cQI = null;

                let cSQ = null;
                let cSQI = null;

                this.state.questions.map((question, index) => {
                    if (question['interval'] <= Number(this.player.getCurrentTime().toFixed(0))) {
                        if (!(question['skipped'] || question['answered']) && !hasContent) {
                            cQ = question;
                            cQI = index;
                            // this.setState({ currentQuestion: question, currentQuestionIndex: index })
                            this.player.pauseVideo();
                            hasContent = true;
                        }
                    }
                });

                let hasSummativeQuestion = false;
                this.state.summativeQuestions.map((question, index) => {
                    if (question['interval'] <= Number(this.player.getCurrentTime().toFixed(0))) {
                        if (!(question['skipped'] || question['answered']) && !hasSummativeQuestion) {
                            cSQ = question;
                            cSQI = index;
                            // this.setState({ currentSummativeQuestion: question, currentSummativeQuestionIndex: index })
                            this.player.pauseVideo();
                            hasSummativeQuestion = true;
                        }
                    }
                });

                let filteredItems = [...this.state.filteredItems]
                // filteredItems = filteredItems.filter(item => item.interval >= Number(this.player.getCurrentTime().toFixed(0)));
                this.setState({ filteredItems }, this.updateReducer)

                if (cQ || cQI || cSQ || cSQI) {
                    this.setState({
                        currentQuestion: cQ,
                        currentQuestionIndex: cQI,
                        currentSummativeQuestion: cSQ,
                        currentSummativeQuestionIndex: cSQI,
                        resumeUpdated: false,
                    })
                }
            }
        } catch {

        }

    }

    resumeVideo = (questionIndex, isSummative = false, pauseVideo = false, targetTimeStamp = undefined) => {

        if (isSummative) {
            let summativeQuestions = [...this.state.summativeQuestions];
            summativeQuestions[questionIndex]['answered'] = true;
            this.setState({
                summativeQuestions: summativeQuestions,
                currentSummativeQuestion: null,
                currentSummativeQuestionIndex: null,
                resumeUpdated: true
            });
        } else {
            let questions = [...this.state.questions];
            questions[questionIndex]['answered'] = true;
            this.setState({
                questions: questions,
                currentQuestion: null,
                currentQuestionIndex: null,
                resumeUpdated: true
            });
        }
        if (!pauseVideo) {
            this.player.playVideo();
        } else {
            this.pausedVideoByQuestion = true;
        }

        if (targetTimeStamp) { // Set the EndOfSection timestamp if pressed NO
            for (let question of this.state.questions) {
                if (question.interval <= targetTimeStamp) {
                    question.skipped = true
                    question.answered = true;
                } else {
                    question.skipped = false
                    question.answered = false;
                }
            }
            this.player.seekTo(targetTimeStamp)
        }
    }

    getVideoQuestionsStatus = () => {
        let showQuestion = false;
        let showSummativeQuestion = false;

        if (this.state.currentSummativeQuestion && this.state.currentQuestion) {
            if (this.state.currentQuestion.interval > this.state.currentSummativeQuestion.interval) {
                showSummativeQuestion = true;
            } else {
                showQuestion = true;
            }
        } else if (this.state.currentQuestion && this.state.currentSummativeQuestion === null) {
            showQuestion = true;
        } else if (this.state.currentSummativeQuestion && this.state.currentQuestion === null) {
            showSummativeQuestion = true;
        }

        return {
            showQuestion,
            showSummativeQuestion
        }
    }

    closeModal = () => {
        this.setState({ showModal: false, currentSelectedQuestion: null });
    }

    handleClose = () => {
        this.setState({ show: false });
    }

    render() {
        const status = this.getVideoQuestionsStatus()
        let showQuestion = status.showQuestion;
        let showSummativeQuestion = status.showSummativeQuestion;

        return (
            <Aux>

                <div class='video-YT mt-3'>
                    <div id="player"></div>
                    {!this.pausedVideoByQuestion ?
                        <div id="questionsContiner"
                            style={{
                                padding: '20px',
                                width: '100%',
                                height: '463px',
                                background: 'white',
                                position: 'absolute',
                                top: 0,
                                opacity: 0.9,
                                visibility: this.state.currentQuestionIndex !== null || this.state.currentSummativeQuestionIndex !== null ? 'visible' : 'hidden',
                                overflow: 'auto'
                            }}>

                            {showQuestion && !showSummativeQuestion ?
                                <Aux>
                                    {this.state.currentQuestion.questions[0].time ?
                                        <div className='d-flex'>
                                            <p className="checkpoint-timestamp">Time: {this.state.currentQuestion.questions[0].time}</p>
                                            <p className="ml-2 checkpoint-timestamp"> - {this.state.currentQuestion.questions[0].type}</p>
                                        </div>
                                        : null}

                                    <CheckpointContent
                                        question={this.state.currentQuestion}
                                        currentIndex={this.state.currentQuestionIndex}
                                        resumeVideo={this.resumeVideo} />
                                </Aux>
                                : null}

                            {showSummativeQuestion && !showQuestion && !this.state.resumeUpdated ?
                                <Aux>
                                    {this.state.currentSummativeQuestion.time ?
                                        <p className="checkpoint-timestamp">Time : {this.state.currentSummativeQuestion.time}</p>
                                        : null}

                                    <SummativeQuestion
                                        question={this.state.currentSummativeQuestion}
                                        currentIndex={this.state.currentSummativeQuestionIndex}
                                        resumeVideo={this.resumeVideo}
                                        totalquestions={this.state.filteredItems}
                                        sourceType="Video"
                                        content_time={Number(this.player.getDuration().toFixed(0))}
                                    />
                                </Aux>

                                : null}
                        </div>
                        : null}
                </div>
                {this.state.playerLoaded && this.state.raiseHandContent ?
                    <RaiseHandSkipAhead
                        call_type={this.props.call_type}
                        isTeacher={this.props.isTeacher}
                        player={this.player}
                        isPlayerReady={this.state.isPlayerReady}
                        content={this.state.raiseHandContent}
                        filteredItems={this.state.filteredItems}
                        questions={this.state.questions}
                        videoContentId={this.props.videoContentId}
                        summativeQuestions={this.state.summativeQuestions}
                        hasQuestion={(this.state.currentQuestionIndex !== null || this.state.currentSummativeQuestionIndex !== null) && !this.pausedVideoByQuestion ? true : false}
                        changeSkipAheadSkip={this.changeSkipAheadSkip}
                        Content_type={this.props.Content_type}
                        SubSection_id={this.props.SubSection_id}
                        toggleVC={this.props.toggleVC}
                        raisebuttondisabled={this.props.raisebuttondisabled}
                        isLimitExceed={this.props.isLimitExceed}
                    />
                    :
                    <div style={{ height: this.videoheight + 'px' }}
                        className='circular-loading d-flex justify-content-center align-items-center'>
                        <CircularProgress
                            size='62px'
                            color='inherit'
                        />
                    </div>
                }

                <Modal
                    show={this.state.show}
                    handleClose={this.handleClose}
                    title="Submit Feedback"
                    body={<Feedback />}
                    draggable={true} />

                <Modal
                    show={this.state.showModal}
                    handleClose={this.closeModal}
                    title=""
                    body={this.modalBody}
                    customButton={this.customButton}
                    draggable={false} />
            </Aux>
        );
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(VideoContent));
