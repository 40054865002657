import React, { Component } from 'react';
import './CuriosityExplorationLeftPart.scss'
import VideoComponent, { SimpleVideoComponent } from '../VideoComponent/VideoComponent'
import data from '../../../../data/CuriosityExplorationLeftPart.json'
import { withRouter } from 'react-router-dom'
import { fetchCuriosityExplorationContent } from '../../../../common/API';

class CuriosityExplorationLeftPart extends Component {

    state = {
        trustedWebsites: [],
        googleSearches: [],
        videoDetails: {},
        explorationId: 1   // TODO: Change it according to screen when multiple data added
    }

    contentClickHandler = (e) => {
        const targetLink = e.target.closest('a');
        if (!targetLink) return;
        e.preventDefault();

        this.props.history.push(e.target.getAttribute("href"))
    }

    componentDidMount() {
        const reqData = {
            params: {
                explorationId: this.state.explorationId
            }
        }
        fetchCuriosityExplorationContent(reqData)
            .then(res => {
                this.setState({
                    trustedWebsites: data.trustedWebsites,
                    googleSearches: data.googleSearches,
                    videoDetails: {
                        ...res.data,
                        videoDescription: <div onClick={this.contentClickHandler} dangerouslySetInnerHTML={{ __html: res.data.videoDescription }}></div>
                    }
                })
            }).catch(err => {
                console.error(err.message);
                alert('Some error occurred!');
                this.props.history.goBack();
            })
    }

    setVideoDetailsRatings = (newRatings) => {
        this.setState({
            videoDetails: { ...this.state.videoDetails, ...newRatings }
        })
    }

    render() {
        return (
            <>
                <div className="content">
                    <div className="curriculum-title">
                        <p className="first">Have you ever wondered...</p>
                        <h1 className="second">How does a plane fly?</h1>
                    </div>

                    <p className="component-title">Teacher's Recommendation</p>
                    <VideoComponent {...this.state.videoDetails}
                        explorationId={this.state.explorationId}
                        setVideoDetailsRatings={this.setVideoDetailsRatings} />

                    <div style={{ borderBottom: '1px solid #BBBBBB' }}>
                        <p className="component-title">Websites we Trust</p>
                        {this.state.trustedWebsites.map(website =>
                            <SimpleVideoComponent {...website} key={website.id} />)}
                    </div>

                    <div style={{ marginBottom: '20px' }}>
                        <p className="component-title">Google Searches</p>
                        {this.state.googleSearches.map(website =>
                            <SimpleVideoComponent {...website} key={website.id} />)}
                    </div>
                </div>
            </>
        )
    }
}

export default withRouter(CuriosityExplorationLeftPart);