import React from 'react'
import './TextbookHint.scss'

class TextbookHint extends React.Component {

    render() {
        let { top, left } = this.props.hintPosition
        let hintContent = this.props.hintObject ?
            "<p class='mb-1 font-weight-bold'>Teacher’s comment:</p>" + this.props.hintObject.hintDetail : ''

        return (
            <div className={`textbook-hint ${this.props.showHint ? 'show-hint' : 'hidden-hint'}`}
                style={{ top, left }}>
                <div className="hint-content">
                    <p dangerouslySetInnerHTML={{ __html: hintContent }}>
                    </p>
                </div>
            </div>
        )
    }
}

export default TextbookHint