// import React, { Component } from 'react';
// import './Navlinks.scss';

// class Navlinks extends Component {
//     constructor(props) {
//         super(props);
//         this.state = {
//             isNavExpanded: false
//         };
//     }

//     toggleNav = () => {
//         this.setState(prevState => ({
//             isNavExpanded: !prevState.isNavExpanded
//         }));
//     }

//     render() {
//         const { isNavExpanded } = this.state;

//         return (
//             <div>
//                 <button className='hamburger' onClick={this.toggleNav}>H</button>
//                 <ul className={isNavExpanded ? "navlinks expanded" : "navlinks"}>
//                     {this.props.children}
//                 </ul>
//             </div>
//         );
//     }
// }

// export default Navlinks;



import React, { Component } from 'react';
import './Navlinks.scss';

class Navlinks extends Component {
    constructor(props) {
        super(props);
        this.state = {
            isNavExpanded: false
        };
    }

    componentDidUpdate(prevProps){
        if(this.props.isExpanded !== prevProps.isExpanded){
            this.setState({isNavExpanded: this.props.isExpanded});
        }
    }
    toggleNav = () => {
        this.props.onMenuClick(!this.state.isNavExpanded)
        this.setState(prevState => ({
            isNavExpanded: !prevState.isNavExpanded
        }));
    }

    render() {
        const { isNavExpanded } = this.state;

        return (
            <div>
                <button
                    className={isNavExpanded ? 'hamburger closeHamburger' : 'hamburger'}
                    onClick={this.toggleNav}
                >
                    
                </button>
                <ul className={isNavExpanded ? 'navlinks expanded' : 'navlinks'}>
                    {this.props.children}
                </ul>
            </div>
        );
    }
}

export default Navlinks;

