import React from 'react';
import './Challenge.scss';
import { Link } from 'react-router-dom';

import { withNamespaces } from 'react-i18next';
import { getRoutes } from '../../../common/Functions';
import {  imgURL } from '../../../common/API';


const CarouselChallenge = (props) => {
    return (
        <Link className='instructional-content-challenge'
            to={{
                pathname: props.link,
                state: {
                    prevPath: getRoutes(props.prevPath, window.location.pathname),
                    prevNode: getRoutes(props.prevNode, props.currentNodeTitle)
                }
            }}>
            <p className='challenge-title'></p>
            <div>
                <div className='video-thumb'>
                    <a>
                        <img src={imgURL + props.image} alt={props.title}  style={{fontSize:"13px"}}/>
                    </a>
                </div>
                <div className='challenge-text'>
                    <h3>{props.title}</h3>
                    <div className='descriptiontext' dangerouslySetInnerHTML={{ __html: props.description }}></div>
                </div>
            </div>
        </Link>
    );
}

export default withNamespaces()(CarouselChallenge);