import React from 'react';
import './List.scss';
import { Row, Col } from 'react-bootstrap';
import ListCard from '../../../components/NotesBookmarks/List/ListCard/ListCard';
import Summary from '../../../components/NotesBookmarks/List/Summary/Summary';

export default class List extends React.Component {

    state = {
        activeIndex: 0
    }

    listcardClickHandler = (index) => {
        this.setState({ activeIndex: index });
    }

    render() {
        return (
            <Row className='pt-4'>
                <Col lg={8} sm={12} className='border-grey-r'>
                    <div className='headertitle'> All Notes/Bookmarks </div>
                    {this.props.data.length > 0 ?
                        this.props.data.map((item, index) => (
                            <ListCard {...item} active={index === this.state.activeIndex ? true : false} key={index} clicked={() => this.listcardClickHandler(index)} />
                        ))
                        :
                        <p className='mt-3'>No notes, bookmarks or highlights...</p>
                    }
                </Col>
                <Col lg={4} sm={12} className="rightpanel">
                    <div className='headertitle mt-md-5 mt-lg-0'> Notes/Bookmarks Summary </div>
                    {this.props.data.length > 0 ?
                        <Summary
                            sortBy={this.props.sortBy}
                            {...this.props.data[this.state.activeIndex]}
                            deleteBookmark={this.props.deleteBookmark}
                            deleteHighlight={this.props.deleteHighlight}
                            deleteNote={this.props.deleteNote} />
                        : null}
                </Col>
            </Row>
        );
    }
}