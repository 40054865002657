import React, { useEffect } from 'react';
import {
    Card,
    CardContent,
    CardMedia,
    Paper, CardActions, Typography
} from "@material-ui/core";
import { getInstituteId, getCurrentUserId, getTimeDifference, convertDurationToSeonds, Problem_source, formatDate } from '../../common/Functions';
import { Institute, halfTestMarksLimit, fullPTTimeInString, halfPTTimeInString } from '../../common/Constants'
import { savepttimer, updatePTAutoSubmit, PTTimerSubmitUpdate } from '../../common/API';
import { makeStyles } from "@material-ui/core/styles";
import Chip from '@mui/material/Chip';
import Stack from '@mui/material/Stack';
import Cookies from 'universal-cookie';
import cardmediaTestImage from '../../assets/images/PracticeTestImage.png';
import { Link } from 'react-router-dom';
import TryThisPin from '../Common/TryThisPin/TryThisPin';
import { useHistory } from 'react-router-dom';
import { Row, Col } from 'react-bootstrap';

const useStyles = makeStyles((theme) => ({
    root: {

    },
    cards: {
        width: '100%',
        height: '100%',
        flexDirection: 'column',
        justifyContent: 'flex-start',
        alignItems: 'flex-start',
        display: 'inline-flex',

    },
    paper: {
        alignSelf: 'stretch', height: 411, background: 'white',
        boxShadow: '0px 3px 1px -2px rgba(0, 0, 0, 0.20)', borderRadius: 8, overflow: 'hidden',
        flexDirection: 'column', justifyContent: 'flex-start', alignItems: 'flex-start', display: 'flex'
    },
    cardMedia: {
        alignSelf: 'stretch',
        borderRadius: 4,
        flexDirection: 'column',
        justifyContent: 'flex-start',
        alignItems: 'flex-start',
        display: 'flex'
    },
    mediaImage: {
        width: "100%",
        height: 180,
        // background: 'linear-gradient(0deg, rgba(27, 38, 47, 0.56) 0%, rgba(27, 38, 47, 0.56) 100%)',
        justifyContent: 'flex-end',
        alignItems: 'end',
        display: 'inline-flex',
        padding: "8px",

    },
    guidedChip: {
        color: '#1B262F',
        background: '#F2CEE6 !important',
        cursor: "pointer",
        '&:hover': {
            // opacity: "0.38",
            background: '#F2CEE6 !important',
        },
    },
    examChip: {
        color: '#1B262F',
        background: '#F2CEE6',
        opacity: "0.38",
        cursor: "pointer"
    },
    statusChip: {
        color: '#1B262F',
        background: '#B9E28C',
    },
    contentContainer: {
        height: '100%'
    },
    scoreDiv: {
        alignSelf: 'stretch', color: '#1F7EB1', fontSize: 14, fontFamily: 'Average Sans !important',
        fontWeight: '400 !important', letterSpacing: 0.10, wordWrap: 'break-word',
        marginBottom: "5px"
    },
    chTitle: {
        alignSelf: 'stretch', color: 'rgba(27, 38, 47, 0.87)', fontSize: 20,
        fontFamily: 'Open Sans !important', fontWeight: '600 !important', letterSpacing: 0.15,
        wordWrap: 'break-word',
        marginBottom: "10px"
    },
    chDescription: {
        alignSelf: 'stretch', color: 'rgba(27, 38, 47, 0.60)', fontSize: 18,
        fontFamily: 'Average Sans !important', fontWeight: '400 !important', letterSpacing: 0.15,
        wordWrap: 'break-word',
        //minWidth: 335,
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        display: '-webkit-box',
        WebkitLineClamp: '2',
        WebkitBoxOrient: 'vertical',
        marginBottom: '10px'
    },
    chDate: {
        alignSelf: 'stretch', color: 'rgba(27, 38, 47, 0.87)', fontSize: 20,
        fontFamily: 'Open Sans !important', fontWeight: '400 !important', letterSpacing: 0.15,
        wordWrap: 'break-word',
        marginBottom: "10px"
    },
}));
const cookies = new Cookies();

const ChallengeCards = (props) => {
    const classes = useStyles()
    const history = useHistory();


    let isPTDueDateEnded = false
    let stardate = formatDate(props.item.start_date)
    let enddate = formatDate(props.item.end_date)
    if (enddate < formatDate(Date.now())) {
        isPTDueDateEnded = true
    }

    useEffect(() => {
        cookies.remove("practiceTitle")
        cookies.remove("challengeStatus")
        cookies.remove("totalchallengeperPtTest")
        cookies.remove("totalattepmtedchallenge")
        cookies.remove("fromFRQ")

        cookies.remove("practiceMode")
        cookies.remove("unique_id")
    }, [])

    useEffect(() => {
        if (getInstituteId() === Institute.Rice) {
            const interval = setInterval(() => {
                let startEpoch = props.item.epoch
                if (startEpoch > 0 && props.item.challengeStatus === "In Progress") {

                    let totalTime
                    let totalChallenges = props.item.totalchallengeperPtTest
                    if (totalChallenges > halfTestMarksLimit) {
                        totalTime = fullPTTimeInString
                    }
                    else {
                        totalTime = halfPTTimeInString
                    }

                    let now = new Date();
                    let currentEpoch = Math.floor(now.getTime() / 1000);
                    let getTimeDiffArray = getTimeDifference(currentEpoch, startEpoch)
                    let currentTimeDiff = getTimeDiffArray.hours + ":" + getTimeDiffArray.minutes + ":" + getTimeDiffArray.seconds

                    if (convertDurationToSeonds(totalTime) < convertDurationToSeonds(currentTimeDiff)) {
                        clearInterval(interval);
                        debugger;
                        /// Update Remaining TimeLapse in DB
                        const reqData1 = {
                            "user_id": getCurrentUserId(),
                            "unique_id": parseInt(props.item.unique_id),
                            "timelapse": "00:00:00",
                            "epoch": props.item.epoch
                        }

                        savepttimer(reqData1).then(res => {
                            if (res.data !== undefined && res.data !== null) {
                                /// PT TimeLapse updated successfully..!
                            }
                        })

                        const reqData = {
                            "user_id": getCurrentUserId(),
                            "unique_id": parseInt(props.item.unique_id),
                            "problem_source": Problem_source.apptesthomework
                        }

                        updatePTAutoSubmit(reqData).then(res => {
                            if (res.data !== undefined && res.data !== null) {
                                const reqData = {
                                    "user_id": getCurrentUserId(),
                                    "unique_id": parseInt(props.item.unique_id),
                                }
                                PTTimerSubmitUpdate(reqData).then(res => {
                                    if (res.data !== undefined && res.data !== null) {
                                        props.itemchanged()
                                    }
                                })

                            }
                        })
                    }
                }
            }, 5000)
        }
    }, [])

    const btnGuidedORExamModeClickHandler = (mode) => {
        cookies.remove("practiceTitle")
        cookies.remove("challengeStatus")
        cookies.remove("totalchallengeperPtTest")
        cookies.remove("totalattepmtedchallenge")
        cookies.remove("fromFRQ")

        cookies.set("practiceTitle", props.item.title, { path: "/" })
        cookies.set("challengeStatus", props.item.challengeStatus, { path: "/" })
        cookies.set("totalchallengeperPtTest", props.item.totalchallengeperPtTest, { path: "/" })
        cookies.set("totalattepmtedchallenge", props.item.totalattepmtedchallenge, { path: "/" })
        cookies.set("timelapse", props.item.timelapse, { path: "/" })
        cookies.set("epoch" + parseInt(props.item.unique_id), props.item.epoch, { path: "/" })

        if (getInstituteId() === Institute.Rice) {
            if (props.item.epoch === 0) {
                //         let now = new Date();
                //         let gmtEpoch = Math.floor(now.getTime() / 1000);
                //         const reqData = {
                //             "user_id": getCurrentUserId(),
                //             "unique_id": parseInt(props.item.unique_id),
                //             "epoch": gmtEpoch,
                //             "timelapse": null
                //         }

                //         savepttimer(reqData).then(res => {
                //             if (res.data !== undefined && res.data !== null) {
                //                 cookies.set("epoch" + parseInt(props.item.unique_id), gmtEpoch, { path: "/" })
                //                 window.location = "/questionRenderer/" + props.item.unique_id + "/exam"
                //             }
                //         })
                window.location = "/testInstructions/" + props.item.unique_id + "/exam"
            }
            else if (props.item.epoch === -1) {
                //    let initialTime = cookies.get("timelapse", { pathname: "/" })

                let now = new Date();
                let gmtEpoch = Math.floor(now.getTime() / 1000);
                const reqData = {
                    "user_id": getCurrentUserId(),
                    "unique_id": parseInt(props.item.unique_id),
                    "timelapse": props.item.timelapse,
                    "epoch": gmtEpoch
                }

                savepttimer(reqData).then(res => {
                    if (res.data !== undefined && res.data !== null) {
                        cookies.set("epoch" + parseInt(props.item.unique_id), props.item.epoch, { path: "/" })
                        window.location = "/questionRenderer/" + props.item.unique_id + "/exam"
                    }
                })
            }
            else {
                window.location = "/questionRenderer/" + props.item.unique_id + "/exam"
            }
        }
        else {
            window.location = "/testInstructions/" + props.item.unique_id + "/" + mode
        }
    }

    return (
        <>
            <Card className={classes.cards}>
                <Paper className={classes.paper} elevation={3}>
                    <CardMedia
                        image={cardmediaTestImage}
                        className={classes.cardMedia}>

                        <div
                            className={classes.mediaImage}>
                            <Chip label={props.item.challengeStatus} className={classes.statusChip} />
                        </div>
                    </CardMedia>
                    <CardContent className={classes.contentContainer}>
                        <Typography className={classes.scoreDiv}>Scored {props.item.totalhwscore}/{props.item.totalpoints} </Typography>
                        <Typography className={classes.chTitle}>
                            {props.item.title}</Typography>
                        <Typography className={classes.chDescription}>
                            {props.item.description}
                        </Typography>
                        {props.item.id === 46 || props.item.id === 215 || props.item.id === 116 ?
                            <TryThisPin showPin /> : null
                        }
                        <Typography className={classes.chDate}>
                            <Row className='no-gutters'>
                                {/* <Col sm="3">
                                                                    </Col> */}
                                <Col><span >Duration:</span>  {stardate} - {enddate}</Col>
                            </Row>

                        </Typography>

                    </CardContent>
                    <CardActions>
                        <Stack direction="row" spacing={1}>
                            {isPTDueDateEnded ?
                                <>
                                    {props.item.challengeStatus === "Completed" ?
                                        <Link
                                            to={{
                                                pathname: "/quesionRenderer/" + props.item.unique_id + "/guided/review",
                                                state: { prevPath: ['/practicetest/'], prevNode: [getInstituteId() === Institute.Rice ? 'Quiz' : 'Practice Test'] }
                                            }}
                                        >
                                            <Chip label="Assessment Summary" className={classes.guidedChip} />
                                        </Link>
                                        :
                                        getInstituteId() === Institute.Rice ?
                                            <Chip disabled={isPTDueDateEnded} label={props.item.challengeStatus === "In Progress" ? "Resume Quiz" : "Quiz Not Attempted"} onClick={() => btnGuidedORExamModeClickHandler("exam")} className={classes.guidedChip} />
                                            :
                                            <>
                                                <Chip label="Guided Mode" onClick={() => btnGuidedORExamModeClickHandler("guided")} className={classes.guidedChip} />
                                                <Chip label="Exam Mode" onClick={() => btnGuidedORExamModeClickHandler("exam")} className={classes.guidedChip} />
                                            </>
                                    }
                                </>
                                :
                                props.item.challengeStatus === "Completed" ?
                                    <Link
                                        to={{
                                            pathname: "/quesionRenderer/" + props.item.unique_id + "/guided/review",
                                            state: { prevPath: ['/practicetest/'], prevNode: [getInstituteId() === Institute.Rice ? 'Quiz' : 'Practice Test'] }
                                        }}
                                    >
                                        <Chip label="Assessment Summary" className={classes.guidedChip} />
                                    </Link>
                                    :
                                    getInstituteId() === Institute.Rice ?
                                        <Chip label={props.item.challengeStatus === "In Progress" ? "Resume Quiz" : "Start Quiz"} onClick={() => btnGuidedORExamModeClickHandler("exam")} className={classes.guidedChip} />
                                        :
                                        <>
                                            <Chip label="Guided Mode" onClick={() => btnGuidedORExamModeClickHandler("guided")} className={classes.guidedChip} />
                                            <Chip label="Exam Mode" onClick={() => btnGuidedORExamModeClickHandler("exam")} className={classes.guidedChip} />
                                        </>

                            }

                        </Stack>
                    </CardActions>

                </Paper>

            </Card>
        </>
    )

}
export default ChallengeCards