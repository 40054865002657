import React from 'react'
import './TakeNotes.scss'
import { Label, Form, FormGroup, Input } from "reactstrap";
import ModalComponent from '../../Common/ModalComponent/ModalComponent';
import Button from '../../Common/Button/Button';

export default class TakeNotes extends React.Component {
    state = {
        note: '',
        isReview: false,
        formErrors: {
            note: ''
        }
    }

    handleNoteChange = (e) => {
        if (e.target.value.trim().length > 0 && this.state.formErrors.note !== '') {
            this.setState({
                formErrors: {
                    note: ''
                }
            })
        }
        this.setState({
            note: e.target.value
        })
    }

    handleNoteTypeChange = (e) => {
        this.setState({
            isReview: e.target.name === 'review' ? (e.target.value === 'on' ? true : false) : (e.target.value !== 'on' ? true : false)
        })
    }

    componentWillReceiveProps(newProps) {
        this.setState({
            note: '',
            isReview: false,
            formErrors: {
                note: ''
            }
        })
    }

    saveNote = () => {
        if (this.state.note.trim()) {
            this.props.onSave(this.state)
        } else {
            this.setState({
                formErrors: {
                    note: "empty",
                }
            })
        }
    }

    render() {
        const modalTitle = (
            <h4>Take a note</h4>
        );

        const modalBody = (
            <>
                <Form>
                    <FormGroup>
                        <h5>{this.props.title}</h5>
                        <Input
                            autoFocus
                            value={this.state.note}
                            onChange={this.handleNoteChange}
                            type="textarea"
                            name="note"
                            placeholder="Take a note here..." />
                        {this.state.formErrors.note !== '' && <p className='note-validator'>Please enter the note first.</p>}
                    </FormGroup>

                    <Label className='note-type-label'>Type of the note:</Label>
                    <FormGroup check inline>
                        <Label check>
                            <Input type="radio" name="clarification"
                                checked={!this.state.isReview}
                                onChange={this.handleNoteTypeChange} />
                                    For Clarification</Label>
                    </FormGroup>

                    <FormGroup check inline>
                        <Label check>
                            <Input type="radio" name="review"
                                checked={this.state.isReview}
                                onChange={this.handleNoteTypeChange} />
                                    For Review</Label>
                    </FormGroup>
                </Form>
            </>
        );

        const customButton = (
            <>
                <Button clicked={this.saveNote} className="mr-2 yellow-btn">Save</Button>
                <Button clicked={this.props.toggleModal} className="mr-2 grey-btn">Cancel</Button>
            </>
        );
        return (
            <ModalComponent
                show={this.props.showModal}
                handleClose={this.props.toggleModal}
                title={modalTitle}
                body={modalBody}
                showButton={false}
                customButton={customButton}
                draggable={false}
            />
        )
    }

}