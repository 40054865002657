import React, { Component } from "react";
import LogoSign from '../../assets/images/logo-sign.svg';
class TourContent extends Component {

    render() {
        return <>
            <div className="d-flex align-items-center" >
                <img src={LogoSign} alt="" className="tourLogo" />
                <span className="tourTitle pl-2">{this.props.tourTitle}</span>
            </div>
            <div className="d-flex tourcontentDiv">
                <div className="tourcontent">
                    {this.props.contentVideo !== null ?
                        <div style={{ width: '100%', float: 'left' }}>
                            <video playsinline muted loop={true} autoPlay={true} width={this.props.isVideoWidth === true ? "280" : "345"} height="240" controls>
                                <source src={this.props.contentVideo} type="video/mp4" />
                            </video>
                        </div>
                        :
                        this.props.contentImg !== null ?
                            <div style={{ width: '100%', float: 'left' }}>
                                <img src={this.props.contentImg} alt="" className="tourcontentImage img-fluid text-center" />
                            </div>
                            : null
                    }
                    <span className="tourcontentHeaderSpan">{this.props.contentHeader}</span>
                    <div className="tourcontentDetails" dangerouslySetInnerHTML={{ __html: this.props.contentDetails }} />
                </div>
            </div>
        </>
    }
}

export default (TourContent)